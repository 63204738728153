import React from 'react';
import error from '../assets/Error.jpg'


export const Error401 = () => {
  return (
    <div class="mars">


<img src={error} class="error401" /></div>
  );
}

export default Error401;