import * as React from 'react';
import configData from "../config.json";

export const WeighbridgeHeader = (props) => {
    const [dateTime, setDateTime] = React.useState("");
    const [message, setMessage] = React.useState("");
    const version = configData.VERSION;
    const [docketNo, setDocketNo] = React.useState("");
    const timeInterval = React.useRef(null);
    if (docketNo !== props.docketNo) setDocketNo(props.docketNo);

    React.useEffect(() => {
        UpdateTime();
        timeInterval.current = setInterval(() => UpdateTime(), 10000);
        return () => {
            if (timeInterval.current !== null) clearInterval(timeInterval.current);
        }
    }, []);

    React.useEffect(() => {
        setMessage(props.message);
        setTimeout(() => { setMessage("") }, 2000);
    }, [props.message]);

    const UpdateTime = () => {
        let dt = new Date();
        setDateTime(dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear() + " " + (dt.getHours() % 12) + ":" + (dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes());
    }

    return (
        <div className="weighbridgeInfo">
            <div className="weighbridgeInfoTime">{dateTime}</div>
            <div className="weighbridgeInfoVersion">{version}</div>
            <div className="weighbridgeInfoMessage">{message}</div>
            <div className="weighbridgeDocket">{docketNo}</div>
        </div>
    );
}

export default WeighbridgeHeader;
