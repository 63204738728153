import * as React from "react";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTitle,
    ChartLegend,
    exportVisual
} from "@progress/kendo-react-charts";
import configData from "../../config.json";
import { GetToken } from "../../components/Session";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import "hammerjs";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import DashboardCards from "./DashboardCards";
import { exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GetSession } from '../../components/Session';

const InventoryTrend = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [categories, setCategories] = React.useState(["Product 1", "Product 2", "Product 3", "Product 4", "Product 5"]);
    const [inOut, setInOut] = React.useState(null);
    const [inventoryPerformance, setInventoryPerformance] = React.useState(null);
    const [pieData, setPieData] = React.useState(null);
    const [accumulated, setAccumulated] = React.useState(null);
    const [siteHeading, setSiteHeading] = React.useState("Inventory by Product");
    const [expanded, setExpanded] = React.useState(false);
    const [inoutMonths, setInoutMonths] = React.useState(months);
    const [topNProducts, setTopNProducts] = React.useState("Product Inventory")
    const sale = React.useRef(null);
    const productchart = React.useRef(null);
    const total = React.useRef(null);
    const piechart = React.useRef(null);
    const [sites, setSites] = React.useState([]);
    const [site, setSite] = React.useState();
    const [products, setProducts] = React.useState([]);
    const [product, setProduct] = React.useState();
    const [salesYAxis, setSalesYAxis] = React.useState("");
    const [productYAxis, setProductYAxis] = React.useState("");
    const [accYAxis, setAccYAxis] = React.useState("");
    const timer = React.useRef(null);
    const [layout, setLayout] = React.useState([
        { "field": "site", "label": "Site", "isVisible": true, "type": "list" },
        { "field": "product", "label": "Product", "isVisible": true, "type": "list" },
    ]);
    const siteId = React.useRef(0);
    const productId = React.useRef(0);

    const onImageExportClick = (e) => {
        var chart = e.currentTarget.name;
        var chartVisual = null;
        if (chart == 'sales') {
            chartVisual = sale.current && exportVisual(sale.current);
        }
        else if (chart == 'product') {
            chartVisual = productchart.current && exportVisual(productchart.current);
        }
        else if (chart == 'total') {
            chartVisual = total.current && exportVisual(total.current);
        }
        else {
            chartVisual = piechart.current && exportVisual(piechart.current);
        }

        if (chartVisual) {
            exportPDF(chartVisual, {
                paperSize: "A4",
                landscape: true,
            }).then((dataURI) => saveAs(dataURI, "Inventory.pdf"));
        }
    };
    React.useEffect(() => {
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'InventoryTrend'";
        fetch(baseUrl, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile) {
                let data = [];
                for (let i = 0; i < d.length; i++) {
                    data.push({ field: d[i].field, label: d[i].label, type: d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile });
                }
                setLayout(data);
            } else {
                setLayout(d);
            }
        });  
        baseUrl = configData.SERVER_URL + "apiv7/options";
        fetch(baseUrl, init).then((response) => response.json()).then((d) => {

            setSites(CreateList(d.sites));
            setSite({ id: "0", name: "All" });
            setProducts(CreateList(d.products));
            setProduct({ id: "0", name: "All" });
        });
        UpdateDashBoard(0, 0);
        let url = `${configData.SERVER_URL}apiv7/settings`;
        fetch(url, init).then(response => response.json()).then(d => {
            if (d.length > 0) {
                let updateRate = GetSetting(d, "RealTimeDashRefresh") * 1000;
                timer.current = setInterval(DoUpdate, updateRate);
            }
        })
        return () => {
            if (timer.current !== null) clearInterval(timer.current);
        }
    }, []);

    const DoUpdate = () => {
        UpdateDashBoard(siteId.current, productId.current);
    }

    const UpdateDashBoard = (siteId, productId) => {
        const timeOffset = new Date().getTimezoneOffset() / 60;
        let baseUrl = configData.SERVER_URL + `apiV7/dashboard/getinventorytrends?site=${siteId}&product=${productId}&offset=${timeOffset}`;
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        fetch(baseUrl, init)
            .then((response) => response.json())
            .then((d) => {
                setInOut(d.inOut);
                setInventoryPerformance(d.productInventory);
                setAccumulated(d.accumulated);
                setPieData(d.pieData);
                setCategories(d.productNames);
                setTopNProducts(`Product Inventory (Top ${d.productNames.length})`);
                setInoutMonths(d.monthNames);
                setSalesYAxis(d.salesY);
                setProductYAxis(d.productY);
                setAccYAxis(d.accY);
            });
    }

    const GetSetting = (s, name) => {
        if (s === undefined || s === null || s === "") return false;
        let st = undefined;
        st = s.find(obj => { return obj.name == name });
        if (st === undefined) return undefined;
        let v = String(st.value);
        switch (st.type) {
            case 0:
                v = v.toLowerCase() === "true";
                break;
            case 1:
                v = parseInt(v);
                break;
            case 2:
                v = parseFloat(v);
                break;
        }
        return v;
    }

    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }
    const siteChanged = (event) => {
        setSite(event.target.value);
        siteId.current = event.target.value.id;
        UpdateDashBoard(event.target.value.id, product.id);
    }
    const productChanged = (event) => {
        setProduct(event.target.value);
        productId.current = event.target.value.id;
        UpdateDashBoard(site.id, event.target.value.id);
    }

    const CreateList = (d) => {
        var x = [{ id: "0", name: "All" }];
        d.map((v) => x.push({ id: v.id, name: v.name  }));
        return x;
    };

    return (
        <>
            <div className="row" style={{ padding: "5px 10px" }}>
                {GetField("site")["isVisible"] && <div className="col-md-3">
                    <label className="control-label">{GetField("site")["label"]}</label>
                    <DropDownList id="Site" data={sites} value={site} textField="name" dataItemKey="id" onChange={siteChanged} />
                </div>}
                {GetField("product")["isVisible"] && <div className="col-md-3">
                    <label className="control-label">{GetField("product")["label"]}</label>
                    <DropDownList id="Product" data={products} value={product} textField="name" dataItemKey="id" onChange={productChanged} />
                </div>}
            </div>
            <div className="row mb-3">
                <div className="row" style={{ padding: "5px 10px" }}></div>{" "}
                {inOut && <div className="col-6">
                    <button className="k-button" name="sales" onClick={onImageExportClick}>Export as PDF</button>
                    <div className="k-card">
                        <Chart ref={sale} transitions={false}
                            style={{
                                height: 350,
                            }}
                        >
                            <ChartTitle
                                text="Inventory"
                                font="22Pt bold"
                                color="#00008B"
                            />
                            <ChartLegend position="top" orientation="horizontal" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem
                                    categories={months}
                                    startAngle={45}
                                />
                            </ChartCategoryAxis>
                            <ChartValueAxis>
                                <ChartValueAxisItem title={{ text: salesYAxis }} />
                            </ChartValueAxis>
                            <ChartSeries>
                                {inOut.map((item, idx) => (
                                    <ChartSeriesItem
                                        key={idx}
                                        type="line"
                                        tooltip={{
                                            visible: true,
                                        }}
                                        data={item.data}
                                        name={item.name}
                                    />
                                ))}
                            </ChartSeries>
                        </Chart>
                    </div>
                </div>}
                {inventoryPerformance && <div className="col-6">
                    <button className="k-button" name="product" onClick={onImageExportClick}>Export as PDF</button>
                    <div className="k-card">
                        <Chart ref={productchart} transitions={false}
                            style={{
                                height: 350,
                            }}
                        >
                            <ChartTitle
                                text={topNProducts}
                                font="22Pt bold"
                                color="#00008B"
                            />
                            <ChartLegend position="top" orientation="horizontal" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem
                                    categories={categories}
                                    startAngle={45}
                                />
                            </ChartCategoryAxis>
                            <ChartValueAxis>
                                <ChartValueAxisItem title={{ text: productYAxis }} />
                            </ChartValueAxis>
                            <ChartSeries>
                                {inventoryPerformance.map((item, idx) => (
                                    <ChartSeriesItem
                                        key={idx}
                                        type="column"
                                        tooltip={{
                                            visible: true,
                                        }}
                                        data={item.data}
                                        name={item.name}
                                    />
                                ))}
                            </ChartSeries>
                        </Chart>
                    </div>
                </div>}
            </div>
            <div className="row">
                {accumulated && <div className="col-6">
                    <button className="k-button" name="total" onClick={onImageExportClick}>Export as PDF</button>
                    <div className="k-card">
                        <Chart ref={total} transitions={false}
                            style={{
                                height: 350,
                            }}
                        >
                            <ChartTitle
                                text="Inventory Movements"
                                font="22Pt bold"
                                color="#00008B"
                            />
                            <ChartLegend position="top" orientation="horizontal" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem
                                    categories={inoutMonths}
                                    startAngle={45}
                                />
                            </ChartCategoryAxis>
                            <ChartValueAxis>
                                <ChartValueAxisItem title={{ text: accYAxis }} />
                            </ChartValueAxis>
                            <ChartSeries>
                                {accumulated.map((item, idx) => (
                                    <ChartSeriesItem
                                        key={idx}
                                        type="area"
                                        tooltip={{
                                            visible: true,
                                        }}
                                        data={item.data}
                                        name={item.name}
                                    />
                                ))}
                            </ChartSeries>
                        </Chart>
                    </div>
                </div>}
                {pieData && <div className="col-6">
                    <button className="k-button" name="site" onClick={onImageExportClick}>Export as PDF</button>
                    <div className="k-card">
                        <Chart ref={piechart} transitions={false}
                            style={{
                                height: 450,
                            }}
                        >
                            <ChartTitle
                                text={siteHeading}
                                font="22Pt bold"
                                color="#00008B"
                            />
                            <ChartLegend position="side" orientation="vertical" />
                            <ChartSeries>
                                <ChartSeriesItem
                                    type="pie"
                                    overlay={{
                                        gradient: "sharpBevel",
                                    }}
                                    tooltip={{
                                        visible: true,
                                    }}
                                    data={pieData}
                                    categoryField="name"
                                    field="share"
                                />
                            </ChartSeries>
                        </Chart>
                    </div>
                </div>}
            </div>
        </>
    );
};
export default InventoryTrend;
