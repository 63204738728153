import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormNumericTextBox, FormDropDownList } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { GetPermissions } from '../components/Options';

const ModifyInventory = () => {
    const { action } = useParams(); //modifyinventory/action
    const navigate = useNavigate();
    const [sites, setSites] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [permissions, setPermissions] = React.useState(null);

    const [layout, setLayout] = React.useState([
        { "field": "sites", "label": "Site", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "products", "label": "Product", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "amount", "label": "Amount", "defaultValue": 0, "isVisible": true, "type": "numeric" }
    ]);

    React.useEffect(() => {
        GetPermissions(["ManageInventory"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'ModifyInventory'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            setLayout(d); 
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => { 
            setSites(opt.sites);
            setProducts(opt.products);
        });
    }, []);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    const handleSubmit = (dataItem) => {
        var url = `${configData.SERVER_URL}apiv7/inventorymovements`;
        let type = "IN";
        let docketNo = "MANUAL";
        let amount = dataItem.amount;
        var utcDate = new Date().toISOString();
        switch(action){
            case "Deduct":
                type = "OUT";
                amount = -dataItem.amount;
                break;
            case "Stock Take":
                docketNo = "STOCK TAKE";
                break;
            default:
                type = "IN";               
                break;
        }
        const init = {
          method: 'POST',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
                "dateCreated": utcDate,
                "productId": dataItem.products.id,
                "product": null,
                "type": type,
                "amount": amount,
                "userId": 1,
                "user": null,
                "siteId": dataItem.sites.id,
                "site": null,
                "isEdit": false,
                "isZeroed": false,
                "dateZeroed": null,
                "docketNo": docketNo,
                "zeroedBy": 0,
                "isActive": true,
                "stockLevel": 0,
                "stockValue": 0
            })
        }
        fetch(url, init).then(response => response).then(d => { 
            var msg = "Inventory adjusted";
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/inventory", {state: {msg: msg}});
    }
    if (permissions && !permissions.ManageInventory) Redirect("Error: You do not have permission to manage inventory");
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) =>  value ? "" : "Please select an option";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} render={(formRenderProps) => (
                <FormElement style={{width: "95%"}} >
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                        {GetField("sites")["isVisible"] && <div className='col-md-3'>
                                <Field id={"sites"} name={"sites"} label={GetField("sites")["label"]} component={FormDropDownList} data={sites} textField="name" validator={optionValidator} />
                            </div>}  
                        </div>
                        <div className="row">
                        {GetField("products")["isVisible"] && <div className='col-md-3'>
                                <Field id={"products"} name={"products"} label={GetField("products")["label"]} component={FormDropDownList} data={products} textField="name" validator={optionValidator}/>
                            </div>}  
                        </div>
                        <div className="row">
                        {GetField("amount")["isVisible"] && <div className='col-md-3'>
                                <Field id={"amount"} name={"amount"} label={GetField("amount")["label"]} component={FormNumericTextBox} min={0} max={100000} validator={textValidator}/>
                                </div>}
                        </div>
                        </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-2'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{action}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Cancel</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>                        
    );

}

export default ModifyInventory;