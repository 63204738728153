import * as React from 'react';
import { Form, FormElement, FieldArray } from "@progress/kendo-react-form";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { TextBox } from "@progress/kendo-react-inputs";
import { Label } from '@progress/kendo-react-labels';
import { GetSetting } from "./Helpers";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { CreateNewProduct } from "./Transaction";

export const FormGridEditContext = React.createContext("");

const DeleteCell = (props) => {
    const { dataItem } = props;
    return (
        <td>
            <Button icon="delete" onClick={() => props.remove(dataItem)} title="Delete" />
        </td>
    )
}

const FormGrid = (fieldArrayRenderProps) => {
    const {name} = fieldArrayRenderProps;
    const prods = fieldArrayRenderProps.productList;
    const DeleteProduct = fieldArrayRenderProps.deleteProduct;
    const showWeight = fieldArrayRenderProps.showEstWeight;
    

    const CommandCell = (props) => {
        return (
            <DeleteCell {...props} remove={Remove} />
        )
    }

    const Remove = (item) => {
        DeleteProduct(item);
    }

    return <FormGridEditContext.Provider value={name}>
        <Grid data={prods} pageable={{
            buttonCount: 4,
            pageSizeValue: 5,
            pageSizes: [5, 10, 20, "All"],
        }}
        >
            <GridColumn field="name" title="Counted Product" editable={false} />
            <GridColumn width="120" field="currentAmount" title="Quantity" format="{0:n2}" editable={false} />
            {showWeight && <GridColumn width="120" field="estWeight" title="Est. Weight (t)" format="{0:n2}" editable={false} />}
            <GridColumn width="80" field="Action" title="Delete" cell={CommandCell} editable={false} />
        </Grid>
    </FormGridEditContext.Provider>;
};

export const MixedProducts = (props) => {

    const [permissions, setPermissions] = React.useState(null);
    const [weighedProducts, setWeighedProducts] = React.useState(props.weighed); // weighed product dropdown
    const [countedProducts, setCountedProducts] = React.useState(props.counted); // counted product dropdown
    const [productList, setProductList] = React.useState([]); // list with selected weighed and counted products
    const [countedProductList, setCountedProductList] = React.useState([]); // selected counted products
    const [selCountedProduct, setSelCountedProduct] = React.useState(); // currently selected counted product in dropdown
    const [selWeighedProduct, setSelWeighedProduct] = React.useState(); // currrently selected weighed product in dropdown
    const [quantity, setQuantity] = React.useState(1); // quantity in quantity textbox
    const showEstWeight = GetSetting(props.settings, "ShowEstimatedWeightOnCountedTicketWindow");
    if (props.trans.selectedMixedProducts !== null && props.trans.selectedMixedProducts !== productList) {
        setProductList(props.trans.selectedMixedProducts);
        let pl = props.trans.selectedMixedProducts;
        if (pl !== null && pl.length !== 0) {
            let cl = [];
            pl.map((x) => {
                if (x.productId !== props.trans.productId) cl.push(x);
            });
            setCountedProductList(cl);
        }
    }
    if (props.trans.productId > 0 && (selWeighedProduct === undefined || props.trans.productId !== selWeighedProduct.productId)) {
        let p = weighedProducts.find(obj => obj.id === String(props.trans.productId));
        if (p !== undefined && p.id !== "1") {
            var np = CreateNewProduct();
            np.productId = parseInt(p.id);
            np.name = p.name;
            setSelWeighedProduct(np);
        }
    }

    const [layout, setLayout] = React.useState([
        { "field": "weighedProduct", "label": "Weighed Product", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "countedProduct", "label": "Counted Product", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "quantity", "label": "Quantity", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "estWeight", "label": "Est. Weight (t)", "defaultValue": "", "isVisible": true, "type": "text" }
    ]);

    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    const weighedProductChanged = (e) => {
        var p = CreateNewProduct();
        p.productId = parseInt(e.value.id);
        p.name = e.value.name;
        setSelWeighedProduct(p);
        if (props.setWeighedProduct) props.setWeighedProduct(p);
        var mp = [p, ...countedProductList];
        UpdateMixedProducts(mp);
    }

    const countedProductChanged = (e) => {
        setSelCountedProduct(e.value);
    }

    const addProduct = () => {
        if (selCountedProduct === undefined) return;
        let cp = [...countedProductList];
        let id = parseInt(selCountedProduct.id);
        let itm = productList.find(obj => obj.productId === id);
        if (itm !== undefined) {
            itm.currentAmount += quantity;
            itm.estWeight = itm.currentAmount * itm.minWeight;
        }  else {
            var p = CreateNewProduct();
            p.productId = id;
            p.name = selCountedProduct.name;
            let sp = selCountedProduct.data.split(',');
            let mw = 0;
            if (sp.length > 1) mw = parseFloat(sp[1]);
            p.minWeight = mw;
            p.currentAmount = quantity;
            p.estWeight = quantity * mw;
            cp.push(p);
        }
        setCountedProductList(cp);
        var mp = [];
        if (selWeighedProduct !== undefined) {
            mp = [selWeighedProduct, ...cp]; // weighed product is always first
        } else {
            mp = cp; // just the counted products if weighed not selected
        }
        setProductList(mp); 
        UpdateMixedProducts(mp);
    }

    const quantityChanged = (e) => {
        setQuantity(parseInt(e.value));
    }

    const deleteProduct = (pr) => {
        let cp = countedProductList.filter(obj => (obj.productId !== pr.productId));
        setCountedProductList(cp);
        if (selWeighedProduct !== undefined)
            UpdateMixedProducts([selWeighedProduct, ...cp]);
        else
            UpdateMixedProducts(cp);
    }

    const UpdateMixedProducts = (mp) => {
        if (props.updateMixedProducts) props.updateMixedProducts(mp);
    }

    return (
        <div>
            <div className='row' style={{ paddingBottom: "20px" }} >
                {props.trans.loadType==="MIXED" && GetField("weighedProduct")["isVisible"] && <div className='col-md-6'>
                    <Label>{GetField("weighedProduct")["label"]}</Label>
                    <DropDownList id={"weighedProduct"} name={"weighedProduct"} data={weighedProducts} textField="name" value={selWeighedProduct} onChange={weighedProductChanged} />
                </div>}
            </div>
            <div className='row' style={{ paddingBottom: "20px" }} >
                {GetField("countedProduct")["isVisible"] && <div className='col-md-6'>
                    <Label>{GetField("countedProduct")["label"]}</Label>
                    <DropDownList id={"countedProduct"} name={"countedProduct"} data={countedProducts} textField="name" onChange={countedProductChanged} />
                </div>}
                {GetField("quantity")["isVisible"] && <div className='col-md-2'>
                    <Label>{GetField("quantity")["label"]}</Label>
                    <TextBox id={"quantity"} name={"quantity"} type="number" min={1} max={100000} value={quantity} onChange={quantityChanged} />
                </div>}
                <div className='col-md-1' style={{ paddingTop: "20px" }}>
                    <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={addProduct}>Add Product</Button>
                </div>
            </div>
            <div className='row'>
                <Form render={(formRenderProps) => (
                <FormElement>
                        <FieldArray name="countedProducts" component={FormGrid} productList={countedProductList} deleteProduct={deleteProduct} showEstWeight={showEstWeight} />
                    </FormElement>
                )} />
            </div>
        </div>
    );
}