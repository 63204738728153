import * as React from "react";
import {
  TileLayout,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import LeaderBoard from "./charts/LeaderBoard";
import ProductChart from "./charts/ProductChart";
import TonPerVolume from "./charts/TonPerVolume";
import WeeklyTile from "./trend/WeeklyTile";
import MonthlyTile from "./trend/MonthlyTile";
import YearlyTile from "./trend/YearlyTile";
import FinYearlyTile from "./trend/FinYearlyTile";
import PerformanceTrend from "./trend/PerformanceTrend";
import InventoryTrend from "./trend/InventoryTrend";
import RealTimeTile from "./trend/RealTimeTile";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import DashboardCards from "./trend/DashboardCards";
import { GetPermissions } from "../components/Options";
import LandingPage from "./LandingPage";

const Dashboard = () => {
  const [selected, setSelected] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [permissions, setPermissions] = React.useState(null);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  React.useEffect(() => {
    GetPermissions(
      ["ViewBasicDashboard", "ViewAdvancedDashboard", "ViewCustomDashboard", "ViewInventoryDashboard"],
      setPermissions
      );

  }, []);

  const tiles = [
    {
      defaultPosition: { col: 1, colSpan: 3, rowSpan: 2 },
      resizable: false,
      reorderable: false,
      body: <TonPerVolume></TonPerVolume>,
    },
    {
      defaultPosition: { col: 4, colSpan: 1, rowSpan: 1 },
      resizable: false,
      reorderable: false,
      body: <ProductChart></ProductChart>,
    },
    {
      defaultPosition: { col: 4, colSpan: 1, rowSpan: 1 },
      resizable: false,
      reorderable: false,
      body: <LeaderBoard></LeaderBoard>,
    },
  ];

  return  (
      (permissions && (permissions.ViewAdvancedDashboard || permissions.ViewCustomDashboard || permissions.ViewBasicDashboard)) ? (
          
      <TabStrip selected={selected} onSelect={handleSelect} id="Dashboard">
      {permissions.ViewBasicDashboard && (
        <TabStripTab title="Real Time Data">
          <RealTimeTile></RealTimeTile>
        </TabStripTab>
      )}
      {permissions.ViewAdvancedDashboard && (
        <TabStripTab title="Weekly Trends">
          <WeeklyTile></WeeklyTile>
        </TabStripTab>
      )}
      {permissions.ViewAdvancedDashboard && (
        <TabStripTab title="Monthly Trends">
           <MonthlyTile></MonthlyTile>
        </TabStripTab>
      )}
      {permissions.ViewAdvancedDashboard && (
        <TabStripTab title="Yearly Trends">
            <YearlyTile></YearlyTile>
        </TabStripTab>
      )}
      {permissions.ViewAdvancedDashboard && (
        <TabStripTab title="Financial Year">
           <FinYearlyTile></FinYearlyTile>
        </TabStripTab>
      )}
      {permissions.ViewCustomDashboard && (
        <TabStripTab title="Performance">
           <PerformanceTrend></PerformanceTrend>
        </TabStripTab>
      )}
      {permissions.ViewInventoryDashboard && (
        <TabStripTab title="Inventory">
           <InventoryTrend></InventoryTrend>
        </TabStripTab>
      )}
              </TabStrip>
    ):
    (
      <LandingPage></LandingPage>
    )
  );
  
};
export default Dashboard;
