import React from 'react'
import SignaturePad from './signature_pad.js'

const SignatureCanvas = React.forwardRef((props, ref) => {
  const { canvasProps } = props || {};
  const canvasRef = React.useRef(null);
  const [ sigPad, setSigPad ] = React.useState();

  
  React.useEffect(() => {   
      const canvas = canvasRef.current;     
      const sp = new SignaturePad(canvas); 
      setSigPad(sp);
      sp.on();
  }, []);

  React.useImperativeHandle(ref, () => {
    return {
      on(){
//        window.addEventListener('resize', this._checkClearOnResize)
        return sigPad.on()
      },   
      off(){
//        window.removeEventListener('resize', this._checkClearOnResize)
        return sigPad.off()
      },   
      clear(){
        return sigPad.clear()
      },   
      isEmpty(){
        return sigPad.isEmpty()
      },   
      fromDataURL(dataURL, options){
        return sigPad.fromDataURL(dataURL, options)
      },   
      toDataURL(type, encoderOptions){
        return sigPad.toDataURL(type, encoderOptions)
      },  
      fromData(pointGroups){
        return sigPad.fromData(pointGroups)
      },  
      toData(){
        return sigPad.toData()
      }
    };
  });

  return (
    <canvas ref={canvasRef} {...canvasProps} style={{border:"1px solid #cccccc", width: "500px"}} />
  );
});

export default SignatureCanvas;