import * as React from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

// ItemSelector display a buttons and returns the select buttons
// It displays a virtual keyboard and allows it or physical keyboard to search options
// Options are and array of { code: "abc", name: "name" } objects set as props.options
// Options are filtered by name and code
// props.dataType is name of object, eg "Job"
// props.showDialog controls dialog visibility
// props.allowDismiss displays "X"" in top-right corner and allows dismissal without a selection
// props.showCodes causes codes to be displayed on buttons
// props.itemSelected is callback that is called with code when button is clicked
// example declaration:
// <ItemSelector dataType="Job" options={jobList} itemSelected={JobSelected} showDialog={showItemSelector} allowDismiss />

export const ItemSelector = (props) => {
    const dataType = props.dataType; // type of data being selected, eg Job
    const showKeyboard = props.showKeyboard;
    const [options, setOptions] = React.useState(props.options);  // full list of items to display  
    const showDialog = props.showDialog; // show/hide item selector
    const allowDismiss = props.allowDismiss !== undefined && props.allowDismiss !== false;
    const showCodes = props.showCodes !== undefined && props.showCodes !== false;
    const keyboard = React.useRef(); // reference to onscreen keyboard
    const [search, setSearch] = React.useState(""); // current search text
    const [offence, setOffence] = React.useState(""); // current offence text
    const [offenceHasFocus, setOffenceHasFocus] = React.useState(false);
    const [buttons, setButtons] = React.useState(props.options); // filter items to display as buttons
    const layoutName = props.layoutName === undefined ? "default" : props.layoutName;

    if (options !== props.options) {
        setOptions(props.options);
        setButtons(props.options);
    }

    // keys to display on virtual keyboard
    const [layout, setLayout] = React.useState({
        'default': [
            '1 2 3 4 5 6 7 8 9 0 - {bksp}',
            'Q W E R T Y U I O P',
            'A S D F G H J K L {enter}',
            'Z X C V B N M {clear}',
            '{space}'
        ],
        'rego': [
            '1 2 3 4 5 6 7 8 9 0 {clear}',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Z X C V B N M {enter}'
        ],
        'basic': [
            '1 2 3 4 5 6 7 8 9 0 -',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Z X C V B N M {clear}'
        ],
        'numeric': [
            '7 8 9',
            '4 5 6',
            '1 2 3',
            '0 . {clear}',
            '{enter}'
        ]
    });
    // display names for function keys
    const display = {
        '{bksp}': 'Back',
        '{enter}': 'Enter',
        '{space}': 'Space',
        '{clear}': 'Clear All'
    }

    const DialogNo = () => {
        if (props.itemSelected !== undefined) props.itemSelected("***CANCEL");
    }

    const KeyPressed = (k) => {
        if (k === "{clear}") {
            if (offenceHasFocus) {
                setOffence("");
            } else {
                SearchChanged("");
            }
            if (keyboard.current !== undefined) keyboard.current.setInput("");
        }
    }

    const onChange = (ev) => {
        if (offenceHasFocus) {
            setOffence(ev);
        } else {
            if (offence !== "" && ev.startsWith(offence)) {
                SearchChanged(ev.substr(offence.length));
            } else {
                SearchChanged(ev);
            }
        }
    }

    const TextboxChanged = (ev) => {
        SearchChanged(ev.value);
    }

    const OffenceChanged = (ev) => {
        setOffence(ev.value);
    }

    const SearchChanged = (s) => {
        setSearch(s);
        let sl = s.toLowerCase();
        let b = [];
        options.map((x) => {
            if (x.name.toLowerCase().indexOf(sl) >= 0 || x.code.indexOf(sl) >= 0) b.push(x);
        });
        setButtons(b);
    }

    const ButtonClicked = (code) => {
        if (props.itemSelected !== undefined) {
            if (isNaN(code))
                props.itemSelected(code, offence);
            else                
                props.itemSelected(parseInt(code), offence);
        }
        KeyPressed("{clear}");
    }

    const CreateButton = (b) => {
        return (
            <div key={b.code} className={"itemSelectorButtonDiv"}><Button className={"itemSelectorButton"} onClick={() => ButtonClicked(b.code)}>
                <div className={"itemButtonText"} >{b.name}</div>
                {showCodes && <div className={"itemButtonCode"} >{b.code}</div>}
            </Button></div>
        );
    }

    const OffenceFocus = () => {
        setOffenceHasFocus(true);
    }

    const FocusLost = () => {
        setOffenceHasFocus(false);
        if (search !== "") SearchChanged(search);
    }

    return (
        <div>
            {showDialog && <Dialog onClose={DialogNo}>
                <div className={"itemSelectorHeader"}>
                    <div className={"itemSelectorTitle"}>Select {dataType}</div>
                    {showKeyboard && <div><TextBox autoFocus={true} className={"itemSelectorSearch"} placeholder={"Search for " + dataType} value={search} onChange={TextboxChanged}></TextBox></div>}
                    {allowDismiss && <div className={"itemSelectorClose"}><Button icon={"close"} onClick={DialogNo}></Button></div>}
                </div>
                <div className={"itemSelector"}>
                    <div className={"itemSelectorDiv" }>{buttons.map((x) => CreateButton(x))}</div>
                    {true && <div className={"keyboardDiv"}><Keyboard keyboardRef={r => (keyboard.current = r)} onKeyPress={KeyPressed} onChange={onChange} display={display} layout={layout} layoutName={layoutName} /></div>}
                </div>
                {dataType === "First Weigh" && <div className="vkOffence row">
                    <div className="col-md-9">
                        <label>Offence Comment</label>
                        <TextBox value={offence} onChange={OffenceChanged} onFocus={OffenceFocus} ></TextBox>
                    </div>
                    <div className="col-md-2"><Button onClick={FocusLost} style={{marginTop: "22px"}}>Add Comment</Button></div>
                </div>}
            </Dialog>}
        </div>
    )

}