import configData from "../config.json";
import { GetSession, GetToken } from './Session';

const GetColumnDefs = (view, setColumns, setErrorMessage) => {
    var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=${view}`;
    const init = {
        method: 'GET',
        accept: 'application/json',
        headers: {"Authorization": "Bearer " + GetToken()}
      }
    fetch(url, init).then(response => response.json()).then(d => { 
        let data = [];
        for(let i = 0; i < d.length; i++){
            if (d[i].isAvailable && String(d[i].field).indexOf("search") !== 0){
                let v = d[i].isVisible;
                if (GetSession().IsMobile) v = d[i].isVisibleOnMobile;
                let o = {field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: v};
                if (d[i].columnWidth > 0 && !GetSession().IsMobile) o.width = d[i].columnWidth;
                data.push(o);
            }
        }
        setColumns(data);
    }).catch(error => {
        if (setErrorMessage !== undefined){
            setErrorMessage("Error loading page. Please check your Internet connection");
        }
    })
}

export default GetColumnDefs