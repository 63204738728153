import configData from "../config.json";
import React, { useState } from "react";
import { setUserSession } from "../components/Session";
import Weighmorelogo from "../assets/weighmorelogo";
import SlideButton from 'react-slide-button';

import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import $ from 'jquery'
const SignIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [fail, setFail] = React.useState(false);
    const [reset, setReset] = useState(0);


    const submitted = async (e) => {


        try {
            var url = `${configData.SERVER_URL}apiV7/Auth`;

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors",
                body: JSON.stringify({
                    username,
                    password,
                }),
            });

            if (response.ok) {
                setFail(false)
                const data = await response.json();
                setUserSession(data.userName, data.token, data.userId, data.Permissions);
                window.location.href = "/";
            } else {
                setFail(true)
                setReset(counter => counter + 1)
                console.error("Login failed");
            }
        } catch (error) {
            setReset(counter => counter + 1)
            console.error("Login error:", error);
        }
    };

    return (

        <div>
            <div style={{ height: "100%", width: "100%", margin: 0, position: "fixed" }}>
                <div className="mud-wasm"></div>
                <div className="mud-wasm"></div>
                <div className="mud-wasm" ></div>
            </div>
            <div >
                <div className="form-container sign-in-container" style={{ background: "#ffffffd0" }} >
                    <form >
                        <Weighmorelogo />
                        <h1>Sign in</h1>
                        <input
                            required
                            type="text"
                            placeholder="User Name"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <input
                            required
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {fail && <h6 style={{ color: "red" }}>Invalid User Name or Password</h6>}

                        <SlideButton className="slide-submit"
                            mainText="Slide to Enter"
                            overlayText="Submitted"
                            caretClassList="my-caret-class"
                            overlayClassList="my-overlay-class"
                            onSlideDone={function () {
                                submitted();
                            }}
                            reset={reset}
                        />

                    </form>
                </div>
                <footer>
                    <p>
                        <a href="https://wms.screenconnect.com" target="_blank">
                            <b>Remote Support</b>
                        </a>
                        &nbsp; &nbsp; &nbsp;
                        <a href="https://weigh-more.com.au/contact/" target="_blank">
                            <b>Contact Us</b>
                        </a>
                        &nbsp; &nbsp; &nbsp;
                        <a href="https://weigh-more.com.au/about-us/" target="_blank">
                            <b>About</b>
                        </a>
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default SignIn;
