import * as React from 'react';
import { WeighBridgePanel } from './WeighBridgePanel';
import configData from "../config.json";
import { GetToken, GetUserId } from '../components/Session';
import { CreateNewTransaction, GetTransactionData, GetTransactionFromFirstWeigh, CreateTransactionFromTruck, GetLoadTypeName } from "./Transaction";
import {
    GetField, GetSetting, GetRawSetting, UpdateWeights, UpdateLights, UpdateRemoteDisplay, LightClicked, UpdateCameras, GetStreamingUrl,
    GetSignature, GetEftpos, DefaultLoadType, CheckLoadAgainstTare, GetSelectionItems, GetNextPromptType, PostTransaction, GenerateStateEvent, SetRemoteDisplayMessage,
    GetWarningText, IsAutomated, GetActiveScales, PostEventLog, IsWeighedLoad, GetNextStandardPrompt, GetNextVisitorPrompt, LogVisitor, CreateOffence, GetCloudLogin
} from "./Helpers";
import { DocketPreview } from "./DocketPreview";
import { Button } from "@progress/kendo-react-buttons";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { OperatorData } from "./OperatorData";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FirstWeighs } from "./FirstWeighs";
import { Cameras } from "./Cameras";
import Iframe from 'react-iframe'
import { Signatures } from './Signature';
import { ItemSelector } from './ItemSelector';
import { Eftpos } from './Eftpos';
import { Preview } from './Preview';
import { VirtualKeyboardEntry } from './VirtualKeyboardEntry';
import { WeighbridgeHeader } from './WeighbridgeHeader';
import { MixedProducts } from './MixedProducts';
import { JobTonnage } from './JobTonnage';
import { HIDProcess } from './HIDProcess';
import { TruckConfig } from './TruckConfiguration';
import $ from "jquery";

const Weighbridge = () => {
    const site = React.useRef();
    const [weights, setWeights] = React.useState([0, 0, 0, 0, 0, 0]);
    const businessData = React.useRef(null);
    const [lights, setLights] = React.useState({ Lights: [], Gates: [] });
    const [cameraImages, setCameraImages] = React.useState([]);
    const [display, setDisplay] = React.useState({ Text: "", Weight: 0, MessageTime: 0, WeightTime: 1000 });
    const [showWeightSlider, setShowWeightSlider] = React.useState(false);
    const [showKeyboardIcon, setShowKeyboardIcon] = React.useState(false);
    const [docket, setDocket] = React.useState("");
    const [loadType, setLoadType] = React.useState("");
    const weight = React.useRef([0, 0, 0, 0, 0, 0]);
    const wbSettings = React.useRef({ Decks: 1, MaxWeight: 60 });
    const trans = React.useRef();
    const firstWeigh = React.useRef();
    const settings = React.useRef("");
    const regoList = React.useRef();
    const [rego, setRego] = React.useState("");
    const [regoDisabled, setRegoDisabled] = React.useState(false);
    const currentRego = React.useRef("");
    const currentSplit = React.useRef(0);
    const allOptions = React.useRef();
    const jobOptions = React.useRef([]); // allOptions filtered for a job
    const [showSplit, setShowSplit] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogMessage, setDialogMessage] = React.useState("");
    const [dialogYesText, setDialogYesText] = React.useState("Yes");
    const [dialogNoText, setDialogNoText] = React.useState("No");
    const [dialogError, setDialogError] = React.useState("");
    const [showRegoDiv, setShowRegoDiv] = React.useState(true);
    const [showLookup, setShowLookup] = React.useState(true);
    const [showLogSplit, setShowLogSplit] = React.useState(false);
    const [showRestart, setShowRestart] = React.useState(true);
    const [showReprint, setShowReprint] = React.useState(true);
    const [showAccept, setShowAccept] = React.useState(false);
    const [showOptions, setShowOptions] = React.useState(false);
    const [showFirstWeighTable, setShowFirstWeighTable] = React.useState(false);
    const [showCameras, setShowCameras] = React.useState(false);
    const loadTypes = [{ label: "STANDARD" }, { label: "M3" }, { label: "STOREDTARE" }, { label: "FIRST" }, { label: "SECOND" }, { label: "REGO" }, { label: "MIXED" }, { label: "VISITOR" }, { label: "SIMPLE" }];
    const [showLoadType, setShowLoadType] = React.useState(true);
    const [loadTypeDisabled, setLoadTypeDisabled] = React.useState(false);
    const [showTraffic, setShowTraffic] = React.useState(true);
    const displayingSplitWeight = React.useRef(false);
    const showAllJobs = React.useRef(false);
    const overloadOverride = React.useRef(false);
    const truckJobs = React.useRef([]);
    const dialogCb = React.useRef(null);
    const regoFound = React.useRef(false);
    const mode = React.useRef("normal");
    const NA = { "id": "1", "name": "NA" };
    const [options, setOptions] = React.useState({
        jobs: [NA],
        products: [NA],
        customers: [NA],
        sources: [NA],
        destinations: [NA],
        truckConfigs: [NA],
        selectedJob: NA,
        selectedProduct: NA,
        selectedCustomer: NA,
        selectedSource: NA,
        selectedDestination: NA,
        selectedTruckConfig: NA,
        loadType: "",
        deliveryAddress: "",
        haulage: 0
    });
    const [streamUrl, setStreamUrl] = React.useState();
    const [signature, setSignature] = React.useState(); // signature businessdata object
    const [showHidProcess, setShowHidProcess] = React.useState(false);
    const [showTruckConfig, setShowTruckConfig] = React.useState(false);
    const [showSignaturePad, setShowSignaturePad] = React.useState(false); // display signature pad on screeen
    const [useTouchScreenSignature, setUseTouchScreenSignature] = React.useState(true); // use the kiosk touchscreen rather than Topaz
    const [voucher, setVoucher] = React.useState("");
    const serverData = React.useRef();
    const weightStatus = React.useRef();
    const prompt = React.useRef("");
    const product = React.useRef();
    const [activeWeighbridge, setActiveWeighbridge] = React.useState("");
    const [activeScales, setActiveScales] = React.useState();
    const defaultProduct = React.useRef(); // default values for products from product NA(1)
    const activeScale = React.useRef();
    const [scaleDisabled, setScaleDisabled] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [updateFirstWeighs, setUpdateFirstWeighs] = React.useState(new Date());
    const [fwClass, setFwClass] = React.useState("");
    const transactionWait = React.useRef({ "ready": true, "timer": null }); // data to delay start of transaction until previous truck leaves

    // item selector
    const [showItemSelector, setShowItemSelector] = React.useState(false);
    const [itemSelectorOptions, setItemSelectorOptions] = React.useState([]);
    const [itemSelectorCallback, setItemSelectorCallback] = React.useState();
    const [itemSelectorType, setItemSelectorType] = React.useState("");
    const [itemSelectorShowCodes, setItemSelectorShowCodes] = React.useState(false);
    const [itemSelectorLayout, setItemSelectorLayout] = React.useState("default");
    const [itemShowKeyboard, setItemShowKeyboard] = React.useState(true);

    // mixed products
    const [showMixedProducts, setShowMixedProducts] = React.useState(false);

    // eftpos
    const [eftposCommand, setEftposCommand] = React.useState();
    const [eftposData, setEftposData] = React.useState();

    // docket preview
    const previewOn = React.useRef(false);
    const [showPreview, setShowPreview] = React.useState(previewOn.current);
    const [transaction, setTransaction] = React.useState(); // DTO.Transaction data
    const [transInfo, setTransInfo] = React.useState(); // transaction info, eg rego and tares

    // virtual keyboard text entry
    const [textEntryType, setTextEntryType] = React.useState("");
    const [textEntryInitial, setTextEntryInitial] = React.useState("");
    const [textEntryExtra, setTextEntryExtra] = React.useState("");
    const [textEntryExtra2, setTextEntryExtra2] = React.useState("");
    const [jobTonnage, setJobTonnage] = React.useState();
    const cloudStatus = React.useRef(false);
    const cloudCreds = React.useRef();
    var running = 0;
    const automatedTimer = React.useRef(null);
    const bdTimer = React.useRef(null);
    const dialogTimer = React.useRef(null);

    const lastRefreshed = React.useRef(0);

    const [layout, setLayout] = React.useState([
        { "field": "splitWeigh", "label": "Split Weigh", "isVisible": true, },
        { "field": "job", "label": "Job", "isVisible": true, },
        { "field": "product", "label": "Product", "isVisible": true, },
        { "field": "customer", "label": "Customer", "isVisible": true, },
        { "field": "destination", "label": "Destination", "isVisible": true, },
        { "field": "source", "label": "Source", "isVisible": true, },
        { "field": "truckConfig", "label": "Truck Config", "isVisible": true, },
        { "field": "deliveryAddress", "label": "Delivery Address", "isVisible": true, },
        { "field": "haulage", "label": "Haulage", "isVisible": true, },
        { "field": "loadType", "label": "Load Type", "isVisible": true, },
        { "field": "standard", "label": "Standard", "isVisible": true, },
        { "field": "m3", "label": "m3", "isVisible": true, },
        { "field": "stored", "label": "Stored", "isVisible": true, },
        { "field": "first", "label": "First", "isVisible": true, },
        { "field": "second", "label": "Second", "isVisible": true, },
        { "field": "rego", "label": "Rego", "isVisible": true, },
        { "field": "mixed", "label": "Mixed", "isVisible": true, },
        { "field": "visitor", "label": "Visitor", "isVisible": true, },
        { "field": "simple", "label": "Simple", "isVisible": true, },
        { "field": "lookup1st", "label": "Lookup 1st Weight", "isVisible": true, },
        { "field": "logSplit", "label": "Log Split Weight", "isVisible": true, },
        { "field": "restart", "label": "Restart", "isVisible": true, },
        { "field": "reprint", "label": "Reprint", "isVisible": true, },
        { "field": "accept", "label": "Accept", "isVisible": true, },
        { "field": "tare", "label": "Tare", "isVisible": true, },
        { "field": "paymentType", "label": "Payment Type", "isVisible": true, },
        { "field": "direction", "label": "Goods Direction", "isVisible": true, },
        { "field": "chargeRate", "label": "Charge Rate", "isVisible": true, },
        { "field": "vehicleOwner", "label": "Vehicle Owner", "isVisible": true, },
        { "field": "delivery", "label": "Delivery Fee (Ex GST)", "isVisible": true, },
        { "field": "fee", "label": "Penalty Fee (Ex GST)", "isVisible": true, },
        { "field": "comment", "label": "Comment", "isVisible": true, },
        { "field": "print", "label": "Print Docket", "isVisible": true, },
        { "field": "back", "label": "Back", "isVisible": true, },
        { "field": "clearSignature", "Clear": "Accept", "isVisible": true, },
        { "field": "saveSignature", "Save": "Accept", "isVisible": true, },
        { "field": "cash", "label": "Cash", "isVisible": true, },
        { "field": "account", "label": "Account", "isVisible": true, },
        { "field": "cheque", "label": "Cheque", "isVisible": true, },
        { "field": "eftpos", "label": "EFTPOS", "isVisible": true, },
        { "field": "creditCard", "label": "Credit Card", "isVisible": true, },
        { "field": "voucher", "label": "Voucher", "isVisible": true, },
        { "field": "owing", "label": "Amount Owing", "isVisible": true, },
        { "field": "tendered", "label": "Amount Tendered", "isVisible": true, },
        { "field": "change", "label": "Change", "isVisible": true, },
        { "field": "paymentSubtotal", "label": "Subtotal", "isVisible": true, },
        { "field": "paymentFees", "label": "Fees", "isVisible": true, },
        { "field": "paymentDiscounts", "label": "Discounts", "isVisible": true, },
        { "field": "paymentOwing", "label": "Owing", "isVisible": true, },
    ]);

    React.useEffect(() => {
        let url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'WeighBridge'";
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        fetch(url, init).then(response => response.json()).then(d => {
            setLayout(d);
        }).catch((error) => {
            console.log("User Interfaces fetch error: " + error.message);
        });
        url = `${configData.SERVER_URL}apiv7/sites/${configData.SITE}`;
        fetch(url, init).then(response => response.json()).then(d => {
            site.current = d;
        }).catch((error) => {
            console.log("Sites fetch error: " + error.message);
        });
        url = `${configData.SERVER_URL}apiv7/private/getsitesettings?siteId=${configData.SITE}`;
        fetch(url, init).then(response => response.json()).then(d => {
            if (d.length > 0) {
                settings.current = d;
                mode.current = String(GetRawSetting(d, "Mode")).toLowerCase();
                wbSettings.current = {
                    Decks: GetRawSetting(d, "IsMultiDeckWeighbridge") ? GetRawSetting(d, "NoOfDecks") : 1,
                    MaxWeight: GetRawSetting(d, "MaximumWeight")
                };
                if (GetRawSetting(d, "ShowFirstWeighTable")) setShowFirstWeighTable(true);
                setItemSelectorShowCodes(GetRawSetting(d, "ItemSelectorShowCodes"));
                setShowReprint(GetRawSetting(d, "UseReprintMenu"));
                setShowLookup(!GetRawSetting(d, "HideLookup1stWeigh"));
                setShowCameras(!GetRawSetting(d, "HideSurveillanceCameras"));
                setShowKeyboardIcon(GetRawSetting(d, "ShowKeyboardButtonforRego"));
                setShowLoadType(GetRawSetting(d, "AllowLoadTypeSelection"));
                setShowHidProcess(GetRawSetting(d, "InitiateWeighingProcessByDriverWithHID"));
                setLoadType(DefaultLoadType(d));
                if (GetRawSetting(d, "HideSurveillanceCameras")) {
                    $("#RegoDiv").addClass("RegoNoCameras");
                    $(".loadTypesDiv").addClass("LoadTypeNoCameras");
                    setFwClass("FirstWeighNoCameras");
                }
            }
        }).catch((error) => {
            console.log("Get site settings fetch error: " + error.message);
        });
        url = `${configData.SERVER_URL}clientapi/weighbridge/getregos`;
        fetch(url, init).then(response => response.json()).then(d => {
            regoList.current = d;
        });
        url = `${configData.SERVER_URL}apiv7/options?userId=${GetUserId()}`;
        fetch(url, init).then(response => response.json()).then(d => {
            if (GetSetting(settings, "DisplaySuppliersAndCustomers")){
                let c = [...d.customers];
                d.suppliers.map((x) => {
                    c.push({ id: x.id, name: "Sup: " + x.name, data: x.data });
                });
                d.customers = c;
            }
            allOptions.current = d;
            UseAllOptions(d);
        });
        url = `${configData.SERVER_URL}apiv7/products/1`;
        fetch(url, init).then(response => response.json()).then(d => {
            defaultProduct.current = d;
            product.current = d;
        });
        GetCloudLogin((cred) => {
            cloudCreds.current = cred;
        });
        if (running == 0) {
            require('./WeighBridge.scss');
            running = 1;
            trans.current = CreateNewTransaction(settings.current);
            GetBusinessData();
            GenerateStateEvent("Reset", "");
        }
        return () => { // kill any times when leaving page
            if (automatedTimer.current !== null) clearTimeout(automatedTimer.current); 
            if (bdTimer.current !== null) clearTimeout(bdTimer.current); 
            if (dialogTimer.current !== null) clearTimeout(dialogTimer.current); 
        }
    }, []);

    const UseAllOptions = (d) => {
        let o = { ...options };
        o.jobs = d.jobs;
        o.products = d.products;
        o.customers = d.customers;
        o.destinations = d.destinations;
        o.sources = d.sources;
        o.truckConfigs = d.vehicles;
        setOptions(o);
        //        TestItemSelector(d);
    }

    const GetBusinessData = () => {
        var url = `${configData.SERVER_URL}apiv7/businessdata?prm=wb&$filter=isActive eq true and siteId eq ${configData.SITE}&$orderby=prompt`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (businessData.current === null) {
                let act = GetActiveScales(d);
                if (act.length > 1 && GetSetting(settings, "UseTwoScaleOption")) {
                    setActiveScales(act);
                    setActiveWeighbridge(act[0]);
                    activeScale.current = act[0];
                }
            }
            let old = JSON.stringify(businessData.current);
            let upd = JSON.stringify(d);
            if (old != upd || new Date().getTime() > lastRefreshed.current + 30000) {
                businessData.current = d;
                lastRefreshed.current = new Date().getTime();
                let ws = UpdateWeights(d, weights, wbSettings.current.Decks, activeScale.current);
                if (displayingSplitWeight.current) {
                    weight.current[0] = ws.weights[0];
                } else {
                    weight.current = ws.weights;
                }
                setWeights(weight.current);
                let w = ws.weights;
                if (!transactionWait.current.ready && w[0] <= GetSetting(settings, "MinimumWeight") && transactionWait.current.timer === null) {
                    transactionWait.current.timer = setTimeout(() => {
                        transactionWait.current.timer = null;
                        transactionWait.current.ready = true;
                    }, GetSetting(settings, "TransactionWaitTime"));
                }
                weightStatus.current = ws.status;
                if (w !== undefined && !previewOn.current) {
                    let lt = trans.current.loadType;
                    let sh = (w[0] >= GetSetting(settings, "MinimumWeight")) && weightStatus.current.IsStable && currentRego.current !== "" && lt !== "";
                    if (sh && IsAutomated(mode.current) && w[0] > 0) automatedTimer.current = setTimeout(AcceptClicked(), 1000);
                    if (lt === "COUNTED" || lt === "VISITOR" || lt === "STANDARD") sh = true;
                    if (lt === "COUNTED" && (trans.current.selectedMixedProducts === undefined || trans.current.selectedMixedProducts.length < 1)) sh = false;
                    if (lt === "MIXED" && (trans.current.selectedMixedProducts === undefined || trans.current.selectedMixedProducts.length < 1 || trans.current.productId < 1)) sh = false;
                    setShowAccept(sh);
                    if (lt === "COUNTED" || lt === "MIXED" || lt === "SIMPLE" || lt === "REGO" || lt === "VISITOR" || lt === "STANDARD") {
                        setShowFirstWeighTable(false);
                        setShowLookup(false);
                        setShowOptions(false)
                    } else {
                        setShowLogSplit(sh && (wbSettings.current.Decks === 1) && displayingSplitWeight.current);
                        setShowOptions(sh && GetSetting(settings, "EditDataInClientApp"));
                    }
                    var shMixed = lt === "MIXED" || lt === "COUNTED";
                    if (shMixed !== showMixedProducts) setShowMixedProducts(shMixed);
                }
                if (w !== undefined && serverData.current !== undefined) {
                    let max = GetSetting(settings, "MaximumWeight");
                    if (serverData.current.truck !== undefined && serverData.current.truck !== null && serverData.current.truck.maxLoad > 0) max = serverData.current.truck.maxLoad;
                    if (GetSetting(settings, "EnableOverloadTransaction")) max = (1 + GetSetting(settings, "MaxLoadExceedAllowance") / 100) * max;
                    if (w[0] > max) {
                        let msg = "";
                        let rmt = "";
                        if (serverData.current.truck.tare === 0 && serverData.current.truck.loadType.toUpperCase() === "STOREDTARE") {
                            msg = "The selected vehicle stored tare is zero in the system. Cannot proceed with the transaction.";
                            trans.current.eventType = 6;
                            trans.current.eventLogError = "Stored Tare Transaction Error";
                            rmt = "Leave";
                        } else {
                            let over = Math.round((serverData.current.truck.maxLoad - w[0]) * 100) / 100;
                            msg = `Vehicle Maximum Exceeded. You must tip off. <br/>Maximum truck load is ${serverData.current.truck.maxLoad}t, overloaded by ${Math.abs(over)}t.`;
                            trans.current.eventType = 7;
                            trans.current.eventLogError = "Overload";
                            rmt = "TipOff";
                        }
                        ShowError(msg);
                        trans.current.eventLogDescription = msg;
                        PostEventLog(trans.current, GetOnlineToken());
                        GenerateStateEvent("Reset", rmt);
                        Restart();
                        SetBusinessDataTimer();
                        return false;
                    }
                }
                setLights(UpdateLights(d));
                setDisplay(UpdateRemoteDisplay(d));
                if (currentRego.current === "" && !trans.current.suppressScanning && transactionWait.current.ready) CheckScanners(d);
                setCameraImages(UpdateCameras(d, settings));
                setEftposData(GetEftpos(d));
                let sig = GetSignature(d);
                if (sig !== undefined) {
                    setSignature(sig);
                }
                if (GetSetting(settings, "HideSurveillanceCameras")) {
                    $("#RegoDiv").addClass("RegoNoCameras");
                    $(".loadTypesDiv").addClass("LoadTypeNoCameras");
                    setFwClass("FirstWeighNoCameras");
                }

            }
            SetBusinessDataTimer();
        }).catch((error) => {
            console.log("GetBusinessData fetch error: " + error.message);
            SetBusinessDataTimer();
        });
    }

    const SetBusinessDataTimer = () => {
        bdTimer.current = setTimeout(() => GetBusinessData(), 500);
    }

    const CheckScanners = (d) => {
        let sc = d.find(obj => (obj.dataType === "hidscanner" || obj.dataType === "ibutton" || obj.dataType === "anpr" || obj.dataType === "rfid") && obj.value !== "");
        if (sc === undefined) return;

        let init = {
            method: 'PUT',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        let url = `${configData.SERVER_URL}clientapi/device/putcommand?businessdataid=${sc.businessDataId}&command=Clear`;
        fetch(url, init).catch((error) => {
            console.log("Check scanners PUT fetch error: " + error.message);
        });
        init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        if (GetSetting(settings, "InitiateWeighingProcessByDriverWithHID")) {
            let url = `${configData.SERVER_URL}apiv7/drivers?$filter=tagid eq '${sc.value}'`;
            fetch(url, init).then(response => response.json()).then(d => {
                if (d.length === 0) {
                    setDialogError("Your card number not present/in-active in the system. Please check  with site admin.");
                    return;
                }
                trans.current.driverId = d[0].driverId;
                PromptForText("Plant Number", "Hi " + d[0].name);
            }).catch((error) => {
                console.log("Check scanners driver fetch error: " + error.message);
            });

        } else {
            if (sc.dataType.toLowerCase() === "anpr") {
                setRego(sc.value);
                if (GetSetting(settings, "PromptToConfirmRego")) {
                    trans.current.stateEvent = sc.stateEvent;
                    ShowDialog(`Is ${rego} the correct registration`, RegoConfirmed, "Confirm Registration");
                } else {
                    trans.current.suppressScanning = true;
                    CheckRego(sc.value, sc.stateEvent);
                }
                return;
            }
            url = `${configData.SERVER_URL}apiv7/trucks?$filter=TagId eq '${sc.value}'`;
            fetch(url, init).then(response => response.json()).then(d => {
                let rego = "";
                if (d.length > 0) rego = d[0].name;
                setRego(rego);
                if (GetSetting(settings, "PromptToConfirmRego")) {
                    trans.current.stateEvent = sc.stateEvent;
                    ShowDialog(`Is ${rego} the correct registration`, RegoConfirmed, "Confirm Registration");
                } else {
                    trans.current.suppressScanning = true;
                    CheckRego(rego, sc.stateEvent);
                }
            }).catch((error) => {
                console.log("Check scanners truck get fetch error: " + error.message);
            });
        }
    }

    const RegoConfirmed = () => {
        setRegoDisabled(true);
        CheckRego(rego, trans.current.stateEvent);
    }

    const RegoChanged = (e) => {
        trans.current.suppressScanning = e.value !== "";
        setRego(e.value);
        CheckRego(e.value);
    }

    const FirstWeighSelected = (e) => {
        setLoadTypeDisabled(true);
        RegoChanged(e);
    }

    const CheckRego = (rego, event = "", ignore = false) => {
        if (regoList.current !== undefined && !regoList.current.includes(rego)) {
            if (rego === "") {
                UseAllOptions(allOptions.current);
                currentRego.current = "";
                if (trans.current !== null) trans.current.suppressScanning = false;
                regoFound.current = false;
                setShowAccept(false);
                setShowLogSplit(false);
                setShowOptions(false);
                setShowFirstWeighTable(GetSetting(settings, "ShowFirstWeighTable"));
                setShowLookup(!GetSetting(settings, "HideLookup1stWeigh"));
                setShowSplit(false);
                setLoadTypeDisabled(false);
                setLoadType("");
                trans.current.hasFirstWeigh = false;
                return;
            }
            let sh = (weights[0] >= GetSetting(settings, "MinimumWeight") && loadType !== "");
            setShowLogSplit(sh && (wbSettings.current.Decks === 1) && displayingSplitWeight.current);
            setShowAccept(sh);
            setShowOptions(loadType !== "STANDARD" && loadType !== "REGO" && GetSetting(settings, "EditDataInClientApp"));
            setShowFirstWeighTable(false);
            setShowSplit(true);
            ClearAllSelections();
            if (trans.current === null || trans.current === undefined) trans.current = CreateNewTransaction(settings);
        }
        currentRego.current = rego;
        var url = `${configData.SERVER_URL}clientapi/weighbridge/regochanged?rego=${rego}&stateevent=${event}&ignoreoffences=${ignore}`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            setShowFirstWeighTable(GetSetting(settings, "ShowFirstWeighTable"));
            setShowLookup(!GetSetting(settings, "HideLookup1stWeigh"));
            let sh = (weights[0] >= GetSetting(settings, "MinimumWeight"));
            setShowLogSplit(sh && (wbSettings.current.Decks === 1) && displayingSplitWeight.current);
            serverData.current = d;
            switch (d.result) {
                case 0: // truck found, no first weigh
                    if (String(d.truck.loadType).toUpperCase() === "STOREDTARE" && d.truck.tare === 0) {
                        GenerateStateEvent("ticketprinted", ""); // Open exit gate so truck can leave
                        let t = GetTransactionData(trans.current, site.current, settings, cloudStatus.current);
                        t.eventLogError = "Stored Tare Transaction Error";
                        t.eventLogDescription = "The selected vehicle has a zero stored tare in the system. Cannot proceed with the transaction.";
                        PostEventLog(t, GetOnlineToken());
                        setDialogError(t.eventLogDescription);
                    }
                    regoFound.current = true;
                    firstWeigh.current = null;
                    trans.current = CreateTransactionFromTruck(d.truck, settings);
                    setShowOptions(GetSetting(settings, "EditDataInClientApp"));
                    if (!GetSetting(settings, "IsDirectionBasedonTruckInOut")) trans.current.direction = "IN";
                    setShowFirstWeighTable(false);
                    trans.current.loadType = String(d.truck.loadType).toUpperCase();
                    setLoadType(String(d.truck.loadType).toUpperCase());
                    setShowLookup(false);
                    setShowAccept(sh);
                    SetTruck(d);
                    if (GetSetting(settings, "PromptCommentBeforeWeight"))
                        PromptForComment();
                    else {
                        if (IsAutomated(mode.current)) DoAutomated();
                    }
                    break;
                case 1: // first weigh found
                    SetFirstWeigh(d);
                    firstWeigh.current = d.firstWeigh;
                    regoFound.current = true;
                    trans.current = GetTransactionFromFirstWeigh(d.firstWeigh, settings);
                    setLoadType("SECOND");
                    trans.current.loadType = "Second";
                    setShowFirstWeighTable(false);
                    setShowLookup(false);
                    setShowAccept(sh);
                    if (GetSetting(settings, "PromptCommentBeforeWeight"))
                        PromptForComment();
                    else {
                        if (IsAutomated(mode.current)) DoAutomated();
                    }
                    break;
                case 3: // offence found
                    if (!GetSetting(settings, "OffOffenceCommentsAlert")) {
                        ShowDialog("<h3>This vehicle has offences:</h3><p>" + String(d.message).replace("\r\n", "<br/>") + "</p>", OffencesOk, "Are you sure you want to proceed for transaction?");
                    }
                    break;
                case 4:
                    ShowError("Unknown truck not permitted on weighbridge");
                    setRego("");
                    rego.current = "";
                    return;
                    break;
                default:
                    trans.current.registration1 = currentRego.current;
                    trans.current.tare = 0;
                    if (currentRego.current.length > 0) {
                        switch (loadType) {
                            case "STANDARD":
                            case "VISITOR":
                            case "COUNTED":
                                trans.current.loadType = loadType;
                                setShowFirstWeighTable(false);
                                setShowLookup(false);
                                setShowAccept(loadType !== "COUNTED" || (trans.current.selectedMixedProducts !== null && trans.current.selectedMixedProducts.length > 0));
                                setShowOptions(false);
                                break;
                            case "REGO":
                            case "MIXED":
                                trans.current.loadType = loadType;
                                if (sh) {
                                    setShowFirstWeighTable(false);
                                    setShowLookup(false);
                                    setShowAccept(true);
                                    setShowOptions(false);
                                }
                                break;
                            default:
                                setLoadType("FIRST");
                                trans.current.loadType = "FIRST";
                                break;
                        }
                        setShowMixedProducts((loadType === "MIXED" && sh) || loadType === "COUNTED");
                    } else {
                        setLoadType("");
                    }
                    break;
            }
            if (d.docketNo !== undefined) {
                trans.current.docket = String(d.docketNo);
                setDocket("Docket: " + d.docketNo);
            }
        }).catch((error) => {
            console.log("Check rego fetch error: " + error.message);
        });
    }

    const SetFirstWeigh = (f) => {
        var o = { ...options };
        var fw = f.firstWeigh;
        o.loadType = "SECOND";
        if (fw.jobId > 1) {
            SetControlsForJob(fw.jobId, f.name);
        } else {
            o.customers = allOptions.current.customers;
            o.destinations = allOptions.current.destinations;
            truckJobs.current = f.jobs;
            o.jobs = showAllJobs.current ? allOptions.current.jobs : f.jobs;
            o.products = allOptions.current.products;
            o.sources = allOptions.current.sources;
            o.truckConfigs = allOptions.current.vehicleConfigurations;
            o.selectedCustomer = f.selectedCustomer;
            o.selectedDestination = f.selectedDestination;
            o.selectedJob = f.selectedJob;
            o.selectedProduct = f.selectedProduct;
            o.selectedSource = f.selectedSource;
            o.selectedTruckConfig = f.selectedVehicleConfiguration;
            o.deliveryAddress = "";
        }
        setOptions(o);
        if (weights[0] > GetSetting(settings, "MinimumWeight")) {
            setShowOptions(GetSetting(settings, "EditDataInClientApp"));
            setShowLookup(false);
            setShowLogSplit(wbSettings.current.Decks === 1 && displayingSplitWeight.current);
            setShowAccept(true);
        }
    }

    const SetTruck = (f) => {
        var o = { ...options };
        o.loadType = f.truck.loadType;
        o.customers = f.customers;
        o.destinations = f.destinations;
        truckJobs.current = f.jobs;
        if (f.selectedJob !== null && f.selectedJob.id !== "1") {
            o.selectedJob = f.selectedJob;
            trans.current.jobId = parseInt(o.selectedJob.id);
            SetControlsForJob(parseInt(o.selectedJob.id), f.truck.name);
        } else {
            // only set values for each entity if selected value is not 1. If not the MUSTENTER options won't work
            o.jobs = showAllJobs.current ? allOptions.current.jobs : f.jobs;
            o.products = f.products;
            o.sources = f.sources;
            o.truckConfigs = f.vehicleConfigurations;
            if (f.selectedCustomer !== null && f.selectedCustomer.id !== "1") {
                o.selectedCustomer = f.selectedCustomer;
                trans.current.customerId = parseInt(f.selectedCustomer.id);
            }
            if (f.selectedDestination !== null && f.selectedDestination.id !== "1") {
                o.selectedDestination = f.selectedDestination;
                trans.current.destinationId = parseInt(f.selectedDestination.id);
            }
            if (f.selectedSource !== null && f.selectedSource.id !== "1") {
                o.selectedSource = f.selectedSource;
                trans.current.sourceId = parseInt(f.selectedSource.id);
            }
            if (f.selectedProduct !== null && f.selectedProduct.id !== "1") {
                o.selectedProduct = f.selectedProduct;
                trans.current.productId = parseInt(f.selectedProduct.id);
            }
            if (f.selectedProductCategory !== null) trans.current.productCategoryId = parseInt(f.selectedProductCategory.id);
            if (f.selectedVehicleConfiguration !== null && f.selectedVehicleConfiguration.id !== "1") {
                o.selectedTruckConfig = f.selectedVehicleConfiguration;
                trans.current.vehicleConfigurationId = parseInt(f.selectedVehicleConfiguration.id);
            }
            setOptions(o);
        }
        if (weights[0] > GetSetting(settings, "MinimumWeight") && loadType !== "") {
            setShowOptions(GetSetting(settings, "EditDataInClientApp"));
            setShowLookup(false);
            setShowLogSplit(wbSettings.current.Decks === 1 && displayingSplitWeight.current);
            setShowAccept(true);
        }
    }

    const OffencesOk = () => {
        CheckRego(currentRego.current, "", true);
    }

    const LookupFirstClicked = () => {
        var url = `${configData.SERVER_URL}apiv7/FirstWeighs?$orderby=tareindate desc`;
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        fetch(url, init).then(response => response.json()).then(d => {
            let f = [];
            d.map(x => {
                f.push({ code: x.name, name: x.name });
            });
            setItemSelectorShowCodes(false);
            setItemSelectorLayout("default");
            ShowItemSelector(f, () => FirstWeightSelected, "First Weigh");
        }).catch((error) => {
            console.log("Lookup first fetch error: " + error.message);
        });
    }

    const FirstWeightSelected = (f, offence) => {
        setShowItemSelector(false);
        setLoadTypeDisabled(true);
        if (!f.startsWith("***")) {
            if (offence !== undefined && offence !== "") {
                CreateOffence(f, offence, configData.SITE);
                setRego("");
                CheckRego("");
            } else {
                setRego(f);
                CheckRego(f);
            }
        }
    }

    const LogSplitClicked = () => {
        if (currentSplit.current > 5) return;
        if (currentSplit.current === 0 && GetSetting(settings, "OpenExitOnSplit")) GenerateStateEvent("liftboomgate", "");
        currentSplit.current++;
        weight.current[currentSplit.current] = weight.current[0];
        setWeights(weight.current);
    }

    const AcceptClicked = () => {
        let weighed = IsWeighedLoad(loadType);
        if (weight.current[0] === 0 && !IsWeighedLoad) return;
        let w = weight.current;
        if (weighed) {
            let r = CheckLoadAgainstTare(serverData.current, w[0]);
            if (r !== "") {
                ShowError(r);
                return;
            }
        }
        // capture tare on first weigh logic
        if (weighed) {
            if (GetSetting(settings, "IsCaptureMaxLoadonFirstWeigh")) {
                if (loadType === "FIRST" && trans.current.truckId <= 1 && trans.current.maxLoadEntered === 0) {
                    setItemSelectorLayout("numeric");
                    PromptForText("Maximum Load");
                    return;
                }
            }
        }

        if (GetSetting(settings, "UseCommentPad") && !GetSetting(settings, "PromptCommentBeforeWeight") && trans.current.comment === "") {
            PromptForComment();
            return;
        }

        if (!CheckStoredTare()) return;

        // overload logic
        if (weighed && !CheckOverload()) return;

        if (trans.current.orderNumberRequired && trans.current.orderNumber === "") {
            if (options.selectedJob !== undefined && options.selectedJob.id !== "1" && !trans.current.orderNumberRequiredPerTxn) {
                if (!(loadType === "FIRST" && GetSetting(settings, "IgnoreFirstWeighEntityPrompts"))) {
                    PromptForText("Order Number");
                    return;
                }
            } else {
                if (options.selectedJob !== undefined && options.selectedJob.data !== "") {
                    trans.current.orderNumber = options.selectedJob.data;
                } else {
                    if (!(loadType === "FIRST" && GetSetting(settings, "IgnoreFirstWeighEntityPrompts"))) {
                        PromptForText("Order Number");
                        return;
                    }
                }
            }
        }

        // job tonnage tracker
        if (GetSetting(settings, "EnableJobTonnageTracker") && trans.current.jobId > 1 && trans.current.tonnageConfirmed < 2 && (trans.current.loadType === "SECOND" || trans.current.loadType === "STOREDTARE")) {
            if (jobOptions.current.prices !== undefined && jobOptions.current.prices.length > 0) {
                let p = jobOptions.current.prices.find(obj => obj.productId === trans.current.productId);
                if (p !== undefined && p.tonnesOrdered > 0) {
                    let left = p.tonnesOrdered - p.tonnesProcessed;
                    if (left < p.tonnesOrdered * 0.1 && trans.current.tonnageConfirmed < 1) { // less than 10% remaining for product on job
                        ShowDialog(`Only ${left}t left, which will be less than 10% of order quota. Do you want to continue?`, TonnageYes);
                        return;
                    }
                    let jt = {
                        "jppId": p.jobProductPriceId,
                        "price": p.price,
                        "jobName": jobOptions.current.selectedJob.name,
                        "productName": jobOptions.current.selectedProduct.name,
                        "tonnesOrdered": p.tonnesOrdered,
                        "tonnesProcessed": p.tonnesProcessed,
                        "tonnesLeft": left,
                        "weight": weights[0]
                    }
                    setJobTonnage(jt);
                    return;
                }
            }
        }

        // trailer confirmation
        if (GetSetting(settings, "EnableTrailersConfirmation") && trans.current.loadType !== "SECOND") {
            if (trans.current.trailersConfirmed < 1) {
                PromptForText("Trailer 1 Rego", trans.current.registration2);
                return;
            }
            if (trans.current.trailersConfirmed < 2) {
                PromptForText("Trailer 2 Rego", trans.current.registration3);
                return;
            }
        }

        if (GetSetting(settings, "EditDataInClientApp") && trans.current.loadType !== "MIXED") {
            trans.current.productId = parseInt(options.selectedProduct.id);
            trans.current.customerId = parseInt(options.selectedCustomer.id);
            trans.current.destinationId = parseInt(options.selectedDestination.id);
            trans.current.sourceId = parseInt(options.selectedSource.id);
            trans.current.jobId = parseInt(options.selectedJob.id);
            //trans.current.productCategoryId = parseInt(options.selectedProductCategory.id);
            trans.current.vehicleId = 1;
            trans.current.vehicleConfigurationId = parseInt(options.selectedTruckConfig === null ? 1 : options.selectedTruckConfig.id);
        }
        trans.current.loadType = loadType;
        trans.current.gross = w[0];
        trans.current.gross1 = w[1];
        trans.current.gross2 = w[2];
        trans.current.gross3 = w[3];
        trans.current.gross4 = w[4];
        trans.current.gross5 = w[5];
        if (loadType.toLowerCase() === "first") {
            trans.current.tare = w[0];
            trans.current.tare1 = w[1];
            trans.current.tare2 = w[2];
            trans.current.tare3 = w[3];
            trans.current.tare4 = w[4];
            trans.current.tare5 = w[5];
        }
        trans.current.payments = GetSetting(settings, "PaymentDefaultOption");
        if (trans.current.productId > 0) RecalculatePrice();
        prompt.current = "";
        jobOptions.current = options;
        setScaleDisabled(true);
        if (GetSetting(settings, "InitiateWeighingProcessByDriverWithHID")) {
            PreviewClosed(true);
            return;
        }
        switch (loadType) {
            case "REGO":
                PromptForText("VIN");
                break;
            default:
                DisplayNextPrompt("");
                break;
        }
    }

    const TonnageYes = () => {
        trans.current.tonnageConfirmed = 1;
        AcceptClicked();
    }

    const JobTonnageCB = (res) => {
        setJobTonnage(undefined);
        if (res !== undefined) {
            trans.current.tonnageConfirmed = 2;
            AcceptClicked();
        }
    }

    const CheckStoredTare = () => {
        if (trans.current.loadType !== "STOREDTARE" || !GetSetting(settings, "CheckScaleWeightWithRegisterTare") || mode.current === "instructions") return true;
        if (weights[0] === 0 || weights[0] > trans.current.tare) return true;
        let msg = `Weight on Scale ${weights[0].toFixed(2)} is less than Register Tare - ${trans.current.tare.toFixed(2)} Truck Id = ${trans.current.truckId}, Product Id = ${trans.current.productId}`;
        trans.current.eventType = 6;
        trans.current.eventLogError = "Other";
        trans.current.eventLogDescription = msg;
        PostEventLog(trans.current, GetOnlineToken());
        let l = trans.current.tare - weights[0];
        msg = `Weight on Scale is less than Register Tare. Register load is ${trans.current.tare.toFixed(2)}t, less by ${l.toFixed(2)}t.`;
        ShowError(msg);
        Restart();
        return false;
    }

    const RecalculatePrice = () => {
        if (trans.current.loadType === "MIXED" && trans.current.selectedMixedProducts !== null) {
            let wp = trans.current.selectedMixedProducts.find(obj => obj.productId === trans.current.productId);
            if (wp !== undefined) wp.currentAmount = weights[0] - trans.current.tare;
        }
        let t = { ...trans.current };
        var url = `${configData.SERVER_URL}clientapi/weighbridge/getupdatedprices`;
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify(t)
        }
        fetch(url, init).then(response => response.json()).then(d => {
            trans.current = d;
            trans.current.transactionDate = new Date();
            if (d.tareInDate !== null) trans.current.tareInDate = new Date(d.tareInDate);
            if (trans.current.tareInDate === null && trans.current.loadType === "FIRST") trans.current.tareInDate = new Date();
            setTransaction(trans.current);
        }).catch((error) => {
            console.log("Recalculate price fetch error: " + error.message);
        });

    }

    // OperatorData and WeighbridgePanel post back control changes
    const ChildControlChanged = (name, value) => {
        var o = { ...options };
        switch (name) {
            case "Job":
                o.selectedJob = value.Job;
                SetControlsForJob(value.Job.id, rego);
                break;

            case "ShowAllJobs":
                showAllJobs.current = value;
                o.jobs = value ? allOptions.current.jobs : truckJobs.current;
                break;

            case "SplitWeight":
                displayingSplitWeight.current = value;
                let sh = (weights[0] >= GetSetting(settings, "MinimumWeight")) && rego !== "";
                setShowLogSplit(sh && (wbSettings.current.Decks === 1) && value);
                break;

            case "Product":
                o.selectedProduct = value.Product;
                var url = `${configData.SERVER_URL}apiv7/products/${value.Product.id}`;
                const init = {
                    method: 'GET',
                    accept: 'application/json',
                    headers: { "Authorization": "Bearer " + GetToken() }
                }
                fetch(url, init).then(response => response.json()).then(d => {
                    product.current = d;
                }).catch((error) => {
                    console.log("ChildControlChanged product fetch error: " + error.message);
                });
                break;
            case "Customer":
                o.selectedCustomer = value.Customer;
                let opt = JSON.parse(value.Customer.data);
                trans.current.orderNumberRequiredPerTxn = opt.RequiredPerTxn;
                trans.current.orderNumberRequired = opt.OrderNumberRequired;
                break;
            case "Source": o.selectedSource = value.Source; break;
            case "Destination": o.selectedDestination = value.Destination; break;
            case "TruckConfig": o.selectedTruckConfig = value.TruckConfig; break;
        }
        setOptions(o);
    }

    const ShowDialog = (message, onYes, title = "Please confirm") => {
        setDialogTitle(title);
        setDialogMessage(message);
        dialogCb.current = onYes;
        if (onYes !== null) {
            setDialogYesText("Yes");
            setDialogNoText("No");
        }
        else {
            setDialogYesText("");
            setDialogNoText("Ok");
        }
        if (IsAutomated(mode.current) || GetSetting(settings, "InitiateWeighingProcessByDriverWithHID")) {
            dialogTimer.current = setTimeout(() => { setDialogMessage(""); }, 10000);
        }
    }

    const DialogYes = () => {
        setDialogMessage("");
        if (dialogCb !== null) dialogCb.current();
    }
    const DialogNo = () => {
        setDialogMessage("");
    }

    const ShowError = (msg) => {
        setDialogError(msg);
        if (IsAutomated(mode.current) || GetSetting(settings, "InitiateWeighingProcessByDriverWithHID")) {
//            dialogTimer.current = setTimeout(() => { setDialogError(""); }, 10000);
        }
    }

    const EftposCallback = (r, e) => {
        setEftposCommand("");
        if (e)
            alert("Eftpos successful: " + r);
        else {
            if (String(r).toLowerCase().startsWith("error")) {
                alert(r);
            }
            else {
                alert("Eftpos failed");
            }
        }
    }

    const SetControlsForJob = (j, rego, cb = "") => {
        var url = `${configData.SERVER_URL}clientapi/weighbridge/jobchanged?jobid=${j}&rego=${rego}`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            let o = { ...options };
            o.products = d.products;
            o.productCategories = d.productCategories;
            o.customers = d.customers;
            o.destinations = d.destinations;
            o.sources = d.sources;
            o.truckConfigs = d.vehicleConfigurations;
            o.selectedJob = d.selectedJob;
            o.selectedCustomer = d.selectedCustomer;
            let opt = JSON.parse(d.selectedCustomer.data);
            trans.current.orderNumberRequiredPerTxn = opt.RequiredPerTxn;
            trans.current.orderNumberRequired = opt.OrderNumberRequired;
            trans.current.jobId = parseInt(d.selectedJob.id);
            if (firstWeigh.current !== undefined && firstWeigh.current !== null) {
                let v = o.customers.find(obj => { return obj.id === String(firstWeigh.current.customerId) });
                o.selectedCustomer = (v === undefined ? d.selectedCustomer : v);
                v = o.destinations.find(obj => { return obj.id === String(firstWeigh.current.destinationId) });
                o.selectedDestination = (v === undefined ? d.selectedDestination : v);
                v = o.products.find(obj => { return obj.id === String(firstWeigh.current.productId) });
                o.selectedProduct = (v === undefined ? d.selectedProduct : v);
                v = o.productCategories.find(obj => { return obj.id === String(firstWeigh.current.productCategoryId) });
                o.selectedProductCategory = (v === undefined ? d.selectedProductCategory : v);
                v = o.sources.find(obj => { return obj.id === String(firstWeigh.current.sourceId) });
                o.selectedSource = (v === undefined ? d.selectedSource : v);
            } else {
                o.selectedCustomer = d.selectedCustomer;
                o.selectedDestination = d.selectedDestination;
                o.selectedProduct = d.selectedProduct;
                o.selectedProductCategory = d.selectedProductCategory;
                o.selectedSource = d.selectedSource;
                if (GetSetting(settings, "MustInputProduct")) {
                    trans.current.productId = 0;
                } else {
                    trans.current.productId = parseInt(d.selectedProduct.id);
                }
                if (d.selectedCustomer !== null) trans.current.customerId = parseInt(d.selectedCustomer.id);
                if (d.selectedSource !== null) trans.current.sourceId = parseInt(d.selectedSource.id);
                if (d.selectedDestination !== null) trans.current.destinationId = parseInt(d.selectedDestination.id);
            }
            o.selectedTruckConfig = d.selectedVehicleConfiguration;
            o.deliveryAddress = d.deliveryAddress;
            o.haulage = d.haulage;
            setOptions(o);
            o.prices = d.prices;
            jobOptions.current = o;
            if (cb === "JobCallback") DisplayNextPrompt("JobCallback");
        }).catch((error) => {
            console.log("SetControlsForJob fetch error: " + error.message);
        });
    }

    const GetCustomerJobs = (c) => {
        var url = `${configData.SERVER_URL}apiv7/jobs?$filter=customerid eq ${c}`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            let j = [];
            d.map((x) => {
                j.push({ id: x.jobId, name: x.name });
            });
            jobOptions.current.jobs = j;
            DisplayNextPrompt("JobCallback");
        }).catch((error) => {
            console.log("GetCustomerJobs fetch error: " + error.message);
        });
    }

    const ShowStream = (s) => {
        let u = GetStreamingUrl(s, businessData.current);
        setStreamUrl(u);
    }

    const CloseStreaming = () => {
        setStreamUrl(undefined);
    }

    const ShowItemSelector = (options, callback, type, keyboard, hidecodes) => {
        setItemSelectorType(type);
        setShowItemSelector(true);
        setItemSelectorShowCodes(hidecodes !== true && GetSetting(settings, "ItemSelectorShowCodes"));
        setItemSelectorLayout(type === "First Weigh" ? "default" : "basic");
        setItemSelectorCallback(callback);
        setItemShowKeyboard(keyboard)
        setItemSelectorOptions(options);
    }

    const ToggleSlider = () => {
        setShowWeightSlider(!showWeightSlider);
    }

    const RegoFocus = () => {
        if (GetSetting(settings, "UseKeyboardforRego")) PromptForRego();
    }

    const PromptForRego = () => {
        PromptForText("Rego");
        setItemSelectorLayout("rego");
    }

    const TextEnteredCallback = (type, t) => {
        setTextEntryType("");
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() },
            cache: "no-cache"
        };
        let commTitle = GetSetting(settings, "CommentTitle");
        switch (type) {
            case "***CANCEL":
                CancelItemSelection();
                break;

            case "Rego":
                setRego(t);
                CheckRego(t);
                break;

            case "Trailer 1 Rego":
                trans.current.registration2 = t;
                trans.current.trailersConfirmed = 1;
                AcceptClicked();
                break;

            case "Trailer 2 Rego":
                trans.current.registration3 = t;
                trans.current.trailersConfirmed = 2;
                AcceptClicked();
                break;

            case "Maximum Load":
                trans.current.maxLoadEntered = parseFloat(t);
                AcceptClicked();
                break;

            case commTitle:
                trans.current.comment = t;
                if (IsAutomated(mode.current))
                    DoAutomated();
                break;

            case "Voucher":
                if (t == "") {
                    setDialogError("Voucher Cleared");
                    setVoucher("");
                    trans.current.voucher = "";
                    RecalculatePrice();
                    return;
                }
                var url = `${configData.SERVER_URL}apiv7/vouchers/checkvouchercode?code=${t}`;

                fetch(url, init).then(response => response.json()).then(d => {
                    if (d.status !== undefined && d.status >= 300) {
                        setDialogError("Voucher not found");
                        setVoucher("");
                    } else {
                        let dt = new Date();
                        let from = new Date(Date.parse("1/1/1"));
                        if (d.startDate !== null) from = new Date(Date.parse(d.startDate));
                        if (from > dt) {
                            setDialogError(`Voucher not valid until ${from.getDate()}/${from.getMonth()}/${from.getFullYear()}`);
                            setVoucher("");
                            return;
                        }
                        let todate = new Date(Date.parse("31/12/3000"));
                        if (d.endDate !== null) todate = new Date(Date.parse(d.endDate));
                        if (dt > todate) {
                            setDialogError("Voucher has expired");
                            setVoucher("");
                            return;
                        }
                        if (d.totalUses >= d.usesAllowed) {
                            setDialogError("Voucher has been used already");
                            setVoucher("");
                            return;
                        }
                        setVoucher(d);
                        trans.current.voucher = d.code;
                        RecalculatePrice();
                    }
                }).catch((error) => {
                    console.log("Check voucher coded fetch error: " + error.message);
                });
                break;

            case "Order Number":
                trans.current.orderNumber = t;
                AcceptClicked();
                break;
            case "VIN":
                trans.current.vinNumber = t;
                DisplayNextPrompt("");
                break;
            case "Visitor Name":
            case "Visitor Company":
            case "Visitor Phone":
                DisplayNextPrompt(t);
                break;

            case "Reason for Visit":
                trans.current.visitorReason = t;
                LogVisitor(trans.current, site.current);
                Restart();
                break;

            case "Plant Number":
                trans.current.registration1 = t;
                url = `${configData.SERVER_URL}apiv7/trucks?$filter=Name eq '${t}'`;
                fetch(url, init).then(response => response.json()).then(d => {
                    if (d.length === 0) {
                        trans.current.registration1 = "";
                        setDialogError(`The entered plant number ${t} not present in the system. Please check with site administrator.`);
                        return;
                    }
                    trans.current.maxLoad1 = d[0].maxLoad;
                    trans.current.maxLoad = d[0].maxLoad;
                    setShowTruckConfig(true);
                }).catch((error) => {
                    console.log("Lookup plant number fetch error: " + error.message);
                });
                break;

            case "Comment":
                trans.current.comment = t;
                AcceptClicked();
                break;

            case "Trailer Number":
                if (t === "***1") {
                    setShowTruckConfig(true);
                    return;
                }
                if (t === "***2") {
                    trans.current.registration2 = "";
                    trans.current.maxLoad2 = 0;
                    trans.current.maxLoad = trans.current.maxLoad1;
                    return;
                }
                trans.current.registration2 = t;
                url = `${configData.SERVER_URL}apiv7/trucks?$filter=Name eq '${t}'`;
                fetch(url, init).then(response => response.json()).then(d => {
                    if (d.length === 0) {
                        trans.current.registration2 = "";
                        setDialogError(`The entered trailer number ${t} not present in the system. Please check with site administrator.`);
                        return;
                    }
                    trans.current.maxLoad2 = d[0].maxLoad;
                    trans.current.maxLoad = trans.current.maxLoad1 + d[0].maxLoad;
                }).catch((error) => {
                    console.log("Lookup trailer number fetch error: " + error.message);
                });
                break;
        }
    }

    const LoadTypeChanged = (e) => {
        let id = String(e.target.innerText);
        if (id.length === 2 && id.startsWith("M")) id = "COUNTED";
        if (id === "STORED") id = "STOREDTARE";
        setLoadType(id);
        trans.current.loadType = id;
        switch (id) {
            case "STANDARD":
            case "COUNTED":
                setShowFirstWeighTable(false);
                setShowLookup(false);
                setShowOptions(false);
                var sh = currentRego.current.length > 0;
                if (id === "COUNTED" && (trans.current.selectedMixedProducts === null || trans.current.selectedMixedProducts.length === 0)) sh = false;
                setShowAccept(sh);
                setShowMixedProducts(id === "COUNTED");
                break;
            case "REGO":
            case "VISITOR":
            case "MIXED":
                setShowFirstWeighTable(false);
                setShowLookup(false);
                setShowOptions(false);
                var sh = currentRego.current.length > 0 && weight.current[0] >= GetSetting(settings, "MinimumWeight");
                setShowAccept(sh && (id !== "MIXED" || trans.current.productId > 0));
                setShowMixedProducts(id === "MIXED");
                break;
            case "SIMPLE":
                setShowFirstWeighTable(false);
                setShowLookup(false);
                trans.current.loadType = id;
                setShowOptions(false);
                setShowAccept(weight.current[0] >= GetSetting(settings, "MinimumWeight"));
                break;
            default:
                var sh = (weights[0] >= GetSetting(settings, "MinimumWeight")) && rego !== "";
                setShowLookup(false);
                setShowAccept(sh);
                setShowMixedProducts(false);
                break;
        }
    }

    const SetWeights = (w) => {
        weight.current[0] = w;
        setWeights(weight.current);
    }

    const RestartClicked = () => {
        GenerateStateEvent("Reset", "");
        Restart();
    }

    const Restart = () => {
        setShowPreview(false);
        currentRego.current = "";
        setRegoDisabled(false);
        firstWeigh.current = null;
        product.current = defaultProduct.current;
        previewOn.current = false;
        setShowRegoDiv(true);
        setShowRestart(true);
        setShowReprint(GetSetting(settings, "UseReprintMenu"));
        setShowLogSplit(false);
        setShowTraffic(true);
        setLoadTypeDisabled(false);
        setShowCameras(!GetSetting(settings, "HideSurveillanceCameras"));
        UseAllOptions(allOptions.current);
        setItemSelectorOptions([]);
        setRego("");
        setLoadType("");
        regoFound.current = false;
        setShowAccept(false);
        setShowLogSplit(false);
        setShowOptions(false);
        setActiveWeighbridge(activeWeighbridge);
        setShowMixedProducts(false);
        setShowLoadType(GetSetting(settings, "AllowLoadTypeSelection"));
        if (GetSetting(settings, "ShowFirstWeighTable")) {
            setShowFirstWeighTable(true);
            setUpdateFirstWeighs(new Date());
        }
        setShowLookup(!GetSetting(settings, "HideLookup1stWeigh"));
        currentSplit.current = 0;
        weight.current = [0, 0, 0, 0, 0, 0];
        setWeights(weight.current);
        overloadOverride.current = false;
        setScaleDisabled(false);
        trans.current = CreateNewTransaction(settings);
    }

    const ReprintClicked = () => {
        var url = `${configData.SERVER_URL}clientapi/weighbridge/reprintlast`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() },
            cache: "no-cache"
        };
        fetch(url, init).then(response => response.text()).then(d => {
            ShowDialog(d, null, "Docket Printed");
        }).catch((error) => {
            console.log("Reprint clicked fetch error: " + error.message);
        });
    }

    const DisplayNextPrompt = (result) => {
        // Displays the next prompt according to settings. If non remaining, display transaction preview
        // save result from current prompt
        // For jobs need to lookup values on server annd callback is required when date is retreived. Result will be set as "JobCallback" to re-enter business logic
        if (result === "***CANCEL") {
            CancelItemSelection();
            return;
        }
        setShowMixedProducts(false);
        if (result === "JobCallback") {
            result = "Job";
        } else {
            // save the result value in the transaction for the current prompt
            switch (prompt.current) {
                case "Job":
                    trans.current.jobId = result;
                    // For jobs, want to filter other inputs for job. This will call back into DisplayNextPrompt but skip this section
                    SetControlsForJob(result, rego, "JobCallback");
                    return;
                case "Product":
                case "AllProducts":
                    trans.current.productId = result; break;
                case "ProductCategory": trans.current.productCategoryId = result; break;
                case "Customer":
                    trans.current.customerId = result;
                    let cust = options.customers.find(obj => { return obj.id === String(result) });
                    if (cust !== undefined) {
                        let c = JSON.parse(cust.data);
                        trans.current.orderNumberRequired = c.OrderNumberRequired;
                        trans.current.orderNumberRequiredPerTxn = c.RequiredPerTxn;
                        if (c.OrderNumberRequired || c.RequiredPerTxn) {
                            PromptForText("Order Number");
                            return;
                        }
                    }
                    break;
                case "Destination": trans.current.destinationId = result; break;
                case "Source": trans.current.sourceId = result; break;
                case "TruckConfig": trans.current.vehicleConfigurationId = result; break;
                case "Vehicles": trans.current.vehicleId = result; break;
                case "VisitorType": trans.current.visitorType = result; break;
                case "VisitorName": trans.current.visitorName = result; break;
                case "VisitorCompany": trans.current.visitorOrg = result; break;
                case "VisitorContact": trans.current.visitorPhone = result; break;
                case "VisitorReason": trans.current.visitorReason = result; break;
            }
        }
        // Find the next data to display. 
        // If an option has already been selected skip that option.
        // If there is only one choice available, use that option and skip it
        let skip = true;
        let enterSecond = loadType == "SECOND" && GetSetting(settings, "IgnoreFirstWeighEntityPrompts");
        do {
            switch (loadType) {
                case "STANDARD": // standard does Vehicles, AllProducts then any MUST Enter prompts
                    switch (prompt.current) {
                        case "": prompt.current = "Vehicles"; break;
                        case "Vehicles": prompt.current = "AllProducts"; break;
                        case "AllProducts": prompt.current = GetNextPromptType(settings, "", trans.current.loadType); break;
                        default: prompt.current = GetNextPromptType(settings, prompt.current, trans.current.loadType); break;
                    }
                    break;
                case "VISITOR": prompt.current = GetNextVisitorPrompt(prompt.current); break;
                case "REGO": // Rego is all product then MUST Enter prompt
                    switch (prompt.current) {
                        case "": prompt.current = "AllProducts"; break;
                        case "AllProducts": prompt.current = GetNextPromptType(settings, "", trans.current.loadType); break;
                        default: prompt.current = GetNextPromptType(settings, prompt.current, trans.current.loadType); break;
                    }
                    break;
                case "MIXED":
                    prompt.current = GetNextPromptType(settings, prompt.current, trans.current.loadType);
                    if (prompt.current === "") prompt.current = result === "" ? "Vehicles" : "";
                    break;
                case "FIRST":
                    if (GetSetting(settings, "IgnoreFirstWeighEntityPrompts"))
                        prompt.current = "";
                    else
                        prompt.current = GetNextPromptType(settings, prompt.current, trans.current.loadType);
                    break;
                default: prompt.current = GetNextPromptType(settings, prompt.current, trans.current.loadType); break;

            }
            switch (prompt.current) {
                case "Job":
                    if (jobOptions.current.jobs === undefined || trans.current.jobId === 0 || (enterSecond && trans.current.jobId === 1)) {
                        if (jobOptions.current.jobs.length === 1) {
                            trans.current.jobId = jobOptions.current.jobs === undefined ? 1 : parseInt(jobOptions.current.jobs[0].id);
                        } else {
                            skip = false;
                        }
                    }
                    break;
                case "Product":
                    if (trans.current.productId === 0 || (enterSecond && trans.current.productId === 1)) {
                        if (jobOptions.current.products === undefined || jobOptions.current.products.length === 1) {
                            trans.current.productId = jobOptions.current.products === undefined ? 1 : parseInt(jobOptions.current.products[0].id);
                        } else {
                            skip = false;
                        }
                    }
                    break;
                case "ProductCategory":
                    if (trans.current.productCategoryId === 0 || (enterSecond && trans.current.productCategoryId === 1)) {
                        if (jobOptions.current.productCategories === undefined || jobOptions.current.productCategories.length === 1) {
                            trans.current.productCategoryId = jobOptions.current.productCategories === undefined ? 1 : parseInt(jobOptions.current.productCategories[0].id);
                        } else {
                            skip = false;
                        }
                    }
                    break;
                case "Customer":
                    if (trans.current.customerId === 0 || (enterSecond && trans.current.customerId === 1)) {
                        if (jobOptions.current.customers === undefined || jobOptions.current.customers.length === 1) {
                            trans.current.customerId = jobOptions.current.customers === undefined ? 1 : parseInt(jobOptions.current.customers[0].id);
                        } else {
                            skip = false;
                        }
                    }
                    break;
                case "Destination":
                    if (trans.current.destinationId === 0 || (enterSecond && trans.current.destinationId === 1)) {
                        if (jobOptions.current.destinations === undefined || jobOptions.current.destinations.length === 1) {
                            trans.current.destinationId = jobOptions.current.destinations === undefined ? 1 : parseInt(jobOptions.current.destinations[0].id);
                        } else {
                            skip = false;
                        }
                    }
                    break;
                case "Source":
                    if (trans.current.sourceId === 0 || (enterSecond && trans.current.sourceId === 1)) {
                        if (jobOptions.current.sources === undefined || jobOptions.current.sources.length === 1) {
                            trans.current.sourceId = jobOptions.current.sources === undefined ? 1 : parseInt(jobOptions.current.sources[0].id);
                        } else {
                            skip = false;
                        }
                    }
                    break;
                case "TruckConfig":
                    if (trans.current.vehicleConfigurationId === 0 || (enterSecond && trans.current.vehicleConfigurationId === 1)) {
                        if (jobOptions.current.truckConfigs === undefined || jobOptions.current.truckConfigs.length === 1) {
                            trans.current.vehicleConfigurationId = jobOptions.current.truckConfigs === undefined ? 1 : parseInt(jobOptions.current.truckConfigs[0].id);
                        } else {
                            skip = false;
                        }
                    }
                    break;
                case "Vehicles":
                case "AllProducts":
                case "VisitorType":
                case "VisitorName":
                case "VisitorCompany":
                case "VisitorContact":
                case "VisitorReason":
                    skip = false;
                    break;
                case "":
                    skip = false;
                    break;
            }
        } while (skip);
        // Display the next selection prompt
        switch (prompt.current) {
            case "": // nothing to select - jump to transaction preview
                setShowItemSelector(false);
                PreviewTransaction();
                return;
            case "Job":
                if (GetSetting(settings, "PromptCustomerFirstInDataSelection") && result !== "Job") {
                    // filter jobs by customer
                    prompt.current = "Customer";
                    GetCustomerJobs(result);
                } else {
                    // show all jobs
                    let j = jobOptions.current.jobs;
                    if (!GetSetting(settings, "ShowNAinJobPopupWindowList")) {
                        j = [];
                        jobOptions.current.jobs.map((x) => {
                            if (x.id !== "1") {
                                j.push(x);
                            }
                        });
                    }
                    ShowItemSelector(GetSelectionItems(j), () => DisplayNextPrompt, "Job"); 
                }
                break;
            case "Product": ShowItemSelector(GetSelectionItems(jobOptions.current.products), () => DisplayNextPrompt, "Product", true); break;
            case "ProductCategory": ShowItemSelector(GetSelectionItems(jobOptions.current.productCategories), () => DisplayNextPrompt, "ProductCategory", true); break;
            case "Customer": ShowItemSelector(GetSelectionItems(jobOptions.current.customers), () => DisplayNextPrompt, "Customer", true); break;
            case "Destination": ShowItemSelector(GetSelectionItems(jobOptions.current.destinations), () => DisplayNextPrompt, "Destination", true); break;
            case "Source": ShowItemSelector(GetSelectionItems(jobOptions.current.sources), () => DisplayNextPrompt, "Source", true); break;
            case "TruckConfig": ShowItemSelector(GetSelectionItems(jobOptions.current.truckConfigs), () => DisplayNextPrompt, "TruckConfig", true); break;
            case "Vehicles": ShowItemSelector(GetSelectionItems(allOptions.current.vehicles), () => DisplayNextPrompt, "Vehicles", true); break;
            case "AllProducts": ShowItemSelector(GetSelectionItems(allOptions.current.products), () => DisplayNextPrompt, "Product", true); break;
            case "VisitorType":
                let itms = [];
                itms.push({ code: "Visitor", name: "Visitor" });
                itms.push({ code: "Service Vehicle", name: "Service Vehicle" });
                ShowItemSelector(itms, () => DisplayNextPrompt, "Visitor Type", false, true); break;
            case "VisitorName": PromptForText("Visitor Name"); break;
            case "VisitorCompany": PromptForText("Visitor Company"); break;
            case "VisitorContact": PromptForText("Visitor Phone"); break;
            case "VisitorReason": PromptForText("Reason for Visit"); break;
        }
    }

    const CancelItemSelection = () => {
        setItemSelectorOptions([]);
    }

    const PreviewTransaction = () => {
        setShowRegoDiv(false);
        setShowOptions(false);
        setShowRestart(false);
        setShowReprint(false);
        setShowLogSplit(false);
        setShowLoadType(false);
        setShowSplit(false);
        setShowTraffic(false);
        setShowCameras(false);
        setShowFirstWeighTable(false);
        previewOn.current = true;
        setShowPreview(true);
        setTransaction(trans.current);
        let info = [];
        switch (trans.current.loadType) {
            case "STANDARD":
                let vn = allOptions.current.vehicles.find(obj => { return obj.id === String(trans.current.vehicleId) });
                info = [
                    { "name": GetField(layout, "loadType")["label"], "value": trans.current.loadType },
                    { "name": "Vehicle Type", "value": vn.name },
                    { "name": GetField(layout, "rego")["label"], "value": rego }
                ];
                break;
            case "MIXED":
            case "COUNTED":
                info = [
                    { "name": GetField(layout, "rego")["label"], "value": rego },
                    { "name": GetField(layout, "loadType")["label"], "value": trans.current.loadType },
                ];
                break;
            default:
                info = [
                    { "name": GetField(layout, "rego")["label"], "value": rego },
                    { "name": GetField(layout, "loadType")["label"], "value": trans.current.loadType },
                    { "name": GetField(layout, "tare")["label"] + " 1", "value": trans.current.tare1.toFixed(2) + "t" },
                    { "name": GetField(layout, "tare")["label"] + " 2", "value": trans.current.tare2.toFixed(2) + "t" },
                    { "name": GetField(layout, "tare")["label"] + " 3", "value": trans.current.tare3.toFixed(2) + "t" },
                    { "name": GetField(layout, "tare")["label"] + " 4", "value": trans.current.tare4.toFixed(2) + "t" },
                ];
                break;
        }
        setTransInfo(info);
    }

    // control changed on Preview page
    const PreviewSelection = (type, val) => {
        switch (type) {
            case "Payment":
                trans.current.payments = val;
                if (val.toLowerCase() === "voucher") {
                    PromptForText("Voucher");
                }
                break;
            case "Direction": trans.current.direction = val; break;
            case "Rate": trans.current.chargeRate = val; break;
            case "Owner": trans.current.vehicleOwner = val; break;
            case "Delivery": trans.current.cartageCharge = val; break;
            case "Penalty": trans.current.penaltyCharge = val; break;
            case "Comment": trans.current.comment = val; break;
        }
        RecalculatePrice();
    }

    const PromptForText = (t, def = "") => {
        setItemSelectorLayout("default");
        setTextEntryType(t);
        setTextEntryInitial(def);
        setTextEntryExtra("");
        setTextEntryExtra2("");
    }

    // Print docket or Back on preview screen
    const PreviewClosed = (save) => {
        if (GetSetting(settings, "MustEnterSignature")) {
            if (signature !== undefined && signature.value !== undefined) {
                if (signature.value == "") {
                    setDialogError("Please sign the docket");
                    return;
                }
            }
        }
        if (save) {
            let t = GetTransactionData(trans.current, site.current, settings, cloudStatus.current);
            PostTransaction(t, ResponseCallback, GetOnlineToken());
        } else {
            FinalizeTransaction();
        }
    }

    const ResponseCallback = (r) => {
        if (r.result === 0) {
            setMessage("Transaction Saved");
            let r = regoList.current.find(obj => { return obj === trans.current.registration1 });
            if (r === undefined) regoList.current.push(trans.current.registration1);
            setDocket("Last Docket: " + trans.current.docket);
            transactionWait.current.ready = false;
            Restart();
        } else {
            if (r.online) { // Save to cloud failed. Try saving locally
                let t = GetTransactionData(trans.current, site.current, settings, false);
                PostTransaction(t, ResponseCallback, "");
            } else {
                setMessage("ERROR");
                alert(r.message);
            }
        }
    }

    const FinalizeTransaction = () => {
        previewOn.current = false;
        setShowPreview(false);
        setShowRegoDiv(true);
        setShowRestart(true);
        setShowReprint(GetSetting(settings, "UseReprintMenu"));
        setShowLogSplit(false);
        setShowTraffic(true);
        setShowCameras(!GetSetting(settings, "HideSurveillanceCameras"));
        setShowSplit(true);
        setScaleDisabled(false);
        setShowLoadType(GetSetting(settings, "AllowLoadTypeSelection"));
        if (trans.current.loadType === "COUNTED" || trans.current.loadType === "MIXED")
            setShowMixedProducts(true);
        else
            setShowOptions(GetSetting(settings, "EditDataInClientApp"));
    }

    const ClearAllSelections = () => {
        let o = { ...options };
        o.customers = allOptions.current.customers;
        o.destinations = allOptions.current.destinations;
        truckJobs.current = allOptions.current.jobs;
        o.jobs = allOptions.current.jobs;
        o.products = allOptions.current.products;
        o.sources = allOptions.current.sources;
        o.truckConfigs = allOptions.current.vehicleConfigurations;
        o.selectedCustomer = {id : "0", name : "", data : ""};
        o.selectedDestination = { id: "0", name: "", data: "" };
        o.selectedJob = { id: "0", name: "", data: "" };
        o.selectedProduct = { id: "0", name: "", data: "" };
        o.selectedSource = { id: "0", name: "", data: "" };
        o.selectedTruckConfig = { id: "0", name: "", data: "" };
        o.deliveryAddress = "";
        setOptions(o);
    }

    const CheckOverload = () => {
        // process overload logic:
        // if EnableOverloadTransaction is false, allow no overloads: HARD Logic
        // if overload < OverloadWarningAllowance, just ignore
        // if overload > MaxLoadExceedAllowance (%), stop transaction: HARD/SOFT
        // if between those limited display up to DailyOverloadsAllowed warnings: HARD/SOFT
        // For last 2, if OperatorCannotOverrideOverload transaction is stopped (Hard), otherwise Yes/No dialog (soft)

        if (overloadOverride.current) return true; // if previously overridden just continue
        if (serverData.current === undefined) return true; // for some load type these is no rego requirement
        let wt = weights[0];
        let maxLoadWarnings = 0;
        let lastVisit = null;
        let maxLoad = GetSetting(settings, "MaximumWeight");
        if (serverData.current.truck !== undefined && serverData.current.truck !== null) {
            maxLoad = serverData.current.truck.maxLoad;
            maxLoadWarnings = serverData.current.truck.maxLoadWarnings;
            lastVisit = serverData.current.truck.lastVisit;
        } else {
            if (serverData.current.firstWeigh !== null) {
                maxLoad = serverData.current.firstWeigh.maxLoad;
                maxLoadWarnings = serverData.current.firstWeigh.maxLoadWarnings;
            }
        }
        if (maxLoad === 0) return true; // if maxLoad is not set cannot enforce it
        let absMax = maxLoad;
        if (GetSetting(settings, "EnableOverloadTransaction")) absMax = (1 + GetSetting(settings, "MaxLoadExceedAllowance") / 100) * maxLoad;
        let minWarn = ((1 + GetSetting(settings, "OverloadWarningAllowance") / 100) * maxLoad).toFixed(2);
        let over = (wt - maxLoad).toFixed(2);
        let msg = "";
        let allowance = (GetSetting(settings, "OverloadWarningAllowance")) ;
        if (wt > maxLoad && !GetSetting(settings, "EnableOverloadTransaction")) {
            msg = `Vehicle Maximum Exceeded. You must tip off. <br/>Maximum truck load is ${maxLoad}t, overloaded by ${over}t.`;
            ShowError(msg);
            trans.current.eventType = 7;
            trans.current.eventLogError = "Overload";
            trans.current.eventLogDescription = msg;
            PostEventLog(trans.current, GetOnlineToken());
            GenerateStateEvent("Reset", "TipOff");
            Restart();
            return false;
        }
        if (wt > absMax) {
            if (GetSetting(settings, "OperatorCannotOverrideOverload")) {
                msg = `Vehicle Maximum Exceeded. You must tip off. <br/>Maximum truck load is ${maxLoad}t, overloaded by ${over}t.`;
                ShowError(msg);
                GenerateStateEvent("Reset", "TipOff");
                trans.current.eventType = 7;
                trans.current.eventLogError = "Overload";
                trans.current.eventLogDescription = msg;
                PostEventLog(trans.current, GetOnlineToken());
                Restart();
                return false;
            } else {
                ShowDialog(`The vehicle exceeded ${allowance}% over the vehicle's legal weight. Max truck load is ${maxLoad}t, overloaded by ${over}t.`, OverloadAllowed, "Confirm Overload");
                return false;
            }
        }
        if (lastVisit === null) return true;
        let lv = new Date(Date.parse(lastVisit));
        let n = new Date();
        if (lv.getFullYear() !== n.getFullYear() || lv.getMonth() !== n.getMonth() || lv.getDate() !== n.getDate()) return true;
        let daily = maxLoadWarnings;
        if (wt < maxLoad) return true;
        if (daily > GetSetting(settings, "DailyOverloadsAllowed")) {
            if (GetSetting(settings, "OperatorCannotOverrideOverload")) {
                msg = `The vehicle maximum load already exceeded ${daily} times today. <br/>Cannot proceed with the transaction.`;
                ShowError(msg);
                trans.current.eventType = 7;
                trans.current.eventLogError = "Overload";
                trans.current.eventLogDescription = msg;
                PostEventLog(trans.current, GetOnlineToken());
                GenerateStateEvent("Reset", "Leave");
                Restart();
                return false;
            } else {
                ShowDialog(`The vehicle max load already exceeded ${daily} times today. <br/>Click Yes to proceed with the transaction.`, OverloadAllowed, "Confirm Overload");
                return false;
            }
        } else {
            // give a specific warning for the nth overload
            daily++;
            trans.current.maxLoadWarnings = daily;
            let n = GetWarningText(daily);
            msg = `${n} WARNING. Vehicle maximum load exceeded. <br/>Max truck load is ${maxLoad}t, overloaded by ${over}t.`;
            ShowDialog(msg, OverloadAllowed, "Confirm Overload");
            trans.current.eventType = 7;
            trans.current.eventLogError = "Overload";
            trans.current.eventLogDescription = msg;
            PostEventLog(trans.current, GetOnlineToken());
            return false;
        }
        return true;
    }

    const OverloadAllowed = () => {
        overloadOverride.current = true;
        AcceptClicked();
    }

    const DoAutomated = () => {
        if (weights[0] >= GetSetting(settings, "MinimumWeight") && rego !== "" && loadType !== "") AcceptClicked();
        if (mode.current === "instructions") DoInstructions();
    }

    const DoInstructions = () => {
        ShowError("Please drive onto weighbridge and follow instructions on remote display.");
    }

    const PromptForComment = () => {
        let ttl = GetSetting(settings, "CommentTitle");
        if (ttl === undefined || ttl === "") ttl = "Comment";
        PromptForText(ttl, "");
    }

    const ChangeScale = (s) => {
        setActiveWeighbridge(s);
        activeScale.current = s;
        let x = { ...businessData.current };
        businessData.current = x;
    }

    const SetWeighedProduct = (p) => {
        trans.current.productId = p.productId;
        setShowAccept(true);
    }

    const UpdateMixedProducts = (c) => {
        trans.current.selectedMixedProducts = c;
        var sh = (currentRego.current.length > 0) && (c !== null && c.length > 0);
        if (trans.current.loadType === "MIXED" && trans.current.productId === 0) sh = false;
        setShowAccept(sh);
    }

    const TruckConfigSelected = (s) => {
        setShowTruckConfig(false);
        if (s.indexOf("***CANCEL") === 0) return;
        if (s.indexOf("***BACK") === 0) {
            PromptForText("Plant Number");
            return;
        }
        let tc = allOptions.current.vehicleConfigurations.find(obj => obj.id === s);
        trans.current.vehicleConfigurationId = parseInt(s);
        trans.current.configName = tc.name;
        PromptForText("Trailer Number");
        setTextEntryExtra("Back");
        setTextEntryExtra2("No trailer");
    }

    const ChangeCloudStatus = (s) => {
        cloudStatus.current = s;
        if (s && cloudCreds.current === undefined) GetCloudLogin((cred) => {
            cloudCreds.current = cred;
        });

    }

    const GetOnlineToken = () => {
        if (cloudStatus.current && cloudCreds.current !== undefined) {
            return cloudCreds.current.token;
        }
        return "";
    }

    const HIDClick = (b) => {
        switch (b) {
            case "Cancel":
                ShowDialog("Are you sure you want to cancel this transaction?", Restart);
                break;
            case "Back":
                PromptForText("Trailer Number");
                setTextEntryExtra("Back");
                setTextEntryExtra2("No trailer");
                break;
            case "Confirm":
                AcceptClicked();
                break;
        }
    }

    return (
        <div style={{ display: "flex" }}>
            {showRegoDiv && <div id="RegoDiv">
                {showKeyboardIcon && <Button className={"showKeyboard"} title="Show keyboard" onClick={PromptForRego}><span style={{ fontFamily: "Wingdings", fontSize: "48pt" }}>7</span></Button>}
                <AutoComplete className="regoLookup" data={regoList.current} value={rego} onFocus={RegoFocus} onChange={RegoChanged} disabled={regoDisabled} placeholder="Enter Rego"></AutoComplete>
                {showLookup && <div><Button className="bigButton" onClick={LookupFirstClicked}>{GetField(layout, "lookup1st")["label"]}</Button></div>}
                {showLogSplit && <div className="logSplit"><Button className="bigButton" onClick={LogSplitClicked}>{GetField(layout, "logSplit")["label"]}</Button></div>}
                {showAccept && <div><Button className="bigButton acceptButton" onClick={AcceptClicked}>{GetField(layout, "accept")["label"]}</Button></div>}
            </div>}
            {showCameras && <div id="CameraPanelDiv"><Cameras snapshots={cameraImages} businessData={businessData.current} showStream={ShowStream} settings={settings} /></div>}
            {!showCameras && !showPreview && <div id="NoCameraDiv"></div>}
            <div id="WeightBridgePanelDiv"><WeighBridgePanel weights={weights} display={display} lights={lights} settings={settings} wbSettings={wbSettings.current} lightClicked={LightClicked} layout={layout} controlChanged={ChildControlChanged} showSlider={showWeightSlider} setWeights={SetWeights} toggleSlider={ToggleSlider} allowSplit={showSplit} activeScales={activeScales} changeScale={ChangeScale} activeScale={activeWeighbridge} scaleDisabled={scaleDisabled} weightStatus={weightStatus.current} showTraffic={showTraffic} changeCloudStatus={ChangeCloudStatus} options={allOptions.current} /></div>
            {showReprint && <div className="reprint"><Button className="bigButton" onClick={ReprintClicked}>{GetField(layout, "reprint")["label"]}</Button></div>}
            {showRestart && <div className="restart"><Button className="bigButton" onClick={RestartClicked}>{GetField(layout, "restart")["label"]}</Button></div>}
            {showLoadType && <div className="loadTypesDiv">
                <div>
                    <label className="control-label">{GetField(layout, "loadType")["label"]}</label>
                    <div className="loadTypeOptions">
                        {GetSetting(settings, "UseStandardLoad") && <div className="loadTypeDiv"><Button selected={loadType === "STANDARD"} onClick={LoadTypeChanged} togglable={true} disabled={loadTypeDisabled}>{GetField(layout, "standard")["label"]}</Button></div>}
                        {GetSetting(settings, "UseCountedItem") && <div className="loadTypeDiv"><Button selected={loadType === "COUNTED"} onClick={LoadTypeChanged} togglable={true} disabled={loadTypeDisabled}>{GetField(layout, "m3")["label"]}</Button></div>}
                        {GetSetting(settings, "UseStoredTare") && <div className="loadTypeDiv"><Button selected={loadType === "STOREDTARE"} onClick={LoadTypeChanged} togglable={true} disabled={loadTypeDisabled || (trans.current !== null && trans.current.tare === 0)}>{GetField(layout, "stored")["label"]}</Button></div>}
                        {GetSetting(settings, "UseFirstWeigh") && <div className="loadTypeDiv"><Button selected={loadType === "FIRST"} onClick={LoadTypeChanged} togglable={true} disabled={loadTypeDisabled}>{GetField(layout, "first")["label"]}</Button></div>}
                        {GetSetting(settings, "UseSecondWeigh") && <div className="loadTypeDiv"><Button selected={loadType === "SECOND"} onClick={LoadTypeChanged} togglable={true} disabled={loadTypeDisabled || (trans.current !== null && !trans.current.hasFirstWeigh)}>{GetField(layout, "second")["label"]}</Button></div>}
                        {GetSetting(settings, "UseRegoWeigh") && <div className="loadTypeDiv"><Button selected={loadType === "REGO"} onClick={LoadTypeChanged} togglable={true} disabled={loadTypeDisabled}>{GetField(layout, "rego")["label"]}</Button></div>}
                        {GetSetting(settings, "UseMixedLoad") && <div className="loadTypeDiv"><Button selected={loadType === "MIXED"} onClick={LoadTypeChanged} togglable={true} disabled={loadTypeDisabled}>{GetField(layout, "mixed")["label"]}</Button></div>}
                        {GetSetting(settings, "UseVisitor") && <div className="loadTypeDiv"><Button selected={loadType === "VISITOR"} onClick={LoadTypeChanged} togglable={true} disabled={loadTypeDisabled}>{GetField(layout, "visitor")["label"]}</Button></div>}
                        {GetSetting(settings, "UseSimple") && <div className="loadTypeDiv"><Button selected={loadType === "SIMPLE"} onClick={LoadTypeChanged} togglable={true} disabled={loadTypeDisabled}>{GetField(layout, "simple")["label"]}</Button></div>}
                    </div>
                </div>
            </div>}
            {showFirstWeighTable && <div id="FirstWeighDiv" className={fwClass}><FirstWeighs firstWeighSelected={FirstWeighSelected} update={updateFirstWeighs} /></div>}
            {settings !== undefined && showOptions && <div id="OperatorDiv">
                <OperatorData layout={layout} settings={settings} options={options} controlChanged={ChildControlChanged} />
            </div>}
            {showMixedProducts && <div id="MixedProductsDiv"><MixedProducts counted={allOptions.current.countedProducts} weighed={allOptions.current.weighedProducts} setWeighedProduct={SetWeighedProduct} updateMixedProducts={UpdateMixedProducts} trans={trans.current} settings={settings} /></div> }
            {settings !== undefined && showPreview && <Preview onClose={PreviewClosed} onSelection={PreviewSelection} layout={layout} settings={settings} transaction={transaction} displayInfo={transInfo} signature={signature} site={site}></Preview>}
            {itemSelectorOptions.length > 0 && <ItemSelector id="ItemSelector" dataType={itemSelectorType} options={itemSelectorOptions} itemSelected={itemSelectorCallback} showDialog={showItemSelector} allowDismiss showCodes={itemSelectorShowCodes} layoutName={itemSelectorLayout} showKeyboard={itemShowKeyboard } />}
            {textEntryType !== "" && <VirtualKeyboardEntry dataType={textEntryType} textEntered={TextEnteredCallback} allowDismiss layoutName={itemSelectorLayout} initialText={textEntryInitial} extraButton={textEntryExtra} extraButton2={textEntryExtra2} />}
            {jobTonnage && <JobTonnage jobTonnage={jobTonnage} callback={JobTonnageCB} />}
            {showHidProcess && <HIDProcess weights={weights} trans={trans.current} buttonClicked={HIDClick} decks={wbSettings.current.Decks} />}
            {showTruckConfig && <TruckConfig options={allOptions.current.vehicleConfigurations} itemSelected={TruckConfigSelected} />}
            {dialogError !== "" && <Dialog title={"Error"} onClose={() => setDialogError("")}>
                <div className="errorMessage" dangerouslySetInnerHTML={{ __html: dialogError }}></div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => setDialogError("")}>Ok</button>
                </DialogActionsBar>
            </Dialog>}
            {dialogMessage !== "" && <Dialog title={dialogTitle} onClose={DialogNo}>
                <div className="dialogMessage" dangerouslySetInnerHTML={{ __html: dialogMessage}}></div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bigButton" onClick={DialogNo}>{dialogNoText}</button>
                    {dialogYesText !== "" && <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bigButton acceptButton" onClick={DialogYes}>{dialogYesText}</button>}
                </DialogActionsBar>
            </Dialog>}
            <Eftpos command={eftposCommand} data={eftposData} callback={EftposCallback}></Eftpos>
            {streamUrl && <div id="StreamWindow"><div id="StreamClose" onClick={CloseStreaming}>X</div><Iframe id="StreamFrame" url={streamUrl}></Iframe></div>}
            <WeighbridgeHeader message={message} docketNo={docket} />
        </div>
    );
}

export default Weighbridge;