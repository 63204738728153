import SignInForm from "./SignIn";

export default function Login() {
  return (
    <div className="App">
      <div className="container" id="container">
        <SignInForm />        
      </div>
    </div>
  );
}
