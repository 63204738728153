import * as React from "react";
import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend, exportVisual, ChartValueAxis, ChartValueAxisItem } from "@progress/kendo-react-charts";
import configData from "../../config.json";
import { GetToken } from "../../components/Session";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import DashboardCards from "./DashboardCards";
import "hammerjs";
import { exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { Grid, GridColumn } from '@progress/kendo-react-grid';

const RealTimeTrend = () => {
    const [categories, setCategories] = React.useState(null);
    const [sales, setSales] = React.useState(null);
    const [productPerformance, setProductPerformance] = React.useState(null);
    const [trucks, setTrucks] = React.useState(null);
    const [tonnage, setTonnage] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [hours, setHours] = React.useState([]);
    const [sites, setSites] = React.useState([]);
    const [trucksOnSite, setTrucksOnSite] = React.useState([]);
    const sale = React.useRef(null);
    const product = React.useRef(null);
    const total = React.useRef(null);
    const site = React.useRef(null);
    const transCount = React.useRef("");
    const timer = React.useRef(null);
    const valueAxisLabels = { step: 2 };
    const [valueAxisMax, setValueAxisMax] = React.useState(2);
    const onImageExportClick = (e) => {
        var chart = e.currentTarget.name;
        var chartVisual = null;
        if (chart == 'sales') {
            chartVisual = sale.current && exportVisual(sale.current);
        }
        else if (chart == 'product') {
            chartVisual = product.current && exportVisual(product.current);
        }
        else if (chart == 'total') {
            chartVisual = total.current && exportVisual(total.current);
        }
        else {
            chartVisual = site.current && exportVisual(site.current);
        }

        if (chartVisual) {
            exportPDF(chartVisual, {
                paperSize: "A4",
                landscape: true,
            }).then((dataURI) => saveAs(dataURI, "RealtimeData.pdf"));
        }
    };
    React.useEffect(() => {
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        let url = `${configData.SERVER_URL}apiv7/settings`;
        fetch(url, init).then(response => response.json()).then(d => {
            if (d.length > 0) {
                let updateRate = GetSetting(d, "RealTimeDashRefresh") * 1000;
                RefreshData();
                timer.current = setInterval(RefreshData, updateRate);
            }
        })
        return () => {
            if (timer.current !== null) clearInterval(timer.current);
        }
    }, []);


    const GetSetting = (s, name) => {
        if (s === undefined || s === null || s === "") return false;
        let st = undefined;
        st = s.find(obj => { return obj.name == name });
        if (st === undefined) return undefined;
        let v = String(st.value);
        switch (st.type) {
            case 0:
                v = v.toLowerCase() === "true";
                break;
            case 1:
                v = parseInt(v);
                break;
            case 2:
                v = parseFloat(v);
                break;
        }
        return v;
    }

    const RefreshData = () => {
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        const timeOffset = new Date().getTimezoneOffset() / 60;
        let url = configData.SERVER_URL + `apiV7/dashboard/gettransactioncount`;

        fetch(url, init)
            .then((response) => response.text())
            .then((d) => {
                if (d !== transCount.current) {
                    transCount.current = d;
                    url = configData.SERVER_URL + `apiV7/dashboard/getrealtime?offset=${timeOffset}`;
                    fetch(url, init)
                        .then((response) => response.json())
                        .then((d) => {
                            setHours(d.hours);
                            setSales(d.sales);
                            if (d.maxSale > 1.8 && d.maxSale * 1.2 != valueAxisMax) setValueAxisMax(d.maxSale * 1.2);
                            setProductPerformance(d.productPerformance);
                            setTonnage(d.tonnage);
                            setTrucks(d.trucks);
                            setSites(d.siteNames);
                            if (d.trucksOnSite !== null) {
                                let ts = [...d.trucksOnSite];
                                ts.forEach(s => {
                                    s.timeIn = new Date(s.timeIn + "Z").toLocaleTimeString("en-AU");
                                });
                                setTrucksOnSite(ts);
                            }
                            setCategories(d.productNames);
                        });
                }
            });
    }

    return (
        <>
            <ExpansionPanel
                title="Quick View"
                expandIcon="fa fa-plus"
                collapseIcon="fa fa-minus"
                expanded={expanded}
                onAction={(e) => setExpanded(!e.expanded)}
            >
                <Reveal>
                    {expanded && (
                        <ExpansionPanelContent>
                            <DashboardCards period="daily"></DashboardCards>
                        </ExpansionPanelContent>
                    )}
                </Reveal>

            </ExpansionPanel>

            <div className="row mb-3">
                <div className="row" style={{ padding: "5px 10px", wudth: "100%" }}></div>{" "}
                {sales && <div className="col-6">
                    <button className="k-button" name="sales" onClick={onImageExportClick}>Export as PDF</button>
                    <div className="k-card">
                        <Chart ref={sale} name="sales" style={{ height: 350 }} transitions={false}>
                            <ChartTitle text="Transactions" font="22Pt bold" color="#00008B" />
                            <ChartLegend />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={hours} startAngle={45} />
                            </ChartCategoryAxis>
                            <ChartSeries>
                                {sales.map((item, idx) => (
                                    <ChartSeriesItem key={idx} type="line" style="step" markers={{visible: false}} tooltip={{ visible: true }} data={item.data} name={item.name} />
                                ))}
                            </ChartSeries>
                            <ChartValueAxis>
                                <ChartValueAxisItem
                                    min={0}
                                    max={valueAxisMax}
                                    labels={valueAxisLabels}
                                />
                            </ChartValueAxis>
                        </Chart>
                    </div>
                </div>}
                <div className="col-6">
                    <button className="k-button" name="product" onClick={onImageExportClick}>Export as PDF</button>
                    {productPerformance && <div className="k-card">
                        <Chart ref={product} style={{ height: 350 }} transitions={false}>
                            <ChartTitle text="Products" font="22Pt bold" color="#00008B" />
                            <ChartLegend visible={false} />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={categories} startAngle={45} />
                            </ChartCategoryAxis>
                            <ChartSeries>
                                {productPerformance.map((item, idx) => (
                                    <ChartSeriesItem key={idx} type="column" tooltip={{ visible: true }} data={item.data} name={item.name} />
                                ))}
                            </ChartSeries>
                        </Chart>
                    </div>}
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <button className="k-button" name="total" onClick={onImageExportClick}>Export as PDF</button>
                    {tonnage && <div className="k-card">
                        <Chart ref={total} style={{ height: 350 }} transitions={false}>
                            <ChartTitle text="Tonnage" font="22Pt bold" color="#00008B" />
                            <ChartLegend />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={hours} startAngle={45} />
                            </ChartCategoryAxis>
                            <ChartSeries>
                                {tonnage.map((item, idx) => (
                                    <ChartSeriesItem key={idx} type="line" style="step" markers={{ visible: false }} tooltip={{ visible: true }} data={item.data} name={item.name} />
                                ))}
                            </ChartSeries>
                        </Chart>
                    </div>}
                </div>
                <div className="col-6">
                    {trucks && trucksOnSite === null && <div>
                    <button className="k-button" name="site" onClick={onImageExportClick}>Export as PDF</button>
                    <div className="k-card">
                        <Chart ref={product} style={{ height: 350 }} transitions={false}>
                            <ChartTitle text="Trucks on Site" font="22Pt bold" color="#00008B" />
                            <ChartLegend visible={false} />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={sites} startAngle={45} />
                            </ChartCategoryAxis>
                            <ChartSeries>
                                {trucks.map((item, idx) => (
                                    <ChartSeriesItem key={idx} type="column" tooltip={{ visible: true }} data={item.data} name={item.name} />
                                ))}
                            </ChartSeries>
                        </Chart>
                    </div></div>}
                    {trucksOnSite && <div className="k-card">
                        <Grid data={trucksOnSite} style={{ height: "400px" }}> 
                            <GridColumn field="rego" title="Rego" />
                            <GridColumn field="direction" title="Direction" />
                            <GridColumn field="totalTare" title="Total Tare" />
                            <GridColumn field="timeIn" title="Time In" />
                            <GridColumn field="timeOnSite" title="Time On Site" />
                        </Grid>
                    </div>}
                </div>
            </div>
        </>
    );
};
export default RealTimeTrend;
