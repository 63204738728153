import * as React from 'react';
import DataGrid from '../components/DataGrid';
import { GetToken } from '../components/Session';
import GetColumnDefs from '../components/ColumnDefs';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import Notifications from '../components/Notifications';
import configData from "../config.json";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const OutputState = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const [exportdata, setExportdata] = React.useState("");
    const [sites, setSites] = React.useState([]);
    const [site, setSite] = React.useState();
    const navigate = useNavigate();
    const [selected, setSelected] = React.useState(0);
    const [lookupParams, setLookupParams] = React.useState("");
    const handleSelect = (e) => {
        setSelected(e.selected);
    };
    const [layout, setLayout] = React.useState([
        { "field": "searchSite", "label": "Site", "isVisible": true, "type": "list" },
    ]);

    if (permissions && !permissions.EditAppSettings) navigate("/error401");
    React.useEffect(() => {
        GetColumnDefs("OutputState", setColumns);
        GetPermissions(["EditAppSettings", "NewAppSettings", "DeleteAppSettings"], setPermissions);
        let baseUrl = configData.SERVER_URL + "apiv7/options";
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        baseUrl = configData.SERVER_URL + "apiv7/options";
        fetch(baseUrl, init).then((response) => response.json()).then((d) => {

            setSites(CreateList(d.sites));
            setSite({ id: "0", name: "All" });
        });
    }, [])

    const CreateList = (d) => {
        var x = [{ id: "0", name: "All" }];
        d.map((v) => x.push({ id: v.id, name: v.name + " (" + v.id + ")" }));
        return x;
    };

    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    const updateData = (e) => {
        setExportdata(e.value);
    }

    const siteChanged = (event) => {
        setSite(event.target.value);
        if (event.target.value.id == "0")
            setLookupParams("");
        else
            setLookupParams(event.target.value.id);
    }

    const ImportJson = () => {
        let url = configData.SERVER_URL + "apiv7/private/importoutputstates";
        const init = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: exportdata
        };
        fetch(url, init).then(response => response.text()).then(d => {
            alert(d);
        });
    }

    const ExportJson = () => {
        let url = configData.SERVER_URL + "apiv7/outputstates";
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        fetch(url, init).then(response => response.text()).then(d => {
            setExportdata(d);
        });
    }

    return (
        <div>
            <Notifications errorMessage={errorMessage} />
            <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title="Edit Output States">
                    <div className="row" style={{ padding: "5px 10px" }}>
                        {GetField("searchSite")["isVisible"] && <div className="col-md-3">
                            <label className="control-label">{GetField("searchSite")["label"]}</label>
                            <DropDownList id="Site" data={sites} value={site} textField="name" dataItemKey="id" onChange={siteChanged} />
                        </div>}
                    </div>
                    <div className="row">
                        {columns && permissions && <DataGrid dataSource='apiv7/outputstates' columns={columns} permissions={permissions} keyField="outputStateId" params={lookupParams} />}
                    </div>
                </TabStripTab>
                <TabStripTab title="Import/Export Output States">
                    <div className="row" style={{ padding: "20px" }}>
                        WARNING: Importing JSON will delete existing configuration.
                    </div>
                    <div className="row" style={{marginBottom: "20px"}}>
                        <div className="col-md-1"><Button onClick={ImportJson}>Import JSON</Button></div>
                        <div className="col-md-1"><Button onClick={ExportJson}>Export JSON</Button></div>
                    </div>
                    <TextArea cols="80" rows="30" onChange={updateData} value={exportdata}/>
                </TabStripTab>
            </TabStrip>
        </div>
    );

}

export default OutputState;