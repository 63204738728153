import * as React from "react";
import { useParams } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import configData from "../config.json";
import { GetToken } from "../components/Session";
import { TextArea } from "@progress/kendo-react-inputs";
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";

const PrintTransactions = () => {
    const { id } = useParams(); // offences/id url is used to edit existing offense, otherwise id is undefined
    const [ docket, setDocket ] = React.useState("");
    const [ printText, setPrintText ] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/");

    React.useEffect(() => {
        GetPermissions(["ViewTransaction"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/private/getdockettext?transactionid=${id}`;
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.text()).then(d => { 
            setDocket(d);
            setPrintText(String(d).replace(/\r\n/g, "<br>"));
        });
    }, []);

    const Print = () => {
        var newWin = window.open('', 'Print-Window');
        newWin.document.open();
        newWin.document.write('<html><head><title>Transaction ' + id + '</title><footer>Transaction ' + id + '</footer></head><body onload="window.print()"><div style="font-family:\'Courier\'">' + printText + '</div></body></html>');
        newWin.document.close();
        setTimeout(function () { newWin.close(); }, 10);
    }

    return (
        <div>
            <div className="row" style={{padding:"20px"}}>
                <div className="col-md-3">
                    <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={Print}>Print</Button>
                    <a href="/transactions"><Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Back</Button></a>
                </div>
            </div>
            <div className="row" style={{padding:"20px"}}>
                <div className="col-md-6">
                <TextArea rows={30} columns={100} value={docket} style={{fontFamily:"Courier", fontWeight:"bold"}}/>
                </div>
            </div>
        </div>
    );
}

export default PrintTransactions;
