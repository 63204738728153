import * as React from 'react';
import { TextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

// VirtualKeyoardEntry display a buttons and allows text to be entered
// set dataType to type of data. This will prompt as "Enter [dataType]" and return datatype to callback
// allowDismiss shows X in top right hand corner
// layoutname changes keyboard layout for data
// textEntered is callback function when enter clicked
// Example: <VirtualKeyboardEntry dataType={"Rego"} textEntered={TextEnteredCallback} allowDismiss layoutName={"rego""} />
export const VirtualKeyboardEntry= (props) => {
    const dataType = props.dataType; // type of data being entered, eg Rego
    let caption = (props.initialText !== undefined && props.initialText !== "") ? props.initialText : "Enter " + dataType;
    const allowDismiss = props.allowDismiss !== undefined && props.allowDismiss !== false;
    const [text, setText] = React.useState("");   
    const keyboard = React.useRef(); // reference to onscreen keyboard
    const [layoutName, setLayoutName] = React.useState(props.layoutName === undefined ? "default" : props.layoutName);
    const lastDataType = React.useRef();

    React.useEffect(() => {
        if (props.initialText !== undefined && props.initialText !== "" && dataType !== "Plant Number") setText(props.initialText);
    }, []);

    // keys to display on virtual keyboard
    const [layout, setLayout] = React.useState({
        'default': [
            '1 2 3 4 5 6 7 8 9 0 {bksp}',
            'Q W E R T Y U I O P',
            'A S D F G H J K L {enter}',
            '{shift} Z X C V B N M {clear}',
            '{space}'
        ],
        'shift': [
            '! @ # $ % ^ & * ( ) {bksp}',
            'q w e r t y u i o p',
            'a s d f g h j k l {enter}',
            '{shift} z x c v b n m {clear}',
            '{space}'
        ],
        'rego': [
            '1 2 3 4 5 6 7 8 9 0 {clear}',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Z X C V B N M {enter}'
        ],
        'basic': [
            '1 2 3 4 5 6 7 8 9 0 -',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Z X C V B N M {clear}'
        ],
        'numeric': [
            '7 8 9',
            '4 5 6',
            '1 2 3',
            '0 . {clear}',
            '{enter}'
        ]
    });
    // display names for function keys
    const display = {
        '{bksp}': 'Back',
        '{enter}': 'Enter',
        '{space}': 'Space',
        '{clear}': 'Clear',
        '{shift}': 'Shift'
    }

    const DialogNo = () => {
        props.textEntered("***CANCEL");
    }

    const KeyPressed = (k) => {
        switch (k) {
            case "{clear}":
                setText("");
                if (keyboard.current !== undefined) keyboard.current.setInput("");
                break;
            case "{bksp}":
                if (text.length > 0) setText(text.substring(0, text.length - 1));
                break;
            case "{enter}":
                props.textEntered(dataType, text);
                break;
            case "{shift}":
                setLayoutName(layoutName === "default" ? "shift" : "default");
                break;

        }       
    }

    const extraButtonClicked = (t) => {
        props.textEntered(dataType, t);
    }

    const onChange = (ev) => {
        setText(ev);
    }

    const TextboxChanged = (ev) => {
        setText(ev.value);
    }

    const KeyPress = (ev) => {
        if (ev.charCode === 13) props.textEntered(dataType, text);
    }

    if (lastDataType.current !== dataType) {
        lastDataType.current = dataType;
        KeyPressed("{clear}");
    }

    return (
        <div>
            {dataType !== "" && <Dialog onClose={DialogNo} className="virtualKeyboard">
                <div className={"vkEntryHeader"} >
                    <div className={"vkEntryTitle"}>{caption}
                        {props.extraButton && props.extraButton !== "" && <Button id="textExtraButton" onClick={() => extraButtonClicked("***1")}>{props.extraButton}</Button>}
                        {props.extraButton2 && props.extraButton2 !== "" && <Button id="textExtraButton2" onClick={() => extraButtonClicked("***2")}>{props.extraButton2}</Button>}
                    </div>
                    <div><TextBox autoFocus={true} className={"vkEntryTextbox"} placeholder={"Enter " + dataType} value={text} onChange={TextboxChanged} onKeyPress={KeyPress}></TextBox></div>
                    {allowDismiss && <div className={"vkEntryClose"}><Button icon={"close"} onClick={DialogNo}></Button></div>}
                </div>
                <div className={"textKeyboard"}>
                    <div className={"textKeyboardDiv"}><Keyboard keyboardRef={r => (keyboard.current = r)} onKeyPress={KeyPressed} onChange={onChange} display={display} layout={layout} layoutName={layoutName} /></div>
                </div>
            </Dialog>}
        </div>
    )

}