
export const Reports = [
    {
        id: "ListOfTransactions",
        report: "List of Transactions",
    },
    {
        id: "ByDate",
        report: "By Date",
    },
    {
        id: "ByPayment",
        report: "By Payment",
    },
    {
        id: "BySite",
        report: "By Site",
    },
    {
        id: "ByCustomer",
        report: "By Customer",
    },
    {
        id: "BySupplier",
        report: "By Supplier",
    },
    {
        id: "ByWasteStream",
        report: "By Waste Stream",
    },
    {
        id: "BySource",
        report: "By Source",
    },
    {
        id: "ByDestination",
        report: "By Destination",
    },
    {
        id: "ByProduct",
        report: "By Product",
    },
    {
        id: "ByCountedProduct",
        report: "By Counted Item Product",
    },
    {
        id: "ByProductwithEPASource",
        report: "By Product with EPA Source",
    },
    {
        id: "ByTruckConfiguration",
        report: "By Truck Configuration",
    },
    {
        id: "ByVehicleType",
        report: "By Vehicle Type",
    },
    {
        id: "ByVehicle",
        report: "By Vehicle",
    },
    {
        id: "ByJob",
        report: "By Job",
    },
    {
        id: "ByOperator",
        report: "By Operator",
    },
    {
        id: "ByOverweight",
        report: "By Overweight",
    }
];