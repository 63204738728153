import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormTextArea, FormDatePicker, FormNumericTextBox, FormDropDownList } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState  } from "../components/multiselecttree-data-operations";
import { MultiSelectTreeEditor } from "../components/MultiSelectTreeEditor";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';
import { formatDate } from '@progress/kendo-intl';

const EditTrucks = () => {
    const { id } = useParams(); // edittrucks/id url is used to edit existing truck, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [directions, setDirections] = React.useState([]);
    const [chargeRates, setChargeRates] = React.useState([]);
    const [loadTypes, setLoadTypes] = React.useState([]);
    const [marks, setMarks] = React.useState([]);
    const [vehicles, setVehicles] = React.useState([]);
    const [drivers, setDrivers] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [currentProducts, setCurrentProducts] = React.useState("");
    const [selectedTab, setSelectedTab] = React.useState(0);
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    const [permissions, setPermissions] = React.useState(null);    
    
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "truckId","label": "Id","defaultValue": "","isVisible": false,"type": "numeric" },
        { "field": "name","label": "Registration 1","defaultValue": "","isVisible": true,"type": "text" },
        { "field": "code","label": "Code","defaultValue": "","isVisible": true,"type": "text" },
        { "field": "registration2","label": "Registration 2","defaultValue": "","isVisible": true,"type": "text" },
        { "field": "registration3","label": "Registration 3","defaultValue": "","isVisible": true,"type": "text" },
        { "field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean"},
        { "field": "isTrailer","label": "Trailer Rego","defaultValue": "false","isVisible": true,"type": "boolean" },
        { "field": "fleet","label": "Fleet","defaultValue": "","isVisible": true,"type": "text" },
        { "field": "tagId","label": "Tag Id","defaultValue": "","isVisible": true,"type": "text" },
        { "field": "inOrOut","label": "Goods Direction","defaultValue": "","isVisible": true,"type": "list" },
        { "field": "chargeRate","label": "Charge Rate","defaultValue": "LOCAL","isVisible": true,"type": "list" },
        { "field": "splitTare","label": "Split Tare","defaultValue": "false","isVisible": true,"type": "boolean" },
        { "field": "chargeSiteOwned","label": "Charge Site Owned","defaultValue": "false","isVisible": true,"type": "boolean" },
        { "field": "siteOwned","label": "Site Owned","defaultValue": "false","isVisible": true,"type": "boolean" },
        { "field": "loadType","label": "Load Type","defaultValue": "First","isVisible": true,"type": "list" },
        { "field": "tareTerm","label": "Tare Term (Days)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "lastTareDate","label": "Last Tare Date","defaultValue": new Date(), "isVisible": true,"type": "date" },
        { "field": "tareDetails","label": "Tare and Max GVM details","defaultValue": "","isVisible": true,"type": "heading" },
        { "field": "tare1","label": "Tare 1 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "tare2","label": "Tare 2 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "tare3","label": "Tare 3 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "tare4","label": "Tare 4 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "tare5","label": "Tare 5 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "maxLoad1","label": "Max GVM 1 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "maxLoad2","label": "Max GVM 2 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "maxLoad3","label": "Max GVM 3 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "maxLoad4","label": "Max GVM 4 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "maxLoad5","label": "Max GVM 5 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "maxLoad","label": "Total Max GVM (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "truckDetails","label": "Truck","defaultValue": "","isVisible": true,"type": "heading" },
        { "field": "otherDetails","label": "Other Details","defaultValue": "","isVisible": true,"type": "heading" },
        { "field": "siteDetails","label": "Site Details","defaultValue": "","isVisible": true,"type": "heading" },
        { "field": "linkedData","label": "Relationships","defaultValue": "","isVisible": true,"type": "heading" },
        { "field": "jobId","label": "Job","defaultValue": [],"isVisible": true,"type": "list" },
        { "field": "customerId","label": "Customer","defaultValue": "","isVisible": true,"type": "list" },
        { "field": "productId","label": "Product","defaultValue": "","isVisible": true,"type": "list" },
        { "field": "supplierId","label": "Supplier","defaultValue": "","isVisible": true,"type": "list" },
        { "field": "destinationId","label": "Destination","defaultValue": "","isVisible": true,"type": "list" },
        { "field": "sourceId","label": "Source","defaultValue": "","isVisible": true,"type": "list" },
        { "field": "truckSites","label": "Sites","defaultValue": "","isVisible": true,"type": "list" },
        { "field": "vehicleConfigurationId","label": "Truck Configuration","defaultValue": "","isVisible": true,"type": "list" },
        { "field": "vehicleId","label": "Vehicle Type","defaultValue": {id: 1, name: "NA"},"isVisible": true,"type": "list" },
        { "field": "driverId","label": "Driver","defaultValue": {id: 1, name: "NA"},"isVisible": true,"type": "list" },
        { "field": "markId","label": "Mark","defaultValue": {id: 1, name: "NA"},"isVisible": true,"type": "list" },
        { "field": "description","label": "Comment","defaultValue": "","isVisible": true,"type": "text" },
        { "field": "aBN","label": "ABN","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "maxLoadWarnings","label": "Max Load Warnings","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "lastVisit","label": "Last Visit Date","defaultValue": new Date(),"isVisible": true,"type": "date" },
        { "field": "lastTare1","label": "Last Visit Tare 1 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "lastTare2","label": "Last Visit Tare 2 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "lastTare3","label": "Last Visit Tare 3 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "lastTare4","label": "Last Visit Tare 4 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "lastTare5","label": "Last Visit Tare 5 (t)","defaultValue": 0,"isVisible": true,"type": "numeric" },
        { "field": "lastTotalTare","label": "Last Visit Total Tare (t)","defaultValue": 0,"isVisible": true,"type": "numeric" }
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        truckId: GetField("truckId")["defaultValue"],
        name: GetField("name")["defaultValue"],
        code: GetField("code")["defaultValue"],
        registration2: GetField("registration2")["defaultValue"],
        registration3: GetField("registration3")["defaultValue"],
        isTrailer: String(GetField("isTrailer")["defaultValue"]).toLowerCase() === "true",
        fleet: GetField("fleet")["defaultValue"],
        tagId: GetField("tagId")["defaultValue"],
        inOrOut: GetField("inOrOut")["defaultValue"],
        chargeRate: GetField("chargeRate")["defaultValue"],
        splitTare: String(GetField("splitTare")["defaultValue"]).toLowerCase() === "true",
        chargeSiteOwned: String(GetField("chargeSiteOwned")["defaultValue"]).toLowerCase() === "true",
        siteOwned: String(GetField("siteOwned")["defaultValue"]).toLowerCase() === "true",
        loadType: GetField("loadType")["defaultValue"],
        tareTerm: GetField("tareTerm")["defaultValue"],
        lastTareDate: new Date(),
        tareDetails: GetField("tareDetails")["defaultValue"],
        tare1: GetField("tare1")["defaultValue"],
        tare2: GetField("tare2")["defaultValue"],
        tare3: GetField("tare3")["defaultValue"],
        tare4: GetField("tare4")["defaultValue"],
        tare5: GetField("tare5")["defaultValue"],
        maxLoad1: GetField("maxLoad1")["defaultValue"],
        maxLoad2: GetField("maxLoad2")["defaultValue"],
        maxLoad3: GetField("maxLoad3")["defaultValue"],
        maxLoad4: GetField("maxLoad4")["defaultValue"],
        maxLoad5: GetField("maxLoad5")["defaultValue"],
        maxLoad: GetField("maxLoad")["defaultValue"],
        otherDetails: GetField("otherDetails")["defaultValue"],
        jobId: [],
        customerId: [],
        productId: [],
        supplierId: [],
        destinationId: [],
        sourceId: [],
        siteId: [],
        vehicleConfigurationId: [],
        vehicleId: GetField("vehicleId")["defaultValue"],
        driverId: GetField("driverId")["defaultValue"],
        markId: GetField("markId")["defaultValue"],
        description: GetField("description")["defaultValue"],
        aBN: GetField("aBN")["defaultValue"],
        maxLoadWarnings: GetField("maxLoadWarnings")["defaultValue"],
        lastVisit: GetField("lastVisit")["defaultValue"],
        lastTare1: GetField("lastTare1")["defaultValue"],
        lastTare2: GetField("lastTare2")["defaultValue"],
        lastTare3: GetField("lastTare3")["defaultValue"],
        lastTare4: GetField("lastTare4")["defaultValue"],
        lastTare5: GetField("lastTare5")["defaultValue"],
        lastTotalTare: GetField("lastTotalTare")["defaultValue"],
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
      });

    React.useEffect(() => {
        GetPermissions(["ViewTruck", "NewTruck", "EditTruck"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditTrucks'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        var lo = null;
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
                lo = data;
            } else {
                setLayout(d); 
                lo = d;
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setOptions(opt);
            let allSites = GetList(opt.sites);
            let defSites = ConfigureSites(opt.sites, opt.inactiveSites, allSites);
            setMarks(opt.marks);
            setDirections(opt.directions);
            setChargeRates(opt.chargeRates);
            setVehicles(opt.vehicles);
            setDrivers(opt.drivers);
            let lt = []; // filter first in transaction
            for(let i = 0; i < opt.loadTypes.length; i++){
                if (opt.loadTypes[i] !== "Loader") lt.push(opt.loadTypes[i]);
            }
            setLoadTypes(lt);
            let defJobs = ConfigureJobs(opt.jobs, opt.inactiveJobs, "1");
            let defProd = ConfigureProducts(opt.products, opt.inactiveProducts, "1");
            let defSupp = ConfigureSuppliers(opt.suppliers, opt.inactiveSuppliers, "1");
            let defCust = ConfigureCustomers(opt.customers, opt.inactiveCustomers, "1");
            let defSrc = ConfigureSources(opt.sources, opt.inactiveSources, "1");
            let defDest = ConfigureDestinations(opt.destinations, opt.inactiveDestinations, "1");
            let defVC = ConfigureVehicleConfigurations(opt.vehicleConfigurations, "1");
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/trucks/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var mk = opt.marks.find(obj => { return obj.id === String(d.markId) });
                    var driver = opt.drivers.find(obj => { return obj.id === String(d.driverId) });
                    var vehicle = opt.vehicles.find(obj => { return obj.id === String(d.vehicleId) });
                    var ltd = d.lastTareDate === null ? new Date("2000-01-01") : new Date(d.lastTareDate);
                    var fmt = lo.find(obj => { return obj.field === "lastTareDate" })["format"];
                    var lastTareDate = formatDate(ltd, fmt);
                    var lvd = d.lastVisit === null ? null : new Date(d.lastVisit);
                    let prod = ConfigureProducts(opt.products, opt.inactiveProducts, d.productList);
                    let supp = ConfigureSuppliers(opt.suppliers, opt.inactiveSuppliers, d.supplierList);
                    let jobs = ConfigureJobs(opt.jobs, opt.inactiveJobs, d.jobList);
                    let cust = ConfigureCustomers(opt.customers, opt.inactiveCustomers, d.customerList);
                    let src = ConfigureSources(opt.sources, opt.inactiveSources, d.sourceList);
                    let dest = ConfigureDestinations(opt.destinations, opt.inactiveDestinations, d.destinationList);
                    let vc = ConfigureVehicleConfigurations(opt.vehicleConfigurations, d.truckConfigurationList);
                    let sites = ConfigureSites(opt.sites, opt.inactiveSites, d.siteList);
                    setValues({
                        truckId: d.truckId === null ? "" : d.truckId,
                        name: d.name === null ? "" : d.name,
                        code: d.code === null ? "" : d.code,
                        registration2: d.registration2 === null ? "" : d.registration2,
                        registration3: d.registration3 === null ? "" : d.registration3,
                        isActive: d.isActive === null ? true : d.isActive,
                        isTrailer: d.isTrailer === null ? false : d.isTrailer,
                        fleet: d.fleet === null ? "" : d.fleet,
                        tagId: d.tagId === null ? "" : d.tagId,
                        inOrOut: d.inOrOut === null ? "" : d.inOrOut,
                        chargeRate: d.chargeRate === null ? "" : d.chargeRate,
                        splitTare: d.splitTare === null ? "" : d.splitTare,
                        chargeSiteOwned: d.chargeSiteOwned === null ? "" : d.chargeSiteOwned,
                        siteOwned: d.siteOwned === null ? false : d.siteOwned,
                        loadType: d.loadType === null ? "" : d.loadType,
                        tareTerm: d.tareTerm === null ? "" : d.tareTerm,
                        lastTareDate: lastTareDate,
                        lastVisit: lvd,
                        tareDetails: d.tareDetails === null ? "" : d.tareDetails,
                        tare1: d.tare1 === null ? "" : d.tare1,
                        tare2: d.tare2 === null ? "" : d.tare2,
                        tare3: d.tare3 === null ? "" : d.tare3,
                        tare4: d.tare4 === null ? "" : d.tare4,
                        tare5: d.tare5 === null ? "" : d.tare5,
                        maxLoad1: d.maxLoad1 === null ? "" : d.maxLoad1,
                        maxLoad2: d.maxLoad2 === null ? "" : d.maxLoad2,
                        maxLoad3: d.maxLoad3 === null ? "" : d.maxLoad3,
                        maxLoad4: d.maxLoad4 === null ? "" : d.maxLoad4,
                        maxLoad5: d.maxLoad5 === null ? "" : d.maxLoad5,
                        maxLoad: d.maxLoad === null ? "" : d.maxLoad,
                        otherDetails: d.otherDetails === null ? "" : d.otherDetails,
                        jobId: jobs,
                        customerId: cust,
                        productId: prod,
                        supplierId: supp,
                        destinationId: dest,
                        sourceId: src,
                        truckSites: sites,
                        vehicleConfigurationId: vc,
                        vehicleId: d.vehicleId === null ? "" : vehicle,
                        driverId: d.driverId === null ? "" : driver,
                        markId: d.markId === null ? "" : mk,
                        description: d.description === null ? "" : d.description,
                        aBN: d.aBN === null ? 0 : d.abn,
                        maxLoadWarnings: d.maxLoadWarnings === null ? "" : d.maxLoadWarnings,
                        lastTare1: d.lastTare1 === null ? "" : d.lastTare1,
                        lastTare2: d.lastTare2 === null ? "" : d.lastTare2,
                        lastTare3: d.lastTare3 === null ? "" : d.lastTare3,
                        lastTare4: d.lastTare4 === null ? "" : d.lastTare4,
                        lastTare5: d.lastTare5 === null ? "" : d.lastTare5,
                        lastTotalTare: d.lastTotalTare === null ? "" : d.lastTotalTare,
                        active: d.isActive === null ? true : d.isActive
                    });
                    setFormkey(formkey + 1);
                })
            } else {
                let v = {...values};
                v.customerId = defCust;
                v.truckSites = defSites;
                v.jobId = defJobs;
                v.productId = defProd;
                v.supplierId = defSupp;
                v.destinationId = defDest;
                v.sourceId = defSrc;
                v.vehicleConfigurationId = defVC;
                setValues(v);
                setFormkey(formkey + 1);
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/trucks", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    const dataItemKey = "id";
    const checkField = "checkField";
    const checkIndeterminateField = "checkIndeterminateField";
    const subItemsField = "items";
    const expandField = "expanded";
    const textField = "text";
    const fields = { dataItemKey, checkField, checkIndeterminateField, expandField, subItemsField };

    const GetConfigData = (act, dis, idList) => {
        let slsp = idList == null || idList === "" ? [] : idList.split(',');
        let sl = [];
        let selected = [];
        let inactive = null;
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            inactive = {id: 999999, text: 'Inactive', items: sl };
        }
        return { active: active, inactive: inactive, selected: selected};
    }

    // state data for JOB multi-select tree   
    const [jobsData, setJobsData] = React.useState([{id:0, text: "Active", items: []}]);
    const [jobsValue, setJobsValue] = React.useState([]);
    const [jobExpanded, setJobExpanded] = React.useState([]);
    const onJobsChange = (event) => {
        setJobsValue(getMultiSelectTreeValue(jobsData, {...fields, ...event, value: jobsValue, }));
    }
    const onJobsExpandChange = React.useCallback((event) => setJobExpanded(expandedState(event.item, dataItemKey, jobExpanded)), [jobExpanded]);
    const jobsTreeData = React.useMemo(() => processMultiSelectTreeData(jobsData, { expanded: jobExpanded, value: jobsValue, ...fields }), [jobExpanded, jobsValue]);
    const ConfigureJobs = (act, dis, idList) => {
        let x = GetConfigData(act, dis, idList);
        if (x.inactive === null)
            setJobsData([x.active]);
        else
        setJobsData([x.active, x.inactive]);
        setJobsValue([...x.selected]);
        return [...x.selected];
    }
    
    // state data for CUSTOMER multi-select tree   
    const [customersData, setCustomersData] = React.useState([{id:0, text: "Active", items: []}]);
    const [customersValue, setCustomersValue] = React.useState([]);
    const [customerExpanded, setCustomerExpanded] = React.useState([]);
    const onCustomersChange = (event) => {
        setCustomersValue(getMultiSelectTreeValue(customersData, {...fields, ...event, value: customersValue, }));
    }
    const onCustomersExpandChange = React.useCallback((event) => setCustomerExpanded(expandedState(event.item, dataItemKey, customerExpanded)), [customerExpanded]);
    const customersTreeData = React.useMemo(() => processMultiSelectTreeData(customersData, { expanded: customerExpanded, value: customersValue, ...fields }), [customerExpanded, customersValue]);
    const ConfigureCustomers = (act, dis, idList) => {
        let x = GetConfigData(act, dis, idList);
        if (x.inactive === null)
            setCustomersData([x.active]);
        else
        setCustomersData([x.active, x.inactive]);
        setCustomersValue([...x.selected]);
        return [...x.selected];
    }

    // state data for PRODUCT multi-select tree   
    const [productsData, setProductsData] = React.useState([{id:0, text: "Active", items: []}]);
    const [productsValue, setProductsValue] = React.useState([]);
    const [productExpanded, setProductExpanded] = React.useState([]);
    const onProductsChange = (event) => {
        setProductsValue(getMultiSelectTreeValue(productsData, {...fields, ...event, value: productsValue, }));
    }
    const onProductsExpandChange = React.useCallback((event) => setProductExpanded(expandedState(event.item, dataItemKey, productExpanded)), [productExpanded]);
    const productsTreeData = React.useMemo(() => processMultiSelectTreeData(productsData, { expanded: productExpanded, value: productsValue, ...fields }), [productExpanded, productsValue]);
    const ConfigureProducts = (act, dis, productList) => {
        let x = GetConfigData(act, dis, productList);
        if (x.inactive === null)
            setProductsData([x.active]);
        else
            setProductsData([x.active, x.inactive]);
        setProductsValue([...x.selected]);
        return [...x.selected];
    }

    // state data for SUPPLIER multi-select tree   
    const [suppliersData, setSuppliersData] = React.useState([{id:0, text: "Active", items: []}]);
    const [suppliersValue, setSuppliersValue] = React.useState([]);
    const [supplierExpanded, setSupplierExpanded] = React.useState([]);
    const onSuppliersChange = (event) => {
        setSuppliersValue(getMultiSelectTreeValue(suppliersData, {...fields, ...event, value: suppliersValue, }));
    }
    const onSuppliersExpandChange = React.useCallback((event) => setSupplierExpanded(expandedState(event.item, dataItemKey, supplierExpanded)), [supplierExpanded]);
    const suppliersTreeData = React.useMemo(() => processMultiSelectTreeData(suppliersData, { expanded: supplierExpanded, value: suppliersValue, ...fields }), [supplierExpanded, suppliersValue]);
    const ConfigureSuppliers = (act, dis, supplierList) => {
        let x = GetConfigData(act, dis, supplierList);
        if (x.inactive === null)
            setSuppliersData([x.active]);
        else
            setSuppliersData([x.active, x.inactive]);
        setSuppliersValue([...x.selected]);
        return [...x.selected];
    }
    
    // state data for SOURCE multi-select tree   
    const [sourcesData, setSourcesData] = React.useState([{id:0, text: "Active", items: []}]);
    const [sourcesValue, setSourcesValue] = React.useState([]);
    const [sourceExpanded, setSourceExpanded] = React.useState([]);
    const onSourcesChange = (event) => {
        setSourcesValue(getMultiSelectTreeValue(sourcesData, {...fields, ...event, value: sourcesValue, }));
    }
    const onSourcesExpandChange = React.useCallback((event) => setSourceExpanded(expandedState(event.item, dataItemKey, sourceExpanded)), [sourceExpanded]);
    const sourcesTreeData = React.useMemo(() => processMultiSelectTreeData(sourcesData, { expanded: sourceExpanded, value: sourcesValue, ...fields }), [sourceExpanded, sourcesValue]);
    const ConfigureSources = (act, dis, sourceList) => {
        let x = GetConfigData(act, dis, sourceList);
        if (x.inactive === null)
            setSourcesData([x.active]);
        else
            setSourcesData([x.active, x.inactive]);
        setSourcesValue([...x.selected]);
        return [...x.selected];
    }

    // state data for DESTINATION multi-select tree 
    const [destData, setDestData] = React.useState([{id:0, text: "Active", items: []}]);
    const [destValue, setDestValue] = React.useState([]);
    const [destExpanded, setDestExpanded] = React.useState([]);
    const onDestChange = (event) => setDestValue(
        getMultiSelectTreeValue(destData, {...fields, ...event, value: destValue, })
    );
    const onDestExpandChange = React.useCallback((event) => setDestExpanded(expandedState(event.item, dataItemKey, destExpanded)), [destExpanded]);
    const destTreeData = React.useMemo(() => processMultiSelectTreeData(destData, { expanded: destExpanded, value:destValue, ...fields }), [destExpanded, destValue]);
    const ConfigureDestinations = (act, dis, destList) => {
        let x = GetConfigData(act, dis, destList);
        if (x.inactive === null)
            setDestData([x.active]);
        else
            setDestData([x.active, x.inactive]);
        setDestValue([...x.selected]);
        return [...x.selected];
        }

    // state data for VEHICLE CONNFIGURATION multi-select tree   
    const [vehicleConfigurationsData, setVehicleConfigurationsData] = React.useState([{id:0, text: "Active", items: []}]);
    const [vehicleConfigurationsValue, setVehicleConfigurationsValue] = React.useState([]);
    const [vehicleConfigurationExpanded, setVehicleConfigurationExpanded] = React.useState([]);
    const onVehicleConfigurationsChange = (event) => {
        setVehicleConfigurationsValue(getMultiSelectTreeValue(vehicleConfigurationsData, {...fields, ...event, value: vehicleConfigurationsValue, }));
    }
    const onVehicleConfigurationsExpandChange = React.useCallback((event) => setVehicleConfigurationExpanded(expandedState(event.item, dataItemKey, vehicleConfigurationExpanded)), [vehicleConfigurationExpanded]);
    const vehicleConfigurationsTreeData = React.useMemo(() => processMultiSelectTreeData(vehicleConfigurationsData, { expanded: vehicleConfigurationExpanded, value: vehicleConfigurationsValue, ...fields }), [vehicleConfigurationExpanded, vehicleConfigurationsValue]);
    const ConfigureVehicleConfigurations = (act, vehicleConfigurationList) => {
        let x = GetConfigData(act, undefined, vehicleConfigurationList);
        setVehicleConfigurationsData([x.active]);
        setVehicleConfigurationsValue([...x.selected]);
        return [...x.selected];
    }

    const [sitesData, setSitesData] = React.useState([{id:0, text: "Active", items: []}]);
    const [sitesValue, setSitesValue] = React.useState([]);
    const [siteExpanded, setSiteExpanded] = React.useState([]);
    const onSitesChange = (event) => {
        setSitesValue(getMultiSelectTreeValue(sitesData, {...fields, ...event, value: sitesValue, }));
    }
    const onSitesExpandChange = React.useCallback((event) => setSiteExpanded(expandedState(event.item, dataItemKey, siteExpanded)), [siteExpanded]);
    const sitesTreeData = React.useMemo(() => processMultiSelectTreeData(sitesData, { expanded: siteExpanded, value: sitesValue, ...fields }), [siteExpanded, sitesValue]);
    const ConfigureSites = (act, dis, siteList) => {
        if (siteList === null) return;
        let slsp = siteList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            let inactive = {id: 999999, text: 'Inactive', items: sl };
            setSitesData([active, inactive]);
        }
        else {
            setSitesData([active]);
        }
        setSitesValue([...selected]);       
        return [...selected];
    }

    const GetList = (obj) => {
        if (obj === undefined || obj === null) return "";
        var l = "";
        for (let i = 0; i < obj.length; i++){
            let id = obj[i].id;
            if (id !== 0 && id !== 999990){
                l += id;
                if (i < obj.length - 1) l += ",";
            }
        }
        return l;
    }

    const Validate = (dataItem) => {
        let st = "";
        if (dataItem.name === undefined || dataItem.name === '') st += "Name\r\n";
        if (dataItem.code === undefined || dataItem.code === '') st += "Code\r\n";
        if (dataItem.inOrOut === undefined || dataItem.inOrOut === '') st += "Goods Direction\r\n";
        if (dataItem.chargeRate === undefined || dataItem.chargeRate === '') st += "Charge Rate\r\n";
        if (dataItem.loadType === undefined || dataItem.loadType === '') st += "Load Type\r\n";
        if (st !== ""){
            setSelectedTab(0);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        if (dataItem.vehicleId === undefined || dataItem.vehicleId === '') st += "Vehicle Type\r\n";
        if (dataItem.driverId === undefined || dataItem.driverId === '') st += "Driver\r\n";
        if (dataItem.markId === undefined || dataItem.markId === '') st += "Mark\r\n";
        if (st !== ""){
            setSelectedTab(2);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        return true;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        if (!Validate(dataItem)) return;   
        var pth = id === undefined ? "" : "/" + id;
        var jobList = GetList(dataItem.jobId);
        var siteList = GetList(dataItem.truckSites);
        var destList = GetList(dataItem.destinationId);
        var srcList = GetList(dataItem.sourceId);
        var custList = GetList(dataItem.customerId);
        var prodList = GetList(dataItem.productId);
        var suppList = GetList(dataItem.supplierId);
        var vcList = GetList(dataItem.vehicleConfigurationId);

        var url = `${configData.SERVER_URL}apiv7/trucks${pth}`;
        const init = {
            method: id === undefined ? 'POST' : 'PUT',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
            "truckId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.name, "name"),
            "code": GetDefaultData(dataItem.code, "code"),
            "registration2": GetDefaultData(dataItem.registration2, "registration2"),
            "registration3": GetDefaultData(dataItem.registration3, "registration3"),
            "isTrailer": GetDefaultData(dataItem.isTrailer, "isTrailer"),
            "fleet": GetDefaultData(dataItem.fleet, "fleet"),
            "tagId": GetDefaultData(dataItem.tagId, "tagId"),
            "inOrOut": GetDefaultData(dataItem.inOrOut, "inOrOut"),
            "chargeRate": GetDefaultData(dataItem.chargeRate, "chargeRate"),
            "splitTare": GetDefaultData(dataItem.splitTare, "splitTare"),
            "chargeSiteOwned": GetDefaultData(dataItem.chargeSiteOwned, "chargeSiteOwned"),
            "siteOwned": GetDefaultData(dataItem.siteOwned, "siteOwned"),
            "loadType": GetDefaultData(dataItem.loadType, "loadType"),
            "lastTareDate": null, // don't override this, it is set by client
            "tareTerm": GetDefaultData(dataItem.tareTerm, "tareTerm"),
            "lastVisit": GetDefaultData(dataItem.lastVisit, "lastVisit"),
            "tare1": GetDefaultData(dataItem.tare1, "tare1"),
            "tare2": GetDefaultData(dataItem.tare2, "tare2"),
            "tare3": GetDefaultData(dataItem.tare3, "tare3"),
            "tare4": GetDefaultData(dataItem.tare4, "tare4"),
            "tare5": GetDefaultData(dataItem.tare5, "tare5"),
            "maxLoad1": GetDefaultData(dataItem.maxLoad1, "maxLoad1"),
            "maxLoad2": GetDefaultData(dataItem.maxLoad2, "maxLoad2"),
            "maxLoad3": GetDefaultData(dataItem.maxLoad3, "maxLoad3"),
            "maxLoad4": GetDefaultData(dataItem.maxLoad4, "maxLoad4"),
            "maxLoad5": GetDefaultData(dataItem.maxLoad5, "maxLoad5"),
            "maxLoad": GetDefaultData(dataItem.maxLoad, "maxLoad"),
            "customerList": custList,
            "productList": prodList,
            "supplierList": suppList,
            "destinationList": destList,
            "sourceList": srcList,
            "siteList": siteList,
            "jobList": jobList,
            "truckConfigurationList": vcList,
            "driverId": GetDefaultData(dataItem.driverId.id, "driverId"),
            "markId": GetDefaultData(dataItem.markId.id, "markId"),
            "vehicleId": GetDefaultData(dataItem.vehicleId.id, "markId"),
            "description": GetDefaultData(dataItem.description, "description"),
            "aBN": GetDefaultData(dataItem.aBN, "aBN"),
            "maxLoadWarnings": GetDefaultData(dataItem.maxLoadWarnings, "maxLoadWarnings"),
            "lastTare1": GetDefaultData(dataItem.lastTare1, "lastTare1"),
            "lastTare2": GetDefaultData(dataItem.lastTare2, "lastTare2"),
            "lastTare3": GetDefaultData(dataItem.lastTare3, "lastTare3"),
            "lastTare4": GetDefaultData(dataItem.lastTare4, "lastTare4"),
            "lastTare5": GetDefaultData(dataItem.lastTare5, "lastTare5"),
            "lastTotalTare": GetDefaultData(dataItem.lastTotalTare, "lastTotalTare"),
            "isActive": GetDefaultData(dataItem.active, "active") 
            })
        }
        fetch(url, init).then(response => response).then(d => { 
            var msg = id === undefined ? "Truck created" : "Truck updated";
            if (d.status > 299) msg = `Error ${d.status} creating Truck`;
            Redirect(msg);
        });
    }   
    
    const LoadTypeChanged = (ev) => {
        let type = ev.value;
        if (type === "Counted"){
            ConfigureProducts(options.countedProducts, undefined, currentProducts);
        }
        else {
            ConfigureProducts(options.products, options.inactiveProducts, currentProducts);
        }
    }

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) =>  value ? "" : "Please select an option";
      
    return (
        <div style={{padding: 20}}>
           <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
           <FormElement style={{width: "95%"}} >
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("truckDetails")["isVisible"] && <TabStripTab title={GetField("truckDetails")["label"]}>
                        <div className="row">
                            {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("code")["isVisible"] && <div className='col-md-3'>
                                <Field id={"code"} name={"code"} label={GetField("code")["label"]} component={FormInput} validator={textValidator} />
                            </div>}                            
                            {GetField("registration2")["isVisible"] && <div className="col-md-3">
                                <Field id={"registration2"} name={"registration2"} label={GetField("registration2")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("registration3")["isVisible"] && <div className="col-md-3">
                                <Field id={"registration3"} name={"registration3"} label={GetField("registration3")["label"]} component={FormInput}/>
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("inOrOut")["isVisible"] && <div className='col-md-3'>
                                <Field id={"inOrOut"} name={"inOrOut"} label={GetField("inOrOut")["label"]} component={FormDropDownList} data={directions} validator={optionValidator}  />
                            </div>}                           
                            {GetField("chargeRate")["isVisible"] && <div className='col-md-3'>
                                <Field id={"chargeRate"} name={"chargeRate"} label={GetField("chargeRate")["label"]} component={FormDropDownList} data={chargeRates} validator={optionValidator} />
                            </div>}                           
                            {GetField("loadType")["isVisible"] && <div className='col-md-3'>
                                <Field id={"loadType"} name={"loadType"} onChange={LoadTypeChanged} label={GetField("loadType")["label"]} component={FormDropDownList} data={loadTypes} validator={optionValidator} />
                            </div>}                           
                            {GetField("fleet")["isVisible"] && <div className="col-md-3">
                                <Field id={"fleet"} name={"fleet"} label={GetField("fleet")["label"]} component={FormInput}/>
                            </div>}
                        </div>                        
                        <div className='row' >
                            {GetField("tagId")["isVisible"] && <div className="col-md-3">
                                <Field id={"tagId"} name={"tagId"} label={GetField("tagId")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("isTrailer")["isVisible"] && <div className='col-md-3'>
                                <Field id={"isTrailer"} name={"isTrailer"} label={GetField("isTrailer")["label"]} component={FormSwitch} />
                            </div>}                            
                            {GetField("splitTare")["isVisible"] && <div className='col-md-3'>
                                <Field id={"splitTare"} name={"splitTare"} label={GetField("splitTare")["label"]} component={FormSwitch} />
                            </div>}                            
                            {GetField("chargeSiteOwned")["isVisible"] && <div className='col-md-3'>
                                <Field id={"chargeSiteOwned"} name={"chargeSiteOwned"} label={GetField("chargeSiteOwned")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>                        
                        <div className='row' >
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}                            
                            {GetField("siteOwned")["isVisible"] && <div className='col-md-3'>
                                <Field id={"siteOwned"} name={"siteOwned"} label={GetField("siteOwned")["label"]} component={FormSwitch} />
                            </div>}                            
                            {GetField("lastTareDate")["isVisible"] && <div className='col-md-3'>
                                <Field id={"lastTareDate"} name={"lastTareDate"} label={GetField("lastTareDate")["label"]} component={FormInput} disabled />
                            </div>}                            
                        </div>         
                        </TabStripTab>}
                        {GetField("tareDetails")["isVisible"] && <TabStripTab title={GetField("tareDetails")["label"]}>
                        <div className="row">
                            {GetField("tare1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare1"} name={"tare1"} label={GetField("tare1")["label"]} component={FormNumericTextBox} min={0} max={100000}  />
                            </div>}
                            {GetField("maxLoad1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"maxLoad1"} name={"maxLoad1"} label={GetField("maxLoad1")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                            {GetField("lastTare1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"lastTare1"} name={"lastTare1"} label={GetField("lastTare1")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("tare2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare2"} name={"tare2"} label={GetField("tare2")["label"]} component={FormNumericTextBox} min={0} max={100000}  />
                            </div>}
                            {GetField("maxLoad2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"maxLoad2"} name={"maxLoad2"} label={GetField("maxLoad2")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                            {GetField("lastTare2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"lastTare2"} name={"lastTare2"} label={GetField("lastTare2")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("tare3")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare3"} name={"tare3"} label={GetField("tare3")["label"]} component={FormNumericTextBox} min={0} max={100000}  />
                            </div>}
                            {GetField("maxLoad3")["isVisible"] && <div className='col-md-3'>
                                <Field id={"maxLoad3"} name={"maxLoad3"} label={GetField("maxLoad3")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                            {GetField("lastTare3")["isVisible"] && <div className='col-md-3'>
                                <Field id={"lastTare3"} name={"lastTare3"} label={GetField("lastTare3")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("tare4")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare4"} name={"tare4"} label={GetField("tare4")["label"]} component={FormNumericTextBox} min={0} max={100000}  />
                            </div>}
                            {GetField("maxLoad4")["isVisible"] && <div className='col-md-3'>
                                <Field id={"maxLoad4"} name={"maxLoad4"} label={GetField("maxLoad4")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                            {GetField("lastTare4")["isVisible"] && <div className='col-md-3'>
                                <Field id={"lastTare4"} name={"lastTare4"} label={GetField("lastTare4")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("tare5")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare5"} name={"tare5"} label={GetField("tare5")["label"]} component={FormNumericTextBox} min={0} max={100000}  />
                            </div>}
                            {GetField("maxLoad5")["isVisible"] && <div className='col-md-3'>
                                <Field id={"maxLoad5"} name={"maxLoad5"} label={GetField("maxLoad5")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                            {GetField("lastTare5")["isVisible"] && <div className='col-md-3'>
                                <Field id={"lastTare5"} name={"lastTare5"} label={GetField("lastTare5")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("maxLoad")["isVisible"] && <div className='col-md-3'>
                                <Field id={"maxLoad"} name={"maxLoad"} label={GetField("maxLoad")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                            {GetField("lastTotalTare")["isVisible"] && <div className='col-md-3'>
                                <Field id={"lastTotalTare"} name={"lastTotalTare"} label={GetField("lastTotalTare")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("otherDetails")["isVisible"] && <TabStripTab title={GetField("otherDetails")["label"]}>                   
                        <div className='row' >
                            {GetField("vehicleId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"vehicleId"} name={"vehicleId"} label={GetField("vehicleId")["label"]} component={FormDropDownList} data={vehicles} textField="name" validator={optionValidator}  />
                            </div>}                           
                            {GetField("driverId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"driverId"} name={"driverId"} label={GetField("driverId")["label"]} component={FormDropDownList} data={drivers} textField="name" validator={optionValidator}  />
                            </div>}                           
                            {GetField("markId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"markId"} name={"markId"} label={GetField("markId")["label"]} component={FormDropDownList} data={marks} textField="name" validator={optionValidator}/>
                            </div>}                           
                            {GetField("lastVisit")["isVisible"] && <div className="col-md-3"> 
                                <Field id={"lastVisit"} name={"lastVisit"} label={GetField("lastVisit")["label"]} component={FormDatePicker}
                                    wrapperStyle={{ width: "90%", marginRight: "18px",}}/>
                            </div>}
                        </div>                        
                        <div className='row' >
                            {GetField("maxLoadWarnings")["isVisible"] && <div className='col-md-3'>
                                <Field id={"maxLoadWarnings"} name={"maxLoadWarnings"} label={GetField("maxLoadWarnings")["label"]} component={FormNumericTextBox} min={0} max={100000} />
                            </div>}
                            {GetField("aBN")["isVisible"] && <div className="col-md-3">
                                <Field id={"aBN"} name={"aBN"} label={GetField("aBN")["label"]} component={FormNumericTextBox}/>
                            </div>}
                            {GetField("description")["isVisible"] && <div className="col-md-3">
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormTextArea}/>
                            </div>}
                        </div>               
                        </TabStripTab>}
                        {GetField("linkedData")["isVisible"] && <TabStripTab title={GetField("linkedData")["label"]}>         
                        <div className="row">
                                    {GetField("jobId")["isVisible"] && <div className="col-md-6">{GetField("jobId")["label"]}</div>}
                                    {GetField("customerId")["isVisible"] && <div className="col-md-6">{GetField("customerId")["label"]}</div>}
                        </div>                                        
                        <div className='row' >
                            {GetField("jobId")["isVisible"] && <div className='col-md-6'>
                                        <Field  component={MultiSelectTreeEditor} name={"jobId"} data={jobsTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                            {GetField("customerId")["isVisible"] && <div className='col-md-6'>
                                        <Field component={MultiSelectTreeEditor} name={"customerId"} filterable={true} data={customersTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                        </div>
                        <div className="row">
                                    {GetField("productId")["isVisible"] && <div className="col-md-6">{GetField("productId")["label"]}</div>}
                                    {GetField("supplierId")["isVisible"] && <div className="col-md-6">{GetField("supplierId")["label"]}</div>}
                        </div>                                        
                        <div className="row">
                            {GetField("productId")["isVisible"] && <div className='col-md-6'>
                            <Field component={MultiSelectTreeEditor} name={"productId"} data={productsTreeData} value={productsValue} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                            {GetField("supplierId")["isVisible"] && <div className='col-md-6'>
                            <Field component={MultiSelectTreeEditor} name={"supplierId"} data={suppliersTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                        </div>
                        <div className="row">
                                    {GetField("destinationId")["isVisible"] && <div className="col-md-6">{GetField("destinationId")["label"]}</div>}
                                    {GetField("sourceId")["isVisible"] && <div className="col-md-6">{GetField("sourceId")["label"]}</div>}
                        </div>                                        
                        <div className='row' >
                            {GetField("destinationId")["isVisible"] && <div className='col-md-6'>
                            <Field component={MultiSelectTreeEditor} name={"destinationId"} data={destTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                            {GetField("sourceId")["isVisible"] && <div className='col-md-6'>
                            <Field component={MultiSelectTreeEditor} name={"sourceId"} data={sourcesTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                        </div>
                        <div className="row">
                                    {GetField("vehicleConfigurationId")["isVisible"] && <div className="col-md-6">{GetField("vehicleConfigurationId")["label"]}</div>}
                                    {GetField("siteDetails")["isVisible"] && <div className="col-md-6">{GetField("siteDetails")["label"]}</div>}
                        </div>                                        
                        <div className='row' >
                            {GetField("vehicleConfigurationId")["isVisible"] && <div className='col-md-6'>
                            <Field component={MultiSelectTreeEditor} name={"vehicleConfigurationId"} data={vehicleConfigurationsTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                            {GetField("siteDetails")["isVisible"] && <div className='col-md-6'>
                            <Field component={MultiSelectTreeEditor} name={"truckSites"} data={sitesTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                        </div>
                        </TabStripTab>}
                        </TabStrip>                        
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Cancel</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditTrucks