import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { useNavigate } from "react-router-dom";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import configData from "../config.json";
import { GetSession, GetToken } from "../components/Session";

const EventLogs = () => {
    const [columns, setColumns] = React.useState(null);
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();

    let dr = JSON.parse(localStorage.getItem('EventLogDates'));
    if (dr !== null){
        if (dr.start !== null) dr.start = new Date(dr.start);
        if (dr.end !== null) dr.end = new Date(dr.end);
    } else {
        let dt = new Date();
        dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: null};
        localStorage.setItem("EventLogDates", JSON.stringify(dr));
    }

    const rangeChanged = (event) => { 
        ChangeRange(event.target.value, parseInt(user.id));
    }

    const ChangeRange = (dr, u) => {
        setDateRange(dr); 
        localStorage.setItem("EventLogDates", JSON.stringify(dr));
        var params = {
            FromDate: dr.start === null ? "2000-01-01T00:00:00Z" : dr.start.toISOString(),
            ToDate: dr.end === null ? "2050-01-01T00:00:00Z" : dr.end.toISOString(),
            UserId: u
        };
        setLookupParams(encodeURI(JSON.stringify(params)));        
    }

    const userChanged = (event) => {
        setUser(event.target.value);
        ChangeRange(dateRange, parseInt(event.target.value.id));
    }

    const [dateRange, setDateRange] = React.useState(dr);
    const [users, setUsers] = React.useState([]);
    const [user, setUser] = React.useState({ id: "0", name: "All" });
    const [lookupParams, setLookupParams] = React.useState("");     
    React.useEffect(() => { 
        GetColumnDefs("EventLogs", setColumns); 
        GetPermissions(["ViewPrintEventLog"], setPermissions);
        ChangeRange(dateRange);
        const baseUrl = configData.SERVER_URL + "apiv7/options";
        const init = { method: "GET", accept: "application/json", headers: {"Authorization": "Bearer " + GetToken()} };
        fetch(baseUrl, init).then((response) => response.json()).then((d) => {
            setUsers(CreateList(d.users));
            setUser({ id: "0", name: "All" })
        });
      }, [])

        
  const CreateList = (d) => {
    var x = [{ id: "0", name: "All" }];
    return x.concat(d);
  };

    if (permissions && !permissions.CanView) navigate("/");

    return (
        <div>
            <div style={{ paddingTop: "20px" }}> 
            {permissions && permissions.CanView && <div className="row" style={{padding:"5px 10px"}}>
                    <div className="col-md-3" style={{ marginLeft: "30px" }} >
                    <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged}  />
                    </div>
                    <div className="col-md-2" style={{ paddingTop: "7px", marginLeft:"-70px" }} >
                  <label className="control-label">User</label>
                  <DropDownList id="User" data={users} value={user} textField="name" dataItemKey="id" onChange={userChanged}/>
                </div>
            </div>}
        </div>           
        {columns && permissions && permissions.CanView && <DataGrid dataSource='apiv7/eventlogs' columns={columns} permissions={permissions} keyField ="eventLogId" params={lookupParams}/>}
        </div>       
    );

}

export default EventLogs;