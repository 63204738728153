import * as React from 'react';
import Notifications from '../components/Notifications';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";

const WasteStreams = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");

    React.useEffect(() => {
         GetColumnDefs("WasteStreams", setColumns, setErrorMessage);
         GetPermissions(["ViewProductCategory", "NewProductCategory", "EditProductCategory", "DeleteProductCategory", "ReportProductCategory"], setPermissions) 
    }, [])

    return (
        <div>
        <Notifications errorMessage={errorMessage}/>  
        {columns && permissions && <DataGrid dataSource='apiv7/productcategories' columns={columns} permissions={permissions} keyField ="productCategoryId" />}
        </div>       
    );
}

export default WasteStreams;