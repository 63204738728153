import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import Notifications from '../components/Notifications';
import { useNavigate } from "react-router-dom";

const Sites = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");

    React.useEffect(() => { 
        GetColumnDefs("Sites", setColumns, setErrorMessage); 
        GetPermissions(["ViewSite", "NewSite", "EditSite", "DeleteSite", "ReportSite"], setPermissions)
    }, [])
    return (
        <div>
        <Notifications errorMessage={errorMessage}/>
        {columns && permissions && <DataGrid dataSource='apiv7/sites' columns={columns} permissions={permissions} keyField ="siteId" />}
        </div>       
    );

}

export default Sites;