import * as React from "react";
import ReactPlayer from "react-player/youtube";

const VideoTraining = () => {
  return (
      <div>
    <h1 align="center">Training Modules</h1>
      <div class="row">
        <div class="row justify-content-center" style={{ padding:'70px'} }>
          <div class="col-3">
            {" "}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=5qZuwSfnUKU"
              width={"100%"}
            />
          </div>
          <div class="col-3">
            {" "}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=8zt-jZOc7vA"
              width={"100%"}
            />
            </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTraining;
