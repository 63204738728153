import * as React from 'react';
import { TextArea } from "@progress/kendo-react-inputs";
import { GetTransactionData } from "./Transaction";
import configData from "../config.json";
import { GetToken, GetUserId } from '../components/Session';

export const DocketPreview = (props) => {
    const trans = props.transaction;
    const site = props.site;
    const settings = props.settings;
    const [docket, setDocket] = React.useState("");

    if (trans !== undefined && site !== undefined) { 
        var td = GetTransactionData(trans, site.current, settings);
        let init = {
            method: "POST",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken(), "Content-type": "application/json" },
            body: JSON.stringify(td)
        };
        let url = `${configData.SERVER_URL}apiv7/private/getdocketpreview`;
        fetch(url, init).then(response => response.text()).then(d => {
            let dx = String(d).replaceAll(/\[\/?b\]/gi, "");
            setDocket(dx);
        });
    }

    return (
        <div><TextArea rows={23} columns={100} value={docket} className="docketPreview" /></div>
    );
}