import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement, FieldArray } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormTextArea, FormDateTimePicker, FormNumericTextBox, FormDropDownList } from "../components/formComponents";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import $  from "jquery"
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';

export const FormGridEditContext = React.createContext("");

const DeleteCell = (props) => {
    const { dataItem } = props;
    return (
        <td>
            <Button icon="delete" onClick={() => props.remove(dataItem)} title="Delete" />
        </td>
    )
}

const FormGrid = (fieldArrayRenderProps) => {
    const { name } = fieldArrayRenderProps;
    const prods = fieldArrayRenderProps.productList;
    const DeleteProduct = fieldArrayRenderProps.deleteProduct;


    const CommandCell = (props) => {
        return (
            <DeleteCell {...props} remove={Remove} />
        )
    }

    const Remove = (item) => {
        DeleteProduct(item);
    }

    return <FormGridEditContext.Provider value={name}>
        <Grid data={prods} pageable={{
            buttonCount: 4,
            pageSizeValue: 5,
            pageSizes: [5, 10, 20, "All"],
        }}
        >
            <GridColumn field="name" title="Counted Product" editable={false} />
            <GridColumn width="120" field="currentAmount" title="Quantity" format="{0:n2}" editable={false} />
            <GridColumn width="80" field="Action" title="Delete" cell={CommandCell} editable={false} />
        </Grid>
    </FormGridEditContext.Provider>;
};

const EditTransactions = () => {
    const { id } = useParams(); // edittransactions/id url is used to edit existing transaction, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [sites, setSites] = React.useState([]);
    const [directions, setDirections] = React.useState([]);
    const [chargeRates, setChargeRates] = React.useState([]);
    const [loadTypes, setLoadTypes] = React.useState([]);
    const [loadType, setLoadType] = React.useState("");
    const [payments, setPayments] = React.useState([]);
    const [vehicles, setVehicles] = React.useState([]);
    const [drivers, setDrivers] = React.useState([]);
    const [trucks, setTrucks] = React.useState([]);
    const [owners, setOwners] = React.useState(["PRIVATE", "SITE"]);
    const [jobs, setJobs] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [productCategories, setProductCategories] = React.useState([]);
    const [destinations, setDestinations] = React.useState([]);
    const [sources, setSources] = React.useState([]);
    const [vehicleConfigurations, setVehicleConfigurations] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const [subStreams, setSubStreams] = React.useState([]);
    const [destinationPurposes, setDestinationPurposes] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [permissions, setPermissions] = React.useState(null);
    const [priceDisabled, setPriceDisabled] = React.useState(false);
    const priceList = React.useRef([]);
    const [countedProducts, setCountedProducts] = React.useState([]); // selected counted products
    const [countedProductList, setCountedProductList] = React.useState([]); // selected counted products
    const [selCountedProduct, setSelCountedProduct] = React.useState(); // currently selected counted product in dropdown
    const [quantity, setQuantity] = React.useState(1); // quantity in quantity textbox
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        {"field": "docket","label": "Docket","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "transactionDate","label": "Transaction Date","defaultValue": new Date(),"isVisible": true,"type": "datetime"},
        {"field": "tareInDate","label": "Tare In Date","defaultValue": new Date(),"isVisible": true,"type": "datetime"},
        {"field": "registration1","label": "Registration 1","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "registration2","label": "Registration 2","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "registration3","label": "Registration 3","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "payments","label": "Payment Method","defaultValue": "ACCOUNT","isVisible": true,"type": "list"},
        {"field": "direction","label": "Goods Direction","defaultValue": "NA","isVisible": true,"type": "list"},
        {"field": "chargeRate","label": "Charge Rate","defaultValue": "LOCAL","isVisible": true,"type": "list"},
        {"field": "vehicleOwner","label": "Vehicle Owner","defaultValue": "SITE","isVisible": true,"type": "list"},
        {"field": "orderNumber","label": "Order Number","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "loadType","label": "Load Type","defaultValue": "Second","isVisible": true,"type": "list"},
        {"field": "tareDetails","label": "Gross and Tare Details","defaultValue": "","isVisible": true,"type": "heading"},
        {"field": "gross1","label": "Gross 1 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "gross2","label": "Gross 2 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "gross3","label": "Gross 3 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "gross4","label": "Gross 4 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "gross5","label": "Gross 5 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "tare1","label": "Tare 1 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "tare2","label": "Tare 2 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "tare3","label": "Tare 3 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "tare4","label": "Tare 4 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "tare5","label": "Tare 5 (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "net","label": "Net (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "maxLoad","label": "Max GVM (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "otherDetails","label": "Other details","defaultValue": "","isVisible": true,"type": "heading"},
        {"field": "siteId","label": "Site","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "jobId","label": "Job","defaultValue": {"id": "1", "name": "NA"} ,"isVisible": true,"type": "list"},
        {"field": "customerId","label": "Customer","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "productId","label": "Product","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "productCategoryId","label": "Wase Stream","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "destinationId","label": "Destination","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "sourceId","label": "Source","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "vehicleConfigurationId","label": "Truck Configuration","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "vehicleId","label": "Vehicle Type","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "truckId","label": "Vehicle","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "driverId","label": "Driver","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "userId","label": "User","defaultValue": {"id": "1", "name": "NA"},"isVisible": true,"type": "list"},
        {"field": "vINNumber","label": "VIN Number","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "subStream","label": "Sub Stream","defaultValue": "NA","isVisible": true,"type": "list"},
        {"field": "destinationPurpose","label": "Destination Purpose","defaultValue": "NA","isVisible": true,"type": "list"},
        {"field": "price","label": "Price $/tonne","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "epaRate","label": "EPA Charge $/tonne","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "penaltyCharge","label": "Penalty Charge (excl GST)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "cartageCharge","label": "Cartage Charge (excl GST)","defaultValue": 0,"isVisible": false,"type": "numeric"},
        {"field": "haulage","label": "Haulage","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "estimatedWeight","label": "Estimated Weight (t)","defaultValue": 0,"isVisible": true,"type": "numeric"},
        {"field": "comment","label": "Comment","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "transactionDetails","label": "Transaction","defaultValue": "","isVisible": true,"type": "heading"},
        {"field": "deliveryDetails","label": "Delivery address and contact details","defaultValue": "","isVisible": true,"type": "heading"},
        {"field": "deliveryAddress","label": "Delivery Address","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "deliverySuburb","label": "Delivery Suburb","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "deliveryState","label": "Delivery State","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "deliveryPostcode","label": "Delivery Postcode","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "deliveryPhone","label": "Delivery Phone","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "deliveryContact","label": "Delivery Contact","defaultValue": "","isVisible": true,"type": "text"},
        {"field": "countedProducts", "label": "Counted Products", "defaultValue": "", "isVisible": true, "type": "text" },
        {"field": "countedProduct", "label": "Product", "defaultValue": "", "isVisible": true, "type": "text" },
        {"field": "countedQuantity", "label": "Quantity", "defaultValue": "1", "isVisible": true, "type": "numeric" },
        {"field": "countedAdd", "label": "Add Product", "defaultValue": "", "isVisible": true, "type": "text" },
        {"field": "productList", "label": "Product List", "defaultValue": "", "isVisible": true, "type": "text" }
     ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        docket: GetField("docket")["defaultValue"],
        transactionDate: GetField("transactionDate")["defaultValue"],
        tareInDate: GetField("tareInDate")["defaultValue"],
        registration1: GetField("registration1")["defaultValue"],
        registration2: GetField("registration2")["defaultValue"],
        registration3: GetField("registration3")["defaultValue"],
        payments: GetField("payments")["defaultValue"],
        direction: GetField("direction")["defaultValue"],
        chargeRate: GetField("chargeRate")["defaultValue"],
        vehicleOwner: GetField("vehicleOwner")["defaultValue"],
        orderNumber: GetField("orderNumber")["defaultValue"],
        loadType: GetField("loadType")["defaultValue"],
        tareDetails: GetField("tareDetails")["defaultValue"],
        gross1: GetField("gross1")["defaultValue"],
        gross2: GetField("gross2")["defaultValue"],
        gross3: GetField("gross3")["defaultValue"],
        gross4: GetField("gross4")["defaultValue"],
        gross5: GetField("gross5")["defaultValue"],
        tare1: GetField("tare1")["defaultValue"],
        tare2: GetField("tare2")["defaultValue"],
        tare3: GetField("tare3")["defaultValue"],
        tare4: GetField("tare4")["defaultValue"],
        tare5: GetField("tare5")["defaultValue"],
        net: GetField("net")["defaultValue"],
        maxLoad: GetField("maxLoad")["defaultValue"],
        otherDetails: GetField("otherDetails")["defaultValue"],
        siteId: GetField("siteId")["defaultValue"],
        jobId: GetField("jobId")["defaultValue"],
        customerId: GetField("customerId")["defaultValue"],
        productId: GetField("productId")["defaultValue"],
        productCategoryId: GetField("productCategoryId")["defaultValue"],
        destinationId: GetField("destinationId")["defaultValue"],
        sourceId: GetField("sourceId")["defaultValue"],
        vehicleConfigurationId: GetField("vehicleConfigurationId")["defaultValue"],
        vehicleId: GetField("vehicleId")["defaultValue"],
        truckId: GetField("truckId")["defaultValue"],
        driverId: GetField("driverId")["defaultValue"],
        userId: GetField("userId")["defaultValue"],
        vINNumber: GetField("vINNumber")["defaultValue"],
        subStream: GetField("subStream")["defaultValue"],
        destinationPurpose: GetField("destinationPurpose")["defaultValue"],
        price: GetField("price")["defaultValue"],
        epaRate: GetField("epaRate")["defaultValue"],
        penaltyCharge: GetField("penaltyCharge")["defaultValue"],
        cartageCharge: GetField("cartageCharge")["defaultValue"],
        haulage: GetField("haulage")["defaultValue"],
        estimatedWeight: GetField("estimatedWeight")["defaultValue"],
        comment: GetField("comment")["defaultValue"],
        deliveryDetails: GetField("deliveryDetails")["defaultValue"],
        deliveryAddress: GetField("deliveryAddress")["defaultValue"],
        deliverySuburb: GetField("deliverySuburb")["defaultValue"],
        deliveryState: GetField("deliveryState")["defaultValue"],
        deliveryPostcode: GetField("deliveryPostcode")["defaultValue"],
        deliveryPhone: GetField("deliveryPhone")["defaultValue"],
        deliveryContact: GetField("deliveryContact")["defaultValue"]
    });

    React.useEffect(() => {
        GetPermissions(["ViewTransaction", "NewTransaction", "EditTransactionsPage"], setPermissions);
        // dynURl aand dynInit will switch between cloud and local depending on online/offline behaviour
        let token = sessionStorage.getItem("cloudtoken");
        let dynUrl = ((token === null || token === "") ? configData.SERVER_URL : configData.CLOUD_URL);
        const dynInit = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + ((token === null || token === "") ? GetToken(): token)}
        }
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditTransactions'";
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {         
            setOptions(opt);  
            setSites(opt.sites);
            setVehicles(opt.vehicles);
            setDrivers(opt.drivers);
            setDirections(opt.directions);
            setChargeRates(opt.chargeRates);
            let lt = []; // filter first in transaction
            for(let i = 0; i < opt.loadTypes.length; i++){
                if (opt.loadTypes[i] !== "First" && opt.loadTypes[i] !== "All") lt.push(opt.loadTypes[i]);
            }
            setLoadTypes(lt);
            setPayments(opt.paymentMethods);
            setJobs(opt.jobs);
            setCustomers(opt.customers);
            setProducts(opt.weighedProducts);
            setCountedProducts(opt.countedProducts);
            setProductCategories(opt.productCategories);
            setDestinations(opt.destinations);
            setSources(opt.sources);
            setVehicleConfigurations(opt.vehicleConfigurations);
            setUsers(opt.users);
            setTrucks(opt.trucks);
            setStates(opt.states);
            setSubStreams(opt.subStreams);
            setDestinationPurposes(opt.destinationPurposes);
            setQuantity(1);
            if (id !== undefined){
               url = `${dynUrl}apiv7/transactions/${id}`;
                fetch(url, dynInit).then(response => response.json()).then(d => {
                    var dest = opt.destinations.find(obj => { return obj.id === String(d.destinationId) });
                    var cust = opt.customers.find(obj => { return obj.id === String(d.customerId) });
                    var driver = opt.drivers.find(obj => { return obj.id === String(d.driverId) });
                    var vehicle = opt.vehicles.find(obj => { return obj.id === String(d.vehicleId) });
                    var site = opt.sites.find(obj => { return obj.id === String(d.siteId) });                    
                    var truck = opt.trucks.find(obj => { return obj.id === String(d.truckId) });                    
                    var user = opt.users.find(obj => { return obj.id === String(d.userId) });                    
                    var job = opt.jobs.find(obj => { return obj.id === String(d.jobId) });                    
                    var source = opt.sources.find(obj => { return obj.id === String(d.sourceId) });                    
                    var product = opt.products.find(obj => { return obj.id === String(d.productId) });                    
                    var productCategory = opt.productCategories.find(obj => { return obj.id === String(d.productCategoryId) });                    
                    var vehicleConfig = opt.vehicleConfigurations.find(obj => { return obj.id === String(d.vehicleConfigurationId) });                    
                    let v = {
                        docket: d.docket === null ? "" : d.docket,
                        transactionDate: d.transactionDate === null ? new Date("2000-01-01") : new Date(d.transactionDate + "Z"),
                        tareInDate: d.tareInDate === null ? new Date("2000-01-01") : new Date(d.tareInDate + "Z"),
                        registration1: d.registration1 === null ? "" : d.registration1,
                        registration2: d.registration2 === null ? "" : d.registration2,
                        registration3: d.registration3 === null ? "" : d.registration3,
                        payments: d.payments === null ? "" : d.payments,
                        direction: d.direction === null ? "" : d.direction,
                        chargeRate: d.chargeRate === null ? "" : d.chargeRate,
                        vehicleOwner: d.vehicleOwner === null ? "" : d.vehicleOwner,
                        orderNumber: d.orderNumber === null ? "" : d.orderNumber,
                        loadType: d.loadType === null ? "" : d.loadType,
                        tareDetails: d.tareDetails === null ? "" : d.tareDetails,
                        gross1: d.gross1 === null ? "" : d.gross1,
                        gross2: d.gross2 === null ? "" : d.gross2,
                        gross3: d.gross3 === null ? "" : d.gross3,
                        gross4: d.gross4 === null ? "" : d.gross4,
                        gross5: d.gross5 === null ? "" : d.gross5,
                        tare1: d.tare1 === null ? "" : d.tare1,
                        tare2: d.tare2 === null ? "" : d.tare2,
                        tare3: d.tare3 === null ? "" : d.tare3,
                        tare4: d.tare4 === null ? "" : d.tare4,
                        tare5: d.tare5 === null ? "" : d.tare5,
                        net: d.net === null ? "" : d.net,
                        maxLoad: d.maxLoad === null ? "" : d.maxLoad,
                        otherDetails: d.otherDetails === null ? "" : d.otherDetails,
                        siteId: d.siteId === null ? "" : site,
                        jobId: d.jobId === null ? "" : job,
                        customerId: d.customerId === null ? "" : cust,
                        productId: d.productId === null ? "" : product,
                        productCategoryId: d.productCategoryId === null ? "" : productCategory,
                        destinationId: d.destinationId === null ? "" : dest,
                        sourceId: d.sourceId === null ? "" : source,
                        vehicleConfigurationId: d.vehicleConfigurationId === null ? "" : vehicleConfig,
                        vehicleId: d.vehicleId === null ? "" : vehicle,
                        truckId: d.truckId === null ? "" : truck,
                        driverId: d.driverId === null ? "" : driver,
                        userId: d.userId === null ? "" : user,
                        vinNumber: d.vinNumber === null ? "" : d.vinNumber,
                        subStream: d.subStream === null ? "" : d.subStream,
                        destinationPurpose: d.destinationPurpose === null ? "" : d.destinationPurpose,
                        price: d.price === null ? "" : d.price,
                        epaRate: d.epaRate === null ? "" : d.epaRate,
                        penaltyCharge: d.penaltyCharge === null ? "" : d.penaltyCharge,
                        cartageCharge: d.cartageCharge === null ? "" : d.cartageCharge,
                        haulage: d.haulage === null ? "" : d.haulage,
                        estimatedWeight: d.estimatedWeight === null ? "" : d.estimatedWeight,
                        comment: d.comment === null ? "" : d.comment,
                        deliveryDetails: d.deliveryDetails === null ? "" : d.deliveryDetails,
                        deliveryAddress: d.deliveryAddress === null ? "" : d.deliveryAddress,
                        deliverySuburb: d.deliverySuburb === null ? "" : d.deliverySuburb,
                        deliveryState: d.deliveryState === null ? "" : d.deliveryState,
                        deliveryPostcode: d.deliveryPostcode === null ? "" : d.deliveryPostcode,
                        deliveryPhone: d.deliveryPhone === null ? "" : d.deliveryPhone,
                        deliveryContact: d.deliveryContact === null ? "" : d.deliveryContact
                    };
                    JobChanged(d.jobId,opt, v);
                })
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (f === undefined) f = "";
        if (fld === undefined || fld === null) return f;
        let res = fld["defaultValue"];
        if (fld["isVisible"]) res = f;
        switch(fld["type"]){
            case "numeric": 
                res = parseFloat(res); 
                if (isNaN(res)) res = 0;
                break;
            case "boolean": res = (res === "true" || res === true); break;
            case "date":
                if (res === "") res = null;
                break;
            default: break;
        }
        return res;
    }

    const GetDropdownData = (f, name) => {
        let res = 0;
        if (f === undefined || f.id === undefined) 
            res = GetDefaultData('', name);
        else
            res = f.id;
        return parseInt(res);
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/transactions", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    const Validate = (dataItem) => {
        let st = "";
        if (dataItem.registration1 === "") st += "Registration 1\r\n";
        if (dataItem.payments === "") st += "Payment Method\r\n";
        if (dataItem.direction === "") st += "Good Direction\r\n";
        if (dataItem.chargeRate === "") st += "Charge Rate\r\n";
        if (dataItem.vehicleOwner === "") st += "Vehicle Owner\r\n";
        if (dataItem.loadType === "") st += "Load Type ";
        if (st !== ""){
            setSelectedTab(0);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        let lt = String(dataItem.loadType).toUpperCase();
        if (lt !== "STANDARD" && lt !== "COUNTED" && lt !== "SIMPLE") {
            if (dataItem.gross1 === 0) st += "Gross 1\r\n";
            if (dataItem.tare1 === 0) st += "Tare 1";
        }
        if (st !== ""){
            setSelectedTab(1);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        if ((loadType === "COUNTED" || loadType === "MIXED") && countedProductList.length === 0) {
            setSelectedTab(2);
            alert("Select a counted product");
            return false;
        }
        if (dataItem.siteId === undefined) st += "Site\r\n";
        if (dataItem.jobId === undefined) st += "Job\r\n";
        if (dataItem.customerId === undefined) st += "Customer\r\n";
        if (dataItem.productId === undefined) st += "Product\r\n";
        if (dataItem.destinationId === undefined) st += "Destination\r\n";
        if (dataItem.sourceId === undefined) st += "Source\r\n";
        if (dataItem.vehicleConfigurationId === undefined) st += "Truck Configuration\r\n";
        if (dataItem.vehicleId === undefined || (lt === "STANDARD" && dataItem.vehicleId.id === "1")) st += "Vehicle Type\r\n";
        if (dataItem.truckId === undefined) st += "Vehicle\r\n";
        if (dataItem.driverId === undefined) st += "Driver\r\n";
        if (dataItem.userId === undefined) st += "User\r\n";        
        if (st !== ""){
            setSelectedTab(3);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        return true;
    }
    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        if (!Validate(dataItem)) return;
        var pth = id === undefined ? "" : "/" + id;

        let token = sessionStorage.getItem("cloudtoken");
        let countedProducts = [];
        if (id === undefined && countedProductList.length > 0 && (loadType === "COUNTED" || loadType === "MIXED")) {
            countedProductList.map((x) => {
                countedProducts.push({ id: x.productId, name: x.name, quantity: x.currentAmount });
            });
        }
        let baseUrl = (token === "" || token === null ? configData.SERVER_URL : configData.CLOUD_URL);
        var url = `${baseUrl}apiv7/transactions${pth}`;
        const init = {
            method: id === undefined ? 'POST' : 'PUT',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + (token === "" || token === null ? GetToken() : token)
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
            "transactionId": id === undefined ? 0 : id,
            "docket": GetDefaultData(dataItem.docket, "docket"),
            "transactionDate": GetDefaultData(dataItem.transactionDate, "transactionDate"),
            "tareInDate": GetDefaultData(dataItem.tareInDate, "tareInDate"),
            "registration1": GetDefaultData(dataItem.registration1, "registration1"),
            "registration2": GetDefaultData(dataItem.registration2, "registration2"),
            "registration3": GetDefaultData(dataItem.registration3, "registration3"),
            "payments": GetDefaultData(dataItem.payments, "payments"),
            "direction": GetDefaultData(dataItem.direction, "direction"),
            "chargeRate": GetDefaultData(dataItem.chargeRate, "chargeRate"),
            "vehicleOwner": GetDefaultData(dataItem.vehicleOwner, "vehicleOwner"),
            "orderNumber": GetDefaultData(dataItem.orderNumber, "orderNumber"),
            "loadType": GetDefaultData(dataItem.loadType, "loadType"),
            "tareDetails": GetDefaultData(dataItem.tareDetails, "tareDetails"),
            "gross1": GetDefaultData(dataItem.gross1, "gross1"),
            "gross2": GetDefaultData(dataItem.gross2, "gross2"),
            "gross3": GetDefaultData(dataItem.gross3, "gross3"),
            "gross4": GetDefaultData(dataItem.gross4, "gross4"),
            "gross5": GetDefaultData(dataItem.gross5, "gross5"),
            "tare1": GetDefaultData(dataItem.tare1, "tare1"),
            "tare2": GetDefaultData(dataItem.tare2, "tare2"),
            "tare3": GetDefaultData(dataItem.tare3, "tare3"),
            "tare4": GetDefaultData(dataItem.tare4, "tare4"),
            "tare5": GetDefaultData(dataItem.tare5, "tare5"),
            "net": GetDefaultData(dataItem.net, "net"),
            "maxLoad": GetDefaultData(dataItem.maxLoad, "maxLoad"),
            "otherDetails": GetDefaultData(dataItem.otherDetails, "otherDetails"),
            "siteId": GetDropdownData(dataItem.siteId, "siteId"),
            "jobId": GetDropdownData(dataItem.jobId, "jobId"),
            "customerId": GetDropdownData(dataItem.customerId, "customerId"),
            "productId": GetDropdownData(dataItem.productId, "productId"),
            "productCategoryId": GetDropdownData(dataItem.productCategoryId, "productCategoryId"),
            "destinationId": GetDropdownData(dataItem.destinationId, "destinationId"),
            "sourceId": GetDropdownData(dataItem.sourceId, "sourceId"),
            "vehicleConfigurationId": GetDropdownData(dataItem.vehicleConfigurationId, "vehicleConfigurationId"),
            "vehicleId": GetDropdownData(dataItem.vehicleId, "vehicleId"),
            "driverId": GetDropdownData(dataItem.driverId, "driverId"),
            "userId": GetDropdownData(dataItem.userId, "userId"),
            "truckId": GetDropdownData(dataItem.truckId, "truckId"),
            "markId": 1,
            "vINNumber": GetDefaultData(dataItem.vINNumber, "vinNumber"),
            "subStream": GetDefaultData(dataItem.subStream, "subStream"),
            "destinationPurpose": GetDefaultData(dataItem.destinationPurpose, "destinationPurpose"),
            "price": GetDefaultData(dataItem.price, "price"),
            "epaRate": GetDefaultData(dataItem.epaRate, "epaRate"),
            "penaltyCharge": GetDefaultData(dataItem.penaltyCharge, "penaltyCharge"),
            "cartageCharge": GetDefaultData(dataItem.cartageCharge, "cartageCharge"),
            "haulage": GetDefaultData(dataItem.haulage, "haulage"),
            "estimatedWeight": GetDefaultData(dataItem.estimatedWeight, "estimatedWeight"),
            "comment": GetDefaultData(dataItem.comment, "comment"),
            "deliveryDetails": GetDefaultData(dataItem.deliveryDetails, "deliveryDetails"),
            "deliveryAddress": GetDefaultData(dataItem.deliveryAddress, "deliveryAddress"),
            "deliverySuburb": GetDefaultData(dataItem.deliverySuburb, "deliverySuburb"),
            "deliveryState": GetDefaultData(dataItem.deliveryState, "deliveryState"),
            "deliveryPostcode": GetDefaultData(dataItem.deliveryPostcode, "deliveryPostcode"),
            "deliveryPhone": GetDefaultData(dataItem.deliveryPhone, "deliveryPhone"),
            "deliveryContact": GetDefaultData(dataItem.deliveryContact, "deliveryContact"),
            "countedProducts": countedProducts,
            "isActive":1

            })
        }
        fetch(url, init).then(response => {
            var msg = id === undefined ? "Transaction created" : "Transaction updated";
            if (response.status < 300) Redirect(msg);
            return response.text();
        }).then(d => { 
            Redirect(d);
        });
    }   

    const LookupRego = (e) => {
        e.preventDefault();
        let rego = $("#registration1").val();
        let url = configData.SERVER_URL + "apiv7/trucks?$filter=contains(name,'" + rego + "')";
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: {"Authorization": "Bearer " + GetToken()}
          }
        fetch(url, init).then(response => response.json()).then(j => {     
            if (j.length > 0) {
                let x = {...values};
                x.registration1 = j[0].name;
                x.tare1 = j[0].tare1;
                x.tare2 = j[0].tare2;
                x.tare3 = j[0].tare3;
                x.tare4 = j[0].tare4;
                x.tare5 = j[0].tare5;
                x.maxLoad = j[0].maxLoad;
                x.loadType = j[0].loadType;
                x.truckId = options.trucks.find(obj => { return obj.id === String(j[0].truckId) });                    
                setValues(x);
                setFormkey(formkey + 1);
                url = configData.SERVER_URL + "apiv7/truckjobs?$filter=truckId eq " + j[0].truckId;
                fetch(url, init).then(response => response.json()).then(j => {     
                    if (j.length > 0 && options.jobs.length > 0){
                        let x = {...values};
                        let jl = [];
                        for (let i = 0; i < j.length; i++){
                            let jid = j[i].jobId;
                            let jobj = options.jobs.find(obj => { return obj.id === String(jid) });                    
                            jl.push(jobj);
                        }
                        setJobs(jl);
                        x.jobId = jl[0];
                        setValues(x);
                        setFormkey(formkey + 1);
                    }
                });
            }
            else 
            {
                alert("No matching registration found");
            }
        });
    }
    const jobdropdownChange = (ev)=>{
        JobChanged(ev.value.id, options, values)
    }
    const JobChanged = (id, options, currentvalues) => {
        if (id === "1") {
            return;
        }
        let url = configData.SERVER_URL + "apiv7/jobs/" + id;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: {"Authorization": "Bearer " + GetToken()}
          }
        fetch(url, init).then(response => response.json()).then(d => {     
            let val = { ...currentvalues };
            if (d !== null && d.jobId !== 1) {
                let SelectedSite;
                let SelectedDestination;
                let SelectedSource;
                let SelectedTruck;
                let SelectedJob = options.jobs.find(obj => obj.id === String(d.jobId));
                if (d.siteList !== undefined && d.siteList !== null && d.siteList.length > 0) {
                    let sp = String(d.siteList).split(',');
                    SelectedSite = options.sites.find(obj => obj.id === String(sp[0]));
                    let x = [];
                    for (let i = 0; i < sp.length; i++) {
                        let sn = options.sites.find(obj => obj.id === sp[i]);
                        if (sn !== undefined) x.push({ id: sp[i], name: sn.name });
                    }
                    setSites(x);
                } else {
                    setSites([{ id: 1, name: "NA" }]);
                }
                if (d.sourceList !== undefined && d.sourceList !== null && d.sourceList.length > 0) {
                    let sp = String(d.sourceList).split(',');
                    SelectedSource = options.sources.find(obj => obj.id === String(sp[0]));
                    let x = [];
                    for (let i = 0; i < sp.length; i++) {
                        let sn = options.sources.find(obj => obj.id === sp[i]);
                        if (sn !== undefined) x.push({ id: sp[i], name: sn.name });
                    }
                    setSources(x);
                } else {
                    setSources([{ id: 1, name: "NA" }]);
                }
                if (d.destinationList !== undefined && d.destinationList !== null && d.destinationList.length > 0) {
                    let sp = String(d.destinationList).split(',');
                    SelectedDestination = options.destinations.find(obj => obj.id === String(sp[0]));
                    let x = [];
                    for (let i = 0; i < sp.length; i++) {
                        let sn = options.destinations.find(obj => obj.id === sp[i]);
                        if (sn !== undefined) x.push({ id: sp[i], name: sn.name });
                    }
                    setDestinations(x);
                } else {
                    setDestinations([{ id: 1, name: "NA" }]);
                }
                if (d.truckList !== undefined && d.truckList !== null && d.truckList.length > 0) {
                    let sp = String(d.truckList).split(',');
                    SelectedTruck = options.trucks.find(obj => obj.id === String(sp[0]));
                    let x = [];
                    for (let i = 0; i < sp.length; i++) {
                        let sn = options.trucks.find(obj => obj.id === sp[i]);
                        if (sn !== undefined) x.push({ id: sp[i], name: sn.name });
                    }
                    setTrucks(x);
                } else {
                    setTrucks([{ id: 1, name: "NA" }]);
                }
                let sn = options.customers.find(obj => obj.id === String(d.customerId));
                if (sn !== undefined) {
                    let x = [];
                    x.push(sn);
                    setCustomers(x);
                    val.jobId = SelectedJob;
                    val.customerId = sn;
                }
                if (SelectedSite !== undefined) val.siteId = SelectedSite;
                if (SelectedDestination !== undefined) val.destinationId = SelectedDestination;
                if (SelectedSource !== undefined) val.sourceId = SelectedSource;
                if (SelectedTruck !== undefined) val.truckId = SelectedTruck;
                priceList.current = d.jobProductPrices;
                if (d.jobProductPrices !== null && d.jobProductPrices.length > 0) {
                    let pid = parseInt(val.productId === undefined ? 0 : val.productId.id);
                    if (pid === 0 && d.jobProductPrices.length > 0) {
                        pid = d.jobProductPrices[0].productId;
                        val.productId = 0;
                    }
                    let jpp = d.jobProductPrices.find(obj => { return obj.productId === pid });
                    if (jpp === undefined) {
                        setPriceDisabled(false);
                    } else {
                        val.price = jpp.price;
                        setPriceDisabled(true);
                    }
                    let x = [];
                    for (let i = 0; i < d.jobProductPrices.length; i++) {
                        let p = options.products.find(obj => obj.id === String(d.jobProductPrices[i].productId));
                        if (p !== undefined) {
                            if (val.productId === 0) val.productId = p;
                            x.push(p);
                        }
                    }
                    setProducts(x);
                } else { // use product price and allow editing
                    setProducts(options.products);
                }
            } else {
                setSites(options.sites);
                setCustomers(options.customers);
                setProducts(options.products);
                setDestinations(options.destinations);
                setSources(options.sources);
                setTrucks(options.trucks);
            }
            setValues(val);
            setFormkey(formkey + 1);
        });
    }

    const productChanged = (ev) => {
        UpdateproductId(ev);
        let d = ev.value.data;
        let sp = ev.value.data.split(',');
        if (sp.length > 1) d = sp[0];
        let pc = options.productCategories.find(obj => { return obj.id === d });
        if (priceList.current === undefined) return;
        let val = { ...values };
        val.productCategoryId = pc;
        let pid = parseInt(ev.value.id);
        let jpp = priceList.current.find(obj => { return obj.productId === pid });
        if (jpp === undefined) {
            setPriceDisabled(false);
        } else {
            val.price = jpp.price;
            setPriceDisabled(true);
        }
        setValues(val);
        setFormkey(formkey + 1); 
    }

    const UpdatevehicleId = (e) => {
        let x = values;
        x.vehicleId = e.value;
        if (x.loadType === "Standard") {
            let pr = options.vehicles.find(obj => { return obj.id === e.value.id });
            if (pr !== undefined) x.price = pr.data;
        }
        setValues(x);
        setFormkey(formkey + 1); 
        setPriceDisabled(true);
    }

    const countedProductChanged = (e) => {
        setSelCountedProduct(e.value);
    }

    const addProduct = (e) => {
        e.preventDefault();
        if (selCountedProduct === undefined) return;
        let cp = [...countedProductList];
        let id = parseInt(selCountedProduct.id);
        let itm = countedProductList.find(obj => obj.productId === id);
        if (itm !== undefined) {
            itm.currentAmount += quantity;
        } else {
            var p = { productId: id, name: selCountedProduct.name, currentAmount: quantity };
            cp.push(p);
        }
        setCountedProductList(cp);
    }

    const quantityChanged = (e) => {
        setQuantity(parseInt(e.value));
    }

    const deleteProduct = (pr) => {
        let cp = countedProductList.filter(obj => (obj.productId !== pr.productId));
        setCountedProductList(cp);
     }

    const UpdatetransactionDate = (e) => { let x = values; x.transactionDate = e.value; setValues(x); }
    const UpdatetareInDate = (e) => { let x = values; x.tareInDate = e.value; setValues(x); }
    const Updateregistration1 = (e) => { let x = values; x.registration1 = e.value; setValues(x); }
    const Updateregistration2 = (e) => { let x = values; x.registration2 = e.value; setValues(x); }
    const Updateregistration3 = (e) => { let x = values; x.registration3 = e.value; setValues(x); }
    const Updatepayments = (e) => { let x = values; x.payments = e.value; setValues(x); }
    const Updatedirection = (e) => { let x = values; x.direction = e.value; setValues(x); }
    const UpdatechargeRate = (e) => { let x = values; x.chargeRate = e.value; setValues(x); }
    const UpdatevehicleOwner = (e) => { let x = values; x.vehicleOwner = e.value; setValues(x); }
    const UpdateorderNumber = (e) => { let x = values; x.orderNumber = e.value; setValues(x); }
    const UpdateloadType = (e) => { let x = values; x.loadType = e.value; setValues(x); setLoadType(e.value.toUpperCase()); }
    const UpdatetareDetails = (e) => { let x = values; x.tareDetails = e.value; setValues(x); }
    const Updategross1 = (e) => { let x = values; x.gross1 = e.value; setValues(x); }
    const Updategross2 = (e) => { let x = values; x.gross2 = e.value; setValues(x); }
    const Updategross3 = (e) => { let x = values; x.gross3 = e.value; setValues(x); }
    const Updategross4 = (e) => { let x = values; x.gross4 = e.value; setValues(x); }
    const Updategross5 = (e) => { let x = values; x.gross5 = e.value; setValues(x); }
    const Updatetare1 = (e) => { let x = values; x.tare1 = e.value; setValues(x); }
    const Updatetare2 = (e) => { let x = values; x.tare2 = e.value; setValues(x); }
    const Updatetare3 = (e) => { let x = values; x.tare3 = e.value; setValues(x); }
    const Updatetare4 = (e) => { let x = values; x.tare4 = e.value; setValues(x); }
    const Updatetare5 = (e) => { let x = values; x.tare5 = e.value; setValues(x); }
    const Updatenet = (e) => { let x = values; x.net = e.value; setValues(x); }
    const UpdatemaxLoad = (e) => { let x = values; x.maxLoad = e.value; setValues(x); }
    const UpdatesiteId = (e) => { let x = values; x.siteId = e.value; setValues(x); }
    const UpdatecustomerId = (e) => { let x = values; x.customerId = e.value; setValues(x); }
    const UpdateproductId = (e) => { let x = values; x.productId = e.value; setValues(x); }
    const UpdateproductCategoryId = (e) => { let x = values; x.productCategoryId = e.value; setValues(x); }
    const UpdatedestinationId = (e) => { let x = values; x.destinationId = e.value; setValues(x); }
    const UpdatesourceId = (e) => { let x = values; x.sourceId = e.value; setValues(x); }
    const UpdatevehicleConfigurationId = (e) => { let x = values; x.vehicleConfigurationId = e.value; setValues(x); }
    const UpdatetruckId = (e) => { let x = values; x.truckId = e.value; setValues(x); }
    const UpdatedriverId = (e) => { let x = values; x.driverId = e.value; setValues(x); }
    const UpdateuserId = (e) => { let x = values; x.userId = e.value; setValues(x); }
    const UpdatevinNumber = (e) => { let x = values; x.vinNumber = e.value; setValues(x); }
    const UpdatesubStream = (e) => { let x = values; x.subStream = e.value; setValues(x); }
    const UpdatedestinationPurpose = (e) => { let x = values; x.destinationPurpose = e.value; setValues(x); }
    const Updateprice = (e) => { let x = values; x.price = e.value; setValues(x); }
    const UpdateepaRate = (e) => { let x = values; x.epaRate = e.value; setValues(x); }
    const UpdatepenaltyCharge = (e) => { let x = values; x.penaltyCharge = e.value; setValues(x); }
    const Updatehaulage = (e) => { let x = values; x.haulage = e.value; setValues(x); }
    const UpdateestimatedWeight = (e) => { let x = values; x.estimatedWeight = e.value; setValues(x); }
    const Updatecomment = (e) => { let x = values; x.comment = e.value; setValues(x); }
    const UpdatedeliveryAddress = (e) => { let x = values; x.deliveryAddress = e.value; setValues(x); }
    const UpdatedeliverySuburb = (e) => { let x = values; x.deliverySuburb = e.value; setValues(x); }
    const UpdatedeliveryState = (e) => { let x = values; x.deliveryState = e.value; setValues(x); }
    const UpdatedeliveryPostcode = (e) => { let x = values; x.deliveryPostcode = e.value; setValues(x); }
    const UpdatedeliveryPhone = (e) => { let x = values; x.deliveryPhone = e.value; setValues(x); }
    const UpdatedeliveryContact = (e) => { let x = values; x.deliveryContact = e.value; setValues(x); }
   
    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) =>  value ? "" : "Please select an option";
    return (
        <div style={{padding: 20}}>
           <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
           <FormElement style={{width: "95%"}} >
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("transactionDetails")["isVisible"] && <TabStripTab title={GetField("transactionDetails")["label"]}>

                        <div className="row">
                            {GetField("transactionDate")["isVisible"] && <div className="col-md-3"> 
                                <Field id={"transactionDate"} name={"transactionDate"} label={GetField("transactionDate")["label"]} component={FormDateTimePicker} onChange={UpdatetransactionDate}
                                    wrapperStyle={{ width: "90%", marginRight: "18px",}}/>
                            </div>}
                            {GetField("tareInDate")["isVisible"] && <div className="col-md-3"> 
                                <Field id={"tareInDate"} name={"tareInDate"} label={GetField("tareInDate")["label"]} component={FormDateTimePicker} onChange={UpdatetareInDate}
                                    wrapperStyle={{ width: "90%", marginRight: "18px",}}/>
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("registration1")["isVisible"] && <div className="col-md-3">
                                <Field id={"registration1"} name={"registration1"} label={GetField("registration1")["label"]} component={FormInput} validator={textValidator} onChange={Updateregistration1}/>
                            </div>}
                            {GetField("registration1")["isVisible"] && <div className='col-md-3' style={{paddingTop: "45px"}}>
                                <Button onClick={LookupRego}>Search</Button>
                            </div>}                            
                            {GetField("registration2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"registration2"} name={"registration2"} label={GetField("registration2")["label"]} component={FormInput} onChange={Updateregistration2}/>
                            </div>}                            
                            {GetField("registration3")["isVisible"] && <div className='col-md-3'>
                                <Field id={"registration3"} name={"registration3"} label={GetField("registration3")["label"]} component={FormInput} onChange={Updateregistration3}/>
                            </div>}                            
                        </div>                        
                        <div className='row' >
                            {GetField("payments")["isVisible"] && <div className='col-md-3'>
                                <Field id={"payments"} name={"payments"} label={GetField("payments")["label"]} component={FormDropDownList} data={payments} validator={optionValidator} onChange={Updatepayments}/>
                            </div>}                           
                            {GetField("direction")["isVisible"] && <div className='col-md-3'>
                                <Field id={"direction"} name={"direction"} label={GetField("direction")["label"]} component={FormDropDownList} data={directions} validator={optionValidator} onChange={Updatedirection} />
                            </div>}                           
                            {GetField("chargeRate")["isVisible"] && <div className='col-md-3'>
                                <Field id={"chargeRate"} name={"chargeRate"} label={GetField("chargeRate")["label"]} component={FormDropDownList} data={chargeRates} validator={optionValidator} onChange={UpdatechargeRate}/>
                            </div>}                           
                            {GetField("vehicleOwner")["isVisible"] && <div className='col-md-3'>
                                <Field id={"vehicleOwner"} name={"vehicleOwner"} label={GetField("vehicleOwner")["label"]} component={FormDropDownList} data={owners} validator={optionValidator} onChange={UpdatevehicleOwner}/>
                            </div>}                           
                        </div>                        
                        <div className='row' >
                            {GetField("orderNumber")["isVisible"] && <div className='col-md-3'>
                                <Field id={"orderNumber"} name={"orderNumber"} label={GetField("orderNumber")["label"]} component={FormInput} onChange={UpdateorderNumber}/>
                            </div>}                            
                            {GetField("loadType")["isVisible"] && <div className='col-md-3'>
                                <Field id={"loadType"} name={"loadType"} label={GetField("loadType")["label"]} component={FormDropDownList} data={loadTypes} validator={optionValidator} onChange={UpdateloadType}/>
                            </div>}                           
                        </div>                        
                        </TabStripTab>}
                        {GetField("tareDetails")["isVisible"] && <TabStripTab title={GetField("tareDetails")["label"]}>
                        <div className="row">
                            {GetField("tare1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare1"} name={"tare1"} label={GetField("tare1")["label"]} component={FormNumericTextBox} min={0} max={100000}  validator={textValidator} onChange={Updatetare1}/>
                            </div>}
                            {GetField("gross1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"gross1"} name={"gross1"} label={GetField("gross1")["label"]} component={FormNumericTextBox} min={0} max={100000} validator={textValidator} onChange={Updategross1}/>
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("tare2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare2"} name={"tare2"} label={GetField("tare2")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatetare2} />
                            </div>}
                            {GetField("gross2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"gross2"} name={"gross2"} label={GetField("gross2")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updategross2}/>
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("tare3")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare3"} name={"tare3"} label={GetField("tare3")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatetare3} />
                            </div>}
                            {GetField("gross3")["isVisible"] && <div className='col-md-3'>
                                <Field id={"gross3"} name={"gross3"} label={GetField("gross3")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updategross3}/>
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("tare4")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare4"} name={"tare4"} label={GetField("tare4")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatetare4} />
                            </div>}
                            {GetField("gross4")["isVisible"] && <div className='col-md-3'>
                                <Field id={"gross4"} name={"gross4"} label={GetField("gross4")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updategross4}/>
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("tare5")["isVisible"] && <div className='col-md-3'>
                                <Field id={"tare5"} name={"tare5"} label={GetField("tare5")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatetare5} />
                            </div>}
                            {GetField("gross5")["isVisible"] && <div className='col-md-3'>
                                <Field id={"gross5"} name={"gross5"} label={GetField("gross5")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updategross5}/>
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("net")["isVisible"] && <div className='col-md-3'>
                                <Field id={"net"} name={"net"} label={GetField("net")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatenet}/>
                            </div>}
                            {GetField("maxLoad")["isVisible"] && <div className='col-md-3'>
                                <Field id={"maxLoad"} name={"maxLoad"} label={GetField("maxLoad")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={UpdatemaxLoad}/>
                            </div>}
                        </div>
                        </TabStripTab>}
                            {GetField("countedProducts")["isVisible"] && id === undefined && (loadType === "COUNTED" || loadType === "MIXED") && <TabStripTab title={GetField("countedProducts")["label"]}>
                            <div className='row'>
                                {GetField("countedProduct")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"countedProduct"} name={"countedProduct"} label={GetField("countedProduct")["label"]} component={FormDropDownList} data={countedProducts} textField="name" onChange={countedProductChanged} />
                                </div>}
                                {GetField("countedQuantity")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"countedQuantity"} name={"countedQuantity"} label={GetField("countedQuantity")["label"]} component={FormNumericTextBox} min={1} max={100000} defaultValue={quantity} onChange={quantityChanged} />
                                </div>}
                                {GetField("countedAdd")["isVisible"] && <div className='col-md-1' style={{ paddingTop: "35px" }}>
                                        <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={addProduct}>{GetField("countedAdd")["label"]}</Button>
                                </div>}
                            </div>                   
                                <div className='row' style={{marginTop: "20px"}} >
                                    {GetField("productList")["isVisible"] && <div>
                                        <FormElement>
                                        <FieldArray name="countedProducts" component={FormGrid} productList={countedProductList} deleteProduct={deleteProduct} />
                                        </FormElement>
                                </div>}
                            </div> 
                        </TabStripTab>}
                        {GetField("otherDetails")["isVisible"] && <TabStripTab title={GetField("otherDetails")["label"]}>
                        <div className='row' >
                            {GetField("siteId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"siteId"} name={"siteId"} label={GetField("siteId")["label"]} component={FormDropDownList} data={sites} textField="name" validator={optionValidator} onChange={UpdatesiteId} />
                            </div>}                           
                            {GetField("jobId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobId"} name={"jobId"} label={GetField("jobId")["label"]} onChange={jobdropdownChange} component={FormDropDownList} data={jobs} textField="name" validator={optionValidator} />
                            </div>}                           
                            {GetField("customerId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"customerId"} name={"customerId"} label={GetField("customerId")["label"]} component={FormDropDownList} data={customers} textField="name"  validator={optionValidator} onChange={UpdatecustomerId} />
                            </div>}                           
                            {GetField("productId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"productId"} name={"productId"} label={GetField("productId")["label"]} onChange={productChanged} component={FormDropDownList} data={products} textField="name"  validator={optionValidator} />
                            </div>}                           
                        </div>                        
                        <div className='row' >
                            {GetField("productCategoryId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"productCategoryId"} name={"productCategoryId"} label={GetField("productCategoryId")["label"]} component={FormDropDownList} data={productCategories} textField="name" validator={optionValidator} onChange={UpdateproductCategoryId} />
                            </div>}                           
                            {GetField("destinationId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"destinationId"} name={"destinationId"} label={GetField("destinationId")["label"]} component={FormDropDownList} data={destinations} textField="name" validator={optionValidator} onChange={UpdatedestinationId} />
                            </div>}                           
                            {GetField("sourceId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"sourceId"} name={"sourceId"} label={GetField("sourceId")["label"]} component={FormDropDownList} data={sources} textField="name" validator={optionValidator} onChange={UpdatesourceId} />
                            </div>}                           
                            {GetField("vehicleConfigurationId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"vehicleConfigurationId"} name={"vehicleConfigurationId"} label={GetField("vehicleConfigurationId")["label"]} component={FormDropDownList} data={vehicleConfigurations} textField="name" validator={optionValidator} onChange={UpdatevehicleConfigurationId} />
                            </div>}                           
                        </div>                        
                        <div className='row' >
                            {GetField("vehicleId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"vehicleId"} name={"vehicleId"} label={GetField("vehicleId")["label"]} component={FormDropDownList} data={vehicles} textField="name" validator={optionValidator} onChange={UpdatevehicleId} />
                            </div>}                           
                            {GetField("truckId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"truckId"} name={"truckId"} label={GetField("truckId")["label"]} component={FormDropDownList} data={trucks} textField="name"  validator={optionValidator} onChange={UpdatetruckId} />
                            </div>}                           
                            {GetField("driverId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"driverId"} name={"driverId"} label={GetField("driverId")["label"]} component={FormDropDownList} data={drivers} textField="name"  validator={optionValidator} onChange={UpdatedriverId} />
                            </div>}                           
                            {GetField("userId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"userId"} name={"userId"} label={GetField("userId")["label"]} component={FormDropDownList} data={users} textField="name"  validator={optionValidator} onChange={UpdateuserId} />
                            </div>}                           
                        </div>                        
                        <div className='row' >
                            {GetField("vINNumber")["isVisible"] && <div className='col-md-3'>
                                <Field id={"vinNumber"} name={"vinNumber"} label={GetField("vINNumber")["label"]} component={FormInput} onChange={UpdatevinNumber} />
                            </div>}                            
                            {GetField("subStream")["isVisible"] && <div className='col-md-3'>
                                <Field id={"subStream"} name={"subStream"} label={GetField("subStream")["label"]} component={FormDropDownList} data={subStreams} onChange={UpdatesubStream} />
                            </div>}                           
                            {GetField("destinationPurpose")["isVisible"] && <div className='col-md-3'>
                                <Field id={"destinationPurpose"} name={"destinationPurpose"} label={GetField("destinationPurpose")["label"]} component={FormDropDownList} data={destinationPurposes} onChange={UpdatedestinationPurpose}/>
                            </div>}                           
                        </div>                        
                        <div className='row' >
                            {GetField("price")["isVisible"] && <div className='col-md-3'>
                                <Field id={"price"} name={"price"} label={GetField("price")["label"]} disabled={priceDisabled} component={FormNumericTextBox} min={0} max={100000} onChange={Updateprice} />
                            </div>}
                            {GetField("epaRate")["isVisible"] && <div className='col-md-3'>
                                <Field id={"epaRate"} name={"epaRate"} label={GetField("epaRate")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={UpdateepaRate} />
                            </div>}
                            {GetField("penaltyCharge")["isVisible"] && <div className='col-md-3'>
                                <Field id={"penaltyCharge"} name={"penaltyCharge"} label={GetField("penaltyCharge")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={UpdatepenaltyCharge} />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("haulage")["isVisible"] && <div className='col-md-3'>
                                <Field id={"haulage"} name={"haulage"} label={GetField("haulage")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatehaulage} />
                            </div>}
                            {GetField("estimatedWeight")["isVisible"] && <div className='col-md-3'>
                                <Field id={"estimatedWeight"} name={"estimatedWeight"} label={GetField("estimatedWeight")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={UpdateestimatedWeight} />
                            </div>}
                            {GetField("comment")["isVisible"] && <div className="col-md-3">
                                <Field id={"comment"} name={"comment"} label={GetField("comment")["label"]} component={FormTextArea} onChange={Updatecomment}/>
                            </div>}
                        </div>                        
                        </TabStripTab>}
                        {GetField("deliveryDetails")["isVisible"] && <TabStripTab title={GetField("deliveryDetails")["label"]}>
                        <div className='row' >
                            {GetField("deliveryAddress")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryAddress"} name={"deliveryAddress"} label={GetField("deliveryAddress")["label"]} component={FormInput} onChange={UpdatedeliveryAddress} />
                            </div>}
                            {GetField("deliverySuburb")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliverySuburb"} name={"deliverySuburb"} label={GetField("deliverySuburb")["label"]} component={FormInput} onChange={UpdatedeliverySuburb} />
                            </div>}
                            {GetField("deliveryState")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryState"} name={"deliveryState"} label={GetField("deliveryState")["label"]} component={FormDropDownList} data={states} onChange={UpdatedeliveryState} />
                            </div>}
                            {GetField("deliveryPostcode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryPostcode"} name={"deliveryPostcode"} label={GetField("deliveryPostcode")["label"]} component={FormInput} onChange={UpdatedeliveryPostcode} />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("deliveryPhone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryPhone"} name={"deliveryPhone"} label={GetField("deliveryPhone")["label"]} component={FormInput} onChange={UpdatedeliveryPhone} />
                            </div>}
                            {GetField("deliveryContact")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryContact"} name={"deliveryContact"} label={GetField("deliveryContact")["label"]} component={FormInput} onChange={UpdatedeliveryContact} />
                            </div>}
                        </div>
                        </TabStripTab>}
                        </TabStrip>                        
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Cancel</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditTransactions