import * as React from 'react';
import { Label } from '@progress/kendo-react-labels';
import redlight from '../assets/redlight.png'
import greenlight from '../assets/greenlight.png'
import gateclosed from '../assets/gateclosed.png'
import gateopen from '../assets/gateopen.png'

// Traffic control manages lights and boom gates
// It supports up to 2 traffic lights and 2 boom gates
// Captions will be business data Name
// Clicking image open and close gate or change red/green of lights
// If light and gate has same Name they will work as pair, so clicking one changes both
// Pass the props.lightClicked callback to receive Id and Value
export const TrafficControl = (props) => {
    const LightClicked = props.lightClicked;
    const lights = props.lights.Lights;
    const gates = props.lights.Gates;
    const l1Div = React.useRef();
    const l2Div = React.useRef();
    const g1Div = React.useRef();
    const g2Div = React.useRef();
    const [showLight, setShowLight] = React.useState(true);
    const [showGate, setShowGate] = React.useState(true);

    let now = new Date().getTime() - 10000;
    if (l1Div.current !== undefined) {
        if (lights.length > 0 && lights[0].Updated.getTime() < now)
            l1Div.current.classList.add("DeviceOffline");
        else
            l1Div.current.classList.remove("DeviceOffline");
    }
    if (l2Div.current !== undefined) {
        if (lights.length > 1 && lights[1].Updated.getTime() < now)
            l2Div.current.classList.add("DeviceOffline");
        else
            l2Div.current.classList.remove("DeviceOffline");
    }
    if (g1Div.current !== undefined) {
        if (gates.length > 0 && gates[0].Updated.getTime() < now)
            g1Div.current.classList.add("DeviceOffline");
        else
            g1Div.current.classList.remove("DeviceOffline");
    }
    if (g2Div.current !== undefined) {
        if (gates.length > 1 && gates[1].Updated.getTime() < now)
            g2Div.current.classList.add("DeviceOffline");
        else
            g2Div.current.classList.remove("DeviceOffline");
    }
     

    var light1 = (lights.length > 0 && String(lights[0].Value).toLowerCase() === "green") ? greenlight : redlight;
    var light2 = (lights.length > 1 && String(lights[1].Value).toLowerCase() === "green") ? greenlight : redlight;
    var inGate = (gates.length > 0 && String(gates[0].Value).toLowerCase() === "open") ? gateopen : gateclosed;
    var outGate = (gates.length > 1 && String(gates[1].Value).toLowerCase() === "open") ? gateopen : gateclosed;

    const Click1 = () => ChangeLight(lights[0], String(lights[0].Value).toLowerCase() === "green" ? "Red" : "Green");
    const Click2 = () => ChangeLight(lights[1], String(lights[1].Value).toLowerCase() === "green" ? "Red" : "Green");
    const Click3 = () => ChangeGate(gates[0], String(gates[0].Value).toLowerCase() === "open" ? "Closed" : "Open");
    const Click4 = () => ChangeGate(gates[1], String(gates[1].Value).toLowerCase() === "open" ? "Closed" : "Open");
    const ChangeLight = (light, value) => {
        if (LightClicked === undefined) return;
        LightClicked(light.Id, value);
        let g = gates.find(obj => obj.Name === light.Name);
        if (g !== undefined) LightClicked(g.Id, value === "Green" ? "Open" : "Closed");
    }
    const ChangeGate = (gate, value) => {
        if (LightClicked === undefined) return;
        LightClicked(gate.Id, value);
        let l = lights.find(obj => obj.Name == gate.Name);
        if (l !== undefined) LightClicked(l.Id, value === "Open" ? "Green" : "Red");
    }

    const ClickLight = () => {
        setShowGate(true);
        setShowLight(false);
    }

    const ClickGate = () => {
        if (!showLight) { 
            setShowLight(true);
            setShowGate(true);
        } else {
            setShowLight(true);
            setShowGate(false);
        }
    }

    const MouseOver = (e) => {
        console.log(e);
    }
    return (
        <div style={{ display: "flex", marginRight: "10px" }} >
            {lights.length > 0 && <div className="gates" ref={l1Div}>
                {showLight && <Label className="tlLabel"><div onClick={ClickLight}>{lights[0].Name}</div></Label>}
                {showLight && <div><img src={light1} title={lights[0].Name} onClick={Click1}></img></div>}
            </div>}
            {lights.length > 1 && <div className="gates" ref={l2Div}>
                {showLight && <Label className="tlLabel"><div onClick={ClickLight}>{lights[1].Name}</div></Label>}
                {showLight && <div><img src={light2} title={lights[1].Name} onClick={Click2}></img></div>}
                </div>}
            {gates.length > 0 && <div className="gates" ref={g1Div}>
                {showGate && <Label className="tlLabel"><div onClick={ClickGate}>{gates[0].Name}</div></Label>}
                {showGate && <div><img src={inGate} title={gates[0].Name} onClick={Click3}></img></div>}
            </div>}
            {gates.length > 1 && <div className="gates" ref={g2Div}>
                {showGate && <Label className="tlLabel"><div onClick={ClickGate}>{gates[1].Name}</div></Label>}
                {showGate && <div><img src={outGate} title={gates[1].Name} onClick={Click4}></img></div>}
            </div>}
        </div>
    );
}