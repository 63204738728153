import * as React from "react";
import Notifications from '../components/Notifications';
import DataGrid from "../components/DataGrid";
import GetColumnDefs from "../components/ColumnDefs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextBox } from "@progress/kendo-react-inputs";
import configData from "../config.json";
import { GetSession, GetToken } from "../components/Session";
import { GetPermissions } from '../components/Options';
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const [lookupParams, setLookupParams] = React.useState("");
  const [sources, setSources] = React.useState([]);
  const [source, setSource] = React.useState();
  const [destinations, setDestinations] = React.useState([]);
  const [destination, setDestination] = React.useState();
  const [sites, setSites] = React.useState([]);
  const [site, setSite] = React.useState();
  const [directions, setDirections] = React.useState([]);
  const [direction, setDirection] = React.useState();
  const [stockLevel, setStockLevel] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const ready = React.useRef(false);
  const [columns, setColumns] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [permissions, setPermissions] = React.useState(null);
  const navigate = useNavigate();
  if (permissions && !permissions.CanView) navigate("/error401");
  const [layout, setLayout] = React.useState([
    { "field": "searchAdvanced", "label": "Advanced Search", "isVisible": true },
    { "field": "searchSite", "label": "Site", "isVisible": true },
    { "field": "searchSource", "label": "Source", "isVisible": true },
    { "field": "searchDestination", "label": "Destination", "isVisible": true },
    { "field": "searchDirection", "label": "Direction", "isVisible": true },
    { "field": "stockLevelBelow", "label": "Show Stock Level Below", "isVisible": true }
  ]);

  const GetField = (f) => {
      return layout.find(obj => { return obj.field === f });
  }

  const sourceChanged = (event) => {
    setSource(event.target.value);
  };
  const destinationChanged = (event) => {
    setDestination(event.target.value);
  };
  const siteChanged = (event) => {
    setSite(event.target.value);
  };
  const directionChanged = (event) => {
    setDirection(event.target.value);
  };
  const stockLevelChanged = (event) => {
    setStockLevel(event.target.value);
  }
  const DoSearch = () => {
    if (ready.current) {
      var prodParam = {
        Source: source.id,
        Destination: destination.id,
        Site: site.id,
        Direction: direction,
        StockLevel: parseInt(stockLevel)
      };
      setLookupParams(encodeURI(JSON.stringify(prodParam)));
    }
  };
  const ClearSearch = () => {
    if (ready.current) {
      ClearParams();
      var jobParam = {
        Source: "0",
        Destination: "0",
        Site: "0",
        StockLevel: ""
      };
      setLookupParams(encodeURI(JSON.stringify(jobParam)));
    }
  };
  const ClearParams = () => {
    setSource({ id: "0", name: "All" });
    setDestination({ id: "0", name: "All" });
    setSite({ id: "0", name: "All" });
    setDirection("All");
    setStockLevel("");
  };

  React.useEffect(() => {
    GetPermissions(["ViewProduct"], setPermissions);
    GetColumnDefs("products", setColumns, setErrorMessage);
    GetPermissions(["ViewProduct", "NewProduct", "EditProduct", "DeleteProduct", "ReportProduct"], setPermissions)
    let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'Products'";
    const init = {
      method: "GET",
      accept: "application/json",
      headers: {"Authorization": "Bearer " + GetToken()},
    };
    fetch(baseUrl, init).then(response => response.json()).then(d => { 
      if (GetSession().IsMobile){
          let data = [];
          for(let i = 0; i < d.length; i++){
              data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
          }
          setLayout(data);
      } else {
          setLayout(d); 
      }
    }); 
    baseUrl = configData.SERVER_URL + "apiv7/options";
    fetch(baseUrl, init)
      .then((response) => response.json())
      .then((d) => {
        setSources(CreateList(d.sources));
        setSource({ id: "0", name: "All" });
        setDestinations(CreateList(d.destinations));
        setDestination({ id: "0", name: "All" });
        setSites(CreateList(d.sites));
        setSite({ id: "0", name: "All" });
        setDirections(d.directions);
        setDirection("All");
        ready.current = true;
      });
  }, []);
  
  const CreateList = (d) => {
    var x = [{ id: "0", name: "All" }];
    return x.concat(d);
  };

  const RowStyle = (p) => {
      let bg = { backgroundColor: "white" };
      if (p.dataItem.stockLevel < p.dataItem.minimumStockLevel) {
          bg = { backgroundColor: "rgb(243, 23, 0, 0.5)" };
      } else {
          if (p.dataItem.stockLevel < p.dataItem.minimumStockLevel * 1.2) bg = { backgroundColor: "rgb(255, 255, 127, 1)" };
      }
      return bg;
  }

  return (
    <div>
        <Notifications errorMessage={errorMessage}/>
        {GetField("searchAdvanced")["isVisible"] && <ExpansionPanel
        title={GetField("searchAdvanced")["label"]}
        expandIcon="fa fa-plus"
        collapseIcon="fa fa-minus"
        expanded={expanded}
        onAction={(e) => setExpanded(!e.expanded)}
      >
        <Reveal>
          {expanded && (
            <ExpansionPanelContent>
              <div className="row" style={{ padding: "5px 10px" }}>
                {GetField("searchSite")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchSite")["label"]}</label>
                  <DropDownList
                    id="Site"
                    data={sites}
                    value={site}
                    textField="name"
                    dataItemKey="id"
                    onChange={siteChanged}
                  />
                </div>}
                {GetField("searchSource")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchSource")["label"]}</label>
                  <DropDownList
                    id="Source"
                    data={sources}
                    value={source}
                    textField="name"
                    dataItemKey="id"
                    onChange={sourceChanged}
                  />
                </div>}
                {GetField("searchDestination")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchDestination")["label"]}</label>
                  <DropDownList
                    id="Destination"
                    data={destinations}
                    value={destination}
                    textField="name"
                    dataItemKey="id"
                    onChange={destinationChanged}
                  />
                </div>}
                {GetField("searchDirection")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchDirection")["label"]}</label>
                  <DropDownList
                    id="Direction"
                    data={directions}
                    value={direction}
                    onChange={directionChanged}
                  />
                </div>}
              </div>
              <div className="row" style={{ padding: "10px" }}>
                {GetField("stockLevelBelow")["isVisible"] && <div className="col-md-3">
                 <label className="control-label">{GetField("stockLevelBelow")["label"]}</label>
                 <TextBox id="StockLevelBelow" type="number" value={stockLevel} onChange={stockLevelChanged } />
                </div>}
              </div>
              <div className="row" style={{ padding: "10px" }}>
                <div className="col-md-5"></div>
                <div className="col-md-3">
                  <Button onClick={DoSearch} themeColor={"primary"}>
                    Search
                  </Button>{" "}
                  <Button onClick={ClearSearch} themeColor={"primary"}>
                    Clear
                  </Button>
                </div>
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>}
      {columns && permissions && <DataGrid
              dataSource="apiv7/products"
              params={lookupParams}
              dataFilter="productType eq 'W'"
              extraParam="/W"
              columns={columns}
              permissions={permissions}
              keyField="productId"
              rowStyle={RowStyle}
      />}
    </div>
  );
};

export default Products;
