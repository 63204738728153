import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { Button } from '@progress/kendo-react-buttons';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import Notifications from '../components/Notifications';

const Inventory = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");
    React.useEffect(() => {
        GetColumnDefs("InventoryMovements", setColumns);
        GetPermissions(["ViewInventory", "ManageInventory", "ReportInventory"], setPermissions)
    }, [])
    return (
        <div>
            <Notifications errorMessage={errorMessage} />
            {permissions && permissions.ManageInventory && <div className="row" style={{ padding: "5px 10px" }}>
                <div className="col-md-2">
                    <a href="/ModifyInventory/Add"><Button themeColor={"primary"}>Add</Button></a>
                </div>
                <div className="col-md-2">
                    <a href="/ModifyInventory/Deduct"><Button themeColor={"primary"}>Deduct</Button></a>
                </div>
                <div className="col-md-2">
                    <a href="/ModifyInventory/Stock Take"><Button themeColor={"primary"}>Stock Take</Button></a>
                </div>
                <div className="col-md-2">
                    <a href="InventoryHistory"><Button themeColor={"primary"}>History</Button></a>
                </div>
            </div>}
            <div className="row">
                {columns && permissions && <DataGrid dataSource='apiv7/stock' sortable={false} columns={columns} permissions={permissions} />}
            </div>
        </div>
    );

}

export default Inventory;