import * as React from 'react';
import configData from "../config.json";
import { GetToken } from '../components/Session';
import { GetSetting, GetRawSetting, UpdateWeights, UpdateLights, UpdateRemoteDisplay, LightClicked, UpdateCameras, GetStreamingUrl } from "./Helpers";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { WeightMeter } from './WeightMeter';
import DataGrid from '../components/DataGrid';
import { TrafficControl } from './TrafficControl';
import { RemoteDisplay } from './RemoteDisplay';
import RealTimeTile from "../pages/trend/RealTimeTile";
import { TextArea } from "@progress/kendo-react-inputs";
import { GetPermissions } from '../components/Options';
import Iframe from 'react-iframe'
import './Mobile.scss';

const Mobile = () => {
    const site = React.useRef();
    const [weights, setWeights] = React.useState([0, 0, 0, 0, 0, 0]);
    const businessData = React.useRef(null);
    const [lights, setLights] = React.useState({ Lights: [], Gates: [] });
    const [cameraImages, setCameraImages] = React.useState([]);
    const [cameraNames, setCameraNames] = React.useState([]);
    const [display, setDisplay] = React.useState({ Text: "", Weight: 0, MessageTime: 0, WeightTime: 1000 });
    const weight = React.useRef([0, 0, 0, 0, 0, 0]);
    const wbSettings = React.useRef({ Decks: 1, MaxWeight: 60 });
    const settings = React.useRef("");
    const weightStatus = React.useRef();
    const [showTraffic, setShowTraffic] = React.useState(true);
    const [showRemote, setShowRemote] = React.useState(true);
    const [showWeight, setShowWeight] = React.useState(true);
    const [showCameras, setShowCameras] = React.useState(false);
    const [streamUrl, setStreamUrl] = React.useState();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [docket, setDocket] = React.useState("");
    var running = 0;
    const lastRefreshed = React.useRef(0);
    const [columns, setColumns] = React.useState([
        { field: 'firstWeighId', label: 'Id', type: 'numeric', format: null, isVisible: false },
        { field: 'name', label: 'Rego', type: 'text', format: null, isVisible: true },
        { field: 'tareInDate', label: 'Date Time', type: 'date', format: 'd/MM/yy hh:mm', isVisible: true },
        { field: 'tare', label: 'Gross Weight (t)', type: 'numeric', format: '{0:n2}', isVisible: true }
    ]);
    const [permissions, setPermissions] = React.useState(null);


    React.useEffect(() => {
        GetPermissions(["ViewFirstWeigh"], setPermissions);
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        let url = `${configData.SERVER_URL}apiv7/private/getsitesettings?siteId=${configData.SITE}`;
        fetch(url, init).then(response => response.json()).then(d => {
            if (d.length > 0) {
                settings.current = d;
                wbSettings.current = {
                    Decks: GetRawSetting(d, "IsMultiDeckWeighbridge") ? GetRawSetting(d, "NoOfDecks") : 1,
                    MaxWeight: GetRawSetting(d, "MaximumWeight")
                };
                setShowCameras(!GetRawSetting(d, "HideSurveillanceCameras"));
                setShowTraffic(!GetRawSetting(d, "HideTrafficLight"));
                setShowRemote(!GetRawSetting(d, "HideRemoteDisplay"));
                setShowWeight(!GetRawSetting(d, "HideWeightController"));
            }
        }).catch((error) => {
            console.log("Get site settings fetch error: " + error.message);
        });
        if (running == 0) {
            running = 1;
            GetBusinessData();
        }
    }, []);

    const GetBusinessData = () => {
        var url = `${configData.SERVER_URL}apiv7/businessdata?$filter=isActive eq true and siteId eq ${configData.SITE}&$orderby=prompt`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            let old = JSON.stringify(businessData.current);
            let upd = JSON.stringify(d);
            if (old != upd || new Date().getTime() > lastRefreshed.current + 10000) {
                businessData.current = d;
                lastRefreshed.current = new Date().getTime();
                let ws = UpdateWeights(d, weights, wbSettings.current.Decks);
                weight.current = ws.weights;
                let w = ws.weights;
                weightStatus.current = ws.status;
                setWeights(w);
                setLights(UpdateLights(d));
                setDisplay(UpdateRemoteDisplay(d));
                setDocket(GetDocket(d));
                let ci = [];
                let cn = [];
                let showanpr = !GetSetting(settings, "HideANPRCamera");
                d.map((x) => {
                    if (x.dataType === "camera" && x.value !== "") {
                        let conf = x.devices[0].configuration;
                        let j = JSON.parse(conf);
                        let img = x.value;
                        if (showanpr || !j.ANPR) {
                            cn.push(x.name);
                            ci.push(img);
                        }
                    }
                });
                setCameraImages(ci);
                if (cameraNames.length === 0) setCameraNames(cn);
            }
            SetBusinessDataTimer();
        }).catch((error) => {
            console.log("GetBusinessData fetch error: " + error.message);
            SetBusinessDataTimer();
        });
    }

    const SetBusinessDataTimer = () => {
        setTimeout(() => GetBusinessData(), 500);
    }

    const GetDocket = (d) => {
        let r = d.find(obj => obj.dataType === "printer");
        if (r === undefined) return "";
        return r.value;
    }

    const CameraClicked = (e) => {
 //       let u = GetStreamingUrl(e, businessData.current);
//        setStreamUrl(u);
    }

    const CloseStreaming = () => {
        setStreamUrl(undefined);
    }

    const handleTabSelect = (e) => { setSelectedTab(e.selected); };

    return (
        <div>
            <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                <TabStripTab title="Weighbridge">
                    <div id="MobileView">
                    <div id="MobileWM">
                        {showWeight && <WeightMeter
                            weights={weights}
                            settings={settings}
                            gaugeColour="blue"
                            maxWeight={wbSettings.current.MaxWeight}
                            width="388"
                            decks={wbSettings.current.Decks}
                            allowSplit={false}
                           />}
                    </div>
                        {showTraffic && <div id="Traffic"><TrafficControl lights={lights} lightClicked={LightClicked} /></div>}
                        {showRemote && <div id="Remote"><RemoteDisplay display={display} /></div>}
                    </div>
                </TabStripTab>
                {showCameras && <TabStripTab title="Cameras">
                    <div id="MobileCameraPanelDiv">
                        {cameraImages.length > 0 && <div><img src={cameraImages[0]} className={"MobileSnapshot"} onClick={() => CameraClicked(1)} /></div>}
                        {cameraImages.length > 1 && <div><img src={cameraImages[1]} className={"MobileSnapshot"} onClick={() => CameraClicked(2)} /></div>}
                        {cameraImages.length > 2 && <div><img src={cameraImages[2]} className={"MobileSnapshot"} onClick={() => CameraClicked(3)} /></div>}
                        {cameraImages.length > 3 && <div><img src={cameraImages[3]} className={"MobileSnapshot"} onClick={() => CameraClicked(4)} /></div>}
                    </div>
                </TabStripTab>}
                <TabStripTab title="Sales">
                    <RealTimeTile></RealTimeTile>
                </TabStripTab>
                <TabStripTab title="First Weighs">
                    <DataGrid dataSource='apiv7/firstweighs' columns={columns} permissions={permissions} />
                </TabStripTab>
                <TabStripTab title="Docket">
                    <TextArea rows={80} columns={100} value={docket} className="DocketPreview" />
                </TabStripTab>
                </TabStrip>
            {streamUrl && <div id="MobileStreamWindow"><div id="MobileStreamClose" onClick={CloseStreaming}>X</div><Iframe id="StreamFrame" url={streamUrl}></Iframe></div>}
        </div>
    );
}

export default Mobile;