import { DropDownList } from "@progress/kendo-react-dropdowns";
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import Notifications from '../components/Notifications';
import { TelerikReportViewer } from '@progress/telerik-react-report-viewer/dist/cjs/main';
import { GetSession, GetToken } from '../components/Session';
import configData from "../config.json";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate, useParams } from "react-router-dom";
const ReportViewer = (props) => {
    let viewer;
    const navigate = useNavigate();
    let FromDate = props.dateRange.start === null ? "2000-01-01T00:00:00Z" : props.dateRange.start;
    let ToDate = props.dateRange.end === null ? "2040-01-01T00:00:00Z" : props.dateRange.end;
    var offset = -(props.dateRange.end.getTimezoneOffset() / 60);
    const sites = props.sites;
    const removeCSS = () => {
        document.querySelector("link[href='" + configData.SERVER_URL + "apiv7/reports/resources/font/fonticons-18.0.24.305.css/']").remove();

    }
    return (
        <div className="report">
            <TelerikReportViewer
                ref={el => viewer = el}
                serviceUrl={configData.SERVER_URL + "apiv7/reports/"}
                ready={removeCSS}
                parameters={{
                    editors: {
                        multiSelect: 'COMBO_BOX'
                    }
                }}
                reportSource={{
                    report: props.name + '.trdp',
                    parameters: { 'Url': configData.SERVER_URL.replace(/.$/, ""), 'Token': GetToken(), 'TransactionDate': FromDate, 'EndDate': ToDate, 'Site': sites, 'offset': offset, 'company': props.company, 'Address': props.address },
                    headers: {}
                }}
                viewerContainerStyle={{
                    position: 'absolute',
                    left: '180px',
                    right: '20px',
                    top: '120px',
                    bottom: '0px',

                }}
                //templateUrl="telerikReportViewer-17.1.23.718.html"
                viewMode="INTERACTIVE"
                scaleMode="PRINT_PREVIEW"
                scale={1}
                documentMapAreaPosition="RIGHT"
                enableAccessibility={true} /></div>
    );
};
export default ReportViewer;