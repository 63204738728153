import * as React from "react";
import * as ReactDOM from "react-dom";
import { PivotGrid, PivotGridContainer, PivotGridConfigurator, sumAggregate, minAggregate, maxAggregate, averageAggregate, usePivotLocalDataService, PivotGridConfiguratorButton,} from "@progress/kendo-react-pivotgrid";
import { Loader } from "@progress/kendo-react-indicators";
//import "./pivotStyle.css";
import { Button } from "@progress/kendo-react-buttons";
import { saveAsExcel } from "./exportReports";
import { GetToken } from "../components/Session";
import configData from "../config.json";
//import { WideColumn } from "../components/wide-column";
//const dummyData = createData(1000);
const dimensions = {
  LoadType: {caption: "Load Type", displayValue: (item) => item.LoadType, sortValue: (displayValue) => displayValue,},
  Product: {caption: "Products", displayValue: (item) => item.Product, sortValue: (displayValue) => displayValue,},
  Customer: {caption: "Customer", displayValue: (item) => item.Customer,sortValue: (displayValue) => displayValue,},
  Site: {caption: "Site",displayValue: (item) => item.Site,sortValue: (displayValue) => displayValue,},
};
const measures = [ {name: "Total",value: (item) => item.Price, aggregate: sumAggregate, },
                   {name: "Max", value: (item) => item.Price, aggregate: maxAggregate, },
                   { name: "Min",value: (item) => item.Price, aggregate: minAggregate, },
                   { name: "Average", value: (item) => item.Price, aggregate: averageAggregate,}, 
];
const defaultMeasureAxes = [{name: ["Total"],},];
const defaultRowAxes = [{name: ["Site"], expand: true,},{name: ["LoadType"],},];
const defaultColumnAxes = [{name: ["Customer"],expand: true,}, {name: ["Product"],},];
const defaultFilter = [];
const defaultSort = Object.keys(dimensions).map((k) => ({field: k, dir: "asc",}));


const Reports = () => {
    const [data, setdata] = React.useState([])
    //setdata(dummyData)
    React.useEffect(() => {
        let url = configData.SERVER_URL + "apiv7/Transactions"  
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
          if (d !== undefined && d.length > 0){
            const r = new Array(d.length);
            for(let i = 0; i<d.length; i++ ){
                r[i] = {LoadType: d[i]['loadType'],Customer:d[i]['customerName'],Price:d[i]['price'], Product:d[i]['productName'],Site:d[i]['site']};
            }
            setdata(r);
          }
        });
        }, []);
    const serviceArgs = {dimensions, measures, data, defaultRowAxes, defaultColumnAxes, defaultMeasureAxes, defaultSort, defaultFilter,};
    const [show, setShow] = React.useState(false);
    const { pivotProps, configuratorProps, state } = usePivotLocalDataService(serviceArgs);
    const pivotgrid = React.useRef(null);
    const onSaveAsExcel = React.useCallback(() => {
        if (pivotgrid.current) {
        saveAsExcel(pivotgrid.current);
        }
    }, []);
    const handleButtonClick = React.useCallback(() => {
        setShow(!show);
    }, [show]);
  
    return (
      <div>
      <Button onClick={onSaveAsExcel}>Export to Excel</Button>
      <PivotGridContainer>
                <PivotGrid
                    {...pivotProps}
                    style={{
                        height: 700,
                    }}
                
        />
        {show && <PivotGridConfigurator {...configuratorProps} />}
        <PivotGridConfiguratorButton onClick={handleButtonClick} />
        {state.loading && (
        <Loader
          style={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          size="large"
        />
      )}
      </PivotGridContainer>
    </div>  
    );
}

export default Reports;