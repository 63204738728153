import * as React from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
import { PutServerCommand } from "./Helpers"

// Implements Linkly EFTPOS
// Usage:
// <Eftpos command={eftposCommand} data={eftposData} callback={EftposCallback}></Eftpos>
// {eftposCommand} has the format { Command: "Payment", Reference: "1234", Amount: 100, CashOut: 0 }
// Where Command is "Payment", "Refund", "Reprint" or "GetLastTransaction"
// Reference is caller's payment reference (eg receipt no)
// Amount is payment/refund amount
// CashOut is cash out with payment
//
// EftposData is BusinessData object for EFTPOS
//
// EftposCallback is a callback that is called when payment succeeds or fails. eg:
//     const EftposCallback = (r, e) => {} where the receipt is passed back in r and e is true if the transaction is successful
export const Eftpos = (props) => {
    const [showDialog, setShowDialog] = React.useState(false);
    const data = props.data; // Eftpos business data
    const command = props.command; // Command object for Payment, Refund, Reprint or GetLastTransaction
    const [button1, setButton1] = React.useState();
    const [button2, setButton2] = React.useState();
    const [button3, setButton3] = React.useState();
    const [button4, setButton4] = React.useState();
    const [showInput, setShowInput] = React.useState(false);
    const [value, setValue] = React.useState("");
    const [message, setMessage] = React.useState(""); 
    const receipt = React.useRef("");
    const lastCommand = React.useRef();
    const lastData = React.useRef();

    const SetButtonX = (n, v) => {
        switch (n) {
            case 0: setButton1(v); break;
            case 1: setButton2(v); break;
            case 2: setButton3(v); break;
            case 3: setButton4(v); break;
        }
    }

    const GetButtonX = (x) => {
        switch (x) {
            case 1: return button1;
            case 2: return button2;
            case 3: return button3;
            case 4: return button4;
        }
        return undefined;
    } 

    const UpdateData = () => {
        if (lastCommand.current === undefined || lastCommand.current === "") return;
        if (data.value === undefined || data.value === "" || (lastData.current !== undefined && lastData.current.value === data.value)) return;
        lastData.current = data;
        let j = "";
        try {
            j = JSON.parse(data.value);
        }
        catch (e) {
            console.log(e);
            return;
        }
        let txt = String(j.Text);
        switch (j.Type) {
            case "Display": // display event
                setMessage(j.Text);
                let flags = String(j.Flags);
                let n = 0;
                let x = flags.substring(0, 1);
                if (flags.substring(0, 1) == "1") SetButtonX(n++, "Cancel");
                if (flags.substring(1, 2) == "1") SetButtonX(n++, "Accept");
                if (flags.substring(2, 3) == "1") SetButtonX(n++, "Decline");
                if (flags.substring(3, 4) == "1") SetButtonX(n++, "Authorise");
                setShowInput(flags.substring(4, 5) == "1");
                if (flags.substring(5, 6) == "1") SetButtonX(n++, "Ok");              
                break;
            case "Receipt": // receipt event - only need to process on reprinting
                let rec = String(j.Receipt);
                receipt.current = rec;
                if (lastCommand.current.Command === "Reprint") {
                    setShowDialog(false);
                    if (props.callback !== undefined) props.callback(rec, true);
                }
                break;
            case "Transaction": // transaction event
                let r = receipt.current;
                if (r === "") r = String(j.Receipt);
                setMessage("");
                PutServerCommand(data.businessDataId, "Clear");
                switch (txt.substring(0, 3)) {
                    case "100": // approved
                    case "110": // get last transaction
                        setShowDialog(false);
                        if (props.callback !== undefined) props.callback(r, true);
                        break;
                    case "0TM": // cancelled
                        setShowDialog(false);
                        if (props.callback !== undefined) props.callback(r, false);
                        break;
                    case "0PF": // pinpad offline
                        setShowDialog(false);
                        if (props.callback !== undefined) props.callback("Error: Pinpad is offline", false);
                        break;
                    default:
                        setShowDialog(false);
                        if (props.callback !== undefined) props.callback(r, false);
                        break;
                }
                break;
        }
    }

    if (data !== undefined && data !== "" && data !== lastData.current) {
        UpdateData();
    }

    if (command !== undefined && command !== "" && command != lastCommand.current) {
        lastData.current = "";
        setButton1("");
        setButton2("");
        setButton3("");
        setButton4("");
        setMessage("");
        setValue("");
        receipt.current = "";
        setShowDialog(true);
        UpdateData();
        PutServerCommand(data.businessDataId, JSON.stringify(command));
        lastCommand.current = command;
    }

    const Button1Clicked = () => { SendButtonCommand(1); }
    const Button2Clicked = () => { SendButtonCommand(2); }
    const Button3Clicked = () => { SendButtonCommand(3); }
    const Button4Clicked = () => { SendButtonCommand(4); }

    const SendButtonCommand = (b) => {
        let cmd = { Command: "KeyPressed", Reference: value, Amount: b, CashOut: 0 }
        PutServerCommand(data.businessDataId, JSON.stringify(cmd));
    }

    const ValueChanged = (e) => {
        setValue(e.value);
    }

    const DialogNo = () => {
        if (String(button1).toLowerCase() === "cancel") {
            Button1Clicked();
        }
    }

    return (
        <div>{showDialog && <Dialog title={"Processing Payment"} onClose={DialogNo}>
            <div className={"eftpos"}>
                <div className={"eftposMessage"}>{message}</div>
                <div className={"eftposButtons"}>
                    {button1 && <Button id="B1" className={"eftposButton"} onClick={Button1Clicked}>{button1}</Button>}
                    {button2 && <Button id="B2" className={"eftposButton"} onClick={Button2Clicked}>{button2}</Button>}
                    {button3 && <Button id="B3" className={"eftposButton"} onClick={Button3Clicked}>{button3}</Button>}
                    {button4 && <Button id="B4" className={"eftposButton"} onClick={Button4Clicked}>{button4}</Button>}
                </div>
                {showInput && <div className={"eftposInput"}><TextBox value={value} onChange={ValueChanged} placeholder={"Enter value here"}></TextBox></div>}
            </div>
        </Dialog>}
        </div>
     );
}