import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormSwitch } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { TextBox } from "@progress/kendo-react-inputs";
import { GetPermissions } from '../components/Options';

const EditRoles = () => {
    const { id } = useParams(); // editroles/id url is used to edit existing role, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [searchText, setSearchText] = React.useState('');
    const [data, setData] = React.useState([]);
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    const [permissions, setPermissions] = React.useState(null);
    
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
            { "field": "name", "label": "Name", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean"},
            { "field": "roleDetails", "label": "Role", "defaultValue": "", "isVisible": true, "type": "heading"},
            { "field": "permissionDetails", "label": "Permissions", "defaultValue": "", "isVisible": true, "type": "heading"}
        ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        name : GetField("name")["defaultValue"],
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
      });

    // When page first loads:
    // - populate drop downs from api/options
    // - if in edit mode, set initial values for all fields for api/drivers
    React.useEffect(() => {
        GetPermissions(["ViewRole", "NewRole", "EditRole"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'editRoles'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/roles/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    setValues({
                        name: d.name === null ? "" : d.name, 
                        active: d.isActive
                    });
                    setFormkey(formkey + 1);
                    url = `${configData.SERVER_URL}apiv7/rolepermissions/${id}?`;
                    fetch(url, init).then(response => response.json()).then(d => {
                        setData(d);
                    })
                })
           }
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/roles${pth}`;
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "roleId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.name ,"name"),
            "isActive": GetDefaultData(dataItem.active, "active")
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = id === undefined ? "New role created" : "Role updated";
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/roles", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");;
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");;
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");;

    const CustomCell = (props) => {
        let chk = props.dataItem["isActive"];
        let cbid = "CB" + props.dataItem["permissionId"];

        return (<td><Checkbox name={cbid} onChange={PermissionChanged} defaultChecked={chk} /> </td>)
    }

    const PermissionChanged = (e) => {
        let perm = String(e.target.name).replace("CB", "");
        let pid = parseInt(perm);
        let rec = data.find(obj => { return obj.permissionId === pid });  
        if (rec !== undefined){
            rec.isActive = e.value;
            setFormkey(formkey + 1);
        }
        let body = {}
        let url = `${configData.SERVER_URL}apiv7/rolepermissions/${id}/${perm}`;
        let verb = "DELETE";
        if (e.value){
            body = `{ "roleId": ${id}, "permissionId": ${perm} }`;
            url = url = `${configData.SERVER_URL}apiv7/rolepermissions`;
            verb = "POST";
        }
        let init = {
            method: verb,
            accept: 'application/json',
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
                },
            mode: "cors",
            cache: "no-cache",
            body: body
          }
        fetch(url, init).then().then(d => {

        });
    }

    const SearchTextChanged = (e) =>
    {
        setSearchText(e.value);
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: {"Authorization": "Bearer " + GetToken()}
          }
        let url = `${configData.SERVER_URL}apiv7/rolepermissions/${id}`;
        if (e.value !== '') url += `?search=${e.value}`;
        fetch(url, init).then(response => response.json()).then(d => {
            setData(d);
            setFormkey(formkey + 1);
        })
    }

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    return (
        <div>
        <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
            {GetField("roleDetails")["isVisible"] && <TabStripTab title={GetField("roleDetails")["label"]}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                           {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
            </TabStripTab>}
            {GetField("permissionDetails")["isVisible"] && id !== undefined && <TabStripTab title={GetField("permissionDetails")["label"]}>
            <div className='row' style={{padding: 40}}>
                <div className='col-md-3'>
                <TextBox id="SearchText" value={searchText} onChange={SearchTextChanged} placeholder='Search' />
                </div>
            </div>
            <Grid
                data={data}
                    >
                    <GridColumn field="name" title="Permission" />
                    <GridColumn field="description" title="Description" />
                    <GridColumn field="v6Name" title="Version 6 Permission" />
                    <GridColumn field="isActive" title="Has Permission" cell={CustomCell} />
                </Grid>
            </TabStripTab>}
            </TabStrip>                        
        </div>
    );
}

export default EditRoles