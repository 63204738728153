import * as React from "react";
import Notifications from '../components/Notifications';
import DataGrid from "../components/DataGrid";
import GetColumnDefs from "../components/ColumnDefs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import configData from "../config.json";
import { GetPermissions } from '../components/Options';
import { GetSession, GetToken } from "../components/Session";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { useNavigate } from "react-router-dom";
import { TextBox } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

const Customers = () => {
  const [lookupParams, setLookupParams] = React.useState("");
  const [sites, setSites] = React.useState([]);
  const [site, setSite] = React.useState();
  const [payments, setPayments] = React.useState([]);
  const [payment, setPayment] = React.useState();
  const [deliveryStates, setDeliveryStates] = React.useState([]);
  const [deliveryState, setDeliveryState] = React.useState();
  const [name, setName] = React.useState();
  const [account, setAccount] = React.useState();
  const [state, setState] = React.useState();
  const [suburb, setSuburb] = React.useState();
  const [suburbs, setSuburbs] = React.useState();
  const [expanded, setExpanded] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const ready = React.useRef(false);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("Sync MYOB");
  const myobItem = React.useRef(null);
  const [dateRange, setDateRange] = React.useState();
  const [layout, setLayout] = React.useState([
    { "field": "searchAdvanced", "label": "Advanced Search", "isVisible": true, "type": "text" },
    { "field": "searchName", "label": "Name", "isVisible": true, "type": "text" },
    { "field": "searchAccount", "label": "Account Number", "isVisible": true, "type": "text" },
    { "field": "searchState", "label": "State", "isVisible": true, "type": "text" },
    { "field": "searchSuburb", "label": "Suburb", "isVisible": true, "type": "text" },
    { "field": "searchSite", "label": "Site", "isVisible": true, "type": "text" },
    { "field": "searchDeliveryState", "label": "Delivery State", "isVisible": true, "type": "text" },
    { "field": "searchPaymentMethod", "label": "Payment Method", "isVisible": true, "type": "text" }
  ]);

  const GetField = (f) => {
      return layout.find(obj => { return obj.field === f });
  }

  const siteChanged = (event) => {
    setSite(event.target.value);
  };
  const deliveryStateChanged = (event) => {
    setDeliveryState(event.target.value);
  };
  const paymentChanged = (event) => {
    setPayment(event.target.value);
  };
  const nameChanged = (event) => {
    setName(event.target.value);
  };
  const accountChanged = (event) => {
    setAccount(event.target.value);
  };
  const stateChanged = (event) => {
    setState(event.target.value);
  };
  const suburbChanged = (event) => {
    setSuburb(event.target.value);
  };

  const DoSearch = () => {
    var custParam = {
      Name: name,
      Account: account,
      State: state,
      Suburb: suburb,
      Site: site.id,
      DeliveryState: deliveryState,
      PaymentMethod: payment,
    };
    setLookupParams(encodeURI(JSON.stringify(custParam)));
  };
  const ClearSearch = () => {
    if (ready.current) {
      ClearParams();
      var jobParam = {
        State: "All",
        Name: "",
        Account: "",
        Suburb: "All",
        DeliveryState: "All",
        Site: "0",
        PaymentMethod: "All",
      };
      setLookupParams(encodeURI(JSON.stringify(jobParam)));
    }
  };
  const ClearParams = () => {
    setSite({ id: "0", name: "All" });
    setName("");
    setAccount("");
    setState("All")
    setSuburb("All");
    setDeliveryState("All");
    setPayment("All");
  };
  React.useEffect(() => {
    GetPermissions(["ViewCustomer", "NewCustomer", "EditCustomer", "DeleteCustomer", "ReportCustomer", "SyncMyob"], setPermissions);
    let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'Customers'";
    const init = {
      method: "GET",
      accept: "application/json",
      headers: {"Authorization": "Bearer " + GetToken()},
    };
    fetch(baseUrl, init).then(response => response.json()).then(d => { 
      if (GetSession().IsMobile){
          let data = [];
          for(let i = 0; i < d.length; i++){
              data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
          }
          setLayout(data);
      } else {
          setLayout(d); 
      }
    }); 
    baseUrl = configData.SERVER_URL + "apiv7/options";
    fetch(baseUrl, init)
      .then((response) => response.json())
      .then((d) => {
        setSites(CreateList(d.sites));
        setSuburbs(["All"].concat(d.customerSuburbs));
        setDeliveryStates(d.states);
        let pm = ["All", ...d.paymentMethods];
        setPayments(pm);
        ClearParams();
        ready.current = true;
      });
  }, []);
  
  const CreateList = (d) => {
    var x = [{ id: "0", name: "All" }];
    return x.concat(d);
  };

  const [columns, setColumns] = React.useState(null);
  const [permissions, setPermissions] = React.useState(null);
  React.useEffect(() => { 
    GetColumnDefs("Customers", setColumns, setErrorMessage); 
  }, [])
  if (permissions && !permissions.CanView) navigate("/error401");


    const MyobSyncCommand = (item, ev) => {
        setDialogTitle(`Sync MYOB for ${item.name}`);
        setShowDialog(true);
        myobItem.current = item;
    }

  const DialogNo = () => {
    setShowDialog(false);
  }

  const rangeChanged = (event) => {
      setDateRange(event.target.value);
  }

  const DialogYes = () => {
      if (dateRange === undefined || dateRange.start === null || dateRange.end === null) {
         alert("You must specify a date range");
         return;
      }
      setShowDialog(false);
      var url = `${configData.SERVER_URL}apiv7/customers?id=${myobItem.current.customerId}&fromDate=${dateRange.start.toISOString()}&toDate=${dateRange.end.toISOString() }`;
      const init = {
          method: 'PATCH',
          accept: "application/json",
          headers: {
              "Content-type": "application/json",
              "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
      }
      fetch(url, init).then(res => res.text()).then(d => {
          alert(d);
      });
  }

  return (
    <div>
        <Notifications errorMessage={errorMessage}/>
        {GetField("searchAdvanced")["isVisible"] && <ExpansionPanel
        title={GetField("searchAdvanced")["label"]}
        expandIcon="fa fa-plus"
        collapseIcon="fa fa-minus"
        expanded={expanded}
        onAction={(e) => setExpanded(!e.expanded)}
      >
        <Reveal>
          {expanded && (
            <ExpansionPanelContent>
              <div className="row" style={{ padding: "0px 10px" }}>
                {GetField("searchName")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchName")["label"]}</label>
                  <TextBox id="Name" type="text" onChange={nameChanged} />
                </div>}
                {GetField("searchAccount")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchAccount")["label"]}</label>
                  <TextBox id="Account" type="text" onChange={accountChanged} />
                </div>}
                {GetField("searchState")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchState")["label"]}</label>
                  <DropDownList
                    id="State"
                    data={deliveryStates}
                    value={state}
                    onChange={stateChanged}
                  />
                </div>}
                {GetField("searchSuburb")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchSuburb")["label"]}</label>
                  <DropDownList
                    id="DeliveryState"
                    data={suburbs}
                    value={suburb}
                    onChange={suburbChanged}
                  />
                </div>}
              </div>
              <div className="row" style={{ padding: "5px 10px" }}>
                <div className="col-md-3">
                  <label className="control-label">{GetField("searchSite")["label"]}</label>
                  <DropDownList
                    id="Site"
                    data={sites}
                    value={site}
                    textField="name"
                    dataItemKey="id"
                    onChange={siteChanged}
                  />
                </div>
                {GetField("searchDeliveryState")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchDeliveryState")["label"]}</label>
                  <DropDownList
                    id="DeliveryState"
                    data={deliveryStates}
                    value={deliveryState}
                    onChange={deliveryStateChanged}
                  />
                </div>}
                {GetField("searchPaymentMethod")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchPaymentMethod")["label"]}</label>
                  <DropDownList
                    id="PaymentsMethod"
                    data={payments}
                    value={payment}
                    onChange={paymentChanged}
                  />
                </div>}
                <div className="col-md-3" style={{ padding: "25px" }}>
                  <Button onClick={DoSearch} themeColor={"primary"}>
                    Search
                  </Button>{" "}
                  <Button onClick={ClearSearch} themeColor={"primary"}>
                    Clear
                  </Button>
                </div>
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>}
      {columns && permissions && <DataGrid
        dataSource="apiv7/customers"
        params={lookupParams}
        dataFilter="CustomerType eq 'C'"
        columns={columns}
        permissions={permissions}
        extraParam="/C"
        keyField="customerId"
        customCommand={MyobSyncCommand}
        customCommandName="MyobSyncCommand"
          />}
          {showDialog && <Dialog title={dialogTitle} onClose={DialogNo}>
              <div style={{paddingBottom: "20px"} }>Normal Sync Interval: {myobItem.current === null ? "" : myobItem.current.myobSyncInterval}</div>
              <div><DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged} /></div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={DialogNo}>Cancel</button>
                <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={DialogYes}>Sync Now</button>
            </DialogActionsBar>
        </Dialog>}
    </div>
  );
};

export default Customers;
