import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormTextArea, FormNumericTextBox, FormDropDownList, FormMultiSelect } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState  } from "../components/multiselecttree-data-operations";
import { MultiSelectTreeEditor } from "../components/MultiSelectTreeEditor";

const EditCustomers = () => {
    const { id } = useParams(); // editcustomers/type/id url is used to edit existing customer, otherwise id is undefined
    const { type } = useParams(); // type S = Supplier, C = customer
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [states, setStates] = React.useState([ ]);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [isMonthly, setIsMonthly] = React.useState(false);
    const [syncIntervals, setSyncIntervals] = React.useState([ ]);
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    const [permissions, setPermissions] = React.useState(null);

    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "customerDetails", "label": "Customer", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "name", "label": "Name", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "description", "label": "Notes", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "accountNumber", "label": "Account", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "paymentMethod", "label": "Payment Method", "defaultValue": "ACCOUNT", "isVisible": true, "type": "text"},
        { "field": "siteCreated", "label": "SiteCreated", "defaultValue": "", "isVisible": false, "type": "boolean"},
        { "field": "address1", "label": "Address Line 1", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "address2", "label": "Address Line 2", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "suburb", "label": "Suburb", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "state", "label": "State", "defaultValue": "NSW", "isVisible": true, "type": "text"},
        { "field": "postcode", "label": "Postcode", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "country", "label": "Country", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "phone", "label": "Phone", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "fax", "label": "Fax", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "mobile", "label": "Mobile", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "contact", "label": "Contact", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "comment", "label": "Comment", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "email", "label": "Email", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "abn", "label": "ABN", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "delivery", "label": "Delivery Contact", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "deliveryAddress1", "label": "Delivery Address 1", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "deliveryAddress2", "label": "Delivery Address 2", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "deliverySuburb", "label": "Delivery Suburb", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "deliveryState", "label": "Delivery State", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "deliveryPostcode", "label": "Delivery Postcode", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "deliveryZone", "label": "Delivery Zone", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "deliveryPhone", "label": "Delivery Phone", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "tagId", "label": "Tag ID", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "customerType", "label": "CustomerType", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "fixedCharge", "label": "FixedCharge", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "discount", "label": "Discount (% off)", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "noGST", "label": "No GST", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "hidePriceOnTicket", "label": "Hide Price On Ticket", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "ticketCopies", "label": "Ticket Copies", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "fixedCartage", "label": "Fixed Delivery Cartage", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "isOrderNoRequired", "label": "Order Number Required", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "isAlwaysReqOrderNoPerTxn", "label": "Unique Order Number Required For Each Transaction", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "docketInitials", "label": "DocketInitials", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "active", "label": "Active", "defaultValue": "TRUE", "isVisible": true, "type": "boolean"},
        { "field": "addressDetails", "label": "Address and contact details", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "deliveryDetails", "label": "Delivery address and contact details", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "siteDetails", "label": "Site details", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "myobSyncInterval", "label": "MYOB Sync Intervals", "defaultValue": "Daily", "isVisible": true, "type": "text"},
        { "field": "monthlySyncDay", "label": "Monthly Sync Day", "defaultValue": 1, "isVisible": true, "type": "numeric"},
        { "field": "customerSites", "label": "Sites assigned", "defaultValue": 1, "isVisible": true, "type": "list"},
        { "field": "customerTrucks", "label": "Trucks", "defaultValue": 1, "isVisible": true, "type": "list"},
        { "field": "smsDocket", "label": "Send Docket via SMS", "defaultValue": false, "isVisible": true, "type": "boolean" },
        { "field": "emailDocket", "label": "Send Docket via Email", "defaultValue": false, "isVisible": true, "type": "boolean" }
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        name: GetField("name")["defaultValue"],
        accountNumber: GetField("accountNumber")["defaultValue"],
        description: GetField("description")["defaultValue"],
        paymentMethod: GetField("paymentMethod")["defaultValue"],
        address1: GetField("address1")["defaultValue"],
        address2: GetField("address2")["defaultValue"],
        suburb: GetField("suburb")["defaultValue"],
        state: GetField("state")["defaultValue"],
        postcode: GetField("postcode")["defaultValue"],
        country: GetField("country")["defaultValue"],
        phone: GetField("phone")["defaultValue"],
        fax: GetField("fax")["defaultValue"],
        mobile: GetField("mobile")["defaultValue"],
        contact: GetField("contact")["defaultValue"],
        comment: GetField("comment")["defaultValue"],
        email: GetField("email")["defaultValue"],
        abn: GetField("abn")["defaultValue"],
        delivery: GetField("delivery")["defaultValue"],
        deliveryAddress1: GetField("deliveryAddress1")["defaultValue"],
        deliveryAddress2: GetField("deliveryAddress2")["defaultValue"],
        deliverySuburb: GetField("deliverySuburb")["defaultValue"],
        deliveryState: GetField("deliveryState")["defaultValue"],
        deliveryPostcode: GetField("deliveryPostcode")["defaultValue"],
        deliveryZone: GetField("deliveryZone")["defaultValue"],
        deliveryPhone: GetField("deliveryPhone")["defaultValue"],
        tagId: GetField("tagId")["defaultValue"],
        customerType: GetField("customerType")["defaultValue"],
        fixedCharge: GetField("fixedCharge")["defaultValue"],
        discount: GetField("discount")["defaultValue"],
        noGST: GetField("noGST")["defaultValue"],
        hidePriceOnTicket: GetField("hidePriceOnTicket")["defaultValue"],
        ticketCopies: GetField("ticketCopies")["defaultValue"],
        fixedCartage: GetField("fixedCartage")["defaultValue"],
        isOrderNoRequired: GetField("isOrderNoRequired")["defaultValue"],
        isAlwaysReqOrderNoPerTxn: GetField("isAlwaysReqOrderNoPerTxn")["defaultValue"],
        docketInitials: GetField("docketInitials")["defaultValue"],
        addressDetails: GetField("addressDetails")["defaultValue"],
        deliveryDetails: GetField("deliveryDetails")["defaultValue"],
        siteDetails: GetField("siteDetails")["defaultValue"],
        myobSyncInterval: GetField("myobSyncInterval")["defaultValue"],
        monthlySyncDay: GetField("monthlySyncDay")["defaultValue"],
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true",
        customerSites: [],
        customerTrucks: [],
        smsDocket: GetField("smsDocket")["defaultValue"],
        emailDocket: GetField("emailDocket")["defaultValue"],
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vehicleconfigurations
    React.useEffect(() =>
    {
        GetPermissions(["ViewCustomer", "NewCustomer", "EditCustomer"], setPermissions)
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditCustomers'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setStates(opt.states);
            let allSites = GetList(opt.sites);
            let defSites = ConfigureSites(opt.sites, opt.inactiveSites, allSites);
            ConfigureTrucks(opt.trucks, opt.inactiveTrucks, "");
            setPaymentMethods(opt.paymentMethods);
            setSyncIntervals(opt.syncIntervals);
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/customers/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    let sites = ConfigureSites(opt.sites, opt.inactiveSites, d.siteList);                    
                    let trucks = ConfigureTrucks(opt.trucks, opt.inactiveTrucks, d.truckList);
                    setValues({
                        name: d.name === null ? "" : d.name,
                        accountNumber: d.accountNumber === null ? "" : d.accountNumber,
                        description: d.description === null ? "" : d.description,
                        paymentMethod: d.paymentMethod === null ? "" : d.paymentMethod,
                        address1: d.address1 === null ? "" : d.address1,
                        address2: d.address2 === null ? "" : d.address2,
                        suburb: d.suburb === null ? "" : d.suburb,
                        state: d.state === null ? "" : d.state,
                        postcode: d.postcode === null ? "" : d.postcode,
                        country: d.country === null ? "" : d.country,
                        customerSites: sites,
                        customerTrucks: trucks,
                        phone: d.phone === null ? "" : d.phone,
                        fax: d.fax === null ? "" : d.fax,
                        mobile: d.mobile === null ? "" : d.mobile,
                        contact: d.contact === null ? "" : d.contact,
                        comment: d.comment === null ? "" : d.comment,
                        email: d.email === null ? "" : d.email,
                        abn: d.abn === null ? "" : d.abn,
                        delivery: d.delivery === null ? "" : d.delivery,
                        deliveryAddress1: d.deliveryAddress1 === null ? "" : d.deliveryAddress1,
                        deliveryAddress2: d.deliveryAddress2 === null ? "" : d.deliveryAddress2,
                        deliverySuburb: d.deliverySuburb === null ? "" : d.deliverySuburb,
                        deliveryState: d.deliveryState === null ? "" : d.deliveryState,
                        deliveryPostcode: d.deliveryPostcode === null ? "" : d.deliveryPostcode,
                        deliveryZone: d.deliveryZone === null ? "" : d.deliveryZone,
                        deliveryPhone: d.deliveryPhone === null ? "" : d.deliveryPhone,
                        tagId: d.tagId === null ? "" : d.tagId,
                        customerType: d.customerType === null ? "" : d.customerType,
                        fixedCharge: d.fixedCharge,
                        discount: d.discount/100,
                        noGST: d.noGST,
                        hidePriceOnTicket: d.hidePriceOnTicket,
                        ticketCopies: d.ticketCopies,
                        fixedCartage: d.fixedCartage,
                        isOrderNoRequired: d.isOrderNoRequired,
                        isAlwaysReqOrderNoPerTxn: d.isAlwaysReqOrderNoPerTxn,
                        docketInitials: d.docketInitials === null ? "" : d.docketInitials,
                        addressDetails: d.addressDetails === null ? "" : d.addressDetails,
                        deliveryDetails: d.deliveryDetails === null ? "" : d.deliveryDetails,
                        siteDetails: d.siteDetails === null ? "" : d.siteDetails,
                        myobSyncInterval: d.myobSyncInterval === null ? "" : d.myobSyncInterval,
                        monthlySyncDay: d.monthlySyncDay,
                        active: d.isActive,
                        smsDocket: d.smsDocket,
                        emailDocket: d.emailDocket
                    });
                    setFormkey(formkey + 1);
                })
            } else {
                var v = {...values};
                v.customerSites = defSites;
                v.customerTrucks = [];
                setValues(v);                
                setFormkey(formkey + 1);
            }
        });
    },[]);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    const [sitesData, setSitesData] = React.useState([{id:0, text: "Active", items: []}]);
    const dataItemKey = "id";
    const checkField = "checkField";
    const checkIndeterminateField = "checkIndeterminateField";
    const subItemsField = "items";
    const expandField = "expanded";
    const textField = "text";
    const fields = { dataItemKey, checkField, checkIndeterminateField, expandField, subItemsField };
    const [sitesValue, setSitesValue] = React.useState([]);
    const [siteExpanded, setSiteExpanded] = React.useState([]);
    const onSitesChange = (event) => {
        setSitesValue(getMultiSelectTreeValue(sitesData, {...fields, ...event, value: sitesValue, }));
    }
    const onSitesExpandChange = React.useCallback((event) => setSiteExpanded(expandedState(event.item, dataItemKey, siteExpanded)), [siteExpanded]);
    const sitesTreeData = React.useMemo(() => processMultiSelectTreeData(sitesData, { expanded: siteExpanded, value: sitesValue, ...fields }), [siteExpanded, sitesValue]);
    const ConfigureSites = (act, dis, siteList) => {
        if (siteList === null) return;
        let slsp = siteList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            let inactive = {id: 999999, text: 'Inactive', items: sl };
            setSitesData([active, inactive]);
        }
        else {
            setSitesData([active]);
        }
        setSitesValue([...selected]);       
        return [...selected];
    }

    const [trucksData, setTrucksData] = React.useState([{id:0, text: "Active", items: []}]);
    const [trucksValue, setTrucksValue] = React.useState([]);
    const [truckExpanded, setTruckExpanded] = React.useState([]);
    const onTrucksChange = (event) => {
        setTrucksValue(getMultiSelectTreeValue(trucksData, {...fields, ...event, value: trucksValue, }));
    }
    const onTrucksExpandChange = React.useCallback((event) => setTruckExpanded(expandedState(event.item, dataItemKey, truckExpanded)), [truckExpanded]);
    const trucksTreeData = React.useMemo(() => processMultiSelectTreeData(trucksData, { expanded: truckExpanded, value: trucksValue, ...fields }), [truckExpanded, trucksValue]);
    const ConfigureTrucks = (act, dis, siteList) => {
        if (siteList === null) return;
        let slsp = siteList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            let inactive = {id: 999999, text: 'Inactive', items: sl };
            setTrucksData([active, inactive]);
        }
        else {
            setTrucksData([active]);
        }
        setTrucksValue([...selected]);       
        return [...selected];
    }

    const GetList = (obj) => {
        if (obj === undefined || obj === null || obj.length === 0) return "";
        var l = "";
        for (let i = 0; i < obj.length; i++){
            let id = obj[i].id;
            if (id !== 0 && id !== 999990){
                l += id;
                if (i < obj.length - 1) l += ",";
            }
        }
        return l;
    }

    const isNullOrEmpty = (x) => { return (x === undefined || x === null || x === ""); }
    
    const Validate = (dataItem) => {
        let st = "";
        if (isNullOrEmpty(dataItem.name)) st += "Name\r\n";
        if (isNullOrEmpty(dataItem.accountNumber)) st += "Account\r\n";
        if (st !== ""){
            setSelectedTab(0);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        return true;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        if (!Validate(dataItem)) return;  
        var pth = id === undefined ? "" : "/" + id;
        var siteList = GetList(dataItem.customerSites);
        var truckList = GetList(dataItem.customerTrucks);
        var url = `${configData.SERVER_URL}apiv7/customers${pth}`;
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "customerId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.name, "name"),
            "accountNumber": GetDefaultData(dataItem.accountNumber, "accountNumber"),
            "description": GetDefaultData(dataItem.description, "description"),
            "paymentMethod": GetDefaultData(dataItem.paymentMethod, "paymentMethod"),
            "address1": GetDefaultData(dataItem.address1, "address1"),
            "address2": GetDefaultData(dataItem.address2, "address2"),
            "suburb": GetDefaultData(dataItem.suburb, "suburb"),
            "state": GetDefaultData(dataItem.state, "state"),
            "postcode": GetDefaultData(dataItem.postcode, "postcode"),
            "country": GetDefaultData(dataItem.country, "country"),
            "phone": GetDefaultData(dataItem.phone, "phone"),
            "fax": GetDefaultData(dataItem.fax, "fax"),
            "mobile": GetDefaultData(dataItem.mobile, "mobile"),
            "contact": GetDefaultData(dataItem.contact, "contact"),
            "comment": GetDefaultData(dataItem.comment, "comment"),
            "email": GetDefaultData(dataItem.email, "email"),
            "abn": GetDefaultData(dataItem.abn, "abn"),
            "delivery": GetDefaultData(dataItem.delivery, "delivery"),
            "deliveryAddress1": GetDefaultData(dataItem.deliveryAddress1, "deliveryAddress1"),
            "deliveryAddress2": GetDefaultData(dataItem.deliveryAddress2, "deliveryAddress2"),
            "deliverySuburb": GetDefaultData(dataItem.deliverySuburb, "deliverySuburb"),
            "deliveryState": GetDefaultData(dataItem.deliveryState, "deliveryState"),
            "deliveryPostcode": GetDefaultData(dataItem.deliveryPostcode, "deliveryPostcode"),
            "deliveryZone": GetDefaultData(dataItem.deliveryZone, "deliveryZone"),
            "deliveryPhone": GetDefaultData(dataItem.deliveryPhone, "deliveryPhone"),
            "tagId": GetDefaultData(dataItem.tagId, "tagId"),
            "customerType": type,
            "fixedCharge": GetDefaultData(dataItem.fixedCharge, "fixedCharge"),
            "discount": GetDefaultData(dataItem.discount, "discount")*100,
            "noGST": GetDefaultData(dataItem.noGST, "nOGST"),
            "hidePriceOnTicket": GetDefaultData(dataItem.hidePriceOnTicket, "hidePriceOnTicket"),
            "ticketCopies": GetDefaultData(dataItem.ticketCopies, "ticketCopies"),
            "fixedCartage": GetDefaultData(dataItem.fixedCartage, "fixedCartage"),
            "isOrderNoRequired": GetDefaultData(dataItem.isOrderNoRequired, "isOrderNoRequired"),
            "isAlwaysReqOrderNoPerTxn": GetDefaultData(dataItem.isAlwaysReqOrderNoPerTxn, "isAlwaysReqOrderNoPerTxn"),
            "docketInitials": GetDefaultData(dataItem.docketInitials, "docketInitials"),
            "myobSyncInterval": GetDefaultData(dataItem.myobSyncInterval, "myobSyncInterval"),
            "monthlySyncDay": GetDefaultData(dataItem.monthlySyncDay, "monthlySyncDay"),
            "siteList": siteList,
            "truckList": truckList,
            "isActive": GetDefaultData(dataItem.active, "active"),
            "sMSDocket": GetDefaultData(dataItem.smsDocket, "smsDocket"),
            "emailDocket": GetDefaultData(dataItem.emailDocket, "emailDocket"),
           })
        }
        fetch(url, init).then(response => response).then(d => { 
            if(type === 'C'){
                var msg = id === undefined ? "Customer created" : "Customer updated";
            }
            else{
                var msg = id === undefined ? "Supplier created" : "Supplier updated";
            }
            Redirect(msg);
        });
    }

    const onMyobChanged = (ev) => { setIsMonthly(ev.value === "Monthly"); }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        if(type === 'C'){
            navigate("/customers", {state: {msg: msg}});
        }
        else{
            navigate("/suppliers", {state: {msg: msg}});
        }
       
    }

    if (permissions && !permissions.CanView) navigate("error401");
    if (permissions && !permissions.CanEdit && id !== undefined) Redirect("Error: You do not have permission to edit customers");
    if (permissions && !permissions.CanCreate && id === undefined) Redirect("Error: You do not have permission to create customers");

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}} >
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("customerDetails")["isVisible"] && <TabStripTab title={GetField("customerDetails")["label"]}>
                        <div className="row">
                           {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("accountNumber")["isVisible"] && <div className="col-md-3">
                                <Field id={"accountNumber"} name={"accountNumber"} label={GetField("accountNumber")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                           {GetField("abn")["isVisible"] && <div className="col-md-3">
                                <Field id={"abn"} name={"abn"} label={GetField("abn")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("paymentMethod")["isVisible"] && <div className='col-md-3'>
                                <Field id={"paymentMethod"} name={"paymentMethod"} label={GetField("paymentMethod")["label"]} component={FormDropDownList} data={paymentMethods} />
                            </div>}
                        </div>
                        <div className='row' >                            
                            {GetField("fixedCharge")["isVisible"] && <div className="col-md-3">
                                <Field id={"fixedCharge"} name={"fixedCharge"} label={GetField("fixedCharge")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}
                            {GetField("discount")["isVisible"] && <div className="col-md-3">
                                        <Field id={"discount"} name={"discount"} label={GetField("discount")["label"]} component={FormNumericTextBox} min={0} max={100} format="p" spinners={false} />
                            </div>}
                            {GetField("fixedCartage")["isVisible"] && <div className="col-md-3">
                                <Field id={"fixedCartage"} name={"fixedCartage"} label={GetField("fixedCartage")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}
                            {GetField("ticketCopies")["isVisible"] && <div className="col-md-3">
                                <Field id={"ticketCopies"} name={"ticketCopies"} label={GetField("ticketCopies")["label"]} component={FormNumericTextBox} min={0} max={10} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("comment")["isVisible"] && <div className='col-md-3'>
                                <Field id={"comment"} name={"comment"} label={GetField("comment")["label"]} component={FormTextArea}  />
                            </div>}                            
                            {GetField("tagId")["isVisible"] && <div className="col-md-3">
                                <Field id={"tagId"} name={"tagId"} label={GetField("tagId")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("smsDocket")["isVisible"] && <div className='col-md-3'>
                                <Field id={"smsDocket"} name={"smsDocket"} label={GetField("smsDocket")["label"]} component={FormSwitch}  />
                            </div>}
                            {GetField("emailDocket")["isVisible"] && <div className='col-md-3'>
                                <Field id={"emailDocket"} name={"emailDocket"} label={GetField("emailDocket")["label"]} component={FormSwitch}  />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("hidePriceOnTicket")["isVisible"] && <div className='col-md-3'>
                                <Field id={"hidePriceOnTicket"} name={"hidePriceOnTicket"} label={GetField("hidePriceOnTicket")["label"]} component={FormSwitch} />
                            </div>}
                            {GetField("noGST")["isVisible"] && <div className='col-md-3'>
                                <Field id={"noGST"} name={"noGST"} label={GetField("noGST")["label"]} component={FormSwitch} />
                            </div>}
                            {GetField("isOrderNoRequired")["isVisible"] && <div className='col-md-3'>
                                <Field id={"isOrderNoRequired"} name={"isOrderNoRequired"} label={GetField("isOrderNoRequired")["label"]} component={FormSwitch} />
                            </div>}
                            {GetField("isAlwaysReqOrderNoPerTxn")["isVisible"] && <div className='col-md-3'>
                                <Field id={"isAlwaysReqOrderNoPerTxn"} name={"isAlwaysReqOrderNoPerTxn"} label={GetField("isAlwaysReqOrderNoPerTxn")["label"]} component={FormSwitch} />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("addressDetails")["isVisible"] && <TabStripTab title={GetField("addressDetails")["label"]}>
                        <div className='row' >
                            {GetField("address1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address1"} name={"address1"} label={GetField("address1")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("address2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address2"} name={"address2"} label={GetField("address2")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("suburb")["isVisible"] && <div className='col-md-3'>
                                <Field id={"suburb"} name={"suburb"} label={GetField("suburb")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("state")["isVisible"] && <div className='col-md-3'>
                                <Field id={"state"} name={"state"} label={GetField("state")["label"]} component={FormDropDownList} data={states} />
                            </div>}                           
                        </div>
                        <div className='row' >
                            {GetField("postcode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"postcode"} name={"postcode"} label={GetField("postcode")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("phone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"phone"} name={"phone"} label={GetField("phone")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("contact")["isVisible"] && <div className='col-md-3'>
                                <Field id={"contact"} name={"contact"} label={GetField("contact")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("mobile")["isVisible"] && <div className='col-md-3'>
                                <Field id={"mobile"} name={"mobile"} label={GetField("mobile")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("email")["isVisible"] && <div className='col-md-3'>
                                <Field id={"email"} name={"email"} label={GetField("email")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("country")["isVisible"] && <div className='col-md-3'>
                                <Field id={"country"} name={"country"} label={GetField("country")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("fax")["isVisible"] && <div className='col-md-3'>
                                <Field id={"fax"} name={"fax"} label={GetField("fax")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("deliveryDetails")["isVisible"] && <TabStripTab title={GetField("deliveryDetails")["label"]}>
                        <div className='row' >
                            {GetField("deliveryAddress1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryAddress1"} name={"deliveryAddress1"} label={GetField("deliveryAddress1")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("deliveryAddress2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryAddress2"} name={"deliveryAddress2"} label={GetField("deliveryAddress2")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("deliverySuburb")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliverySuburb"} name={"deliverySuburb"} label={GetField("deliverySuburb")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("deliveryState")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryState"} name={"deliveryState"} label={GetField("deliveryState")["label"]} component={FormDropDownList} data={states} />
                            </div>}                           
                        </div>
                        <div className='row' >
                            {GetField("deliveryPostcode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryPostcode"} name={"deliveryPostcode"} label={GetField("deliveryPostcode")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("deliveryZone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryZone"} name={"deliveryZone"} label={GetField("deliveryZone")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("deliveryPhone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"deliveryPhone"} name={"deliveryPhone"} label={GetField("deliveryPhone")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("delivery")["isVisible"] && <div className='col-md-3'>
                                <Field id={"delivery"} name={"delivery"} label={GetField("delivery")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        <div className='row'>
                        {GetField("myobSyncInterval")["isVisible"] && <div className='col-md-3'>
                                <Field id={"myobSyncInterval"} name={"myobSyncInterval"} label={GetField("myobSyncInterval")["label"]} component={FormDropDownList} data={syncIntervals} onChange={onMyobChanged} />
                            </div>}                           
                            {GetField("monthlySyncDay")["isVisible"] && isMonthly && <div className="col-md-3">
                                <Field id={"monthlySyncDay"} name={"monthlySyncDay"} label={GetField("monthlySyncDay")["label"]} component={FormNumericTextBox} min={1} max={31} />
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("siteDetails")["isVisible"] && <TabStripTab title={GetField("siteDetails")["label"]}>                                       
                            <div className='row' style={{ padding: "10px" }} >
                                    {configData.SITE === 1 && GetField("customerSites")["isVisible"] && <div className='col-md-6' style={{ minWidth: "500px" }}>
                                    {GetField("customerSites")["label"]}
                                        <Field component={MultiSelectTreeEditor} name={"customerSites"} data={sitesTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                                </div>}
                                    {GetField("customerTrucks")["isVisible"] && <div className='col-md-6' style={{minWidth: "500px"}}>
                                    {GetField("customerTrucks")["label"]}
                                    <Field component={MultiSelectTreeEditor} name={"customerTrucks"} data={trucksTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />
                                </div>}
                            </div>                                       
                            <div className='row' >
                                
                        </div></TabStripTab>}
                        </TabStrip>                            
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditCustomers