import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDropDownList,  FormTextArea, FormInput, FormSwitch } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';

const EditDrivers = () => {
    const { id } = useParams(); // offences/id url is used to edit existing driver, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [states, setStates] = React.useState([ ]);
    const [customers, setCustomers] = React.useState([ ]);
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [permissions, setPermissions] = React.useState(null)
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    const navigate = useNavigate();

    // default layout data for page
    const [layout, setLayout] = React.useState([
            { "field": "name", "label": "Name", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "code", "label": "Code", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean"},
            { "field": "tagId", "label": "Tag Id", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "driverDetails", "label": "Driver", "defaultValue": "", "isVisible": true, "type": "heading" },
            { "field": "addressDetails", "label": "Address and contact details", "defaultValue": "", "isVisible": true, "type": "heading" },
            { "field": "address1", "label": "Address Line 1", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "address2", "label": "Address Line 2", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "suburb", "label": "Suburb", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "state", "label": "State", "defaultValue": "", "isVisible": true, "type": "list" },
            { "field": "postcode", "label": "Post Code", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "country", "label": "Country", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "phone", "label": "Phone", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "mobile", "label": "Mobile", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "email", "label": "Email", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "description", "label": "Comment", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "customerId", "label": "Customer", "defaultValue": "", "isVisible": true, "type": "list" }
        ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        name : GetField("name")["defaultValue"],
        code : GetField("code")["defaultValue"],
        tagId : GetField("tagId")["defaultValue"],
        addressDetails : GetField("addressDetails")["defaultValue"],
        address1 : GetField("address1")["defaultValue"],
        address2 : GetField("address2")["defaultValue"],
        suburb : GetField("suburb")["defaultValue"],
        state : GetField("state")["defaultValue"],
        postcode : GetField("postcode")["defaultValue"],
        country : GetField("country")["defaultValue"],
        phone : GetField("phone")["defaultValue"],
        mobile : GetField("mobile")["defaultValue"],
        email : GetField("email")["defaultValue"],
        description : GetField("description")["defaultValue"],
        customerId : GetField("customerId")["defaultValue"],      
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
      });

    // When page first loads:
    // - populate drop downs from api/options
    // - if in edit mode, set initial values for all fields for api/drivers
    React.useEffect(() => {
        GetPermissions(["ViewDriver", "NewDriver", "EditDriver"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'editDrivers'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setStates(opt.states);
            setCustomers(opt.customers);
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/drivers/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var c = opt.customers.find(obj => { return obj.id === String(d.customerId) });
                    setValues({
                        name: d.name === null ? "" : d.name, 
                        code: d.code === null ? "" : d.code, 
                        tagId: d.tagId === null ? "" : d.tagId, 
                        addressDetails: d.addressDetails === null ? "" : d.addressDetails, 
                        address1: d.address1 === null ? "" : d.address1, 
                        address2: d.address2 === null ? "" : d.address2, 
                        suburb: d.suburb === null ? "" : d.suburb, 
                        state: d.state === null ? "" : d.state, 
                        postcode: d.postcode === null ? "" : d.postcode, 
                        country: d.country === null ? "" : d.country, 
                        phone: d.phone === null ? "" : d.phone, 
                        mobile: d.mobile === null ? "" : d.mobile, 
                        email: d.email === null ? "" : d.email, 
                        description: d.description === null ? "" : d.description, 
                        customerId: d.customerId === null ? "" : c, 
                        active: d.isActive
                    });
                    setFormkey(formkey + 1);
                })
            }
        });
    },[]);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    const isNullOrEmpty = (x) => { return (x === undefined || x === null || x === ""); }
    
    const Validate = (dataItem) => {
        let st = "";
        if (isNullOrEmpty(dataItem.name)) st += "Name\r\n";
        if (isNullOrEmpty(dataItem.code)) st += "Code\r\n";
        if (st !== ""){
            setSelectedTab(0);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        return true;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        if (!Validate(dataItem)) return;  
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/drivers${pth}`;
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "driverId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.name ,"name"),
            "code": GetDefaultData(dataItem.code ,"code"),
            "tagId": GetDefaultData(dataItem.tagId ,"tagId"),
            "addressDetails": GetDefaultData(dataItem.addressDetails ,"addressDetails"),
            "address1": GetDefaultData(dataItem.address1 ,"address1"),
            "address2": GetDefaultData(dataItem.address2 ,"address2"),
            "suburb": GetDefaultData(dataItem.suburb ,"suburb"),
            "state": GetDefaultData(dataItem.state ,"state"),
            "postcode": GetDefaultData(dataItem.postcode ,"postcode"),
            "country": GetDefaultData(dataItem.country ,"country"),
            "phone": GetDefaultData(dataItem.phone ,"phone"),
            "mobile": GetDefaultData(dataItem.mobile ,"mobile"),
            "email": GetDefaultData(dataItem.email ,"email"),
            "description": GetDefaultData(dataItem.description ,"description"),
            "customerId": GetDefaultData(dataItem.customerId === undefined ? null : dataItem.customerId.id,"customerId"),
            "isActive": GetDefaultData(dataItem.active, "active")
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = id === undefined ? "New driver created" : "Driver updated";
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/drivers", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("driverDetails")["isVisible"] && <TabStripTab title={GetField("driverDetails")["label"]}>
                        <div className="row">
                           {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("code")["isVisible"] && <div className="col-md-3">
                                <Field id={"code"} name={"code"} label={GetField("code")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("tagId")["isVisible"] && <div className="col-md-3">
                                <Field id={"tagId"} name={"tagId"} label={GetField("tagId")["label"]} component={FormInput} />
                            </div>}
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        </TabStripTab>}
                        {GetField("addressDetails")["isVisible"] && <TabStripTab title={GetField("addressDetails")["label"]}>
                        <div className='row' >
                            {GetField("address1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address1"} name={"address1"} label={GetField("address1")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("address2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address2"} name={"address2"} label={GetField("address2")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("suburb")["isVisible"] && <div className='col-md-3'>
                                <Field id={"suburb"} name={"suburb"} label={GetField("suburb")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("state")["isVisible"] && <div className='col-md-3'>
                                <Field id={"state"} name={"state"} label={GetField("state")["label"]} component={FormDropDownList} data={states} />
                            </div>}                           
                        </div>
                        <div className='row' >
                            {GetField("postcode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"postcode"} name={"postcode"} label={GetField("postcode")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("phone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"phone"} name={"phone"} label={GetField("phone")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("mobile")["isVisible"] && <div className='col-md-3'>
                                <Field id={"mobile"} name={"mobile"} label={GetField("mobile")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("email")["isVisible"] && <div className='col-md-3'>
                                <Field id={"email"} name={"email"} label={GetField("email")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("country")["isVisible"] && <div className='col-md-3'>
                                <Field id={"country"} name={"country"} label={GetField("country")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("customerId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"customerId"} name={"customerId"} label={GetField("customerId")["label"]} component={FormDropDownList} textField={"name"} data={customers} />
                            </div>}                           
                            {GetField("description")["isVisible"] && <div className='col-md-3'>
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormTextArea}  />
                            </div>}
                        </div>
                        </TabStripTab>}
                        </TabStrip>                        
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditDrivers