import * as React from "react";
import { TaskBoard } from "@progress/kendo-react-taskboard";
import { Column } from "../components/Tasks/TodoColumn";
import { MyTaskBoardCard } from "../components/Tasks/card";
import { useState } from "react";
import { GetSession, GetToken } from '../components/Session';

const Todos = () => {
  const [cards, setCards] = useState([{ id: 1, title: "New task", order: 0, description: "New task", status: "todo", priority: { color: "green", priority: "Low Priority" }, image: null }]);
  const [tasks, setTasks] = useState(cards.map((c) => ({ id: c.id, title: c.title, description: c.description, status: c.status, priority: c.priority, })));
  
  const onChangeHandler = React.useCallback((event) => {
    if (event.type === "column") {
      setColumnsData(event.data);
    } else {
      // New Task has been added.
      if (event.item && event.item.id === undefined) {
        event.item.id = event.data.length + 1;
      }
      setTasks(event.data);
        var q = "https://localhost:7294/apiv7/userfilters?$filter=viewName eq 'cards'";
      var init = {
          method: "POST", 
          headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + GetToken()
          },
          body: JSON.stringify({
            userId: GetSession().UserId,
            viewName: 'ToDos',
            value: JSON.stringify(event.data)
          })
      };
      fetch(q, init).then().then();
    }
  }, []);

  const columns = [ { id: 1, title: "To-Do", status: "todo" }, { id: 2, title: "In Progress", status: "inProgress" }, { id: 3, title: "Done", status: "done" }, { id: 4, title: "Archive", status: "archive" }];
  const priorities = [ { priority: "Low Priority", color: "green" }, { priority: "Normal", color: "blue" }, { priority: "Urgent", color: "red" } ];
  const [columnsData, setColumnsData] = React.useState(columns);

  React.useEffect(() => {
      let url =`https://localhost:7294/apiv7/userfilters?$userid=${GetSession().UserId}&filter=viewName eq 'ToDos'`   
    const init = {
      method: 'GET',
      accept: 'application/json',
      headers: { "Authorization": "Bearer " + GetToken() }
    }
    fetch(url, init).then(response => response.json()).then(d => { 
      if (d !== undefined && d.length > 0){
        let arr = d[0].value;
        let cd = JSON.parse(arr);
        setCards(cd)
        let t = cd.map((c) => ({
          id: c.id,
          title: c.title,
          description: c.description,
          status: c.status,
          priority: c.priority,
        }))
        setTasks(t)
      }
    });
    }, []);
  
    return (
    <div className={"k-taskboard-column-header"}>
      <div className={"k-taskboard-column-header-text k-text-ellipsis"}>
            {tasks && <TaskBoard columnData={columnsData} taskData={tasks} priorities={priorities} onChange={onChangeHandler} column={Column} card={MyTaskBoardCard} style={{ height: "660px" }} tabIndex={0}>           
            </TaskBoard> }
        </div>
    </div>    
    );
}

export default Todos;