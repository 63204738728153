import * as React from "react";
import { TileLayout } from "@progress/kendo-react-layout";
import RealTimeTrend from "./RealTimeTrend";


const RealTimeTile = () => {
    const tiles = [
        {
            defaultPosition: {
                col: 1,
                colSpan: 4,
                rowSpan: 1,
            },
            resizable: false,
            reorderable: false,
            body: <RealTimeTrend></RealTimeTrend>,
        },
    ];

    return (

        <TileLayout
            rowHeight={900}
            columns={4}
            gap={{
                rows: 5,
                columns: 5,
            }}
            items={tiles}
            adaptive={true}
        />
    );
};

export default RealTimeTile;
