import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import configData from "../config.json";
import { TextArea } from "@progress/kendo-react-inputs";
import { GetPermissions } from '../components/Options';
import { GetSession, GetToken } from "../components/Session";

const ViewDocket = () => {
    const { id } = useParams(); 
    const [docket, setDocket] = React.useState("");
    const navigate = useNavigate();
    const [permissions, setPermissions] = React.useState(null);
    const [layout, setLayout] = React.useState([]);

    React.useEffect(() => {
        GetPermissions(["ViewTransaction"], setPermissions);
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        let url = `${configData.SERVER_URL}apiv7/private/getdocketbycode?docket=${id}`;
        fetch(url, init).then(response => response.text()).then(d => {
            setDocket(d);
        });
    }, []);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/docketlookup", { state: { msg: msg } });
    }
    if (permissions && !permissions.CanView) navigate("/");

    return (
        <div style={{ padding: "20px" }}>
            <div className="row" style={{ padding: "20px" }}>
                <div className="col-md-6">
                    <TextArea rows={30} columns={100} value={docket} style={{ fontFamily: "Courier", fontWeight: "bold" }} />
                </div>
            </div>
            <div className="k-form-buttons" style={{ paddingTop: 10 }}>
                <div className='col-md-1'>
                    <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                </div>
            </div>
        </div>
    );
}

export default ViewDocket;
