import * as React from "react";
import Notifications from '../components/Notifications';
import DataGrid from "../components/DataGrid";
import GetColumnDefs from "../components/ColumnDefs";
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
import { MultiSelect, DropDownList } from "@progress/kendo-react-dropdowns";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { GetPermissions } from '../components/Options';
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { filterBy } from "@progress/kendo-data-query";

const Trucks = () => {
  const [lookupParams, setLookupParams] = React.useState("");
  const [loadTypes, setLoadTypes] = React.useState([]);
  const [loadType, setLoadType] = React.useState([]);
  const [vehicleTypes, setVehicleTypes] = React.useState([]);
  const [vehicleType, setVehicleType] = React.useState();
  const [jobs, setJobs] = React.useState([]);
  const [job, setJob] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [customer, setCustomer] = React.useState([]);
  const [sources, setSources] = React.useState([]);
  const [source, setSource] = React.useState([]);
  const [destinations, setDestinations] = React.useState([]);
  const [destination, setDestination] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [site, setSite] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [truckConfigs, setTruckConfigs] = React.useState([]);
  const [truckConfig, setTruckConfig] = React.useState([]);
  const [directions, setDirections] = React.useState([]);
  const [direction, setDirection] = React.useState();
  const [truckTrailers, setTruckTrailers] = React.useState([]);
  const [truckTrailer, setTruckTrailer] = React.useState();
  const [statii, setStatii] = React.useState([]);
  const [status, setStatus] = React.useState();
  const [expanded, setExpanded] = React.useState(false);
  const ready = React.useRef(false);
  const [columns, setColumns] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [permissions, setPermissions] = React.useState(null);
  const [productdata, setproductData] = React.useState();
  const [customerdata, setcustomerData] = React.useState();
  const [jobdata, setjobData] = React.useState(); 
  const [truckconfdata, settruckconfData] = React.useState();
  const [destdata, setdestData] = React.useState();
  const [srcdata, setsrcData] = React.useState();
  const options = React.useRef();
  const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");


  const [layout, setLayout] = React.useState([
    { "field": "searchAdvanced", "label": "Advanced Search", "isVisible": true, },
    { "field": "searchRego", "label": "Rego", "isVisible": true, }, 
    { "field": "searchCode", "label": "Code", "isVisible": true, }, 
    { "field": "searchFleet", "label": "Fleet", "isVisible": true, },
    { "field": "searchLoadType", "label": "Load Type", "isVisible": true, }, 
    { "field": "searchVehicletype", "label": "Vehicle Type", "isVisible": true, }, 
    { "field": "searchJob", "label": "Job", "isVisible": true, }, 
    { "field": "searchCustomer", "label": "Customer", "isVisible": true, }, 
    { "field": "searchSource", "label": "Source", "isVisible": true, }, 
    { "field": "searchDestination", "label": "Destination", "isVisible": true, }, 
    { "field": "searchSite", "label": "Site", "isVisible": true, }, 
    { "field": "searchProduct", "label": "Product", "isVisible": true, }, 
    { "field": "searchTruckConfig", "label": "Truck Config", "isVisible": true, }, 
    { "field": "searchDirection", "label": "Direction", "isVisible": true, }, 
    { "field": "searchStatus", "label": "Status", "isVisible": true, }, 
    { "field": "searchTruckTrailer", "label": "Truck/Trailer", "isVisible": true, }, 
    { "field": "searchTagId", "label": "Tag ID", "isVisible": true, }  
  ]);

  const GetField = (f) => {
    return layout.find(obj => { return obj.field === f });
  }

  const DoSearch = () => {
    var truckParam = {
      Name: $("#Rego").val(),
      Code: $("#Code").val(),
      Fleet:$("#Fleet").val(),
      LoadType: loadType === undefined ? "0" : GetLoadTypes(loadType),
      Vehicle: GetIds(vehicleType),
      Job: GetIds(job),
      Customer: GetIds(customer),
      Source: GetIds(source),
      Destination: GetIds(destination),
      Site: GetIds(site),
      Product: GetIds(product),
      TruckConfig: GetIds(truckConfig),
      Direction: direction,
      Status: status,
      Trucktrailer: truckTrailer,
      TagId: $("#TagId").val(),
    };
    setLookupParams(encodeURI(JSON.stringify(truckParam)));
    };

    const GetIds = (d) => {
        if (d.length === undefined || d.length === 0) return "0";
        let r = "";
        d.map(x => {
            r += (r.length > 0 ? ";" : "") + x.id;
        });
        return r;
    }

    const GetLoadTypes = (loadType) => {
        if (loadType === "" || loadType === undefined) return "";
        let r = "";
        loadType.map(x => {
            r += (r.length > 0 ? ";" : "") + x;
        })
        return r;
    }

  const ClearSearch = () => {
    if (ready.current) {
      ClearParams();
      var jobParam = {
        Source: "0",
        Destination: "0",
        Site: "0",
      };
      setLookupParams(encodeURI(JSON.stringify(jobParam)));
    }
  };
  const ClearParams = () => {
    $("#Rego").val("");
    $("#Code").val("");
    $("#Fleet").val("");
    setLoadType([]);
    setVehicleType([]);
    setJob([]);
    setCustomer([]);
    setSource([]);
    setDestination([]);
    setSite([]);
    setProduct([]);
    setTruckConfig([]);
    setDirection("All");
    setTruckTrailer("All");
    setStatus("Active");
  };
  const loadTypeChanged = (event) => {
    setLoadType(event.target.value);
  };
  const vehicleTypeChanged = (event) => {
    setVehicleType(event.target.value);
  };
  const jobChanged = (event) => {
    setJob(event.target.value);
  };
  const customerChanged = (event) => {
    setCustomer(event.target.value);
  };
  const sourceChanged = (event) => {
    setSource(event.target.value);
  };
  const destinationChanged = (event) => {
    setDestination(event.target.value);
  };
  const siteChanged = (event) => {
    setSite(event.target.value);
  };
  const productChanged = (event) => {
    setProduct(event.target.value);
  };
  const truckConfigChanged = (event) => {
    setTruckConfig(event.target.value);
  };
  const directionChanged = (event) => {
    setDirection(event.target.value);
  };
  const statusChanged = (event) => {
    setStatus(event.target.value);
  };
  const truckTrailerChanged = (event) => {
    setTruckTrailer(event.target.value);
  };

  React.useEffect(() => {
    GetColumnDefs("trucks", setColumns, setErrorMessage); 
    GetPermissions(["ViewTruck", "NewTruck", "EditTruck", "DeleteTruck", "ReportTruck"], setPermissions)
    let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'Trucks'";
    const init = {
      method: "GET",
      accept: "application/json",
      headers: {"Authorization": "Bearer " + GetToken()},
    };
    fetch(baseUrl, init).then(response => response.json()).then(d => { 
      if (GetSession().IsMobile){
          let data = [];
          for(let i = 0; i < d.length; i++){
              data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
          }
          setLayout(data);
      } else {
          setLayout(d); 
      }
    });  
    baseUrl = configData.SERVER_URL + "apiv7/options";
    fetch(baseUrl, init)
      .then((response) => response.json())
        .then((d) => {
        options.current = d;
        setLoadTypes(d.loadTypes);
        setVehicleTypes(d.vehicles);
        setJobs(d.jobs);
        setjobData(d.jobs);
        setCustomers(d.customers);
        setcustomerData(d.customers);
        setSources(d.sources);
        setsrcData(d.sources);
        setDestinations(d.destinations);
        setdestData(d.destinations);
        setSites(d.sites);
        setProducts(d.products);
        setproductData(d.products);
        setTruckConfigs(d.vehicleConfigurations);
        settruckconfData(d.vehicleConfigurations);
        setDirections(d.allDirections);
        setTruckTrailers(d.truckTrailer);
        setStatii(d.status);
        ClearParams();
        ready.current = true;
      });
  }, []);
  
    const filterproductData = (filter) => {
        const productdata = products.slice();
        return filterBy(productdata, filter);
    };
    const filterprodChange = (event) => {
        setproductData(filterproductData(event.filter));
    };

    const filterjobtData = (filter) => {
        const jobdata = jobs.slice();
        return filterBy(jobdata, filter);
    };
    const filterjobChange = (event) => {
        setjobData(filterjobtData(event.filter));
    };

    const filtercustomerData = (filter) => {
        const customerdata = customers.slice();
        return filterBy(customerdata, filter);
    };
    const filtercustChange = (event) => {
        setcustomerData(filtercustomerData(event.filter));
    };
    const filterdesData = (filter) => {
        const desdata = destinations.slice();
        return filterBy(desdata, filter);
    };
    const filterdesChange = (event) => {
        setdestData(filterdesData(event.filter));
    };

    const filtersrctData = (filter) => {
        const srcdata = sources.slice();
        return filterBy(srcdata, filter);
    };
    const filtersrcChange = (event) => {
        setsrcData(filtersrctData(event.filter));
    };

    const filtertruckconfData = (filter) => {
        const truckconfdata = truckConfigs.slice();
        return filterBy(truckconfdata, filter);
    };
    const filtertruckconfChange = (event) => {
        settruckconfData(filtertruckconfData(event.filter));
    };
  return (
    <div>
      <Notifications errorMessage={errorMessage}/>
      {GetField("searchAdvanced")["isVisible"] && <ExpansionPanel
        title={GetField("searchAdvanced")["label"]}
        expandIcon="fa fa-plus"
        collapseIcon="fa fa-minus"
        expanded={expanded}
        onAction={(e) => setExpanded(!e.expanded)}
      >
        <Reveal>
          {expanded && (
            <ExpansionPanelContent>
              <div className="row" style={{ padding: "5px 10px" }}>
              {GetField("searchRego")["isVisible"] && <div className="col-md-2">
                  <label className="control-label">{GetField("searchRego")["label"]}</label>
                  <TextBox id="Rego" type="text" />
                </div>}
                {GetField("searchCode")["isVisible"] && <div className="col-md-2">
                  <label className="control-label">{GetField("searchCode")["label"]}</label>
                  <TextBox id="Code" type="text" />
                              </div>}                              
                {GetField("searchLoadType")["isVisible"] && <div className="col-md-2">
                  <label className="control-label">{GetField("searchLoadType")["label"]}</label>
                  <MultiSelect
                    id="LoadType"
                    data={loadTypes}
                    value={loadType}
                    onChange={loadTypeChanged}
                  />
                </div>}
                {GetField("searchVehicletype")["isVisible"] && <div className="col-md-2">
                  <label className="control-label">{GetField("searchVehicletype")["label"]}</label>
                  <MultiSelect
                    id="VehicleType"
                    data={vehicleTypes}
                    value={vehicleType}
                    textField="name"
                    dataItemKey="id"
                    onChange={vehicleTypeChanged}
                  />
                </div>}
                {GetField("searchJob")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchJob")["label"]}</label>
                    <MultiSelect
                        onFilterChange={filterjobChange}
                        filterable={true}
                        id="Job" data={jobdata}
                        value={job}
                        textField="name"
                        dataItemKey="id"
                        onChange={jobChanged}
                    />
                              </div>}
              </div>
              <div className="row" style={{ padding: "5px 10px" }}>
                
                {GetField("searchCustomer")["isVisible"] && <div className="col-md-2">
                  <label className="control-label">{GetField("searchCustomer")["label"]}</label>
                  <MultiSelect
                    id="Customer"
                    onFilterChange={filtercustChange}
                    data={customerdata}
                    value={customer}
                    textField="name"
                    dataItemKey="id"
                    onChange={customerChanged}
                  />
                </div>}
                {GetField("searchSource")["isVisible"] && <div className="col-md-2">
                  <label className="control-label">{GetField("searchSource")["label"]}</label>
                  <MultiSelect
                    id="Source"
                    onFilterChange={filtersrcChange}
                    data={srcdata}
                    value={source}
                    textField="name"
                    dataItemKey="id"
                    onChange={sourceChanged}
                  />
                </div>}
                {GetField("searchDestination")["isVisible"] && <div className="col-md-2">
                  <label className="control-label">{GetField("searchDestination")["label"]}</label>
                  <MultiSelect
                    id="Destination"
                    onFilterChange={filterdesChange}
                    data={destdata}
                    value={destination}
                    textField="name"
                    dataItemKey="id"
                    onChange={destinationChanged}
                  />
                </div>}
                {GetField("searchSite")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchSite")["label"]}</label>
                    <MultiSelect
                        id="Site"
                        data={sites}
                        value={site}
                        textField="name"
                        dataItemKey="id"
                        onChange={siteChanged}
                    />
                </div>}
                {GetField("searchProduct")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchProduct")["label"]}</label>
                    <MultiSelect
                        onFilterChange={filterprodChange}
                        id="Product" data={productdata}
                        value={product}
                        textField="name"
                        dataItemKey="id"
                        onChange={productChanged}
                    />
                </div>}
              </div>
              <div className="row" style={{ padding: "5px 10px" }}>
              
                
                {GetField("searchTruckConfig")["isVisible"] && <div className="col-md-2">
                  <label className="control-label">{GetField("searchTruckConfig")["label"]}</label>
                  <MultiSelect
                    id="TruckConfig"
                    onFilterChange={filtertruckconfChange}
                    data={truckconfdata}
                    value={truckConfig}
                    textField="name"
                    dataItemKey="id"
                    onChange={truckConfigChanged}
                  />
                </div>}
                {GetField("searchDirection")["isVisible"] && <div className="col-md-2">
                  <label className="control-label">{GetField("searchDirection")["label"]}</label>
                  <DropDownList
                    id="Direction"
                    data={directions}
                    value={direction}
                    onChange={directionChanged}
                  />
                </div>}
                {GetField("searchStatus")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchStatus")["label"]}</label>
                    <DropDownList
                        id="TruckTrailer"
                        data={statii}
                        value={status}
                        onChange={statusChanged}
                    />
                </div>}
                {GetField("searchTruckTrailer")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchTruckTrailer")["label"]}</label>
                    <DropDownList
                        id="TruckTrailer"
                        data={truckTrailers}
                        value={truckTrailer}
                        onChange={truckTrailerChanged}
                    />
                </div>}
                {GetField("searchTagId")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchTagId")["label"]}</label>
                    <TextBox id="TagId" type="text" />
                              </div>}                             
                          </div>

                          <div className="row" style={{ padding: "5px 10px" }}>

                           
                              {GetField("searchFleet")["isVisible"] && <div className="col-md-2">
                                  <label className="control-label">{GetField("searchFleet")["label"]}</label>
                                  <TextBox id="Fleet" type="text" />
                              </div>}
                          </div>
                         
              <div className="row" style={{ padding: "10px" }}>
                <div className="col-md-5"></div>
                <div className="col-md-3">
                  <Button onClick={DoSearch} themeColor={"primary"}>
                    Search
                  </Button>{" "}
                  <Button onClick={ClearSearch} themeColor={"primary"}>
                    Clear
                  </Button>
                </div>
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>}
      {columns && permissions && <DataGrid
        dataSource="apiv7/trucks"
        params={lookupParams}
        columns={columns}
        permissions={permissions}
        keyField="truckId"
      />}
    </div>
  );
};
export default Trucks;
