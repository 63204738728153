import * as React from "react";
import { GetSession, GetToken } from './components/Session';
import { GetPermissions } from './components/Options';
import configData from "./config.json";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Customers from "./pages/Customers";
import Drivers from "./pages/Drivers";
import Transactions from "./pages/Transactions";
import Sites from "./pages/Sites";
import WasteStreams from "./pages/WasteStreams";
import Sources from "./pages/Sources";
import Destinations from "./pages/Destinations";
import Products from "./pages/Products";
import CountedItemProducts from "./pages/CountedItemProducts";
import VehicleConfigurations from "./pages/VehicleConfigurations";
import Vehicles from "./pages/Vehicles";
import Jobs from "./pages/Jobs";
import Users from "./pages/Users";
import EventLogs from "./pages/EventLogs";
import Offences from "./pages/Offences";
import FirstWeighs from "./pages/FirstWeighs";
import DocketLookup from "./pages/DocketLookup";
import Inventory from "./pages/Inventory";
import InventoryHistory from "./pages/InventoryHistory";
import Suppliers from "./pages/Suppliers";
import Roles from "./pages/Roles";
import Trucks from "./pages/Trucks";
import EditOffences from "./pages/EditOffences";
import EditProductCategories from "./pages/EditProductCategories";
import EditVehicleConfigurations from "./pages/EditVehicleConfigurations";
import EditSites from "./pages/EditSites";
import EditCustomers from "./pages/EditCustomers";
import ManagePages from "./pages/ManagePages";
import EditUserInterfaces from "./pages/EditUserInterfaces";
import EditSources from "./pages/EditSources";
import EditProducts from "./pages/EditProducts";
import EditDrivers from "./pages/EditDrivers";
import EditVehicles from "./pages/EditVehicles";
import EditUsers from "./pages/EditUsers";
import EditJobs from "./pages/EditJobs";
import AddExtraCharge from "./pages/AddExtraCharge";
import EditTrucks from "./pages/EditTrucks";
import EditTransactions from "./pages/EditTransactions";
import Contact from "./pages/Contact";
import ModifyInventory from "./pages/ModifyInventory";
import EditDestinations from "./pages/EditDestinations";
import EPAReport from "./pages/EPAReport";
import DrawerContainer from "./components/DrawerContainer";
import Error401 from "./components/Error401";
import Error404 from "./components/Error404";
import Dashboard from "./pages/Dashboard";
import EditRoles from "./pages/EditRoles";
import VideoTraining from "./pages/VideoTraining";
import Settings from "./pages/Settings";
import Account from "./pages/Account";
import PrintTransactions from "./pages/PrintTransactions";
import EditDocketLookup from "./pages/EditDocketLookup";
import ViewDocket from "./pages/ViewDocket";
import Todos from "./pages/Todos";
import SystemSetting from "./pages/SystemSetting";
import EditSiteSystem from "./pages/EditSiteSystem";
import EditFirstWeighs from "./pages/EditFirstWeighs";
import Reports from "./pages/Reports.jsx";
import Support from "./pages/Support";
import EditSupport from "./pages/EditSupport";
import UserRegistration from "./pages/UserRegistration";
import SimpleReports from "./pages/SimpleReports";
import Login from "./pages/Login";
import Password from "./pages/Password";
import Logout from "./pages/Logout";
import Dockets from "./pages/Dockets";
import EditDocketTemplates from "./pages/EditDocketTemplates";
import UserApproval from "./pages/UserApproval";
import Weighbridge from "./WeighBridge/Weighbridge";
import WeighbridgeSettings from "./pages/WeighbridgeSettings";
import Vouchers from "./pages/Vouchers";
import EditVouchers from "./pages/EditVouchers";
import PrintVouchers from "./pages/PrintVouchers";
import BusinessData from "./pages/BusinessData";
import EditBusinessData from "./pages/EditBusinessData";
import Messages from "./pages/Messages";
import EditMessageQueues from "./pages/EditMessageQueues";
import OutputState from "./pages/OutputState";
import EditOutputStates from "./pages/EditOutputStates";
import Kiosk from "./WeighBridge/Kiosk"
import WatchVideo from "./pages/WatchVideo";
import Mobile from "./WeighBridge/Mobile";
const App = () => {
    const token = GetToken();
    const theme = React.useRef("theme-default");
    const [primarycolor, setprimarycolor] = React.useState("");
    const [background, setbackground] = React.useState("");
    const [fontcolor, setfontcolor] = React.useState("");
    const [defaultinterface, setdefaultinterface] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    React.useEffect(() => {
        GetPermissions(["ViewSettings"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=settings`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            let def = d.find(obj => { return obj.field === "defaultinterface" });
            let s = d.find(obj => { return obj.field === "swatch" });
            let t = d.find(obj => { return obj.field === "theme" });
            let b = d.find(obj => { return obj.field === "background" });
            let f = d.find(obj => { return obj.field === "fontcolor" });
            theme.current = t.defaultValue;
            setprimarycolor(s.defaultValue);
            setbackground(b.defaultValue);
            setfontcolor(f.defaultValue);
            setdefaultinterface(def.defaultValue);

        });
    }, []);
    let styles;
    const root = document.documentElement;
    root.style.setProperty('--kendo-color-primary', primarycolor);
    root.style.setProperty('--kendo-color-secondary', primarycolor);
    root.style.setProperty('--kendo-color-border', primarycolor);
    root.style.setProperty('--kendo-color-border', primarycolor);
    root.style.setProperty('--kendo-color-subtle', primarycolor);

    root.style.setProperty('--kendo-color-secondary-hover', primarycolor);
    root.style.setProperty('--kendo-color-light', primarycolor);
    root.style.setProperty('--kendo-color-on-light', "black");
    root.style.setProperty('--kendo-color-on-app-surface', primarycolor);
    root.style.setProperty('--kendo-color-border', primarycolor);
    root.style.setProperty('--kendo-color-surface', primarycolor);
    root.style.setProperty('--kendo-color-border', primarycolor);
    root.style.setProperty('--kendo-color-on-app-surface', "black");

    root.style.setProperty('--kendo-color-base-hover', primarycolor);
    root.style.setProperty('--kendo-color-primary-hover', primarycolor);
    root.style.setProperty('--kendo-color-base', primarycolor);
    root.style.setProperty('--kendo-color-on-app-surface', fontcolor);
    root.style.setProperty('--kendo-color-on-base', fontcolor);
    if (background === 'Yes') {
        root.style.setProperty('--kendo-color-surface-alt', primarycolor);
    }
    
    import("@progress/kendo-" + theme.current + "/dist/all.scss").then((res) => { styles = res; });
    
  const isLoggedIn = token != undefined && token != null
  const getroutes = () => {
      return (
       
      <Routes>
        <Route path="/login" element={<Login/>}/>
        <Route path="/logout" element={<Logout/>}/>
        <Route path="/" element={isLoggedIn? <Dashboard/> : <Navigate to='/login'/>}/>        
        <Route path={"/Customers"} element={isLoggedIn ? <Customers/> : <Navigate to='/login'/>}/>        
        <Route path="/Drivers" element={isLoggedIn ? <Drivers/> : <Navigate to='/login'/>}/>        
        <Route path="/Transactions" element={isLoggedIn? <Transactions/> : <Navigate to='/login'/>}/>        
        <Route path="/Sites" element={isLoggedIn? <Sites/> : <Navigate to='/login'/>}/>        
        <Route path="/WasteStreams" element={isLoggedIn? <WasteStreams/> : <Navigate to='/login'/>}/>        
        <Route path="/Sources" element={isLoggedIn? <Sources/> : <Navigate to='/login'/>}/>        
        <Route path="/Destinations" element={isLoggedIn? <Destinations/> : <Navigate to='/login'/>}/>        
        <Route path="/Products" element={isLoggedIn? <Products/> : <Navigate to='/login'/>}/>        
        <Route path="/CountedItemProducts" element={isLoggedIn? <CountedItemProducts/> : <Navigate to='/login'/>}/>        
        <Route path="/VehicleConfigurations" element={isLoggedIn? <VehicleConfigurations/> : <Navigate to='/login'/>}/>        
        <Route path="/Vehicles" element={isLoggedIn? <Vehicles/> : <Navigate to='/login'/>}/>        
        <Route path="/Jobs" element={isLoggedIn? <Jobs/> : <Navigate to='/login'/>}/>        
        <Route path="/Users" element={isLoggedIn? <Users/> : <Navigate to='/login'/>}/>        
        <Route path="/EventLogs" element={isLoggedIn? <EventLogs/> : <Navigate to='/login'/>}/>        
        <Route path="/Offences" element={isLoggedIn? <Offences/> : <Navigate to='/login'/>}/>        
        <Route path="/FirstWeighs" element={isLoggedIn? <FirstWeighs/> : <Navigate to='/login'/>}/>        
        <Route path="/DocketLookup" element={isLoggedIn? <DocketLookup/> : <Navigate to='/login'/>}/>        
        <Route path="/Inventory" element={isLoggedIn? <Inventory/> : <Navigate to='/login'/>}/>   
        <Route path="/InventoryHistory" element={isLoggedIn? <InventoryHistory/> : <Navigate to='/login'/>}/>      
        <Route path="/Suppliers" element={isLoggedIn? <Suppliers/> : <Navigate to='/login'/>}/>        
        <Route path="/Roles" element={isLoggedIn? <Roles/> : <Navigate to='/login'/>}/>        
        <Route path="/Trucks" element={isLoggedIn? <Trucks/> : <Navigate to='/login'/>}/>        
        <Route path="/EPAReport" element={isLoggedIn? <EPAReport/> : <Navigate to='/login'/>}/>        
        <Route path="/ManagePages" element={isLoggedIn? <ManagePages/> : <Navigate to='/login'/>}/>        
        <Route path="/VideoTraining" element={isLoggedIn? <VideoTraining/> : <Navigate to='/login'/>}/>        
        <Route path="/EditOffences/:id?" element={isLoggedIn? <EditOffences/> : <Navigate to='/login'/>}/>        
        <Route path="/EditProductCategories/:id?" element={isLoggedIn? <EditProductCategories/> : <Navigate to='/login'/>}/>        
        <Route path="/EditVehicleConfigurations/:id?" element={isLoggedIn? <EditVehicleConfigurations/> : <Navigate to='/login'/>}/>        
        <Route path="/EditSites/:id?" element={isLoggedIn? <EditSites/> : <Navigate to='/login'/>}/>        
        <Route path="/EditCustomers/:type/:id?" element={isLoggedIn? <EditCustomers/> : <Navigate to='/login'/>}/>        
        <Route path="/EditUserInterfaces/:id?" element={isLoggedIn? <EditUserInterfaces/> : <Navigate to='/login'/>}/>        
        <Route path="/EditSources/:id?" element={isLoggedIn? <EditSources/> : <Navigate to='/login'/>}/>        
        <Route path="/EditProducts/:type/:id?" element={isLoggedIn? <EditProducts/> : <Navigate to='/login'/>}/>        
        <Route path="/EditDrivers/:id?" element={isLoggedIn? <EditDrivers/> : <Navigate to='/login'/>}/>        
        <Route path="/EditDestinations/:id?" element={isLoggedIn? <EditDestinations/> : <Navigate to='/login'/>}/>        
        <Route path="/EditVehicles/:id?" element={isLoggedIn? <EditVehicles/> : <Navigate to='/login'/>}/>        
        <Route path="/EditUsers/:id?" element={isLoggedIn? <EditUsers/> : <Navigate to='/login'/>}/>        
        <Route path="/EditJobs/:id?" element={isLoggedIn? <EditJobs/> : <Navigate to='/login'/>}/>        
        <Route path="/AddExtraCharge/:id?" element={isLoggedIn? <AddExtraCharge/> : <Navigate to='/login'/>}/>        
        <Route path="/EditTrucks/:id?" element={isLoggedIn? <EditTrucks/> : <Navigate to='/login'/>}/>        
        <Route path="/EditTransactions/:id?" element={isLoggedIn? <EditTransactions/> : <Navigate to='/login'/>}/>        
        <Route path="/EditRoles/:id?" element={isLoggedIn? <EditRoles/> : <Navigate to='/login'/>}/>        
        <Route path="/EditFirstWeighs/:id?" element={isLoggedIn? <EditFirstWeighs/> : <Navigate to='/login'/>}/>        
        <Route path="/ModifyInventory/:action" element={isLoggedIn? <ModifyInventory/> : <Navigate to='/login'/>}/>        
        <Route path="/Contact" element={isLoggedIn? <Contact/> : <Navigate to='/login'/>}/>        
        <Route path="/Settings" element={isLoggedIn? <Settings/> : <Navigate to='/login'/>}/>        
        <Route path="/Password" element={isLoggedIn ? <Password /> : <Navigate to='/login' />} />        
        <Route path="/Dockets" element={isLoggedIn? <Dockets/> : <Navigate to='/login'/>}/>        
        <Route path="/EditDocketTemplates/:id?" element={isLoggedIn? <EditDocketTemplates/> : <Navigate to='/login'/>}/>        
        <Route path="/Account" element={isLoggedIn? <Account/> : <Navigate to='/login'/>}/>        
        <Route path="/PrintTransactions/:id?" element={isLoggedIn? <PrintTransactions/> : <Navigate to='/login'/>}/>        
        <Route path="/EditDocketLookup/:id?" element={isLoggedIn? <EditDocketLookup/> : <Navigate to='/login'/>}/>        
        <Route path="/ViewDocket/:id?" element={isLoggedIn ? <ViewDocket /> : <Navigate to='/login' />} />        
        <Route path="/Todos" element={isLoggedIn? <Todos/> : <Navigate to='/login'/>}/>        
        <Route path="/SystemSetting" element={isLoggedIn? <SystemSetting/> : <Navigate to='/login'/>}/>        
        <Route path="/EditSiteSystem/:id?" element={<EditSiteSystem />} />
        <Route path="/Reports" element={isLoggedIn? <Reports/> : <Navigate to='/login'/>}/>        
        <Route path="/Support" element={isLoggedIn? <Support/> : <Navigate to='/login'/>}/>        
        <Route path="/EditSupport" element={isLoggedIn? <EditSupport/> : <Navigate to='/login'/>}/>        
        <Route path="/UserRegistration" element={isLoggedIn? <UserRegistration/> : <Navigate to='/login'/>}/>        
        <Route path="/Reports" element={isLoggedIn? <Reports/> : <Navigate to='/login'/>}/>        
        <Route path="/WeighbridgeSettings" element={isLoggedIn ? <WeighbridgeSettings /> : <Navigate to='/login' />} />
        <Route path="/Weighbridge" element={isLoggedIn ? <Weighbridge /> : <Navigate to='/login' />} />
        <Route path="/BusinessData" element={isLoggedIn ? <BusinessData /> : <Navigate to='/login' />} />
        <Route path="/OutputState" element={isLoggedIn ? <OutputState /> : <Navigate to='/login' />} />
        <Route path="/Messages" element={isLoggedIn ? <Messages /> : <Navigate to='/login' />} />
        <Route path="/EditMessageQueues/:id?" element={isLoggedIn ? <EditMessageQueues /> : <Navigate to='/login' />} />
        <Route path="/EditBusinessData/:id?" element={isLoggedIn ? <EditBusinessData /> : <Navigate to='/login' />} />
        <Route path="/EditOutputStates/:id?" element={isLoggedIn ? <EditOutputStates /> : <Navigate to='/login' />} /> 
        <Route path="/SimpleReports" element={isLoggedIn? <SimpleReports/> : <Navigate to='/login'/>}/> 
        <Route path="/UserApproval" element={isLoggedIn? <UserApproval/> : <Navigate to='/login'/>}/> 
        <Route path="/Vouchers" element={isLoggedIn ? <Vouchers /> : <Navigate to='/login' />} /> 
        <Route path="/EditVouchers/:id?" element={isLoggedIn ? <EditVouchers /> : <Navigate to='/login' />} />        
        <Route path="/PrintVouchers/:id?" element={isLoggedIn ? <PrintVouchers /> : <Navigate to='/login' />} />        
        <Route path="/Error401" element={<Error401/>}/>
        <Route path="/WatchVideo/:link?" element={<WatchVideo/>}/>
        <Route path="/Kiosk" element={isLoggedIn ? <Kiosk /> : <Navigate to='/login' />} />
        <Route path="/Mobile" element={isLoggedIn ? <Mobile /> : <Navigate to='/login' />} />
        <Route path="*" element={<Error404/>}/>          
      </Routes>
    );
  };

  const path = window.location.href;

  return isLoggedIn && !path.toLowerCase().endsWith("kiosk") && !path.toLowerCase().endsWith("mobile") ? (
    <Router>
          <DrawerContainer>{getroutes()}</DrawerContainer>
    </Router>
     ) : (
      <Router>
      {getroutes()}
    </Router>      
  );
};

export default App;
