import * as React from "react";
import { GetToken } from "../../components/Session";
import configData from "../../config.json";
import {
    Card,
    CardTitle,
    CardBody,
    CardActions,
  } from "@progress/kendo-react-layout";
 

const DashboardCards = (props) => {
 const period = props.period;
 const [weigh,setWeigh] = React.useState(null);
 const [previousWeight,setPreviousWeight] = React.useState(null);
 const [previousFinalWeight,setPreviousFinalWeight] = React.useState(null);
 const [transactions,setTransactions] = React.useState(null);
 const [previousTransactions,setPreviousTransactions] = React.useState(null);
 const [previousFinalTransactions,setPreviousFinalTransactions] = React.useState(null);
 const [sales,setSales] = React.useState(null);
 const [previousSales,setPreviousSales] = React.useState(null);
 const [previousFinalSales,setPreviousFinalSales] = React.useState(null);
 const [average,setAverage] = React.useState(null);
 const [previousAverage,setPreviousAverage] = React.useState(null);
 const [previousFinalAverage,setPreviousFinalAverage] = React.useState(null);
 var thisPeriod = "";
 var lastPeriod = "";
 var heading
  switch(period) {
    case "financial":
      thisPeriod = "This financial year-to-Date";
      lastPeriod = "Previous financial year";
      heading = "Financial"
      break;
    case "yearly":
      thisPeriod = "Year-to-Date";
      lastPeriod = "Previous Year";
      heading = "Yearly"
      break;
    case "Monthly":
      thisPeriod = "Month-to-Date";
      lastPeriod = "Previous month";
      heading = "Monthly"
      break;
    case "weekly":
      thisPeriod = "Week-to-Date";
      lastPeriod = "Previous week";
      heading = "Weekly"
      break;
    default:
      thisPeriod = "Today";
      lastPeriod = "Yesterday";
      heading = "Daily"
  }
 React.useEffect(() => {
  const baseUrl = configData.SERVER_URL + "apiV7/dashboard/getsummaryforperiod?period="+period;
  const init = {
    method: "GET",
    accept: "application/json",
    headers: {"Authorization": "Bearer " + GetToken()},
  };
  fetch(baseUrl, init)
    .then((response) => response.json())
    .then((d) => {
      setWeigh(d.weight);
      setPreviousWeight(d.previousWeight);
      setPreviousFinalWeight(d.previousFinalWeight);
      setTransactions(d.transactions);
      setPreviousTransactions(d.previousTransactions);
      setPreviousFinalTransactions(d.previousFinalTransactions);
      setSales(d.sales);
      setPreviousSales(d.previousSales);
      setPreviousFinalSales(d.previousFinalSales);
      setAverage(d.average);
      setPreviousAverage(d.previousAverage);
      setPreviousFinalAverage(d.previousFinalAverage);
    });
},[]);
  return (
    <>
        <div
            style={{
                display: "flex",
                paddingBottom: 20,
            }}>
                <div className="k-card-list" style={{width:"25%"}}>
                    <Card
                      style={{
                        width: "90%",
                        height: "90%",
                        textAlign: "center",
                        background:"#F2F3F5",
                        padding:"15px"
                      }}
                      type="info"
                    >
                      <CardTitle><h3>{heading} Net Weight</h3></CardTitle>
                      <CardBody>/
                        
                        <h6>{thisPeriod}</h6>
                        <h3 style={{color:"green"}}>{weigh} (t)</h3><br></br>
                          <h6>{lastPeriod} at this point </h6>
                        <h3>{previousWeight} (t)</h3><br></br>
                        <h6>{lastPeriod}</h6>
                        <h3>{previousFinalWeight} (t)</h3><br></br>
                      </CardBody>
                    </Card>
                  </div>
                <div className="k-card-list" style={{width:"25%"}}>
                
                  <Card
                    style={{
                      width: "90%",
                      height: "90%",
                      textAlign: "center",
                      background:"#F2F3F5",
                      padding:"15px"

                    }}
                    type="info"
                  >
                    <CardTitle><h3>No of Transactions</h3></CardTitle>
                    <CardBody>
                       <h6>{thisPeriod}</h6>
                      <h3 style={{color:"green"}}> {transactions}</h3><br></br>
                      <h6>{lastPeriod} at this point </h6>
                      <h3>{previousTransactions}</h3><br></br>
                      <h6>{lastPeriod}  Total</h6>
                      <h3>{previousFinalTransactions}</h3><br></br>
                    </CardBody>
                  </Card>
                </div>
                <div className="k-card-list" style={{width:"25%"}}>
                  <Card
                    style={{
                      width: "90%",
                      height: "90%",
                      textAlign: "center",
                      background:"#F2F3F5",
                      padding:"15px"
                    }}
                    type="info"
                  >
                    <CardTitle><h3>Sales</h3></CardTitle>
                    <CardBody>
                      <h6>{thisPeriod}</h6>
                      <h3 style={{color:"green"}}>{sales}</h3><br></br>
                          <h6>{lastPeriod} at this point</h6>
                      <h3>{previousSales}</h3><br></br>
                      <h6>{lastPeriod}  Total</h6>
                      <h3> {previousFinalSales}</h3><br></br>
                    </CardBody>
                  </Card>
                </div>
                <div className="k-card-list" style={{width:"25%"}}>
                  <Card
                    style={{
                      width: "90%",
                      height: "90%",
                      textAlign: "center",
                      background:"#F2F3F5",
                      padding:"15px"
                      
                      
                    }}
                    type="info"
                  >
                    <CardTitle><h3>Average $/t</h3></CardTitle>
                    <CardBody>
                      
                      <h6>{thisPeriod}</h6>
                      <h3 style={{color:"green"}}>{average}</h3><br></br>
                          <h6>{lastPeriod} at this point </h6>
                      <h3> {previousAverage}</h3><br></br>
                      <h6>{lastPeriod} Total Average Weight </h6>
                      <h3>{previousFinalAverage}</h3><br></br>
                    </CardBody>
                  </Card>
                </div>
              </div>
    </>
  );
};
export default DashboardCards;