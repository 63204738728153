import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import Notifications from '../components/Notifications';
import { GetSession, GetToken } from '../components/Session';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { GetTransactionFromFirstWeigh } from '../WeighBridge/Transaction';
import { NumericTextBox } from "@progress/kendo-react-inputs";

const FirstWeighs = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const [sites, setSites] = React.useState([ ]);
    const [rego, setRego] = React.useState("");
    const [site, setSite] = React.useState();
    const [showDialog, setShowDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("Convert to Transaction");
    const ready = React.useRef(false);
    const navigate = useNavigate();
    const [layout, setLayout] = React.useState([
        { "field": "searchSite", "label": "Site", "isVisible": true },
        { "field": "searchRego", "label": "Rego", "isVisible": true }        
      ]);

    const fwData = React.useRef("");
    const [values, setValues] = React.useState({
        tare1: 0,
        tare2: 0,
        tare3: 0,
        tare4: 0
          
    });

    // navigation    
    const Redirect = (msg = "") => {
        window.location.reload("/firstweighs", { state: { msg: msg } });
    }

    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }
    
    let dr = JSON.parse(localStorage.getItem('FirstWeighDates'));
    if (dr !== null){
        if (dr.start !== null) dr.start = new Date(dr.start);
        if (dr.end !== null) dr.end = new Date(dr.end);
    } else {
        let dt = new Date();
        dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: null};
        localStorage.setItem("FirstWeighDates", JSON.stringify(dr));
    }
    const [dateRange, setDateRange] = React.useState(dr);
    const [lookupParams, setLookupParams] = React.useState(""); 

    if (permissions && !permissions.CanView) navigate("/error401");

    React.useEffect(() => { 
        GetColumnDefs("FirstWeighs", setColumns, setErrorMessage); 
        GetPermissions(["ViewFirstWeigh", "DeleteFirstWeigh", "EditFirstWeigh", "NewFirstWeigh"], setPermissions);
        let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'FirstWeighs'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        };
        fetch(baseUrl, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
          });  
        baseUrl = configData.SERVER_URL + "apiv7/options";
        fetch(baseUrl, init).then(response => response.json()).then(d => {
            setSites(CreateList(d.sites));
            ClearParams();
            ready.current = true;
        });        
    }, [])

    const CreateList = (d) => {
        var x = [ {"id": "0", "name": "All"}];
        return x.concat(d);
    }
    
    const ClearSearch = () => {
        if (ready.current) {
          ClearParams();
          var params = {
            SiteId: 0,
            FromDate: "2000-01-01T00:00:00Z",
            ToDate: "2050-01-01T00:00:00Z",
            Rego: ""
          };
          setLookupParams(encodeURI(JSON.stringify(params)));
        }
      };

    const ClearParams = () => {
        setSite({ "id": "0", "name": "All"});
        setRego("");
        setDateRange({start: null, end: null});
    }

    const rangeChanged = (event) => { 
        let dr = event.target.value;
        setDateRange(dr.value); 
        localStorage.setItem("FirstWeighDates", JSON.stringify(dr));
    }

    const siteChanged = (event) => {setSite(event.target.value);}

    const regoChanged = (event)=> { setRego(event.target.value);}

    const DoSearch = () => {
        var params = {
            FromDate: dr.start === null ? "2000-01-01T00:00:00Z" : dr.start.toISOString(),
            ToDate: dr.end === null ? "2050-01-01T00:00:00Z" : dr.end.toISOString(),
            SiteId: parseInt(site.id),
            Rego: rego
        };
        setLookupParams(encodeURI(JSON.stringify(params)));        
    }

    const FWCommand = (item, ev) => {
        setDialogTitle(`Convert Trnsaction for for ${item.name}`);
        setShowDialog(true);
        fwData.current = GetTransactionFromFirstWeigh(item);
    }

    const DialogNo = () => {
        setShowDialog(false);
    }
    const DialogYes = () => {

        if (values.tare1 === 0 && values.tare2 === 0 && values.tare3 === 0 && values.tare4 === 0 && values.tare5 === 0) {
            alert("You must specify a gross weight");
            return;
        }

        fwData.current.gross1 = values.tare1;
        fwData.current.gross2 = values.tare2;
        fwData.current.gross3 = values.tare3;
        fwData.current.gross4 = values.tare4;
        fwData.current.gross5 = values.tare5;
        fwData.current.loadType = 'Second';
        fwData.current.vehicleConfigurationId = 1;


        let t = { ...fwData.current };
        var url = `${configData.SERVER_URL}clientapi/weighbridge/getupdatedprices`;
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify(t)
        }
        fetch(url, init).then(response => response.json()).then(d => {
            fwData.current = d;
            var url = `${configData.SERVER_URL}clientapi/weighbridge/posttransaction`;
            const init = {
                method: 'POST',
                accept: "application/json",
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + GetToken()
                },
                mode: "cors",
                cache: "no-cache",
                body: JSON.stringify(fwData.current),
            }
            fetch(url, init).then(response => response).then(d => {
                var msg = "Transaction created";
                if (d.status > 299) msg = `Error ${d.status} creating Transaction: ${d.detail}`;
                Redirect(msg);
            });

        }).catch((error) => {
            console.log("Recalculate price fetch error: " + error.message);
        });

        setShowDialog(false);       
    }

    const Updatetare1 = (e) => { let x = { ...values }; x.tare1 = e.value; setValues(x); }
    const Updatetare2 = (e) => { let x = { ...values }; x.tare2 = e.value; setValues(x); }
    const Updatetare3 = (e) => { let x = { ...values }; x.tare3 = e.value; setValues(x); }
    const Updatetare4 = (e) => { let x = { ...values }; x.tare4 = e.value; setValues(x); }
    const Updatetare5 = (e) => { let x = { ...values }; x.tare5 = e.value; setValues(x); }    

    return (
        <div>
        <Notifications errorMessage={errorMessage}/>
        <div>
           <div className="row" style={{ padding: "25px" }}>
                {GetField("searchSite")["isVisible"] && <div className="col-md-3">
                    <label className="control-label">{GetField("searchSite")["label"]}</label>
                    <DropDownList id="Site" data={sites} value={site} textField="name" dataItemKey="id" onChange={siteChanged} />
                </div>}
                {GetField("searchRego")["isVisible"] && <div className="col-md-3">
                  <label className="control-label">{GetField("searchRego")["label"]}</label>
                  <TextBox id="Rego" type="text" value={rego} onChange={regoChanged} />
                </div>}
                <div className="col-md-3">
                    <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged}  />
                </div>
            </div>
            <div className='col-md-3' style={{ padding: "20px" }}>
                    <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={DoSearch}>Search</Button>
                    <Button themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={ClearSearch}>Clear</Button>
            </div>
        </div> 
            {columns && permissions && <DataGrid dataSource='apiv7/firstweighs' columns={columns} permissions={permissions} params={lookupParams} deleteAll keyField="firstWeighId"  customCommand={FWCommand}
                customCommandName="FWCommand" />}  {showDialog && <Dialog title={dialogTitle} onClose={DialogNo}>
                <div className="row">
                    <div className='col-md-3'>
                        <label>Gross 1</label>
                        <NumericTextBox id={"tare1"} name={"tare1"} defaultValue={0} min={0} max={200} onChange={Updatetare1} />
                    </div>
                    <div className='col-md-3'>
                        <label>Gross 2</label>
                        <NumericTextBox id={"tare2"} name={"tare2"} defaultValue={0}  min={0} max={200}  onChange={Updatetare2} />
                    </div>
                    <div className='col-md-3'>
                        <label>Gross 3</label>
                        <NumericTextBox id={"tare3"} name={"tare3"} defaultValue={0}  min={0} max={200} onChange={Updatetare3} />
                    </div>
                    <div className='col-md-3'>
                        <label>Gross 4</label>
                        <NumericTextBox id={"tare4"} name={"tare4"} defaultValue={0} min={0} max={200} onChange={Updatetare4} />
                    </div>
                    <div className='col-md-3'>
                        <label>Gross 4</label>
                        <NumericTextBox id={"tare5"} name={"tare5"} defaultValue={0}  min={0} max={200} onChange={Updatetare5} />
                    </div>

                </div>
                
                    <DialogActionsBar>
                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={DialogNo}>Cancel</button>
                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={DialogYes}>Convert Now</button>
                    </DialogActionsBar>
                </Dialog>}
        </div>       
    );

}

export default FirstWeighs;