import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

const Confirm = (props) => {
    return (<div>
        <Dialog title={props.Title}>
          <p style={{margin: "25px", textAlign: "center"}}>{props.Message}</p>
          <DialogActionsBar>
            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={props.NoClicked}>No</button>
            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={props.YesClicked}>Yes</button>
          </DialogActionsBar>
        </Dialog>
      </div>);
}
export default Confirm