import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken, GetUserId } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormTextArea, FormDateTimePicker, FormNumericTextBox, FormDropDownList } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

const EditFirstWeighs = () => {
    const { id } = useParams(); // editfirstweighs/id url is used to edit rego on a first weigh
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [directions, setDirections] = React.useState([]);
    const [chargeRates, setChargeRates] = React.useState([]);
    const [sites, setSites] = React.useState([]);
    const [drivers, setDrivers] = React.useState([]);
    const [trucks, setTrucks] = React.useState([]);
    const [owners, setOwners] = React.useState(["PRIVATE", "SITE"]);
    const [jobs, setJobs] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [productCategories, setProductCategories] = React.useState([]);
    const [destinations, setDestinations] = React.useState([]);
    const [sources, setSources] = React.useState([]);
    const [snapshot1, setSnapshot1] = React.useState("");
    const [snapshot2, setSnapshot2] = React.useState("");
    const [snapshot3, setSnapshot3] = React.useState("");
    const [snapshot4, setSnapshot4] = React.useState("");
    const [vehicleConfigurations, setVehicleConfigurations] = React.useState([]);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "name", "label": "Rego", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "comment", "label": "Comment", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "registration2", "label": "Registration 2", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "registration3", "label": "Registration 3", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "direction", "label": "Direction", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "chargeRate", "label": "Charge Rate", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "vehicleOwner", "label": "Vehicle Owner", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "tareInDate", "label": "Tare In Date", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "tare1", "label": "Gross 1", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "tare2", "label": "Gross 2", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "tare3", "label": "Gross 3", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "tare4", "label": "Gross 4", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "tare5", "label": "Gross 5", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "maxLoad", "label": "Max GVM (t)", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "snapshot1", "label": "Snapshot 1", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "snapshot2", "label": "Snapshot 2", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "snapshot3", "label": "Snapshot 3", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "snapshot4", "label": "Snapshot 4", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "haulage", "label": "Haulage", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "deliveryAddress", "label": "Delivery Address", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "orderNumber", "label": "Order Number", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "siteId", "label": "Site", "defaultValue": 1, "isVisible": true, "type": "text" },
        { "field": "jobId", "label": "Job", "defaultValue": 1, "isVisible": true, "type": "text" },
        { "field": "customerId", "label": "Customer", "defaultValue": 1, "isVisible": true, "type": "text" },
        { "field": "supplierId", "label": "Supplier", "defaultValue": 1, "isVisible": true, "type": "text" },
        { "field": "productId", "label": "Product", "defaultValue": 1, "isVisible": true, "type": "text" },
        { "field": "productCategoryId", "label": "Product Category", "defaultValue": 1, "isVisible": true, "type": "text" },
        { "field": "destinationId", "label": "Destination", "defaultValue": 1, "isVisible": true, "type": "text" },
        { "field": "sourceId", "label": "Source", "defaultValue": 1, "isVisible": true, "type": "text" },
        { "field": "driverId", "label": "Driver", "defaultValue": 1, "isVisible": true, "type": "text" },
        { "field": "transactionDetails", "label": "Transaction", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "tareDetails", "label": "Tare Details", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "otherDetails", "label": "Other Details", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "snapshots", "label": "Camera Snapshots", "defaultValue": "", "isVisible": true, "type": "text" }
      ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        name: "",
        tareInDate: new Date(),
        registration2: "",
        registration3: "",
        direction: "",
        chargeRate: "",
        vehicleOwner: "",
        orderNumber: "",
        tare1: 0,
        tare2: 0,
        tare3: 0,
        tare4: 0,
        tare5: 0,
        siteId: "",
        jobId: { id: "1", name: "NA" },
        customerId: { id: "1", name: "NA" },
        productId: { id: "1", name: "NA" },
        productCategoryId: { id: "1", name: "NA" },
        destinationId: { id: "1", name: "NA" },
        sourceId: { id: "1", name: "NA" },
        vehicleConfigurationId: { id: "1", name: "NA" },
        vehicleId: { id: "1", name: "NA" },
        truckId: "",
        driverId: ""
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/addextracharge
    React.useEffect(() => {
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditFirstWeighs'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => {
            setLayout(d);
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {
            setSites(opt.sites);
            setDrivers(opt.drivers);
            setDirections(opt.directions);
            setChargeRates(opt.chargeRates);
            setJobs(opt.jobs);
            setCustomers(opt.customers);
            setProducts(opt.products);
            setProductCategories(opt.productCategories);
            setDestinations(opt.destinations);
            setSources(opt.sources);
            setVehicleConfigurations(opt.vehicleConfigurations);
            setTrucks(opt.trucks);
            if (id !== undefined) {
                url = `${configData.SERVER_URL}apiv7/firstweighs/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var dest = opt.destinations.find(obj => { return obj.id === String(d.destinationId) });
                    var cust = opt.customers.find(obj => { return obj.id === String(d.customerId) });
                    var site = opt.sites.find(obj => { return obj.id === String(d.siteId) });
                    var job = opt.jobs.find(obj => { return obj.id === String(d.jobId) });
                    var source = opt.sources.find(obj => { return obj.id === String(d.sourceId) });
                    var product = opt.products.find(obj => { return obj.id === String(d.productId) });
                    var productCategory = opt.productCategories.find(obj => { return obj.id === String(d.productCategoryId) });
                    setSnapshot1(d.snapshot1 === null ? "" : d.snapshot1);
                    setSnapshot2(d.snapshot2 === null ? "" : d.snapshot2);
                    setSnapshot3(d.snapshot3 === null ? "" : d.snapshot3);
                    setSnapshot4(d.snapshot4 === null ? "" : d.snapshot4);
                    let s = d.tareInDate + "Z";
                    let dt = new Date(s).toLocaleString("en-AU");
                    setValues({
                        name: d.name,
                        comment: d.comment,
                        registration2: d.registration2,
                        registration3: d.registration3,
                        direction: d.direction,
                        chargeRate: d.chargeRate,
                        vehicleOwner: d.vehicleOwner,
                        tareInDate: dt,
                        tare1: d.tare1,
                        tare2: d.tare2,
                        tare3: d.tare3,
                        tare4: d.tare4,
                        tare5: d.tare5,
                        deliveryAddress: d.deliveryAddress,
                        haulage: d.haulage,
                        orderNumber: d.orderNumber,
                        siteId: site,
                        jobId: job,
                        customerId: cust,
                        productId: product,
                        productCategoryId: productCategory,
                        destinationId: dest,
                        sourceId: source,
                        maxLoad: d.maxLoad
                    })
                    setFormkey(formkey + 1);
                });
            } else {
                let v = { ...values };
                v.siteId = opt.sites[0];
                setValues(v);
                setFormkey(formkey + 1);
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    const GetDropdownData = (f, name) => {
        let res = 0;
        if (f === undefined || f.id === undefined)
            res = GetDefaultData('', name);
        else
            res = f.id;
        return parseInt(res);
    }

    const Validate = (dataItem) => {
        let st = "";
        if (dataItem.tare1 === undefined || dataItem.tare1 === 0) st += "Gross 1\r\n";
        if (st !== "") {
            setSelectedTab(1);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        return true;
    }


    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {       
        if (!Validate(dataItem)) return;  
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/FirstWeighs${pth}`;
        const init = {
            method: id === undefined ? 'POST' : 'PUT',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "firstWeighId": parseInt(id === undefined ? 0 : id),
                "tareInDate": GetDefaultData(dataItem.tareInDate, "tareInDate"),
                "name": GetDefaultData(dataItem.name, "name"),
                "registration2": GetDefaultData(dataItem.registration2, "registration2"),
                "registration3": GetDefaultData(dataItem.registration3, "registration3"),
                "direction": GetDefaultData(dataItem.direction, "direction"),
                "chargeRate": GetDefaultData(dataItem.chargeRate, "chargeRate"),
                "vehicleOwner": GetDefaultData(dataItem.vehicleOwner, "vehicleOwner"),
                "orderNumber": GetDefaultData(dataItem.orderNumber, "orderNumber"),
                "tare1": GetDefaultData(dataItem.tare1, "tare1"),
                "tare2": GetDefaultData(dataItem.tare2, "tare2"),
                "tare3": GetDefaultData(dataItem.tare3, "tare3"),
                "tare4": GetDefaultData(dataItem.tare4, "tare4"),
                "tare5": GetDefaultData(dataItem.tare5, "tare5"),
                "tare": 0,
                "maxLoad": GetDefaultData(dataItem.maxLoad, "maxLoad"),
                "siteId": GetDropdownData(dataItem.siteId, "siteId"),
                "jobId": GetDropdownData(dataItem.jobId, "jobId"),
                "customerId": GetDropdownData(dataItem.customerId, "customerId"),
                "productId": GetDropdownData(dataItem.productId, "productId"),
                "productCategoryId": GetDropdownData(dataItem.productCategoryId, "productCategoryId"),
                "destinationId": GetDropdownData(dataItem.destinationId, "destinationId"),
                "sourceId": GetDropdownData(dataItem.sourceId, "sourceId"),
                "haulage": GetDefaultData(dataItem.haulage, "haulage"),
                "comment": GetDefaultData(dataItem.comment, "comment"),
                "payments": "ACCOUNT",
                "loadType": "FIRST",
                "isShowAllJobsClicked": false,
                "userId": parseInt(GetUserId()),
                "supplierId": 1,
                "driverId": 1,
                "maxLoadWarnings": 0,
                "groupAxles": 0,
                "penaltyCharge": 0,
                "isLoaderUpdated": false,
                "isActive": true
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = id === undefined ? "Created" : "Updated";
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/firstweighs", {state: {msg: msg}});
    }

    const Updatename = (e) => { let x = values; x.name = e.value; setValues(x); }
    const Updateregistration2 = (e) => { let x = values; x.registration2 = e.value; setValues(x); }
    const Updateregistration3 = (e) => { let x = values; x.registration3 = e.value; setValues(x); }
    const Updatedirection = (e) => { let x = values; x.direction = e.value; setValues(x); }
    const UpdatechargeRate = (e) => { let x = values; x.chargeRate = e.value; setValues(x); }
    const UpdatevehicleOwner = (e) => { let x = values; x.vehicleOwner = e.value; setValues(x); }
    const UpdateorderNumber = (e) => { let x = values; x.orderNumber = e.value; setValues(x); }
    const Updatetare1 = (e) => { let x = values; x.tare1 = e.value; setValues(x); }
    const Updatetare2 = (e) => { let x = values; x.tare2 = e.value; setValues(x); }
    const Updatetare3 = (e) => { let x = values; x.tare3 = e.value; setValues(x); }
    const Updatetare4 = (e) => { let x = values; x.tare4 = e.value; setValues(x); }
    const Updatetare5 = (e) => { let x = values; x.tare5 = e.value; setValues(x); }
    const UpdatemaxLoad = (e) => { let x = values; x.maxLoad = e.value; setValues(x); }
    const UpdatesiteId = (e) => { let x = values; x.siteId = e.value; setValues(x); }
    const UpdatejobId = (e) => { let x = values; x.jobId = e.value; setValues(x); }
    const UpdatecustomerId = (e) => { let x = values; x.customerId = e.value; setValues(x); }
    const UpdateproductId = (e) => { let x = values; x.productId = e.value; setValues(x); }
    const UpdateproductCategoryId = (e) => { let x = values; x.productCategoryId = e.value; setValues(x); }
    const UpdatedestinationId = (e) => { let x = values; x.destinationId = e.value; setValues(x); }
    const UpdatesourceId = (e) => { let x = values; x.sourceId = e.value; setValues(x); }
    const handleTabSelect = (e) => { setSelectedTab(e.selected); };
    const Updatehaulage = (e) => { let x = values; x.haulage = e.value; setValues(x); }
    const Updatecomment = (e) => { let x = values; x.comment = e.value; setValues(x); }


    // form validators
    const textValidator = (value) => !value ? "Please enter a value" : "";
    const optionValidator = (value) => value ? "" : "Please select an option";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                        <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("transactionDetails")["isVisible"] && <TabStripTab title={GetField("transactionDetails")["label"]}>
                        <div className='row'>
                            {GetField("tareInDate")["isVisible"] && GetField("tareInDate")["isVisible"] && <div className="col-md-3">
                                <Field id={"tareInDate"} name={"tareInDate"} label={GetField("tareInDate")["label"]} component={FormInput} validator={textValidator} disabled/>
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("name")["isVisible"] && GetField("name")["isVisible"] && <div className="col-md-3">
                                        <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator} onChange={Updatename} />
                            </div>}
                            {GetField("registration2")["isVisible"] && GetField("registration2")["isVisible"] && <div className="col-md-3">
                                        <Field id={"registration2"} name={"registration2"} label={GetField("registration2")["label"]} component={FormInput} onChange={Updateregistration2} />
                            </div>}
                            {GetField("registration3")["isVisible"] && GetField("registration3")["isVisible"] && <div className="col-md-3">
                                        <Field id={"registration3"} name={"registration3"} label={GetField("registration3")["label"]} component={FormInput} onChange={Updateregistration3} />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("direction")["isVisible"] && <div className='col-md-3'>
                                <Field id={"direction"} name={"direction"} label={GetField("direction")["label"]} component={FormDropDownList} data={directions} validator={optionValidator} onChange={Updatedirection} />
                            </div>}
                            {GetField("chargeRate")["isVisible"] && <div className='col-md-3'>
                                <Field id={"chargeRate"} name={"chargeRate"} label={GetField("chargeRate")["label"]} component={FormDropDownList} data={chargeRates} validator={optionValidator} onChange={UpdatechargeRate} />
                            </div>}
                            {GetField("vehicleOwner")["isVisible"] && <div className='col-md-3'>
                                <Field id={"vehicleOwner"} name={"vehicleOwner"} label={GetField("vehicleOwner")["label"]} component={FormDropDownList} data={owners} validator={optionValidator} onChange={UpdatevehicleOwner} />
                            </div>}
                        </div>                        
                        <div className='row' >
                            {GetField("orderNumber")["isVisible"] && <div className='col-md-3'>
                                <Field id={"orderNumber"} name={"orderNumber"} label={GetField("orderNumber")["label"]} component={FormInput} onChange={UpdateorderNumber} />
                            </div>}
                        </div>     
                        </TabStripTab>}
                        {GetField("tareDetails")["isVisible"] && <TabStripTab title={GetField("tareDetails")["label"]}>                        
                                <div className="row">
                                    {GetField("tare1")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"tare1"} name={"tare1"} label={GetField("tare1")["label"]} component={FormNumericTextBox} min={0} max={100000} validator={textValidator} onChange={Updatetare1} />
                                    </div>}
                                </div>
                                <div className="row">
                                    {GetField("tare2")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"tare2"} name={"tare2"} label={GetField("tare2")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatetare2} />
                                    </div>}
                                </div>
                                <div className="row">
                                    {GetField("tare3")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"tare3"} name={"tare3"} label={GetField("tare3")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatetare3} />
                                    </div>}
                                </div>
                                <div className="row">
                                    {GetField("tare4")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"tare4"} name={"tare4"} label={GetField("tare4")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatetare4} />
                                    </div>}
                                </div>
                                <div className="row">
                                    {GetField("tare5")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"tare5"} name={"tare5"} label={GetField("tare5")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatetare5} />
                                    </div>}
                                </div>
                                <div className="row">
                                    {GetField("maxLoad")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"maxLoad"} name={"maxLoad"} label={GetField("maxLoad")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={UpdatemaxLoad} />
                                    </div>}
                                </div>
                            </TabStripTab>}
                            {GetField("otherDetails")["isVisible"] && <TabStripTab title={GetField("otherDetails")["label"]}>
                                <div className='row' >
                                    {GetField("siteId")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"siteId"} name={"siteId"} label={GetField("siteId")["label"]} component={FormDropDownList} data={sites} textField="name" validator={optionValidator} onChange={UpdatesiteId} />
                                    </div>}
                                    {GetField("jobId")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"jobId"} name={"jobId"} label={GetField("jobId")["label"]} component={FormDropDownList} data={jobs} textField="name" validator={optionValidator} onChange={UpdatejobId } />
                                    </div>}
                                    {GetField("customerId")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"customerId"} name={"customerId"} label={GetField("customerId")["label"]} component={FormDropDownList} data={customers} textField="name" validator={optionValidator} onChange={UpdatecustomerId} />
                                    </div>}
                                    {GetField("productId")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"productId"} name={"productId"} label={GetField("productId")["label"]} component={FormDropDownList} data={products} textField="name" validator={optionValidator} onChange={UpdateproductId } />
                                    </div>}
                                </div>
                                <div className='row' >
                                    {GetField("productCategoryId")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"productCategoryId"} name={"productCategoryId"} label={GetField("productCategoryId")["label"]} component={FormDropDownList} data={productCategories} textField="name" validator={optionValidator} onChange={UpdateproductCategoryId} />
                                    </div>}
                                    {GetField("destinationId")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"destinationId"} name={"destinationId"} label={GetField("destinationId")["label"]} component={FormDropDownList} data={destinations} textField="name" validator={optionValidator} onChange={UpdatedestinationId} />
                                    </div>}
                                    {GetField("sourceId")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"sourceId"} name={"sourceId"} label={GetField("sourceId")["label"]} component={FormDropDownList} data={sources} textField="name" validator={optionValidator} onChange={UpdatesourceId} />
                                    </div>}
                                    {GetField("haulage")["isVisible"] && <div className='col-md-3'>
                                        <Field id={"haulage"} name={"haulage"} label={GetField("haulage")["label"]} component={FormNumericTextBox} min={0} max={100000} onChange={Updatehaulage} />
                                    </div>}
                                </div>
                                <div className='row' >
                                    {GetField("comment")["isVisible"] && <div className="col-md-6">
                                        <Field id={"comment"} name={"comment"} label={GetField("comment")["label"]} component={FormTextArea} onChange={Updatecomment} />
                                    </div>}
                                </div>
                            </TabStripTab>}
                            {GetField("snapshots")["isVisible"] && <TabStripTab title={GetField("snapshots")["label"]}>
                                <div className='row' >
                                    <div className='col-md-3'>
                                        <img src={snapshot1} />
                                    </div>
                                    <div className='col-md-3'>
                                        <img src={snapshot2} />
                                    </div>
                                    <div className='col-md-3'>
                                        <img src={snapshot3} />
                                    </div>
                                    <div className='col-md-3'>
                                        <img src={snapshot4} />
                                    </div>
                                </div>
                            </TabStripTab>}
                        </TabStrip>
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-2'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditFirstWeighs