import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement, FieldArray } from "@progress/kendo-react-form";
import { FormDropDownList, FormNumericTextBox, FormTextArea, FormInput, FormSwitch, FormDatePicker } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';
import { DatePickerPropsContext } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Label } from '@progress/kendo-react-labels';
import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextBox } from '@progress/kendo-react-inputs';
import $  from "jquery"

export const FormGridEditContext = React.createContext({});

const DeleteCell = (props) => {
    const {dataItem} = props;
    return (
        <td>
          <Button icon="delete" onClick={() => props.remove(dataItem)} title="Delete" />
        </td>
      )
}

const FormGrid = fieldArrayRenderProps => {
    const [filter, setFilter] = React.useState([]);
    const { name } = fieldArrayRenderProps;
    const vpp = fieldArrayRenderProps.productList;
    const vehicleId = fieldArrayRenderProps.vehicleId;
    const UpdateGrid = fieldArrayRenderProps.update;
    const [editIndex, setEditIndex] = React.useState();

    const CommandCell = (props) => {
        return (
        <DeleteCell {...props} remove={Remove} />
        )
    }
    
    const datePickerProps = React.useCallback( // fix date format for filter and edit in grid
        (datePickerProps) => ({...datePickerProps, format: 'dd/MM/yy',  }), []
    );

    const [editID, setEditID] = React.useState(null);
    const rowClick = (event) => { setEditID(event.dataItem.Id);  };

    const itemChange = (event) => {
        const inEditID = event.dataItem.Id;
        const field = event.field || "";
        const newData = vpp.map((item) =>
        item.Id === inEditID
            ? {
                ...item,
                [field]: event.value,
            }
            : item
        );
        fieldArrayRenderProps.updated(newData);
        UpdatePrice(event.dataItem, field, event.value);
    };

    const UpdatePrice = (item, field, value) => {
        var url = `${configData.SERVER_URL}apiv7/vehicleproductprices`;
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "vehicleId": vehicleId,
                "productId": item.Id,
                "inLocalDiscount" : field === "Local" ? value : item.Local,
                "inVisitStandard": field === "Visit" ? value: item.Visit
            })
        }
        fetch(url, init).then(response => response).then(d => { 
        });        
    }

    // Save the changes
    const onSave = React.useCallback(() => {
      console.log(fieldArrayRenderProps);
      setEditIndex(undefined);
    }, [fieldArrayRenderProps]);
    
    const Remove = (item) => {
        var url = `${configData.SERVER_URL}apiv7/vehicleproductprices/${item.vppId}`;
        const init = {
            method: 'DELETE',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache"
        }
        fetch(url, init).then(response => response).then(d => { 
           UpdateGrid();
        }); 
    }

    return <FormGridEditContext.Provider value={{ onSave, editIndex, parentField: name }}>
        <DatePickerPropsContext.Provider value={datePickerProps}>
            <Grid 
                pageable={{
                buttonCount: 4,
                pageSizes: [10, 20, 50, 100, 200, "All"],
                }}
                reorderable={true}
                resizable={true}
                data={filterBy(vpp, filter).map((item) => ({
                    ...item,
                    inEdit: item.Id === editID,
                    }))}
                editField="inEdit"
                onRowClick={rowClick}
                onItemChange={itemChange}
            >
                <Column field="Name" title="Product" editable={false}  />
                <Column field="Local" title="Local (Discount) In $" format="{0:c}" editor="numeric" filter="numeric" />
                <Column field="Visit" title="Visit (Standard) In $" format="{0:n2}" editor="numeric" filter="numeric" />
                <Column field="Action" title="Delete" cell={CommandCell} editable={false}  />
            </Grid>
        </DatePickerPropsContext.Provider>          
    </FormGridEditContext.Provider>;
};

const EditVehicles = () => {
    const { id } = useParams(); // vehicles/id url is used to edit existing vehicle, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [products, setProducts] = React.useState([ ]);
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [permissions, setPermissions] = React.useState(null);
    const [vpp, setVpp] = React.useState([]);
    const [product, setProduct] = React.useState([]);

    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        {"field": "name", "label": "Name", "defaultValue": "", "isVisible": true, "type": "text"},
        {"field": "code", "label": "Code", "defaultValue": "", "isVisible": true, "type": "text"},
        {"field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean"},
        {"field": "netWeight", "label": "Class (t)", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        {"field": "description", "label": "Comment", "defaultValue": "", "isVisible": true, "type": "text"},
        {"field": "productId", "label": "Product", "defaultValue": "", "isVisible": true, "type": "list"},
        {"field": "linkedProducts", "label": "Linked Products", "defaultValue": "", "isVisible": true, "type": "grid"},
        {"field": "vehicleDetails", "label": "Vehicle", "defaultValue": 0, "isVisible": true, "type": "heading"},
        {"field": "pricingDetails", "label": "Pricing details", "defaultValue": 0, "isVisible": true, "type": "heading"},
        {"field": "inLocalDiscount", "label": "Local (Discount) In $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        {"field": "inLocalDiscountGST", "label": "Local In GST", "defaultValue": "false", "isVisible": true, "type": "boolean"},
        {"field": "inLocalDiscount_S", "label": "Scheduled Local (Discount) in $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        {"field": "schedulePriceDate", "label": "Schedule Price Date", "defaultValue": "", "isVisible": true, "type": "date"},
        {"field": "inVisitStandard", "label": "Visit (Standard) In $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        {"field": "inVisitStandardGST", "label": "Visit In GST", "defaultValue": "false", "isVisible": true, "type": "boolean"},
        {"field": "inVisitStandard_S", "label": "Schedule Visit (Standard) In $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "addProduct", "label": "Product", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "addLocal", "label": "Local (Discount) In $", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "addVisit", "label": "Visit (Standard) In $", "defaultValue": 0, "isVisible": true, "type": "numeric" },        
    ]);
    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState([]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        name : GetField("name")["defaultValue"],
        code : GetField("code")["defaultValue"],
        netWeight : GetField("netWeight")["defaultValue"],
        description : GetField("description")["defaultValue"],
        productId : GetField("productId")["defaultValue"],
        pricingDetails : GetField("pricingDetails")["defaultValue"],
        inLocalDiscount : GetField("inLocalDiscount")["defaultValue"],
        inLocalDiscountGST : GetField("inLocalDiscountGST")["defaultValue"] === "true",
        inLocalDiscount_S : GetField("inLocalDiscount_S")["defaultValue"],
        schedulePriceDate : new Date(),
        inVisitStandard : GetField("inVisitStandard")["defaultValue"],
        inVisitStandardGST : GetField("inVisitStandardGST")["defaultValue"] === "true",
        inVisitStandard_S : GetField("inVisitStandard_S")["defaultValue"],
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true",
        addProduct: [],
        addLocal: GetField("addLocal")["defaultValue"],
        addVisit: GetField("addVisit")["defaultValue"],
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vehicles
    React.useEffect(() => {
        GetPermissions(["ViewVehicleType", "NewVehicleType", "EditVehicleType"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'editVehicles'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setProducts(opt.products);
            if (id !== undefined){            
                url = configData.SERVER_URL + "apiv7/vehicleproductprices?$filter=vehicleId eq " + id;
                fetch(url, init).then(response => response.json()).then(vpp => {           
                    let pl = [];
                    for (let i = 0; i < opt.products.length; i++){
                        let p = vpp.find(obj => { return String(obj.productId) === opt.products[i].id});
                        let sel = p !== undefined ? true: false;
                        let local = p !== undefined ? p.inLocalDiscount : 0;
                        let visit = p !== undefined ? p.inVisitStandard: 0;
                        if (p !== undefined){
                            pl.push({ vppId: p.vehicleProductPriceId, Id: opt.products[i].id, Name: opt.products[i].name, Selected: sel, InLocalDiscount: local, InVisitStandard: visit });
                        }
                    }
                    setData(pl);
                    if (id !== undefined){
                    url = `${configData.SERVER_URL}apiv7/vehicles/${id}`;
                    fetch(url, init).then(response => response.json()).then(d => {
                        var p = opt.products.find(obj => { return obj.id === String(d.productId) });
                        var dt = new Date();
                        if (dt.getMonth() === 0 && dt.getDate() === 1) dt = new Date(dt.getFullYear() - 1, 0, 1, 0, 0, 0, 0);
                        var spd = d.schedulePriceDate === null ? new Date(dt.getFullYear(),0,1,0,0,0,0) : new Date(d.schedulePriceDate);
                        setValues({
                            name: d.name === null ? "" : d.name, 
                            code: d.code === null ? "" : d.code, 
                            netWeight: d.netWeight === null ? "" : d.netWeight, 
                            description: d.description === null ? "" : d.description, 
                            productId: d.productId === null ? "" : p, 
                            pricingDetails: d.pricingDetails === null ? "" : d.pricingDetails, 
                            inLocalDiscount: d.inLocalDiscount === null ? "" : d.inLocalDiscount, 
                            inLocalDiscountGST: d.inLocalDiscountGST === null ? "" : d.inLocalDiscountGST, 
                            inLocalDiscount_S: d.inLocalDiscount_S === null ? "" : d.inLocalDiscount_S, 
                            schedulePriceDate: spd, 
                            inVisitStandard: d.inVisitStandard === null ? "" : d.inVisitStandard, 
                            inVisitStandardGST: d.inVisitStandardGST === null ? "" : d.inVisitStandardGST, 
                            inVisitStandard_S: d.inVisitStandard_S === null ? "" : d.inVisitStandard_S, 
                            active: d.isActive
                        });
                        UpdateProductPrices(opt.products);
                        setFormkey(formkey + 1);
                        })
                    }
                });
            }
        });
    }, []);

    const UpdateProductPrices = (products) => {
        let url = configData.SERVER_URL + "apiv7/vehicleproductprices?$filter=vehicleId eq " + id;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: {"Authorization": "Bearer " + GetToken()}
          }
          fetch(url, init).then(response => response.json()).then(pp => {           
            ConfigureProductPrices(products, pp);
        });
    }

    const ConfigureProductPrices = (products, pp) => {
        let pl = [];
        if (pp !== null){
            for (let i = 0; i < pp.length; i++){
                pl.push({ vppId: pp[i].vehicleProductPriceId, Id: pp[i].productId, Name: pp[i].product.name, Local: pp[i].inLocalDiscount, Visit: pp[i].inVisitStandard});
            }
        }
        setVpp(pl);
    }

    const vppUpdated = (newData) => {
        setVpp(newData);
    };

    const productChanged = (e) => { setProduct(e.value); }

    const addProduct = (e) => {
        e.preventDefault();       
        if (product === undefined || product.length === 0) {
            alert("Select a product");
            return;
        }
        let loc = parseFloat($("#addLocal").val().replace("$", ""));
        if (isNaN(loc)) loc = 0;
        let vis = parseFloat($("#addVisit").val());
        if ((isNaN(vis))) vis = 0;
        var url = `${configData.SERVER_URL}apiv7/vehicleproductprices`;
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "vehicleId": id,
                "productId": product.id,
                "inLocalDiscount" : loc,
                "inVisitStandard": vis
            })
        }
        fetch(url, init).then(response => response).then(d => { 
            UpdatePrices();
        });
    }

    function UpdatePrices(){  
        UpdateVehicleProductPrices(products);
    }

    const UpdateVehicleProductPrices = (products) => {
        let url = configData.SERVER_URL + "apiv7/vehicleproductprices?$filter=vehicleId eq " + id;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: {"Authorization": "Bearer " + GetToken()}
          }
          fetch(url, init).then(response => response.json()).then(pp => {           
            ConfigureProductPrices(products, pp);
        });
    }

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (name === "schedulePriceDate" && f === "") f = null;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var n = new Date();
        n = new Date(n.getFullYear(), n.getMonth(), n.getDate());
        var dt = dataItem.schedulePriceDate;
        if (dataItem.schedulePriceDate < n) dt = null;
        var url = `${configData.SERVER_URL}apiv7/vehicles${pth}`;
        var vpps = [];
        for (let i = 0; i < data.length; i++){
            if (data[i].Selected){
                vpps.push({vehicleId: id, productId: data[i].Id, inLocalDiscount: data[i].InLocalDiscount, inVisitStandard: data[i].InVisitStandard});
            }
        }
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "vehicleId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.name ,"name"),
            "code": GetDefaultData(dataItem.code ,"code"),
            "netWeight": GetDefaultData(dataItem.netWeight ,"netWeight"),
            "description": GetDefaultData(dataItem.description ,"description"),
            "productId": GetDefaultData(parseInt(dataItem.productId.id),"productId"),
            "inLocalDiscount": GetDefaultData(dataItem.inLocalDiscount ,"inLocalDiscount"),
            "inLocalDiscountGST": GetDefaultData(dataItem.inLocalDiscountGST ,"inLocalDiscountGST"),
            "inLocalDiscount_S": GetDefaultData(dataItem.inLocalDiscount_S ,"inLocalDiscount_S"),
            "schedulePriceDate": GetDefaultData(dt ,"schedulePriceDate"),
            "inVisitStandard": GetDefaultData(dataItem.inVisitStandard ,"inVisitStandard"),
            "inVisitStandardGST": GetDefaultData(dataItem.inVisitStandardGST ,"inVisitStandardGST"),
            "inVisitStandard_S": GetDefaultData(dataItem.inVisitStandard_S ,"inVisitStandard_S"),
            "VehicleProductPrices": vpps,
            "isActive": GetDefaultData(dataItem.active, "active")
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = id === undefined ? "New vehicle created" : "Vehicle updated";
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/vehicles", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    const [editID, setEditID] = React.useState(null);
    const rowClick = (event) => {
        setEditID(event.dataItem.Id);
    };
    const itemChange = (event) => {
        const inEditID = event.dataItem.Id;
        const field = event.field || "";
        const newData = data.map((item) =>
        item.Id === inEditID
            ? {
                ...item,
                [field]: event.value,
            }
            : item
        );
        setData(newData);
    };

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) =>  value ? "" : "Please select an option";

    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("vehicleDetails")["isVisible"] && <TabStripTab title={GetField("vehicleDetails")["label"]}>
                        <div className="row">
                           {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("code")["isVisible"] && <div className="col-md-3">
                                <Field id={"code"} name={"code"} label={GetField("code")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        <div className='row' >
                            {GetField("netWeight")["isVisible"] && <div className='col-md-3'>
                                <Field id={"netWeight"} name={"netWeight"} label={GetField("netWeight")["label"]} component={FormNumericTextBox} min={0} max={100000}  />
                            </div>}
                            {GetField("description")["isVisible"] && <div className='col-md-3'>
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormTextArea}  />
                            </div>}
                            {GetField("productId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"productId"} name={"productId"} label={GetField("productId")["label"]} component={FormDropDownList} data={products} textField="name"  validator={optionValidator}/>
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("pricingDetails")["isVisible"] && <TabStripTab title={GetField("pricingDetails")["label"]}>
                        <div className='row' >
                            {GetField("inLocalDiscount")["isVisible"] && <div className="col-md-3">
                                <Field id={"inLocalDiscount"} name={"inLocalDiscount"} label={GetField("inLocalDiscount")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                            {GetField("inLocalDiscountGST")["isVisible"] && <div className='col-md-3'>
                                <Field id={"inLocalDiscountGST"} name={"inLocalDiscountGST"} label={GetField("inLocalDiscountGST")["label"]} component={FormSwitch} />
                            </div>}                            
                            {GetField("inLocalDiscount_S")["isVisible"] && <div className="col-md-3">
                                <Field id={"inLocalDiscount_S"} name={"inLocalDiscount_S"} label={GetField("inLocalDiscount_S")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                            {GetField("schedulePriceDate")["isVisible"] && <div className="col-md-3"> 
                                <Field id={"schedulePriceDate"} name={"schedulePriceDate"} label={GetField("schedulePriceDate")["label"]} component={FormDatePicker}
                                    wrapperStyle={{ width: "90%", marginRight: "18px",}}/>
                            </div>}                        </div>

                        <div className='row' >
                        {GetField("inVisitStandard")["isVisible"] && <div className="col-md-3">
                                <Field id={"inVisitStandard"} name={"inVisitStandard"} label={GetField("inVisitStandard")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                            {GetField("inVisitStandardGST")["isVisible"] && <div className='col-md-3'>
                                <Field id={"inVisitStandardGST"} name={"inVisitStandardGST"} label={GetField("inVisitStandardGST")["label"]} component={FormSwitch} />
                            </div>}                            
                            {GetField("inVisitStandard_S")["isVisible"] && <div className="col-md-3">
                                <Field id={"inVisitStandard_S"} name={"inVisitStandard_S"} label={GetField("inVisitStandard_S")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                        </div>
                        </TabStripTab>}
                        {GetField("linkedProducts")["isVisible"] && id !== undefined && <TabStripTab title={GetField("linkedProducts")["label"]}>
                        <div className='row' style={{paddingBottom: "20px"}} >
                            {GetField("addProduct")["isVisible"] && <div className='col-md-3'>
                                <Label>{GetField("addProduct")["label"]}</Label>
                                <DropDownList id={"addProduct"} name={"addProduct"} data={products} textField="name" onChange={productChanged} />
                            </div>}
                            {GetField("addLocal")["isVisible"] && <div className='col-md-3'>
                                <Label>{GetField("addLocal")["label"]}</Label>
                                <TextBox id={"addLocal"} name={"v"} type="number" min={0} max={100000}  />
                            </div>}
                            {GetField("addVisit")["isVisible"] && <div className='col-md-3'>
                                <Label>{GetField("addVisit")["label"]}</Label>
                                <TextBox id={"addVisit"} name={"addVisit"} type="number" min={0} max={100000} />
                            </div>}
                            <div className='col-md-1' style={{paddingTop: "20px"}}>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={addProduct}>Add Product</Button>
                            </div>
                        </div>
                        <div>
                            <FieldArray name="productId" dataItemKey={"id"} component={FormGrid} productList={vpp} updated={vppUpdated} vehicleId={id} update={UpdatePrices} />
                        </div>
                        </TabStripTab>}
                    </TabStrip>                        
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditVehicles