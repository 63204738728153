import * as React from 'react';
import Notifications from '../components/Notifications';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";

const ManagePages = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");

    React.useEffect(() => { 
      GetPermissions(["ViewManagePages", "NewManagePages", "EditManagePages", "DeleteManagePages"], setPermissions);
      GetColumnDefs("ManagePages", setColumns, setErrorMessage); 
    }, [])

    const setVisibility = (cbid, vis) => {    
        let id = String(cbid).substring(2);
        let type= "Visible";
        if (cbid.startsWith("VM")) type = "Mobile";
        if (cbid.startsWith("AV")) type = "Available";
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/${id}?visibility=${vis}&type=${type}`;
        const init = {
          method: 'PATCH',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache"
        }
        fetch(url, init).then(response => response).then(d => {           
        });
    }

    return (
        <div>
        <Notifications errorMessage={errorMessage}/>
        {columns && <DataGrid dataSource='apiv7/userInterfaces' columns={columns} permissions={permissions} setVisibility={setVisibility} keyField ="userInterfaceId" />}
        </div>       
    );

}

export default ManagePages;