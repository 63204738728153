import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import Notifications from '../components/Notifications';

const SystemSetting = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);

    React.useEffect(() => { 
        GetColumnDefs("Sites", setColumns, setErrorMessage); 
        GetPermissions(["ViewSite","EditSite"], setPermissions)
    }, [])
    return (
        <div>
        <Notifications errorMessage={errorMessage}/>
        {columns && permissions && <DataGrid dataSource='apiv7/sites' editUrl={"Editsitesystem"} columns={columns} permissions={permissions} keyField ="siteId" />}
        </div>       
    );

}

export default SystemSetting;