/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { Reports } from "./ReportData";
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import  ReportViewer  from '../components/ReportViewer';
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { MultiSelectTreeEditor } from "../components/MultiSelectTreeEditor";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState } from "../components/multiselecttree-data-operations";
import { GetPermissions } from '../components/Options';
const SimpleReports = () => { 
    const [permissions, setPermissions] = React.useState(null);
    const [company, setCompany] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    React.useEffect(() => {
        GetPermissions(["ViewSettings"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/sites?$filter=siteid eq 1`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (d !== undefined) {
                setCompany(d[0]['address1']);
                setAddress(d[0]['address2']);
            }
            
        });
    }, []);
    const initialExpanded = Reports.reduce(
        (acc, cur) => ({
            ...acc,
            [cur.id]: false,
        }),
        {}
    );   
    const [expanded, setExpanded] = React.useState({
        ...initialExpanded,
        Brazil: true,
    });
    const onAction = React.useCallback(
        (id) => {
            setExpanded({
                ...expanded,
                [id]: !expanded[id],
            });
        },
        [expanded]
    );
    let dr;
    let dt = new Date();
    dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()) };
    //localStorage.setItem("ReportDates", JSON.stringify(dr));
    
    const [dateRange, setDateRange] = React.useState(dr);
    const [reportName, setReportName] = React.useState();
    const [sitesData, setSitesData] = React.useState();
    const rangeChanged = (event) => {
        let dr = event.target.value;
        setDateRange(dr);
    }
    const GenerateReport = (report) => {
        setReportName(report);
    }
    
    const [siteValue, setSiteValue] = React.useState([]);
    const onChange = (event) => {
        setSiteValue([...event.value]);
    };
    const reset = () => {
        setReportName("");
    }
    const GetList = (obj) => {
        if (obj === undefined || obj === null) return "";
        var l = [];
        for (let i = 0; i < obj.length; i++) {
            let name = obj[i].name;
            l.push(name)
        }
        return l;
    }
    React.useEffect(() => {
        var url = configData.SERVER_URL + "apiv7/options";
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(opt => {
            var sites = GetList(opt.sites)
            setSitesData(sites)
            setSiteValue(sites)
        });
    }, []);
    
    return (
        <div>
            {!reportName && <div>
                <h5 class="report-title">Click One of the Transaction Reports to View</h5>
                <div className="row Reportfillter">
                    <div className="col-md-4">
                        <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged} />
                    </div>
                    <div className='col-md-4'>
                        <div className="col-md-4">Site</div>
                        <MultiSelect
                            data={sitesData}
                            onChange={onChange}
                            value={siteValue}
                            placeholder="Please select ..."
                        />
                    </div>
                </div>
                <div className="wrapper">
                    {Reports.map((item) => (
                        <ExpansionPanel
                            title={item.report}
                            expanded={expanded === item.id}
                            tabIndex={0}
                            key={item.id}
                            onAction={(event) => {
                                setExpanded(event.expanded ? "" : item.id);
                            }}
                        >
                            <Reveal transitionEnterDuration={150} transitionExitDuration={150}>
                                {expanded === item.id && (
                                    <ExpansionPanelContent>
                                        <div className="content">
                                            <div className="row">
                                                <div className="col-md-3 ReportButton">
                                                    {item.id !== 'ListOfTransactions' && <Button onClick={() => GenerateReport("Transaction" + item.id)} id={"Transaction" + item.report} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">View {item.report} Report</Button>}
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 ReportButton">
                                                    {item.id !== 'ByProductwithEPASource' && item.id !== 'ByOverweight' && item.id !== 'ListOfTransactions' && <Button onClick={() => GenerateReport("Transaction" + item.id + "Summary")} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">View Summary {item.report} Report</Button>}
                                                    {item.id === 'ByOverweight' && <Button onClick={() => GenerateReport("Transaction" + item.id + "Job")} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">View Summary {item.report} By Job Report</Button>}
                                                </div>

                                            </div>
                                            {item.id === 'ListOfTransactions' && <div className="ReportButton"><Button onClick={() => GenerateReport("TransactionsList")} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Transaction List</Button>
                                                <Button style={{marginLeft:"10px"}} onClick={() => GenerateReport("ManualList")} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"> Manual List</Button>
                                                <Button style={{ marginLeft: "10px" }} onClick={() => GenerateReport("DeletedList")} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"> Deleted List</Button>
                                            </div>}
                                            {item.id === 'ListOfTransactions' && <div className="ReportButton">
                                                <Button onClick={() => GenerateReport("VehicleTareDetailsList")} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Transaction Vehicle Tare Details List</Button></div>}
                                            {item.id === 'ByCustomer' && <div className="ReportButton"><Button onClick={() => GenerateReport("TransactionByProductCustomerSummary")} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">View Summary {item.report} By Product</Button></div>}
                                            {item.id === 'ByProduct' && <div className="ReportButton"><Button onClick={() => GenerateReport("TransactionByDestinationByProductSummary")} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">View Summary By Destination {item.report}</Button></div>}
                                            {item.id === 'ByJob' && <div className="ReportButton"><Button onClick={() => GenerateReport("TransactionByProductJobSummary")} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">View Summary {item.report} By Product Report</Button></div>}
                                            {item.id === 'ByJob' && <div className="ReportButton"><Button onClick={() => GenerateReport("TransactionByJobByLoad")} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">View {item.report} MaxLoad Report</Button></div>}
                                        </div>
                                    </ExpansionPanelContent>
                                )}
                            </Reveal>
                        </ExpansionPanel>

                    ))}
                </div>
            </div>}
            {reportName && <div> <Button style={{ marginLeft: "10px", marginTop: "30px" }} type={"submit"} onClick={() => reset()}  themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"> Back To List</Button>
                <ReportViewer name={reportName} sites={siteValue} dateRange={dateRange} company={company} address={address}></ReportViewer></div>}
        </div>
    );

}

export default SimpleReports;