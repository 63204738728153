import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDropDownList, FormInput, FormNumericTextBox, FormSwitch, FormTextArea } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { GetPermissions } from '../components/Options';

const EditMessageQueues = () => {
    const { id } = useParams(); 
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); 
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "type", "label": "Message Type", "defaultValue": "EMAIL", "isVisible": true, "type": "text", "format": "" },
        { "field": "to", "label": "To", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "field": "creationTime", "label": "Creation Date", "defaultValue": "", "isVisible": true, "type": "date", "format": "d/MM/yy hh:mm" },
        { "field": "subject", "label": "Subject", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "field": "body", "label": "Body", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "field": "notes", "label": "Notes", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "field": "numberOfSendAttempts", "label": "Send Attempts", "defaultValue": 0, "isVisible": true, "type": "numeric", "format": "" },
        { "field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean", "format": "" }
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        type: GetField("type")["defaultValue"],
        to: GetField("to")["defaultValue"],
        subject: GetField("subject")["defaultValue"],
        body: GetField("body")["defaultValue"],
        creationTime: "",
        notes: "",
        numberOfSendAttempts: GetField("numberOfSendAttempts")["defaultValue"],
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
    });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/offences
    React.useEffect(() => {
        GetPermissions(["ViewMessageQueue", "NewMessageQueue", "EditMessageQueue"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=editMessages`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile) {
                let data = [];
                for (let i = 0; i < d.length; i++) {
                    data.push({ field: d[i].field, label: d[i].label, type: d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile });
                }
                setLayout(data);
            } else {
                setLayout(d);
            }
        });
        if (id !== undefined) {
            url = `${configData.SERVER_URL}apiv7/messagequeues/${id}`;
            fetch(url, init).then(response => response.json()).then(d => {
                var dti = new Date(d.creationTime + "Z");
                let dt = new Date(dti).toLocaleString("en-AU");
                setValues({
                    type: "EMAIL", //d.type,
                    creationTime: dt,
                    to: d.to,
                    subject: d.subject,
                    notes: d.notes,
                    body: d.body,
                    numberOfSendAttempts: d.numberOfSendAttempts,
                    active: d.isActive
                });
                setFormkey(formkey + 1);
            })
        }
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch (fld["type"]) {
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/messagequeues${pth}`;
        const init = {
            method: id === undefined ? 'POST' : 'PUT',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "messageQueueId": id === undefined ? 0 : id,
                "to": GetDefaultData(dataItem.to, "to"),
                "creationTime": new Date().toISOString(),
                "subject": GetDefaultData(dataItem.subject, "subject"),
                "body": GetDefaultData(dataItem.body, "body"),
                "attachment": null,
                "notes": GetDefaultData(dataItem.notes, "notes"),
                "numberOfSendAttempts": GetDefaultData(dataItem.numberOfSendAttempts, "numberOfSendAttempts"),
                "isActive": GetDefaultData(dataItem.active, "active")
            })
        }
        fetch(url, init).then(response => response).then(d => {
            var msg = id === undefined ? "New message created" : "Message updated";
            if (d.status > 299) msg = `Error ${d.status} creating Message`;
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/messages", { state: { msg: msg } });
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const regoValidator = (value) => !value ? "Please enter a value" : "";
    const optionValidator = (value) => value ? "" : "Please select an option";
    return (
        <div style={{ padding: 20 }}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{ width: "98%" }}>
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                            {GetField("type")["isVisible"] && GetField("type")["isVisible"] && <div className="col-md-3">
                                <Field id={"type"} name={"type"} label={GetField("type")["label"]} component={FormInput} disabled />
                            </div>}
                            {GetField("creationTime")["isVisible"] && <div className="col-md-3">
                                <Field id={"creationTime"} name={"creationTime"} label={GetField("creationTime")["label"]} component={FormInput} disabled />
                            </div>}
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("to")["isVisible"] && <div className='col-md-6'>
                                <Field id={"to"} name={"to"} label={GetField("to")["label"]} component={FormInput} />
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("subject")["isVisible"] && <div className='col-md-6'>
                                <Field id={"subject"} name={"subject"} label={GetField("subject")["label"]} component={FormInput} />
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("body")["isVisible"] && <div className='col-md-6'>
                                <Field id={"body"} name={"body"} label={GetField("body")["label"]} component={FormTextArea} rows={10} />
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("notes")["isVisible"] && <div className='col-md-6'>
                                <Field id={"notes"} name={"notes"} label={GetField("notes")["label"]} component={FormTextArea} rows={5} />
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("numberOfSendAttempts")["isVisible"] && <div className='col-md-2'>
                                <Field id={"numberOfSendAttempts"} name={"numberOfSendAttempts"} label={GetField("numberOfSendAttempts")["label"]} component={FormNumericTextBox} />
                            </div>}
                        </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{ paddingTop: 10 }}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>
                </FormElement>
            )} />
        </div>
    );
}

export default EditMessageQueues