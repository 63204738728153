import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Notification } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

const UserApproval = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/");

    React.useEffect(() => { 
        GetColumnDefs("userapproval", setColumns, setErrorMessage); 
        GetPermissions(["ViewUserApproval", "ApproveUserApproval", "DeleteUserApproval", "ReportUserApproval"], setPermissions)
    }, [])

    const ApproveUser = (item) => {
        var url = `${configData.SERVER_URL}apiv7/private/approveuser/${item.externalUserId}`;
        const init = {
          method: 'POST',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache"
        }
        fetch(url, init).then(response => {
            if (response.status > 499) return response.json();
            return response;
        }).then(d => {           
            if (d.status > 499) {
                setErrorMessage("ERROR: " + d.detail);
                setMessage("");
            } else if (d.status > 299) {
                setErrorMessage("ERROR: " + d.status + ". Please contact support");
                setMessage("");
            } else {
                setMessage("User was approved");
                setErrorMessage("");
            }
            setTimeout(() => {
                setMessage("");
                setErrorMessage("");
            }, 5000);
        });
    }

    return ( 
        <div>
            {errorMessage && <Fade><Notification type={{ style: "error", icon: true }}>{errorMessage}</Notification></Fade>}
            {message && <Fade><Notification type={{ style: "success", icon: true }}>{message}</Notification></Fade>}
        {columns && permissions && <DataGrid dataSource='apiv7/externalusers' columns={columns} approve={ApproveUser} permissions={permissions} keyField ="externalUserId" />}
        </div>       
    );
}

export default UserApproval;