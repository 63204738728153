import * as React from 'react';
import $ from 'jquery';

export const Cameras = (props) => {
    const images = props.snapshots;
    const c1Div = React.useRef();
    const c2Div = React.useRef();
    const c3Div = React.useRef();
    const c4Div = React.useRef();
    let online = [true, true, true, true];
    switch (images.length) {
        case 1:
            $("#CameraPanel").removeClass("cameraPanel2Cameras").addClass("cameraPanel1Camera");
            break;
        case 2:
            $("#CameraPanel").addClass("cameraPanel2Cameras").removeClass("cameraPanel1Camera");
            break;
        default:
            $("#CameraPanel").removeClass("cameraPanel2Cameras").removeClass("cameraPanel1Camera");
            break;
    }
    for (var i = 0; i < images.length; i++) {
        if (images[i].startsWith("Offline")) {
            images[i] = images[i].replace("Offline", "");
            online[i] = false;
        }
        let cd = c1Div.current;
        switch (i) {
            case 1: cd = c2Div.current; break;
            case 2: cd = c3Div.current; break;
            case 3: cd = c4Div.current; break;
        }
        if (cd !== undefined && cd !== null && cd.classList !== undefined) {
            if (online[i]) {
                cd.classList.remove("DeviceOffline");
            }
            else {
                cd.classList.add("DeviceOffline");
            }
        }
    }

    const CameraClicked = (e) => {
        // currently not working as browser requires content to be HTTPS
        // need to apply 
    //    if (props.showStream === undefined) return;
    //    let ind = parseInt(String(e.target.id).substring(1));
    //    props.showStream(ind);
    }

    return (
        <div id="CameraPanel" className={"cameraPanel"}>
            {images.length > 0 && <div ref={c1Div}><img id="C1" src={images[0]} className={"cameraSnapshot"} onClick={CameraClicked} /></div>}
            {images.length > 1 && <div ref={c2Div}><img id="C2" src={images[1]} className={"cameraSnapshot"} onClick={CameraClicked} /></div>}
            {images.length > 2 && <div ref={c3Div}><img id="C3" src={images[2]} className={"cameraSnapshot"} onClick={CameraClicked} /></div>}
            {images.length > 3 && <div ref={c4Div}><img id="C4" src={images[3]} className={"cameraSnapshot"} onClick={CameraClicked} /></div>}
        </div>
    );
}