import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import Notifications from '../components/Notifications';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";

const Offences = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");

    React.useEffect(() => { 
        GetColumnDefs("offences", setColumns, setErrorMessage); 
        GetPermissions(["ViewOffence", "NewOffence", "EditOffence", "DeleteOffence", "ReportOffence"], setPermissions)
    }, [])

    const CreateParams = (dr) => {
        var params = {
            FromDate: dr.start === null ? "2000-01-01T00:00:00Z" : dr.start.toISOString(),
            ToDate: dr.end === null ? "2050-01-01T00:00:00Z" : dr.end.toISOString()
        };
        return encodeURI(JSON.stringify(params));               
    }

    let dr = JSON.parse(localStorage.getItem('OffenceDates'));
    if (dr !== null){
        if (dr.start !== null) dr.start = new Date(dr.start);
        if (dr.end !== null) dr.end = new Date(dr.end);
    } else {
        let dt = new Date();
        dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: null};
        localStorage.setItem("OffenceDates", JSON.stringify(dr));
    }
    const [dateRange, setDateRange] = React.useState(dr);
    const par = CreateParams(dr);
    const [lookupParams, setLookupParams] = React.useState(par); 

    const rangeChanged = (event) => { 
        let dr = event.target.value;
        setDateRange(dr.value); 
        localStorage.setItem("OffenceDates", JSON.stringify(dr));
        var params = {
            FromDate: dr.start === null ? "2000-01-01T00:00:00Z" : dr.start.toISOString(),
            ToDate: dr.end === null ? "2050-01-01T00:00:00Z" : dr.end.toISOString()
        };
        setLookupParams(encodeURI(JSON.stringify(params)));        
    }

    return ( 
        <div>
        <Notifications errorMessage={errorMessage}/>
        <div>
            <div className="row" style={{padding:"30px"}}>
                <div className="col-md-3">
                    <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged}  />
                </div>
            </div>
        </div>           
        {columns && permissions && <DataGrid dataSource='apiv7/offences' columns={columns} permissions={permissions} keyField ="offenceId" params={lookupParams} />}
        </div>       
    );
}

export default Offences;