import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormDatePicker, FormNumericTextBox, FormTextArea, FormDropDownList, FormMultiSelect } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState  } from "../components/multiselecttree-data-operations";
import { MultiSelectTreeEditor } from "../components/MultiSelectTreeEditor";

const EditProducts = () => {
    const { id } = useParams(); // editproducts/id url is used to edit existing product, otherwise id is undefined
    const { type } = useParams();
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [directions, setDirections] = React.useState([ ]);
    const [categories, setCategories] = React.useState([ ]);
    const [destinations, setDestinations] = React.useState([ ]);
    const [sources, setSources] = React.useState([ ]);
    const epaStatii = ["", "Leviable", "Exempt", "Non-Waste", "Under Contract", "Other"];
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [permissions, setPermissions] = React.useState(null);
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "productHeading", "label": "Product ", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "name", "label": "Name", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "code", "label": "Code", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "active", "label": "Active", "defaultValue": true, "isVisible": true, "type": "boolean"},
        { "field": "direction", "label": "Goods Direction", "defaultValue": "NA", "isVisible": true, "type": "text"},
        { "field": "productCategoryId", "label": "Waste Stream", "defaultValue": {id: 1, name: "NA"}, "isVisible": true, "type": "text"},
        { "field": "description", "label": "Description", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "inLocalDiscount", "label": "Local (Discount) in $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "inLocalDiscountGST", "label": "Local In GST", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "outLocalDiscount", "label": "Local (Discount) Out $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "outLocalDiscountGST", "label": "Local Out GST", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "minLocalDiscount", "label": "Local Minimum $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "inVisitStandard", "label": "Visit (Standard) In $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "inVisitStandardGST", "label": "Visit In GST", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "outVisitStandard", "label": "Visit (Standard) Out $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "outVisitStandardGST", "label": "Visit Out GST", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "outVisitMinimum", "label": "Visit Minimum $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "pricingDetails", "label": "Pricing details", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "epaDetails", "label": "EPA details", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "wasteTypeCode", "label": "EPA Waste Type Code", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "epaLevy", "label": "EPA Levy $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "minEPALevy", "label": "EPA Minimum $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "epaStatus", "label": "EPA Status", "defaultValue": "Leviable", "isVisible": true, "type": "list"},
        { "field": "epaDIN", "label": "EPA DIN No", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "epaSourceRequired", "label": "EPA Source Required", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "otherDetails", "label": "Other details", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "destinationId", "label": "Destination", "defaultValue": {id: 1, name: "NA"}, "isVisible": true, "type": "text"},
        { "field": "sourceId", "label": "Source", "defaultValue": {id: 1, name: "NA"}, "isVisible": true, "type": "text"},
        { "field": "minWeight", "label": "Minimum Weight (t)", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "toVolumeFactor", "label": "Volume Factor /w", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "noOfDockets", "label": "No Of Dockets", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "stockLevel", "label": "Stock Level", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "minimumStockLevel", "label": "Minimum Stock Level", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "maximumStockLevel", "label": "Maximum Stock Level", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "royalty", "label": "Royalty $", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "liftBoomGate", "label": "List Boom Gate", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "ledgerAccount", "label": "Ledge Account", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "resourceCode", "label": "Resource Code", "defaultValue": "", "isVisible": true, "type": "text", "format": null},
        { "field": "stockPrice", "label": "Stock Price", "defaultValue": 0, "isVisible": true, "type": "numeric", "format": null},
        { "field": "showStockLevelOnDocket", "label": "Show Stock Level on Docket", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "siteDetails", "label": "Site details", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "priceSchedule", "label": "Price Schedule", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "schedulePriceDate","label": "Schedule Price Date","defaultValue": "","isVisible": true,"type": "date"},
        { "field": "scheduleLocalDiscountIn","label": "Schedule Local (Discount) In $","defaultValue": "","isVisible": true,"type": "numeric"},
        { "field": "scheduleLocalDiscountOut","label": "Schedule Local (Discount) Out $","defaultValue": "","isVisible": true,"type": "numeric"},
        { "field": "scheduleLocalMinimum","label": "Schedule Local Minimum $","defaultValue": "","isVisible": true,"type": "numeric"},
        { "field": "scheduleVisitIn","label": "Schedule Visit (Standard) In $","defaultValue": "","isVisible": true,"type": "numeric"},
        { "field": "scheduleVisitOut","label": "Schedule Visit (Standard) Out $","defaultValue": "","isVisible": true,"type": "numeric"},
        { "field": "scheduleVisitMinimum","label": "Schedule Visit Minimum $","defaultValue": "","isVisible": true,"type": "numeric"},
        { "field": "scheduledEPALevy","label": "Schedule EPA Levy $","defaultValue": "","isVisible": true,"type": "numeric"},
        { "field": "scheduleEPAMinimum","label": "Schedule EPA Minimum $","defaultValue": "","isVisible": true,"type": "numeric" }        
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    const [values, setValues] = React.useState({
            name: GetField("name")["defaultValue"],
            code: GetField("code")["defaultValue"],
            direction: GetField("direction")["defaultValue"],
            productCategoryId: GetField("productCategoryId")["defaultValue"],
            description: GetField("description")["defaultValue"],
            inLocalDiscount: GetField("inLocalDiscount")["defaultValue"],
            inLocalDiscountGST: String(GetField("inLocalDiscountGST")["defaultValue"]).toLowerCase() === "true",
            outLocalDiscount: GetField("outLocalDiscount")["defaultValue"],
            outLocalDiscountGST: String(GetField("outLocalDiscountGST")["defaultValue"]).toLowerCase() === "true",
            minLocalDiscount: GetField("minLocalDiscount")["defaultValue"],
            inVisitStandard: GetField("inVisitStandard")["defaultValue"],
            inVisitStandardGST: String(GetField("inVisitStandardGST")["defaultValue"]).toLowerCase() === "true",
            outVisitStandard: GetField("outVisitStandard")["defaultValue"],
            outVisitStandardGST: String(GetField("outVisitStandardGST")["defaultValue"]).toLowerCase() === "true",
            outVisitMinimum: GetField("outVisitMinimum")["defaultValue"],
            pricingDetails: GetField("pricingDetails")["defaultValue"],
            epaDetails: GetField("epaDetails")["defaultValue"],
            wasteTypeCode: GetField("wasteTypeCode")["defaultValue"],
            epaLevy: GetField("epaLevy")["defaultValue"],
            minEPALevy: GetField("minEPALevy")["defaultValue"],
            epaStatus: GetField("epaStatus")["defaultValue"],
            epaDIN: GetField("epaDIN")["defaultValue"],
            epaSourceRequired: String(GetField("epaSourceRequired")["defaultValue"]).toLowerCase() === "true",
            otherDetails: GetField("otherDetails")["defaultValue"],
            destinationId: GetField("destinationId")["defaultValue"],
            sourceId: GetField("sourceId")["defaultValue"],
            minWeight: GetField("minWeight")["defaultValue"],
            toVolumeFactor: GetField("toVolumeFactor")["defaultValue"],
            noOfDockets: GetField("noOfDockets")["defaultValue"],
            stockLevel: GetField("stockLevel")["defaultValue"],
            royalty: GetField("royalty")["defaultValue"],
            liftBoomGate: String(GetField("liftBoomGate")["defaultValue"]).toLowerCase() === "true",
            ledgerAccount: GetField("ledgerAccount")["defaultValue"],
            resourceCode: GetField("resourceCode")["defaultValue"],
            stockPrice: GetField("stockPrice")["defaultValue"],
            sourceSites: [],
            showStockLevelOnDocket: String(GetField("showStockLevelOnDocket")["defaultValue"]).toLowerCase() === "true",
            siteDetails: GetField("siteDetails")["defaultValue"],
            active: String(GetField("active")["defaultValue"]).toLowerCase() === "true",
            schedulePriceDate: new Date(),
            scheduleLocalDiscountIn: GetField("scheduleLocalDiscountIn")["defaultValue"],
            scheduleLocalDiscountOut: GetField("scheduleLocalDiscountOut")["defaultValue"],
            scheduleLocalMinimum: GetField("scheduleLocalMinimum")["defaultValue"],
            scheduleVisitIn: GetField("scheduleVisitIn")["defaultValue"],
            scheduleVisitOut: GetField("scheduleVisitOut")["defaultValue"],
            scheduleVisitMinimum: GetField("scheduleVisitMinimum")["defaultValue"],
            scheduledEPALevy: GetField("scheduledEPALevy")["defaultValue"],
            scheduleEPAMinimum: GetField("scheduleEPAMinimum")["defaultValue"]        
        });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vehicleconfigurations
    React.useEffect(() => {
        GetPermissions(["ViewProduct", "NewProduct", "EditProduct"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditProducts'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setDirections(opt.directions);
            setCategories(opt.productCategories);
            setDestinations(opt.destinations);
            setSources(opt.sources);
            let allSites = GetList(opt.sites);
            let defSites = ConfigureSites(opt.sites, opt.inactiveSites, allSites);
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/products/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var dest = opt.destinations.find(obj => { return obj.id === String(d.destinationId) });
                    var source = opt.sources.find(obj => { return obj.id === String(d.sourceId) });
                    var cat = opt.productCategories.find(obj => { return obj.id === String(d.productCategoryId) });
                    let sites = ConfigureSites(opt.sites, opt.inactiveSites, d.siteList);

                    setValues({
                        name: d.name === null ? "" : d.name,
                        code: d.code === null ? "" : d.code,
                        active: d.active === null ? "" : d.isActive,
                        direction: d.direction === null ? "" : d.direction,
                        productCategoryId: d.productCategoryId === null ? "" : cat,
                        description: d.description === null ? "" : d.description,
                        inLocalDiscount: d.inLocalDiscount === null ? 0 : d.inLocalDiscount,
                        inLocalDiscountGST: d.inLocalDiscountGST === null ? false : d.inLocalDiscountGST,
                        outLocalDiscount: d.outLocalDiscount === null ? 0 : d.outLocalDiscount,
                        outLocalDiscountGST: d.outLocalDiscountGST === null ? false : d.outLocalDiscountGST,
                        minLocalDiscount: d.minLocalDiscount === null ? 0 : d.minLocalDiscount,
                        inVisitStandard: d.inVisitStandard === null ? 0 : d.inVisitStandard,
                        inVisitStandardGST: d.inVisitStandardGST === null ? "" : d.inVisitStandardGST,
                        outVisitStandard: d.outVisitStandard === null ? 0 : d.outVisitStandard,
                        outVisitStandardGST: d.outVisitStandardGST === null ? "" : d.outVisitStandardGST,
                        outVisitMinimum: d.outVisitMinimum === null ? 0 : d.minVisitStandard,
                        wasteTypeCode: d.wasteTypeCode === null ? "" : d.wasteTypeCode,
                        epaLevy: d.epaLevy === null ? 0 : d.epaLevy,
                        minEPALevy: d.minEPALevy === null ? 0 : d.minEPALevy,
                        epaStatus: d.epaStatus === null ? "" : d.epA_Status,
                        epaDIN: d.epaDIN === null ? "" : d.epA_Din,
                        epaSourceRequired: d.epaSourceRequired === null ? false : d.report_To_EPA,
                        otherDetails: d.otherDetails === null ? "" : d.otherDetails,
                        destinationId: d.destinationId === null ? "" : dest,
                        sourceId: d.sourceId === null ? "" : source,
                        minWeight: d.minWeight === null ? 0 : d.minWeight,
                        toVolumeFactor: d.toVolumeFactor === null ? 0 : d.toVolumeFactor,
                        noOfDockets: d.noOfDockets === null ? 0 : d.noOfDockets,
                        stockLevel: d.stockLevel === null ? 0 : d.stockLevel,
                        minimumStockLevel: d.minimumStockLevel === null ? 0 : d.minimumStockLevel,
                        maximumStockLevel: d.maximumStockLevel === null ? 0 : d.maximumStockLevel,
                        royalty: d.royalty === null ? 0 : d.royalty,
                        liftBoomGate: d.liftBoomGate === null ? false : d.liftBoomGate,
                        ledgerAccount: d.ledgerAccount === null ? 0 : d.ledgerAccount,
                        resourceCode: d.resourceCode === null ? "" : d.resourceCode,
                        sourceSites: sites,
                        stockPrice: d.stockPrice === null ? 0 : d.stockPrice,
                        showStockLevelOnDocket: d.showStockLevelOnDocket === null ? false : d.showStockLevelOnDocket,
                        siteDetails: d.siteDetails === null ? "" : d.siteDetails,
                        schedulePriceDate: d.schedulePriceDate === null ? new Date(): new Date(d.schedulePriceDate),
                        scheduleLocalDiscountIn: d.scheduleLocalDiscountIn === null ? 0: d.inLocalDiscount_S,
                        scheduleLocalDiscountOut: d.scheduleLocalDiscountOut === null ? 0: d.outLocalDiscount_S,
                        scheduleLocalMinimum: d.scheduleLocalMinimum === null ? 0: d.minLocalDiscount_S,
                        scheduleVisitIn: d.scheduleVisitIn === null ? 0: d.inVisitStandard_S,
                        scheduleVisitOut: d.scheduleVisitOut === null ? 0: d.outVisitStandard_S,
                        scheduleVisitMinimum: d.scheduleVisitMinimum === null ? 0: d.minVisitStandard_S,
                        scheduledEPALevy: d.scheduledEPALevy === null ? 0: d.epaLevy_S,
                        scheduleEPAMinimum: d.scheduleEPAMinimum === null ? 0: d.minEPALevy_S
                    });
                    setFormkey(formkey + 1);
                })
            } else {
                var v = {...values};
                v.sourceSites = defSites;
                setFormkey(formkey + 1);
                setValues(v);                
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (f === undefined) f = "";
        if (fld === undefined || fld === null) return f;
        let res = fld["defaultValue"];
        if (fld["isVisible"]) res = f;
        switch(fld["type"]){
            case "numeric": 
                res = parseFloat(res); 
                if (isNaN(res)) res = 0;
                break;
            case "boolean": res = (res === "true" || res === true); break;
            case "date":
                if (res === "") res = null;
                break;
            default: break;
        }
        return res;
    }

    const GetDropdownData = (f, name) => {
        let res = 0;
        if (f === undefined || f.id === undefined) 
            res = GetDefaultData('', name);
        else
            res = f.id;
        return parseInt(res);
    }

    const isNullOrEmpty = (x) => { return (x === undefined || x === null || x === ""); }
    
    const Validate = (dataItem) => {
        let st = "";
        if (isNullOrEmpty(dataItem.name)) st += "Name\r\n";
        if (isNullOrEmpty(dataItem.code)) st += "Code\r\n";
        if (isNullOrEmpty(dataItem.direction) && GetField("direction")["isVisible"]) st += "Goods Direction\r\n";
        if (isNullOrEmpty(dataItem.productCategoryId) && GetField("productCategoryId")["isVisible"]) st += "Waste Stream\r\n";
        if (st !== ""){
            setSelectedTab(0);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        if (isNullOrEmpty(dataItem.destinationId)) st += "Destination\r\n";
        if (isNullOrEmpty(dataItem.sourceId)) st += "Source\r\n";
        if (st !== ""){
            setSelectedTab(3);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        return true;
    }

    const [sitesData, setSitesData] = React.useState([{id:0, text: "Active", items: []}]);
    const dataItemKey = "id";
    const checkField = "checkField";
    const checkIndeterminateField = "checkIndeterminateField";
    const subItemsField = "items";
    const expandField = "expanded";
    const textField = "text";
    const fields = { dataItemKey, checkField, checkIndeterminateField, expandField, subItemsField };

    const [sitesValue, setSitesValue] = React.useState([]);
    const [siteExpanded, setSiteExpanded] = React.useState([]);
    const onSitesChange = (event) => {
        setSitesValue(getMultiSelectTreeValue(sitesData, {...fields, ...event, value: sitesValue, }));
    }
    const onSitesExpandChange = React.useCallback((event) => setSiteExpanded(expandedState(event.item, dataItemKey, siteExpanded)), [siteExpanded]);
    const sitesTreeData = React.useMemo(() => processMultiSelectTreeData(sitesData, { expanded: siteExpanded, value: sitesValue, ...fields }), [siteExpanded, sitesValue]);
    const ConfigureSites = (act, dis, siteList) => {
        if (siteList === null) return;
        let slsp = siteList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            let inactive = {id: 999999, text: 'Inactive', items: sl };
            setSitesData([active, inactive]);
        }
        else {
            setSitesData([active]);
        }
        setSitesValue([...selected]);       
        return [...selected];
    }

    const GetList = (obj) => {
        if (obj === undefined || obj === null || obj.length === 0) return "";
        var l = "";
        for (let i = 0; i < obj.length; i++){
            let id = obj[i].id;
            if (id !== 0 && id !== 999990){
                l += id;
                if (i < obj.length - 1) l += ",";
            }
        }
        return l;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        if (!Validate(dataItem)) return;        
        var pth = id === undefined ? "" : "/" + id;
        var siteList = GetList(dataItem.sourceSites);
        var url = `${configData.SERVER_URL}apiv7/products${pth}`;
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "productId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.name, "name"),
            "code": GetDefaultData(dataItem.code, "code"),
            "direction": GetDefaultData(dataItem.direction, "direction"),
            "productCategoryId": GetDropdownData(dataItem.productCategoryId, "productCategoryId"),
            "description": GetDefaultData(dataItem.description, "description"),
            "inLocalDiscount": GetDefaultData(dataItem.inLocalDiscount, "inLocalDiscount"),
            "inLocalDiscountGST": GetDefaultData(dataItem.inLocalDiscountGST, "inLocalDiscountGST"),
            "outLocalDiscount": GetDefaultData(dataItem.outLocalDiscount, "outLocalDiscount"),
            "outLocalDiscountGST": GetDefaultData(dataItem.outLocalDiscountGST, "outLocalDiscountGST"),
            "minLocalDiscount": GetDefaultData(dataItem.minLocalDiscount, "minLocalDiscount"),
            "inVisitStandard": GetDefaultData(dataItem.inVisitStandard, "inVisitStandard"),
            "inVisitStandardGST": GetDefaultData(dataItem.inVisitStandardGST, "inVisitStandardGST"),
            "outVisitStandard": GetDefaultData(dataItem.outVisitStandard, "outVisitStandard"),
            "outVisitStandardGST": GetDefaultData(dataItem.outVisitStandardGST, "outVisitStandardGST"),
            "minVisitStandard": GetDefaultData(dataItem.outVisitMinimum, "outVisitMinimum"),
            "wasteTypeCode": GetDefaultData(dataItem.wasteTypeCode, "wasteTypeCode"),
            "epaLevy": GetDefaultData(dataItem.epaLevy, "epaLevy"),
            "minEPALevy": GetDefaultData(dataItem.minEPALevy, "minEPALevy"),
            "epA_Status": GetDefaultData(dataItem.epaStatus, "epaStatus"),
            "epA_Din": GetDefaultData(dataItem.epaDIN, "epaDIN"),
            "report_To_EPA": GetDefaultData(dataItem.epaSourceRequired, "epaSourceRequired"),
            "destinationId": GetDropdownData(dataItem.destinationId, "destinationId"),
            "sourceId": GetDropdownData(dataItem.sourceId, "sourceId"),
            "minWeight": GetDefaultData(dataItem.minWeight, "minWeight"),
            "toVolumeFactor": GetDefaultData(dataItem.toVolumeFactor, "toVolumeFactor"),
            "noOfDockets": GetDefaultData(dataItem.noOfDockets, "noOfDockets"),
            "stockLevel": GetDefaultData(dataItem.stockLevel, "stockLevel"),
            "minimumStockLevel": GetDefaultData(dataItem.minimumStockLevel, "minimumStockLevel"),
            "maximumStockLevel": GetDefaultData(dataItem.maximumStockLevel, "maximumStockLevel"),
            "royalty": GetDefaultData(dataItem.royalty, "royalty"),
            "liftBoomGate": GetDefaultData(dataItem.liftBoomGate, "liftBoomGate"),
            "ledgerAccount": GetDefaultData(dataItem.ledgerAccount, "ledgerAccount"),
            "resourceCode": GetDefaultData(dataItem.resourceCode, "resourceCode"),
            "stockPrice": GetDefaultData(dataItem.stockPrice, "stockPrice"),
            "showStockLevelOnDocket": GetDefaultData(dataItem.showStockLevelOnDocket, "showStockLevelOnDocket"),
            "productType": type,
            "siteList": siteList,
            "isActive": GetDefaultData(dataItem.active, "active"),
            "schedulePriceDate": GetDefaultData(dataItem.schedulePriceDate, "schedulePriceDate"),
            "inLocalDiscount_S": GetDefaultData(dataItem.scheduleLocalDiscountIn, "scheduleLocalDiscountIn"),
            "outLocalDiscount_S": GetDefaultData(dataItem.scheduleLocalDiscountOut, "scheduleLocalDiscountOut"),
            "minLocalDiscount_S": GetDefaultData(dataItem.scheduleLocalMinimum, "scheduleLocalMinimum"),
            "inVisitStandard_S": GetDefaultData(dataItem.scheduleVisitIn, "scheduleVisitIn"),
            "outVisitStandard_S": GetDefaultData(dataItem.scheduleVisitOut, "scheduleVisitOut"),
            "minVisitStandard_S": GetDefaultData(dataItem.scheduleVisitMinimum, "scheduleVisitMinimum"),
            "epaLevy_S": GetDefaultData(dataItem.scheduledEPALevy, "scheduledEPALevy"),
            "minEPALevy_S": GetDefaultData(dataItem.scheduleEPAMinimum, "scheduleEPAMinimum")           
            })
        }
        fetch(url, init).then(response => response).then(d => { 
            var msg = id === undefined ? "Product created" : "Product updated";
            if (d.status > 299) msg = `Error ${d.status} creating Product`;
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        if (type === "W")
            navigate("/products", {state: {msg: msg}});
        else
            navigate("/counteditemproducts", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/");
    if (permissions && !permissions.CanEdit && id !== undefined) Redirect("Error: You do not have permission to edit products");
    if (permissions && !permissions.CanCreate && id === undefined) Redirect("Error: You do not have permission to create products");

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) =>  value ? "" : "Please select an option";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "95%"}} >
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                    {GetField("productHeading")["isVisible"] && <TabStripTab title={GetField("productHeading")["label"]}>
                        <div className="row">
                           {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("code")["isVisible"] && <div className="col-md-3">
                                <Field id={"code"} name={"code"} label={GetField("code")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("direction")["isVisible"] && <div className='col-md-3'>
                                <Field id={"direction"} name={"direction"} label={GetField("direction")["label"]} component={FormDropDownList} data={directions} validator={optionValidator} />
                            </div>}                            
                            {GetField("productCategoryId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"productCategoryId"} name={"productCategoryId"} label={GetField("productCategoryId")["label"]} component={FormDropDownList} data={categories} textField="name"  validator={optionValidator}/>
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("description")["isVisible"] && <div className="col-md-3">
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormTextArea}/>
                            </div>}
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        </TabStripTab>
                        }
                        {GetField("pricingDetails")["isVisible"] && <TabStripTab title={GetField("pricingDetails")["label"]}>
                        <div className='row' >
                            {GetField("inLocalDiscount")["isVisible"] && <div className="col-md-3">
                                <Field id={"inLocalDiscount"} name={"inLocalDiscount"} label={GetField("inLocalDiscount")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                            {GetField("inLocalDiscountGST")["isVisible"] && <div className='col-md-3'>
                                <Field id={"inLocalDiscountGST"} name={"inLocalDiscountGST"} label={GetField("inLocalDiscountGST")["label"]} component={FormSwitch} />
                            </div>}                            
                            {GetField("outLocalDiscount")["isVisible"] && <div className="col-md-3">
                                <Field id={"outLocalDiscount"} name={"outLocalDiscount"} label={GetField("outLocalDiscount")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                            {GetField("outLocalDiscountGST")["isVisible"] && <div className='col-md-3'>
                                <Field id={"outLocalDiscountGST"} name={"outLocalDiscountGST"} label={GetField("outLocalDiscountGST")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        <div className='row' >
                            {GetField("minLocalDiscount")["isVisible"] && <div className="col-md-3">
                                <Field id={"minLocalDiscount"} name={"minLocalDiscount"} label={GetField("minLocalDiscount")["label"]} component={FormNumericTextBox} min={0} max={100000}  format="c2"/>
                            </div>}                            
                        </div>
                        <div className='row' >
                            {GetField("inVisitStandard")["isVisible"] && <div className="col-md-3">
                                <Field id={"inVisitStandard"} name={"inVisitStandard"} label={GetField("inVisitStandard")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                            {GetField("inVisitStandardGST")["isVisible"] && <div className='col-md-3'>
                                <Field id={"inVisitStandardGST"} name={"inVisitStandardGST"} label={GetField("inVisitStandardGST")["label"]} component={FormSwitch} />
                            </div>}                            
                            {GetField("outVisitStandard")["isVisible"] && <div className="col-md-3">
                                <Field id={"outVisitStandard"} name={"outVisitStandard"} label={GetField("outVisitStandard")["label"]} component={FormNumericTextBox} min={0} max={100000}  format="c2"/>
                            </div>}                            
                            {GetField("outVisitStandardGST")["isVisible"] && <div className='col-md-3'>
                                <Field id={"outVisitStandardGST"} name={"outVisitStandardGST"} label={GetField("outVisitStandardGST")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        <div className='row' >
                            {GetField("outVisitMinimum")["isVisible"] && <div className="col-md-3">
                                <Field id={"outVisitMinimum"} name={"outVisitMinimum"} label={GetField("outVisitMinimum")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                        </div>
                        </TabStripTab>}
                        {GetField("epaDetails")["isVisible"] && <TabStripTab title={GetField("epaDetails")["label"]}>
                        <div className='row' >
                            {GetField("wasteTypeCode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"wasteTypeCode"} name={"wasteTypeCode"} label={GetField("wasteTypeCode")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("epaLevy")["isVisible"] && <div className="col-md-3">
                                <Field id={"epaLevy"} name={"epaLevy"} label={GetField("epaLevy")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                            {GetField("minEPALevy")["isVisible"] && <div className="col-md-3">
                                <Field id={"minEPALevy"} name={"minEPALevy"} label={GetField("minEPALevy")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                            {GetField("epaStatus")["isVisible"] && <div className='col-md-3'>
                                <Field id={"epaStatus"} name={"epaStatus"} label={GetField("epaStatus")["label"]} component={FormDropDownList} data={epaStatii} validator={optionValidator} />
                            </div>}                            
                        </div>
                        <div className='row' >
                            {GetField("epaDIN")["isVisible"] && <div className='col-md-3'>
                                <Field id={"epaDIN"} name={"epaDIN"} label={GetField("epaDIN")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("epaSourceRequired")["isVisible"] && <div className='col-md-3'>
                                <Field id={"epaSourceRequired"} name={"epaSourceRequired"} label={GetField("epaSourceRequired")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        </TabStripTab>}
                        {GetField("otherDetails")["isVisible"] && <TabStripTab title={GetField("otherDetails")["label"]}>
                        <div className="row">
                            {GetField("destinationId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"destinationId"} name={"destinationId"} label={GetField("destinationId")["label"]} component={FormDropDownList} data={destinations} textField="name"  validator={optionValidator}/>
                            </div>}                            
                            {GetField("sourceId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"sourceId"} name={"sourceId"} label={GetField("sourceId")["label"]} component={FormDropDownList} data={sources} textField="name"  validator={optionValidator}/>
                            </div>}
                            {GetField("minWeight")["isVisible"] && <div className="col-md-3">
                                <Field id={"minWeight"} name={"minWeight"} label={GetField("minWeight")["label"]} component={FormNumericTextBox} min={0} max={1000} />
                            </div>}                            
                            {GetField("toVolumeFactor")["isVisible"] && <div className="col-md-3">
                                <Field id={"toVolumeFactor"} name={"toVolumeFactor"} label={GetField("toVolumeFactor")["label"]} component={FormNumericTextBox} min={0} max={100} />
                            </div>}                            
                        </div>
                        <div className="row">
                            {GetField("noOfDockets")["isVisible"] && <div className="col-md-3">
                                <Field id={"noOfDockets"} name={"noOfDockets"} label={GetField("noOfDockets")["label"]} component={FormNumericTextBox} min={0} max={100} />
                            </div>}                            
                            {GetField("stockLevel")["isVisible"] && <div className="col-md-3">
                                <Field id={"stockLevel"} name={"stockLevel"} label={GetField("stockLevel")["label"]} component={FormNumericTextBox} min={0} max={10000000} />
                            </div>}                            
                            {GetField("minimumStockLevel")["isVisible"] && <div className="col-md-3">
                            <Field id={"minimumStockLevel"} name={"minimumStockLevel"} label={GetField("minimumStockLevel")["label"]} component={FormNumericTextBox} min={0} max={10000000} />
                            </div>}                            
                            {GetField("maximumStockLevel")["isVisible"] && <div className="col-md-3">
                            <Field id={"maximumStockLevel"} name={"maximumStockLevel"} label={GetField("maximumStockLevel")["label"]} component={FormNumericTextBox} min={0} max={10000000} />
                            </div>}                            
                        </div>
                        <div className="row">
                            {GetField("stockPrice")["isVisible"] && <div className="col-md-3">
                                <Field id={"stockPrice"} name={"stockPrice"} label={GetField("stockPrice")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}                            
                            {GetField("royalty")["isVisible"] && <div className="col-md-3">
                                <Field id={"royalty"} name={"royalty"} label={GetField("royalty")["label"]} component={FormNumericTextBox} min={0} max={100} format="c2" />
                            </div>}                            
                            {GetField("liftBoomGate")["isVisible"] && <div className='col-md-3'>
                                <Field id={"liftBoomGate"} name={"liftBoomGate"} label={GetField("liftBoomGate")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        <div className="row">
                            {GetField("ledgerAccount")["isVisible"] && <div className='col-md-3'>
                                <Field id={"ledgerAccount"} name={"ledgerAccount"} label={GetField("ledgerAccount")["label"]} component={FormNumericTextBox}  />
                            </div>}
                            {GetField("resourceCode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"resourceCode"} name={"resourceCode"} label={GetField("resourceCode")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("showStockLevelOnDocket")["isVisible"] && <div className='col-md-3'>
                                <Field id={"showStockLevelOnDocket"} name={"showStockLevelOnDocket"} label={GetField("showStockLevelOnDocket")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        </TabStripTab>}
                        {configData.SITE === 1 && GetField("siteDetails")["isVisible"] &&  <TabStripTab title={GetField("siteDetails")["label"]}>
                            <div className="row">
                                {GetField("siteDetails")["isVisible"] && <div className="col-md-6"><h5>{GetField("siteDetails")["label"]}</h5></div>}
                            </div>                                        
                            <div className='row' >
                                {GetField("siteDetails")["isVisible"] && <div className='col-md-6'>
                                <Field component={MultiSelectTreeEditor} name={"sourceSites"} data={sitesTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                                </div>}
                            </div>                        </TabStripTab>}
                        {GetField("priceSchedule")["isVisible"] &&  <TabStripTab title={GetField("priceSchedule")["label"]}>
                            <div className="row">
                                {GetField("schedulePriceDate")["isVisible"] && <div className="col-md-3"> 
                                    <Field id={"schedulePriceDate"} name={"schedulePriceDate"} label={GetField("schedulePriceDate")["label"]} component={FormDatePicker}
                                    wrapperStyle={{ width: "90%", marginRight: "18px",}}/>
                                </div>}
                            </div>
                            <div className="row">
                                {GetField("scheduleLocalDiscountIn")["isVisible"] && <div className="col-md-3">
                                    <Field id={"scheduleLocalDiscountIn"} name={"scheduleLocalDiscountIn"} label={GetField("scheduleLocalDiscountIn")["label"]} component={FormNumericTextBox} min={0} max={10000} format="c2"/>
                                </div>}                            
                                {GetField("scheduleLocalDiscountOut")["isVisible"] && <div className="col-md-3">
                                    <Field id={"scheduleLocalDiscountOut"} name={"scheduleLocalDiscountOut"} label={GetField("scheduleLocalDiscountOut")["label"]} component={FormNumericTextBox} min={0} max={10000} format="c2"/>
                                </div>}                            
                                {GetField("scheduleLocalMinimum")["isVisible"] && <div className="col-md-3">
                                    <Field id={"scheduleLocalMinimum"} name={"scheduleLocalMinimum"} label={GetField("scheduleLocalMinimum")["label"]} component={FormNumericTextBox} min={0} max={10000} format="c2"/>
                                </div>}                            
                            </div>
                            <div className="row">
                                {GetField("scheduleVisitIn")["isVisible"] && <div className="col-md-3">
                                    <Field id={"scheduleVisitIn"} name={"scheduleVisitIn"} label={GetField("scheduleVisitIn")["label"]} component={FormNumericTextBox} min={0} max={10000} format="c2"/>
                                </div>}                            
                                {GetField("scheduleVisitOut")["isVisible"] && <div className="col-md-3">
                                    <Field id={"scheduleVisitOut"} name={"scheduleVisitOut"} label={GetField("scheduleVisitOut")["label"]} component={FormNumericTextBox} min={0} max={10000} format="c2"/>
                                </div>}                            
                                {GetField("scheduleVisitMinimum")["isVisible"] && <div className="col-md-3">
                                    <Field id={"scheduleVisitMinimum"} name={"scheduleVisitMinimum"} label={GetField("scheduleVisitMinimum")["label"]} component={FormNumericTextBox} min={0} max={10000} format="c2"/>
                                </div>}                            
                            </div>
                            <div className="row">
                                {GetField("scheduledEPALevy")["isVisible"] && <div className="col-md-3">
                                    <Field id={"scheduledEPALevy"} name={"scheduledEPALevy"} label={GetField("scheduledEPALevy")["label"]} component={FormNumericTextBox} min={0} max={10000} format="c2"/>
                                </div>}                            
                                {GetField("scheduleEPAMinimum")["isVisible"] && <div className="col-md-3">
                                    <Field id={"scheduleEPAMinimum"} name={"scheduleEPAMinimum"} label={GetField("scheduleEPAMinimum")["label"]} component={FormNumericTextBox} min={0} max={10000} format="c2"/>
                                </div>}                            
                            </div>
                        </TabStripTab>}
                        </TabStrip>
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Cancel</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditProducts