import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate, useParams } from "react-router-dom";
import configData from "./config.json";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }
     
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        const GoBack = () => Redirect("");
        
        const Redirect = (msg = "") => {
            const navigate = useNavigate();
            navigate("/trucks", { state: { msg: msg } });
        }
        if (this.state.errorInfo) {
            // Error path
            return (
                <div style={{padding:"40px"} }>
                    <div class="error">Sorry! for your inconvenience there is an  error loading this page please see the details below</div>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                    <Button themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" ><a href={configData.SERVER_URL} >Go Back</a></Button>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;