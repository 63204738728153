import * as React from "react";
import { TaskBoardCard, TaskBoardCardHeader, TaskBoardCardBody } from "@progress/kendo-react-taskboard";
import { ConfirmDialog } from "./confirmDialog";
import { PreviewDialog } from "./previewDialog";

export const MyTaskBoardCard = (props) => {
  return (
    <>
      <TaskBoardCard {...props}>
        <TaskBoardCardHeader {...props} />
        <TaskBoardCardBody {...props} />
      </TaskBoardCard>
      {props.showDeleteConfirm && (
        <ConfirmDialog
          onConfirm={props.onTaskDelete}
          onClose={props.onCloseConfirmDialog}
          dialogMessage={props.confirmDialogMessage}
          dialogTitle={props.confirmDialogTitle}
          dialogConfirmButton={props.confirmDialogConfirmButton}
          dialogCancelButton={props.confirmDialogCancelButton}
        />
      )}
      {props.showTaskPreviewPane && (
        <PreviewDialog
          title={props.task.title}
          description={props.task.description}
          priorityLabel={props.previewDialogPriorityLabel}
          delete={props.previewDialogDelete}
          edit={props.previewDialogEdit}
          onClosePreviewPane={props.onClosePreviewPane}
          onTaskDelete={props.onTaskDelete}
          onTaskEdit={props.onTaskEdit}
          priority={props.task.priority}
        />
      )}
    </>
  );
};
export default MyTaskBoardCard;