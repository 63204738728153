import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormTextArea, FormDropDownList } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState  } from "../components/multiselecttree-data-operations";
import { MultiSelectTreeEditor } from "../components/MultiSelectTreeEditor";

const EditUsers = () => {
    const { id } = useParams(); // editusers/id url is used to edit existing user, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [roles, setRoles] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [states, setStates] = React.useState([ ]);
    const [customers, setCustomers] = React.useState([]);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [permissions, setPermissions] = React.useState(null);
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "roleId", "label": "Role", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "name", "label": "User Login Name", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "password", "label": "Password", "defaultValue": "", "isVisible": true, "type": "password" },
        { "field": "fullName", "label": "Full Name", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "position", "label": "Position (eg Supervisor, Operator)", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "description", "label": "Comment", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "address1", "label": "Address 1", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "address2", "label": "Address 2", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "suburb", "label": "Suburb", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "state", "label": "State", "defaultValue": "NSW", "isVisible": true, "type": "text"},
        { "field": "postcode", "label": "Postcode", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "phone", "label": "Phone", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "mobile", "label": "Mobile", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "email", "label": "Email", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "country", "label": "Country", "defaultValue": "", "isVisible": false, "type": "text" },
        { "field": "addressDetails", "label": "Address and contact details", "defaultValue": "", "isVisible": true, "type": "heading" },
        { "field": "userDetails", "label": "User", "defaultValue": "", "isVisible": true, "type": "heading" },
        { "field": "siteDetails", "label": "Site details", "defaultValue": "", "isVisible": true, "type": "heading" },
        { "field": "copyFrom", "label": "Copy settings from", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean"},
        { "field": "isExternalUser", "label": "External User", "defaultValue": "false", "isVisible": true, "type": "boolean" },
        { "field": "customerId", "label": "Customer", "defaultValue": "", "isVisible": true, "type": "list" },
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        roleId: GetField("roleId")["defaultValue"],
        name: GetField("name")["defaultValue"],
        password: GetField("password")["defaultValue"],
        fullName: GetField("fullName")["defaultValue"],
        position: GetField("position")["defaultValue"],
        description: GetField("description")["defaultValue"],
        address1: GetField("address1")["defaultValue"],
        address2: GetField("address2")["defaultValue"],
        suburb: GetField("suburb")["defaultValue"],
        state: GetField("state")["defaultValue"],
        postcode: GetField("postcode")["defaultValue"],
        phone: GetField("phone")["defaultValue"],
        mobile: GetField("mobile")["defaultValue"],
        email: GetField("email")["defaultValue"],
        country: GetField("country")["defaultValue"],
        addressDetails: GetField("addressDetails")["defaultValue"],
        userSites: [],        
        siteDetails: GetField("siteDetails")["defaultValue"],
        copyFrom: GetField("copyFrom")["defaultValue"],
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vehicleconfigurations
    React.useEffect(() => {
        GetPermissions(["ViewUser", "NewUser", "EditUser"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditUsers'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            let allSites = GetList(opt.sites);
            let defSites = ConfigureSites(opt.sites, opt.inactiveSites, allSites);
            setRoles(opt.roles)
            setStates(opt.states);
            setCustomers(opt.customers);
            let cf = {id: '0', name: "Don't copy settings"};
            let ul = [cf, ...opt.users];
            setUsers(ul);
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/users/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var role = opt.roles.find(obj => { return obj.id === String(d.roleId) });
                    let sites = ConfigureSites(opt.sites, opt.inactiveSites, d.siteList);
                    let cst = opt.customers.find(obj => { return obj.id === String(d.customerId) });
                    setValues({
                        roleId: d.roleId === null ? "" : role,
                        name: d.name === null ? "" : d.name,
                        password: d.password === null ? "" : d.password,
                        fullName: d.fullName === null ? "" : d.fullName,
                        position: d.position === null ? "" : d.position,
                        description: d.description === null ? "" : d.description,
                        address1: d.address1 === null ? "" : d.address1,
                        address2: d.address2 === null ? "" : d.address2,
                        suburb: d.suburb === null ? "" : d.suburb,
                        state: d.state === null ? "" : d.state,
                        postcode: d.postcode === null ? "" : d.postcode,
                        phone: d.phone === null ? "" : d.phone,
                        mobile: d.mobile === null ? "" : d.mobile,
                        email: d.email === null ? "" : d.email,
                        country: d.country === null ? "" : d.country,
                        addressDetails: d.addressDetails === null ? "" : d.addressDetails,
                        siteDetails: d.siteDetails === null ? "" : d.siteDetails,
                        copyFrom: cf,
                        userSites: sites,
                        active: d.isActive === null ? "" : d.isActive,
                        isExternalUser: d.isExternalUser,
                        customerId: d.customerId === null ? "" : cst
                    });
                    setFormkey(formkey + 1);
                })
            } else {
                var v = {...values};
                v.userSites = defSites;
                setValues(v);                
                setFormkey(formkey + 1);
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (f === undefined) f = "";
        if (fld === undefined || fld === null) return f;
        let res = fld["defaultValue"];
        if (fld["isVisible"]) res = f;
        switch(fld["type"]){
            case "numeric": 
                res = parseFloat(res); 
                if (isNaN(res)) res = 0;
                break;
            case "boolean": res = (res === "true" || res === true); break;
            case "date":
                if (res === "") res = null;
                break;
            default: break;
        }
        return res;
    }

    const GetDropdownData = (f, name) => {
        let res = 0;
        if (f === undefined || f.id === undefined) 
            res = GetDefaultData('', name);
        else
            res = f.id;
        return parseInt(res);
    }

    const [sitesData, setSitesData] = React.useState([{id:0, text: "Active", items: []}]);
    const dataItemKey = "id";
    const checkField = "checkField";
    const checkIndeterminateField = "checkIndeterminateField";
    const subItemsField = "items";
    const expandField = "expanded";
    const textField = "text";
    const fields = { dataItemKey, checkField, checkIndeterminateField, expandField, subItemsField };

    const [sitesValue, setSitesValue] = React.useState([]);
    const [siteExpanded, setSiteExpanded] = React.useState([]);
    const onSitesChange = (event) => {
        setSitesValue(getMultiSelectTreeValue(sitesData, {...fields, ...event, value: sitesValue, }));
    }
    const onSitesExpandChange = React.useCallback((event) => setSiteExpanded(expandedState(event.item, dataItemKey, siteExpanded)), [siteExpanded]);
    const sitesTreeData = React.useMemo(() => processMultiSelectTreeData(sitesData, { expanded: siteExpanded, value: sitesValue, ...fields }), [siteExpanded, sitesValue]);
    const ConfigureSites = (act, dis, siteList) => {
        if (siteList === null) return;
        let slsp = siteList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            let inactive = {id: 999999, text: 'Inactive', items: sl };
            setSitesData([active, inactive]);
        }
        else {
            setSitesData([active]);
        }
        setSitesValue([...selected]);       
        return [...selected];
    }

    const GetList = (obj) => {
        if (obj === undefined || obj.length === 0) return "";
        var l = "";
        for (let i = 0; i < obj.length; i++){
            let id = obj[i].id;
            if (id !== 0 && id !== 999990){
                l += id;
                if (i < obj.length - 1) l += ",";
            }
        }
        return l;
    }


    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var siteList = GetList(dataItem.userSites);
        var url = `${configData.SERVER_URL}apiv7/users${pth}`;
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "userId": id === undefined ? 0 : id,
            "roleId": GetDropdownData(dataItem.roleId, "roleId"),
            "name": GetDefaultData(dataItem.name, "name"),
            "password": GetDefaultData(dataItem.password, "password"),
            "fullName": GetDefaultData(dataItem.fullName, "fullName"),
            "position": GetDefaultData(dataItem.position, "position"),
            "description": GetDefaultData(dataItem.description, "description"),
            "address1": GetDefaultData(dataItem.address1, "address1"),
            "address2": GetDefaultData(dataItem.address2, "address2"),
            "suburb": GetDefaultData(dataItem.suburb, "suburb"),
            "state": GetDefaultData(dataItem.state, "state"),
            "postcode": GetDefaultData(dataItem.postcode, "postcode"),
            "phone": GetDefaultData(dataItem.phone, "phone"),
            "mobile": GetDefaultData(dataItem.mobile, "mobile"),
            "email": GetDefaultData(dataItem.email, "email"),
            "country": GetDefaultData(dataItem.country, "country"),
            "siteList": siteList,
            "isActive": GetDefaultData(dataItem.active, "active"),
            "copyFrom": GetDropdownData(dataItem.copyFrom, "copyFrom"),
            "isExternalUser": GetDefaultData(dataItem.isExternalUser, "isExternalUser"),
            "customerId": GetDropdownData(dataItem.customerId, "customerId"),
           })
        }
        fetch(url, init).then(response => response).then(d => { 
            var msg = id === undefined ? "Operator created" : "Operator updated";
            if (d.status > 299) msg = `Error ${d.status} creating Operator`;
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/users", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) => value ? "" : "Please select an option";
    const clearFilters = () => {
        var url = `${configData.SERVER_URL}apiv7/private/deleteuserfilters?userid=${id}`;
        const init = {
            method: 'DELETE',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache"
        }
    }
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "95%"}} >
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("userDetails")["isVisible"] && <TabStripTab title={GetField("userDetails")["label"]}>
                        <div className="row">
                            {GetField("roleId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"roleId"} name={"roleId"} label={GetField("roleId")["label"]} component={FormDropDownList} data={roles} textField="name" dataItemKey="id" validator={optionValidator} />
                            </div>}                            
                            {GetField("copyFrom")["isVisible"] && <div className='col-md-3'>
                                <Field id={"copyFrom"} name={"copyFrom"} label={GetField("copyFrom")["label"]} component={FormDropDownList} data={users} textField="name" dataItemKey="id"/>
                            </div>}                           
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("password")["isVisible"] && <div className='col-md-3'>
                                <Field id={"password"} name={"password"} label={GetField("password")["label"]} type="password" component={FormInput} validator={textValidator} />
                            </div>}                            
                            {GetField("isExternalUser")["isVisible"] && <div className='col-md-2'>
                                <Field id={"isExternalUser"} name={"isExternalUser"} label={GetField("isExternalUser")["label"]} component={FormSwitch} />
                            </div>}
                            {GetField("customerId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"customerId"} name={"customerId"} label={GetField("customerId")["label"]} component={FormDropDownList} data={customers} textField="name" dataItemKey="id" />
                            </div>}                            
                        </div>
                        <div className='row' >
                            {GetField("fullName")["isVisible"] && <div className="col-md-3">
                                <Field id={"fullName"} name={"fullName"} label={GetField("fullName")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("position")["isVisible"] && <div className="col-md-3">
                                <Field id={"position"} name={"position"} label={GetField("position")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("description")["isVisible"] && <div className="col-md-3">
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormTextArea}/>
                            </div>}
                            <div className='col-md-1' style={{ paddingTop: 50 }}>
                                <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={clearFilters}>Clear user filter</Button>
                            </div>
                        </div>
                        </TabStripTab>}
                        {GetField("addressDetails")["isVisible"] && <TabStripTab title={GetField("addressDetails")["label"]}>
                        <div className='row' >
                            {GetField("address1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address1"} name={"address1"} label={GetField("address1")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("address2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address2"} name={"address2"} label={GetField("address2")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("suburb")["isVisible"] && <div className='col-md-3'>
                                <Field id={"suburb"} name={"suburb"} label={GetField("suburb")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("state")["isVisible"] && <div className='col-md-3'>
                                <Field id={"state"} name={"state"} label={GetField("state")["label"]} component={FormDropDownList} data={states} />
                            </div>}                           
                        </div>
                        <div className='row' >
                            {GetField("postcode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"postcode"} name={"postcode"} label={GetField("postcode")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("phone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"phone"} name={"phone"} label={GetField("phone")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("mobile")["isVisible"] && <div className='col-md-3'>
                                <Field id={"mobile"} name={"mobile"} label={GetField("mobile")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("email")["isVisible"] && <div className='col-md-3'>
                                <Field id={"email"} name={"email"} label={GetField("email")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("country")["isVisible"] && <div className='col-md-3'>
                                <Field id={"country"} name={"country"} label={GetField("country")["label"]} component={FormInput}  />
                            </div>}
                        </div>      
                        </TabStripTab>}
                        {GetField("siteDetails")["isVisible"] && <TabStripTab title={GetField("siteDetails")["label"]}>
                        <div className="row">
                            {GetField("siteDetails")["isVisible"] && <div className="col-md-6"><h5>{GetField("siteDetails")["label"]}</h5></div>}
                        </div>                                        
                        <div className='row' >
                            {GetField("siteDetails")["isVisible"] && <div className='col-md-6'>
                            <Field component={MultiSelectTreeEditor} name={"userSites"} data={sitesTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                  
                            </div>}
                        </div>
                        </TabStripTab>}
                        </TabStrip>                        
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Cancel</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditUsers