import * as React from 'react';
import Notifications from '../components/Notifications';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";

const Users = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");

    React.useEffect(() => { 
        GetColumnDefs("Users", setColumns, setErrorMessage); 
        GetPermissions(["ViewUser", "NewUser", "EditUser", "DeleteUser", "ReportUser"], setPermissions);
    }, [])

    return (
        <div>
        <Notifications errorMessage={errorMessage}/>
        {columns && permissions && <DataGrid dataSource='apiv7/users' columns={columns} permissions={permissions} keyField ="userId" />}
        </div>       
    );

}

export default Users;