import * as React from 'react';
import { MultiSelectTree, getMultiSelectTreeValue } from '@progress/kendo-react-dropdowns';
import { processMultiSelectTreeData, expandedState  } from './multiselecttree-data-operations';
  
export const dataItemKey = 'id';
export const checkField = 'checkField';
export const checkIndeterminateField = 'checkIndeterminateField';
export const subItemsField = 'items';
export const expandField = 'expanded';
export const textField = 'text';
export const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};

export const MultiSelectTreeEditor = (fieldRenderProps) => {
  const {
    validationMessage,
    visited,
    data,
    dataItemKey,
    checkField,
    checkIndeterminateField,
    expandField,
    subItemsField,
    ...others
  } = fieldRenderProps;
  const [value, setValue] = React.useState(fieldRenderProps.value);
  const [expanded, setExpanded] = React.useState([data[0]['id']]);
  const treeData = React.useMemo(
    () =>
      processMultiSelectTreeData(data, {
        expanded,
        value: fieldRenderProps.value,
        ...fields,
      }),
    [expanded, value]
  );
    const onChange = (event) => {
    let newValue = getMultiSelectTreeValue(treeData, {
      ...fields,
      ...event,
      value: fieldRenderProps.value !== null ? fieldRenderProps.value : [],
    });
    console.log(newValue)
    if (newValue.length == 0) {
      console.log(newValue)
      newValue = null;
    }

    setValue(newValue);
    fieldRenderProps.onChange({ value: newValue });
  };

  const onExpandChange = React.useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );
  return (
    <div>
      <MultiSelectTree
        value={fieldRenderProps.value}
        data={treeData}
        onChange={onChange}
        textField={textField}
        dataItemKey={dataItemKey}
        checkField={checkField}
        checkIndeterminateField={checkIndeterminateField}
        subItemsField={subItemsField}
        expandField={expandField}
        onExpandChange={onExpandChange}
      />
      {visited && validationMessage && (
        <div role="alert" className="k-form-error k-text-start">
          {validationMessage}
        </div>
      )}
    </div>
  );
};

