import * as React from 'react';
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDropDownList, FormInput, FormNumericTextBox } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { GetPermissions } from '../components/Options';

const EditSupport = () => {
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [permissions, setPermissions] = React.useState(null);
    const [types, setTypes] = React.useState([]);
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        {"field": "type", "label": "Support Type", "defaultValue": "", "isVisible": true, "type": "list", "format": "" },
        {"field": "description", "label": "Description", "defaultValue": "", "isVisible": true, "type": "text", "format": ""},
        {"field": "hoursAllocated", "label": "Hours Allocated", "defaultValue": 0, "isVisible": true, "type": "numeric", "format": ""},
        {"field": "hoursUsed", "label": "Hours Used", "defaultValue": 0, "isVisible": true, "type": "numeric", "format": ""}
      ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        type: GetField("type")["defaultValue"],
        description: GetField("description")["defaultValue"],
        hoursAllocated: GetField("hoursAllocated")["defaultValue"],
        hoursUsed: GetField("hoursUsed")["defaultValue"]
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    React.useEffect(() => {
        GetPermissions(["ViewSupport", "NewSupport"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=EditSupport`;
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setTypes(opt.supportTypes);
            setFormkey(new Date());
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var url = `${configData.SERVER_URL}apiv7/support`;
        var utcDate = new Date().toISOString();
        const init = {
          method: 'POST',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "type": GetDefaultData(dataItem.type, "type"),
            "description":  GetDefaultData(dataItem.description, "description"),
            "logDate": utcDate,
            "hoursAllocated": GetDefaultData(dataItem.hoursAllocated, "hoursAllocated"),
            "hoursUsed": GetDefaultData(dataItem.hoursUsed, "hoursUsed")
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = "New support created";
            if (d.status > 299) msg = `Error ${d.status} creating Support`;
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/support", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/");
    if (permissions && !permissions.CanCreate) Redirect("Error: You do not have permission to create support");

    // form validators
    const regoValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) =>  value ? "" : "Please select an option";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                            {GetField("type")["isVisible"] && <div id="type" className='col-md-3'>
                                <Field id={"type"} name={"type"} label={GetField("type")["label"]} data={types} component={FormDropDownList} validator={optionValidator} />
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("description")["isVisible"] && <div className='col-md-6'>
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormInput}/>
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("hoursAllocated")["isVisible"] && <div className='col-md-3'>
                                <Field id={"hoursAllocated"} name={"hoursAllocated"} label={GetField("hoursAllocated")["label"]} component={FormNumericTextBox}/>
                            </div>}
                            {GetField("hoursUsed")["isVisible"] && <div className='col-md-3'>
                                <Field id={"hoursUsed"} name={"hoursUsed"} label={GetField("hoursUsed")["label"]} component={FormNumericTextBox} />
                            </div>}
                        </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>Create</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditSupport