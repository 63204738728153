import * as React from "react";
import * as ReactDOM from "react-dom";
import { GetToken } from '../../components/Session';
import configData from "../../config.json";

import {
  StockChart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartPanes,
  ChartPane,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartNavigator,
  ChartNavigatorSelect,
  ChartNavigatorSeries,
  ChartNavigatorSeriesItem,
} from "@progress/kendo-react-charts";
import "hammerjs";

const TonPerVolume = () => {
  const [stockData, setStockData] = React.useState([]);

  React.useEffect(() => {
    var url = configData.SERVER_URL + "apiv7/dashboard/getstockdata";
    var t = GetToken();
    const init = {
      method: 'GET',
      accept: 'application/json',
      headers: {"Authorization": "Bearer " + t}
    }
    fetch(url, init).then(response => response.json()).then(d => {
      if (d !== null){
        for (var i = 0; i < d.length; i++) {
          d[i]["date"] = new Date(d[i]["date"]);
        }
        setStockData(d);
      }
    })
  }, []);

  return (
    <div>
  {stockData && <StockChart
    style={{
      height: 800,
    }}
  >
    <ChartTitle text="Live Performance" font="22Pt bold" color="#00008B" />
    <ChartSeries>
      <ChartSeriesItem
        data={stockData}
        type="line"
        field="cost"
        categoryField="date"
      />
      <ChartSeriesItem
        data={stockData}
        type="column"
        field="volume"
        axis="volumeAxis"
        categoryField="date"
        tooltip={{
          format: "{0:C0}",
        }}
      />
    </ChartSeries>
    <ChartValueAxis>
      <ChartValueAxisItem />
      <ChartValueAxisItem visible={false} name="volumeAxis" pane="volumePane" />
    </ChartValueAxis>
    <ChartCategoryAxis>
      <ChartCategoryAxisItem pane="volumePane" />
    </ChartCategoryAxis>
    <ChartPanes>
      <ChartPane title="Value" height={350} />
      <ChartPane
        title="Volume"
        name="volumePane"
        height={150}
        tooltip={{
          format: "{0:C0}",
        }}
      />
    </ChartPanes>
  </StockChart>}
  </div>)
  };

export default TonPerVolume;
