import * as React from 'react';
import Notifications from '../components/Notifications';
import DataGrid from '../components/DataGrid';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
import { MultiSelect, DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { GetCloudLogin } from "../WeighBridge/Helpers";

const Transactions = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [sites, setSites] = React.useState([]);
    const [site, setSite] = React.useState([]);
    const [loadTypes, setLoadTypes] = React.useState([]);
    const [loadType, setLoadType] = React.useState([]);
    const [jobs, setJobs] = React.useState([]);
    const [job, setJob] = React.useState([]);
    const [rego, setRego] = React.useState();
    const [docket, setDocket] = React.useState();
    const [customers, setCustomers] = React.useState([]);
    const [customer, setCustomer] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [product, setProduct] = React.useState([]);
    const [statii, setStatii] = React.useState([]);
    const [status, setStatus] = React.useState();
    const ready = React.useRef(false);
    const timeout = React.useRef(false);
    const navigate = useNavigate();
    const delay = 500;
    const [productdata, setproductData] = React.useState();
    const [customerdata, setcustomerData] = React.useState();
    const [jobdata, setjobData] = React.useState();
    const cloudToken = React.useRef("");
    const options = React.useRef();
    
    if (permissions && !permissions.CanView) navigate("/error401");

    const [layout, setLayout] = React.useState([
      { "field": "searchAdvanced", "label": "Advanced Search", "isVisible": true, "type": "heading" },
      { "field": "searchSite", "label": "Site", "isVisible": true, "type": "list" },
      { "field": "searchRego", "label": "Rego", "isVisible": true, "type": "text" },
      { "field": "searchDocket", "label": "Docket", "isVisible": true, "type": "text" },
      { "field": "searchLoadType", "label": "Load Type", "isVisible": true, "type": "list" },
      { "field": "searchJob", "label": "Job", "isVisible": true, "type": "list" },
      { "field": "searchProduct", "label": "Product", "isVisible": true, "type": "list" },
      { "field": "searchCustomer", "label": "Customer", "isVisible": true, "type": "list" },
      { "field": "searchStatus", "label": "Status", "isVisible": true, "type": "list" }
    ]);

    const [dateRange, setDateRange] = React.useState();
    const [lookupParams, setLookupParams] = React.useState("");

    let dt = new Date();
    let dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 59) };
    let td = localStorage.getItem("TransactionDates");
    
    const GetField = (f) => {
      return layout.find(obj => { return obj.field === f });
    }

    React.useEffect(() => { 
        GetCloudLogin((cred) => {
            cloudToken.current = cred.token;
        });
        GetColumnDefs("Transactions", setColumns, setErrorMessage); 
        GetPermissions(["ViewTransactionsPage", "NewTransaction", "EditTransaction", "DeleteTransaction", "ReportTransaction", "PrintTransaction"], setPermissions);
        let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'Transactions'";
        const init = {
        method: "GET",
        accept: "application/json",
        headers: {"Authorization": "Bearer " + GetToken()},
        };
        fetch(baseUrl, init).then(response => response.json()).then(d => { 
          if (GetSession().IsMobile){
              let data = [];
              for(let i = 0; i < d.length; i++){
                  data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
              }
              setLayout(data);
          } else {
              setLayout(d); 
          }
        });       
        baseUrl = configData.SERVER_URL + "apiv7/options";
        fetch(baseUrl, init)
        .then((response) => response.json())
            .then((d) => {
            options.current = d;
            setLoadTypes(d.loadTypes);
            setJobs(CreateList(d.jobs));
            setjobData(d.jobs.slice())
            setCustomers(CreateList(d.customers));
            setcustomerData(d.customers.slice())
            setSites(d.sites);
            setProducts(CreateList(d.products));
            setproductData(d.products.slice())
            setStatii(d.status);
            ClearParams();
            ready.current = true;
            setDateRange(dr);
            DoSearch(dr);
            if (td !== undefined && td !== "") {
                 setExpanded(true);
                 setTimeout(() => { UpdateSavedParams() }, 100);
            }
        });
    }, [])

    const CreateList = (d) => {
        var x = [{ id: "1", name: "NA" }];
        return x.concat(d);
      };

    const rangeChanged = (event) => { 
        let dr = event.target.value;
        setDateRange(dr); 
    }

    const UpdateSavedParams = () => {
        let def = JSON.parse(td);
        let st = GetSelected(options.current.sites, def.Site); 
        setSite(st);
        let lt = [];
        if (def.LoadType !== "" && def.LoadType !== undefined ) lt = def.LoadType.split(';');
        setLoadType(lt);
        setRego(def.Rego);
        setDocket(def.Docket);
        let j = GetSelected(options.current.jobs, def.Job);
        setJob(j);
        let c = GetSelected(options.current.customers, def.Customer);
        setCustomer(c);
        let p = GetSelected(options.current.products, def.Product);
        setProduct(p);
        dr = { start: new Date(def.FromDate), end: new Date(def.ToDate) };
        setDateRange(dr);
        var truckParam = {
            FromDate: dr.start.toISOString(),
            ToDate: dr.end.toISOString(),
            Rego: def.Rego,
            Docket: def.Docket,
            LoadType: GetLoadTypes(lt),
            Job: GetIds(j),
            Customer: GetIds(c),
            Site: GetIds(st),
            Product: GetIds(p),
            Status: status
        };
        setLookupParams(encodeURI(JSON.stringify(truckParam)));
    }

    const GetSelected = (c, d) => {
        let r = [];
        if (d === undefined || d === null || d === "0") return r;
        let sp = d.split(';');
        sp.forEach(s => {
            r.push(c.find(obj => { return obj.id === s }));
        });
        return r;
    }

    const exportRecord = (rec) => {
        var url = `${configData.SERVER_URL}apiv7/private/getdocketpdf?transactionid=${rec.transactionId}`;
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.blob()).then(d => { 
            var fileURL = URL.createObjectURL(d);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = `docket_${rec.docket}.pdf`;
            fileLink.click();
        });
    }

    const bulkExport = (rec) => {
        var url = `${configData.SERVER_URL}apiv7/private/getdocketzip?ids=${rec}`;
        const init = {
          method: 'GET',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.blob()).then(d => { 
            var fileURL = URL.createObjectURL(d);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = `docket.zip`;
            fileLink.click();
        });
    }

    const loadTypeChanged = (event) => {
        setLoadType(event.target.value);
    };
    const jobChanged = (event) => {
        setJob(event.target.value);
    };
    const customerChanged = (event) => {
        setCustomer(event.target.value);
    };
    const siteChanged = (event) => {
        setSite(event.target.value);
    };
    const productChanged = (event) => {
        setProduct(event.target.value);
    };
    const statusChanged = (event) => {
        setStatus(event.target.value);
    };
    const regoChanged = (event) => {
        setRego(event.target.value);
    }
    const docketChanged = (event) => {
        setDocket(event.target.value);
    }

    const searchClicked = () => {
        let tp = DoSearch(dateRange);
        localStorage.setItem("TransactionDates", JSON.stringify(tp));
    }

    const DoSearch = (dt) => {
        var truckParam = {
            FromDate: dt.start === null ? "2000-01-01T00:00:00Z" : dt.start.toISOString(),
            ToDate: dt.end === null ? "2050-01-01T00:00:00Z" : dt.end.toISOString(),
            Rego: rego === undefined ? "" : rego,
            Docket: docket === undefined ? "" : docket,
            LoadType: loadType === undefined ? "0" : GetLoadTypes(loadType),
            Job: job == undefined ? "0" : GetIds(job),
            Customer: customer == undefined ? "0" : GetIds(customer),
            Site: site === undefined ? "0" : GetIds(site),
            Product: product == undefined ? "0" : GetIds(product),
            Status: status == undefined ? "Active" : status
        };       
        setLookupParams(encodeURI(JSON.stringify(truckParam)));
        return truckParam;
    }

    const GetIds = (d) => {
        if (d.length === undefined || d.length === 0) return "0";
        let r = "";
        d.map(x => {
            r += (r.length > 0 ? ";" : "") + x.id;
        });
        return r;
    }

    const GetLoadTypes = (loadType) => {
        if (loadType === "" || loadType === undefined) return "";
        let r = "";
        loadType.map(x => {
            r += (r.length > 0 ? ";" : "") + x;
        })
        return r;
    }

    const ClearSearch = (dr) => {
        if (ready.current) {
            ClearParams();
            var truckParam = {
                FromDate: dr.start.toISOString(),
                ToDate: dr.end.toISOString(),
                Rego: "",
                Docket: "",
                LoadType: "",
                Job: "0",
                Customer: "0",
                Site: "0",
                Product: "0",
                Status: "Active"
            };
            setLookupParams(encodeURI(JSON.stringify(truckParam)));
            localStorage.setItem("TransactionDates", "");
        }
    }

    const clearClicked = () => {
        let dt = new Date();
        dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 59) };
        ClearSearch(dr);
        DoSearch(dr);
    }

    const ClearParams = () => {
        setRego("");
        setDocket("");
        setDateRange(dr);
        setLoadType([]);
        setJob([]);
        setCustomer([]);
        setSite([]);
        setProduct([]);
        setStatus("Active");
    };
  
    const filterproductData = (filter) => {
        const productdata = products.slice();
        return filterBy(productdata, filter);
    };
    const filterprodChange = (event) => {
        setproductData(filterproductData(event.filter));
    };

    const filterjobtData = (filter) => {
        const jobdata = jobs.slice();
        return filterBy(jobdata, filter);
    };
    const filterjobChange = (event) => {
        setjobData(filterjobtData(event.filter));
    };

    const filtercustomerData = (filter) => {
        const customerdata = customers.slice();
        return filterBy(customerdata, filter);
    };
    const filtercustChange = (event) => {
        setcustomerData(filtercustomerData(event.filter));
    };


    return (
        <div>
        <Notifications errorMessage={errorMessage}/>
        {GetField("searchAdvanced")["isVisible"] && <ExpansionPanel title={GetField("searchAdvanced")["label"]}  expandIcon="fa fa-plus" collapseIcon="fa fa-minus" expanded={expanded} onAction={(e) => setExpanded(!e.expanded)}>
        <Reveal>
          {expanded && (
           <ExpansionPanelContent >
             <div >
              <div className="row" style={{ padding: "5px 10px" }}>
                {GetField("searchSite")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchSite")["label"]}</label>
                    <MultiSelect id="Site" data={sites} textField="name" dataItemKey="id" value={site} onChange={siteChanged} />
                </div>}
                {GetField("searchRego")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchRego")["label"]}</label>
                    <TextBox id="Rego" type="text" value={rego} onChange={regoChanged} />
                </div>}
                {GetField("searchDocket")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchDocket")["label"]}</label>
                    <TextBox id="Docket" type="text" value={docket} onChange={docketChanged} />
                </div>}
                {GetField("searchLoadType")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchLoadType")["label"]}</label>
                    <MultiSelect id="LoadType" data={loadTypes} value={loadType} onChange={loadTypeChanged} />
                </div>}
                <div className="col-md-3">
                    <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged}  />
                </div>
              </div>
              <div className="row" style={{ padding: "5px 10px" }}>
                
                
                
                {GetField("searchJob")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchJob")["label"]}</label>
                    <MultiSelect onFilterChange={filterjobChange} textField="text" filterable={true} id="Job" data={jobdata} value={job} textField="name" dataItemKey="id" onChange={jobChanged} />
                </div>}
                {GetField("searchProduct")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchProduct")["label"]}</label>
                    <MultiSelect onFilterChange={filterprodChange} textField="text" filterable={true} id="Product" data={productdata} value={product} textField="name" dataItemKey="id" onChange={productChanged} />
                </div>}
                {GetField("searchCustomer")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchCustomer")["label"]}</label>
                    <MultiSelect id="Customer" onFilterChange={filtercustChange} textField="text" filterable={true} data={customerdata} value={customer} textField="name" dataItemKey="id" onChange={customerChanged} />
                </div>}
                {GetField("searchStatus")["isVisible"] && <div className="col-md-2">
                    <label className="control-label">{GetField("searchStatus")["label"]}</label>
                    <DropDownList id="Status" data={statii} value={status} onChange={statusChanged} />
                </div>}
                                 
                <div className="col-md-3" style={{ padding: "25px 10px" }}>
                    <Button onClick={searchClicked} themeColor={"primary"}>Search</Button>{" "}
                    <Button onClick={clearClicked} themeColor={"primary"}>Clear</Button>
                </div>
              
              </div>
            </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
        </ExpansionPanel>}
            {columns && permissions && <DataGrid dataSource='apiv7/transactions' viewName='Transactions' exportRecord={exportRecord} bulkExport={bulkExport} columns={columns} permissions={permissions} params={lookupParams} keyField="transactionId" cloudToken={cloudToken.current} />}
        </div>       
    );

}

export default Transactions;