import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement, FieldArray } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormTextArea, FormNumericTextBox, FormDropDownList, FormMultiSelect } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Label } from '@progress/kendo-react-labels';
import { filterBy } from "@progress/kendo-data-query";
import { DatePickerPropsContext } from '@progress/kendo-react-dateinputs';
import { DropDownList, getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState  } from "../components/multiselecttree-data-operations";
import { MultiSelectTreeEditor } from "../components/MultiSelectTreeEditor";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import $  from "jquery"
import { TextBox } from '@progress/kendo-react-inputs';
import { GetPermissions } from '../components/Options';

export const FormGridEditContext = React.createContext({});

const DeleteCell = (props) => {
    const {dataItem} = props;
    return (
        <td>
          <Button icon="delete" onClick={() => props.remove(dataItem)} title="Delete" />
        </td>
      )
}

const FormGrid = fieldArrayRenderProps => {
    const [filter, setFilter] = React.useState([]);
    const { name } = fieldArrayRenderProps;
    const jpp = fieldArrayRenderProps.productList;
    const jobId = fieldArrayRenderProps.jobId;
    const UpdateGrid = fieldArrayRenderProps.update;
    const [editIndex, setEditIndex] = React.useState();

    const CommandCell = (props) => {
        return (
        <DeleteCell {...props} remove={Remove} />
        )
    }
    
    const datePickerProps = React.useCallback( // fix date format for filter and edit in grid
        (datePickerProps) => ({...datePickerProps, format: 'dd/MM/yy',  }), []
    );

    const [editID, setEditID] = React.useState(null);
    const rowClick = (event) => { setEditID(event.dataItem.Id);  };

    const itemChange = (event) => {
        const inEditID = event.dataItem.Id;
        const field = event.field || "";
        const newData = jpp.map((item) =>
        item.Id === inEditID
            ? {
                ...item,
                [field]: event.value,
            }
            : item
        );
        fieldArrayRenderProps.updated(newData);
        UpdatePrice(event.dataItem, field, event.value);
    };

    const UpdatePrice = (item, field, value) => {
        var url = `${configData.SERVER_URL}apiv7/jobproductprices`;
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "jobId": jobId,
                "productId": item.Id,
                "price" : field === "Price" ? value : item.Price,
                "tonnesOrdered": field === "Ordered" ? value: item.Ordered,
                "SchedulePriceDate": field === "SchedDate" ? value: item.SchedDate,
                "price_S": field === "SchedPrice" ? value: item.SchedPrice
            })
        }
        fetch(url, init).then(response => response).then(d => { 
        });        
    }

    // Save the changes
    const onSave = React.useCallback(() => {
      console.log(fieldArrayRenderProps);
      setEditIndex(undefined);
    }, [fieldArrayRenderProps]);
    
    const Remove = (item) => {
        var url = `${configData.SERVER_URL}apiv7/jobproductprices/${item.JppId}`;
        const init = {
            method: 'DELETE',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache"
        }
        fetch(url, init).then(response => response).then(d => { 
           UpdateGrid();
        }); 
    }

    return <FormGridEditContext.Provider value={{ onSave, editIndex, parentField: name }}>
        <DatePickerPropsContext.Provider value={datePickerProps}>
            <Grid 
                pageable={{
                buttonCount: 4,
                pageSizes: [10, 20, 50, 100, 200, "All"],
                }}
                reorderable={true}
                resizable={true}
                data={filterBy(jpp, filter).map((item) => ({
                    ...item,
                    inEdit: item.Id === editID,
                    }))}
                editField="inEdit"
                onRowClick={rowClick}
                onItemChange={itemChange}
            >
                <Column field="Name" title="Product" editable={false}  />
                <Column field="Price" title="Price $" format="{0:c}" editor="numeric" filter="numeric" />
                <Column field="Ordered" title="Tonnes Ordered" format="{0:n2}" editor="numeric" filter="numeric" />
                <Column field="Processed" title="Tonnes Processed" format="{0:n2}" filter="numeric"  editable={false} />
                <Column field="SchedDate" title="Scheduled Date" format="{0:d/MM/yy}" editor="date" filter="date" />
                <Column field="SchedPrice" title="Scheduled Price $" format="{0:c}" filter="numeric" editor="numeric" />
                <Column field="Action" title="Delete" cell={CommandCell} editable={false}  />
            </Grid>
        </DatePickerPropsContext.Provider>          
    </FormGridEditContext.Provider>;
};
  
const EditJobs = () => {
    const { id } = useParams(); // editjobs/id url is used to edit existing job, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [customers, setCustomers] = React.useState([]);
    const [states, setStates] = React.useState([ ]);
    const [products, setProducts] = React.useState([]);
    const [product, setProduct] = React.useState([]);
    const [jpp, setJpp] = React.useState([]);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [permissions, setPermissions] = React.useState(null);
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };

    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "jobId", "label": "ID", "defaultValue": "", "isVisible": false, "type": "numeric" },
        { "field": "name", "label": "Name", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "code", "label": "Code", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "customerId", "label": "Customer", "defaultValue": {id:1, name:"NA"}, "isVisible": true, "type": "list" },
        { "field": "productId", "label": "Product", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "linkedProducts", "label": "Linked Products", "defaultValue": "", "isVisible": true, "type": "table" },
        { "field": "orderNumber", "label": "Order Number", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "destinationId", "label": "Destination", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "linkedDestinations", "label": "Linked Destinations", "defaultValue": "", "isVisible": true, "type": "table" },
        { "field": "sourceId", "label": "Source", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "linkedSources", "label": "Linked Sources", "defaultValue": "", "isVisible": true, "type": "table" },
        { "field": "description", "label": "Comment", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "department", "label": "Department", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "workOrderSystemCode", "label": "Work Order System Code", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "docketShouldBeSigned", "label": "Dockets must be signed", "defaultValue": "false", "isVisible": true, "type": "boolean" },
        { "field": "jobTonnesOrdered", "label": "Job Tonnes Ordered (t)", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "jobTonnesRunningTotal", "label": "Job Tonnage Running Total (t)", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "chargeDetails", "label": "Charge details", "defaultValue": "", "isVisible": true, "type": "heading" },
        { "field": "cartageCostPerTonne", "label": "Cartage Cost $/t", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "minimumCartage", "label": "Cartage Minimum $", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "overloadFee", "label": "Overload Charge $/t", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "cartagePerTonne", "label": "Cartage Charge $/t", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "addressDetails", "label": "Address details", "defaultValue": "", "isVisible": true, "type": "heading" },
        { "field": "jobAddress1", "label": "Job Address 1", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "jobAddress2", "label": "Job Address 2", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "jobSuburb", "label": "Job Suburb", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "jobState", "label": "Job State", "defaultValue": "NSW", "isVisible": true, "type": "list" },
        { "field": "jobPostcode", "label": "Job Postcode", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "jobZone", "label": "Job Zone", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "jobPhone", "label": "Job Phone", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "jobEmail", "label": "Job Email", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "jobTrucks", "label": "Trucks", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "addProduct", "label": "Product", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "addPrice", "label": "Price $", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "addTonnesOrdered", "label": "Tonnes Ordered", "defaultValue": 0, "isVisible": true, "type": "numeric" },
        { "field": "jobDetails", "label": "Job", "defaultValue": "", "isVisible": true, "type": "heading" },
        { "field": "siteDetails", "label": "Site details", "defaultValue": "", "isVisible": true, "type": "heading" },
        { "field": "linkedData", "label": "Relationships", "defaultValue": "", "isVisible": true, "type": "heading" },
        { "field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean"}
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        jobId: GetField("jobId")["defaultValue"],
        name: GetField("name")["defaultValue"],
        code: GetField("code")["defaultValue"],
        customerId: GetField("customerId")["defaultValue"],
        productId: GetField("productId")["defaultValue"],
        linkedProducts: GetField("linkedProducts")["defaultValue"],
        orderNumber: GetField("orderNumber")["defaultValue"],
        destinationId: [],
        linkedDestinations: GetField("linkedDestinations")["defaultValue"],
        sourceId: [],
        linkedSources: GetField("linkedSources")["defaultValue"],
        description: GetField("description")["defaultValue"],
        department: GetField("department")["defaultValue"],
        workOrderSystemCode: GetField("workOrderSystemCode")["defaultValue"],
        docketShouldBeSigned: GetField("docketShouldBeSigned")["defaultValue"].toLowerCase() === "true",
        jobTonnesOrdered: GetField("jobTonnesOrdered")["defaultValue"],
        jobTonnesRunningTotal: GetField("jobTonnesRunningTotal")["defaultValue"],
        chargeDetails: GetField("chargeDetails")["defaultValue"],
        cartageCostPerTonne: GetField("cartageCostPerTonne")["defaultValue"],
        minimumCartage: GetField("minimumCartage")["defaultValue"],
        overloadFee: GetField("overloadFee")["defaultValue"],
        cartagePerTonne: GetField("cartagePerTonne")["defaultValue"],
        addressDetails: GetField("addressDetails")["defaultValue"],
        jobAddress1: GetField("jobAddress1")["defaultValue"],
        jobAddress2: GetField("jobAddress2")["defaultValue"],
        jobSuburb: GetField("jobSuburb")["defaultValue"],
        jobState: GetField("jobState")["defaultValue"],
        jobPostcode: GetField("jobPostcode")["defaultValue"],
        jobZone: GetField("jobZone")["defaultValue"],
        jobPhone: GetField("jobPhone")["defaultValue"],
        jobEmail: GetField("jobEmail")["defaultValue"],
        jobTrucks: [],
        jobSites: [],
        addProduct: [],
        addPrice: GetField("addProduct")["defaultValue"],
        addTonnesOrdered: GetField("addTonnesOrdered")["defaultValue"],
        siteDetails: GetField("siteDetails")["defaultValue"],        
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vehicleconfigurations
    React.useEffect(() => {
        GetPermissions(["ViewJob", "NewJob", "EditJob"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditJobs'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setStates(opt.states);
            setCustomers(opt.customers);
            setProducts(opt.products);
            let defSrc = ConfigureSources(opt.sources, opt.inactiveSources, "1");
            let defDest = ConfigureDestinations(opt.destinations, opt.inactiveDestinations, "1");
            let allSites = GetList(opt.sites);
            let defSites = ConfigureSites(opt.sites, opt.inactiveSites, allSites);
            ConfigureTrucks(opt.trucks, opt.inactiveTrucks, "");
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/jobs/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var cust = opt.customers.find(obj => { return obj.id === String(d.customerId) });
                    let dest = ConfigureDestinations(opt.destinations, opt.inactiveDestinations, d.destinationList);
                    let src = ConfigureSources(opt.sources, opt.inactiveSources, d.sourceList);
                    let sites = ConfigureSites(opt.sites, opt.inactiveSites, d.siteList);
                    let trucks = ConfigureTrucks(opt.trucks, opt.inactiveTrucks, d.truckList);
                    let prod = opt.products.find(obj => { return obj.id === String(d.productId) });

                    setValues({
                        name: d.name === null ? "" : d.name,
                        code: d.code === null ? "" : d.code,
                        customerId: d.customerId === null ? "" : cust,
                        productId: d.productId === null ? "" : prod,
                        orderNumber: d.orderNumber === null ? "" : d.orderNumber,
                        destinationId: dest,
                        sourceId: src,
                        description: d.description === null ? "" : d.description,
                        department: d.department === null ? "" : d.department,
                        workOrderSystemCode: d.workOrderSystemCode === null ? "" : d.workOrderSystemCode,
                        docketShouldBeSigned: d.docketShouldBeSigned === null ? "" : d.docketsShouldBeSigned,
                        jobTonnesOrdered: d.jobTonnesOrdered === null ? "" : d.jobTonnesOrdered,
                        jobTonnesRunningTotal: d.jobTonnesRunningTotal === null ? "" : d.jobTonnesRunningTotal,
                        chargeDetails: d.chargeDetails === null ? "" : d.chargeDetails,
                        cartageCostPerTonne: d.cartageCostPerTonne === null ? "" : d.cartageCostPerTonne,
                        minimumCartage: d.minimumCartage === null ? "" : d.minimumCartage,
                        overloadFee: d.overloadFee === null ? "" : d.overLoadFee,
                        cartagePerTonne: d.cartagePerTonne === null ? "" : d.cartagePerTonne,
                        jobAddress1: d.jobAddress1 === null ? "" : d.jobAddress1,
                        jobAddress2: d.jobAddress2 === null ? "" : d.jobAddress2,
                        jobSuburb: d.jobSuburb === null ? "" : d.jobSuburb,
                        jobState: d.jobState === null ? "" : d.jobState,
                        jobPostcode: d.jobPostcode === null ? "" : d.jobPostcode,
                        jobZone: d.jobZone === null ? "" : d.jobZone,
                        jobPhone: d.jobPhone === null ? "" : d.jobPhone,
                        jobEmail: d.jobEmail === null ? "" : d.jobEmail,
                        jobSites: sites,
                        jobTrucks: trucks,
                        active: d.isActive === null ? "" : d.isActive
                    });
                    UpdateJobProductPrices(opt.products);
                    setFormkey(formkey + 1);
                })
             } else {
                var v = {...values};
                v.jobSites = defSites;
                v.sourceId = defSrc;
                v.destinationId = defDest;
                setValues(v);                
                setFormkey(formkey + 1);
            }
        });
    }, []);

    const ConfigureProductPrices = (products, pp) => {
        let pl = [];
        if (pp !== null){
            for (let i = 0; i < products.length; i++){
                let p = pp.find(obj => { return String(obj.productId) === products[i].id});
                if (p !== undefined){
                    let price = p !== undefined ? p.price : 0;
                    let ordered = p !== undefined ? p.tonnesOrdered: 0;
                    let processed = p !== undefined ? p.tonnesProcessed: 0;
                    let schedDate = p !== undefined && p.schedulePriceDate !== null ? new Date(p.schedulePriceDate) : null;
                    let schedPrice = p !== undefined ? p.price_S: 0;
                    let jppId = p !== undefined ? p.jobProductPriceId: 0;
                    pl.push({ Id: products[i].id, Name: products[i].name, Price: price, Ordered: ordered, Processed: processed, SchedDate: schedDate, SchedPrice: schedPrice, JppId: jppId });
                }
            }
        }
        setJpp(pl);
    }

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (f === undefined) f = "";
        if (fld === undefined || fld === null) return f;
        let res = fld["defaultValue"];
        if (fld["isVisible"]) res = f;
        switch(fld["type"]){
            case "numeric": 
                res = parseFloat(res); 
                if (isNaN(res)) res = 0;
                break;
            case "boolean": res = (res === "true" || res === true); break;
            case "date":
                if (res === "") res = null;
                break;
            default: break;
        }
        return res;
    }

    const GetDropdownData = (f, name) => {
        let res = 0;
        if (f === undefined || f.id === undefined) 
            res = GetDefaultData('', name);
        else
            res = f.id;
        return parseInt(res);
    }

    // navigation
    const GoBack = () => Redirect("");
    const AddExtraCharge = () => navigate("/addextracharge/" + id);
    const Redirect = (msg = "") => {
        navigate("/jobs", {state: {msg: msg}});
    }

    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    const [sourcesData, setSourcesData] = React.useState([{id:0, text: "Active", items: []}]);
    const dataItemKey = "id";
    const checkField = "checkField";
    const checkIndeterminateField = "checkIndeterminateField";
    const subItemsField = "items";
    const expandField = "expanded";
    const textField = "text";
    const fields = { dataItemKey, checkField, checkIndeterminateField, expandField, subItemsField };
    // state data for source multi-select tree   
    const [sourcesValue, setSourcesValue] = React.useState([]);
    const [sourceExpanded, setSourceExpanded] = React.useState([]);
    const onSourcesChange = (event) => {
        setSourcesValue(getMultiSelectTreeValue(sourcesData, {...fields, ...event, value: sourcesValue, }));
    }
    const onSourcesExpandChange = React.useCallback((event) => setSourceExpanded(expandedState(event.item, dataItemKey, sourceExpanded)), [sourceExpanded]);
    const sourcesTreeData = React.useMemo(() => processMultiSelectTreeData(sourcesData, { expanded: sourceExpanded, value: sourcesValue, ...fields }), [sourceExpanded, sourcesValue]);
    const ConfigureSources = (act, dis, sourceList) => {
        if (sourceList === null) sourceList = "";
        let slsp = sourceList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            let inactive = {id: 999999, text: 'Inactive', items: sl };
            setSourcesData([active, inactive]);
        }
        else {
            setSourcesData([active]);
        }
        setSourcesValue([...selected]);       
        return [...selected];
    }

    // state data for destinations multi-select tree 
    const [destData, setDestData] = React.useState([{id:0, text: "Active", items: []}]);
    const [destValue, setDestValue] = React.useState([]);
    const [destExpanded, setDestExpanded] = React.useState([]);
    const onDestChange = (event) => {
        setDestValue(getMultiSelectTreeValue(destData, {...fields, ...event, value: destValue, }) );
    }
    const onDestExpandChange = React.useCallback((event) => setDestExpanded(expandedState(event.item, dataItemKey, destExpanded)), [destExpanded]);
    const destTreeData = React.useMemo(() => processMultiSelectTreeData(destData, { expanded: destExpanded, value:destValue, ...fields }), [destExpanded, destValue]);
    const ConfigureDestinations = (act, dis, destList) => {
        if (destList === null) destList = "";
        let destsp = destList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = destsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: [...sl] };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = destsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
                }
            let inactive = {id: 999999, text: 'Inactive', items: [...sl] };
            setDestData([active, inactive]);
        }
        else {
            setDestData([active]);
        }
        setDestValue([...selected]);
        return [...selected];
    }

    const [sitesData, setSitesData] = React.useState([{id:0, text: "Active", items: []}]);
    const [sitesValue, setSitesValue] = React.useState([]);
    const [siteExpanded, setSiteExpanded] = React.useState([]);
    const onSitesChange = (event) => {
        setSitesValue(getMultiSelectTreeValue(sitesData, {...fields, ...event, value: sitesValue, }));
    }
    const onSitesExpandChange = React.useCallback((event) => setSiteExpanded(expandedState(event.item, dataItemKey, siteExpanded)), [siteExpanded]);
    const sitesTreeData = React.useMemo(() => processMultiSelectTreeData(sitesData, { expanded: siteExpanded, value: sitesValue, ...fields }), [siteExpanded, sitesValue]);
    const ConfigureSites = (act, dis, siteList) => {
        if (siteList === null) siteList = "";
        let slsp = siteList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            let inactive = {id: 999999, text: 'Inactive', items: sl };
            setSitesData([active, inactive]);
        }
        else {
            setSitesData([active]);
        }
        setSitesValue([...selected]);       
        return [...selected];
    }

    const [trucksData, setTrucksData] = React.useState([{id:0, text: "Active", items: []}]);
    const [trucksValue, setTrucksValue] = React.useState([]);
    const [truckExpanded, setTruckExpanded] = React.useState([]);
    const onTrucksChange = (event) => {
        setTrucksValue(getMultiSelectTreeValue(trucksData, {...fields, ...event, value: trucksValue, }));
    }
    const onTrucksExpandChange = React.useCallback((event) => setTruckExpanded(expandedState(event.item, dataItemKey, truckExpanded)), [truckExpanded]);
    const trucksTreeData = React.useMemo(() => processMultiSelectTreeData(trucksData, { expanded: truckExpanded, value: trucksValue, ...fields }), [truckExpanded, trucksValue]);
    const ConfigureTrucks = (act, dis, truckList) => {
        if (truckList === null) truckList = "";
        let slsp = truckList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            let inactive = {id: 999999, text: 'Inactive', items: sl };
            setTrucksData([active, inactive]);
        }
        else {
            setTrucksData([active]);
        }
        setTrucksValue([...selected]);       
        return [...selected];
    }

    const GetList = (obj) => {
        if (obj === undefined || obj === null || obj.length === 0) return "";
        var l = "";
        for (let i = 0; i < obj.length; i++){
            let id = obj[i].id;
            if (id !== 0 && id !== 999990){
                l += id;
                if (i < obj.length - 1) l += ",";
            }
        }
        return l;
    }

    function UpdatePrices(){  
        UpdateJobProductPrices(products);
    }

    const UpdateJobProductPrices = (products) => {
        let url = configData.SERVER_URL + "apiv7/jobproductprices?$filter=jobId eq " + id;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: {"Authorization": "Bearer " + GetToken()}
          }
          fetch(url, init).then(response => response.json()).then(pp => {           
            ConfigureProductPrices(products, pp);
        });
    }

    const jppUpdated = (newData) => {
        setJpp(newData);
    };

    const productChanged = (e) => { setProduct(e.value); }

    const addProduct = (e) => {
        e.preventDefault();       
        if (product === undefined || product.length === 0) {
            alert("Select a product");
            return;
        }
        let pr = parseFloat($("#addPrice").val().replace("$", ""));
        if (isNaN(pr)) pr = 0;
        let ton = parseFloat($("#addTonnesOrdered").val());
        if ((isNaN(ton))) ton = 0;
        var url = `${configData.SERVER_URL}apiv7/jobproductprices`;
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "jobId": id,
                "productId": product.id,
                "price" : pr,
                "tonnesOrdered": ton
            })
        }
        fetch(url, init).then(response => response).then(d => { 
            UpdatePrices();
        });
    }

    const Validate = (dataItem) => {
        let st = "";
        if (dataItem.name === undefined || dataItem.name ==='') st += "Name\r\n";
        if (dataItem.code === undefined || dataItem.code ==='') st += "Code\r\n";
        if (dataItem.customerId === undefined || dataItem.customerId ==='') st += "Customer\r\n";
        if (st !== ""){
            setSelectedTab(0);
            alert("Please enter the following: \r\n" + st);
            return false;
        }
        return true;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        if (!Validate(dataItem)) return;  
        var pth = id === undefined ? "" : "/" + id;
        var siteList = GetList(dataItem.jobSites);
        var destList = GetList(dataItem.destinationId);
        var srcList = GetList(dataItem.sourceId);        
        var truckList = GetList(dataItem.jobTrucks); ;
        var jppList = [];
        for (let i = 0; i < jpp.length; i++){
            //if (jpp[i].Selected){
                jppList.push({jobId: id, productId: jpp[i].Id, price: jpp[i].Price, price_S: jpp[i].SchedPrice, tonnesOrdered:jpp[i].Ordered, schedulePriceDate:jpp[i].SchedDate });
           // }
        }        
        //Price: price, Ordered: ordered, Processed: processed, SchedDate: schedDate, SchedPrice: schedPrice 
        var url = `${configData.SERVER_URL}apiv7/jobs${pth}`;
        const init = {
            method: id === undefined ? 'POST' : 'PUT',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
            "jobId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.name, "name"),
            "code": GetDefaultData(dataItem.code, "code"),
            "customerId": GetDropdownData(dataItem.customerId, "customerId"),
            "productId": GetDropdownData(dataItem.productId, "productId"),
            "orderNumber": GetDefaultData(dataItem.orderNumber, "orderNumber"),
            "description": GetDefaultData(dataItem.description, "description"),
            "department": GetDefaultData(dataItem.department, "department"),
            "workOrderSystemCode": GetDefaultData(dataItem.workOrderSystemCode, "workOrderSystemCode"),
            "docketsShouldBeSigned": GetDefaultData(dataItem.docketShouldBeSigned, "docketShouldBeSigned"),
            "jobTonnesOrdered": GetDefaultData(dataItem.jobTonnesOrdered, "jobTonnesOrdered"),
            "jobTonnesRunningTotal": GetDefaultData(dataItem.jobTonnesRunningTotal, "jobTonnesRunningTotal"),
            "chargeDetails": GetDefaultData(dataItem.chargeDetails, "chargeDetails"),
            "cartageCostPerTonne": GetDefaultData(dataItem.cartageCostPerTonne, "cartageCostPerTonne"),
            "minimumCartage": GetDefaultData(dataItem.minimumCartage, "minimumCartage"),
            "overloadFee": GetDefaultData(dataItem.overloadFee, "overloadFee"),
            "cartagePerTonne": GetDefaultData(dataItem.cartagePerTonne, "cartagePerTonne"),
            "jobAddress1": GetDefaultData(dataItem.jobAddress1, "jobAddress1"),
            "jobAddress2": GetDefaultData(dataItem.jobAddress2, "jobAddress2"),
            "jobSuburb": GetDefaultData(dataItem.jobSuburb, "jobSuburb"),
            "jobState": GetDefaultData(dataItem.jobState, "jobState"),
            "jobPostcode": GetDefaultData(dataItem.jobPostcode, "jobPostcode"),
            "jobZone": GetDefaultData(dataItem.jobZone, "jobZone"),
            "jobPhone": GetDefaultData(dataItem.jobPhone, "jobPhone"),
            "jobEmail": GetDefaultData(dataItem.jobEmail, "jobEmail"),
            "jobTrucks": GetDefaultData(dataItem.jobTrucks, "jobTrucks"),
            "siteList": siteList,
            "truckList": truckList,
            "sourceList": srcList,
            "destinationList": destList,
            "jobProductPrices": jppList,
            "isActive": GetDefaultData(dataItem.active, "active") 
            })
        }
        fetch(url, init).then(response => response).then(d => { 
            var msg = id === undefined ? "Job created" : "Job updated";
            if (d.status > 299) msg = `Error ${d.status} creating Job`;
            Redirect(msg);
        });
    }   
    
    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) =>  value ? "" : "Please select an option";
    return (
        <div style={{padding: 20}}>
           <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
           <FormElement style={{width: "95%"}} >
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("jobDetails")["isVisible"] && <TabStripTab title={GetField("jobDetails")["label"]}>
                        <div className="row">
                            {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("code")["isVisible"] && <div className='col-md-3'>
                                <Field id={"code"} name={"code"} label={GetField("code")["label"]} component={FormInput} validator={textValidator} />
                            </div>}                            
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}                            
                        </div>
                        <div className="row">
                            {GetField("customerId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"customerId"} name={"customerId"} label={GetField("customerId")["label"]} component={FormDropDownList} data={customers} textField="name" validator={optionValidator} />
                            </div>}
                            {GetField("productId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"productId"} name={"productId"} label={GetField("productId")["label"]} component={FormDropDownList} data={products} textField="name" validator={optionValidator} />
                            </div>}
                            {GetField("orderNumber")["isVisible"] && <div className="col-md-3">
                                <Field id={"orderNumber"} name={"orderNumber"} label={GetField("orderNumber")["label"]} component={FormInput} />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("department")["isVisible"] && <div className='col-md-3'>
                                <Field id={"department"} name={"department"} label={GetField("department")["label"]} component={FormInput}/>
                            </div>}                            
                            {GetField("workOrderSystemCode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"workOrderSystemCode"} name={"workOrderSystemCode"} label={GetField("workOrderSystemCode")["label"]} component={FormInput}/>
                            </div>}                            
                            {GetField("description")["isVisible"] && <div className="col-md-3">
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormTextArea}/>
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("jobTonnesOrdered")["isVisible"] && <div className="col-md-3">
                                <Field id={"jobTonnesOrdered"} name={"jobTonnesOrdered"} label={GetField("jobTonnesOrdered")["label"]} disabled component={FormInput}/>
                            </div>}
                            {GetField("jobTonnesRunningTotal")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobTonnesRunningTotal"} name={"jobTonnesRunningTotal"} label={GetField("jobTonnesRunningTotal")["label"]} disabled component={FormInput}/>
                            </div>}                            
                            {GetField("docketShouldBeSigned")["isVisible"] && <div className='col-md-3'>
                                <Field id={"docketShouldBeSigned"} name={"docketShouldBeSigned"} label={GetField("docketShouldBeSigned")["label"]} component={FormSwitch} />
                            </div>}                            
                            {id !== undefined && <div className='col-md-1' style={{paddingTop: 20}}>
                                <Button themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={AddExtraCharge} >Add extra charge</Button>
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("chargeDetails")["isVisible"] && <TabStripTab title={GetField("chargeDetails")["label"]}>
                        <div className='row' >
                            {GetField("cartagePerTonne")["isVisible"] && <div className='col-md-3'>
                                <Field id={"cartagePerTonne"} name={"cartagePerTonne"} label={GetField("cartagePerTonne")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}
                            {GetField("minimumCartage")["isVisible"] && <div className='col-md-3'>
                                <Field id={"minimumCartage"} name={"minimumCartage"} label={GetField("minimumCartage")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}
                            {GetField("cartageCostPerTonne")["isVisible"] && <div className='col-md-3'>
                                <Field id={"cartageCostPerTonne"} name={"cartageCostPerTonne"} label={GetField("cartageCostPerTonne")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}
                            {GetField("overloadFee")["isVisible"] && <div className='col-md-3'>
                                <Field id={"overloadFee"} name={"overloadFee"} label={GetField("overloadFee")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" />
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("addressDetails")["isVisible"] && <TabStripTab title={GetField("addressDetails")["label"]}>
                        <div className='row' >
                            {GetField("jobAddress1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobAddress1"} name={"jobAddress1"} label={GetField("jobAddress1")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("jobAddress2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobAddress2"} name={"jobAddress2"} label={GetField("jobAddress2")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("jobSuburb")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobSuburb"} name={"jobSuburb"} label={GetField("jobSuburb")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("jobState")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobState"} name={"jobState"} label={GetField("jobState")["label"]} component={FormDropDownList} data={states} />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("jobPostcode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobPostcode"} name={"jobPostcode"} label={GetField("jobPostcode")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("jobZone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobZone"} name={"jobZone"} label={GetField("jobZone")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("jobPhone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobPhone"} name={"jobPhone"} label={GetField("jobPhone")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("jobEmail")["isVisible"] && <div className='col-md-3'>
                                <Field id={"jobEmail"} name={"jobEmail"} label={GetField("jobEmail")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("linkedData")["isVisible"] && <TabStripTab title={GetField("linkedData")["label"]}>                                       
                        <div className='row' style={{ padding: "10px" }} >
                            {GetField("destinationId")["isVisible"] && <div className='col-md-4'>
                                {GetField("destinationId")["label"]}
                                <Field component={MultiSelectTreeEditor} name={"destinationId"} data={destTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                            {GetField("sourceId")["isVisible"] && <div className='col-md-4'>
                                {GetField("sourceId")["label"]}
                                <Field component={MultiSelectTreeEditor} name={"sourceId"} data={sourcesTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                            {GetField("jobTrucks")["isVisible"] && <div className='col-md-4'>
                                {GetField("jobTrucks")["label"]}
                                <Field component={MultiSelectTreeEditor} name={"jobTrucks"} data={trucksTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />
                            </div>}
                        </div>                                        
                        <div className='row' style={{ padding: "10px" }} >
                            
                                {configData.SITE === 1 && GetField("siteDetails")["isVisible"] && <div className='col-md-4'>
                                {GetField("siteDetails")["label"]}
                                <Field component={MultiSelectTreeEditor} name={"jobSites"} data={sitesTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("productId")["isVisible"] && id !== undefined && <TabStripTab title={GetField("productId")["label"]}>
                        <div className='row' style={{paddingBottom: "20px"}} >
                            {GetField("addProduct")["isVisible"] && <div className='col-md-3'>
                                <Label>{GetField("productId")["label"]}</Label>
                                <DropDownList id={"addProduct"} name={"addProduct"} data={products} textField="name" onChange={productChanged} />
                            </div>}
                            {GetField("addPrice")["isVisible"] && <div className='col-md-3'>
                                <Label>{GetField("addPrice")["label"]}</Label>
                                <TextBox id={"addPrice"} name={"v"} type="number" min={0} max={100000}  />
                            </div>}
                            {GetField("addTonnesOrdered")["isVisible"] && <div className='col-md-3'>
                                <Label>{GetField("addTonnesOrdered")["label"]}</Label>
                                <TextBox id={"addTonnesOrdered"} name={"addTonnesOrdered"} type="number" min={0} max={100000} />
                            </div>}
                            <div className='col-md-1' style={{ paddingTop: "20px" }}>
                                <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" themeColor={"primary"} onClick={addProduct}>Add Product</Button>
                            </div>
                        </div>
                        <div className='row' >
                            {GetField("productId")["isVisible"] && <div>
                            <FieldArray name="productId" dataItemKey={"id"} component={FormGrid} productList={jpp} updated={jppUpdated} jobId={id} update={UpdatePrices} />
                            </div>}
                        </div>          
                        </TabStripTab>}
                        </TabStrip>                        
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Cancel</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditJobs