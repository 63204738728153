import * as React from 'react';
import { GridColumnMenuSort, GridColumnMenuFilter, GridColumnMenuItemGroup, GridColumnMenuItem, GridColumnMenuItemContent } from '@progress/kendo-react-grid';
import { GetSession, GetToken } from '../components/Session';
import configData from '../config.json';

const ColumnMenu = (props) => {
    const viewName = props.viewName;
    const sortable = props.sortable === undefined ? true : props.sortable;
    const filterable = props.filterable === undefined ? true : props.filterable;
    const [columns, setColumns] = React.useState(props.columns);
    const [columnsExpanded, setColumnsExpanded] = React.useState(false);
    const [filterExpanded, setFilterExpanded] = React.useState(false);
    const onToggleColumn = id => {
      const newColumns = columns.map((column, idx) => {
        return idx === id ? {
          ...column,
          isVisible: !column.isVisible
        } : column;
      });
      setColumns(newColumns);
    };
    const onReset = event => {
      event.preventDefault();
      const newColumns = props.columns.map(col => {
        return {
          ...col,
          isVisible: true
        };
      });
      setColumns(newColumns);
      props.onColumnsSubmit(newColumns);
      if (props.onCloseMenu) {
        props.onCloseMenu();
      }
    };
    const onSubmit = event => {
      if (event) {
        event.preventDefault();
      }
      props.onColumnsSubmit(columns);
      if (props.onCloseMenu) {
        props.onCloseMenu();
      }     
      updateColumnState(columns);
    };
    const onMenuItemClick = () => {
      const value = !columnsExpanded;
      setColumnsExpanded(value);
      setFilterExpanded(value ? false : filterExpanded);
    };
    const oneVisibleColumn = columns.filter(c => c.isVisible).length === 1;
    const updateColumnState = (ds) => {
      var t = viewName + "Columns";
      var q = `apiv7/userfilters`;
      var init = {
          method: "POST", 
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          body: JSON.stringify({
            userId: GetSession().UserId,
            viewName: t,
            value: JSON.stringify(ds)
          })
          };
      fetch(configData.SERVER_URL + q, init).then().then();
    }
        
    return (
        <div>
            
            {filterable && <GridColumnMenuFilter {...props} />}
            <GridColumnMenuItemGroup>
            <GridColumnMenuItem title={'Columns'} iconClass={'k-i-columns'} onClick={onMenuItemClick} />
            <GridColumnMenuItemContent show={columnsExpanded}>
                <div className={'k-column-list-wrapper'}>
                <form onSubmit={onSubmit} onReset={onReset}>
                    <div className={'k-column-list'}>
                    {columns.map((column, idx) => <div key={idx} className={'k-column-list-item'}>
                                        <span>
                                        <input id={`column-visiblity-show-${idx}`} className="k-checkbox k-checkbox-md k-rounded-md" type="checkbox" readOnly={true} disabled={column.isVisible && oneVisibleColumn} checked={column.isVisible} onClick={() => {
                        onToggleColumn(idx);
                    }} />
                                        <label htmlFor={`column-visiblity-show-${idx}`} className="k-checkbox-label" style={{
                        userSelect: 'none'
                    }}>
                                            &nbsp;{column.label}
                                        </label>
                                        </span>
                                    </div>)}
                    </div>
                    <div className={'k-actions k-hstack k-justify-content-stretch'}>
                    <button type={'reset'} className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'}>Reset</button>
                    <button className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'}>Save</button>
                    </div>
                </form>
                </div>
            </GridColumnMenuItemContent>
            </GridColumnMenuItemGroup>
        </div>
    );
}

export default ColumnMenu