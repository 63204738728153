import * as React from 'react';
import configData from "../config.json";
import { GetToken } from '../components/Session';
import { LinearGauge } from "@progress/kendo-react-gauges";
import { Label } from '@progress/kendo-react-labels';
import { Switch } from "@progress/kendo-react-inputs";
import { GetField, GetSetting } from './Helpers';
import { Slider } from "@progress/kendo-react-inputs";
import { GetPermissions } from '../components/Options';

// Implements a weight meter component
// Props:
// weights is array of 6 weights. weights[0] is total weight. For split weight, 2-6 are 5 individual weights. For multi-desk 2-5 are up to 4 deck weights
// width is width of control [default 400px] Optimized for 400px
// overloadColour is colour to display on text and bar when weight > maxWeight [default red]
// gaugeColour is normal (non-overload colour of gauge) [default black]
// validColour is colour of valid weight range on gauge [default #00ff00]
// splitColour is background colour of split and multi-deck texts [default #eeeeee]
// textBackground is background colour of main weight text when not overloaded. overloadColour is used when overloaded [default white]
// maxWeight is maximum weight (t) before overload. Gauge will be a rounded 1.3x this value to show display of overload amount [default 60]
// decks is number of decks. Omit or use 1 for single deck [default 1]
// allowSplit shows split weight slider when decks is 1 [default true]
// hideGauge hides the gauge [default displayed]
// showSlider displays weight slider for testing
// setWeight is a callback to set weights 
// toggleSlider - callback to toggle visibility of weight slider
export const WeightMeter = (props) => {
    const weight = props.weights[0];
    const layout = props.layout;
    const weights = props.weights === undefined ? [0, 0, 0, 0, 0, 0] : [props.weights[1].toFixed(2), props.weights[2].toFixed(2), props.weights[3].toFixed(2), props.weights[4].toFixed(2), props.weights[5].toFixed(2)];
    const overloadColour = props.overloadColour === undefined ? "red" : props.overloadColour;
    const textColour = props.textColour === undefined ? "black" : props.textColour;
    const gaugeColour = props.gaugeColour === undefined ? "black" : props.gaugeColour;
    const splitColour = props.splitColour === undefined ? "#eeeeee" : props.splitColour;
    const textBackground = props.textBackground === undefined ? "white" : props.textBackground;
    const activeScales = props.activeScales;
    const validColour = props.validColour === undefined ? "#00ff00" : props.validColour;
    const maxWeight = props.maxWeight === undefined ? 60 : parseInt(props.maxWeight);
    const gColour = weight > maxWeight ? overloadColour : gaugeColour; 
    let bgColour = weight > maxWeight ? overloadColour: textBackground;
    const max = GetSetting(props.settings, "EnableOverloadTransaction") ? Math.ceil(maxWeight * 10 / 90) * 10 : maxWeight;
    const decks = props.decks === undefined ? 1 : parseInt(props.decks);
    const [splitWeight, setSplitWeight] = React.useState(false);
    const allowSplit = props.allowSplit === undefined ? true : String(props.allowSplit).toLowerCase() === "true" && decks == 1;
    const showGauge = props.hideGauge === undefined ? true : false;
    const showSlider = props.showSlider;
    const sliderTimer = React.useRef();
    const sliderWeight = React.useRef();
    const outerDiv = React.useRef();
    const options = props.options;
    let online = true;
    let weightText = weight.toFixed(2) + "t";
    if (props.weighStatus !== undefined) {
        if (props.weighStatus.IsUnderload || props.weighStatus.IsOverload || props.weighStatus.IsError) {
            if (props.weighStatus.IsUnderload) weightText = "Under";
            if (props.weighStatus.IsOverload) weightText = "Over";
            if (props.weighStatus.IsError) weightText = "Error";
            bgColour = overloadColour;
        }
        let dt = new Date(props.weighStatus.LastReading);
        let now = new Date().getTime() - 10000;
        let lw = dt.getTime();
        if (lw < now) {
            online = false;
            outerDiv.current.classList.add("DeviceOffline");
        } else {
            outerDiv.current.classList.remove("DeviceOffline");
        }
    }

    const linearOptions = {
        transitions: false,
        pointer: {
            value: weight,
            color: gColour,
        },
        style: {
            width: "100%"
        },
        scale: {
            minorUnit: 5,
            majorUnit: 10,
            max: max,
            vertical: false,
            ranges: [
                { from: 0, to: maxWeight, color: validColour },
                { from: maxWeight, to: max, color: overloadColour }
            ]
        }
    };

    const SplitChanged = (ev) => {
        setSplitWeight(ev.value);
        if (props.controlChanged !== undefined) props.controlChanged("SplitWeight", ev.value);
    }

    const SliderChanged = (ev) => {
        if (props.setWeights !== undefined) {
            let w = Math.round(ev.value * 100) / 100;
            sliderWeight.current = w;
            props.setWeights(w);
            if (sliderTimer.current === undefined) {
                sliderTimer.current = setTimeout(() => { UpdateWeight() }, 100);
            }
        }
    }

    const UpdateWeight = () => {
        if (options === undefined || !options.permissions.find(obj => obj === "WeighbridgeTester") === "WeighbridgeTester") return;
        var url = `${configData.SERVER_URL}clientapi/weighbridge/simulateweight?weight=${sliderWeight.current}`;
        const init = {
            method: 'PATCH',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() },
            cache: "no-cache"
        };
        fetch(url, init);
        sliderTimer.current = undefined;
    }

    const WeightClicked = (ev) => {
        if (options !== undefined && options.permissions.find(obj => obj === "WeighbridgeTester") === "WeighbridgeTester") {
            if (ev.ctrlKey && ev.altKey && props.toggleSlider) props.toggleSlider();
        }
    }

    return (
        <div ref={outerDiv}>
            <div style={{ display: "flex" }}>
                <div className="splitWeightControl">{online && allowSplit && <div><Label>{GetField(layout,"splitWeigh")["label"]}</Label><br /><Switch checked={splitWeight} onChange={SplitChanged}></Switch></div>}</div>
                <div className="weightTextDiv" style={{ background: bgColour }} onClick={WeightClicked}><Label className="weightText" style={{ color: textColour }} >{online ? weightText : "Offline"}</Label></div>
            </div>
            {online && allowSplit && splitWeight && <div style={{ marginLeft: "4px", display: "flex" }}>
                <div className="splitWeight"><Label className="splitWeightText">{weights[0]}</Label></div>
                <div className="splitWeight"><Label className="splitWeightText">{weights[1]}</Label></div>
                <div className="splitWeight"><Label className="splitWeightText">{weights[2]}</Label></div>
                <div className="splitWeight"><Label className="splitWeightText">{weights[3]}</Label></div>
                <div className="splitWeight"><Label className="splitWeightText">{weights[4]}</Label></div>
            </div>}
            {decks > 1 && <div style={{ marginLeft: "12px", display: "flex" }}>
                <div className="deckWeight"><Label className="deckWeightText">{weights[0]}</Label></div>
                <div className="deckPlus">+</div><div className="deckWeight"><Label className="deckWeightText">{weights[1]}</Label></div>
                {decks > 2 && <div style={{ display: "flex" }}><div className="deckPlus">+</div><div className="deckWeight"><Label className="deckWeightText">{weights[2]}</Label></div></div>}
                {decks > 3 && <div style={{ display: "flex" }}><div className="deckPlus">+</div><div className="deckWeight"><Label className="deckWeightText">{weights[3]}</Label></div></div>}
            </div>}
            {showGauge && !showSlider && <div className="meterSpacing"><LinearGauge {...linearOptions}/></div>}
            {showSlider && <div><Slider className={"slider"} buttons={false} step={1} defaultValue={0} min={0} max={maxWeight} style={{ width: "100%" }} onChange={SliderChanged}></Slider></div>}
        </div>
    );
};

