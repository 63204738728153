import * as React from 'react';
import Notifications from '../components/Notifications';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";

const CountedItemProducts = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");

    React.useEffect(() => { 
        GetColumnDefs("products", setColumns, setErrorMessage); 
        GetPermissions(["ViewProduct", "NewProduct", "EditProduct", "DeleteProduct", "ReportProduct"], setPermissions)
    }, [])

    const RowStyle = (p) => {
        let bg = { backgroundColor: "white" };
        if (p.dataItem.stockLevel < p.dataItem.minimumStockLevel) {
            bg = { backgroundColor: "rgb(243, 23, 0, 0.5)" };
        } else {
            if (p.dataItem.stockLevel < p.dataItem.minimumStockLevel * 1.2) bg = { backgroundColor: "rgb(255, 255, 127, 1)" };
        }
        return bg;
    }

    return (
        <div>
        <Notifications errorMessage={errorMessage}/>
            {columns && permissions && <DataGrid dataSource='apiv7/products' dataFilter="productType eq 'I'" extraParam="/I" columns={columns} permissions={permissions} keyField="productId" rowStyle={RowStyle} /> }
        </div>       
    );

}

export default CountedItemProducts;