import * as React from "react";
import DataGrid from "../components/DataGrid";
import GetColumnDefs from "../components/ColumnDefs";
import { Button } from "@progress/kendo-react-buttons";
import { MultiSelect, DropDownList } from "@progress/kendo-react-dropdowns";
import configData from "../config.json";
import { GetSession, GetToken } from "../components/Session";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { GetPermissions } from '../components/Options';
import Notifications from '../components/Notifications';
import { useNavigate } from "react-router-dom";

const Jobs = () => {
  const [lookupParams, setLookupParams] = React.useState("");
  const [sources, setSources] = React.useState([]);
  const [source, setSource] = React.useState([]);
  const [destinations, setDestinations] = React.useState([]);
  const [destination, setDestination] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [site, setSite] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const ready = React.useRef(false);
  const [columns, setColumns] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [permissions, setPermissions] = React.useState(null);
  const navigate = useNavigate();
  const options = React.useRef();
  if (permissions && !permissions.CanView) navigate("/error401");
  const [layout, setLayout] = React.useState([
    { "field": "searchAdvanced", "label": "Advanced Search", "isVisible": true },
    { "field": "searchSite", "label": "Site", "isVisible": true },
    { "field": "searchSource", "label": "Source", "isVisible": true },
    { "field": "searchDestination", "label": "Destination", "isVisible": true }
  ]);

  const GetField = (f) => {
    return layout.find(obj => { return obj.field === f });
  }

  function DoSearch() {
    if (ready.current) {
      var jobParam = {
        Source: GetIds(source),
        Destination: GetIds(destination),
        Site: GetIds(site),
      };
      setLookupParams(encodeURI(JSON.stringify(jobParam)));
    }
  }

  const GetIds = (d) => {
        if (d.length === undefined || d.length === 0) return "0";
        let r = "";
        d.map(x => {
            r += (r.length > 0 ? ";" : "") + x.id;
        });
        return r;
  }

  const ClearSearch = () => {
    if (ready.current) {
      ClearParams();
      var jobParam = {
        Source: [],
        Destination: [],
        Site: [],
      };
      setLookupParams(encodeURI(JSON.stringify(jobParam)));
    }
  };
  const ClearParams = () => {
    setSource([]);
    setDestination([]);
    setSite([]);
  };

  const sourceChanged = (event) => {
    setSource(event.target.value);
  };
  const destinationChanged = (event) => {
    setDestination(event.target.value);
  };
  const siteChanged = (event) => {
    setSite(event.target.value);
  };

  React.useEffect(() => {
    GetColumnDefs("jobs", setColumns, setErrorMessage);
    GetPermissions(["ViewJob", "NewJob", "EditJob", "DeleteJob", "ReportJob"], setPermissions)
    let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'Jobs'";
    const init = {
      method: "GET",
      accept: "application/json",
      headers: {"Authorization": "Bearer " + GetToken()},
    };
    fetch(baseUrl, init).then(response => response.json()).then(d => { 
      if (GetSession().IsMobile){
          let data = [];
          for(let i = 0; i < d.length; i++){
              data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
          }
          setLayout(data);
      } else {
          setLayout(d); 
      }
    });
    baseUrl = configData.SERVER_URL + "apiv7/options";
    fetch(baseUrl, init)
      .then((response) => response.json())
        .then((d) => {
        options.current = d;
        setSources(d.sources);
        setDestinations(d.destinations);
        setSites(d.sites);
        ClearParams();
        ready.current = true;
      });
  }, []);
  
  return (
    <div>
      <Notifications errorMessage={errorMessage}/>
      {GetField("searchAdvanced")["isVisible"] && <ExpansionPanel
        title={GetField("searchAdvanced")["label"]}
        expandIcon="fa fa-plus"
        collapseIcon="fa fa-minus"
        expanded={expanded}
        onAction={(e) => setExpanded(!e.expanded)}
      >
        <Reveal>
          {expanded && (
            <ExpansionPanelContent>
              <div>
                <div className="row" style={{ padding: "5px 10px" }}>
                  {GetField("searchSite")["isVisible"] && <div className="col-md-3">
                    <label className="control-label">{GetField("searchSite")["label"]}</label>
                    <MultiSelect
                      id="Site"
                      data={sites}
                      value={site}
                      textField="name"
                      dataItemKey="id"
                      onChange={siteChanged}
                    />
                  </div>}
                  {GetField("searchSource")["isVisible"] && <div className="col-md-3">
                    <label className="control-label">{GetField("searchSource")["label"]}</label>
                    <MultiSelect
                      id="Source"
                      data={sources}
                      value={source}
                      textField="name"
                      dataItemKey="id"
                      onChange={sourceChanged}
                    />
                  </div>}
                  {GetField("searchDestination")["isVisible"] && <div className="col-md-3">
                    <label className="control-label">{GetField("searchDestination")["label"]}</label>
                    <MultiSelect
                      id="Destination"
                      data={destinations}
                      value={destination}
                      textField="name"
                      dataItemKey="id"
                      onChange={destinationChanged}
                    />
                  </div>}
                </div>
                <div className="col-md-3" style={{ padding: "25px" }}>
                    <Button themeColor={"primary"} onClick={DoSearch}>
                      Search
                    </Button>{" "}
                    <Button onClick={ClearSearch} themeColor={"primary"}>
                      Clear
                    </Button>
                </div>
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>}

      {columns && permissions && permissions.CanView && <DataGrid
        dataSource="apiv7/jobs"
        params={lookupParams}
        columns={columns}
        permissions={permissions}
        keyField="jobId"
        extraButton="Add fee"
        extraButtonUrl="addextracharge"
      />}
    </div>
  );
};

export default Jobs;
