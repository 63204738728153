import * as React from 'react';
import Notifications from '../components/Notifications';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';

const Vouchers = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");

    React.useEffect(() => {
        GetColumnDefs("Vouchers", setColumns, setErrorMessage);
        GetPermissions(["ViewVouchers", "NewVouchers", "EditVouchers", "DeleteVouchers", "PrintVouchers", "EnableVouchers"], setPermissions)
    }, [])

    const setVisibility = (cbid, vis) => {
        if (!permissions.EnableVouchers) {
            alert("You do not have permission or enable vouchers. Changes will not take effect.");
            return;
        }
        var id = String(cbid).replace("CB", "");
        var url = `${configData.SERVER_URL}apiv7/vouchers?id=${id}&active=${vis}`;
        const init = {
            method: 'PATCH',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() },
            cache: "no-cache"
        };
        fetch(url, init);
    }

    return (
        <div>
            <Notifications errorMessage={errorMessage} />
            {columns && permissions && <DataGrid dataSource='apiv7/vouchers' columns={columns} permissions={permissions} setVisibility={setVisibility} keyField="voucherId" />}
        </div>
    );
}

export default Vouchers;