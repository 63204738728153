import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "./Header"; //import ProductChart from "../pages/charts/ProductChart";
// import { Switch } from "@progress/kendo-react-inputs";
import { Drawer, DrawerContent, DrawerItem, } from "@progress/kendo-react-layout";
import { GetToken, GetUserId } from './Session';
import configData from "../config.json";

const CustomItem = (props) => {
    const { visible, ...others } = props;
    const arrowDir = props["data-expanded"]
        ? "k-i-arrow-chevron-down"
        : "k-i-arrow-chevron-right";
    return (
        <React.Fragment>
            {props.visible === false ? null : (
                <DrawerItem {...others}>
                    <span className={"k-icon " + props.icon} />
                    <span className={"k-item-text"}>{props.text}</span>
                    {props["data-expanded"] !== undefined && (
                        <span
                            className={"k-icon " + arrowDir}
                            style={{
                                position: "absolute",
                                right: 10,
                            }}
                        />
                    )}
                </DrawerItem>
            )}
        </React.Fragment>
    );
};
export const DrawerContainer = (props) => {
    const [drawerExpanded, setDrawerExpanded] = React.useState(true);
    const navigate = useNavigate();
    const child = false
    const [items, setItems] = React.useState([
        { text: "Weighbridge", permission: "ViewWeighBridge", selected: false, route: "/Weighbridge", icon: "fa fa fa-solid fa-truck-moving", title: "Weighbridge", id: 2 },
        { text: "Dashboard", permission: "ViewBasicDashboard", selected: false, route: "/", icon: "fa fa fa-solid fa-gauge ", title: "Dashboard", id: 1 },
        { text: "Transactions", permission: "ViewTransactionsPage", selected: false, route: "/transactions", icon: "fa fa fa-solid fa-money-bill ", title: "Transactions", id: 15 },
        { text: "Trucks", permission: "ViewTruck", selected: false, route: "/trucks", icon: "fa fa fa-solid fa-truck ", title: "Trucks", id: 20 },
        { text: "EPA Report", permission: "ViewEPAReport", selected: false, route: "/EPAReport", icon: "fa fa fa-solid fa-gavel", title: "EPA Report", id: 28 },
        { text: "Customer", permission: "ViewCustomer", selected: false, route: "/customers", icon: "fa fa fa-solid fa-user-tie", title: "Customer", id: 4 },
        { text: "First Weighs", permission: "ViewFirstWeigh", selected: false, route: "/firstweighs", icon: "fa fa fa-solid fa-1 ", title: "First Weighs", id: 16 },
        { text: "Docket Lookup", permission: "ViewTransaction", selected: false, route: "/docketlookup", icon: "fa fa fa-solid fa-magnifying-glass ", title: "Docket Lookup", id: 17 },
        { text: "Jobs", permission: "ViewJob", selected: false, route: "/jobs", icon: "fa fa fa-solid fa-list-check ", title: "Jobs", id: 14 },


        { separator: true },
        // Group 2
        { text: "Products", permission: "ViewProductsGroup", selected: false, icon: "fa fa fa-solid fa-cart-flatbed", ["data-expanded"]: child, title: "Products", id: 6 },
        { text: "Waste Stream", permission: "ViewProductCategory", selected: false, route: "/wastestreams", icon: "fa fa fa-solid fa-recycle", title: "Waste Stream", id: 7, parentId: 6 },
        { text: "Product", permission: "ViewProduct", selected: false, route: "/products", icon: "fa fa fa-solid fa-boxes ", title: "Product", id: 8, parentId: 6 },
        { text: "Counted Product", permission: "ViewProduct", selected: false, route: "/countedItemProducts", icon: "fa fa fa-solid fa-calculator ", title: "Counted Product", id: 9, parentId: 6 },
        { text: "Source", permission: "ViewSource", selected: false, route: "/sources", icon: "fa fa fa-duotone fa-map-location-dot ", title: "Source", id: 12, parentId: 6 },
        { text: "Destination", permission: "ViewDestination", selected: false, route: "/destinations", icon: "fa fa fa-solid fa-map-location ", title: "Destination", id: 13, parentId: 6 },
        { text: "Inventory", permission: "ViewInventory", selected: false, route: "/inventory", icon: "fa fa fa-solid fa-warehouse ", title: "Inventory", id: 10, parentId: 6 },

        { separator: true },
        // Group 4
        { text: "Other Data", permission: "ViewOtherDataGroup", selected: false, icon: "fa fa fa-solid fa-bars ", ["data-expanded"]: child, title: "Other Data", id: 18 },
        { text: "Supplier", permission: "ViewCustomer", selected: false, route: "/suppliers", icon: "fa fa fa-solid fa-user-tag ", title: "Supplier", id: 5, parentId: 18 },
        { text: "Driver", permission: "ViewDriver", selected: false, route: "/drivers", icon: "fa fa fa-solid fa-id-card ", title: "Driver", id: 19, parentId: 18 },
        { text: "Vehicle Type", permission: "ViewVehicleType", selected: false, route: "/vehicles", icon: "fa fa fa-solid fa-car ", title: "Vehicle Type", id: 21, parentId: 18 },
        { text: "Truck Configuration", permission: "ViewTruckConfiguration", selected: false, route: "/vehicleconfigurations", icon: "fa fa fa-duotone fa-trailer ", title: "Site", id: 22, parentId: 18 },
        { text: "Vouchers", permission: "ViewVouchers", selected: false, route: "/vouchers", icon: "fa fa fa-solid fa-barcode ", title: "Vouchers", id: 23, parentId: 18 },
        { text: "Messages", permission: "ViewMessageQueue", selected: false, route: "/messages", icon: "fa fa fa-solid fa-envelope ", title: "Messages", id: 124, parentId: 18 },

        { separator: true },
        // Group 6
        { text: "Reports", permission: "ViewReportsGroup", selected: false, icon: "fa fa fa-solid fa-file ", ["data-expanded"]: child, title: "Reports", id: 25 },
        { text: "table Reports", permission: "ViewPivotTables", selected: false, route: "/reports", icon: "fa fa fa-solid fa-table ", title: "table Reports", id: 26, parentId: 25 },
        { text: "Simple Report", permission: "ViewSimpleReports", selected: false, route: "/SimpleReports", icon: "fa fa fa-regular fa-file ", title: "Simple Report", id: 27, parentId: 25 },

    { separator: true },
    // Group 7
        { text: "Settings", permission: "ViewSettingsGroup", selected: false, icon: "fa fa fa-solid fa-gear ", ["data-expanded"]: child, title: "Settings", id: 29 },
    { text: "Site", permission: "ViewSite", selected: false, route: "/sites", icon: "fa fa fa-solid fa-sitemap ", title: "Site", id: 2, parentId: 29 },
    { text: "Roles", permission: "ViewRole", selected: false, route: "/roles", icon: "fa fa fa-solid fa-users ", title: "Roles", id: 30, parentId: 29 },
    { text: "Operators", permission: "ViewUser", selected: false, route: "/users", icon: "fa fa fa-solid fa-user ", title: "Operators", id: 31, parentId: 29 },
    { text: "Change Password", permission: "ViewUser", selected: false, route: "/password", icon: "fa fa fa-solid fa-key ", title: "Change Password", id: 32, parentId: 29 },
    { text: "User Approval", permission: "ViewUserApproval", selected: false, route: "/userapproval", icon: "fa fa fa-solid fa-thumbs-up ", title: "User Approval", id: 33, parentId: 29 },
    { text: "Support Report", permission: "ViewSupport", selected: false, route: "/support", icon: "fa fa fa-solid fa-handshake-angle ", title: "Support Report", id: 36, parentId: 29 },
    { text: "Docket Templates", permission: "isAWSSupportRole", selected: false, route: "/dockets", icon: "fa fa fa-solid fa-receipt ", title: "Docket Templates", id: 37, parentId: 29 },
    { text: "User Interface", permission: "isAWSSupportRole", selected: false, route: "/managepages", icon: "fa fa fa-solid fa-users-gear ", title: "User Interface", id: 38, parentId: 29 },
    { text: "System Settings", permission: "isAWSSupportRole", selected: false, route: "/systemsetting", icon: "fa fa fa-solid fa-gear ", title: "System Settings", id: 39, parentId: 29 },
    { text: "Business Data", permission: "isAWSSupportRole", selected: false, route: "/businessdata", icon: "fa fa fa-solid fa-business-time ", title: "Business Data", id: 40, parentId: 29 },
    { text: "Output States", permission: "isAWSSupportRole", selected: false, route: "/outputstate", icon: "fa fa fa-solid fa-chess-rook ", title: "Output States", id: 41, parentId: 29 },
    { text: "Weighbridge Settings", permission: "isAWSSupportRole", selected: false, route: "/weighbridgesettings", icon: "fa fa fa-solid fa-screwdriver-wrench ", title: "Weighbridge Settings", id: 42, parentId: 29 },
        { text: "Manage View", permission: "isAWSSupportRole", selected: false, route: "/managepages", icon: "fa fa fa-solid fa-street-view ", title: "Manage View", id: 43, parentId: 29 },

        { separator: true },
        // Group 5
        { text: "Logs", permission: "ViewLogsGroup", selected: false, icon: "fa fa fa-solid fa-binoculars", ["data-expanded"]: child, title: "Logs", id: 50 },
        { text: "Event Log", permission: "ViewPrintEventLog", selected: false, route: "/eventlogs", icon: "fa fa fa-solid fa-eye ", title: "Event Log", id: 51, parentId: 50 },
        { text: "Offences", permission: "ViewOffence", selected: false, route: "/offences", icon: "fa fa fa-solid fa-xmark", title: "Offences", id: 52, parentId: 50 },

        { text: "Online Training", permission: "ViewTraining", selected: false, route: "/videotraining", icon: "fa fa fa-solid fa-chalkboard ", title: "Online Training", id: 60 },
    ]);
    const [expanded, setExpanded] = React.useState(false);
    const data = items.map((item) => {
        const { parentId, ...others } = item;
        if (parentId !== undefined) {
            const parent = items.find((parent) => parent.id === parentId);
            return {
                ...others,
                visible: parent["data-expanded"],
            };
        }
        return item;
    });

    const CheckPermission = (p, t) => {
        return p.find(obj => obj === t) === t;
    }

    React.useEffect(() => {
        var url = `${configData.SERVER_URL}apiv7/options?userId=${GetUserId()}`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            let newItems = [];
            let isLocal = window.location.href.indexOf("localhost") >= 0 || window.location.href.indexOf("192.168") >= 0;
            if (configData.SITE === 1) isLocal = false;
            for (let i = 0; i < items.length; i++) {
                if (items[i].text !== "Weighbridge" || isLocal) {
                    if (CheckPermission(d.permissions, items[i].permission) || items[i].separator) newItems.push(items[i]);
                }
            }
            setItems(newItems);
        });
    }, [])

    // const [position, setPosition] = React.useState(true);

    // let positionMode = position ? "start" : "end";

    const handleClick = () => {
        setDrawerExpanded(!drawerExpanded);
    };

    const onSelect = (ev) => {

        const currentItem = ev.itemTarget.props;
        if (currentItem["data-expanded"] === undefined) { setDrawerExpanded(false); }
        const isParent = currentItem["data-expanded"] !== undefined;
        const nextExpanded = !currentItem["data-expanded"];
        const newData = items.map((item) => {
            const {
                selected,
                ["data-expanded"]: currentExpanded,
                id,
                ...others
            } = item;
            const isCurrentItem = currentItem.id === id;
            return {
                selected: isCurrentItem,
                ["data-expanded"]:
                    isCurrentItem && isParent ? nextExpanded : currentExpanded,
                id,
                ...others,
            };
        });
        //props.history.push(ev.itemTarget.props.route);
        setItems(newData);
        navigate(ev.itemTarget.props.route);
        setExpanded(false);
    };

    // const handleChange = () => {
    //   setPosition((prevState) => !prevState);
    // };




    return (
        <div>
            <Header onButtonClick={handleClick} />
            <Drawer
                expanded={drawerExpanded}
                mode="push"
                width={180}
                items={data}
                onSelect={onSelect}
                mini={false}
                item={CustomItem}
                mouseenter={onSelect}
            >
                <DrawerContent>{props.children}</DrawerContent>
            </Drawer>
        </div>
    );
};

export default DrawerContainer;
