import * as React from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTitle,
  ChartLegend,
  exportVisual
} from "@progress/kendo-react-charts";
import configData from "../../config.json";
import { GetToken } from "../../components/Session";
import "hammerjs";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import DashboardCards from "./DashboardCards";
import { exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";

const MonthlyTrend = () => {
  const dayNos = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
  const [categories, setCategories] = React.useState(null);
  const [monthlySales, setMonthlySales] = React.useState(null);
  const [productPerformance, setProductPerformance] = React.useState(null);
  const [pieData, setPieData ] = React.useState(null);
  const [accumulated, setAccumulated] = React.useState(null);
  const [siteHeading, setSiteHeading ] = React.useState("Site Performance");
  const [expanded, setExpanded] = React.useState(false);
  const sale = React.useRef(null);
  const product = React.useRef(null);
  const total = React.useRef(null);
  const site = React.useRef(null);
  const [title, setTitle] = React.useState("");
  const [salesYAxis, setSalesYAxis] = React.useState("");
  const [productYAxis, setProductYAxis] = React.useState("");
  const [accYAxis, setAccYAxis] = React.useState("");
  const onImageExportClick = (e) => {
    var chart = e.currentTarget.name;
    var chartVisual = null;
    if(chart == 'sales'){
       chartVisual = sale.current && exportVisual(sale.current);
    }
    else if(chart == 'product'){
       chartVisual = product.current && exportVisual(product.current);
    }
    else if(chart == 'total'){
       chartVisual = total.current && exportVisual(total.current);
    }
    else{
       chartVisual = site.current && exportVisual(site.current);
    }
    
    if (chartVisual) {
      exportPDF(chartVisual, {
        paperSize: "A4",
        landscape: true,
      }).then((dataURI) => saveAs(dataURI, "Weekly Performance.pdf"));
    }
  };
    React.useEffect(() => {
        const timeOffset = new Date().getTimezoneOffset() / 60;
        const baseUrl = configData.SERVER_URL + `apiV7/dashboard/getmonthlytrends?offset=${timeOffset}`;
        const init = {
          method: "GET",
          accept: "application/json",
          headers: {"Authorization": "Bearer " + GetToken()},
        };
        fetch(baseUrl, init)
          .then((response) => response.json())
          .then((d) => {
            setMonthlySales(d.monthlySales);
            setProductPerformance(d.productPerformance);
            setAccumulated(d.accumulated);
            setCategories(d.productNames);
            setPieData(d.pieData);
            if (d.sites === 1) setSiteHeading("Product Share");
            setTitle(d.title);
            setSalesYAxis(d.salesY);
            setProductYAxis(d.productY);
            setAccYAxis(d.accY);
          });
  },[]);

  return (
    <>
      <ExpansionPanel
        title="Quick View"
        expandIcon="fa fa-plus"
        collapseIcon="fa fa-minus"
        expanded={expanded}
        onAction={(e) => setExpanded(!e.expanded)}
      >
        <Reveal>
          {expanded && (
            <ExpansionPanelContent>
                <DashboardCards period="Monthly"></DashboardCards>
            </ExpansionPanelContent>
          )}
        </Reveal>

      </ExpansionPanel>
      <div className="row" style={{ padding: "5px 10px" }}></div>{" "}
      <div className="row mb-3">
        {monthlySales && <div className="col-6">
          <button className="k-button" name="sales" onClick={onImageExportClick}>Export as PDF</button>
          <div className="k-card">
            <Chart ref={sale} 
              style={{
                height: 350,
              }}
            >
              <ChartTitle
                text={title}
                font="22Pt bold"
                color="#00008B"
              />
              <ChartLegend position="top" orientation="horizontal" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={dayNos}
                  startAngle={45}
                />
              </ChartCategoryAxis>
              <ChartValueAxis>
                 <ChartValueAxisItem title={{ text: salesYAxis}} />
              </ChartValueAxis>
              <ChartSeries>
                {monthlySales.map((item, idx) => (
                  <ChartSeriesItem
                    key={idx}
                    type="line"
                    tooltip={{
                      visible: true,
                    }}
                    data={item.data}
                    name={item.name}
                  />
                ))}
              </ChartSeries>
            </Chart>
          </div>
        </div>}
        {productPerformance && <div className="col-6">
          <button className="k-button" name="product" onClick={onImageExportClick}>Export as PDF</button>
          <div className="k-card">
            <Chart  ref={product}
              style={{
                height: 350,
              }}
            >
              <ChartTitle
                text="Product Performance (Top 5)"
                font="22Pt bold"
                color="#00008B"
              />
              <ChartLegend position="top" orientation="horizontal" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={categories}
                  startAngle={45}
                />
               </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem title={{ text: productYAxis }} />
                </ChartValueAxis>
              <ChartSeries>
                {productPerformance.map((item, idx) => (
                  <ChartSeriesItem
                    key={idx}
                    type="column"
                    tooltip={{
                      visible: true,
                    }}
                    data={item.data}
                    name={item.name}
                  />
                ))}
              </ChartSeries>
            </Chart>
          </div>
        </div>}
      </div>
      <div className="row">
      {accumulated && <div className="col-6">
          <button className="k-button" name="total" onClick={onImageExportClick}>Export as PDF</button>
          <div className="k-card">
            <Chart ref={total}
              style={{
                height: 350,
              }}
            >
              <ChartTitle
                text="Accumulated Total"
                font="22Pt bold"
                color="#00008B"
              />
              <ChartLegend position="top" orientation="horizontal" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={dayNos}
                  startAngle={45}
                />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem title={{ text: accYAxis }} />
                </ChartValueAxis>

              <ChartSeries>
                {accumulated.map((item, idx) => (
                  <ChartSeriesItem
                    key={idx}
                    type="area"
                    tooltip={{
                      visible: true,
                    }}
                    data={item.data}
                    name={item.name}
                  />
                ))}
              </ChartSeries>
            </Chart>
          </div>
        </div>}
        {pieData && <div className="col-6">
          <button className="k-button" name="site" onClick={onImageExportClick}>Export as PDF</button>
          <div className="k-card">
            <Chart ref={site}
              style={{
                height: 350,
              }}
            >
              <ChartTitle
                text={siteHeading}
                font="22Pt bold"
                color="#00008B"
              />
              <ChartLegend position="top" orientation="horizontal" />
              <ChartSeries>
                <ChartSeriesItem
                  type="pie"
                  overlay={{
                    gradient: "sharpBevel",
                  }}
                  tooltip={{
                    visible: true,
                  }}
                  data={pieData}
                  categoryField="name"
                  field="share"
                />
              </ChartSeries>
            </Chart>
          </div>
        </div>}
      </div>
    </>
  );
};
export default MonthlyTrend;
