import * as React from 'react';
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import { Notification } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import { ColorPicker } from "@progress/kendo-react-inputs";
import { Switch } from "@progress/kendo-react-inputs";

const Settings = () => {

    const [swatch, setSwatch] = React.useState("rgba(49,118,122,1)");
    const [color, setColor] = React.useState("rgba(255,255,255,1)");
    const [themes, setthemes] = React.useState(["theme-default", "theme-classic", "theme-bootstrap", "theme-material", "theme-fluent"]);
    const [theme, settheme] = React.useState("");
    const [fontcolors, setfontcolors] = React.useState(["Black", "White"]);
    const [fontcolor, setfontcolor] = React.useState("");
    const [backgroundcolors, setbackgroundcolors] = React.useState(["Yes", "No"]);
    const [backgroundcolor, setbackgroundcolor] = React.useState("");
    const [defaultinterface, setdefaultinterface] = React.useState("");
    const [disable, setDisable] = React.useState(false);
    const [layout, setLayout] = React.useState([
        {"field": "swatch", "label": "Theme", "defaultValue": "Main", "isVisible": true, "type": "list", "format": "" },
        {"field": "resetAccount", "label": "Clear All Filters", "defaultValue": "", "isVisible": true, "type": "text", "format": ""}
    ]);
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");

    const [message, setMessage] = React.useState("");
    const DisplayMessage = (m) => {
        setMessage(m);
        setTimeout(() => { setMessage("")}, 3000);
    }
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }
    
    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/offences
    React.useEffect(() => {
        GetPermissions(["ViewSettings"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=settings`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: {"Authorization": "Bearer " + GetToken()}
          }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({userInterfaceId: d[i].userInterfaceId, field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
            let def = d.find(obj => { return obj.field === "defaultinterface" });
            let s = d.find(obj => { return obj.field === "swatch" });
            let t = d.find(obj => { return obj.field === "theme" });
            let f = d.find(obj => { return obj.field === "fontcolor" });
            let b = d.find(obj => { return obj.field === "background" });
            setSwatch(s.defaultValue);
            settheme(t.defaultValue);
            setfontcolor(f.defaultValue);
            setbackgroundcolor(b.defaultValue);
            setdefaultinterface(def.defaultValue);
        });
    }, []);


    const swatchChanged = (e) => {
        
        let s = layout.find(obj => { return obj.field === "swatch" });
        let id = s.userInterfaceId;
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/${id}`;
        let upd = { ...s };
        if (e === undefined) {
            upd.defaultValue = "";
            setSwatch("");
        }
        else {
            upd.defaultValue = e.value;
            setSwatch(e.value);
        }
        
        const init = {
          method: 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify(upd)
        }
        fetch(url, init).then(response => response).then(d => { 
            DisplayMessage("primary color set to: " + e.value);
        });        
    }
    const themeChanged = (e) => {
        setSwatch(e.value);
        let s = layout.find(obj => { return obj.field === "theme" });
        let id = s.userInterfaceId;
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/${id}`;
        let upd = { ...s };
        upd.defaultValue = e.value;
        const init = {
            method: 'PUT',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify(upd)
        }
        fetch(url, init).then(response => response).then(d => {
            DisplayMessage("Theme set to: " + e.value);
            settheme(e.value);
            window.location.reload();
        });
    }
    const fontcolorChanged = (e) => {
        let s = layout.find(obj => { return obj.field === "fontcolor" });
        let id = s.userInterfaceId;
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/${id}`;
        let upd = { ...s };
        if (e === undefined) {
            upd.defaultValue = "Black";
            setfontcolor("Black");
        }
        else {
            upd.defaultValue = e.value;
            setfontcolor(e.value);
        }
        
        const init = {
            method: 'PUT',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify(upd)
        }
        fetch(url, init).then(response => response).then(d => {
            DisplayMessage("font color set to: " + e.value);
            setfontcolor(e.value);
            window.location.reload();
        });
    }
    const setbackground = (e) => {
        let s = layout.find(obj => { return obj.field === "background" });
        let id = s.userInterfaceId;
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/${id}`;
        let upd = { ...s };
        if (e === undefined) {
            upd.defaultValue = "No";
        }
        else {
            upd.defaultValue = e.value;
        }
        
        const init = {
            method: 'PUT',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify(upd)
        }
        fetch(url, init).then(response => response).then(d => {
            DisplayMessage("background set to: " + e.value);
            setfontcolor(e.value);
            window.location.reload();
        });
        // Logs the current checked state of the Switch.
    };
    const changedefaultinterface = (e) => {
        swatchChanged();
        setbackground();
        fontcolorChanged();
        window.location.reload();
        // Logs the current checked state of the Switch.
    };
    const reloadpage = () => {
        window.location.reload();
    }
    



    return (
        <div >
            {message && <Fade><Notification type={{ style: "success", icon: true }}><span>{message}</span></Notification></Fade>}
            <div className="row" style={{ padding: 30, paddingBottom : 10 }}>
                <div className='col-md-2' style={{ paddingTop: 20 }}>
                    <Button onClick={() => changedefaultinterface()} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Set to Default Interface</Button>
                </div>
                {GetField("swatch")["isVisible"] && <div className='col-md-2' style={{ paddingBottom: 30 }}>
                    <label>{GetField("swatch")["label"]}</label>
                    <DropDownList disabled={disable} id={"swatch"} name={"swatch"} data={themes} value={theme} onChange={themeChanged} />
                </div>}
                
                <div className="col-md-2" >
                    <label style={{ width: 300 }}>Primary Color</label>
                    <ColorPicker
                        disabled={disable}
                        
                        view="gradient"
                        value={swatch}
                        onChange={swatchChanged}
                        gradientSettings={{
                            backgroundColor: color,
                        }}
                    />
                </div>
                
            </div>
            <div className="row" style={{ paddingLeft: 30 }}>
                <div className='col-md-2' style={{ paddingBottom: 30 }}>
                    <label>Font Color</label>
                    <DropDownList disabled={disable} id={"fontcolor"} name={"fontcolor"} data={fontcolors} value={fontcolor} onChange={fontcolorChanged} />
                </div>
                <div className='col-md-2' style={{ paddingBottom: 30 }}>
                    <label>Set Background Color</label>
                    <DropDownList disabled={disable} id={"background"} name={"background"} data={backgroundcolors} value={backgroundcolor} onChange={setbackground} />
                </div>
                <div className="col-md-2" style={{ paddingTop: 20 }}>
                    <Button onClick={() => reloadpage()} type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Update</Button>
                </div>
            </div>
                
            
        </div>

    );
}

export default Settings