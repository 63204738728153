import * as React from "react";
import { useParams } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import configData from "../config.json";
import { GetToken } from "../components/Session";
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";

const PrintVouchers = () => {
    const { id } = useParams(); // offences/id url is used to edit existing offense, otherwise id is undefined
    const [printText, setPrintText] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const [voucher, setVoucher] = React.useState();
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/");

    React.useEffect(() => {
        GetPermissions(["ViewTransaction"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/private/getvoucherdata?voucherid=${id}`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            d.startDate = d.startDate !== null ? new Date(d.startDate).toDateString("dd/MM/YYYYs") :"Immediately";
            d.endDate = d.endDate !== null ? new Date(d.endDate).toDateString() : "No expiry";
            let disc = "";
            switch (d.discountType) {
                case "Weight": disc = `First ${d.discountAmount}t free`; break;
                case "Weight%": disc = `${d.discountAmount}% off weight`; break;
                case "Price$": disc = `$${d.discountAmount.tofixed(2)} discount`; break;
                case "Price%": disc = `${d.discountAmount}% discount`; break;
            }
            d.discountText = disc;
            setVoucher(d);
        });
    }, []);

    const Print = () => {
        var newWin = window.open('', 'Print-Window');
        newWin.document.open();
        newWin.document.write(`<html><head><title>Voucher ${voucher.code}</title></head><body onload="window.print()" style="font-family:Arial"><div style="padding:20px;width:450px;border:solid black"><h1>Weighbridge Voucher</h1><img src="${voucher.barcode}"></img><div style="padding-top:20px">Code: ${voucher.code}</div><div>Valid from: ${voucher.startDate}</div><div>Expires:${voucher.endDate}</div><div>Discount: ${voucher.discountText}</div ></div></body></html>`);
        newWin.document.close();
        setTimeout(function () { newWin.close(); }, 10);
    }

    return (
        <div>
            <div className="col-md-12" style={{ padding: "20px" }}>
                <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={Print}>Print</Button>
                <a href="/vouchers"><Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Back</Button></a>
            </div>
            {voucher && <div style={{ padding: "20px", width: "450px", border:"solid" }}>
                <h1>Weighbridge Voucher</h1>
                <img src={voucher.barcode}></img>
                <div style={{ paddingTop: "20px" }}>Code: {voucher.code}</div>
                {voucher.startDate !== "" && <div> Valid from: {voucher.startDate}</div>}
                {voucher.endDate !== "" && <div> Expires: {voucher.endDate}</div>}
                <div>Discount: {voucher.discountText}</div>
            </div>}
        </div>
    );
}

export default PrintVouchers;
