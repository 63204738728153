import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//@import "@progress/kendo-theme-material/dist/all.scss";
//@import "@progress/kendo-theme-fluent/scss/all.scss";
//@import "@progress/kendo-theme-bootstrap/dist/all.scss";

//$kendo-component-bg: rgba(14, 237, 210, 1);
//@import "@progress/kendo-theme-classic/dist/all.scss;"
//import "@progress/kendo-theme-default/dist/all.scss";
import "./App.scss";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
