import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDateTimePicker, FormDropDownList, FormNumericTextBox, FormTextArea } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from '@progress/kendo-react-labels';

const AddExtraCharge = () => {
    const { id } = useParams(); // addextracharge/id url is used to add a charge to job with that id
    const saveName = "Add extra charge";
    const [sites, setSites] = React.useState([ ]);
    const [users, setUsers] = React.useState([ ]);
    const [feeTypes, setFeeTypes] = React.useState([ ]);
    const [jobName, setJobName] = React.useState("");
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
            { "field": "jobId", "label": "Job", "defaultValue": "", "isVisible": true, "type": "text" },
            { "field": "transactionDate", "label": "Date and Time", "defaultValue": "", "isVisible": true, "type": "date" },
            { "field": "userId", "label": "User", "defaultValue": "1", "isVisible": true, "type": "list" },
            { "field": "siteId", "label": "Site", "defaultValue": "1", "isVisible": true, "type": "list" },
            { "field": "registration1", "label": "Fee Type", "defaultValue": "Re-inspection Fee", "isVisible": true, "type": "list" },
            { "field": "price", "label": "Price", "defaultValue": 0, "isVisible": true, "type": "numeric" },
            { "field": "comment", "label": "Comment", "defaultValue": "", "isVisible": true, "type": "text" }
      ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        jobId: GetField("jobId")["defaultValue"],
        siteId: GetField("siteId")["defaultValue"],
        userId: GetField("userId")["defaultValue"],
        registration1: GetField("registration1")["defaultValue"],
        comment: GetField("comment")["defaultValue"],
        price: GetField("price")["defaultValue"],
        transactionDate: new Date()
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/addextracharge
    React.useEffect(() => {
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'extracharge'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { setLayout(d); });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setSites(opt.sites);
            setUsers(opt.users);
            setFeeTypes(opt.feeTypes);
            var jn = opt.jobs.find(obj => { return obj.id === String(id) });
            setJobName(jn.name);
            setValues({
                siteId: opt.sites[0],
                userId: opt.users[0],
                registration1: opt.feeTypes[0],
                comment: values.comment,
                price: values.price,
                transactionDate: values.transactionDate
            })
            setFormkey(formkey + 1);
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var url = `${configData.SERVER_URL}apiv7/Private/postfee`;
        var utcDate = dataItem.transactionDate.toISOString();
        const init = {
          method: 'POST',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "jobId": id,
            "price": GetDefaultData(dataItem.price, "price"),
            "feeType": GetDefaultData(dataItem.registration1, "registration1"),
            "comment":  GetDefaultData(dataItem.comment, "comment"),
            "transactionDate": utcDate,
            "siteId": GetDefaultData(dataItem.siteId.id, "site"),
            "userId": GetDefaultData(dataItem.userId.id, "user")
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = "New charge created";
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/jobs", {state: {msg: msg}});
    }

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) =>  value ? "" : "Please select an option";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                           {GetField("jobId")["isVisible"] && <div className="col-md-3">
                                <Label>{GetField("jobId")["label"]}</Label>
                                <div>{jobName}</div>
                            </div>}
                            {GetField("transactionDate")["isVisible"] && <div className="col-md-3"> 
                                <Field id={"transactionDate"} name={"transactionDate"} label={GetField("transactionDate")["label"]} component={FormDateTimePicker}
                                    wrapperStyle={{ width: "90%", marginRight: "18px",}}/>
                            </div>}
                            {GetField("userId")["isVisible"] && <div id="userShow" className='col-md-3'>
                                <Field id={"userId"} name={"userId"} label={GetField("userId")["label"]} component={FormDropDownList} textField={"name"} data={users} validator={optionValidator} />
                            </div>}
                            {GetField("siteId")["isVisible"] && <div className='col-md-3'>
                                <Field id={"siteId"} name={"siteId"} label={GetField("siteId")["label"]} component={FormDropDownList} textField={"name"} data={sites} validator={optionValidator} />
                            </div>}
                        </div>
                        <div className='row'>
                            
                        </div>
                        <div className='row'>
                            {GetField("registration1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"registration1"} name={"registration1"} label={GetField("registration1")["label"]} component={FormDropDownList} data={feeTypes} validator={optionValidator}/>
                            </div>}
                            {GetField("price")["isVisible"] && <div className="col-md-3">
                                <Field id={"price"} name={"price"} label={GetField("price")["label"]} component={FormNumericTextBox} min={0} max={100000} format="c2" validator={textValidator} />
                            </div>}                            
                            {GetField("comment")["isVisible"] && <div className="col-md-3">
                                <Field id={"comment"} name={"comment"} label={GetField("comment")["label"]} component={FormTextArea}/>
                            </div>}
                        </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{ paddingTop: 10 }}>
                        <div className='col-md-4'></div>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default AddExtraCharge