import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDropDownList, FormInput, FormSwitch, FormNumericTextBox } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { GetPermissions } from '../components/Options';

const EditBusinessData = () => {
    const { id } = useParams(); // editusers/id url is used to edit existing user, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [sites, setSites] = React.useState();
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "name", "label": "Name", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "dataType", "label": "Type", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "maxPollPeriod", "label": "Poll Period", "defaultValue": 2000, "isVisible": true, "type": "numeric" },
        { "field": "isActive", "label": "Active", "defaultValue": "", "isVisible": true, "type": "boolean" },
        { "field": "deviceType", "label": "Device Type", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "stateEvent", "label": "State Event Generated", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "address", "label": "Address", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "prompt", "label": "Display Order", "defaultValue": 0, "isVisible": true, "type": "text" },
        { "field": "configuration", "label": "Configuration", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "siteId", "label": "Site", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "validationRule", "label": "IP Filter", "defaultValue": 0, "isVisible": true, "type": "text" }
    ]);

    const deviceTypes = [
        { name: "indicator", value: "Indicator" },
        { name: "trafficlights", value: "Traffic Lights" },
        { name: "boomgate", value: "Boom Gate" },
        { name: "remotedisplay", value: "Remote Display" },
        { name: "hidscanner", value: "HID Scanner" },
        { name: "rfid", value: "RFID" },
        { name: "ibutton", value: "iButton Reader" },
        { name: "anpr", value: "Number Plate Reader (ANPR)" },
        { name: "camera", value: "Surveillance Camera" },
        { name: "printer", value: "Printer" },
        { name: "signaturepad", value: "Signature Pad" },
        { name: "eftpos", value: "EFTPOS" }
    ];

    const stateEventTypes = ["None", "IdScanned", "Id2Scanned"];

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        name: GetField("name")["defaultValue"],
        dataType: GetField("dataType")["defaultValue"],
        maxPollPeriod: GetField("maxPollPeriod")["defaultValue"],
        deviceType: GetField("deviceType")["defaultValue"],
        address: GetField("address")["defaultValue"],
        configuration: GetField("configuration")["defaultValue"],
        prompt: GetField("prompt")["defaultValue"],
        siteId: GetField("siteId")["defaultValue"],
        validationRule: GetField("validationRule")["defaultValue"],
        active: String(GetField("isActive")["defaultValue"]).toLowerCase() === "true"
    });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vouchers
    React.useEffect(() => {
        GetPermissions(["EditAppSettings", "NewAppSettings"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditBusinessData'";
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile) {
                let data = [];
                for (let i = 0; i < d.length; i++) {
                    data.push({ field: d[i].field, label: d[i].label, type: d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile });
                }
                setLayout(data);
            } else {
                setLayout(d);
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {
            setSites(opt.sites);
            if (id !== undefined) {
                url = `${configData.SERVER_URL}apiv7/businessdata/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var st = opt.sites.find(obj => { return obj.id === String(d.siteId) });
                    var dt = deviceTypes.find(obj => { return obj.name == d.dataType });
                    setValues({
                        name: d.name,
                        dataType: dt,
                        maxPollPeriod: d.maxPollPeriod,
                        validationRule: d.validationRule === null ? "" : d.validationRule,
                        deviceType: d.deviceType,
                        address: d.deviceAddress,
                        configuration: d.deviceConfiguration,
                        stateEvent: d.stateEvent,
                        prompt: parseInt(d.prompt),
                        siteId: st,
                        active: d.isActive === null ? "" : d.isActive
                    });
                    setFormkey(formkey + 1);
                })
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (f === undefined) f = "";
        if (fld === undefined || fld === null) return f;
        let res = fld["defaultValue"];
        if (fld["isVisible"]) res = f;
        switch (fld["type"]) {
            case "numeric":
                res = parseFloat(res);
                if (isNaN(res)) res = 0;
                break;
            case "boolean": res = (res === "true" || res === true); break;
            case "date":
                if (res === "") res = null;
                break;
            default: break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        let dt = GetDefaultData(dataItem.dataType, "dataType");
        if (dt !== undefined) dt = dt.name;
        let st = GetDefaultData(dataItem.siteId, "siteId");
        if (st !== undefined) st = st.id;
        var url = `${configData.SERVER_URL}apiv7/businessdata${pth}`;
        const init = {
            method: id === undefined ? 'POST' : 'PUT',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "businessDataId": id === undefined ? 0 : id,
                "name": GetDefaultData(dataItem.name, "name"),
                "dataType": dt,
                "maxPollPeriod": GetDefaultData(dataItem.maxPollPeriod, "maxPollPeriod"),
                "deviceType": GetDefaultData(dataItem.deviceType, "deviceType"),
                "deviceAddress": GetDefaultData(dataItem.address, "address"),
                "validationRule": GetDefaultData(dataItem.validationRule, "validationRule"),
                "deviceConfiguration": GetDefaultData(dataItem.configuration, "configuration"),
                "stateEvent": GetDefaultData(dataItem.stateEvent, "stateEvent"),
                "prompt": GetDefaultData(dataItem.prompt, "prompt"),
                "siteId": st,
                "isActive": GetDefaultData(dataItem.active, "active")
            })
        }
        fetch(url, init).then(response => response).then(d => {
            var msg = id === undefined ? "Business Data created" : "Business Data updated";
            if (d.status > 299) msg = `Error ${d.status} creating business data`;
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/businessdata", { state: { msg: msg } });
    }
    if (permissions && !permissions.CanEdit) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const textValidator = (value) => !value ? "Please enter a value" : "";
    const optionValidator = (value) => value ? "" : "Please select an option";
    return (
        <div style={{ padding: 20 }}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{ width: "95%" }} >
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                            {GetField("siteId")["isVisible"] && <div className="col-md-3">
                                <Field id={"siteId"} name={"siteId"} label={GetField("siteId")["label"]} component={FormDropDownList} data={sites} textField="name" dataItemKey="id" />
                            </div>}
                            {GetField("isActive")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("isActive")["label"]} component={FormSwitch} />
                            </div>}
                            {GetField("prompt")["isVisible"] && <div className="col-md-3">
                                <Field id={"prompt"} name={"prompt"} label={GetField("prompt")["label"]} component={FormNumericTextBox}  />
                            </div>}
                        </div>
                        <div className="row" style={{ padding: "5px 10px" }}>
                            {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator} />
                            </div>}
                            {GetField("dataType")["isVisible"] && <div className="col-md-3">
                                <Field id={"dataType"} name={"dataType"} label={GetField("dataType")["label"]} component={FormDropDownList} data={deviceTypes} textField="value" dataItemKey="name" />
                            </div>}
                            {GetField("stateEvent")["isVisible"] && <div className="col-md-3">
                                <Field id={"stateEvent"} name={"stateEvent"} label={GetField("stateEvent")["label"]} component={FormDropDownList} data={stateEventTypes} />
                            </div>}
                        </div>
                        <div className="row" style={{ padding: "5px 10px" }}>
                            {GetField("deviceType")["isVisible"] && <div className="col-md-3">
                                <Field id={"deviceType"} name={"deviceType"} label={GetField("deviceType")["label"]} component={FormInput} validator={textValidator} />
                            </div>}
                            {GetField("address")["isVisible"] && <div className="col-md-3">
                                <Field id={"address"} name={"address"} label={GetField("address")["label"]} component={FormInput} />
                            </div>}
                            {GetField("maxPollPeriod")["isVisible"] && <div className="col-md-3">
                                <Field id={"maxPollPeriod"} name={"maxPollPeriod"} label={GetField("maxPollPeriod")["label"]} component={FormInput} validator={textValidator} />
                            </div>}
                        </div>
                        <div className="row" style={{ padding: "5px 10px" }}>
                            {GetField("validationRule")["isVisible"] && <div className="col-md-3">
                                <Field id={"validationRule"} name={"validationRule"} label={GetField("validationRule")["label"]} component={FormInput} />
                            </div>}
                        </div>
                        <div className="row" style={{ padding: "5px 10px" }}>
                            {GetField("configuration")["isVisible"] && <div className="col-md-9">
                                <Field id={"configuration"} name={"configuration"} label={GetField("configuration")["label"]} component={FormInput} />
                            </div>}
                        </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{ paddingTop: 10 }}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Cancel</Button>
                        </div>
                    </div>
                </FormElement>
            )} />
        </div>
    );
}

export default EditBusinessData