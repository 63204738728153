import * as React from 'react';

export const RemoteDisplay = (props) => {
    const values = React.useRef();
    values.current = props.display === undefined ? { Text: "", Weight: 0, MessageTime: 0, WeightTime: 1000 } : props.display;
    const [text, setText] = React.useState("");
    var showingWeight = false;

    var running = 0;

    React.useEffect(() => {
        if (running == 0) {
            running = 1;
            UpdateDisplay();
        }
    }, []);

    const UpdateDisplay = () => {
        if (showingWeight && values.current.Text !== "" && values.current.Text !== undefined) {
            showingWeight = false;
            setText(values.current.Text);
            setTimeout(UpdateDisplay, values.current.MessageTime);
        } else {
            showingWeight = true;
            setText(values.current.Weight.toFixed(2));
            setTimeout(UpdateDisplay, values.current.WeightTime);
        }
    }

    return (
        <div className="remoteDisplay">{text}</div>
    );
}