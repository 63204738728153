import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import configData from "../config.json";
import { GetSession, GetToken } from "../components/Session";
import { TextArea } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "../components/formComponents";
import SignatureCanvas from "../components/SignaturePad";
import { GetPermissions } from '../components/Options';

const EditDocketLookup = () => {
    const { id } = useParams(); // editdocketlookup/id url is used to edit existing signature, otherwise id is undefined
    const [ docket, setDocket ] = React.useState("");
    const [ canvasImg, setCanvasImg ] = React.useState("");
    const [ formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [ editSig, setEditSig ] = React.useState(false);
    const [ signatureId, setSignatureId ] = React.useState(0);
    const navigate = useNavigate();
    const signaturePad = React.useRef(null);
    const [permissions, setPermissions] = React.useState(null);
    const [layout, setLayout] = React.useState([
        {"field": "firstName", "label": "First Name", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        {"field": "lastName", "label": "Last Name", "defaultValue": "", "isVisible": true, "type": "text", "format": ""},
        {"field": "signature", "label": "Signature", "defaultValue": "", "isVisible": true, "type": "text", "format": ""}
      ]);

    React.useEffect(() => {
        GetPermissions(["ViewTransaction", "EditSignatureOnDocket"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=printDocketLookup`;
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = `${configData.SERVER_URL}apiv7/private/getdockettext?transactionid=${id}`;
        fetch(url, init).then(response => response.text()).then(d => { 
            setDocket(d);
            url = `${configData.SERVER_URL}apiv7/signatures/getsignaturefortransaction?transactionid=${id}`;
            fetch(url, init).then(response => {
                if (response.status < 300) return response.json();
            }).then(d => { 
                if (d !== undefined){
                    setSignatureId(d.signatureId);
                    setValues({
                        firstName: d.firstName,
                        lastName: d.lastName,
                        signature: d.content
                    })
                    setCanvasImg("data:image/png;base64," + d.content);
                    setFormkey(formkey + 1);
                }else {
                    setCanvasImg("data:image/bmp;base64,Qk1CAAAAAAAAAD4AAAAoAAAAAQAAAAEAAAABAAEAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP///wCAAAAA");
                    setEditSig(true);
                }
            });
        });
    }, []);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }
    
    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        firstName: GetField("firstName")["defaultValue"],
        lastName: GetField("lastName")["defaultValue"],
        signature: GetField("signature")["defaultValue"]
        });

    const Clear = () => {
        if (editSig){
            signaturePad.current.clear();
        } else {
            setEditSig(true);
        }
        setValues({
            firstName: "",
            lastName: "",
            signature: ""
        });
        setFormkey(formkey + 1);
    }

    const handleSubmit = (dataItem) => {
        let sigData = signaturePad.current === null ? values.signature : signaturePad.current.toDataURL().replace("data:image/png;base64,", "");
        var url = `${configData.SERVER_URL}apiv7/signatures/${signatureId}`;
        if (signatureId === 0) url = `${configData.SERVER_URL}apiv7/signatures`;
        const init = {
          method: signatureId > 0 ? 'PUT' : 'POST',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "signatureId": signatureId,
            "transactionId": id, 
            "content": sigData,
            "firstName": dataItem.firstName,
            "lastName": dataItem.lastName,
            "isActive": true
            })
        }
        fetch(url, init).then(response => response).then(d => { 
            Redirect("Signature updated");
        })
    }

    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/docketlookup", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/");

     return (
        <div style={{padding:"20px"}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                <fieldset className={"k-form-fieldset"}>
                <div className="row" style={{padding:"20px"}}>
                    <div className="col-md-6">
                    <TextArea rows={20} columns={100} value={docket} style={{fontFamily:"Courier", fontWeight:"bold"}}/>
                    </div>
                </div>
                <div className="row" style={{padding:"20px" }}>
                    <label>{GetField("signature")["label"]}</label>
                    <div>
                        {!editSig && <img id="sigImage" src={canvasImg} alt="Sign here" style={{ border:"1px solid green"}} />}
                    </div>
                    {editSig && <div>
                        <SignatureCanvas ref={signaturePad} />
                    </div>}
                </div>
                        <div className="row">
                            {GetField("firstName")["isVisible"] && <div className='col-md-3'>
                                <Field id={"firstName"} name={"firstName"} label={GetField("firstName")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("lastName")["isVisible"] && <div className='col-md-3'>
                                <Field id={"lastName"} name={"lastName"} label={GetField("lastName")["label"]} component={FormInput}/>
                            </div>}
                        </div>
                </fieldset>
                <div className="k-form-buttons" style={{paddingTop: 10}}>
                    <div className='col-md-1'>
                        {permissions && permissions.CanEdit && <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={Clear} >Clear</Button>}
                    </div>
                    <div className='col-md-1'>
                        {permissions && permissions.CanEdit && <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>Save</Button>}
                    </div>
                    <div className='col-md-1'>
                        <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                    </div>
                </div>                   
            </FormElement>         
            )} />   
        </div>
    );
}

export default EditDocketLookup;
