import * as React from "react";
import {
    Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartValueAxis,
    ChartValueAxisItem, ChartTitle, ChartLegend,exportVisual } from "@progress/kendo-react-charts";
import configData from "../../config.json";
import { GetToken } from "../../components/Session";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Splitter } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {  MultiSelect } from "@progress/kendo-react-dropdowns";
import "hammerjs";
import { param } from "jquery";
import { exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";

const PerformanceTrend = () => {
  const [panes, setPanes] = React.useState([{ size: "15%", collapsible: true}, {}]);
  const onChange = (event) => {setPanes(event.newState);};
  const [categories, setCategories] = React.useState(null);
  const [sales, setSales] = React.useState(null);
  const [productPerformance, setProductPerformance] = React.useState(null);
  const [pieData, setPieData ] = React.useState(null);
  const [accumulated, setAccumulated] = React.useState(null);
  const [siteHeading, setSiteHeading ] = React.useState("Site Performance");
  const [loadTypes, setLoadTypes] = React.useState([]);
  const [loadType, setLoadType] = React.useState(JSON.parse(localStorage.getItem('DashboardLoadTypes')));
  const [vehicleTypes, setVehicleTypes] = React.useState([]);
  const [vehicleType, setVehicleType] = React.useState(JSON.parse(localStorage.getItem('DashboardVehicleTypes')));
  const [jobs, setJobs] = React.useState([]);
  const [job, setJob] = React.useState(JSON.parse(localStorage.getItem('DashboardJobs')));
  const [customers, setCustomers] = React.useState([]);
  const [customer, setCustomer] = React.useState(JSON.parse(localStorage.getItem('DashboardCustomers')));
  const [sources, setSources] = React.useState([]);
  const [source, setSource] = React.useState(JSON.parse(localStorage.getItem('DashboardSources')));
  const [destinations, setDestinations] = React.useState([]);
  const [destination, setDestination] = React.useState(JSON.parse(localStorage.getItem('DashboardDestinations')));
  const [sites, setSites] = React.useState([]);
  const [site, setSite] = React.useState(JSON.parse(localStorage.getItem('DashboardSites')));
  const [products, setProducts] = React.useState([]);
  const [product, setProduct] = React.useState(JSON.parse(localStorage.getItem('DashboardProducts')));
  const [directions, setDirections] = React.useState([]);
  const [direction, setDirection] = React.useState(JSON.parse(localStorage.getItem('DashboardDirections')));
  const [labels, setLabels] = React.useState(["1", "2"]);
  const [title, setTitle] = React.useState("");
  const [salesYAxis, setSalesYAxis] = React.useState("");
  const [productYAxis, setProductYAxis] = React.useState("");
  const [accYAxis, setAccYAxis] = React.useState("");
  const sale = React.useRef(null);
  const productgraph = React.useRef(null);
  const total = React.useRef(null);
  const sitegraph = React.useRef(null);
  const onImageExportClick = (e) => {
    var chart = e.currentTarget.name;
    var chartVisual = null;
    if(chart == 'sales'){
       chartVisual = sale.current && exportVisual(sale.current);
    }
    else if(chart == 'product'){
       chartVisual = productgraph.current && exportVisual(productgraph.current);
    }
    else if(chart == 'total'){
       chartVisual = total.current && exportVisual(total.current);
    }
    else{
       chartVisual = sitegraph.current && exportVisual(sitegraph.current);
    }
    
    if (chartVisual) {
      exportPDF(chartVisual, {
        paperSize: "A4",
        landscape: true,
      }).then((dataURI) => saveAs(dataURI, "Weekly Performance.pdf"));
    }
  };

  let dr = JSON.parse(localStorage.getItem('DashboardDates'));
  if (dr !== null){
      if (dr.start !== null) dr.start = new Date(dr.start);
      if (dr.end !== null) dr.end = new Date(dr.end);
  } else {
    dr = { start: null, end: null};
  }
  const [dateRange, setDateRange] = React.useState(dr);

  const rangeChanged = (event) => { 
    let dr = event.target.value;
    setDateRange(dr); 
    localStorage.setItem("DashboardDates", JSON.stringify(dr));
  }
  const loadTypeChanged = (event) => {
    setLoadType(event.target.value);
    localStorage.setItem("DashboardLoadTypes", JSON.stringify(event.target.value));
  };
  const vehicleTypeChanged = (event) => {
    setVehicleType(event.target.value);
    localStorage.setItem("DashboardVehicleTypes", JSON.stringify(event.target.value));
  };
  const jobChanged = (event) => {
    setJob(event.target.value);
    localStorage.setItem("DashboardJobs", JSON.stringify(event.target.value));
  };
  const customerChanged = (event) => {
    setCustomer(event.target.value);
    localStorage.setItem("DashboardCustomers", JSON.stringify(event.target.value));
  };
  const sourceChanged = (event) => {
    setSource(event.target.value);
    localStorage.setItem("DashboardSources", JSON.stringify(event.target.value));
  };
  const destinationChanged = (event) => {
    setDestination(event.target.value);
    localStorage.setItem("DashboardDestinations", JSON.stringify(event.target.value));
  };
  const siteChanged = (event) => {
    setSite(event.target.value);
    localStorage.setItem("DashboardSites", JSON.stringify(event.target.value));
  };
  const productChanged = (event) => {
    setProduct(event.target.value);
    localStorage.setItem("DashboardProducts", JSON.stringify(event.target.value));
  };
  const directionChanged = (event) => {
    setDirection(event.target.value);
    localStorage.setItem("DashboardDirections", JSON.stringify(event.target.value));
  };


  const UpdateData = () => {
    var params = {
      FromDate: dr.start === null ? "2000-01-01T00:00:00Z" : dr.start.toISOString(),
      ToDate: dr.end === null ? "2050-01-01T00:00:00Z" : dr.end.toISOString(),
      LoadType: GetParamList(loadType),
      Source: GetParamIdList(source),
      Destination: GetParamIdList(destination),
      Site: GetParamIdList(site),
      Job: GetParamIdList(job),
      Customer: GetParamIdList(customer),
      Product: GetParamIdList(product),
      VehicleType: GetParamIdList(vehicleType),
      Direction: GetParamList(direction)
    };

    const timeOffset = new Date().getTimezoneOffset() / 60;
    const baseUrl = configData.SERVER_URL + `apiV7/dashboard/getperformancetrends?prm=${encodeURI(JSON.stringify(params))}&offset=${timeOffset}`;
    const init = {
      method: "GET",
      accept: "application/json",
      headers: {"Authorization": "Bearer " + GetToken()},
    };
    fetch(baseUrl, init)
      .then((response) => response.json())
      .then((d) => {
        setSales(d.sales);
        setProductPerformance(d.productPerformance);
        setAccumulated(d.accumulated);
        setPieData(d.pieData);
        setCategories(d.productNames);
        setLabels(d.labels);
        if (d.sites === 1) setSiteHeading("Product Share");
        setTitle(d.title);
        setSalesYAxis(d.salesY);
        setProductYAxis(d.productY);
        setAccYAxis(d.accY);
      });
  }

  const GetParamList = (v) => {
    let r = "";
    if (v === null || v === undefined) return "";
    v.map(function(val, ind){
      if (r !== "") r += ",";
      r += val;
      return r;
    })
    return r;
  }

  const GetParamIdList = (v) => {
    let r = "";
    if (v === null || v === undefined) return "";
    v.map(function(val, ind){
      if (r !== "") r += ",";
      r += val.id;
      return r;
    })
    return r;
  }

  const DoSearch = () => {
    UpdateData();
  };

  const ClearSearch = () => {
    setCustomer("");
    setDateRange("");
    setDestination("");
    setDirection("");
    setJob("");
    setLoadType("");
    setProduct("");
    setSite("");
    setSource("");
    setVehicleType("");
    localStorage.setItem("DashboardDates", null);
    localStorage.setItem("DashboardLoadTypes", null);
    localStorage.setItem("DashboardVehicleTypes", null);
    localStorage.setItem("DashboardJobs", null);
    localStorage.setItem("DashboardCustomers", null);
    localStorage.setItem("DashboardSources", null);
    localStorage.setItem("DashboardDestinations", null);
    localStorage.setItem("DashboardSites", null);
    localStorage.setItem("DashboardProducts", null);
    localStorage.setItem("DashboardDirections", null);
  };

  React.useEffect(() => {
    UpdateData();
    const baseUrl =
      configData.SERVER_URL + "apiv7/options";
    const init = {
      method: "GET",
      accept: "application/json",
      headers: {"Authorization": "Bearer " + GetToken()},
    };
    fetch(baseUrl, init)
      .then((response) => response.json())
      .then((d) => {
        let lt = []; // filter first in transaction
            for(let i = 0; i < d.loadTypes.length; i++){
                if (d.loadTypes[i] !== "First") lt.push(d.loadTypes[i]);
            }
            setLoadTypes(lt);
        setVehicleTypes(d.vehicles);
        setJobs(d.jobs);
        setCustomers(d.customers);
        setSources(d.sources);
        setDestinations(d.destinations);
        setSites(d.sites);
        setProducts(d.products);
        setDirections(d.directions);
      });
  },[]);


  return (
    <>
      <div>
        <Splitter panes={panes} onChange={onChange}>
          <div style={{overflow: "hidden"}}>
            <div className="row"><h6>Filter</h6></div>
            <div className="row">
              <div className="col-md-10" style={{padding: "5px 20px"}}>
                <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged}  />              
              </div>
            </div>
            <div className="row">
            <div className="col-md-10" style={{padding: "5px 20px"}}>
                <label className="control-label">Site</label>
                <MultiSelect id="Site" data={sites} value={site} textField="name" dataItemKey="id" onChange={siteChanged} />
              </div>
            </div>
            <div className="row">
            <div className="col-md-10" style={{padding: "5px 20px"}}>
                <label className="control-label">Job</label>
                <MultiSelect id="Job" data={jobs} value={job} textField="name" dataItemKey="id" onChange={jobChanged} />
              </div>
            </div>
            <div className="row">
            <div className="col-md-10" style={{padding: "5px 20px"}}>
                <label className="control-label">Customer</label>
                <MultiSelect id="Customer" data={customers} value={customer} textField="name" dataItemKey="id" onChange={customerChanged} />
              </div>
            </div>
            <div className="row">
            <div className="col-md-10" style={{padding: "5px 20px"}}>
                <label className="control-label">Source</label>
                <MultiSelect id="Source" data={sources} value={source} textField="name" dataItemKey="id" onChange={sourceChanged} />
              </div>
            </div>
            <div className="row">
            <div className="col-md-10" style={{padding: "5px 20px"}}>
                <label className="control-label">Destination</label>
                <MultiSelect id="Destination" data={destinations} value={destination} textField="name" dataItemKey="id" onChange={destinationChanged} />
              </div>
            </div>
            <div className="row">
            <div className="col-md-10" style={{padding: "5px 20px"}}>
                <label className="control-label">Product</label>
                <MultiSelect id="Product" data={products} value={product} textField="name" dataItemKey="id" onChange={productChanged} />
              </div>
            </div>
            <div className="row">
            <div className="col-md-10" style={{padding: "5px 20px"}}>
                <label className="control-label">Load Type</label>
                <MultiSelect id="LoadType" data={loadTypes} value={loadType} onChange={loadTypeChanged}/>
              </div>
            </div>
            <div className="row">
            <div className="col-md-10" style={{padding: "5px 20px"}}>
                <label className="control-label">Vehicle Type</label>
                <MultiSelect id="VehicleType" data={vehicleTypes} value={vehicleType} textField="name" dataItemKey="id" onChange={vehicleTypeChanged} />
              </div>
            </div>
            <div className="row">
            <div className="col-md-10" style={{padding: "5px 20px"}}>
                <label className="control-label">Direction</label>
                <MultiSelect id="Direction" data={directions} value={direction} onChange={directionChanged}/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10" style={{padding: "5px 20px"}}>
                <Button onClick={DoSearch} themeColor={"primary"}>Search</Button>{" "}
                <Button onClick={ClearSearch} themeColor={"primary"}>Clear</Button>
              </div>
            </div>
             </div>
             <div className="row" style={{overflow: "hidden"}}>
              {sales && <div className="col-6">
                <button name="sales" className="k-button" onClick={onImageExportClick}>Export as PDF</button>
                <div className="k-card">
                  <Chart ref={sale}  style={{height: 350,}}>
                    <ChartTitle text={title} font="22Pt bold" color="#00008B"/>
                    <ChartLegend position="top" orientation="horizontal" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem categories={labels} startAngle={45}/>
                    </ChartCategoryAxis>
                    <ChartValueAxis>
                        <ChartValueAxisItem title={{ text: salesYAxis }} />
                    </ChartValueAxis>
                    <ChartSeries>
                      {sales.map((item, idx) => (
                        <ChartSeriesItem key={idx} type="line" tooltip={{visible: true }} data={item.data} name={item.name}/>
                      ))}
                    </ChartSeries>
                  </Chart>
                </div>
              </div>}
              {productPerformance && <div className="col-6">
                <button name="product" className="k-button" onClick={onImageExportClick}>Export as PDF</button>
                <div className="k-card">
                  <Chart ref={productgraph}  style={{height: 350}}>
                    <ChartTitle text="Product Performance (Top 5)" font="22Pt bold" color="#00008B" />
                    <ChartLegend position="top" orientation="horizontal" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem categories={categories} startAngle={45} />
                    </ChartCategoryAxis>
                    <ChartValueAxis>
                        <ChartValueAxisItem title={{ text: productYAxis }} />
                    </ChartValueAxis>
                    <ChartSeries>
                      {productPerformance.map((item, idx) => (
                        <ChartSeriesItem key={idx} type="column" tooltip={{visible: true}} data={item.data} name={item.name} />
                      ))}
                    </ChartSeries>
                  </Chart>
                </div>
              </div>}
              {accumulated && <div className="col-6">
                <button name="total" className="k-button" onClick={onImageExportClick}>Export as PDF</button>
                <div className="k-card">
                  <Chart ref={total}  style={{ height: 350 }}>
                    <ChartTitle text="Accumulated Total" font="22Pt bold" color="#00008B" />
                    <ChartLegend position="top" orientation="horizontal" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem categories={labels} startAngle={45} />
                    </ChartCategoryAxis>
                    <ChartValueAxis>
                        <ChartValueAxisItem title={{ text: accYAxis }} />
                    </ChartValueAxis>
                    <ChartSeries>
                      {accumulated.map((item, idx) => (
                        <ChartSeriesItem key={idx} type="area" tooltip={{ visible: true }} data={item.data} name={item.name} />
                      ))}
                    </ChartSeries>
                  </Chart>
                </div>
              </div>}
              {pieData && <div className="col-6">
                <button name="site" className="k-button" onClick={onImageExportClick}>Export as PDF</button>
                <div className="k-card">
                  <Chart ref={sitegraph}  style={{height: 350}}>
                    <ChartTitle text={siteHeading} font="22Pt bold" color="#00008B" />
                    <ChartLegend position="top" orientation="horizontal" />
                    <ChartSeries>
                      <ChartSeriesItem type="pie" overlay={{ gradient: "sharpBevel"}} tooltip={{ visible: true}} data={pieData} categoryField="name" field="share"/>
                    </ChartSeries>
                  </Chart>
                </div>
              </div>}
            </div>
        </Splitter>
      </div>
    </>
  );
};
export default PerformanceTrend;
