import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GetField, GetSetting } from "./Helpers";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { NumericTextBox, TextBox } from "@progress/kendo-react-inputs";

export const OperatorData = (props) => {
    const jobs = props.options.jobs;
    const products = props.options.products;
    const customers = props.options.customers;
    const sources = props.options.sources;
    const destinations = props.options.destinations;
    const truckConfigs = props.options.truckConfigs;
    const layout = props.layout;
    const settings = props.settings;
    const controlChanged = props.controlChanged;
    const [selectedJob, setSelectedJob] = React.useState();
    const [selectedProduct, setSelectedProduct] = React.useState();
    const [selectedCustomer, setSelectedCustomer] = React.useState();
    const [selectedSource, setSelectedSource] = React.useState();
    const [selectedDestination, setSelectedDestination] = React.useState();
    const [selectedTruckConfig, setSelectedTruckConfig] = React.useState();
    const [deliveryAddress, setDeliveryAddress] = React.useState("");
    const [haulage, setHaulage] = React.useState(0);
    const [showAllJobs, setShowAllJobs] = React.useState(false);
    const allowTranslinkSelection = GetSetting(settings, "AllowTranslinkSelection");
    const showHaulage = !GetSetting(settings, "DisableHaulageInput");

    React.useEffect(() => {
        setSelectedJob(props.options.selectedJob);
        setSelectedProduct(props.options.selectedProduct);
        setSelectedCustomer(props.options.selectedCustomer);
        setSelectedSource(props.options.selectedSource);
        setSelectedDestination(props.options.selectedDestination);
        setSelectedTruckConfig(props.options.selectedTruckConfig);
        setDeliveryAddress(props.options.deliveryAddress);
        setHaulage(props.options.haulage);
    }, [props.options]);

    const GetOptions = () => {
        return {
            Job: selectedJob,
            Product: selectedProduct,
            Customer: selectedCustomer,
            Source: selectedSource,
            Destination: selectedDestination,
            TruckConfig: selectedTruckConfig,
            DeliveryAddress: deliveryAddress,
            Haulage: haulage
        };
    }

    const DropdownChanged = (e) => {
        let opt = GetOptions();
        let name = e.target.props.id;
        switch (name) {
            case "Job": setSelectedJob(e.value);  opt.Job = e.value; break;
            case "Product": opt.Product = e.value; setSelectedProduct(e.value); break;
            case "Customer": opt.Customer = e.value; setSelectedCustomer(e.value); break;
            case "Source": opt.Source = e.value; setSelectedSource(e.value); break;
            case "Destination": opt.Destination = e.value; setSelectedDestination(e.value);  break;
            case "TruckConfig": opt.TruckConfig = e.value; setSelectedTruckConfig(e.value); break;
        }
        controlChanged(name, opt);
    }

    const ShowAllChanged = (e) => {
        setShowAllJobs(e.value);
        controlChanged("ShowAllJobs", e.value);
    }

    const AddressChanged = (e) => {
        setDeliveryAddress(e.value);
    }

    const HaulageChanged = (e) => {
        setHaulage(e.value);
    }

    return (
        <div>
            {allowTranslinkSelection && <div>
                <div className="dropdownGap"></div>
                <div className="showAllJobs"><Checkbox value={showAllJobs} size="large" onChange={ShowAllChanged} label={GetField(layout, "showAllJobs")["label"]}></Checkbox></div>
                {GetField(layout, "job")["isVisible"] && <div>
                    <label className="control-label">{GetField(layout,"job")["label"]}</label>
                    <DropDownList id="Job" data={jobs} value={selectedJob} textField="name" dataItemKey="id" onChange={DropdownChanged} />
                </div>}
                {GetField(layout, "product")["isVisible"] && <div>
                    <label className="control-label">{GetField(layout, "product")["label"]}</label>
                    <DropDownList id="Product" data={products} value={selectedProduct} textField="name" dataItemKey="id" onChange={DropdownChanged} />
                </div>}
                {GetField(layout, "customer")["isVisible"] && <div>
                    <label className="control-label">{GetField(layout, "customer")["label"]}</label>
                    <DropDownList id="Customer" data={customers} value={selectedCustomer} textField="name" dataItemKey="id" onChange={DropdownChanged} />
                </div>}
                {GetField(layout, "destination")["isVisible"] && <div>
                    <label className="control-label">{GetField(layout, "destination")["label"]}</label>
                    <DropDownList id="Destination" data={destinations} value={selectedDestination} textField="name" dataItemKey="id" onChange={DropdownChanged} />
                </div>}
                {GetField(layout, "source")["isVisible"] && <div>
                    <label className="control-label">{GetField(layout, "source")["label"]}</label>
                    <DropDownList id="Source" data={sources} value={selectedSource} textField="name" dataItemKey="id" onChange={DropdownChanged} />
                </div>}
                {GetField(layout, "truckConfig")["isVisible"] && <div>
                    <label className="control-label">{GetField(layout, "truckConfig")["label"]}</label>
                    <DropDownList id="TruckConfig" data={truckConfigs} value={selectedTruckConfig} textField="name" dataItemKey="id" onChange={DropdownChanged} />
                </div>}
                {GetField(layout, "deliveryAddress")["isVisible"] && <div>
                    <label className="control-label">{GetField(layout, "deliveryAddress")["label"]}</label>
                    <TextBox id="DeliveryAddress" value={deliveryAddress} onChange={AddressChanged} />
                </div>}
                {GetField(layout, "haulage")["isVisible"] && showHaulage && <div>
                    <label className="control-label">{GetField(layout, "haulage")["label"]}</label>
                    <NumericTextBox id="Haulage" value={haulage} min={0} max={100000} format="c2" onChange={HaulageChanged} />
                    </div>}
            </div>}
        </div>
    )
}