import * as React from 'react';
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { Notification } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import $ from "jquery";

const UserRegistration = () => {
    // default layout data for page
    const [prompt, setPrompt] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const [layout, setLayout] = React.useState([
        { "viewName": "UserRegistration", "field": "customerName", "label": "Customer Name", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "viewName": "UserRegistration", "field": "userName", "label": "User Name", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "viewName": "UserRegistration", "field": "password", "label": "Password", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "viewName": "UserRegistration", "field": "confirmPassword", "label": "Confirm Password", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "viewName": "UserRegistration", "field": "mobile", "label": "Mobile", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "viewName": "UserRegistration", "field": "phone", "label": "Phone", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "viewName": "UserRegistration", "field": "email", "label": "Email", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "viewName": "UserRegistration", "field": "captcha", "label": "Captcha", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        { "viewName": "UserRegistration", "field": "captchaPrompt", "label": "Copy the text from below", "defaultValue": "", "isVisible": true, "type": "text", "format": "" }
     ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        customerName: GetField("customerName")["defaultValue"],
        userName: GetField("userName")["defaultValue"],
        password: GetField("password")["defaultValue"],
        confirmPassword: GetField("confirmPassword")["defaultValue"],
        mobile: GetField("mobile")["defaultValue"],
        phone: GetField("phone")["defaultValue"],
        email: GetField("email")["defaultValue"],
        captcha: GetField("captcha")["defaultValue"],
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/offences
    React.useEffect(() => {
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=userregistration`;
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        setPrompt(Math.random().toString(36).substring(2, 10));
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var url = `${configData.SERVER_URL}apiv7/externalusers`;
        const init = {
          method: 'POST',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "customerName": GetDefaultData(dataItem.customerName, "customerName"),
            "userName":  GetDefaultData(dataItem.userName, "userName"),
            "password":  GetDefaultData(dataItem.password, "password"),
            "mobile":  GetDefaultData(dataItem.mobile, "mobile"),
            "phone":  GetDefaultData(dataItem.phone, "phone"),
            "email":  GetDefaultData(dataItem.email, "email")
            })
        }
        fetch(url, init).then(response => {
            if (response.status > 499) return response.json();
            return response;
        }).then(d => {           
            if (d.status > 499) {
                setErrorMessage("ERROR: " + d.detail);
            } else if (d.status > 299) {
                setErrorMessage("ERROR: " + d.status + ". Please contact support");
            } else {
                setMessage("Registration successful");
            }
            setTimeout(() => {
                setMessage("");
                setErrorMessage("");
            }, 5000);
        });
    }

    // form validators
    const validator = (value) =>  !value ? "Please enter a value" : "";
    const validatorUsername = (value) =>  {
        var re  = /[A-Za-z0-9]+\.[A-Za-z0-9]+/
        if (!re.test(value)) return "Please enter a user name with format site.username";
        return "";
    }
    const validatePassword = (value) =>  {
        var first = $("#password").val();
        if (value !== first) return "Passwords don't match";
        return "";
    }
    const promptValidator = (value) =>  {
        if (value !== prompt) return "Does not match";
        return "";
    }
    return (
        <div style={{padding: 20}}>
            {errorMessage && <Fade><Notification type={{ style: "error", icon: true }}>{errorMessage}</Notification></Fade>}
            {message && <Fade><Notification type={{ style: "success", icon: true }}>{message}</Notification></Fade>}
            <Form onSubmit={handleSubmit} initialValues={values} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                           {GetField("customerName")["isVisible"] && GetField("customerName")["isVisible"] && <div className="col-md-3">
                                <Field id={"customerName"} name={"customerName"} label={GetField("customerName")["label"]} component={FormInput} validator={validator}/>
                            </div>}
                        </div>
                        <div className="row">
                           {GetField("userName")["isVisible"] && GetField("userName")["isVisible"] && <div className="col-md-3">
                                <Field id={"userName"} name={"userName"} label={GetField("userName")["label"]} component={FormInput} validator={validatorUsername}/>
                            </div>}
                           {GetField("password")["isVisible"] && GetField("password")["isVisible"] && <div className="col-md-3">
                                <Field id={"password"} name={"password"} label={GetField("password")["label"]} type={"password"} component={FormInput} validator={validator}/>
                            </div>}
                           {GetField("confirmPassword")["isVisible"] && GetField("confirmPassword")["isVisible"] && <div className="col-md-3">
                                <Field id={"confirmPassword"} name={"confirmPassword"} label={GetField("confirmPassword")["label"]} type={"password"} component={FormInput} validator={validatePassword}/>
                            </div>}
                        </div>
                        <div className="row">
                           {GetField("mobile")["isVisible"] && GetField("mobile")["isVisible"] && <div className="col-md-3">
                                <Field id={"mobile"} name={"mobile"} label={GetField("mobile")["label"]} component={FormInput} validator={validator}/>
                            </div>}
                           {GetField("phone")["isVisible"] && GetField("phone")["isVisible"] && <div className="col-md-3">
                                <Field id={"phone"} name={"phone"} label={GetField("phone")["label"]} component={FormInput}/>
                            </div>}
                           {GetField("email")["isVisible"] && GetField("email")["isVisible"] && <div className="col-md-3">
                                <Field id={"email"} name={"email"} label={GetField("email")["label"]} component={FormInput}/>
                            </div>}
                        </div>                        
                        <div className="row">
                           {GetField("captcha")["isVisible"] && GetField("captcha")["isVisible"] && <div className="col-md-3">
                                <Field id={"captcha"} name={"captcha"} label={GetField("captcha")["label"]} placeholder={GetField("captchaPrompt")["label"]} component={FormInput} validator={promptValidator}/><span id="Prompt" style={{fontSize: "18pt", fontWeight: "bold"}}>{prompt}</span>
                            </div>}
                        </div>                        
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>Register</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}
export default UserRegistration;