import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDropDownList, FormInput, FormSwitch, FormTextArea } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import DataGrid from '../components/DataGrid';

const EditSiteSystem = () => {
    
    const { id } = useParams();
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [selected, setSelected] = React.useState(0);
    const [columns, setColumns] = React.useState(null);
    const [permissions, setPermissions] = React.useState(null);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };
    const GoBack = () => Redirect("");
    const navigate = useNavigate();
    const Redirect = (msg = "") => {
        navigate("/systemsetting", {state: {msg: msg}});
     }
     const WeighBridgeType = [{"id": 1,"name":"Normal_Weighbridge"},{"id": 2,"name":"Multi_Deck_Weighbridge"},{"id": 3,"name":"Axle_Group_Weighbridge"},{"id": 4,"name":"WIM_Axle_Group_Weighbridge"}];
     const [layout, setLayout] = React.useState([
        {"field": "IsJobAdditionalFeeEnabled","label": "Is Job Additional Fee Feature Allowed","defaultValue": "","isVisible": true,"type": "boolean","format": ""},    
        {"field": "NoOfUsersAllowed","label": "No Of Users Allowed","defaultValue": "","isVisible": true,"type": "Numeric","format": ""},    
        {"field": "IsEPALicenced","label": "Is EPA Licenced","defaultValue": "","isVisible": true,"type": "boolean","format": ""},    
        {"field": "SiteCount","label": "No of Sites under this Organization","defaultValue": "","isVisible": true,"type": "text","format": ""},    
        {"field": "IsExternalCustomerEnabled","label": "Is External Customer Enabled","defaultValue": "","isVisible": true,"type": "boolean","format": ""},    
        {"field": "CanAccessBI","label": "Is Business Intelligence Feature Allowed","defaultValue": "","isVisible": true,"type": "text","format": ""},    
        {"field": "ShowVehicleConfigImagetoAdd","label": "Allow Truck Configuration Screen To Add Image","defaultValue": "","isVisible": true,"type": "boolean","format": ""},    
        {"field": "IsProductPricesIncludingGST","label": "Is All Product Prices Including GST Price","defaultValue": "","isVisible": true,"type": "boolean","format": ""},    
        {"field": "IsVehicleTypePricesIncludingGST","label": "Is All Vehicle Type Prices Including GST Price","defaultValue": "","isVisible": true,"type": "boolean","format": ""},    
        {"field": "IsJobCartagePricesIncludingGST","label": "Is All Job Cartage Prices Including GST Price","defaultValue": "","isVisible": true,"type": "boolean","format": ""},    
        {"field": "ChargeOverLoad","label": "Charge OverLoad","defaultValue": "","isVisible": true,"type": "boolean","format": ""},    
        {"field": "HideProductVisitCharges","label": "Hide Product Visit Charges","defaultValue": "","isVisible": true,"type": "boolean","format": ""},    
        {"field": "WeighbridgeType","label": "Weighbridge Type","defaultValue": "","isVisible": true,"type": "numeric","format": ""}]);
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }
    const [firstValues, setfirstValues] =  React.useState({
        SiteCount: GetField("SiteCount")["defaultValue"],
        NoOfUsersAllowed: GetField("NoOfUsersAllowed")["defaultValue"],
        IsEPALicenced: String(GetField("IsEPALicenced")["defaultValue"]).toLowerCase() === "true",
        IsJobAdditionalFeeEnabled: String(GetField("IsJobAdditionalFeeEnabled")["defaultValue"]).toLowerCase() === "true",
        IsExternalCustomerEnabled: String(GetField("IsExternalCustomerEnabled")["defaultValue"]).toLowerCase() === "true",
        CanAccessBI: String(GetField("CanAccessBI")["defaultValue"]).toLowerCase() === "true",
        ShowVehicleConfigImagetoAdd: String(GetField("ShowVehicleConfigImagetoAdd")["defaultValue"]).toLowerCase() === "true",
        HideProductVisitCharges: String(GetField("HideProductVisitCharges")["defaultValue"]).toLowerCase() === "true"
    });

    const [secondValues, setSecondValues] = React.useState({
        IsProductPricesIncludingGST: String(GetField("IsProductPricesIncludingGST")["defaultValue"]).toLowerCase() === "true",
        IsVehicleTypePricesIncludingGST: String(GetField("IsVehicleTypePricesIncludingGST")["defaultValue"]).toLowerCase() === "true",
        IsJobCartagePricesIncludingGST: String(GetField("IsJobCartagePricesIncludingGST")["defaultValue"]).toLowerCase() === "true",
        ChargeOverLoad: String(GetField("ChargeOverLoad")["defaultValue"]).toLowerCase() === "true",
        HideProductVisitCharges: String(GetField("HideProductVisitCharges")["defaultValue"]).toLowerCase() === "true",
        CanAccessBI: String(GetField("CanAccessBI")["defaultValue"]).toLowerCase() === "true",
        WeighbridgeType: GetField("WeighbridgeType")["defaultValue"],
    });

        
    React.useEffect(() => {
        GetColumnDefs("SyncSettings", setColumns);
        GetPermissions(["EditAppSettings"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=Editsitesystem`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        if (id !== undefined){
            if (id === '1'){
                // let resp;
                url = `${configData.SERVER_URL}apiv7/private/editsitesystem/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    let x = d;
                    setfirstValues({
                        SiteCount: x.siteCount,
                        NoOfUsersAllowed: x.noOfUsersAllowed,
                        Licenses: x.licenses,
                        TrainingPerQuarter: x.trainingPerQuarter,
                        SupportPerMonth: x.supportPerMonth,
                        IsEPALicenced: x.isEPALicenced,
                        IsJobAdditionalFeeEnabled : x.isJobAdditionalFeeEnabled,
                        IsExternalCustomerEnabled: x.isExternalCustomerEnabled ,
                        CanAccessBI: x.canAccessBI,
                        ShowVehicleConfigImagetoAdd: x.showVehicleConfigImagetoAdd,
                        HideProductVisitCharges: x.hideProductVisitCharges,
                    });
                    setFormkey(formkey + 1);
                })
                    
            }
            else{
                url = `${configData.SERVER_URL}apiv7/private/editsitesetting/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    let x = d;
                    var c = WeighBridgeType.find(obj => { return obj.id === d.weighbridgeType });
                    if (c == undefined) c = WeighBridgeType.find(obj => { return obj.id === 1 });
                    setSecondValues({
                        IsProductPricesIncludingGST:x.isProductPricesIncludingGST,
                        IsVehicleTypePricesIncludingGST: x.isVehicleTypePricesIncludingGST,
                        IsJobCartagePricesIncludingGST: x.isJobCartagePricesIncludingGST,
                        ChargeOverLoad: x.chargeOverLoad,
                        CanAccessBI: x.canAccessBI,
                        HideProductVisitCharges: x.hideProductVisitCharges,
                        WeighbridgeType : c
                    });
                    setFormkey(formkey + 1);
                })
            }
                
        }
    }, []);

    const SiteSystemSubmit = (dataItem) => {
        var url = `${configData.SERVER_URL}apiv7/private/Editsitesystem/${id}`;
        const init = {
            method: 'PUT',
            accept: "application/json",
            headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
            "siteId": id,
            "siteCount": dataItem.SiteCount,
            "licenses": dataItem.Licenses,
            "TrainingPerQuarter": dataItem.TrainingPerQuarter,
            "SupportPerMonth": dataItem.SupportPerMonth,
            "noOfUsersAllowed": dataItem.NoOfUsersAllowed,
            "isJobAdditionalFeeEnabled": dataItem.IsJobAdditionalFeeEnabled,
            "isEPALicenced": dataItem.IsEPALicenced,
            "isExternalCustomerEnabled": dataItem.IsExternalCustomerEnabled,
            "canAccessBI": dataItem.CanAccessBI,
            "showVehicleConfigImagetoAdd": dataItem.ShowVehicleConfigImagetoAdd,
            "hideProductVisitCharges": dataItem.HideProductVisitCharges})
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = "Setting updated";
            if (d.status > 299) msg = `Error ${d.status} Updating setting`;
            Redirect(msg);
        });
    }

    const SiteSettingsSubmit = (dataItem) => {
        var url = `${configData.SERVER_URL}apiv7/private/Editsitesetting/${id}`;
        const init = {
            method: 'PUT',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "siteId": id,
                "IsProductPricesIncludingGST": dataItem.IsProductPricesIncludingGST,
                "IsVehicleTypePricesIncludingGST": dataItem.IsVehicleTypePricesIncludingGST,
                "IsJobCartagePricesIncludingGST": dataItem.IsJobCartagePricesIncludingGST,
                "ChargeOverLoad": dataItem.ChargeOverLoad,
                "hideProductVisitCharges": dataItem.HideProductVisitCharges,
                "canAccessBI": dataItem.CanAccessBI,
                "WeighbridgeType": dataItem.WeighbridgeType.id
            })
        }
        fetch(url, init).then(response => response).then(d => {
            var msg = "Setting updated";
            if (d.status > 299) msg = `Error ${d.status} Updating setting`;
            Redirect(msg);
        });
    }


    const SyncSettingsCommand = (item, ev) => {
        var url = `${configData.SERVER_URL}apiv7/syncsettings?id=${item.syncSettingId}&value=${ev.value}`;
        const init = {
            method: 'PATCH',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
        }
        fetch(url, init);
    }

    return (
        <div style={{ padding: 20 }}>
            <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title="System Settings">
            {id === '1' && <Form onSubmit={SiteSystemSubmit} initialValues={firstValues} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                        {GetField("SiteCount")["isVisible"] && <div className="col-md-3">
                             <Field id={"SiteCount"} name={"SiteCount"} label={GetField("SiteCount")["label"]} component={FormInput}/>
                        </div>}
                        {GetField("NoOfUsersAllowed")["isVisible"] &&  <div className="col-md-3">
                            <Field id={"NoOfUsersAllowed"} name={"NoOfUsersAllowed"} label={GetField("NoOfUsersAllowed")["label"]} component={FormInput}/>
                        </div>}
                        {GetField("NoOfUsersAllowed")["isVisible"] &&  <div className="col-md-3">
                            <Field id={"TrainingPerQuarter"} name={"TrainingPerQuarter"} label={"Training Per Quarter"} component={FormInput}/>
                        </div>}
                        {GetField("NoOfUsersAllowed")["isVisible"] &&  <div className="col-md-3">
                            <Field id={"SupportPerMonth"} name={"SupportPerMonth"} label={"Support Per Month"} component={FormInput}/>
                        </div>}
                        {GetField("IsJobAdditionalFeeEnabled")["isVisible"] &&  <div className='col-md-3'>
                            <Field id={"IsJobAdditionalFeeEnabled"} name={"IsJobAdditionalFeeEnabled"} label={GetField("IsJobAdditionalFeeEnabled")["label"]} component={FormSwitch} />
                        </div>}
                        {GetField("HideProductVisitCharges")["isVisible"] &&    <div className='col-md-3'>
                            <Field id={"HideProductVisitCharges"} name={"HideProductVisitCharges"} label={GetField("HideProductVisitCharges")["label"]} component={FormSwitch} />
                        </div>}
                        {GetField("IsEPALicenced")["isVisible"] && <div className='col-md-3'>
                            <Field id={"IsEPALicenced"} name={"IsEPALicenced"} label={GetField("IsEPALicenced")["label"]} component={FormSwitch} />
                        </div>}
                        {GetField("IsExternalCustomerEnabled")["isVisible"] &&    <div className='col-md-3'>
                            <Field id={"IsExternalCustomerEnabled"} name={"IsExternalCustomerEnabled"} label={GetField("IsExternalCustomerEnabled")["label"]} component={FormSwitch} />
                        </div>}
                    </div>
                    <div className="row">
                        
                        {GetField("CanAccessBI")["isVisible"] &&<div className='col-md-3'>
                            <Field id={"CanAccessBI"} name={"CanAccessBI"} label={GetField("CanAccessBI")["label"]} component={FormSwitch} />
                        </div>}
                        {GetField("ShowVehicleConfigImagetoAdd")["isVisible"] &&<div className='col-md-3'>
                            <Field id={"ShowVehicleConfigImagetoAdd"} name={"ShowVehicleConfigImagetoAdd"} label={GetField("ShowVehicleConfigImagetoAdd")["label"]} component={FormSwitch} />
                        </div>}
                        
                    </div>
                    <div className="row">
                        {GetField("SiteCount")["isVisible"] && <div className="col-md-3">
                            <Field id={"Licenses"} name={"Licenses"} label={"Licenses"} component={FormTextArea}/>
                        </div>}
                    </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                          <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"  disabled={!formRenderProps.allowSubmit} >Save</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />}
            {id !== '1' && <Form onSubmit={SiteSettingsSubmit} initialValues={secondValues} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                        {GetField("IsProductPricesIncludingGST")["isVisible"] && <div className='col-md-3'>
                            <Field id={"IsProductPricesIncludingGST"} name={"IsProductPricesIncludingGST"} label={GetField("IsProductPricesIncludingGST")["label"]} component={FormSwitch} />
                        </div>}
                        {GetField("IsVehicleTypePricesIncludingGST")["isVisible"] && <div className='col-md-3'>
                            <Field id={"IsVehicleTypePricesIncludingGST"} name={"IsVehicleTypePricesIncludingGST"} label={GetField("IsVehicleTypePricesIncludingGST")["label"]} component={FormSwitch} />
                        </div>}
                        {GetField("IsJobCartagePricesIncludingGST")["isVisible"] &&<div className='col-md-3'>
                        <Field id={"IsJobCartagePricesIncludingGST"} name={"IsJobCartagePricesIncludingGST"} label={GetField("IsJobCartagePricesIncludingGST")["label"]} component={FormSwitch} />
                        </div>}
                        {GetField("ChargeOverLoad")["isVisible"] &&<div className='col-md-3'>
                            <Field id={"ChargeOverLoad"} name={"ChargeOverLoad"} label={GetField("ChargeOverLoad")["label"]} component={FormSwitch} />
                            </div>}
                    </div>
                    <div className="row">
                        
                        {GetField("HideProductVisitCharges")["isVisible"] &&<div className='col-md-3'>
                            <Field id={"HideProductVisitCharges"} name={"HideProductVisitCharges"} label={GetField("HideProductVisitCharges")["label"]} component={FormSwitch} />
                        </div>}
                        {GetField("CanAccessBI")["isVisible"] && <div className='col-md-3'>
                            <Field id={"CanAccessBI"} name={"CanAccessBI"} label={GetField("CanAccessBI")["label"]} component={FormSwitch} />
                        </div>}
                        {GetField("WeighbridgeType")["isVisible"] &&<div className='col-md-3'>
                        <Field id={"WeighbridgeType"} name={"WeighbridgeType"} label={"Bridge Type"} component={FormDropDownList} textField={"name"} data={WeighBridgeType} />
                        </div>}
                    </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"  disabled={!formRenderProps.allowSubmit}>Save</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
                    )} />}
                </TabStripTab>
                <TabStripTab title="Sync Settings">
                    <div className="row">
                        {columns && permissions && <DataGrid dataSource='apiv7/syncsettings' columns={columns} permissions={permissions} keyField="syncsettingsId" customCommand={SyncSettingsCommand} customCommandName="SyncSettingsCommand" />}
                    </div>
                </TabStripTab>
            </TabStrip>
        </div>
    );
}

export default EditSiteSystem