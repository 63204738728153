import * as React from 'react';
import { TextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

export const HIDProcess = (props) => {
    const decks = props.decks ? props.decks : 1;
    const weights = props.weights;
    const showScanId = false;
    const trans = props.trans;

    const buttonClicked = (b) => {
        props.buttonClicked(b);
    }

    return (
        <div id="HIDProcess">
            <div id="HIDPanel1">
                <div className="HIDRow">
                    <div className="HIDSmallText">Configuration:</div>
                    <div className="HIDSmallText">{trans ? trans.configName : ""}</div>
                </div>
                <div className="HIDRow">
                    <div className="HIDSmallText">Plant No:</div>
                    <div className="HIDSmallText">{trans ? trans.registration1 : ""}</div>
                </div>
                <div className="HIDRow">
                    <div className="HIDSmallText">Max GVM:</div>
                    <div className="HIDSmallEntry">{trans ? trans.maxLoad1.toFixed(2) : "0.00"}</div>
                    <div className="HIDSmallTonnes">t</div>
                </div>
                <div className="HIDRow">
                    <div className="HIDSmallText">Trailer No:</div>
                    <div className="HIDSmallText">{trans ? trans.registration2 : ""}</div>
                </div>
                <div className="HIDRow">
                    <div className="HIDSmallText">Max GVM:</div>
                    <div className="HIDSmallEntry">{trans ? trans.maxLoad2.toFixed(2) : "0.00"}</div>
                    <div className="HIDSmallTonnes">t</div>
                </div>
                <div className="HIDRow">
                    <div className="HIDText">Total Max GVM:</div>
                    <div className="HIDEntry">{trans ? trans.maxLoad.toFixed(2) : "0.00"}</div>
                    <div className="HIDSmallTonnes">t</div>
                </div>
            </div>
            {showScanId && <div id="HIDScanID">Scan ID</div>}
            <div id="HIDPanel2">
                <div className="HIDRow">
                    <div className="HIDText">Deck 1</div>
                    <div className="HIDEntry">{weights[1].toFixed(2)}</div>
                    <div className="HIDText">t</div>
                </div>
                {decks > 1 && <div className="HIDRow">
                    <div className="HIDText">Deck 2</div>
                    <div className="HIDEntry">{weights[2].toFixed(2)}</div>
                    <div className="HIDText">t</div>
                </div>}
                {decks > 2 && <div className="HIDRow">
                    <div className="HIDText">Deck 3</div>
                    <div className="HIDEntry">{weights[3].toFixed(2)}</div>
                    <div className="HIDText">t</div>
                </div>}
                {decks > 3 && <div className="HIDRow">
                    <div className="HIDText">Deck 4</div>
                    <div className="HIDEntry">{weights[4].toFixed(2)} </div>
                    <div className="HIDText">t</div>
                </div>}
                <div className="HIDRow">
                    <div className="HIDTotal">Total</div>
                    <div className="HIDEntry HIDTotalEntry">{weights[0].toFixed(2)}</div>
                    <div className="HIDTotal">t</div>
                </div>
            </div>
            {trans && trans.registration1 !== "" && <div className="HIDRow">
                <div id="HIDCancel" className="HIDRedBtn" onClick={() => buttonClicked("Cancel")}>Cancel</div>
                <div id="HIDBack" className="HIDRedBtn" onClick={() => buttonClicked("Back")}>Back</div>
                <div id="HIDConfirm" className="HIDRedBtn" onClick={() => buttonClicked("Confirm")}>Confirm</div>
            </div>}
        </div>
    );
}