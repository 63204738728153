import * as React from 'react';
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormDropDownList } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { GetPermissions } from '../components/Options';
import { GetUserId } from '../components/Session';

const Account = () => {
    const id = GetUserId(); 
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [states, setStates] = React.useState([ ]);
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    const [message, setMessage] = React.useState("");
    if (permissions && !permissions.CanView) navigate("/");    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "password", "label": "Password", "defaultValue": "", "isVisible": true, "type": "password" },
        { "field": "fullName", "label": "Full Name", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "address1", "label": "Address 1", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "address2", "label": "Address 2", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "suburb", "label": "Suburb", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "state", "label": "State", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "postcode", "label": "Postcode", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "phone", "label": "Phone", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "mobile", "label": "Mobile", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "email", "label": "Email", "defaultValue": "", "isVisible": true, "type": "text" }
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        roleId: 0,
        password: GetField("password")["defaultValue"],
        fullName: GetField("fullName")["defaultValue"],
        address1: GetField("address1")["defaultValue"],
        address2: GetField("address2")["defaultValue"],
        suburb: GetField("suburb")["defaultValue"],
        state: GetField("state")["defaultValue"],
        postcode: GetField("postcode")["defaultValue"],
        phone: GetField("phone")["defaultValue"],
        mobile: GetField("mobile")["defaultValue"],
        email: GetField("email")["defaultValue"]
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vehicleconfigurations
    React.useEffect(() => {
        GetPermissions(["ViewAccount"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'Account'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setStates(opt.states);
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/users/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var role = opt.roles.find(obj => { return obj.id === String(d.roleId) });
                    setValues({
                        roleId: d.roleId === null ? "" : role,
                        name: d.name === null ? "" : d.name,
                        password: d.password === null ? "" : d.password,
                        fullName: d.fullName === null ? "" : d.fullName,
                        position: d.position === null ? "" : d.position,
                        description: d.description === null ? "" : d.description,
                        address1: d.address1 === null ? "" : d.address1,
                        address2: d.address2 === null ? "" : d.address2,
                        suburb: d.suburb === null ? "" : d.suburb,
                        state: d.state === null ? "" : d.state,
                        postcode: d.postcode === null ? "" : d.postcode,
                        phone: d.phone === null ? "" : d.phone,
                        mobile: d.mobile === null ? "" : d.mobile,
                        email: d.email === null ? "" : d.email,
                        country: d.country === null ? "" : d.country,
                        addressDetails: d.addressDetails === null ? "" : d.addressDetails,
                        siteDetails: d.siteDetails === null ? "" : d.siteDetails,
                        userSites: d.siteList,
                        active: d.isActive === null ? "" : d.isActive
                    });
                    setFormkey(formkey + 1);
                })
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/users${pth}`;
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "userId": id,
            "roleId": GetDefaultData(dataItem.roleId.id, "roleId"),
            "name": GetDefaultData(dataItem.name, "name"),
            "password": GetDefaultData(dataItem.password, "password"),
            "fullName": GetDefaultData(dataItem.fullName, "fullName"),
            "position": GetDefaultData(dataItem.position, "position"),
            "description": GetDefaultData(dataItem.description, "description"),
            "address1": GetDefaultData(dataItem.address1, "address1"),
            "address2": GetDefaultData(dataItem.address2, "address2"),
            "suburb": GetDefaultData(dataItem.suburb, "suburb"),
            "state": GetDefaultData(dataItem.state, "state"),
            "postcode": GetDefaultData(dataItem.postcode, "postcode"),
            "phone": GetDefaultData(dataItem.phone, "phone"),
            "mobile": GetDefaultData(dataItem.mobile, "mobile"),
            "email": GetDefaultData(dataItem.email, "email"),
            "country": GetDefaultData(dataItem.country, "country"),
            "addressDetails": GetDefaultData(dataItem.addressDetails, "addressDetails"),
            "siteDetails": GetDefaultData(dataItem.siteDetails, "siteDetails"),
            "siteList": dataItem.userSites,
            "isActive": GetDefaultData(dataItem.active, "active") 
           })
        }
        fetch(url, init).then(response => response).then(d => { 
            var msg = "Operator updated";
            Redirect(msg);
        });
    }
    const DisplayMessage = (m) => {
        setMessage(m);
        setTimeout(() => { setMessage("") }, 3000);
    }
    const clearFilters = () => {
        let userId = GetSession().UserId;
        var url = `${configData.SERVER_URL}apiv7/private/deleteuserfilters?userid=${userId}`;
        const init = {
            method: 'DELETE',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache"
        }
        fetch(url, init).then(response => response).then(d => {
            DisplayMessage("Filters cleared");
        });
    }

    // navigation
    const Redirect = (msg = "") => {
        navigate("/transactions", {state: {msg: msg}});
    }

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "95%"}} >
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                            {GetField("fullName")["isVisible"] && <div className="col-md-3">
                                <Field id={"fullName"} name={"name"} label={GetField("fullName")["label"]} disabled={true} component={FormInput}/>
                            </div>}
                            {GetField("password")["isVisible"] && <div className='col-md-3'>
                                <Field id={"password"} name={"password"} label={GetField("password")["label"]} type="password" disabled={true} component={FormInput} validator={textValidator} />
                            </div>}                            
                        </div>
                        <div className='row' >
                            {GetField("address1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address1"} name={"address1"} label={GetField("address1")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("address2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address2"} name={"address2"} label={GetField("address2")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("suburb")["isVisible"] && <div className='col-md-3'>
                                <Field id={"suburb"} name={"suburb"} label={GetField("suburb")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("state")["isVisible"] && <div className='col-md-3'>
                                <Field id={"state"} name={"state"} label={GetField("state")["label"]} component={FormDropDownList} data={states} />
                            </div>}                           
                            {GetField("postcode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"postcode"} name={"postcode"} label={GetField("postcode")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("phone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"phone"} name={"phone"} label={GetField("phone")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("mobile")["isVisible"] && <div className='col-md-3'>
                                <Field id={"mobile"} name={"mobile"} label={GetField("mobile")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("email")["isVisible"] && <div className='col-md-3'>
                                <Field id={"email"} name={"email"} label={GetField("email")["label"]} component={FormInput}  />
                            </div>}
                            <div className='col-md-1' style={{ paddingTop: 50 }}>
                                <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={clearFilters}>Clear user filter</Button>
                            </div>
                        </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>Save</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default Account