import * as React from 'react';
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { GetPermissions } from '../components/Options';
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";

const Password = () => {
    const [permissions, setPermissions] = React.useState(null);
    const [pw1, setPw1] = React.useState("");
    const [pw2, setPw2] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");
    const navigate = useNavigate();

    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "existing", "label": "Existing Password", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "newPassword", "label": "New Password", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "reenterPassword", "label": "Reenter New Password", "defaultValue": "", "isVisible": true, "type": "text" },
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        existing: "",
        newPassword: "",
        reenterPassword: ""
    });

    React.useEffect(() => {
        GetPermissions(["EditUser"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'password'";
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile) {
                let data = [];
                for (let i = 0; i < d.length; i++) {
                    data.push({ field: d[i].field, label: d[i].label, type: d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile });
                }
                setLayout(data);
            } else {
                setLayout(d);
            }
        });
    }, []);

    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (f === undefined) f = "";
        if (fld === undefined || fld === null) return f;
        let res = fld["defaultValue"];
        if (fld["isVisible"]) res = f;
        switch (fld["type"]) {
            case "numeric":
                res = parseFloat(res);
                if (isNaN(res)) res = 0;
                break;
            case "boolean": res = (res === "true" || res === true); break;
            case "date":
                if (res === "") res = null;
                break;
            default: break;
        }
        return res;
    }

    // Handle submit button. 
    const handleSubmit = (dataItem) => {
        var url = `${configData.SERVER_URL}apiv7/users?existing=${dataItem.existing}&newpassword=${dataItem.newPassword}`;
        const init = {
            method: 'PATCH',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache"
        }
        fetch(url, init).then(response => response).then(d => {
            if (!d.ok) {
                if (d.status === 401)
                    setErrorMsg("Existing password incorrect");
                else
                    setErrorMsg("Error updating password");
            } else {
                var msg = "Password updated";
                Redirect(msg);
            }
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/transactions", { state: { msg: msg } });
    }
    if (permissions && !permissions.CanEdit) navigate("/error401");
    
    const PW1Changed = (v) => { setPw1(v.value) };
    const PW2Changed = (v) => { setPw2(v.value) };

    // form validators
    const textValidator = (value) => !value ? "Please enter a value" : "";
    const passwordValidator = (value) => {
        if (value === undefined || value.length < 4) return "Enter a password with at 4 least characters";
        if (pw1 !== "" && pw2 !== "" && pw1 !== pw2) return "Passwords don't match";
    }

    return (
        <div style={{ padding: 20 }}>
            {errorMsg && (<Notification type={{ style: "error", icon: true, }} closable={true} onClose={() => setErrorMsg("")}> <span>{errorMsg}</span> </Notification>)}
            <Form onSubmit={handleSubmit} initialValues={values} render={(formRenderProps) => (
                <FormElement style={{ width: "98%" }}>
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                            {GetField("existing")["isVisible"] && <div className="col-md-3">
                                <Field id={"existing"} name={"existing"} label={GetField("existing")["label"]} type="password" component={FormInput} validator={textValidator} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("newPassword")["isVisible"] && <div className="col-md-3">
                                <Field id={"newPassword"} name={"newPassword"} label={GetField("newPassword")["label"]} type="password" onChange={PW1Changed} component={FormInput} validator={passwordValidator} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("reenterPassword")["isVisible"] && <div className="col-md-3">
                                <Field id={"reenterPassword"} name={"reenterPassword"} label={GetField("reenterPassword")["label"]} type="password" onChange={PW2Changed} component={FormInput} validator={passwordValidator} />
                            </div>}
                        </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{ paddingTop: 10 }}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>Update</Button>
                        </div>
                    </div>
                </FormElement>
            )} />
        </div>
    );
}

export default Password