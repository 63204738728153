import { removeUserSession } from "../components/Session";
import configData from "../config.json";
import {  GetToken } from "../components/Session";
import React from "react";




const Logout = () => {
    removeUserSession();
    window.location.href = "/login";
    return (
        <div></div>
    );
};

export default Logout;