import * as React from 'react';
import Notifications from '../components/Notifications';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";

const EPAReport = () => {
    const [sites, setSites] = React.useState([]);
    const [site, setSite] = React.useState({ "id": "0", "name": "All" });
    const [lookupParams, setLookupParams] = React.useState();
    const ready = React.useRef(false);
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null)
    let dt = new Date();
    let dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 59) };
    const [dateRange, setDateRange] = React.useState(dr);
    const timeOffset = dt.getTimezoneOffset() / 60;
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/");

    const [layout, setLayout] = React.useState([
        { "field": "searchSite", "label": "Site", "isVisible": true }
    ]);

    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    React.useEffect(() => {
        GetColumnDefs("EPAReport", setColumns, setErrorMessage);
        GetPermissions(["ViewEPAReport", "UseExportWizard"], setPermissions)
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        };
        let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EPAReport'";
        fetch(baseUrl, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile) {
                let data = [];
                for (let i = 0; i < d.length; i++) {
                    data.push({ field: d[i].field, label: d[i].label, type: d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile });
                }
                setLayout(data);
            } else {
                setLayout(d);
            }
        });
        baseUrl = configData.SERVER_URL + "apiv7/options";
        fetch(baseUrl, init).then(response => response.json()).then(d => {
            setSites(CreateList(d.sites));
            ready.current = true;
            DoSearch();
        });
    }, []);

    function DoSearch() {
        if (ready.current) {
            var params = {
                Site: site.id,
                TimeOffset: timeOffset,
                FromDate: dateRange.start === null ? "2000-01-01T00:00:00Z" : dateRange.start.toISOString(),
                ToDate: dateRange.end === null ? "2050-01-01T00:00:00Z" : dateRange.end.toISOString()
            };
            setLookupParams(encodeURI(JSON.stringify(params)));
        }
    }
    const ClearSearch = () => {
        if (ready.current) {
            ClearParams();
            var params = {
                Site: "0",
                TimeOffset: timeOffset,
                FromDate: dr.start.toISOString(),
                ToDate: dr.end.toISOString()
            };
            setLookupParams(encodeURI(JSON.stringify(params)));
        }
    }
    const ClearParams = () => {
        setSite({ "id": "0", "name": "All" });
        setDateRange(dr);
    }

    const siteChanged = (event) => { setSite(event.target.value); }
    const rangeChanged = (event) => { setDateRange(event.target.value); }

    const CreateList = (d) => {
        var x = [{ "id": "0", "name": "All" }];
        return x.concat(d);
    }

    return (
        <div>
            <Notifications errorMessage={errorMessage} />
            <div style={{ maxWidth: "1500px" }}>
                <div className="row" style={{ padding: "5px 10px" }}>
                    {GetField("searchSite")["isVisible"] && <div className="col-md-3">
                        <label className="control-label">{GetField("searchSite")["label"]}</label>
                        <DropDownList id="Site" data={sites} value={site} textField="name" dataItemKey="id" onChange={siteChanged} />
                    </div>}
                    <div className="col-md-3">
                        <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged} />
                    </div>
                    <div className="col-md-3" style={{ padding: "20px" }}><Button themeColor={"primary"} onClick={DoSearch}>Search</Button> <Button onClick={ClearSearch} themeColor={"primary"}>Clear</Button></div>
                </div>
            </div>
            {columns && permissions && <DataGrid dataSource='apiv7/epareport' sortable={false} filterable={false} params={lookupParams} columns={columns} permissions={permissions} />}
        </div>
    );
}

export default EPAReport;