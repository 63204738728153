import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDateTimePicker, FormDropDownList, FormInput, FormSwitch } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { GetPermissions } from '../components/Options';

const EditOffences = () => {
    const { id } = useParams(); // offences/id url is used to edit existing offense, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [sites, setSites] = React.useState([ ]);
    const [users, setUsers] = React.useState([ ]);
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        {"field": "name", "label": "Rego", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        {"field": "description", "label": "Description", "defaultValue": "", "isVisible": true, "type": "text", "format": ""},
        {"field": "dateTimeIn", "label": "Date Time", "defaultValue": "", "isVisible": true, "type": "date", "format": "d/MM/yy hh:mm"},
        {"field": "site", "label": "Site", "defaultValue": "", "isVisible": true, "type": "numeric", "format": ""},
        {"field": "user", "label": "User", "defaultValue": "", "isVisible": true, "type": "numeric", "format": ""},
        {"field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean", "format": ""}
      ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        site: GetField("site")["defaultValue"],
        user: GetField("user")["defaultValue"],
        rego: GetField("name")["defaultValue"],
        description: GetField("description")["defaultValue"],
        dateTimeIn: new Date(),
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/offences
    React.useEffect(() => {
        GetPermissions(["ViewOffence", "NewOffence", "EditOffence"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=editOffences`;
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setSites(opt.sites);
            setUsers(opt.users);
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/offences/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var s = opt.sites.find(obj => { return obj.id === String(d.siteId) });
                    var u = opt.users.find(obj => { return obj.id === String(d.userId) });
                    var dti = new Date(d.dateTimeIn + "Z");
                    setValues({
                        site: s,
                        user: u,
                        rego: d.name,
                        dateTimeIn: dti,
                        description: d.description,
                        active: d.isActive
                    });
                    setFormkey(formkey + 1);
                })
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/offences${pth}`;
        var utcDate = dataItem.dateTimeIn.toISOString();
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "offenceId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.rego, "name"),
            "description":  GetDefaultData(dataItem.description, "description"),
            "dateTimeIn": utcDate,
            "siteId": configData.SITE === 1 ? GetDefaultData(dataItem.site.id, "site") : configData.SITE,
            "userId": GetDefaultData(dataItem.user.id, "user"),
            "isActive": GetDefaultData(dataItem.active, "active")
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = id === undefined ? "New offence created" : "Offence updated";
            if (d.status > 299) msg = `Error ${d.status} creating Offence`;
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/offences", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const regoValidator = (value) =>  !value ? "Please enter a value" : "";
    const optionValidator = (value) =>  value ? "" : "Please select an option";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}}>
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                           {GetField("name")["isVisible"] && GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"rego"} name={"rego"} label={GetField("name")["label"]} component={FormInput} validator={regoValidator}/>
                            </div>}
                            {GetField("dateTimeIn")["isVisible"] && <div className="col-md-3"> 
                                <Field id={"dateTimeIn"} name={"dateTimeIn"} label={GetField("dateTimeIn")["label"]} component={FormDateTimePicker}
                                    wrapperStyle={{ width: "90%", marginRight: "18px",}}/>
                            </div>}
                            {GetField("user")["isVisible"] && <div id="userShow" className='col-md-3'>
                                <Field id={"user"} name={"user"} label={GetField("user")["label"]} component={FormDropDownList} textField={"name"} data={users} validator={optionValidator} />
                            </div>}
                            {configData.SITE === 1 && GetField("site")["isVisible"] && <div className='col-md-3'>
                                <Field id={"site"} name={"site"} label={GetField("site")["label"]} component={FormDropDownList} textField={"name"} data={sites} validator={optionValidator}/>
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("description")["isVisible"] && <div className='col-md-3'>
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}
                        </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditOffences