import * as React from 'react';
import {
    Card,
    CardTitle,
    CardBody,
    CardActions,
  } from "@progress/kendo-react-layout";
  import { Button } from "@progress/kendo-react-buttons";
  import ReactPlayer from "react-player/youtube";
  import customer from '../assets/Create-customer.mp4'
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const LandingPage = () => {
    

    return ( 
       
        <div
            style={{
                paddingBottom: 20,
            }}>
                <div className="k-card-list" style={{width:"29%",padding:20}}>
                    <Card
                      style={{
                        width: "370%",
                        height: "80%",
                        textAlign:"center",
                        padding:20,
                         }}
                      type="primary"
                    >
                      <CardBody>
                        <CardTitle><h2>Welcome To Weigh More Solution</h2></CardTitle>
                      </CardBody>
                    </Card>
                  </div>
                  <div
                    style={{
                        display: "flex",
                        paddingBottom: 20,
                    }}>
                        <div className="k-card-list" style={{width:"25%",padding:20}}>
                            <Card
                            style={{
                                width: "100%",
                                height: 250,
                                padding:20,
                                textAlign:"center",
                                }}
                            type="info"
                            >
                            <CardBody>
                                <CardTitle>How to create a Transaction</CardTitle>
                                <a href="/watchVideo/createTransaction" className="icon" title="Create Transaction"> <i  className="fa fa fa-solid fa-money-bill"></i> </a>
                            </CardBody>
                            </Card>
                        </div>
                        <div className="k-card-list" style={{width:"25%",padding:20}}>
                            <Card
                            style={{
                                width: "100%",
                                height: 250,
                                padding:20,
                                textAlign:"center",
                                }}
                                type="info"
                            >
                            <CardBody>
                                <CardTitle>How to create a Customer</CardTitle>
                                <a href="/watchVideo" className="icon" title="Create Customer"> <i  className="fa fa fa-solid fa-user-tie"></i> </a>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="k-card-list" style={{width:"25%",padding:20}}>
                            <Card
                            style={{
                                width: "100%",
                                height: 250,
                                padding:20,
                                textAlign:"center",
                                }}
                            type="info"
                            >
                            <CardBody>
                                <CardTitle>How to create a Job</CardTitle>
                                <a href="/watchVideo/createJob" className="icon" title="Create Customer"> <i  className="fa fa fa-solid fa-list-check"></i> </a>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="k-card-list" style={{width:"25%",padding:20}}>
                            <Card
                                style={{
                                    width: "100%",
                                    height: 250,
                                    padding:20,
                                    textAlign:"center",
                                    }}
                                type="info"
                            >
                            <CardBody>
                                <CardTitle>How to create a Source</CardTitle>
                                <a href="/watchVideo/createSource" className="icon" title="Create Customer"> <i  className="fa fa fa-duotone fa-map-location-dot"></i> </a>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div
                    style={{
                        display: "flex",
                        paddingBottom: 20,
                    }}>
                        <div className="k-card-list" style={{width:"25%",padding:20}}>
                            <Card
                            style={{
                                width: "100%",
                                height: 250,
                                padding:20,
                                textAlign:"center",
                                }}
                            type="info"
                            >
                            <CardBody>
                                <CardTitle>How to create a Destination</CardTitle>
                                <a href="/watchVideo/createDestination" className="icon" title="Create Customer"> <i  className="fa fa fa-solid fa-map-location"></i> </a>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="k-card-list" style={{width:"25%",padding:20}}>
                            <Card
                            style={{
                                width: "100%",
                                height: 250,
                                padding:20,
                                textAlign:"center",
                                }}
                            type="info"
                            >
                            <CardBody>
                                <CardTitle>How to create a Product</CardTitle>
                                <a href="/watchVideo/createProduct" className="icon" title="Create Customer"> <i  className="fa fa fa-solid fa-boxes"></i> </a>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="k-card-list" style={{width:"25%",padding:20}}>
                            <Card
                            style={{
                                width: "100%",
                                height: 250,
                                padding:20,
                                textAlign:"center",
                                }}
                            type="info"
                            >
                            <CardBody>
                                <CardTitle>How to create a Truck</CardTitle>
                                <a href="/watchVideo/createTruck" className="icon" title="Create Customer"> <i  className="fa fa fa-solid fa-truck-fast"></i> </a>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="k-card-list" style={{width:"25%",padding:20}}>
                            <Card
                            style={{
                                width: "100%",
                                height: 250,
                                padding:20,
                                textAlign:"center",
                                }}
                            type="info"
                            >
                            <CardBody>
                                <CardTitle>How to Lookup a Docket</CardTitle>
                                <a href="/watchVideo/DocketLookUp" className="icon" title="Create Customer"> <i  className="fa fa fa-solid fa-file"></i> </a>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                
                
                
              </div>
              
         
    );
}

export default LandingPage;