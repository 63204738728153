import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormTextArea, FormDropDownList, FormMultiSelect } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';
import { getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState  } from "../components/multiselecttree-data-operations";
import { MultiSelectTreeEditor } from "../components/MultiSelectTreeEditor";

const EditDestinations = () => {
    const { id } = useParams(); // editdestinations/id url is used to edit existing destinations, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [states, setStates] = React.useState([ ]);
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    const [permissions, setPermissions] = React.useState(null);

    // default layout data for page
    const [layout, setLayout] = React.useState([
        {"field": "destinationDetails","label": "Destination","defaultValue": "","isVisible": true,"type": "heading","format": null },
        {"field": "name","label": "Name","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "code","label": "Code","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "active","label": "Active","defaultValue": true,"isVisible": true,"type": "boolean","format": null },
        {"field": "description","label": "Comment","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "address1","label": "Address Line 1","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "address2","label": "Address Line 2","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "suburb","label": "Suburb","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "state","label": "State","defaultValue": "NSW","isVisible": true,"type": "text","format": null },
        {"field": "postcode","label": "Post Code","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "country","label": "Country","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "phone","label": "Phone","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "contact","label": "Contact","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "mobile","label": "Mobile","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "email","label": "Email","defaultValue": "","isVisible": true,"type": "text","format": null },
        {"field": "addressDetails","label": "Address and contact details","defaultValue": "","isVisible": true,"type": "heading","format": null },
        {"field": "siteDetails","label": "Site details","defaultValue": "","isVisible": true,"type": "heading","format": null }
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        name: GetField("name")["defaultValue"],
        code: GetField("code")["defaultValue"],
        description: GetField("description")["defaultValue"],
        address1: GetField("address1")["defaultValue"],
        address2: GetField("address2")["defaultValue"],
        suburb: GetField("suburb")["defaultValue"],
        state: GetField("state")["defaultValue"],
        postcode: GetField("postcode")["defaultValue"],
        country: GetField("country")["defaultValue"],
        phone: GetField("phone")["defaultValue"],
        contact: GetField("contact")["defaultValue"],
        mobile: GetField("mobile")["defaultValue"],
        email: GetField("email")["defaultValue"],
        addressDetails: GetField("addressDetails")["defaultValue"],
        siteDetails: GetField("siteDetails")["defaultValue"],
        destinationSites: [],
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vehicleconfigurations
    React.useEffect(() =>{
        GetPermissions(["ViewDestination", "NewDestination", "EditDestination"], setPermissions)
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditDestinations'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setStates(opt.states);
            let allSites = GetList(opt.sites);
            let defSites = ConfigureSites(opt.sites, opt.inactiveSites, allSites);
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/destinations/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    let sp = d.siteList === null ? [] : String(d.siteList).split(',');
                    let sites = ConfigureSites(opt.sites, opt.inactiveSites, d.siteList);
                    setValues({
                        name: d.name === null ? "" : d.name,
                        code: d.code === null ? "" : d.code,
                        description: d.description === null ? "" : d.description,
                        destinationSites: sites,
                        address1: d.address1 === null ? "" : d.address1,
                        address2: d.address2 === null ? "" : d.address2,
                        suburb: d.suburb === null ? "" : d.suburb,
                        state: d.state === null ? "" : d.state,
                        postcode: d.postcode === null ? "" : d.postcode,
                        country: d.country === null ? "" : d.country,
                        phone: d.phone === null ? "" : d.phone,
                        contact: d.contact === null ? "" : d.contact,
                        mobile: d.mobile === null ? "" : d.mobile,
                        email: d.email === null ? "" : d.email,
                        addressDetails: d.addressDetails === null ? "" : d.addressDetails,
                        siteDetails: d.siteDetails === null ? "" : d.siteDetails,                       
                        active: d.isActive
                    });
                    setFormkey(formkey + 1);
                })
            } else {
                var v = {...values};
                v.destinationSites = defSites;
                setValues(v);
                setFormkey(formkey + 1);
            }
        });
    },[]);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    const [sitesData, setSitesData] = React.useState([{id:0, text: "Active", items: []}]);
    const dataItemKey = "id";
    const checkField = "checkField";
    const checkIndeterminateField = "checkIndeterminateField";
    const subItemsField = "items";
    const expandField = "expanded";
    const textField = "text";
    const fields = { dataItemKey, checkField, checkIndeterminateField, expandField, subItemsField };

    const [sitesValue, setSitesValue] = React.useState([]);
    const [siteExpanded, setSiteExpanded] = React.useState([]);
    const onSitesChange = (event) => {
        setSitesValue(getMultiSelectTreeValue(sitesData, {...fields, ...event, value: sitesValue, }));
    }
    const onSitesExpandChange = React.useCallback((event) => setSiteExpanded(expandedState(event.item, dataItemKey, siteExpanded)), [siteExpanded]);
    const sitesTreeData = React.useMemo(() => processMultiSelectTreeData(sitesData, { expanded: siteExpanded, value: sitesValue, ...fields }), [siteExpanded, sitesValue]);
    const ConfigureSites = (act, dis, siteList) => {
        if (siteList === null) return;
        let slsp = siteList.split(',');
        let sl = [];
        let selected = [];
        for (let i = 0; i < act.length; i++){
            let entry = { text: act[i].name, id: act[i].id };
            sl.push(entry);
            let found = slsp.find(obj => { return obj === String(act[i].id) });
            if (found !== undefined) selected.push(entry);
        }
        let active = {id: 0, text: 'Active', items: sl };
        if (dis !== undefined && dis.length > 0){
            sl = [];
            for (let i = 0; i < dis.length; i++){
                let entry = { text: dis[i].name, id: dis[i].id };
                sl.push(entry);       
                let found = slsp.find(obj => { return obj === String(dis[i].id) });
                if (found !== undefined) selected.push(entry);
            }
            let inactive = {id: 999999, text: 'Inactive', items: sl };
            setSitesData([active, inactive]);
        }
        else {
            setSitesData([active]);
        }
        setSitesValue([...selected]);       
        return [...selected];
    }

    const GetList = (obj) => {
        if (obj === undefined || obj.length === 0) return "";
        var l = "";
        for (let i = 0; i < obj.length; i++){
            let id = obj[i].id;
            if (id !== 0 && id !== 999990){
                l += id;
                if (i < obj.length - 1) l += ",";
            }
        }
        return l;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var siteList = GetList(dataItem.destinationSites);
        var url = `${configData.SERVER_URL}apiv7/destinations${pth}`;
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "destinationId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.name, "name"),	
            "code": GetDefaultData(dataItem.code, "code"),	
            "description": GetDefaultData(dataItem.description, "description"),	
            "address1": GetDefaultData(dataItem.address1, "address1"),	
            "address2": GetDefaultData(dataItem.address2, "address2"),	
            "suburb": GetDefaultData(dataItem.suburb, "suburb"),	
            "state": GetDefaultData(dataItem.state, "state"),	
            "postcode": GetDefaultData(dataItem.postcode, "postcode"),	
            "country": GetDefaultData(dataItem.country, "country"),	
            "phone": GetDefaultData(dataItem.phone, "phone"),	
            "contact": GetDefaultData(dataItem.contact, "contact"),	
            "mobile": GetDefaultData(dataItem.mobile, "mobile"),	
            "email": GetDefaultData(dataItem.email, "email"),	
            "siteList": siteList,	
            "isActive": GetDefaultData(dataItem.active, "active") 
           })
        }
        fetch(url, init).then(response => response).then(d => { 
            var msg = id === undefined ? "Source created" : "Source updated";
            Redirect(msg);
        });
    }
    
    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/destinations", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const textValidator = (value) =>  !value ? "Please enter a value" : "";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "95%"}} >
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("destinationDetails")["isVisible"] && <TabStripTab title={GetField("destinationDetails")["label"]}>
                        <div className="row">
                           {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("code")["isVisible"] && <div className="col-md-3">
                                <Field id={"code"} name={"code"} label={GetField("code")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("description")["isVisible"] && <div className='col-md-3'>
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormTextArea}  />
                            </div>}                            
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("addressDetails")["isVisible"] && <TabStripTab title={GetField("addressDetails")["label"]}>
                        <div className='row' >
                            {GetField("address1")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address1"} name={"address1"} label={GetField("address1")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("address2")["isVisible"] && <div className='col-md-3'>
                                <Field id={"address2"} name={"address2"} label={GetField("address2")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("suburb")["isVisible"] && <div className='col-md-3'>
                                <Field id={"suburb"} name={"suburb"} label={GetField("suburb")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("state")["isVisible"] && <div className='col-md-3'>
                                <Field id={"state"} name={"state"} label={GetField("state")["label"]} component={FormDropDownList} data={states} />
                            </div>}                           
                        </div>
                        <div className='row' >
                            {GetField("postcode")["isVisible"] && <div className='col-md-3'>
                                <Field id={"postcode"} name={"postcode"} label={GetField("postcode")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("country")["isVisible"] && <div className='col-md-3'>
                                <Field id={"country"} name={"country"} label={GetField("country")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("phone")["isVisible"] && <div className='col-md-3'>
                                <Field id={"phone"} name={"phone"} label={GetField("phone")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("contact")["isVisible"] && <div className='col-md-3'>
                                <Field id={"contact"} name={"contact"} label={GetField("contact")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        <div className='row' >
                            {GetField("mobile")["isVisible"] && <div className='col-md-3'>
                                <Field id={"mobile"} name={"mobile"} label={GetField("mobile")["label"]} component={FormInput}  />
                            </div>}
                            {GetField("email")["isVisible"] && <div className='col-md-3'>
                                <Field id={"email"} name={"email"} label={GetField("email")["label"]} component={FormInput}  />
                            </div>}
                        </div>
                        </TabStripTab> }
                        {configData.SITE === 1 && GetField("siteDetails")["isVisible"] && <TabStripTab title={GetField("siteDetails")["label"]}>
                            <div className="row">
                                {GetField("siteDetails")["isVisible"] && <div className="col-md-6"><h5>{GetField("siteDetails")["label"]}</h5></div>}
                            </div>                                        
                            <div className='row' >
                                {GetField("siteDetails")["isVisible"] && <div className='col-md-6'>
                                <Field component={MultiSelectTreeEditor} name={"destinationSites"} data={sitesTreeData} dataItemKey={dataItemKey} checkField={checkField} size="large" />                                
                                </div>}
                            </div>
                        </TabStripTab>}
                       </TabStrip>                            
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Cancel</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditDestinations