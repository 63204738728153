import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetToken } from '../components/Session';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { GetPermissions } from '../components/Options';
import configData from "../config.json";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TextBox } from "@progress/kendo-react-inputs";

export const FirstWeighs = (props) => {
    const [columns, setColumns] = React.useState([
        { field: 'firstWeighId', label: 'Id', type: 'numeric', format: null, isVisible: false },
        { field: 'name', label: 'Rego', type: 'text', format: null, isVisible: true },
        { field: 'tareInDate', label: 'Date Time', type: 'date', format: 'd/MM/yy hh:mm', isVisible: true },
        { field: 'tare', label: 'Gross Weight (t)', type: 'numeric', format: '{0:n2}' , isVisible: true }
    ]);
    const [permissions, setPermissions] = React.useState(null);
    const [showDialog, setShowDialog] = React.useState(false);
    const [rego, setRego] = React.useState("")
    const selectedRecord = React.useRef(0);
    const [forceUpdate, setForceUpdate] = React.useState(new Date());

    let dr = JSON.parse(localStorage.getItem('FirstWeighDates'));
    if (dr !== null) {
        if (dr.start !== null) dr.start = new Date(dr.start);
        if (dr.end !== null) dr.end = new Date(dr.end);
    } else {
        let dt = new Date();
        dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: null };
        localStorage.setItem("FirstWeighDates", JSON.stringify(dr));
    }
    const [dateRange, setDateRange] = React.useState(dr);
    const [lookupParams, setLookupParams] = React.useState(""); 

    React.useEffect(() => {
        GetPermissions(["ViewFirstWeigh", "DeleteFirstWeigh", "EditFirstWeigh"], setPermissions);
        let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'FirstWeighs'&$orderby=tareindate desc";
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        };
    }, [])

    React.useEffect(() => {
        setForceUpdate(new Date());
    }, [props.update]);

    const rangeChanged = (event) => {
        let dr = event.target.value;
        setDateRange(dr.value);
        localStorage.setItem("FirstWeighDates", JSON.stringify(dr));
        var params = {
            FromDate: dr.start === null ? "2000-01-01T00:00:00Z" : dr.start.toISOString(),
            ToDate: dr.end === null ? "2050-01-01T00:00:00Z" : dr.end.toISOString()
        };
        setLookupParams(encodeURI(JSON.stringify(params)));   
    }

    const CustomCommand = (action, e) => {
        switch (action) {
            case "select":
                props.firstWeighSelected({ value: e.name });
                break;
            case "edit":
                setRego(e.name);
                selectedRecord.current = e.firstWeighId;
                setShowDialog(true);
                break;
        }
    }

    const RegoChanged = (e) => {
        setRego(e.value);
    }

    const DialogYes = () => {
        setShowDialog(false);
        var url = `${configData.SERVER_URL}apiv7/FirstWeighs/${selectedRecord.current}?name=${encodeURI(rego)}`;
        const init = {
            method: 'PATCH',
            mode: "cors",
            cache: "no-cache",
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response).then(d => {
            setForceUpdate(new Date());
        });
    }
    const DialogNo = () => {
        setShowDialog(false);
    }

    return (
        <div>
            <div className="col-md-4">
                <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged} />
            </div>
            {permissions && <DataGrid dataSource='apiv7/firstweighs' update={forceUpdate} columns={columns} permissions={permissions} params={lookupParams} customCommand={CustomCommand} customCommandName="CustomCommand" keyField="firstWeighId" />}
            {showDialog && <Dialog title="Change Rego" onClose={DialogNo}>
                <div><TextBox value={rego} onChange={RegoChanged} ></TextBox>
                </div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={DialogYes}>Ok</button>
                </DialogActionsBar>
            </Dialog>}

        </div>
    );
}