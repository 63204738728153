import * as React from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TextBox } from "@progress/kendo-react-inputs";
import configData from "../config.json";
import { GetToken } from '../components/Session';

export const JobTonnage = (props) => {
    const jt = props.jobTonnage;
    const req = jt.weight + jt.tonnesProcessed;
    const [message, setMessage] = React.useState("");
    const [tonnage, setTonnage] = React.useState(req);

    const SaveTonnage = () => {
        if (tonnage < jt.weight + jt.tonnesProcessed) {
            setMessage(`To continue, tonnage must me at least ${jt.weight + jt.tonnesProcessed}`);
            return;
        }
        setMessage("Saving...");
        var url = `${configData.SERVER_URL}apiv7/jobproductprices?jppid=${jt.jppId}&tonnage=${tonnage}`;
        const init = {
            method: 'PATCH',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() },
            cache: "no-cache"
        };
        fetch(url, init).then(d => {
            if (props.callback) props.callback(tonnage);
        });
    }

    const CloseDialog = () => {
        if (props.callback) props.callback();
    }

    const TextboxChanged = (e) => {
        setTonnage(parseFloat(e.value));
    }

    return (
        <div style={{ minWidth: "400px" }}><Dialog title={"Review Job Tonnage"} onClose={() => CloseDialog("")}>
            <div id="JobTonnage">
                {message && <div className="row">
                    <div className="col-md-12 error">{message}</div>
                </div>}
                <div className="row">
                    <div className="col-md-5">Job</div>
                    <div className="col-md-7">{jt.jobName}</div>
                </div>
                <div className="row">
                    <div className="col-md-5">Product</div>
                    <div className="col-md-7">{jt.productName}</div>
                </div>
                <div className="row">
                    <div className="col-md-5">Tonnes Ordered</div>
                    <div className="col-md-7">{jt.tonnesOrdered.toFixed(2)}</div>
                </div>
                <div className="row">
                    <div className="col-md-5">Tonnes Processed</div>
                    <div className="col-md-7">{jt.tonnesProcessed.toFixed(2)}</div>
                </div>
                <div className="row">
                    <div className="col-md-5">Tonnes Left</div>
                    <div className="col-md-7">{jt.tonnesLeft.toFixed(2)}</div>
                </div>
                <div className="row">
                    <div className="col-md-5">Net Weight</div>
                    <div className="col-md-7">{jt.weight.toFixed(2)}</div>
                </div>
                <div className="row">
                    <div className="col-md-5">Update tonnage for this job to</div>
                    <div className="col-md-7"><TextBox autoFocus={true} type="number" value={tonnage} onChange={TextboxChanged} /></div>
                </div>
            </div>
            <DialogActionsBar>
                <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={CloseDialog}>Back</button>
                <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={SaveTonnage}>Update Job Tonnage and Continue</button>
            </DialogActionsBar>
        </Dialog></div>
    );
}