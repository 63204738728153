import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { GetPermissions } from '../components/Options';
import $ from "jquery";

const EditDocketTemplates = () => {
    const { id } = useParams(); // docketetemplates/id url is used to edit existing docket template, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [permissions, setPermissions] = React.useState(null);
    const [types, setTypes] = React.useState([]);
    const [docketFields, setDocketFields] = React.useState([]);
    const [conditionalField, setConditionalField] = React.useState("");
    const [fieldType, setFieldType] = React.useState("");
    const [purpose, setPurpose] = React.useState("");
    const [sites, setSites] = React.useState([]);
    const [site, setSite] = React.useState();
    const [active, setActive] = React.useState(true);

    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        {"field": "purpose", "label": "Purpose", "defaultValue": "", "isVisible": true, "type": "list", "format": "" },
        {"field": "format", "label": "Format", "defaultValue": "", "isVisible": true, "type": "text", "format": ""},
        {"field": "active", "label": "Active", "defaultValue": true, "isVisible": true, "type": "boolean", "format": ""},
        {"field": "site", "label": "Site", "defaultValue": true, "isVisible": true, "type": "boolean", "format": "" }
      ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        purpose: GetField("purpose")["defaultValue"]
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    React.useEffect(() => {
        GetPermissions(["ViewDocketTemplates", "NewDocketTemplates"], setPermissions);
        var url = `${configData.SERVER_URL}apiv7/userinterfaces/getuserinterfaceforuser?userId=${GetSession().UserId}&viewName=EditDocketTemplates`;
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {           
            setTypes(opt.docketTypes);
            let sl = CreateList(opt.sites); 
            setSites(sl);
            setDocketFields(opt.docketFields);
            if (id !== undefined){
                url = `${configData.SERVER_URL}apiv7/dockettemplates/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    setPurpose(d.purpose);
                    let s = sl.find(obj => obj.id === String(d.siteId));
                    setSite(s);
                    $("#format").val(d.format);
                    setActive(d.isActive);
                });     
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    const CreateList = (d) => {
        var x = [{ id: "1", name: "Cloud (1)" }];
        d.map((v) => x.push({ id: v.id, name: v.name + " (" + v.id + ")" }));
        return x;
    };

    const fieldTypeChanged = (e) => {
        setFieldType(e.target.value);
    }

    const purposeChanged = (e) => {
        setPurpose(e.target.value);
    }

    const activeChanged = (e) => {
        setActive(e.target.value);
    }

    const conditionalFieldChanged = (e) => {
        setConditionalField(e.target.value);
    }
    
    const InsertConditional = (e) => {
        e.preventDefault();
        var fld = "[?" + conditionalField + " {change text here}?]";
        InsertAtCursor(fld);
    }

    const InsertField = (e) => {
        e.preventDefault();
        var fmtStr = $("#FormatString").val();
        var padding = $("#Padding").val();
        var cond = $("#Condition").val();
        var fv = $("#FalseValue").val();
        var txt = "[[" + fieldType;
        if (fmtStr + padding + cond + fv === ""){
            txt += "]]"
        } else {
            txt += "|" + fmtStr;
            if (padding + cond + fv === ""){
                txt += "]]";
            } else {
                txt += "|" + padding;
                if (cond === ""){
                    txt += "]]";
                } else {
                    txt += "|" + cond;
                    if (fv === ""){
                        txt += "]]";
                    } else {
                        txt += "|" + fv + "]]";
                    }
                }
            }
        }
        InsertAtCursor(txt);
    }

    const InsertAtCursor = (text) => {
        const textarea = document.getElementsByName("format")[0];
        if(document.selection){
            textarea.focus();
            var sel=document.selection.createRange();
            sel.text=text;
            return;
        }
        if(textarea.selectionStart || textarea.selectionStart==="0"){
            var t_start=textarea.selectionStart;
            var t_end=textarea.selectionEnd;
            var val_start=textarea.value.substring(0,t_start);
            var val_end=textarea.value.substring(t_end,textarea.value.length);
            textarea.value=val_start+text+val_end;
        } else {
            textarea.value+=text;
        }
    }

    const siteChanged = (event) => {
        setSite(event.target.value);
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = () => {
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/dockettemplates${pth}`;
        const init = {
            method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "docketTemplateId": id === undefined ? 0 : id,
            "purpose": purpose,
            "format": $("#format").val(),
            "siteId": configData.SITE === 1 ? parseInt(site.id) : configData.SITE,
            "isActive": active
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = id === undefined ? "New template created" : "Docket template updated";
            if (d.status > 299) msg = `Error ${d.status} creating Docket Template`;
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/dockets", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/");
    if (permissions && !permissions.CanCreate) Redirect("Error: You do not have permission to create support");

    // form validators
    const optionValidator = (value) =>  value ? "" : "Please select an option";
    return (
        <div style={{padding: 20}}>
            <div className="row" style={{paddingBottom: 10}}>
                {configData.SITE === 1 && <div className='col-md-3'>
                    <Label>{GetField("site")["label"]}</Label>
                    <div><DropDownList id="site" style={{ width: "300px" }} onChange={siteChanged} value={site} data={sites} textField="name" dataItemKey="id" validator={optionValidator} /></div>
                </div>}
                <div className='col-md-3'>
                    <Label>{GetField("purpose")["label"]}</Label>
                    <div><DropDownList id="purpose" style={{ width: "300px" }} onChange={purposeChanged} value={purpose} data={types} validator={optionValidator} /></div>
                </div>
            </div>
            <div className="row" style={{paddingBottom: 20}}>
                <Label>{GetField("active")["label"]}</Label>
                <div><Checkbox id="active" onChange={activeChanged} value={active} /></div>
            </div>
            <div className='row'>
                {GetField("format")["isVisible"] && <div className='col-md-6'>
                    <textarea id={"format"} name={"format"} label={GetField("format")["label"]} rows={30} columns={80} style={{fontFamily: "Consolas, Courier", width: 580}} ></textarea>
                </div>}
                <div className='col-md-3'>
                    <h4>Add field to template</h4>
                    <div className='row'>
                        <Label>Select field</Label>
                    <div><DropDownList id="FieldType" style={{ width: "300px" }} onChange={fieldTypeChanged} data={docketFields}/></div>
                    </div>
                    <div className='row'>
                        <Label>.Net format string</Label>
                    <div><Input id="FormatString" style={{ width: "300px" }} /></div>
                    </div>
                    <div className='row'>
                        <Label>Padding (positive to right align, negative to left alight)</Label>
                    <div><Input id="Padding" style={{ width: "300px" }} /></div>
                    </div>
                    <div className='row'>
                        <Label>Display condition</Label>
                    <div><Input id="Condition" style={{ width: "300px" }} /></div>
                    </div>
                    <div className='row'>
                        <Label>Value if condition is false</Label>
                    <div><Input id="FalseValue" style={{ width: "300px" }} /></div>
                    </div>
                    <div className='row' style={{paddingTop: 10, paddingBottom: 50}}>
                    <div><Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={InsertField}>Insert Field</Button></div>
                    </div>

                    <h4>Add conditional section</h4>
                    <div className='row'>
                        <Label>Conditional value</Label>
                    <div><DropDownList id="ConditionalField" style={{ width: "300px" }} onChange={conditionalFieldChanged} data={docketFields}/></div>
                    </div>
                    <div className='row' style={{paddingTop: 10, paddingBottom: 50}}>
                    <div><Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={InsertConditional}>Insert Conditional Section</Button></div>
                    </div>
                </div>
                <div className='col-md-3' style={{paddingLeft: 40}}>
                <h4>Notes</h4>
                <p>SelectedMixedProducts inserts a list of mixed products.</p>
                <p>Uses .Net formatting strings.</p>
                <ul>
                    <li>&#123;0:C2&#125; for currency</li>
                    <li>&#123;0:F2&#125; for 2 decimal places</li>
                    <li>&#123;0:dd/MM/yy hh:mm:ss tt&#125; for dates like 25/03/23 12:34:23 AM</li>
                </ul>
                <p>For padding:</p>
                <ul>
                    <li>Blank = no padding required</li>
                    <li>5 will output "text" as " text"</li>
                    <li>-5 will output "text" as "text "</li>
                </ul>
                
                <p><span style={{fontWeight: "bold"}}>Display condition</span><br/>[arg1][operator][arg2]. [arg1] and [arg2] can be fields or constants. [operator] is one of &lt;, &lt;=, =, !=, &gt;, &gt;=, IN, LIKE or CONTAINS. If condition is true field will display as normal, otherwise <i>Value if condition is false</i> will display.</p>
                <p>All content with a conditional section will be suppressed if value is null or empty.</p>

                </div>
            </div>
            <div className="k-form-buttons" style={{paddingTop: 10}}>
                <div className='col-md-1'>
                    <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={handleSubmit}>{saveName}</Button>
                </div>
                <div className='col-md-1'>
                    <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                </div>
            </div>                
        </div>
    );
}

export default EditDocketTemplates