import * as React from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";

export const TruckConfig = (props) => {
    const [buttons, setButtons] = React.useState(props.options); 

    const ButtonClicked = (id) => {
        if (props.itemSelected !== undefined) props.itemSelected(id);
    }

    const CreateButton = (b) => {
        if (b.id === "1") return "";
        return (
            <div key={b.id} className={"TCButtonDiv"}><Button className={"TCButton"} onClick={() => ButtonClicked(b.id)}>
                <div className={"TCButtonImage"}><img src={"data:image/png;base64,"+b.data} /></div>
                <div className={"TCButtonText"} >{b.name}</div>
            </Button></div>
        );
    }

    const GoBack = () => {
        if (props.itemSelected !== undefined) props.itemSelected("***BACK");
    }

    const DialogNo = () => {
        if (props.itemSelected !== undefined) props.itemSelected("***CANCEL");
    }

    return (
        <div>
            <Dialog onClose={DialogNo}>
                <div className={"itemSelectorHeader"}>
                    <div className={"itemSelectorTitle"}>Select Truck Configuration</div>
                    <div id="TCBack"><Button onClick={GoBack}>Back</Button></div>
                    <div className={"itemSelectorClose"}><Button icon={"close"} onClick={DialogNo}></Button></div>
                </div>
                <div className={"TCSelector"}>
                    <div className={"TCSelectorDiv"}>{buttons.map((x) => CreateButton(x))}</div>
                </div>
            </Dialog>
        </div>
    )
}