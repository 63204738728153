import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDropDownList, FormInput, FormNumericTextBox, FormSwitch } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';

const EditUserInterfaces = () => {
    const { id } = useParams(); // offences/id url is used to edit existing offense, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [users, setUsers] = React.useState([]);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [permissions, setPermissions] = React.useState(null);
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };
    const navigate = useNavigate();

    // default layout data for page
    const [layout, setLayout] = React.useState([
        {"field": "viewName", "label": "View", "defaultValue": "", "isVisible": true, "type": "text", "format": "" },
        {"field": "field", "label": "Field", "defaultValue": "", "isVisible": true, "type": "text", "format": ""},
        {"field": "label", "label": "Label", "defaultValue": "", "isVisible": true, "type": "text", "format": ""},
        {"field": "defaultValue", "label": "Default Value", "defaultValue": "", "isVisible": true, "type": "text", "format": ""},
        {"field": "type", "label": "Type", "defaultValue": "text", "isVisible": true, "type": "text", "format": ""},
        {"field": "isVisible", "label": "Visible", "defaultValue": "true", "isVisible": true, "type": "boolean", "format": ""},
        {"field": "isVisibleOnMobile", "label": "Display on Mobiles", "defaultValue": "true", "isVisible": true, "type": "boolean", "format": ""},
        {"field": "userId", "label": "User", "defaultValue": {id: "0", name: "All"}, "isVisible": true, "type": "list", "format": ""},
        {"field": "columnWidth", "label": "Column Width (0=auto)", "defaultValue": 0, "isVisible": true, "type": "numeric", "format": ""},
        {"field": "format", "label": "Format", "defaultValue": "", "isVisible": true, "type": "text", "format": ""},
        {"field": "isAvailable", "label": "Available", "defaultValue": "true", "isVisible": true, "type": "boolean", "format": ""},
        {"field": "managePagesMain", "label": "Manage Pages", "defaultValue": "", "isVisible": true, "type": "heading", "format": ""},
        {"field": "tables", "label": "Tables", "defaultValue": "", "isVisible": true, "type": "heading", "format": ""}
      ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        viewName: GetField("viewName")["defaultValue"],
        field: GetField("field")["defaultValue"],
        label: GetField("label")["defaultValue"],
        defaultValue: GetField("defaultValue")["defaultValue"],
        type: GetField("type")["defaultValue"],      
        format: GetField("format")["defaultValue"],      
        isAvailable: GetField("isAvailable")["defaultValue"] === "true",
        isVisible: GetField("isVisible")["defaultValue"] === "true",
        isVisibleOnMobile: GetField("isVisibleOnMobile")["defaultValue"] === "true",
        columnWidth: GetField("columnWidth")["defaultValue"],      
        userId: {id: "0", name: "All"}
      });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/offences
    React.useEffect(() => {
        GetPermissions(["ViewManagePages", "NewManagePages", "EditManagePages"], setPermissions);
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        let url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {     
            let users = [ {id: "0", name: "All"}];
            for (let i = 0; i < opt.users.length; i++){
                users.push(opt.users[i]);
            }
            setUsers(users);
            if (id !== undefined){
                var url = `${configData.SERVER_URL}apiv7/userinterfaces/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    let user = {id: "0", name: "All"};
                    if (d.userId !== null){
                        user = opt.users.find(obj => { return obj.id === String(d.userId) });
                    }
                    setValues({
                        viewName: d.viewName === null ? "" : d.viewName,
                        field: d.field === null ? "" : d.field,
                        label: d.label === null ? "" : d.label,
                        defaultValue: d.defaultValue === null ? "" : d.defaultValue,
                        type: d.type === null ? "" : d.type,
                        format: d.format === null ? "" : d.format,
                        isVisible: d.isVisible === null ? false : d.isVisible,
                        userId: user,
                        isVisibleOnMobile: d.isVisibleOnMobile === null ? false: d.isVisibleOnMobile,
                        isAvailable: d.isAvailable === null ? false: d.isAvailable,
                        columnWidth: d.columnWidth === null ? 0 : d.columnWidth
                    });
                    setFormkey(formkey + 1);
                })
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/userinterfaces${pth}`;
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "userInterfaceId": id === undefined ? "0" : id,
            "viewName": GetDefaultData(dataItem.viewName, "viewName"),
            "field":  GetDefaultData(dataItem.field, "field"),
            "label": GetDefaultData(dataItem.label, "label"),
            "defaultValue": GetDefaultData(dataItem.defaultValue, "defaultValue"),
            "type": GetDefaultData(dataItem.type, "type"),
            "format": GetDefaultData(dataItem.format, "format"),
            "isAvailable": GetDefaultData(dataItem.isAvailable, "isAvailable"),
            "isVisible": GetDefaultData(dataItem.isVisible, "isVisible"),
            "columnWidth": GetDefaultData(dataItem.columnWidth, "columnWidth"),
            "userId": GetDefaultData(dataItem.userId.id, null),
            "isVisibleOnMobile": GetDefaultData(dataItem.isVisibleOnMobile, "isVisibleOnMobile")
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = id === undefined ? "New user interface created" : "User interface updated";
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/managePages", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");   
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const regoValidator = (value) =>  !value ? "Please enter a value" : "";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: 1000}}>
                    <fieldset className={"k-form-fieldset"}>
                        <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("managePagesMain")["isVisible"] && <TabStripTab title={GetField("managePagesMain")["label"]}>

                        <div className="row">
                           {GetField("viewName")["isVisible"] && <div className="col-md-4">
                                <Field id={"viewName"} name={"viewName"} label={GetField("viewName")["label"]} component={FormInput} validator={regoValidator}/>
                            </div>}
                            {GetField("field")["isVisible"] && <div className="col-md-4">
                                <Field id={"field"} name={"field"} label={GetField("field")["label"]} component={FormInput} validator={regoValidator}/>
                            </div>}
                        </div>
                        <div className="row">
                           {GetField("label")["isVisible"] && <div className="col-md-4">
                                <Field id={"label"} name={"label"} label={GetField("label")["label"]} component={FormInput} validator={regoValidator}/>
                            </div>}
                            {GetField("defaultValue")["isVisible"] && <div className="col-md-4">
                                <Field id={"defaultValue"} name={"defaultValue"} label={GetField("defaultValue")["label"]} component={FormInput}/>
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("type")["isVisible"] && <div className='col-md-4'>
                                <Field id={"type"} name={"type"} label={GetField("type")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("userId")["isVisible"] && <div className='col-md-4'>
                                <Field id={"userId"} name={"userId"} label={GetField("userId")["label"]} component={FormDropDownList} textField={"name"} data={users} />
                            </div>}                           
                        </div>
                        <div className='row'>
                            {GetField("isVisibleOnMobile")["isVisible"] && <div className='col-md-4'>
                                <Field id={"isVisibleOnMobile"} name={"isVisibleOnMobile"} label={GetField("isVisibleOnMobile")["label"]} component={FormSwitch} />
                            </div>}
                            {GetField("isVisible")["isVisible"] && <div className='col-md-4'>
                                <Field id={"isVisible"} name={"isVisible"} label={GetField("isVisible")["label"]} component={FormSwitch} />
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("tables")["isVisible"] && <TabStripTab title={GetField("tables")["label"]}>
                        <div className='row'>
                            {GetField("columnWidth")["isVisible"] && <div className='col-md-4'>
                                <Field id={"columnWidth"} name={"columnWidth"} label={GetField("columnWidth")["label"]} component={FormNumericTextBox} min={0} max={1000} />
                            </div>}
                            {GetField("format")["isVisible"] && <div className='col-md-4'>
                                <Field id={"format"} name={"format"} label={GetField("format")["label"]} component={FormInput} />
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("isAvailable")["isVisible"] && <div className='col-md-4'>
                                <Field id={"isAvailable"} name={"isAvailable"} label={GetField("isAvailable")["label"]} component={FormSwitch} />
                            </div>}
                        </div>
                        </TabStripTab>}                        
                        </TabStrip>     
                    </fieldset>
                    <span className={"k-form-separator"} />
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-3'></div>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditUserInterfaces