import React from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import DataLoader from "./dataLoader";
import configData from "../config.json";
import { Button } from "@progress/kendo-react-buttons";
import { NumericTextBox, TextBox } from "@progress/kendo-react-inputs";
import { GetSession, GetToken } from './Session';
import ColumnMenu from '../pages/ColumnMenu';
import Confirm from '../components/Confirm';
import { formatDate, formatNumber } from '@progress/kendo-intl';
import { Checkbox } from '@progress/kendo-react-inputs';
import { DatePickerPropsContext } from '@progress/kendo-react-dateinputs';
import { useNavigate, useParams } from "react-router-dom";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { Dialog } from "@progress/kendo-react-dialogs";
import $ from "jquery"

const DataGrid = (props) => { // use props.update for force grid update for outside this component
    const dataSource = props.dataSource;
    const extraParam = props.extraParam === undefined ? "" : props.extraParam; // used for passing extra parameters for edit, eg "/S" for suppliers or "/C" for customers
    const dataFilter = props.dataFilter === undefined ? "" : props.dataFilter;
    let deleteAll = props.deleteAll !== undefined;
    let extraButton = props.extraButton;
    const extraButtonUrl = props.extraButtonUrl;
    const sortable = props.sortable === undefined ? true : props.sortable;
    const filterable = props.filterable === undefined ? true : props.filterable;
    const params = props.params;
    const Approve = props.approve;
    const setVisibility = props.setVisibility;
    const viewName = props.viewName === undefined ? String(dataSource).replace("apiv7/", "") : props.viewName;
    let editUrl = "/edit" + String(dataSource).replace("apiv7/", "") + extraParam;
    if (props.editUrl !== undefined) editUrl = props.editUrl;
    let printUrl = "/print" + String(dataSource).replace("apiv7/", "") + extraParam;
    if (props.printUrl !== undefined) printUrl = props.printUrl;
    const permissions = props.permissions || { CanEdit: true, CanDelete: true, CanPrint: false, CanCreate: true, CanReport: true, CanApprove: false };
    const canEdit = permissions.CanEdit;
    const canDelete = permissions.CanDelete;
    const canCreate = permissions.CanCreate;
    const canPrint = permissions.CanPrint;
    const canReport = permissions.CanReport;
    const canExport = props.exportRecord !== undefined;
    const [success, setSuccess] = React.useState(false);
    const canApprove = permissions.CanApprove && Approve !== undefined;
    const syncMyob = permissions.SyncMyob;
    if (!canEdit) extraButton = undefined;
    if (!canDelete) deleteAll = false;
    const columns = props.columns;
    const keyField = props.keyField;
    const navigate = useNavigate();
    const [showDlg, setShowDlg] = React.useState(false);
    const [selected, setSelected] = React.useState(0);
    let newRecordBtn = "";
    const [apiData, setData] = React.useState({ data: [], total: 1 });
    const [dataState, setDataState] = React.useState({ skip: 0, take: 0 });
    const [gridKey, setGridKey] = React.useState();
    const [visIds, setVisIds] = React.useState("");
    const bulkExport = props.bulkExport !== undefined;
    const selectedIds = React.useRef([]);
    const customCommand = props.customCommand;
    const customCommandName = props.customCommandName;
    const rowStyle = props.rowStyle; // callback function for setting style of rows
    let minWidth = (canEdit ? 50 : 0) + (canDelete ? 50 : 0) + (canApprove ? 50 : 0) + (canExport ? 50 : 0) + (canPrint ? 50 : 0) + (extraButton !== undefined ? 100 : 0) + (customCommand !== undefined ? 100 : 0);
    if (minWidth > 0 && minWidth < 120) minWidth = 130;
    let colWidth = minWidth + "px";
    const totalRecords = React.useRef(0);
    const [progressMessage, setProgressMessage] = React.useState("");

    const datePickerProps = React.useCallback( // fix date format for filter and edit in grid
        (datePickerProps) => ({
            ...datePickerProps,
            format: 'dd/MM/yy',
        }),
        []
    );
    const [filterActive, setFilterActive] = React.useState(false);
    let lastSearchText = localStorage.getItem(dataSource + "Search");
    if (lastSearchText === null) lastSearchText = "";
    const [searchText, setSearchText] = React.useState(lastSearchText);
    const [filterTitle, setFilterTitle] = React.useState("Clear Filters");
    // handle pagination and page sizes
    const [pageSizeValue, setPageSizeValue] = React.useState();
    const pageChange = (event) => {
        const targetEvent = event.targetEvent;
        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value);
            dataState.skip = 0;
            if (targetEvent.value === 'All')
                dataState.take = 999999;
            else
                dataState.take = targetEvent.value;
        } else {
            dataState.skip = event.page.skip;
            setData();
        }
        updateDataState(dataState);
    }

    // handle datastate changes. This could be sort order or filter changes
    const dataStateChange = (e) => {
        updateDataState(e.dataState);
    };
    // save dataState object to userfilter table
    const updateDataState = (ds) => {
        setDataState(ds);
        setFilterActive(ds.filter !== undefined);
        if (ds.filter !== undefined) {
            let fs = "";
            for (let i = 0; i < ds.filter.filters.length; i++) {
                if (i > 0) fs = fs + ", ";
                fs +=
                    ds.filter.filters[i].filters[0].field +
                    " " +
                    ds.filter.filters[i].filters[0].operator +
                    " " +
                    ds.filter.filters[i].filters[0].value;
            }
            setFilterTitle(fs);
        }
        var q = `apiv7/userfilters`;
        var init = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            body: JSON.stringify({
                userId: GetSession().UserId,
                viewName: viewName,
                value: JSON.stringify(ds)
            })
        };
        fetch(configData.SERVER_URL + q, init).then().then();
    }

    // new data received from data loader
    const dataReceived = (d) => {
        let ids = "";
        totalRecords.current = d.total;
        d.data.map((r) => {
            if (ids !== "") ids += ",";
            ids += r[keyField];
        });
        setVisIds(ids);
        setData(ParseDates(d));
    };
    const ParseDates = (d) => {
        // find any date fields and convert to JS date objects
        const re = new RegExp(/\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}/);
        if (d.data.length > 0) {
            for (var i = 0; i < d.data.length; i++) {
                for (var pr in d.data[i]) {
                    var val = d.data[i][pr];
                    switch (typeof val) {
                        case "string":
                            if (re.test(val)) {
                                d.data[i][pr] = new Date(val + "Z");
                            }
                            break;
                        case "boolean":
                            if (
                                pr.toLowerCase().indexOf("visible") < 0 &&
                                pr.toLowerCase().indexOf("available") < 0 &&
                                !dataSource.endsWith("vouchers")
                            ) {
                                if (pr.toLowerCase().indexOf("active") >= 0) {
                                    d.data[i][pr] = val ? "Active" : "Inactive";
                                } else {
                                    d.data[i][pr] = val ? "Yes" : "No";
                                }
                            }
                            break;
                        default:
                    }
                }
            }
        }
        return d;
    };


    const columnOrderChanged = (ev) => {
        let cols = [...stateColumns];
        let max = ev.columns.length;
        for (let i = 0; i < cols.length; i++) {
            if (cols[i].label !== "Action") {
                let f = ev.columns.find(s => s.title === cols[i].label);
                if (f === undefined) {
                    cols[i].orderIndex = max++;
                } else {
                    cols[i].orderIndex = f.orderIndex;
                }
            }
        }
        setStateColumns(cols);
        var t = viewName + "Columns";
        var q = `apiv7/userfilters`;
        var init = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            body: JSON.stringify({
                userId: GetSession().UserId,
                viewName: t,
                value: JSON.stringify(cols)
            })
        };
        fetch(configData.SERVER_URL + q, init);
    }

    // excel or PDF export
    const ExportAllData = (t) => {
        if (totalRecords.current > 3000) setProgressMessage(`Downloading ${totalRecords.current} records`);
        var url = configData.SERVER_URL + dataSource;
        if (params !== null && params !== "") url += `?prm=${params}`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            var x = { data: d };
            x = ParseDates(x);
            if (t === 1) {
                _export.current.save(x.data, _grid.current.columns);
            } else {
                gridPDFExport.save(x.data);
            }
            setProgressMessage("");
        });
    }
    const _grid = React.useRef();
    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            ExportAllData(1);
        }
    };
    let gridPDFExport;
    const exportPDF = () => {
        if (gridPDFExport !== null) {
                ExportAllData(2);
        }
    };

    const exportBulk = () => {
        if (bulkExport) {
            let v = "";
            selectedIds.current.map((x) => {
                if (v !== "") v += ",";
                v += x;
            });
            if (v === "") {
                alert("Please select records for export");
                return;
            }
            props.bulkExport(v);
        } 
    }

    // display create new record button
    if (canCreate) {
        newRecordBtn = (
            <a href={editUrl}>
                <Button
                    title="Create New Record"
                    icon="plus-circle"
                    style={{ color: "blue" }}
                ></Button>
            </a>
        );
    }

    // action buttons
    const CommandCell = (props) => {
        if (customCommand !== undefined && customCommandName !== undefined) {
            switch (customCommandName) {
                case "CustomCommand":
                    return (
                        <td>
                            <Button icon="arrow-up" onClick={() => customCommand("select", props.dataItem)} title="Select" />
                            <Button icon="edit" onClick={() => customCommand("edit", props.dataItem)} title="Edit" />
                            <Button icon="delete" hidden={!canDelete} onClick={() => DeleteRecord(props.dataItem)} title="Delete" />
                        </td>);
                case "SettingsCommand":
                    return (
                        <td>
                            {props.dataItem.type === 0 && <Checkbox onChange={(ev) => customCommand(props.dataItem, ev)} size={"large"} defaultChecked={String(props.dataItem.value).toLowerCase() === "true"} />}
                            {(props.dataItem.type === 1 || props.dataItem.type === 2) && <NumericTextBox placeholder="Number" defaultValue={props.dataItem.value} onChange={(ev) => customCommand(props.dataItem, ev)} />}
                            {props.dataItem.type === 3 && <TextBox defaultValue={props.dataItem.value} onChange={(ev) => customCommand(props.dataItem, ev)} />}
                        </td>
                    );
                case "SyncSettingsCommand":
                    return (
                        <td>
                            <NumericTextBox placeholder="Number" defaultValue={props.dataItem.syncPeriod} onChange={(ev) => customCommand(props.dataItem, ev)} />
                        </td>
                    );
                case "MyobSyncCommand":
                    return (
                        <td>
                            <a href={editUrl + "/" + props.dataItem[keyField]}><Button icon="edit" hidden={!canEdit} title="Edit" /></a>
                            <Button icon="delete" hidden={!canDelete} onClick={() => DeleteRecord(props.dataItem)} title="Delete" />
                            {props.dataItem.myobId !== null && syncMyob && <Button icon="connector" onClick={(ev) => customCommand(props.dataItem, ev)} title="Sync MYOB" />}
                        </td>);
                case "FWCommand":
                    return (
                        <td>
                            <a href={editUrl + "/" + props.dataItem[keyField]}><Button icon="edit" hidden={!canEdit} title="Edit" /></a>
                            <Button icon="delete" hidden={!canDelete} onClick={() => DeleteRecord(props.dataItem)} title="Delete" />
                            <Button iconClass="fa fa-key fa-fw" onClick={(ev) => customCommand(props.dataItem, ev)} title="Convert to Transaction" />
                        </td>);
                default:
                    return (null
                    );
            }
        }
        return (
            <td>
                <a href={editUrl + "/" + props.dataItem[keyField]}><Button icon="edit" hidden={!canEdit} title="Edit" /></a>
                <Button icon="delete" hidden={!canDelete} onClick={() => DeleteRecord(props.dataItem)} title="Delete" />
                <a href={printUrl + "/" + props.dataItem[keyField]}><Button icon="print" hidden={!canPrint} title="Print" /></a>
                <Button icon="pdf" hidden={!canExport} title="Export Record" onClick={() => ExportRecord(props.dataItem)} />
                <Button icon="check" hidden={!canApprove || props.dataItem.isApproved === "Yes"} onClick={() => Approve(props.dataItem)} title="Approve" />
                <a href={extraButtonUrl + "/" + props.dataItem[keyField]}><Button hidden={extraButton === undefined} title="Add fee" iconClass="fa fa-dollar fa-fw" /></a>
            </td>);
    };

    const SelectCell = (props) => {
        let selName = "Sel" + props.dataItem[keyField];
        return (
            <td>
                <Checkbox name={selName} id={selName} onChange={SetSelected} size={"large"} />
            </td>
        )
    };

    // print callback if used
    const print = (dataItem) => {
        props.onPrint(dataItem);
    }

    // retrieve user filter for this view
    // UserFilter viewName = 'Products' is pagination and filtering -> dataState object
    // UserFilter viewName = 'ProductsColumns' is column selection  -> stateColumns object
    if (dataState !== undefined && dataState.take === 0) {
        var q = `apiv7/userfilters?$filter=userId eq ${GetSession().UserId} and viewName eq '${viewName}'`;
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(configData.SERVER_URL + q, init).then(response => response.json()).then(d => {
            if (d.length > 0) {
                var st = JSON.parse(d[0].value);
                setDataState(st);
                setFilterActive(st.filter !== undefined);
            } else {
                setDataState({ skip: 0, take: 10 });
            }
        });
        q = `apiv7/userfilters?$filter=userId eq ${GetSession().UserId} and viewName eq '${viewName}Columns'`;
        fetch(configData.SERVER_URL + q, init).then(response => response.json()).then(d => {
            if (d.length > 0) {
                var cols = JSON.parse(d[0].value);
                let o = 0;
                for (let i = 0; i < cols.length; i++) {
                    if (cols[i].orderIndex !== undefined && cols[i].orderIndex > o) o = cols[i].orderIndex;
                }
                for (let i = 0; i < cols.length; i++) {
                    if (cols[i].orderIndex === undefined) cols[i].orderIndex = o++;
                }
                setStateColumns(cols);
            }
        });

    }
    // column selection changes
    const [stateColumns, setStateColumns] = React.useState(columns);
    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    }

    // Delete operation
    // display confirm dialog to delete record
    const DeleteRecord = (data) => {
        setSelected(data[keyField]);
        setShowDlg(true);
    };

    const ExportRecord = (data) => {
        props.exportRecord(data);
    }

    const DeleteAll = () => {
        setSelected(0);
        setShowDlg(true);
    };

    // close confirm dialog
    const HideMessage = () => {
        setShowDlg(false);
    };

    // post delete message to API
    const DeleteData = () => {
        setShowDlg(false);
        var init = {
            method: "DELETE",
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        var url = configData.SERVER_URL + `${dataSource}/${selected}`;
        if (selected === 0) {
            url = `${configData.SERVER_URL}${dataSource}?selected=${visIds}`;
        }
        fetch(url, init).then().then(d => {
            UpdateGrid();
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false);
            }, 1000);

        });
    };

    // force an update of Grid. Kendo doesn't make it easy but backuping up state, setting take to 1 and restoring state works
    const UpdateGrid = () => {
        setGridKey(new Date());
    };

    React.useEffect(() => {
        UpdateGrid();
    }, [props.update]);

    const SetSelected = (e) => {
        let id = e.target.name.replace("Sel", "");
        let newIds = [];
        let selIds = selectedIds.current;
        selIds.forEach((x) => {
            if (x !== id) newIds.push(x);
        });
        if (e.value) newIds.push(id);
        selectedIds.current = newIds;
    }

    const CustomHeaderCell = (props) => (
        <HeaderThElement>
                {props.index === 0 && bulkExport ? <Checkbox name="SelectAll" onChange={SelectAll} /> : props.children}
        </HeaderThElement>
    )

    const SelectAll = (e) => {
        let newIds = [];
        var sp = visIds.split(',');
        for (var i = 0; i < sp.length; i++) {
            if (e.value) newIds.push(sp[i]);
            $("#Sel" + sp[i]).prop("checked", e.value);
        }
        selectedIds.current = newIds;
    }

    const CustomCell = (props) => {
        const f = props.field;
        let val = "";
        let sp = String(f).split(".");
        if (sp.length > 1) {
            let par = props.dataItem[sp[0]];
            val = par[sp[1]];
        } else {
            val = props.dataItem[f];
        }
        if (props.format === null || props.format === "") {
            if (typeof props.dataItem[f] === "boolean") {
                switch (props.field) {
                    case "isVisible":
                    case "isActive":
                        let cbid = "CB" + props.dataItem[keyField];
                        return (
                            <td>
                                <Checkbox
                                    name={cbid}
                                    onChange={VisibilityChanged}
                                    defaultChecked={val}
                                />{" "}
                            </td>
                        );
                    case "isVisibleOnMobile":
                        let ivid = "VM" + props.dataItem[keyField];
                        return (
                            <td>
                                <Checkbox
                                    name={ivid}
                                    onChange={VisibilityChanged}
                                    defaultChecked={val}
                                />{" "}
                            </td>
                        );
                    case "isAvailable":
                        let avid = "AV" + props.dataItem[keyField];
                        return (
                            <td>
                                <Checkbox
                                    name={avid}
                                    onChange={VisibilityChanged}
                                    defaultChecked={val}
                                />{" "}
                            </td>
                        );
                    default:
                        if (val) {
                            return <td>Yes</td>;
                        } else {
                            return <td>No</td>;
                        }
                }
            } else {
                return <td>{val}</td>;
            }
        } else {
            const fmt = String(props.format.replace("{0:", "").replace("}", ""));
            if (fmt.startsWith("c") || fmt.startsWith("n"))
                return <td>{formatNumber(val, fmt)}</td>;
            else return <td>{formatDate(val, fmt)}</td>;
        }
    };

    const VisibilityChanged = (ev) => {
        setVisibility(ev.target.name, ev.target.value);
    };

    const clearFilters = () => {
        let ds = { ...dataState };
        ds.filter = undefined;
        updateDataState(ds);
    };

    const SearchTextChanged = (e) => {
        let ds = { ...dataState };
        ds.skip = 0;
        setDataState(ds);
        setSearchText(e.value);
        localStorage.setItem(dataSource + "Search", e.value);
    };

    const rowRender = (trElement, props) => {
        let bg = { backgroundColor: "white" };
        if (rowStyle !== undefined) bg = rowStyle(props);

        const trProps = {
            style: bg,
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    }

    // multiple purpose datagrid with all the bells and whistles
    const dataGrid = (
        <Grid
            data={apiData}
            sortable={sortable}
            fixedScroll={true}
            rowHeight={50}
            pageable={{
                buttonCount: 4,
                pageSizes: [10, 20, 50, 100, 200, "All"],
                pageSizeValue: pageSizeValue,
            }}
            // scrollable={"virtual"}
            reorderable={true}
            resizable={true}
            rowHeight={10}
            style={{
                maxHeight: '900px',
                maxWidth: '100%',
                padding: '30px'
            }}
            {...dataState}
            onDataStateChange={dataStateChange}
            onPageChange={pageChange}
            onColumnReorder={columnOrderChanged}
            ref={_grid}
            cells={{headerCell: CustomHeaderCell}}
           // rowRender={rowRender}
        >
            <GridToolbar>
                {canReport && (
                    <Button
                        title="Export Excel"
                        icon="excel"
                        onClick={excelExport}
                        style={{ color: "green" }}
                    ></Button>
                )}
                {canReport && (
                    <Button
                        title="Export PDF"
                        icon="pdf"
                        onClick={exportPDF}
                        style={{ color: "red" }}
                    ></Button>
                )}
                {bulkExport &&
                    <Button icon="zip" title="Export Selected Dockets" onClick={() => exportBulk()} />
                }
                {newRecordBtn}
                {deleteAll && (
                    <Button title="Delete Records Currently Being Displayed" icon="delete" onClick={DeleteAll}>
                        Delete These Records
                    </Button>
                )}
                {filterActive && (
                    <Button
                        title={filterTitle}
                        icon="filter-clear"
                        onClick={clearFilters}
                    >
                        Clear Filters
                    </Button>
                )}
                {!dataSource.endsWith("transactions") && <TextBox
                    id="SearchText"
                    onChange={SearchTextChanged}
                    value={lastSearchText}
                    placeholder="Search"
                />}
            </GridToolbar>
            {bulkExport && <GridColumn
                cell={SelectCell}
                width="44px"
                orderIndex={-1}
            />}
            <GridColumn
                title="Action"
                cell={CommandCell}
                width={colWidth}
                orderIndex={0}
            />
            {stateColumns.map(
                (column, idx) =>
                    column.isVisible && (
                        <GridColumn
                            key={idx}
                            field={column.field}
                            title={column.label}
                            filter={column.type}
                            format={column.format}
                            dataType={column.type}
                            width={column.width}
                            cell={CustomCell}
                            orderIndex={column.orderIndex}
                            columnMenu={(props) => (
                                <ColumnMenu
                                    {...props}
                                    filterable={filterable}
                                    columns={stateColumns}
                                    viewName={viewName}
                                    onColumnsSubmit={onColumnsSubmit}
                                />
                            )}
                        />
                    )
            )}
        </Grid>
    );
    return (
        <div>
            <NotificationGroup style={{ right: 2080, bottom: 1185, alignItems: "flex-start", flexWrap: "wrap-reverse" }}>
                <Fade>{success && (<Notification type={{ style: "success", icon: true, }} closable={true} onClose={() => setSuccess(false)}> <span>Record Deleted.</span> </Notification>)} </Fade>
            </NotificationGroup>
            <DatePickerPropsContext.Provider value={datePickerProps}>
                <ExcelExport ref={_export}>{dataGrid}</ExcelExport>
                <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
                    {dataGrid}
                </GridPDFExport>
            </DatePickerPropsContext.Provider>
            <DataLoader
                key={gridKey}
                dataState={dataState}
                dataFilter={dataFilter}
                onDataReceived={dataReceived}
                dataSource={dataSource}
                params={params}
                search={searchText}
                cloudToken={props.cloudToken}
            />
            {showDlg && (
                <Confirm
                    YesClicked={DeleteData}
                    NoClicked={HideMessage}
                    Title="Confirm delete"
                    Message="Are you sure you want to delete?"
                />
            )}
            {progressMessage !== "" && (
                <Dialog title={"Please wait"} onClose={() => { setProgressMessage(""); }}>
                    <p>{progressMessage}</p>
                </Dialog>
            )}

        </div>
    );
};

export default DataGrid;
