import configData from "../config.json";
import { GetToken, GetUserId } from '../components/Session';


export const GetField = (layout, f) => {
    if (layout === undefined) return "";
    return layout.find(obj => { return obj.field === f });
}

export const GetSetting = (s, name) => {
    return GetRawSetting(s.current, name);
}

export const GetRawSetting = (s, name) => {
    if (s === undefined || s === null || s === "") return false;
    let st = undefined;
    st = s.find(obj => { return obj.name == name });
    if (st === undefined) return undefined;
    let v = String(st.value);
    switch (st.type) {
        case 0:
            v = v.toLowerCase() === "true";
            break;
        case 1:
            v = parseInt(v);
            break;
        case 2:
            v = parseFloat(v);
            break;
    }
    return v;
}

export const UpdateWeights = (d, weights, decks, wbName) => {
    let ind = d.find(obj => obj.dataType === "indicator" && (wbName === "" || wbName === undefined || String(obj.name).toUpperCase() === String(wbName).toUpperCase()));
    if (ind === null) return undefined;
    let val = JSON.parse(ind.value);
    let online = false;
    if (val !== undefined && val.LastReading !== undefined) {
        let dt = new Date(val.LastReading);
        let now = new Date().getTime();
        let lw = dt.getTime();
        online = lw > now - 10000;
    }
    let w = { ...weights };
    w[0] = online ? parseFloat(val.Weight) : 0;
    w[1] = val.Deck1 === undefined || !online ? 0 : val.Deck1;
    if (decks > 1) {
        w[2] = val.Deck2 === undefined || !online ? 0 : val.Deck2;
        w[3] = val.Deck3 === undefined || !online ? 0 : val.Deck3;
        w[4] = val.Deck4 === undefined || !online ? 0 : val.Deck4;
        w[5] = 0;
    }
    return { weights: w, status: val };
}

export const UpdateLights = (d) => {
    let l = [];
    let g = [];
    d.map((x) => {
        if (x.dataType === "trafficlights") {
            l.push({ Id: x.businessDataId, Name: x.name, Value: x.value, Updated: new Date(x.updatedAt) });
        }
        if (x.dataType === "boomgate") {
            g.push({ Id: x.businessDataId, Name: x.name, Value: x.value, Updated: new Date(x.updatedAt) });
        }
    });
    return { Lights: l, Gates: g };
}


export const UpdateRemoteDisplay = (d) => {
    let r = d.find(obj => obj.dataType === "remotedisplay");
    if (r === undefined) return;
    let configStr = "{\"MessageTime\":500, \"WeightTime\":500}";
    if (r.devices.length > 0) configStr = r.devices[0].configuration;
    let config = JSON.parse(configStr);
    return {
        Text: r.value,
        Weight: parseFloat(r.decimalValue),
        WeightTime: config.WeightTime,
        MessageTime: config.MessageTime
    };
}

export const LightClicked = (id, val) => {
    var url = `${configData.SERVER_URL}apiv7/businessdata?id=${id}&value=${val}`;
    const init = {
        method: 'PATCH',
        accept: 'application/json',
        headers: { "Authorization": "Bearer " + GetToken() },
        cache: "no-cache"
    };
    fetch(url, init).catch((error) => {
        console.log("Light Clicked fetch error: " + error.message);
    });
}

export const UpdateCameras = (d, settings) => {
    let ci = [];
    let now = new Date().getTime() - 10000;
    let showanpr = !GetSetting(settings, "HideANPRCamera");
    d.map((x) => {
        if (x.dataType === "camera" && x.value !== "") {
            let conf = x.devices[0].configuration;
            let j = JSON.parse(conf);
            let img = x.value;
            if (new Date(x.updatedAt).getTime() < now) img = "Offline" + img;
            if (showanpr || !j.ANPR) ci.push(img);
        }
    });
    return ci;
}

export const GetStreamingUrl = (ind, d) => {
    let ci = [];
    d.map((x) => {
        if (x.dataType === "camera") {
            ci.push(x.devices[0].configuration);
        }
    });
    if (ci.length < ind) return "";
    let conf = ci[ind - 1];
    let j = JSON.parse(conf);
    let url = j.Stream;
    if (url === undefined) return "";
    return url;
}

export const GetSignature = (d) => {
    return d.find(obj => obj.dataType === "signaturepad");
}

export const ClearBusinessData = (id) => {
    var url = `${configData.SERVER_URL}clientapi/weighbridge/cleardevice?businessdataid=${id}`;
    const init = {
        method: 'DELETE',
        accept: 'application/json',
        headers: { "Authorization": "Bearer " + GetToken() },
        cache: "no-cache"
    };
    fetch(url, init).catch((error) => {
        console.log("ClearBusinessData fetch error: " + error.message);
    });
}

export const UpdateBusinessData = (businessdataid, deviceid, value, dec = 0) => {
    var url = `${configData.SERVER_URL}clientapi/device/postupdate`;
    const init = {
        method: 'POST',
        accept: "application/json",
        headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
        },
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify({
            "businessDataId": businessdataid,
            "hardwareDeviceId": deviceid,
            "value": value,
            "decimalValue": dec
        })
    }
    fetch(url, init).catch((error) => {
        console.log("UpdateBusinessData fetch error: " + error.message);
    });
}

export const GetEftpos = (d) => {
    return d.find(obj => obj.dataType === "eftpos");
}

export const PutServerCommand = (businessdataid, command) => {
    let cmd = encodeURIComponent(command);
    var url = `${configData.SERVER_URL}clientapi/device/putcommand?businessDataId=${businessdataid}&command=${cmd}`;
    const init = {
        method: 'PUT',
        accept: "application/json",
        headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
        },
        mode: "cors",
        cache: "no-cache"
    }
    fetch(url, init).catch((error) => {
        console.log("PutServerCommand fetch error: " + error.message);
    });

}

export const DefaultLoadType = (d) => {
    let perms = ["UseStandardLoad", "UseCountedItem", "UseStoredTare", "UseFirstWeigh", "UseSecondWeigh", "UseRegoWeigh", "UseMixedLoad", "UseVisitor", "UseSimple"];
    let lt = ["STANDARD", "M3", "STOREDTARE", "FIRST", "SECOND", "REGO", "MIXED", "VISITOR", "SIMPLE"];
    let found = 0;
    let def = "";
    d.map((x) => {
        if (perms.includes(x.name) && x.value.toLowerCase() === "true") {
            found = found + 1;
            if (def === "") def = x.name;
        }
    });
    if (found === 1) {
        let i = perms.indexOf(def);
        def = lt[i];
    } else {
        def = "";
    }
    return def;
}

export const CheckLoadAgainstTare = (data, weight) => {
    if (data === undefined || data.truck === undefined || data.truck === null) return "";
    if (data.truck.tare === 0 || data.truck.tare < weight) return "";
    let err = `Weight on is less than tare. Load is ${weight}t and total tare is ${data.truck.tare}t`;
    return err;
}

export const GetSelectionItems = (data) => {
    let s = [];
    if (data === undefined) return s;
    data.map(x => {
        s.push({ code: String(x.id), name: x.name });
    })
    return s;
}

export const GetNextPromptType = (settings, current, loadType) => {
    let o = ["Job", "Product", "ProductCategory", "Customer", "Destination", "Source", "TruckConfig"];
    let custfirst = ["Customer", "Job", "Product", "ProductCategory", "Destination", "Source", "TruckConfig"];
    let p = ["MustInputJob", "MustInputProduct", "MustInputProductCategory", "MustInputCustomer", "MustInputDestination", "MustInputSource", "MustInputVehicleConfiguration"];
    if (GetSetting(settings, "PromptCustomerFirstInDataSelection")) {
        o = [...custfirst];
        p = ["MustInputCustomer", "MustInputJob", "MustInputProduct", "MustInputProductCategory", "MustInputDestination", "MustInputSource", "MustInputVehicleConfiguration"];
    }
    let i = o.indexOf(current) + 1;
    let res = "";
    for (let j = i; j < 7 && res === ""; j++) {
        if (GetSetting(settings, p[j])) res = o[j];
        if (loadType === "COUNTED" && res !== "Destination" && res !== "Source") res = ""; // counted only prompts for source and dest
        if (loadType === "MIXED" && res !== "Destination" && res !== "Source" && res !== "TruckConfig") res = ""; // counted only prompts for source, dest and truck config
        if (loadType === "REGO" && res !== "Product") res = ""; // rego only prompts for product
    }
    return res;
}

export const GetNextStandardPrompt = (current) => {
    let o = ["Vehicles", "AllProducts"];
    let i = o.indexOf(current) + 1;
    let res = "";
    if (o.length > i) res = o[i];
    return res;
}

export const GetNextVisitorPrompt = (current) => {
    let o = ["VisitorType", "VisitorName", "VisitorCompany", "VisitorContact", "VisitorReason"];
    let i = o.indexOf(current) + 1;
    let res = "";
    if (o.length > i) res = o[i];
    return res;
}

export const PostTransaction = (trans, cb, onlineToken) => {
    console.log("PostTransaction: " + JSON.stringify(trans));
    var base = onlineToken !== "" ? configData.CLOUD_URL : configData.SERVER_URL;
    var token = onlineToken !== "" ? onlineToken : GetToken()
    var url = `${base}clientapi/weighbridge/posttransaction`;
    const init = {
        method: 'POST',
        accept: "application/json",
        headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + token
        },
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(trans)
    }
    fetch(url, init).then(response => {
        if (response.ok) 
            return response.json();
        else
            return { result: response.status };
    }).then(d => {
        if (d.result === undefined || d.result !== 0) {
            let msg = "Post Transaction fetch error: " + (d.result === undefined ? "Unknown error" : d.result);
            console.log(msg);
            cb({ result: 1, message: msg, online: onlineToken !== "" });
        } else {
            console.log("Post Transaction saved");
            cb(d);
        }
    }).catch((error) => {
        console.log("Post Transaction fetch error: " + error.message);
        cb({ result: 1, message: error.message, online: onlineToken !== "" });
    });
}

export const GenerateStateEvent = (event, msg) => {
    var url = `${configData.SERVER_URL}clientapi/weighbridge/updatestatemachine?eventname=${event}&value=${msg}`;
    const init = {
        method: 'PATCH',
        accept: 'application/json',
        headers: { "Authorization": "Bearer " + GetToken() },
        cache: "no-cache"
    };
    fetch(url, init).catch((error) => {
        console.log("GenerateStateEvent fetch error: " + error.message);
    });
}

export const SetRemoteDisplayMessage = (msg) => {
    var url = `${configData.SERVER_URL}clientapi/weighbridge/updateremotedisplay?msg=${msg}`;
    const init = {
        method: 'PATCH',
        accept: 'application/json',
        headers: { "Authorization": "Bearer " + GetToken() },
        cache: "no-cache"
    };
    fetch(url, init).catch((error) => {
        console.log("SetRemoteDisplay fetch error: " + error.message);
    });
}

export const GetWarningText = (num) => {
    switch (num) {
        case 1: return "1st";
        case 2: return "2nd";
        case 3: return "3rd"; 
    }
    return num + "th"; 
}

// returns true for fully automated modes: unmanned and instructions
export const IsAutomated = (mode) => {
    return (mode === "unmanned" || mode === "instructions");
}


// Determines the correct price for product prod with direction dir ("IN" or "OUT") and visit ("Local" or "Visitor")
// If date is past scheduled price date, use the scheduled prices
// returns { price: 12.34, gst: true, minimum: 15.00} with correct unit price, GST status, minimum price
export const GetUnitPrice = (prod, dir, visit) => {
    let r = { price: 0, gst: false, minimum: 0, epa: 0, minEpa: 0 };
    let d = String(dir).toUpperCase();
    let v = String(visit).toUpperCase();
    if (prod.schedulePriceDate === null || prod.schedulePriceDate > Date.now) {
        if (d === "IN") {
            if (v === "LOCAL") {
                r.price = prod.inLocalDiscount;
                r.gst = prod.inLocalDiscountGST;
                r.minimum = prod.minLocalDiscount;
            } else {
                r.price = prod.inVisitStandard;
                r.gst = prod.inVisitStandardGST;
                r.minimum = prod.minVisitStandard;
            }
        } else {
            if (v === "LOCAL") {
                r.price = prod.outLocalDiscount;
                r.gst = prod.outLocalDiscountGST;
            } else {
                r.price = prod.outVisitStandard;
                r.gst = prod.outVisitStandardGST;
            }
        }
        r.epa = prod.epaLevy;
        r.minEpa = prod.minEPALevy;
    } else {
        if (d === "IN") {
            if (v === "LOCAL") {
                r.price = prod.inLocalDiscount_S;
                r.gst = prod.inLocalDiscountGST;
                r.minimum = prod.minLocalDiscount_S;
            } else {
                r.price = prod.inVisitStandard_S;
                r.gst = prod.inVisitStandardGST;
                r.minimum = prod.minVisitStandard_S;
            }
        } else {
            if (v === "LOCAL") {
                r.price = prod.outLocalDiscount_S;
                r.gst = prod.outLocalDiscountGST;
            } else {
                r.price = prod.outVisitStandard_S;
                r.gst = prod.outVisitStandardGST;
            }
        }
        r.epa = prod.epaLevy_S;
        r.minEpa = prod.minEPALevy_S;
    }
    return r;
}

export const GetActiveScales = (d) => {
    let s = [];
    if (d === undefined) return s;
    d.map(x => {
        if (x.dataType === "indicator" && x.isActive) s.push(String(x.name).toUpperCase());
    });
    return s;
}

export const PostEventLog = (trans, onlineToken) => {
    var base = onlineToken !== "" ? configData.CLOUD_URL : configData.SERVER_URL;
    var token = onlineToken !== "" ? onlineToken : GetToken()

    var url = `${base}clientapi/weighbridge/posteventlog`;
    const init = {
        method: 'POST',
        accept: "application/json",
        headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + token
        },
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(trans)
    }
    fetch(url, init).catch((error) => {
        console.log("PostEventLog fetch error: " + error.message);
    });
}

export const IsWeighedLoad = (lt) => {
    return lt === "STOREDTARE" || lt === "FIRST" || lt === "SECOND" || lt === "MIXED" || lt === "SIMPLE";
}

export const LogVisitor = (trans, site) => {

    let visitor = {
        "name": trans.visitorName,
        "registration": trans.registration1,
        "organisation": trans.visitorOrg,
        "phone": trans.visitorPhone,
        "mobile": "",
        "reason": trans.visitorReason,
        "visitorType": trans.visitorType,
        "visiting": "",
        "hasLeft": false,
        "dateTimeIn": trans.transactionDate,
        "dateTimeOut": null,
        "siteId": site.siteId,
        "userId": trans.userId,
        "weighIn": trans.gross,
        "weighOut": 0
    };
    var url = `${configData.SERVER_URL}apiv7/visitors`;
    const init = {
        method: 'POST',
        accept: "application/json",
        headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
        },
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(visitor)
    }
    fetch(url, init).catch((error) => {
        console.log("LogVisitor fetch error: " + error.message);
    });
}

export const CreateOffence = (rego, offence, siteId) => {
    var url = `${configData.SERVER_URL}clientapi/weighbridge/logoffence?rego=${rego}&offence=${encodeURIComponent(offence)}&siteId=${siteId}`;
    const init = {
        method: 'POST',
        accept: 'application/json',
        headers: { "Authorization": "Bearer " + GetToken() },
        cache: "no-cache"
    };
    fetch(url, init).catch((error) => {
        console.log("CreateOffence fetch error: " + error.message);
    });   
}

export const GetCloudLogin = (setCloud) => {
    if (configData.SITE === 1) {
        setCloud("");
        return;
    }
    var id = encodeURIComponent(configData.REMOTE_ID);
    var url = `${configData.CLOUD_URL}apiv7/private/getcredentials?id=${id}`;
    fetch(url).then(resp => resp.json()).then(d => {
        const init = {
            headers: {
                "Content-Type": "application/json",
            },
            method: 'POST',
            mode: "cors",
            body: JSON.stringify(d)
        }
        url = `${configData.CLOUD_URL}apiv7/auth`;
        fetch(url, init).then(resp => resp.json()).then(d => {
            if (d !== undefined && d.token !== undefined) {
                sessionStorage.setItem("cloudtoken", d.token);
                setCloud(d);
            }
        })
    }).catch((error) => {
        sessionStorage.setItem("cloudtoken", "");
    });
}