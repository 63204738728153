import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import Notifications from '../components/Notifications';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import { GetToken } from '../components/Session';
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { TextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { DateRangePicker } from '@progress/kendo-react-dateinputs';


const Messages = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const [statii, setStatii] = React.useState([]);
    const [status, setStatus] = React.useState();
    const [subject, setSubject] = React.useState();
    const [expanded, setExpanded] = React.useState(false);
    const [dateRange, setDateRange] = React.useState();
    const [lookupParams, setLookupParams] = React.useState("");
    const ready = React.useRef(false);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");
    const [layout, setLayout] = React.useState([
        { "field": "searchAdvanced", "label": "Advanced Search", "isVisible": true, },
        { "field": "searchStatus", "label": "Status", "isVisible": true, }, 
        { "field": "searchSubject", "label": "Subject", "isVisible": true, "type": "text" },
        { "field": "searchDate", "label": "Message Date", "isVisible": true, "type": "date" },
        { "field": "type", "label": "Type", "isVisible": true, "type": "text" },
        { "field": "to", "label": "To", "isVisible": true, "type": "text" },
        { "field": "subject", "label": "Subject", "isVisible": true, "type": "text" },
        { "field": "creationTime", "label": "Created", "isVisible": true, "type": "text" },
        { "field": "sentTime", "label": "Sent", "isVisible": true, "type": "text" },
        { "field": "isActive", "label": "Active", "isVisible": true, "type": "text" }
    ]);

    React.useEffect(() => {
        GetColumnDefs("Messages", setColumns, setErrorMessage);
        GetPermissions(["ViewMessageQueue", "NewMessageQueue", "EditMessageQueue", "DeleteMessageQueue"], setPermissions);
        let baseUrl = configData.SERVER_URL + "apiv7/options";
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        fetch(baseUrl, init)
            .then((response) => response.json())
            .then((d) => {
                setStatii(d.status);
                ready.current = true;
                ClearSearch();
            });

    }, []);

    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    const ClearParams = () => {
        setStatus("Active");
        setSubject("");
        let dt = new Date();
        let dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 59) };
        setDateRange(dr);
    };
    const statusChanged = (event) => {
        setStatus(event.target.value);
    };
    const subjectChanged = (event) => {
        setSubject(event.target.value);
    }


    const rangeChanged = (event) => {
        let dr = event.target.value;
        setDateRange(dr);
    }

    const ClearSearch = () => {
        if (ready.current) {
            let dt = new Date();
            let dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 59) };
            ClearParams();
            var params = {
                FromDate: dr.start.toISOString(),
                ToDate: dr.end.toISOString(),
                Subject: "",
                Status: "Active"
            };
            setLookupParams(encodeURI(JSON.stringify(params)));
        }
    }

    const DoSearch = () => {
        var messageParam = {
            FromDate: dateRange.start === null ? "2000-01-01T00:00:00Z" : dateRange.start.toISOString(),
            ToDate: dateRange.end === null ? "2050-01-01T00:00:00Z" : dateRange.end.toISOString(),
            Subject: subject === undefined ? "" : subject,
            Status: status == undefined ? "Active" : status
        }
        setLookupParams(encodeURI(JSON.stringify(messageParam)));
    }

    return (
        <div>
            <Notifications errorMessage={errorMessage} />
            {GetField("searchAdvanced")["isVisible"] && <ExpansionPanel
                title={GetField("searchAdvanced")["label"]}
                expandIcon="fa fa-plus"
                collapseIcon="fa fa-minus"
                expanded={expanded}
                onAction={(e) => setExpanded(!e.expanded)}
            >
            <Reveal>
                {expanded && (
                    <ExpansionPanelContent>
                        <div className="row" style={{ padding: "5px 10px" }}>
                            {GetField("searchSubject")["isVisible"] && <div className="col-md-2">
                                <label className="control-label">{GetField("searchSubject")["label"]}</label>
                                 <TextBox id="Subject" type="text" value={subject} onChange={subjectChanged} />
                            </div>}
                            {GetField("searchStatus")["isVisible"] && <div className="col-md-2">
                                <label className="control-label">{GetField("searchStatus")["label"]}</label>
                                <DropDownList
                                    id="TruckTrailer"
                                    data={statii}
                                    value={status}
                                    onChange={statusChanged}
                                />
                                </div>}
                                {GetField("searchDate")["isVisible"] && <div className="col-md-3">
                                    <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged} />
                                </div>}
                        </div>
                        <div className="row" style={{ padding: "10px" }}>
                            <div className="col-md-2"></div>
                            <div className="col-md-3">
                                <Button onClick={DoSearch} themeColor={"primary"}>
                                    Search
                                </Button>{" "}
                                <Button onClick={ClearSearch} themeColor={"primary"}>
                                    Clear
                                </Button>
                            </div>
                        </div>

                    </ExpansionPanelContent>
                )}
            </Reveal>
        </ExpansionPanel>}
            {columns && permissions && <DataGrid dataSource='apiv7/messagequeues' columns={columns} permissions={permissions} keyField="messageQueueId" params={lookupParams} />}
        </div>
    );
}

export default Messages;