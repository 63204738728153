import React, { useState } from "react";


const weighmorelogo = () =>{
 
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="404" height="250"

          preserveAspectRatio="xMidYMid meet">

          <metadata>
          Created by potrace 1.10, written by Peter Selinger 2001-2011
          </metadata>
          <g>
          <g>
          <path class="st0" d="M73.71,117.87l4.46,42.73l19.66-42.73h27.06l-12.06,68.47H92.61l4.18-22.79l1.71-8.74l3.42-12.44L81.5,186.34
                H65.93l-4.84-43.97l-0.95,9.69c-0.82,8.17-2.69,19.6-5.6,34.28H34.59l12.06-68.47C46.65,117.87,73.71,117.87,73.71,117.87z" />
          <path class="st0" d="M153.1,135.16c6.65,0,12.39,1.63,17.24,4.9c4.84,3.27,7.26,8.45,7.26,15.55l-0.38,5.14
                c-0.95,6.09-3.12,11.2-6.5,15.32c-3.39,4.12-7.36,7.1-11.92,8.94c-1.08,0.44-2.18,0.84-3.32,1.19c-1.14,0.35-2.64,0.73-4.51,1.14
                s-4.07,0.62-6.6,0.62c-7.09,0-12.98-1.73-17.66-5.18c-4.69-3.46-7.03-8.55-7.03-15.27l0.28-3.04l0.29-2.57
                c1.71-8.88,5.62-15.55,11.73-20.03C138.08,137.39,145.13,135.16,153.1,135.16z M151.39,148.36c-3.93,0-6.74,1.75-8.45,5.24
                c-1.46,3.3-2.53,7.5-3.23,12.58c0,5.59,2.22,8.39,6.65,8.39c3.8,0,6.61-1.78,8.45-5.34s2.75-7.5,2.75-11.82
                C157.57,151.37,155.51,148.36,151.39,148.36z" />
          <path class="st0" d="M183.97,136.49h17.95l-1.71,10.16c4.11-7.66,9.94-11.49,17.47-11.49h1.52h1.04l-3.51,20.04
                c-1.52-0.38-2.94-0.57-4.27-0.57c-3.86,0-6.98,1.11-9.35,3.32c-2.37,2.22-4.07,5.98-5.08,11.3l-2.94,17.09h-19.85L183.97,136.49z" />
          <path class="st0" d="M249.2,171.34h18.9c-1.84,4.52-5.32,8.42-10.45,11.7s-11.78,4.92-19.94,4.92c-7.6,0-13.58-1.9-17.95-5.71
                c-4.37-3.8-6.55-8.85-6.55-15.13l0.57-5.23c0.38-2.22,1.03-4.44,1.95-6.66s2.14-4.34,3.66-6.37c2.66-3.68,6.46-6.98,11.4-9.89
                c2.53-1.27,5.03-2.22,7.5-2.86c1.2-0.25,2.56-0.47,4.08-0.66s3.01-0.29,4.46-0.29c5.82,0,10.54,1.09,14.15,3.28
                c3.61,2.18,6.27,5.11,7.98,8.78c1.71,3.67,2.56,7.57,2.56,11.68v1.61c0,0.38,0,0.73,0,1.04c0,0.44,0,0.73,0,0.85l-0.57,3.51
                h-38.08l-0.1,1.82c0,2.11,0.67,3.93,2,5.46c0.82,0.83,1.74,1.5,2.75,2.01c1.14,0.45,2.25,0.67,3.32,0.67
                C244.46,175.9,247.24,174.38,249.2,171.34z M234.77,155.76h17.95c0-1.58-0.13-2.75-0.38-3.51c-0.19-0.89-0.66-1.74-1.42-2.56
                c-1.33-1.65-3.2-2.47-5.6-2.47c-2.72,0-5.02,0.71-6.88,2.14C236.56,150.78,235.34,152.91,234.77,155.76z" />
          </g>
          <g>
          <path class="st1" d="M128.16,67.06h19.91l0.35,38.94l14.49-38.94h17.11l0.61,38.94l14.06-38.94h19.91l-26.63,62.95h-20.25
                l-0.87-36.49l-13.79,36.49h-20.25L128.16,67.06z" />
          <path class="st1" d="M237.81,116.22h17.37c-1.69,4.16-4.89,7.74-9.6,10.76c-4.71,3.02-10.83,4.52-18.33,4.52
                c-6.98,0-12.48-1.75-16.5-5.25s-6.02-8.13-6.02-13.91l0.52-4.81c0.35-2.04,0.95-4.08,1.79-6.12s1.96-3.99,3.36-5.86
                c2.44-3.38,5.94-6.41,10.48-9.1c2.33-1.17,4.63-2.04,6.9-2.62c1.1-0.23,2.36-0.44,3.75-0.61c1.4-0.17,2.76-0.26,4.1-0.26
                c5.35,0,9.69,1,13.01,3.01c3.32,2.01,5.76,4.7,7.33,8.08c1.57,3.38,2.36,6.96,2.36,10.74v1.48c0,0.35,0,0.67,0,0.96
                c0,0.41,0,0.67,0,0.79l-0.53,3.23h-35l-0.09,1.68c0,1.94,0.61,3.61,1.83,5.02c0.76,0.76,1.6,1.38,2.53,1.85
                c1.05,0.41,2.06,0.62,3.06,0.62C233.45,120.41,236.01,119.01,237.81,116.22z M224.54,101.9h16.5c0-1.45-0.12-2.53-0.35-3.23
                c-0.17-0.81-0.61-1.6-1.31-2.36c-1.22-1.51-2.94-2.27-5.15-2.27c-2.5,0-4.61,0.65-6.33,1.96
                C226.19,97.31,225.07,99.28,224.54,101.9z" />
          <path class="st1" d="M265.31,84.26h18.25l-8.12,45.75h-18.07L265.31,84.26z M268.54,65.49h18.25l-2.18,12.92h-18.25L268.54,65.49z
                " />
          <path class="st1" d="M321.01,84.17h15.98l-6.81,38.79c-0.99,5.58-2.53,9.94-4.63,13.08c-1.92,3.25-4.8,5.64-8.64,7.15
                c-3.84,1.51-8.85,2.27-15.02,2.27c-5.18,0-10.03-0.93-14.54-2.79s-6.77-5.33-6.77-10.39h18.25c0,2.1,1.51,3.14,4.54,3.14
                c2.27,0,4.1-0.6,5.5-1.81c1.4-1.09,2.47-3.31,3.23-6.66l0.7-4.1c-3.78,3.32-8.56,4.98-14.32,4.98c-4.54,0-8.41-1.48-11.61-4.45
                c-3.2-2.97-4.8-7.01-4.8-12.14l0.26-2.01l0.26-2.44c1.4-8.21,4.45-14.23,9.17-18.07c4.71-3.84,9.89-5.76,15.54-5.76
                c3.03,0,5.56,0.67,7.6,2.01c2.1,1.22,3.78,3,5.06,5.33L321.01,84.17z M309.93,95.87c-3.14,0-5.31,1.14-6.5,3.4
                c-1.19,2.27-2.17,5.36-2.92,9.25c0,4.42,2.15,6.64,6.46,6.64c3.08,0,5.37-1.28,6.85-3.84s2.23-5.44,2.23-8.64
                C316.04,98.14,314,95.87,309.93,95.87z" />
          <path class="st1" d="M344.06,67.06h18.07l-4.1,22.87c4.48-4.66,9.6-6.98,15.37-6.98c9.78,0,14.67,4.6,14.67,13.79
                c0,1.46-0.17,3.23-0.52,5.33l-4.8,27.94H364.5l4.28-23.83l0.17-3.32c0-3.72-1.72-5.59-5.15-5.59c-4.66,0-7.54,3.03-8.64,9.08
                l-4.1,23.66H332.8L344.06,67.06z" />
          </g>
          <g>
          <g>
          <path d="M41.96,202.26l-0.11,0.64h-2.51l0.14-0.78c0.38-2.14-0.22-3.39-2.12-3.39s-2.94,1.25-3.31,3.36
                  c-0.94,5.33,6.94,5.83,5.77,12.51c-0.63,3.6-2.78,5.94-6.41,5.94s-4.91-2.34-4.28-5.94l0.24-1.36h2.48l-0.26,1.49
                  c-0.38,2.14,0.28,3.39,2.18,3.39c1.9,0,3-1.25,3.38-3.39c0.93-5.29-6.92-5.8-5.74-12.48c0.65-3.66,2.74-5.94,6.3-5.94
                  C41.31,196.33,42.59,198.67,41.96,202.26z" />
          <path d="M77.33,202.29c0.64-3.63,2.85-5.97,6.48-5.97c3.66,0,5.05,2.34,4.41,5.97l-2.17,12.28c-0.63,3.59-2.85,5.97-6.51,5.97
                  c-3.63,0-5.01-2.38-4.37-5.97L77.33,202.29z M77.75,214.71c-0.38,2.17,0.31,3.43,2.21,3.43c1.93,0,3.04-1.25,3.42-3.43
                  l2.21-12.55c0.38-2.14-0.28-3.43-2.21-3.43c-1.9,0-3.04,1.29-3.42,3.43L77.75,214.71z" />
          <path d="M120.79,220.3l4.19-23.74h2.65l-3.76,21.33h6.78l-0.42,2.41H120.79z" />
          <path d="M168.95,214.71c-0.38,2.14,0.24,3.43,2.18,3.43c1.9,0,2.97-1.29,3.35-3.43l3.2-18.15h2.48l-3.18,18.01
                  c-0.63,3.59-2.72,6-6.38,6c-3.63,0-4.87-2.41-4.23-6l3.18-18.01h2.61L168.95,214.71z" />
          <path d="M215.25,220.3l3.76-21.33h-4.38l0.42-2.41h11.36l-0.42,2.41h-4.38l-3.76,21.33H215.25z" />
          <path d="M264.1,196.56l-4.19,23.74h-2.65l4.19-23.74H264.1z" />
          <path d="M298.84,202.29c0.64-3.63,2.85-5.97,6.48-5.97c3.66,0,5.05,2.34,4.41,5.97l-2.17,12.28c-0.63,3.59-2.85,5.97-6.51,5.97
                  c-3.63,0-5.01-2.38-4.37-5.97L298.84,202.29z M299.26,214.71c-0.38,2.17,0.31,3.43,2.21,3.43c1.93,0,3.04-1.25,3.42-3.43
                  l2.21-12.55c0.38-2.14-0.28-3.43-2.21-3.43c-1.9,0-3.04,1.29-3.42,3.43L299.26,214.71z" />
          <path d="M344.64,220.3h-2.37l4.19-23.74h3.36l2.47,17.16l3.03-17.16h2.34l-4.19,23.74h-2.75l-2.69-19.2L344.64,220.3z" />
          <path d="M402.87,202.26l-0.11,0.64h-2.51l0.14-0.78c0.38-2.14-0.22-3.39-2.12-3.39s-2.94,1.25-3.31,3.36
                  c-0.94,5.33,6.94,5.83,5.77,12.51c-0.63,3.6-2.78,5.94-6.41,5.94s-4.91-2.34-4.28-5.94l0.24-1.36h2.48l-0.26,1.49
                  c-0.38,2.14,0.28,3.39,2.18,3.39s3-1.25,3.38-3.39c0.93-5.29-6.91-5.8-5.74-12.48c0.65-3.66,2.74-5.94,6.3-5.94
                  C402.22,196.33,403.51,198.67,402.87,202.26z" />
          </g>
          </g>
          <polygon class="st1" points="122.96,109.32 48.71,109.32 49.72,102.47 121.99,102.47 	" />
          <g>
          <g>
          <g>
          <g>
          <polygon points="309.59,149.53 328.43,149.53 328.43,160.05 332.1,160.05 332.1,143.65 373.69,143.65 373.69,172.04
                      301.75,172.04 					" />
          </g>
          </g>
          <g>
          <path d="M311.05,171.39c0,2.97,2.41,5.38,5.38,5.38c2.98,0,5.39-2.41,5.39-5.38s-2.41-5.38-5.39-5.38
                    C313.46,166.01,311.05,168.42,311.05,171.39z" />
          </g>
          <g>
          <path d="M354.12,171.39c0,2.97,2.41,5.38,5.38,5.38c2.98,0,5.38-2.41,5.38-5.38s-2.41-5.38-5.38-5.38
                    S354.12,168.42,354.12,171.39z" />
          </g>
          <g>
          <path d="M338.46,171.39c0,2.97,2.41,5.38,5.38,5.38s5.38-2.41,5.38-5.38s-2.41-5.38-5.38-5.38S338.46,168.42,338.46,171.39z" />
          </g>
          </g>
          </g>
          <g>
          <polygon points="382.26,136.8 374.2,136.8 391.31,177.84 270.76,177.84 267.95,184.69 402.83,184.69 		" />
          <path d="M290.17,150.75c-2.9-0.72-5.31-1.68-7.34-2.74l-12.72,30.88h8.81L290.17,150.75z" />
          </g>
          <g>
          <g>
          <polygon points="336.99,31.42 314.41,80.04 322.26,80.04 336.99,47.57 342.23,60.14 349.32,60.14 			" />
          </g>
          </g>
          </g>
    </svg>
  );
}
export default weighmorelogo;