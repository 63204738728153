import * as React from 'react';
import Weighbridge from "./Weighbridge";

if (window.innerWidth < 1600) { // for small screens use smallscreen css
    require('./SmallScreen.css')
}

const Kiosk = () => {

    return (<Weighbridge />);
}

export default Kiosk;