import * as React from 'react';
import { Button } from "@progress/kendo-react-buttons";
import SignatureCanvas from "../components/SignaturePad";
import { UpdateBusinessData } from "./Helpers";
import { GetField } from "./Helpers";


export const Signatures = (props) => {
    const signature = props.signature === undefined ? { value: "" } : props.signature;
    const layout = props.layout;
    const useTouch = props.useTouch;
    const touchPad = React.useRef(null);

    const ClearSignature = () => {
        if (useTouch) {
            touchPad.current.clear();
            SaveSignature();
        } else {
            props.clearSignature(signature.businessDataId);
        }
        if (props.setSigned !== undefined) props.setSigned(false);
    }

    const SaveSignature = () => {
        let val = touchPad.current === null ? "" : touchPad.current.toDataURL();
        if (val == "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg==") val = "";
        let hwid = signature.devices !== undefined && signature.devices.length > 0 ? signature.devices[0].hardwareDeviceId : 0;
        UpdateBusinessData(signature.businessDataId, hwid, val, 0);
        if (props.setSigned !== undefined) props.setSigned(true);
    }

    return (
        <div>
            {signature.value !== "" && !useTouch && layout && <div>
                <img src={signature.value} className={"signatureImage"} />
                <div className={"signatureClear"}><Button onClick={ClearSignature}>{GetField(layout, "clearSignature")["label"]}</Button></div>
            </div>}
            {useTouch && layout && <div className={"signaturePad"}>
                <SignatureCanvas ref={touchPad} />
                <div className={"touchSignatureClear"}>
                    <Button onClick={ClearSignature}>{GetField(layout, "clearSignature")["label"]}</Button>
                    <Button onClick={SaveSignature}>{GetField(layout, "saveSignature")["label"]}</Button>
                </div>
            </div>}
        </div>
    );
}