import * as React from 'react';
import Notifications from '../components/Notifications';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";

const Drivers = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");
    
    React.useEffect(() => { 
        GetColumnDefs("Drivers", setColumns, setErrorMessage); 
        GetPermissions(["ViewDriver", "NewDriver", "EditDriver", "DeleteDriver", "ReportDriver"], setPermissions)
    }, [])
    return (
        <div>
        <Notifications errorMessage={errorMessage}/>
        {columns && permissions && <DataGrid dataSource='apiv7/drivers' columns={columns} permissions={permissions} keyField ="driverId" />}
        </div>       
    ); 
}

export default Drivers;