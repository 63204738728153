import * as React from 'react';

const Contact = () => {
return (   
<div className="row">
    <div className="col-md-1"></div>
    <div className="col-md-5">
        <div className="roundedCont">
            <address>
                9/160 Hartley Road<br />
                Smeaton Grange, NSW 2567<br />
                Australia<br /><br />
                <strong>Phone:</strong> +61 1300 366 131<br /><br />
                <strong>Support:</strong>   <a href="mailto:support@weigh-more.com.au">support@weigh-more.com.au</a><br /><br />
                <strong>Marketing:</strong> <a href="mailto:sales@weigh-more.com.au">sales@weigh-more.com.au</a>
            </address>
        </div>
    </div>
    <div className="col-md-5">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.2400092442745!2d150.75361541493172!3d-34.03771398060981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12f1e804ab3971%3A0x36b13d605bca4a1!2s9%2F160%20Hartley%20Rd%2C%20Smeaton%20Grange%20NSW%202567!5e0!3m2!1sen!2sau!4v1581458665540!5m2!1sen!2sau" width="550" height="260" frameborder="0" style={{border: "0px"}} allowfullscreen=""></iframe>
    </div>
</div>
)
}
export default Contact