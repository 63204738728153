import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { Button } from '@progress/kendo-react-buttons';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import Notifications from '../components/Notifications';

const InventoryHistory = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/error401");
    React.useEffect(() => { 
        GetColumnDefs("InventoryHistory", setColumns); 
        GetPermissions(["ViewInventory", "ManageInventory", "ReportInventory"], setPermissions)
    }, [])
    return (
        <div>
            <Notifications errorMessage={errorMessage}/>
            {permissions && permissions.ManageInventory && <div className="row" style={{padding:"5px 10px"}}>
                <div className="col-md-1">
                <a href="inventory"><Button themeColor={"primary"}>Back</Button></a>
                </div>
            </div>}
            <div className="row">
                {columns && permissions && <DataGrid dataSource='apiV7/InventoryHistory' sortable={false} columns={columns} permissions={permissions}  />}
            </div>
        </div>       
    );

}

export default InventoryHistory;