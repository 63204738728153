import * as React from 'react';
import { Label } from '@progress/kendo-react-labels';
import { WeightMeter } from './WeightMeter';
import { TrafficControl } from './TrafficControl';
import { RemoteDisplay } from './RemoteDisplay';
import { GetSetting } from "./Helpers";
import { Button } from "@progress/kendo-react-buttons";
import configData from "../config.json";
import cloud from '../assets/cloud.png'
import nocloud from '../assets/nocloud.png'

export const WeighBridgePanel = (props) => {
    const showTrafficLights = !GetSetting(props.settings, "HideTrafficLight");
    const showRemote = !GetSetting(props.settings, "HideRemoteDisplay");
    const showWeight = !GetSetting(props.settings, "HideWeightController");
    const showTraffic = props.showTraffic === undefined ? true : props.showTraffic;
    const allowSplit = props.allowSplit;
    const activeScale = props.activeScale;
    const [cloudStatus, setCloudStatus] = React.useState("");
    const [cloudIcon, setCloudIcon] = React.useState();
    const cloudTimer = React.useRef(null);
    const cloudOnline = React.useRef(false);

    React.useEffect(() => {
        if (configData.SITE > 1) {
            setCloudStatus("EzyWeigh Cloud Offline");
            cloudOnline.current = false;
            setCloudIcon(nocloud);
            cloudTimer.current = setInterval(CheckCloud, 10000);
        }
        return () => {
            if (cloudTimer.current !== null) clearInterval(cloudTimer.current);
        }
    }, []);

    const RadioClicked = (e) => {
        let id = String(e.target.innerText);
        if (props.changeScale) props.changeScale(id);
    }

    const CheckCloud = () => {
        let url = `${configData.CLOUD_URL}apiv7/private/checkcloud`;
        fetch(url).then(resp => {
            if (resp.ok) return resp.ok;
            CloudOffline("Error " + resp.status);
        }).then(d => {
            if (d !== undefined && d && !cloudOnline.current) {
                console.log("Cloud back online");
                setCloudStatus("EzyWeigh Cloud Online");
                setCloudIcon(cloud);
                cloudOnline.current = true;
                if (props.changeCloudStatus) props.changeCloudStatus(true);
            }
        }).catch((error) => {
            CloudOffline(error);
        });
    }

    const CloudOffline = (error) => {
        if (cloudOnline.current) {
            console.log("Cloud offline:" + error);
            setCloudStatus("EzyWeigh Cloud Offline");
            setCloudIcon(nocloud);
            if (props.changeCloudStatus) props.changeCloudStatus(false);
            cloudOnline.current = false;
        }
    }

    return (
        <div style={{ display: "flex" }}>
            <div>
                {showTraffic && showTrafficLights && <TrafficControl {...props} />}
                {showRemote && <RemoteDisplay {...props} />}
                {props.activeScales !== null && props.activeScales !== undefined && !props.scaleDisabled && <div className="activeScaleOptions">{
                    props.activeScales.map((x) => (
                        <div className="activeScaleDiv"><Button className="activeScaleButton" selected={activeScale === x} onClick={RadioClicked} togglable={true}>{x}</Button></div>
                    ))
                }</div>}
            </div>
            {showWeight && <WeightMeter
                {...props}
                weights={props.weights}
                settings={props.settings}
                gaugeColour="white"
                maxWeight={props.wbSettings.MaxWeight}
                width="400"
                decks={props.wbSettings.Decks}
                allowSplit={allowSplit}
                weighStatus={props.weightStatus}
                options={props.options}
            />}
            {configData.SITE > 1 && GetSetting(props.settings, "DisplayCloud") && <div id="CloudAvailableDiv"><img src={cloudIcon} title={cloudStatus}></img></div>}
        </div>
    );
}