import * as React from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { DocketPreview } from "./DocketPreview";
import { Signatures } from "./Signature";
import { CreateNewTransaction, GetTransactionData } from "./Transaction";
import { NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { Label } from '@progress/kendo-react-labels';
import { ListView } from "@progress/kendo-react-listview";
import { GetField, GetSetting, ClearBusinessData } from "./Helpers";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const InfoItem = (props) => {
    return (
        <div className="tableList">
            <div className="tableListName">{props.dataItem.name}</div>
            <div className="tableListValue">{props.dataItem.value}</div>
        </div>
    );
}

export const Preview = (props) => {
    const [trans, setTrans] = React.useState(props.transaction);
    const [paymentType, setPaymentType] = React.useState();
    const [direction, setDirection] = React.useState();
    const [chargeRate, setChargeRate] = React.useState();
    const [vehicleOwner, setVehicleOwner] = React.useState();
    const [paymentSubtotal, setPaymentSubtotal] = React.useState(props.transaction.subTotal);
    const [paymentFees, setPaymentFees] = React.useState(props.transaction.totalFees);
    const [paymentDiscounts, setPaymentDiscounts] = React.useState(props.transaction.discounts);
    const [paymentOwing, setPaymentOwing] = React.useState(props.transaction.totalCost);
    const [deliveryFee, setDeliveryFee] = React.useState(0);
    const [penaltyFee, setPenaltyFee] = React.useState(0);
    const site = props.site;
    const settings = props.settings;
    const layout = props.layout;
    const infoList = props.displayInfo;
    const signature = props.signature;
    const useSignature = GetSetting(settings, "UseSignaturePad");
    const [tendered, setTendered] = React.useState("$0.00");
    const [change, setChange] = React.useState(0);
    const [showCashPayment, setShowCashPayment] = React.useState(false);
    const [showOwing, setShowOwing] = React.useState(false);
    const useTouch = GetSetting(settings, "UseTouchScreenSignaturePad");
    const showComments = !GetSetting(settings, "HideCommentBox");
    const [signed, setSigned] = React.useState(false);
    const [showSignHere, setShowSignHere] = React.useState(false);
    const savetimer = React.useRef(null);
    const printtimer = React.useRef(null);
    const [enablePrint, setEnablePrint] = React.useState(false);

    let printButton = "SAVE";
    if (props.transaction !== trans) {
        setTrans(props.transaction);
        setShowOwing(true);// props.transaction.totalCost > 0);
    }
    if (props.transaction.subTotal !== paymentSubtotal) setPaymentSubtotal(props.transaction.subTotal);
    if (props.transaction.totalFees != paymentFees) setPaymentFees(props.transaction.totalFees);
    if (props.transaction.totalCost != paymentOwing) setPaymentOwing(props.transaction.totalCost);
    if (props.transaction.discounts !== paymentDiscounts) {
        setPaymentDiscounts(props.transaction.discounts);
        let o = props.transaction.subTotal + props.transaction.totalFees - props.transaction.discounts;
        if (o < 0) o = 0;
        setPaymentOwing(o);
    }
    if (trans.loadType !== "FIRST" || GetSetting(settings, "PrintFirstWeighTicket")) printButton = GetField(layout, "print")["label"];
    let showPenalty = trans.loadType !== "STANDARD" && trans.loadType !== "COUNTED" && trans.loadType !== "MIXED";
    let showDelivery = trans.loadType === "STOREDTARE" || trans.loadType === "SECOND" || trans.loadType === "SIMPLE";


    React.useEffect(() => {
        setPaymentType(GetSetting(props.settings, "PaymentDefaultOption"));
        setDirection(String(props.transaction.direction).toUpperCase());
        setChargeRate(String(props.transaction.chargeRate).toUpperCase());
        setVehicleOwner(String(props.transaction.vehicleOwner).toUpperCase());
        if (props.onSelection) {
            props.onSelection("Payment", GetSetting(props.settings, "PaymentDefaultOption"));
        };
        let tsdt = GetSetting(props.settings, "TicketSaveDelayTime");
        let asdt = GetSetting(props.settings, "AutoSaveDelayTime");
        if (tsdt > 0) {
            printtimer.current = setTimeout(() => {
                setEnablePrint(true);
            }, tsdt * 1000);
        } else {
            setEnablePrint(true);
        }
        if (asdt > 0) {
            savetimer.current = setTimeout(() => { PrintDocket() }, (asdt + tsdt) * 1000);
        }
        return () => {
            if (printtimer.current !== null) clearTimeout(printtimer.current); // clean up when leaving page
            if (savetimer.current !== null) clearTimeout(savetimer.current); 
        }
    }, []);

    const ClosePreview = () => {
        if (props.onClose) props.onClose(false);
    }

    const PrintDocket = () => {
        if (tendered == "$0.00" && paymentOwing > 0 && paymentType === "CASH")
            setShowCashPayment(true);
        else {
            if (GetSetting(props.settings, "MustEnterSignature") && !signed) {
                if (GetSetting(settings, "UseTouchScreenSignaturePad")) {
                    setShowSignHere(true);
                } else {
                    alert("Please sign on the signature pad");
                }
                return;
            }
            if (props.onClose) props.onClose(true);
        }
    }

    const Signed = (s) => {
        setSigned(s);
    }

    const PaymentClicked = (e) => {
        let id = String(e.target.innerText);
        setPaymentType(id);
        if (props.onSelection) props.onSelection("Payment", id);
    }

    const DirectionClicked = (e) => {
        let id = String(e.target.innerText);
        setDirection(id);
        if (props.onSelection) props.onSelection("Direction", id);
    }
    const RateClicked = (e) => {
        let id = String(e.target.innerText);
        setChargeRate(id);
        if (props.onSelection) props.onSelection("Rate", id);
    }
    const OwnerClicked = (e) => {
        let id = String(e.target.innerText);
        setVehicleOwner(id);
        if (props.onSelection) props.onSelection("Owner", id);
    }

    const PenaltyChanged = (e) => {
        setPenaltyFee(e.value);
        setPaymentFees(e.value + deliveryFee);
        if (props.onSelection) props.onSelection("Penalty", e.value);
        let o = paymentSubtotal + e.value + deliveryFee - paymentDiscounts;
        if (o < 0) o = 0;
        setPaymentOwing(o);
    }

    const DeliveryChanged = (e) => {
        setDeliveryFee(e.value);
        setPaymentFees(e.value + penaltyFee);
        if (props.onSelection) props.onSelection("Delivery", e.value);
        let o = paymentSubtotal + e.value + penaltyFee - paymentDiscounts;
        if (o < 0) o = 0;
        setPaymentOwing(o);
    }

    const CommentChanged = (e) => {
        if (props.onSelection) props.onSelection("Comment", e.value);
    }

    const ProcessCashPayment = () => {
        if (props.onClose) props.onClose(true);
    }

    const TenderedChanged = (e) => {
        setTendered(e.value);
        let t = parseFloat(String(e.value).replace("$", ""));
        let c = t - trans.TotalCost;
        setChange(c.toFixed(2));
    }

    const AddPayment = (a) => {
        let t = parseFloat(String(tendered).replace("$", ""));
        setTendered("$" + (t + a).toFixed(2));
        let c = t + a - paymentOwing;
        setChange(c);
    }

    const ClearPayment = () => {
        setTendered("$0.00");
        setChange(0);
    }

    return (
        <div id="PreviewDiv">
            {showCashPayment && <Dialog title={"Cash Payment"} onClose={() => setShowCashPayment(false)}>
                <div className="cashAmounts">
                    <div className="loadTypeDiv"><button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => AddPayment(100) }>$100</button></div>
                    <div className="loadTypeDiv"><button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => AddPayment(50) }>$50</button></div>
                    <div className="loadTypeDiv"><button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => AddPayment(20) }>$20</button></div>
                    <div className="loadTypeDiv"><button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => AddPayment(10)}>$10</button></div>
                    <div className="loadTypeDiv"><button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => AddPayment(5)}>$5</button></div>
                </div>
                <Label className="paymentGap">{GetField(layout, "tendered")["label"]}</Label>
                <div className="cashAmounts">
                    <NumericTextBox format="c2" width={150} onChange={TenderedChanged} value={tendered}></NumericTextBox>
                    <div className="loadTypeDiv"><button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => ClearPayment()}>Clear</button></div>
                    </div>
                <Label className="paymentGap">{GetField(layout, "owing")["label"]}</Label>
                <div className="paymentAmount">${paymentOwing.toFixed(2)}</div>
                <Label className="paymentGap">{GetField(layout, "change")["label"]}</Label>
                <div className="paymentAmount">${change.toFixed(2)}</div>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => ProcessCashPayment()}>Ok</button>
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => setShowCashPayment(false)}>Cancel</button>
               </DialogActionsBar>
            </Dialog>}
            <div id="DocketPreview"><DocketPreview transaction={trans} site={site} settings={props.settings}></DocketPreview></div>
            <div id="TransactionSummary">
                <ListView data={infoList} item={InfoItem}></ListView>
            </div>
            {useSignature && <div id="SignatureDiv" ><Signatures signature={signature} useTouch={useTouch} layout={layout} clearSignature={ClearBusinessData} setSigned={Signed}></Signatures>{showSignHere && <div className="SignHereDiv">Please sign here and click Save</div>}</div>}
            <div id="Payments">
                {showOwing && <div>
                    {paymentType !== "ACCOUNT" && <div style={{ display: "flex" }}>
                        {GetField(layout, "paymentSubtotal")["isVisible"] && <div className="paymentGap">
                            <Label>{GetField(layout, "paymentSubtotal")["label"]}</Label>
                            <div className="paymentTotal paymentTotalLarge">${paymentSubtotal.toFixed(2)}</div>
                        </div>}
                        {GetField(layout, "paymentFees")["isVisible"] && <div className="paymentGap">
                            <Label>{GetField(layout, "paymentFees")["label"]}</Label>
                            <div className="paymentTotal">${paymentFees.toFixed(2)}</div>
                        </div>}
                        {GetField(layout, "paymentDiscounts")["isVisible"] && <div className="paymentGap">
                            <Label>{GetField(layout, "paymentDiscounts")["label"]}</Label>
                            <div className="paymentTotal">${paymentDiscounts.toFixed(2)}</div>
                        </div>}
                        {GetField(layout, "paymentOwing")["isVisible"] && <div className="paymentGap">
                            <Label>{GetField(layout, "paymentOwing")["label"]}</Label>
                            <div className="paymentTotal paymentTotalLarge">${paymentOwing.toFixed(2)}</div>
                        </div>}
                    </div>}
                    <div>
                        {GetField(layout, "paymentType")["isVisible"] && <Label>{GetField(layout, "paymentType")["label"]}</Label>}
                        <div style={{ display: "flex" }}>
                            {!GetSetting(settings, "DisableCashPaymentOption") && <div className="loadTypeDiv"><Button selected={paymentType == "CASH"} onClick={PaymentClicked} togglable={true}>{GetField(layout, "cash")["label"]}</Button></div>}
                            {!GetSetting(settings, "DisableAccountPaymentOption") && <div className="loadTypeDiv"><Button selected={paymentType == "ACCOUNT"} onClick={PaymentClicked} togglable={true}>{GetField(layout, "account")["label"]}</Button></div>}
                            {!GetSetting(settings, "DisableChequePaymentOption") && <div className="loadTypeDiv"><Button selected={paymentType == "CHEQUE"} onClick={PaymentClicked} togglable={true}>{GetField(layout, "cheque")["label"]}</Button></div>}
                            {!GetSetting(settings, "DisableEftposPaymentOption") && <div className="loadTypeDiv"><Button selected={paymentType == "EFTPOS"} onClick={PaymentClicked} togglable={true}>{GetField(layout, "eftpos")["label"]}</Button></div>}
                            {!GetSetting(settings, "DisableCreditcardPaymentOption") && <div className="loadTypeDiv"><Button selected={paymentType == "CREDITCARD"} onClick={PaymentClicked} togglable={true}>{GetField(layout, "creditCard")["label"]}</Button></div>}
                            {!GetSetting(settings, "DisableVoucherPaymentOption") && <div className="loadTypeDiv"><Button selected={paymentType == "VOUCHER"} onClick={PaymentClicked} togglable={true}>{GetField(layout, "voucher")["label"]}</Button></div>}
                        </div>
                    </div>
                </div>}
                <div style={{ display: "flex" }}>
                    <div className="paymentGap">
                        {(!GetSetting(settings, "DisableINDirectionOption") || !GetSetting(settings, "DisableOUTDirectionOption")) && <Label className="paymentGap">{GetField(layout, "direction")["label"]}</Label>}
                        <div style={{ display: "flex" }}>
                            {!GetSetting(settings, "DisableINDirectionOption") && <div className="loadTypeDiv"><Button selected={direction == "IN"} onClick={DirectionClicked} togglable={true}>IN</Button></div>}
                            {!GetSetting(settings, "DisableOUTDirectionOption") && <div className="loadTypeDiv"><Button selected={direction == "OUT"} onClick={DirectionClicked} togglable={true}>OUT</Button></div>}
                        </div>
                    </div>
                    <div className="paymentGap">
                        {(!GetSetting(settings, "DisableLocalRateOption") || !GetSetting(settings, "DisableVisitorRateOption")) && <Label className="paymentGap">{GetField(layout, "chargeRate")["label"]}</Label>}
                        <div style={{ display: "flex" }}>
                            {!GetSetting(settings, "DisableLocalRateOption") && <div className="loadTypeDiv"><Button selected={chargeRate == "LOCAL"} onClick={RateClicked} togglable={true}>Local</Button></div>}
                            {!GetSetting(settings, "DisableVisitorRateOption") && <div className="loadTypeDiv"><Button selected={chargeRate == "VISITOR"} onClick={RateClicked} togglable={true}>Visitor</Button></div>}
                        </div>
                    </div>
                    <div className="paymentGap">
                        {(!GetSetting(settings, "DisablePrivateVehicleOwnerOption") || !GetSetting(settings, "DisableSiteVehicleOwnerOption")) && <Label className="paymentGap">{GetField(layout, "vehicleOwner")["label"]}</Label>}
                        <div style={{ display: "flex" }}>
                            {!GetSetting(settings, "DisablePrivateVehicleOwnerOption") && <div className="loadTypeDiv"><Button selected={vehicleOwner == "PRIVATE"} onClick={OwnerClicked} togglable={true}>Private</Button></div>}
                            {!GetSetting(settings, "DisableSiteVehicleOwnerOption") && <div className="loadTypeDiv"><Button selected={vehicleOwner == "SITE"} onClick={OwnerClicked} togglable={true}>Site</Button></div>}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    {showDelivery && !GetSetting(settings, "DisableCartageInput") && <div className="paymentFeeDiv">
                        <Label className="paymentGap">{GetField(layout, "delivery")["label"]}</Label>
                        <NumericTextBox format="c2" width={120} onChange={DeliveryChanged}></NumericTextBox>
                    </div>}                    
                    {showPenalty && !GetSetting(settings, "DisablePenaltyInput") && <div className="paymentFeeDiv">
                        <Label className="paymentGap">{GetField(layout, "fee")["label"]}</Label>
                        <NumericTextBox format="c2" width={120} onChange={PenaltyChanged}></NumericTextBox>
                    </div>}
                </div>
                {showComments && <div style={{ maxWidth: "550px" }}>
                    <Label className="paymentGap">{GetField(layout, "comment")["label"]}</Label>
                    <TextArea onChange={CommentChanged}></TextArea>
                </div>}
            </div>
            <div id="PreviewButtons">
                <Button className="bigButton redButton" onClick={ClosePreview}>{GetField(layout, "back")["label"]}</Button>
                <Button className="bigButton" onClick={PrintDocket} disabled={!enablePrint}>{printButton}</Button>
            </div>
        </div>
    );
}