import { GetSetting, GetWarningText } from "./Helpers";
import configData from "../config.json";
import { GetUserId } from '../components/Session';

export const CreateNewTransaction = (settings) => {
    let dir = "IN";
    if (settings !== undefined && GetSetting(settings, "DisableINDirectionOption") && !GetSetting(settings, "DisableOUTDirectionOption")) dir = "OUT";
    let rate = "LOCAL";
    if (settings !== undefined &&  GetSetting(settings, "DisableLocalRateOption") && !GetSetting(settings, "DisableVisitorRateOption")) dir = "VISITOR";
    let owner = "PRIVATE";
    if (settings !== undefined &&  GetSetting(settings, "DisablePrivateVehicleOwnerOption") && !GetSetting(settings, "DisableSiteVehicleOwnerOption")) dir = "SITE";
    return {
        "transactionId": 0,
        "docket": "",
        "transactionDate": new Date(),
        "tareInDate": null,
        "loadType": "",
        "payments": "",
        "direction": dir,
        "vehicleOwner": owner,
        "chargeRate": rate,
        "orderNumber": "",
        "comment": "",
        "registration1": "",
        "registration2": "",
        "registration3": "",
        "gross1": 0,
        "gross2": 0,
        "gross3": 0,
        "gross4": 0,
        "gross5": 0,
        "gross": 0,
        "tare1": 0,
        "tare2": 0,
        "tare3": 0,
        "tare4": 0,
        "tare5": 0,
        "tare": 0,
        "net": 0,
        "count": 0,
        "royalty": 0,
        "productRoyalty": 0,
        "price": 0,
        "ePARate": 0,
        "tranCost": 0,
        "totalCost": 0,
        "cartageCharge": 0,
        "cartageCost": 0,
        "gST": 0,
        "rPA": 0,
        "minimumCharge": 0,
        "haulage": 0,
        "deliveryAddress": "",
        "cartageGST": 0,
        "customerDiscount": 0,
        "siteId": configData.SITE,
        "userId": parseInt(GetUserId()),
        "productCategoryId": 0,
        "productId": 0,
        "customerId": 0,
        "destinationId": 0,
        "sourceId": 0,
        "jobId": 0,
        "truckId": 1,
        "vehicleId": 1,
        "driverId": 1,
        "driverEmail": "",
        "vehicleConfigurationId": 0,
        "markId": 1,
        "dateStamp": "",
        "exportedToAccounting": false,
        "isManualEntry": false,
        "vinNumber": "",
        "maxLoad": 0,
        "maxLoad1": 0,
        "maxLoad2": 0,
        "maxLoad3": 0,
        "maxLoad4": 0,
        "maxLoad5": 0,
        "maxLoadEntered": 0,
        "overloadWeight": 0,
        "loadStatus": "",
        "maxLoadWarnings": 0,
        "overLoadFee": 0,
        "overLoadCost": 0,
        "subStream": "",
        "destinationPurpose": "",
        "visitorType": "",
        "visitorName": "",
        "visitorOrg": "",
        "visitorPhone": "",
        "visitorReason": "",
        "penaltyCharge": 0,
        "penaltyGST": 0,
        "eftposCharge": 0,
        "creditCharge": 0,
        "fleet": "",
        "deliverySuburb": "",
        "deliveryState": "",
        "deliveryPostcode": "",
        "deliveryPhone": "",
        "deliveryContact": "",
        "estimatedWeight": 0,
        "subtotalCost": 0,
        "subtotalGST": 0,
        "subtotalSurcharge": 0,
        "subtotalAmountDue": 0,
        "productStockLevelSnapshot": "",
        "voucher": "",
        "discounts": 0,
        "freeWeight": 0,
        "subTotal": 0,
        "totalFees": 0,
        "orderNumberRequired": false,
        "orderNumberRequiredPerTxn": false,
        "selectedMixedProducts": [],
        "trailersConfirmed": 0, 
        "tonnageConfirmed": 0,
        "hasFirstWeigh": false,
        "stateEvent": null,
        "suppressScanning": false
    }
}

export const GetTransactionData = (trans, site, settings, online) => {
    let over = (trans.gross - trans.maxLoad).toFixed(2);
    let allowance = GetSetting(settings, "MaxLoadExceedAllowance");
    let absMax = (1 + allowance / 100) * trans.maxLoad;
    let warning = (trans.maxLoad > 0 && trans.gross > trans.maxLoad + GetSetting(settings, "OverloadWarningAllowance")) && trans.gross1 < absMax;
    let docket = trans.docket;
    if (!online) {
        let d = String(trans.docket);
        if (d.length < 8) d = "00000000" + d;
        if (d.length > 8) d = d.substring(d.length - 8);
        docket = GetSetting(settings, "SiteDocketPrefix") + d;
    }
    return {
        "transactionId": trans.transactionId,
        "docket": docket,
        "transactionDate": trans.transactionDate.toISOString(),
        "tareInDate": trans.tareInDate === null || trans.tareInDate === undefined ? null : trans.tareInDate.toISOString(),
        "loadType": GetLoadTypeName(trans.loadType),
        "payments": GetSetting(settings, "HidePayment") ? "" : trans.payments,
        "direction": trans.direction,
        "vehicleOwner": trans.vehicleOwner,
        "chargeRate": trans.chargeRate,
        "orderNumber": trans.orderNumber,
        "comment": trans.comment,
        "registration1": trans.registration1,
        "registration2": trans.registration2,
        "registration3": trans.registration3,
        "gross1": trans.gross1,
        "gross2": trans.gross2,
        "gross3": trans.gross3,
        "gross4": trans.gross4,
        "gross5": trans.gross5,
        "gross": trans.gross,
        "tare1": trans.tare1,
        "tare2": trans.tare2,
        "tare3": trans.tare3,
        "tare4": trans.tare4,
        "tare5": trans.tare5,
        "tare": trans.tare,
        "net": trans.net,
        "count": trans.count,
        "royalty": trans.royalty,
        "productRoyalty": trans.productRoyalty,
        "price": trans.price,
        "ePARate": trans.ePARate,
        "tranCost": trans.tranCost,
        "totalCost": trans.totalCost,
        "cartageCharge": trans.cartageCharge,
        "cartageCost": trans.cartageCost,
        "gST": trans.gst,
        "ePA": trans.epa,
        "minimumCharge": trans.minimumCharge,
        "haulage": trans.haulage,
        "deliveryAddress": trans.deliveryAddress,
        "cartageGST": trans.cartageGST, 
        "customerDiscount": trans.customerDiscount,
        "siteId": site.siteId,
        "code": site.code,
        "name": site.name,
        "address1": site.address1,
        "address2": site.address2,
        "suburb": site.suburb,
        "state": site.state,
        "postcode": site.postcode,
        "phone": site.phone,
        "aBN": site.abn,
        "mobile": site.mobile,
        "contact": site.contact,
        "email": site.email,
        "eFTPOSRate": site.eftposRate,
        "creditRate": site.creditRate,
        "weighbridgeType": site.weighbridgeType,
        "isEPALicenced": site.isEPALicenced,
        "userId": trans.userId,
        "userName": "",
        "userDescription": "",
        "userPosition": "",
        "userFullName": "",
        "productCategoryId": 1,
        "productCategoryName": "NA",
        "productId": trans.productId === 0 ? 1 : trans.productId,
        "productName": "NA",
        "productCode": "",
        "productDescription": "",
        "productType": "",
        "productDirection": "",
        "customerId": trans.customerId === 0 ? 1 :trans.customerId,
        "customerName": "NA",
        "destinationId": trans.destinationId === 0 ? 1 : trans.destinationId,
        "destinationName": "NA",
        "sourceId": trans.sourceId === 0 ? 1 : trans.sourceId,
        "sourceName": "NA",
        "jobId": trans.jobId === 0 ? 1 : trans.jobId,
        "jobName": "NA",
        "truckId": trans.truckId,
        "truckName": "NA",
        "vehicleId": trans.vehicleId === 0 ? 1 : trans.vehicleId,
        "vehicleName": "NA",
        "driverId": trans.driverId,
        "driverName": "NA",
        "driverEmail": "",
        "vehicleConfigurationId": trans.vehicleConfigurationId === 0 ? 1 : trans.vehicleConfigurationId,
        "vehicleConfigurationName": "NA",
        "markId": trans.markId,
        "markName": "NA",
        "dateStamp": trans.dateStamp,
        "exportedToAccounting": trans.exportedToAccounting,
        "isManualEntry": trans.isManualEntry,
        "vINNumber": trans.vinNumber,
        "maxLoad": trans.maxLoad,
        "maxLoad1": trans.maxLoad1,
        "maxLoad2": trans.maxLoad2,
        "maxLoad3": trans.maxLoad3,
        "maxLoad4": trans.maxLoad4,
        "maxLoad5": trans.maxLoad5,
        "maxLoadEntered": trans.maxLoadEntered,
        "overloadWeight": trans.overloadWeight,
        "loadStatus": trans.loadStatus,
        "maxLoadWarnings": trans.maxLoadWarnings,
        "overLoadFee": trans.overLoadFee,
        "overLoadCost": trans.overLoadCost,
        "subStream": trans.subStream,
        "destinationPurpose": trans.destinationPurpose,
        "visitorType": trans.visitorType,
        "visitorName": trans.visitorName,
        "visitorOrg": trans.visitorOrg,
        "visitorPhone": trans.visitorPhone,
        "penaltyCharge": trans.penaltyCharge,
        "penaltyGST": trans.penaltyGST,
        "eftposCharge": trans.eftposCharge,
        "creditCharge": trans.creditCharge,
        "fleet": trans.fleet,
        "deliverySuburb": trans.deliverySuburb,
        "deliveryState": trans.deliveryState,
        "deliveryPostcode": trans.deliveryPostcode,
        "deliveryPhone": trans.deliveryPhone,
        "deliveryContact": trans.deliveryContact,
        "estimatedWeight": trans.estimatedWeight,
        "myobSynced": false,
        "productStockLevelSnapshot": trans.productStockLevelSnapshot,
        "selectedMixedProducts": null,
        "subtotalCost": trans.subTotal,
        "subtotalGST": trans.gst,
        "subtotalSurcharge": trans.totalFees,
        "subtotalAmountDue": trans.subTotal - trans.discounts,
        "isOverload": trans.maxLoad > 0 && trans.gross1 > trans.maxLoad,
        "isOverPercent": trans.maxLoad > 0 && trans.gross1 > absMax,
        "isWarning": warning,
        "overload": over,
        "warningNumber": GetWarningText(trans.maxLoadWarnings),
        "voucher": trans.voucher,
        "discounts": trans.discounts,
        "freeWeight": trans.freeWeight,
        "subTotal": trans.subTotal,
        "totalFees": trans.totalFees,
        "eventType": 6,
        "eventLogError": "",
        "eventLogDescription": "",
        "selectedMixedProducts": trans.selectedMixedProducts,
        "registeredPublicWeighbridgeNo": site.registeredPublicWeighbridgeNo
    }
}

export const GetTransactionFromFirstWeigh = (f, settings) => {
    let n = CreateNewTransaction(settings);
    n.hasFirstWeigh = true;
    n.transactionDate = new Date();
    n.tareInDate = new Date(f.tareInDate);
    n.loadType = f.loadType;
    n.payments = f.payments;
    n.direction = String(f.direction).toUpperCase();
    n.vehicleOwner = String(f.vehicleOwner).toUpperCase();
    n.chargeRate = String(f.chargeRate).toUpperCase();
    n.orderNumber = f.orderNumber;
    n.comment = f.comment;
    n.registration1 = f.name;
    n.registration2 = f.registration2;
    n.registration3 = f.registration3;
    n.tare1 = f.tare1;
    n.tare2 = f.tare2;
    n.tare3 = f.tare3;
    n.tare4 = f.tare4;
    n.tare5 = f.tare5;
    n.tare = f.tare;
    n.haulage = f.haulage;
    n.deliveryAddress = f.deliveryAddress;
    n.siteId = f.siteId;
    n.userId = f.userId;
    n.productCategoryId = f.productCategoryId;
    n.productId = f.productId;
    n.customerId = f.customerId;
    n.destinationId = f.destinationId;
    n.sourceId = f.sourceId;
    n.jobId = f.jobId;
    n.truckId = 1;
    n.vehicleId = 1;
    n.driverId = f.driverId;
    n.markId = 1;
    n.maxLoad = f.maxLoad;
    n.maxLoadEntered = f.maxLoad;
    n.maxLoadWarnings = f.maxLoadWarnings;
    n.subStream = f.subStream;
    n.destinationPurpose = f.destinationPurpose;
    n.visitorType = f.visitorType;
    n.visitorName = f.visitorName;
    n.visitorOrg = f.visitorOrg;
    n.visitorPhone = f.visitorPhone;
    n.penaltyCharge = f.penaltyCharge;
    n.suppressScanning = true;
    return n;
}

export const CreateTransactionFromTruck = (t, settings) => {
    let n = CreateNewTransaction(settings);
    n.transactionDate = new Date();
    n.loadType = "SECOND";
    n.direction = String(t.inOrOut).toUpperCase();
    n.chargeRate = String(t.chargeRate).toUpperCase();
    n.registration1 = t.name;
    n.registration2 = t.registration2;
    n.registration3 = t.registration3;
    if (t.tare1 > 0 && String(t.loadType).toLocaleUpperCase() === "STOREDTARE") {
        n.tare1 = t.tare1;
        n.tare2 = t.tare2;
        n.tare3 = t.tare3;
        n.tare4 = t.tare4;
        n.tare5 = t.tare5;
        n.tare = t.tare;
    }
    n.userId = parseInt(GetUserId());
    n.truckId = t.truckId;
    n.vehicleId = t.vehicleId;
    n.driverId = t.driverId;
    n.markId = t.markId;
    n.maxLoad = t.maxLoad;
    n.maxLoad1 = t.maxLoad1;
    n.maxLoad2 = t.maxLoad2;
    n.maxLoad3 = t.maxLoad3;
    n.maxLoad4 = t.maxLoad4;
    n.maxLoad5 = t.maxLoad5;
    n.maxLoadWarnings = t.maxLoadWarnings;
    n.suppressScanning = true;
    return n;
}

export const GetLoadTypeName = (t) => {
    switch (String(t).toUpperCase()) {
        case "STANDARD": return "Standard";
        case "COUNTED":
        case "M3":
            return "Counted";
        case "STOREDTARE":
        case "STORED":
            return "StoredTare";
        case "FIRST": return "First";
        case "SECOND": return "Second";
        case "REGO": return "Rego";
        case "MIXED": return "Mixed";
        case "VISITOR": return "Visitor";
        case "SIMPLE": return "Simple";
    }
    return "";
}

export const CreateNewProduct = () => {
    return {
        "productId": 0,
        "name": "",
        "currentAmount": 0,
        "price": 0,
        "ePA": false,
        "gST": 0,
        "cartage": 0,
        "tranCost": 0,
        "cartageGST": 0,
        "totalCost": 0,
        "customerDiscount": 0,
        "overLoadFee": 0,
        "overLoadCharge": 0,
        "penalty": 0,
        "penaltyGST": 0,
        "cartageCost": 0,
        "amount": 0,
        "minimumCharge": 0,
        "penalty": 0,
        "penaltyGST": 0,
        "eFTPOSCharge": 0,
        "cREDITCharge": 0,
        "siteCreated": false,
        "inLocalDiscount": 0,
        "inLocalDiscountGST": false,
        "outLocalDiscount": 0,
        "outLocalDiscountGST": false,
        "minLocalDiscount": 0,
        "inVisitStandard": 0,
        "inVisitStandardGST": false,
        "outVisitStandard": 0,
        "outVisitStandardGST": false,
        "minVisitStandard": 0,
        "ePALevy": 0,
        "minEPALevy": 0,
        "royalty": 0,
        "minWeight": 0,
        "report_To_EPA": false,
        "toVolumeFactor": 0,
        "inLocalDiscount_S": 0,
        "outLocalDiscount_S": 0,
        "minLocalDiscount_S": 0,
        "inVisitStandard_S": 0,
        "outVisitStandard_S": 0,
        "minVisitStandard_S": 0,
        "ePALevy_S": 0,
        "minEPALevy_S": 0,
        "productCategoryId": 0,
        "sourceId": 0,
        "destinationId": 0,
        "liftBoomGate": false,
        "showStockLevelOnDocket": false,
        "configName": ""
    }
}