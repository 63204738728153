import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDatePicker, FormDropDownList, FormInput, FormSwitch, FormNumericTextBox } from "../components/formComponents";
import { Button } from "@progress/kendo-react-buttons";
import { GetPermissions } from '../components/Options';

const EditVouchers = () => {
    const { id } = useParams(); // editusers/id url is used to edit existing user, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [permissions, setPermissions] = React.useState(null);
    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "voucherId", "label": "Voucher Id", "defaultValue": "", "isVisible": true, "type": "numeric" },
        { "field": "code", "label": "Voucher Code", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "quantity", "label": "Quantity", "defaultValue": 1, "isVisible": true, "type": "numeric" },
        { "field": "startDate", "label": "Active From", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "endDate", "label": "Active Until", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "discountType", "label": "Discount Type", "defaultValue": { name: "Price%", value: "Price Percent Discount" }, "isVisible": true, "type": "list" },
        { "field": "discountAmount", "label": "Discount Amount", "defaultValue": "10", "isVisible": true, "type": "text" },
        { "field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean" },
        { "field": "totalUses", "label": "Total Uses", "defaultValue": "", "isVisible": true, "type": "numeric" },
        { "field": "usesAllowed", "label": "Uses Allowed", "defaultValue": "1", "isVisible": true, "type": "numeric" }
    ]);

    const voucherTypes = [{ name: "Weight", value: "Free Weight (t)" }, { name: "Weight%", value: "Weight Percentage Off" }, { name: "Price$", value: "Price Discount ($)" }, { name: "Price%", value: "Price Percent Discount" }];

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        voucherId: GetField("voucherId")["defaultValue"],
        code: GetField("code")["defaultValue"],
        startDate: new Date(),
        endDate: new Date(),
        quantity: 1,
        discountType: GetField("discountType")["defaultValue"],
        discountAmount: GetField("code")["defaultValue"],
        usesAllowed: GetField("usesAllowed")["defaultValue"],
        totalUses: GetField("totalUses")["defaultValue"],
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
    });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vouchers
    React.useEffect(() => {
        GetPermissions(["ViewVouchers", "NewVouchers", "EditVouchers", "EnableVouchers"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditVouchers'";
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile) {
                let data = [];
                for (let i = 0; i < d.length; i++) {
                    data.push({ field: d[i].field, label: d[i].label, type: d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile });
                }
                setLayout(data);
            } else {
                setLayout(d);
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {
            if (id !== undefined) {
                url = `${configData.SERVER_URL}apiv7/vouchers/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    var sd = d.startDate === null ? new Date() : new Date(d.startDate);
                    var ed = d.endDate === null ? new Date(sd.getFullYear() + 1, sd.getMonth(), sd.getDate()) : new Date(d.endDate);
                    var dt = voucherTypes.find(obj => { return obj.name === d.discountType });
                    setValues({
                        voucherId: d.voucherId === null ? "" : 1,
                        code: d.code === null ? "" : d.code,
                        quantity: 1,
                        startDate: sd,
                        endDate: ed,
                        discountType: dt,
                        discountAmount: d.discountAmount,
                        usesAllowed: d.usesAllowed,
                        totalUses: d.totalUses,
                        active: d.isActive === null ? "" : d.isActive
                    });
                    setFormkey(formkey + 1);
                })
            } 
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (f === undefined) f = "";
        if (fld === undefined || fld === null) return f;
        let res = fld["defaultValue"];
        if (fld["isVisible"]) res = f;
        switch (fld["type"]) {
            case "numeric":
                res = parseFloat(res);
                if (isNaN(res)) res = 0;
                break;
            case "boolean": res = (res === "true" || res === true); break;
            case "date":
                if (res === "") res = null;
                break;
            default: break;
        }
        return res;
    }


    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/vouchers${pth}`;
        const init = {
            method: id === undefined ? 'POST' : 'PUT',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "voucherId": id === undefined ? 0 : id,
                "code": GetDefaultData(dataItem.code, "code"),
                "quantity": GetDefaultData(dataItem.quantity, "quantity"),
                "startDate": GetDefaultData(dataItem.startDate, "startDate"),
                "endDate": GetDefaultData(dataItem.endDate, "endDate"),
                "discountType": GetDefaultData(dataItem.discountType, "discountType").name,
                "discountAmount": GetDefaultData(dataItem.discountAmount, "discountAmount"),
                "usesAllowed": GetDefaultData(dataItem.usesAllowed, "discountAmount"),
                "barcode": "",
                "isActive": GetDefaultData(dataItem.active, "active")
            })
        }
        fetch(url, init).then(response => response).then(d => {
            var msg = id === undefined ? "Voucher created" : "Voucher updated";
            if (d.status > 299) msg = `Error ${d.status} creating Voucher`;
            Redirect(msg);
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/vouchers", { state: { msg: msg } });
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const textValidator = (value) => !value ? "Please enter a value" : "";
    const optionValidator = (value) => value ? "" : "Please select an option";
    return (
        <div style={{ padding: 20 }}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{ width: "95%" }} >
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} disabled={permissions && !permissions.EnableVouchers} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("code")["isVisible"] && <div className="col-md-3">
                                <Field id={"code"} name={"code"} label={GetField("code")["label"]} component={FormInput} validator={textValidator} />
                            </div>}
                            {GetField("quantity")["isVisible"] && id === undefined && <div className="col-md-3">
                                <Field id={"quantity"} name={"quantity"} label={GetField("quantity")["label"]} min="1" max="100" defaultValue="1" component={FormNumericTextBox} />
                            </div>}
                            {GetField("quantity")["isVisible"] && id === undefined && <div className="col-md-3" style={{paddingTop: "40px"} }>
                                Note: If multiple barcodes are created, the voucher code will be the prefix. 
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("startDate")["isVisible"] && <div className="col-md-3">
                                <Field id={"startDate"} name={"startDate"} label={GetField("startDate")["label"]} component={FormDatePicker}
                                    wrapperStyle={{ width: "90%", marginRight: "18px", }} />
                            </div>}
                            {GetField("endDate")["isVisible"] && <div className="col-md-3">
                                <Field id={"endDate"} name={"endDate"} label={GetField("endDate")["label"]} component={FormDatePicker}
                                    wrapperStyle={{ width: "90%", marginRight: "18px", }} />
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("discountType")["isVisible"] && <div className="col-md-3">
                                <Field id={"discountType"} name={"discountType"} label={GetField("discountType")["label"]} component={FormDropDownList} textField="value" data={voucherTypes} validator={optionValidator} />
                            </div>}
                            {GetField("code")["isVisible"] && <div className="col-md-3">
                                <Field id={"discountAmount"} name={"discountAmount"} label={GetField("discountAmount")["label"]} defaultValue="10" component={FormNumericTextBox} validator={textValidator} />
                            </div>}
                            {GetField("code")["isVisible"] && <div className="col-md-6" style={{paddingTop: "10px"} }>
                                For Free Weight this is Number of tonnes free<br />
                                For Weight % off this is percentage subtracted from weight<br/>
                                For Price $ discount this is the number of dollars off<br />
                                For Price % discount this is the discount percentage
                            </div>}
                        </div>
                        <div className="row">
                            {GetField("usesAllowed")["isVisible"] && <div className="col-md-3">
                                <Field id={"usesAllowed"} name={"usesAllowed"} label={GetField("usesAllowed")["label"]} min="1" max="10000" component={FormNumericTextBox} validator={textValidator} />
                            </div>}
                            {GetField("totalUses")["isVisible"] && <div className="col-md-3">
                                <Field id={"totalUses"} name={"totalUses"} label={GetField("totalUses")["label"]} disabled component={FormNumericTextBox} />
                            </div>}
                        </div>
                    </fieldset>
                    <div className="k-form-buttons" style={{ paddingTop: 10 }}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Cancel</Button>
                        </div>
                    </div>
                </FormElement>
            )} />
        </div>
    );
}

export default EditVouchers