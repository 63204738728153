import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormTextArea, FormNumericTextBox } from "../components/formComponents";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GetPermissions } from '../components/Options';
import $ from "jquery";
import { Label } from '@progress/kendo-react-labels';
import { CheckLoadAgainstTare } from '../WeighBridge/Helpers';

const EditSites = () => {
    const { id } = useParams(); // offences/id url is used to edit existing offense, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [permissions, setPermissions] = React.useState(null);
    const [sites, setSites] = React.useState();
    const [site, setSite] = React.useState();
    const [copySettings, setCopySettings] = React.useState(false);
    const [copySync, setCopySync] = React.useState(false);
    const [copyDockets, setCopyDockets] = React.useState(false);
    const handleTabSelect = (e) => {setSelectedTab(e.selected); };

    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "name", "label": "Name", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "code", "label": "Code", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "description", "label": "Comment", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "currentDocket", "label": "Current Docket", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "docketHead", "label": "Docket Head", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "address1", "label": "Organisation", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "address2", "label": "Address", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "suburb", "label": "Suburb", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "state", "label": "State", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "postcode", "label": "Postcode", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "country", "label": "Country", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "phone", "label": "Phone", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "fax", "label": "Fax", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "mobile", "label": "Mobile", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "contact", "label": "Contact", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "email", "label": "Email", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "installed", "label": "Installed", "defaultValue": "", "isVisible": false, "type": "text"},
        { "field": "certificate", "label": "Certificate", "defaultValue": "", "isVisible": false, "type": "text"},
        { "field": "siteCount", "label": "SiteCount", "defaultValue": "", "isVisible": false, "type": "text"},
        { "field": "isProductPricesIncludingGST", "label": "IsProductPricesIncludingGST", "defaultValue": "false", "isVisible": false, "type": "boolean"},
        { "field": "isVehicleTypePricesIncludingGST", "label": "IsVehicleTypePricesIncludingGST", "defaultValue": "false", "isVisible": false, "type": "boolean"},
        { "field": "isJobCartagePricesIncludingGST", "label": "IsJobCartagePricesIncludingGST", "defaultValue": "false", "isVisible": false, "type": "boolean"},
        { "field": "noOfUsersAllowed", "label": "NoOfUsersAllowed", "defaultValue": "0", "isVisible": false, "type": "numeric"},
        { "field": "chargeOverLoad", "label": "ChargeOverLoad", "defaultValue": "false", "isVisible": false, "type": "boolean"},
        { "field": "isJobAdditionalFeeEnabled", "label": "IsJobAdditionalFeeEnabled", "defaultValue": "false", "isVisible": false, "type": "boolean"},
        { "field": "hideProductVisitCharges", "label": "HideProductVisitCharges", "defaultValue": "false", "isVisible": false, "type": "boolean"},
        { "field": "facilityEPLNumber", "label": "Facility EPL Number", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "isEPALicenced", "label": "EPA Licensed", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "isExternalCustomerEnabled", "label": "IsExternalCustomerEnabled", "defaultValue": "false", "isVisible": false, "type": "boolean"},
        { "field": "abn", "label": "ABN", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "hidePriceForAccountPayment", "label": "Hide Price For Acc Payment", "defaultValue": false, "isVisible": true, "type": "boolean"},
        { "field": "canAccessBI", "label": "CanAccessBI", "defaultValue": "false", "isVisible": false, "type": "boolean"},
        { "field": "showVehicleConfigImagetoAdd", "label": "ShowVehicleConfigImagetoAdd", "defaultValue": "false", "isVisible": false, "type": "boolean"},
        { "field": "eftposRate", "label": "EFTPOS Surcharge (%)", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "creditRate", "label": "CreditRate", "defaultValue": 0, "isVisible": true, "type": "numeric"},
        { "field": "fixedPlateNumbers", "label": "Fixed Plate Number", "defaultValue": "", "isVisible": true, "type": "text"},
        { "field": "weighbridgeType", "label": "WeighbridgeType", "defaultValue": "0", "isVisible": false, "type": "numeric"},
        { "field": "active", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean"},
        { "field": "siteDetails", "label": "Site", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "addressDetails", "label": "Address Details", "defaultValue": "", "isVisible": true, "type": "heading"},
        { "field": "registeredPublicWeighbridgeNo", "label": "Registered WB No", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "contactAndOther", "label": "Contact and other settings", "defaultValue": "", "isVisible": true, "type": "heading" },
        { "field": "copySettings", "label": "Settings", "defaultValue": "true", "isVisible": true, "type": "boolean" },
        { "field": "copySync", "label": "Sync Settings", "defaultValue": "true", "isVisible": true, "type": "boolean" },
        { "field": "copyDockets", "label": "Docket Templates", "defaultValue": "true", "isVisible": true, "type": "boolean" }
    ]);

    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        name: GetField("name")["defaultValue"],
        code: GetField("code")["defaultValue"],
        description: GetField("description")["defaultValue"],
        currentDocket: GetField("currentDocket")["defaultValue"],
        docketHead: GetField("docketHead")["defaultValue"],
        address1: GetField("address1")["defaultValue"],
        address2: GetField("address2")["defaultValue"],
        suburb: GetField("suburb")["defaultValue"],
        state: GetField("state")["defaultValue"],
        postcode: GetField("postcode")["defaultValue"],
        country: GetField("country")["defaultValue"],
        phone: GetField("phone")["defaultValue"],
        fax: GetField("fax")["defaultValue"],
        mobile: GetField("mobile")["defaultValue"],
        contact: GetField("contact")["defaultValue"],
        email: GetField("email")["defaultValue"],
        facilityEPLNumber: GetField("facilityEPLNumber")["defaultValue"],
        isEPALicenced: GetField("isEPALicenced")["defaultValue"] === "true",
        abn: GetField("abn")["defaultValue"],
        hidePriceForAccountPayment: GetField("hidePriceForAccountPayment")["defaultValue"],
        eftposRate: GetField("eftposRate")["defaultValue"],
        fixedPlateNumbers: GetField("fixedPlateNumbers")["defaultValue"],
        registeredPublicWeighbridgeNo: GetField("registeredPublicWeighbridgeNo")["defaultValue"],
        active: String(GetField("active")["defaultValue"]).toLowerCase() === "true"
      });


    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/sites
    React.useEffect(() => {
        GetPermissions(["ViewSite", "NewSite", "EditSite", "isAWSSupportRole"], setPermissions)
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditSites'";
        const init = {
          method: 'GET',
          accept: 'application/json',
          headers: {"Authorization": "Bearer " + GetToken()}
        }
        fetch(url, init).then(response => response.json()).then(d => { 
            if (GetSession().IsMobile){
                let data = [];
                for(let i = 0; i < d.length; i++){
                    data.push({field: d[i].field, label: d[i].label, type:d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile});
                }
                setLayout(data);
            } else {
                setLayout(d); 
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(d => {
            setSites(CreateList(d.sites));
        });
        if (id !== undefined){
            url = `${configData.SERVER_URL}apiv7/sites/${id}`;
            fetch(url, init).then(response => response.json()).then(d => {
                setValues({
                    name: d.name === null ? "" : d.name,
                    code: d.code === null ? "" : d.code,
                    description: d.description === null ? "" : d.description,
                    currentDocket: d.currentDocket === null ? "" : d.currentDocket,
                    docketHead: d.docketHead === null ? "" : d.docketHead,
                    address1: d.address1 === null ? "" : d.address1,
                    address2: d.address2 === null ? "" : d.address2,
                    suburb: d.suburb === null ? "" : d.suburb, 
                    state: d.state === null ? "" : d.state,
                    postcode: d.postcode === null ? "" : d.postcode,
                    country: d.country === null ? "" : d.country,
                    phone: d.phone === null ? "" : d.phone,
                    fax: d.fax === null ? "" : d.fax,
                    mobile: d.mobile === null ? "" : d.mobile,
                    contact: d.contact === null ? "" : d.contact,
                    email: d.email === null ? "" : d.email,
                    facilityEPLNumber: d.facilityEPLNumber === null ? "" : d.facilityEPLNumber,
                    isEPALicenced: d.isEPALicenced,
                    abn: d.abn === null ? "" : d.abn,
                    hidePriceForAccountPayment: d.hidePriceForAccountPayment,
                    eftposRate: d.eftposRate,
                    creditRate: d.creditRate,
                    fixedPlateNumbers: d.fixedPlateNumbers === null ? "" : d.fixedPlateNumbers,
                    registeredPublicWeighbridgeNo: d.registeredPublicWeighbridgeNo,
                    active: d.isActive
                });
                setFormkey(formkey + 1);
            })
        }
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (fld === undefined || fld === null) return f;
        if (fld["isVisible"]) return f;
        let res = "";
        switch(fld["type"]){
            case "text": res = fld["defaultValue"]; break;
            case "numeric": res = parseFloat(fld["defaultValue"]); break;
            case "boolean": res = fld["defaultValue"] === "true"; break;
            default: res = fld["defaultValue"]; break;
        }
        return res;
    }

    const CreateList = (d) => {
        var x = [{ id: "1", name: "Cloud (1)" }];
        d.map((v) => x.push({ id: v.id, name: v.name + " (" + v.id + ")" }));
        return x;
    };

    const ImportV6 = () => {
        if (id === undefined) return;
        var url = `${configData.SERVER_URL}apiv7/private/ImportV6Settings`;
        var x = $("#V6Settings").val();
        var a = $("#V6AppSettings").val();
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
                "Content-Type": "application/json", 
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "siteId": parseInt(id),
                "xml": x,
                "appSettings": a

            })
        }
        fetch(url, init).then(response => response).then(d => {
            if (d.ok)
                alert("V6 settings imported");
            else
                alert("Error importing file: " + d.statusText);
        });
    }

    const siteChanged = (e) => {
        setSite(e.value);
    }

    const CopySite = () => {
        if (id === undefined) return;
        if (site === undefined) {
            alert("Please select a site");
            return;
        }
        var url = `${configData.SERVER_URL}apiv7/private/CopySiteSettings?siteId=${id}&copyFrom=${site.id}&copySync=${copySync}&copySettings=${copySettings}&copyDockets=${copyDockets}`;
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
        };
        fetch(url, init).then(response => response).then(d => {
            if (d.ok)
                alert("Settings copied");
            else
                alert("Error copying settings: " + d.statusText);
        });
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/sites${pth}`;
        const init = {
          method: id === undefined ? 'POST' : 'PUT',
          accept: "application/json",
          headers: {
            "Content-type": "application/json",
            "Authorization": "Bearer " + GetToken()
          },
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify({
            "siteId": id === undefined ? 0 : id,
            "name": GetDefaultData(dataItem.name, "name"),
            "code": GetDefaultData(dataItem.code, "code"),
            "abn": GetDefaultData(dataItem.abn, "abn"),
            "description":  GetDefaultData(dataItem.description, "description"),           
            "docketHead": GetDefaultData(dataItem.docketHead, "docketHead"),
            "address1": GetDefaultData(dataItem.address1, "address1"),
            "address2": GetDefaultData(dataItem.address2, "address2"),
            "suburb": GetDefaultData(dataItem.suburb, "suburb"),
            "state": GetDefaultData(dataItem.state, "state"),
            "postcode": GetDefaultData(dataItem.postcode, "postcode"),
            "country": GetDefaultData(dataItem.country, "country"),
            "phone": GetDefaultData(dataItem.phone, "phone"),
            "fax": GetDefaultData(dataItem.fax, "fax"),
            "contact": GetDefaultData(dataItem.contact, "contact"),
            "mobile": GetDefaultData(dataItem.mobile, "mobile"),
            "email": GetDefaultData(dataItem.email, "email"),
            "facilityEPLNumber": GetDefaultData(dataItem.facilityEPLNumber, "facilityEPLNumber"),
            "isEPALicenced": GetDefaultData(dataItem.isEPALicenced, "isEPALicenced"),
            "hidePriceForAccountPayment": GetDefaultData(dataItem.hidePriceForAccountPayment, "hidePriceForAccountPayment"),
            "eftposRate": GetDefaultData(dataItem.eftposRate, "eftposRate"),
            "creditRate": GetDefaultData(dataItem.creditRate, "creditRate"),
            "fixedPlateNumbers": GetDefaultData(dataItem.fixedPlateNumbers, "fixedPlateNumbers"),
            "registeredPublicWeighbridgeNo": GetDefaultData(dataItem.registeredPublicWeighbridgeNo, "registeredPublicWeighbridgeNo"),
            "isActive": GetDefaultData(dataItem.active, "active")
            })
        }
        fetch(url, init).then(response => response).then(d => {           
            var msg = id === undefined ? "Site created" : "Site updated";
            Redirect(msg);
        });
    }

    // autocomplete for looking up addresses
    // TO-DO: v6 used Google Places API to do this but it was broken anyway.
    
    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
       navigate("/sites", {state: {msg: msg}});
    }
    if (permissions && !permissions.CanView) navigate("/error401");
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const textValidator = (value) =>  value === "" ? "Please enter a value" : "";
    return (
        <div style={{padding: 20}}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{width: "98%"}} >
                    <fieldset className={"k-form-fieldset"}>
                    <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                        {GetField("siteDetails")["isVisible"] && <TabStripTab title={GetField("siteDetails")["label"]}>
                        <div className="row">
                           {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("code")["isVisible"] && <div className="col-md-3">
                                <Field id={"code"} name={"code"} label={GetField("code")["label"]} component={FormInput} validator={textValidator}/>
                            </div>}
                            {GetField("abn")["isVisible"] && <div className="col-md-3">
                                <Field id={"abn"} name={"abn"} label={GetField("abn")["label"]} component={FormInput}/>
                            </div>}
                        </div>
                        </TabStripTab>}
                        {GetField("addressDetails")["isVisible"] && <TabStripTab title={GetField("addressDetails")["label"]}>
                            <div className="row">
                           {GetField("address1")["isVisible"] && <div className="col-md-3">
                                <Field id={"address1"} name={"address1"} label={GetField("address1")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("address2")["isVisible"] && <div className="col-md-3">
                                <Field id={"address2"} name={"address2"} label={GetField("address2")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("suburb")["isVisible"] && <div className="col-md-3">
                                <Field id={"suburb"} name={"suburb"} label={GetField("suburb")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("state")["isVisible"] && <div className="col-md-3">
                                <Field id={"state"} name={"state"} label={GetField("state")["label"]} component={FormInput}/>
                            </div>}
                        </div>
                        <div className="row">
                           {GetField("postcode")["isVisible"] && <div className="col-md-3">
                                <Field id={"postcode"} name={"postcode"} label={GetField("postcode")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("country")["isVisible"] && <div className="col-md-3">
                                <Field id={"country"} name={"country"} label={GetField("country")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("phone")["isVisible"] && <div className="col-md-3">
                                <Field id={"phone"} name={"phone"} label={GetField("phone")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("fax")["isVisible"] && <div className="col-md-3">
                                <Field id={"fax"} name={"fax"} label={GetField("fax")["label"]} component={FormInput}/>
                            </div>}
                        </div>
                        </TabStripTab> }
                        {GetField("contactAndOther")["isVisible"] && <TabStripTab title={GetField("contactAndOther")["label"]}>
                        <div className="row">
                           {GetField("contact")["isVisible"] && <div className="col-md-3">
                                <Field id={"contact"} name={"contact"} label={GetField("contact")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("mobile")["isVisible"] && <div className="col-md-3">
                                <Field id={"mobile"} name={"mobile"} label={GetField("mobile")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("email")["isVisible"] && <div className="col-md-3">
                                <Field id={"email"} name={"email"} label={GetField("email")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("description")["isVisible"] && <div className="col-md-3">
                                <Field id={"description"} name={"description"} label={GetField("description")["label"]} component={FormTextArea}/>
                            </div>}
                        </div>
                        <div className="row">
                           {GetField("docketHead")["isVisible"] && <div className="col-md-3">
                                <Field id={"docketHead"} name={"docketHead"} label={GetField("docketHead")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("currentDocket")["isVisible"] && <div className="col-md-3">
                                <Field id={"currentDocket"} name={"currentDocket"} label={GetField("currentDocket")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("facilityEPLNumber")["isVisible"] && <div className="col-md-3">
                                <Field id={"facilityEPLNumber"} name={"facilityEPLNumber"} label={GetField("facilityEPLNumber")["label"]} component={FormInput}/>
                            </div>}
                            {GetField("fixedPlateNumbers")["isVisible"] && <div className="col-md-3">
                                <Field id={"fixedPlateNumbers"} name={"fixedPlateNumbers"} label={GetField("fixedPlateNumbers")["label"]} component={FormInput}/>
                            </div>}
                        </div>
                        <div className="row">
                           {GetField("eftposRate")["isVisible"] && <div className="col-md-3">
                                <Field id={"eftposRate"} name={"eftposRate"} label={GetField("eftposRate")["label"]} component={FormNumericTextBox} min={0} max={100} validator={textValidator}/>
                            </div>}
                            {GetField("creditRate")["isVisible"] && <div className="col-md-3">
                                <Field id={"creditRate"} name={"creditRate"} label={GetField("creditRate")["label"]} component={FormNumericTextBox} min={0} max={100} validator={textValidator} />
                            </div>}
                            {GetField("registeredPublicWeighbridgeNo")["isVisible"] && <div className="col-md-3">
                                <Field id={"registeredPublicWeighbridgeNo"} name={"registeredPublicWeighbridgeNo"} label={GetField("registeredPublicWeighbridgeNo")["label"]} component={FormInput} />
                            </div>}
                        </div>
                        <div className='row'>
                            {GetField("active")["isVisible"] && <div className='col-md-3'>
                                <Field id={"active"} name={"active"} label={GetField("active")["label"]} component={FormSwitch} />
                            </div>}
                            {GetField("isEPALicenced")["isVisible"] && <div className='col-md-3'>
                                <Field id={"isEPALicenced"} name={"isEPALicenced"} label={GetField("isEPALicenced")["label"]} component={FormSwitch} />
                            </div>}
                            {GetField("hidePriceForAccountPayment")["isVisible"] && <div className='col-md-3'>
                                <Field id={"hidePriceForAccountPayment"} name={"hidePriceForAccountPayment"} label={GetField("hidePriceForAccountPayment")["label"]} component={FormSwitch} />
                            </div>}
                        </div>
                        </TabStripTab>}
                        {id !== undefined && permissions && permissions.isAWSSupportRole && <TabStripTab title="Upload V6 Settings">
                            <div className="row">
                                <div class="col-md-10">
                                    <label>Paste awsconfig.xml here:</label>
                                    <FormTextArea id="V6Settings" cols="120" rows="20"/>
                                </div>
                                <div class="col-md-10">
                                    <label>Paste AppSettings from WeighbridgePOS.WPF.exe.config here:</label>
                                    <FormTextArea id="V6AppSettings" cols="120" rows="10" />
                                </div>
                                <div class="col-md-12" style={{ paddingTop: "25px" }}>
                                    Copy only lines like &lt;add key="..." value="..." /&gt;
                                </div>
                                <div class="col-md-2" style={{ paddingTop: "25px" }}>
                                    <Button onClick={ImportV6}>Import</Button>
                                </div>
                            </div>
                        </TabStripTab>}
                        {id !== undefined && permissions && permissions.isAWSSupportRole && <TabStripTab title="Copy Settings from Site">
                            <div className="row">
                                <div class="col-md-12">
                                    <label>Site</label>
                                    <DropDownList data={sites} value={site} textField="name" dataItemKey="id" onChange={siteChanged} />
                                </div>
                                    <div className='row' style={{marginTop: "20px"} }>
                                    {GetField("copySettings")["isVisible"] && <div className='col-md-3'>
                                            <Checkbox name={"copySettings"} size="large" onChange={(e) => setCopySettings(e.value)} label={GetField("copySettings")["label"]} />
                                    </div>}
                                    {GetField("copySync")["isVisible"] && <div className='col-md-3'>
                                            <Checkbox name={"copySync"} size="large" onChange={(e) => setCopySync(e.value)} label={GetField("copySync")["label"]} />
                                    </div>}
                                        {GetField("copyDockets")["isVisible"] && <div className='col-md-3'>
                                            <Checkbox name={"copyDocket"} size="large" onChange={(e) => setCopyDockets(e.value)} label={GetField("copyDockets")["label"]} />
                                    </div>}
                                </div>
                                <div class="col-md-2" style={{ paddingTop: "25px" }}>
                                    <Button onClick={CopySite}>Copy Settings</Button>
                                </div>
                            </div>
                        </TabStripTab>}
                    </TabStrip>                        
                    </fieldset>
                    <div className="k-form-buttons" style={{paddingTop: 10}}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back to List</Button>
                        </div>
                    </div>                
                </FormElement>
            )} />
        </div>
    );
}

export default EditSites