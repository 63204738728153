import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { Button } from '@progress/kendo-react-buttons';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import Notifications from '../components/Notifications';
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { DropDownList } from "@progress/kendo-react-dropdowns";

const WeighbridgeSettings = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const [sites, setSites] = React.useState([]);
    const [site, setSite] = React.useState();
    const navigate = useNavigate();
    const [lookupParams, setLookupParams] = React.useState(""); 
    const [layout, setLayout] = React.useState([
        { "field": "searchSite", "label": "Site", "isVisible": true, "type": "list" },
    ]);

    if (permissions && !permissions.EditAppSettings) navigate("/error401");
    React.useEffect(() => {
        GetColumnDefs("WeighbridgeSettings", setColumns);
        GetPermissions(["EditAppSettings"], setPermissions);
        let baseUrl = configData.SERVER_URL + "apiv7/options";
        const init = {
            method: "GET",
            accept: "application/json",
            headers: { "Authorization": "Bearer " + GetToken() },
        };
        baseUrl = configData.SERVER_URL + "apiv7/options";
        fetch(baseUrl, init).then((response) => response.json()).then((d) => {

            setSites(CreateList(d.sites));
            setSite({ id: "0", name: "All" });
        });
    }, [])

    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }
    const siteChanged = (event) => {
        setSite(event.target.value);
        if (event.target.value.id == "0")
            setLookupParams("");
        else
            setLookupParams(event.target.value.id);
    }
    const CreateList = (d) => {
        var x = [{ id: "0", name: "All" }, {id: "1", name: "Cloud (1)"}];
        d.map((v) => x.push({ id: v.id, name: v.name + " (" + v.id + ")" }));
        return x;
    };

    const SettingsCommand = (item, ev) => {
        var url = `${configData.SERVER_URL}apiv7/settings?id=${item.settingsId}&value=${ev.value}`;
        const init = {
            method: 'PATCH',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
        }
        fetch(url, init);
    }

    return (
        <div>
            <Notifications errorMessage={errorMessage} />
            <div className="row" style={{ padding: "5px 10px" }}>
            {GetField("searchSite")["isVisible"] && <div className="col-md-3">
                <label className="control-label">{GetField("searchSite")["label"]}</label>
                <DropDownList id="Site" data={sites} value={site} textField="name" dataItemKey="id" onChange={siteChanged} />
                </div>}
            </div>
            <div className="row">
                {columns && permissions && <DataGrid dataSource='apiv7/settings' columns={columns} permissions={permissions} params={lookupParams} keyField="settingsId" customCommand={SettingsCommand} customCommandName="SettingsCommand" />}
            </div>
        </div>
    );

}

export default WeighbridgeSettings;