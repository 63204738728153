import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import configData from "../config.json";
import { GetSession, GetToken } from '../components/Session';
import { Form, Field, FormElement, FieldArray } from "@progress/kendo-react-form";
import { FormInput, FormSwitch, FormDropDownList } from "../components/formComponents";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import { GetPermissions } from '../components/Options';
import { filterBy } from "@progress/kendo-data-query";
import { Label } from '@progress/kendo-react-labels';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextBox } from '@progress/kendo-react-inputs';

export const FormGridEditContext = React.createContext({});

const DeleteCell = (props) => {
    const { dataItem } = props;
    return (
        <td>
            <Button icon="delete" onClick={() => props.remove(dataItem)} title="Delete" />
        </td>
    )
}

const TriggerFormGrid = fieldArrayRenderProps => {
    const { name } = fieldArrayRenderProps;
    const triggerList = fieldArrayRenderProps.triggerList;

    const CommandCell = (props) => {
        return (
            <DeleteCell {...props} remove={Remove} />
        )
    }

    const Remove = (item) => {
        var url = `${configData.SERVER_URL}apiv7/outputstatetriggers/${item.Id}`;
        const init = {
            method: 'DELETE',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache"
        }
        fetch(url, init).then(response => response).then(d => {
            if (fieldArrayRenderProps.refresh !== undefined) fieldArrayRenderProps.refresh();
        });
    }

    return <FormGridEditContext.Provider value={{ parentField: name }}>
        <Grid
            pageable={{
                buttonCount: 4,
                pageSizes: [10, 20, 50, 100, 200, "All"],
            }}
            reorderable={true}
            resizable={true}
            data={triggerList}
        >
            <Column field="Name" title="Name" editable={false} />
            <Column field="BusinessData" title="Business Data" editable={false} />
            <Column field="Filter" title="Filter" editable={false} />
            <Column field="NextState" title="Next State" editable={false} />
            <Column field="Action" title="Delete" cell={CommandCell} editable={false} />
        </Grid>
    </FormGridEditContext.Provider>;
};

const OutputFormGrid = fieldArrayRenderProps => {
    const { name } = fieldArrayRenderProps;
    const outputList = fieldArrayRenderProps.outputList;

    const CommandCell = (props) => {
        return (
            <DeleteCell {...props} remove={Remove} />
        )
    }

    const Remove = (item) => {
        var url = `${configData.SERVER_URL}apiv7/outputstatevalues/${item.Id}`;
        const init = {
            method: 'DELETE',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache"
        }
        fetch(url, init).then(response => response).then(d => {
            if (fieldArrayRenderProps.refresh !== undefined) fieldArrayRenderProps.refresh();
        });
    }

    return <FormGridEditContext.Provider value={{ parentField: name }}>
        <Grid
            pageable={{
                buttonCount: 4,
                pageSizes: [10, 20, 50, 100, 200, "All"],
            }}
            reorderable={true}
            resizable={true}
            data={outputList}
        >
            <Column field="BusinessData" title="Business Data" editable={false} />
            <Column field="Value" title="Value" editable={false} />
            <Column field="Action" title="Delete" cell={CommandCell} editable={false} />
        </Grid>
    </FormGridEditContext.Provider>;
};

const EditOutputStates = () => {
    const { id } = useParams(); // editusers/id url is used to edit existing user, otherwise id is undefined
    const saveName = id === undefined ? "Create" : "Update";
    const [formkey, setFormkey] = React.useState(0); // seems to be needed to push values to form after loading
    const [permissions, setPermissions] = React.useState(null);
    const [triggers, setTriggers] = React.useState([]);
    const [outputs, setOutputs] = React.useState([]);
    const [businessData, setBusinessData] = React.useState([]);
    const [outputStates, setOutputStates] = React.useState([]);
    const [newEvent, setNewEvent] = React.useState();
    const [newBusinessData, setNewBusinessData] = React.useState();
    const [newFilter, setNewFilter] = React.useState();
    const [newNextState, setNewNextState] = React.useState();
    const [outputBusinessData, setOutputBusinessData] = React.useState();
    const [outputValue, setOutputValue] = React.useState();
    const [options, setOptions] = React.useState([]);
    const [sites, setSites] = React.useState();

    const navigate = useNavigate();
    // default layout data for page
    const [layout, setLayout] = React.useState([
        { "field": "name", "label": "Name", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "triggers", "label": "Triggers", "defaultValue": "", "isVisible": true, "type": "text" },
        { "field": "outputs", "label": "Outputs", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "addEvent", "label": "New Event", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "newBusinessData", "label": "Business Data", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "newFilter", "label": "Filter", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "newNextState", "label": "Next State", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "outputBusinessData", "label": "Output Business Data", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "outputValue", "label": "Output Value", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "siteId", "label": "Site", "defaultValue": "", "isVisible": true, "type": "list" },
        { "field": "isActive", "label": "Active", "defaultValue": "true", "isVisible": true, "type": "boolean" }
    ]);

    const triggerEvents = ["BusinessData", "Reset", "IDScanned", "ID2Scanned", "TicketPrinted", "LiftBoomGate", "Timeout"];
    // Gets field from layout based on field name
    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    // State data for form. Values match form value on submit
    const [values, setValues] = React.useState({
        name: GetField("name")["defaultValue"],
        isActive: String(GetField("isActive")["defaultValue"]).toLowerCase() === "true"
    });

    // When page first loads:
    // - populate drop downs from apiv7/options
    // - if in edit mode, set initial values for all fields for apiv7/vouchers
    React.useEffect(() => {
        GetPermissions(["EditAppSettings", "NewAppSettings"], setPermissions);
        var url = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'EditOutputStates'";
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        fetch(url, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile) {
                let data = [];
                for (let i = 0; i < d.length; i++) {
                    data.push({ field: d[i].field, label: d[i].label, type: d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile });
                }
                setLayout(data);
            } else {
                setLayout(d);
            }
        });
        url = configData.SERVER_URL + "apiv7/options";
        fetch(url, init).then(response => response.json()).then(opt => {
            setOptions(opt);
            setSites(opt.sites);
            if (id !== undefined) {
                url = `${configData.SERVER_URL}apiv7/outputstates/${id}`;
                fetch(url, init).then(response => response.json()).then(d => {
                    let s = opt.sites.find(obj => { return obj.id === String(d.siteId) });
                    setValues({
                        name: d.name === null ? "" : d.name,
                        siteId: s,
                        isActive: d.isActive === null ? "" : d.isActive
                    });
                    ConfigureTriggers(d.triggers, opt);
                    ConfigureOutputs(d.outputs, opt);
                    setBusinessData(opt.businessData);
                    setOutputStates(opt.outputStates);
                    setFormkey(formkey + 1);
                })
            }
        });
    }, []);

    // Returns value to be sent to web api:
    // If field is visible just return f
    // If field is hidden, return defaultValue from layout data
    const GetDefaultData = (f, name) => {
        let fld = GetField(name);
        if (f === undefined) f = "";
        if (fld === undefined || fld === null) return f;
        let res = fld["defaultValue"];
        if (fld["isVisible"]) res = f;
        switch (fld["type"]) {
            case "numeric":
                res = parseFloat(res);
                if (isNaN(res)) res = 0;
                break;
            case "boolean": res = (res === "true" || res === true); break;
            case "date":
                if (res === "") res = null;
                break;
            default: break;
        }
        return res;
    }

    const ReloadTriggers = () => {
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        }
        let url = `${configData.SERVER_URL}apiv7/outputstates/${id}`;
        fetch(url, init).then(response => response.json()).then(d => {
            ConfigureTriggers(d.triggers, options);
            ConfigureOutputs(d.outputs, options);
        });
    }

    const ConfigureTriggers = (t, opt) => {
        let tr = [];
        if (t !== null) {
            for (let i = 0; i < t.length; i++) {
                let dn = "";
                if (t[i].businessDataId !== null) {
                    let v = opt.businessData.find(obj => { return obj.id == t[i].businessDataId });
                    if (v !== undefined) dn = v.name;
                }
                let ns = "";
                if (t[i].nextStateId !== null && opt.outputStates !== null) {
                    let v = opt.outputStates.find(obj => { return obj.id == t[i].nextStateId });
                    if (v !== undefined) ns = v.name;
                }
                tr.push({ Id: t[i].outputStateTriggerId, Name: t[i].name, BusinessData: dn, Filter: t[i].filter, NextState: ns });
            }
        }
        setTriggers(tr);
    }

    const ConfigureOutputs = (t, opt) => {
        let tr = [];
        if (t !== null) {
            for (let i = 0; i < t.length; i++) {
                let dn = "";
                if (t[i].businessDataId !== null && opt.businessData !== null) {
                    let v = opt.businessData.find(obj => { return obj.id == t[i].businessDataId });
                    if (v !== undefined) dn = v.name;
                }
                tr.push({ Id: t[i].outputStateValueId, BusinessData: dn, Value: t[i].value });
            }
        }
        setOutputs(tr);
    }

    // Handle submit button. For create POST the data, for edit PUT the data, then redirect to offenses index page.
    const handleSubmit = (dataItem) => {
        var pth = id === undefined ? "" : "/" + id;
        var url = `${configData.SERVER_URL}apiv7/outputstates${pth}`;
        const init = {
            method: id === undefined ? 'POST' : 'PUT',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "outputStateId": id === undefined ? 0 : id,
                "name": GetDefaultData(dataItem.name, "name"),
                "siteId": GetDefaultData(dataItem.siteId.id, "siteId"),
                "isActive": GetDefaultData(dataItem.isActive, "isActive")
            })
        }
        fetch(url, init).then(response => response).then(d => {
            var msg = id === undefined ? "Output State created" : "Output State updated";
            if (d.status > 299) msg = `Error ${d.status} creating Output State`;
            Redirect(msg);
        });
    }

    const AddNewEvent = (e) => {
        setNewEvent(e.value);
    }
    const AddNewBusinessData = (e) => {
        setNewBusinessData(e.value);
        setNewEvent("BusinessData");
    }
    const AddNewFilter = (e) => { setNewFilter(e.value); }
    const AddNewNextState = (e) => { setNewNextState(e.value); }
    const AddOutputBusinessData = (e) => { setOutputBusinessData(e.value); }
    const AddOutputValue = (e) => { setOutputValue(e.value); }

    const AddTriggerEvent = (event) => {
        if (newEvent === undefined && newBusinessData === undefined) {
            alert("Select a New Event OR BusinessData");
            return;
        }
        if (newNextState === undefined) {
            alert("Select a Next State");
            return;
        }
        let ev = "";
        let bd = null;
        if (newEvent === undefined || newEvent === "BusinessData") {
            bd = parseInt(newBusinessData.id);
        } else {
            ev = newEvent;
        }
        var ns = parseInt(newNextState.id);
        var url = `${configData.SERVER_URL}apiv7/outputstatetriggers`;
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "outputStateTriggerId": 0,
                "outputStateId": parseInt(id),
                "name": ev,
                "businessDataId": bd,
                "filter": newFilter === undefined ? "" : newFilter,
                "nextStateId": ns
            })
        }
        fetch(url, init).then(response => response).then(d => {
            ReloadTriggers();
        });
    }

    const AddOutput = (event) => {
        if (outputBusinessData === undefined) {
            alert("Select a BusinessData");
            return;
        }
        let bd = outputBusinessData.id;
        var url = `${configData.SERVER_URL}apiv7/outputstatevalues`;
        const init = {
            method: 'POST',
            accept: "application/json",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + GetToken()
            },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "outputStateId": id,
                "businessDataId": bd,
                "value": outputValue
            })
        }
        fetch(url, init).then(response => response).then(d => {
            ReloadTriggers();
        });
    }

    // navigation
    const GoBack = () => Redirect("");
    const Redirect = (msg = "") => {
        navigate("/outputstate", { state: { msg: msg } });
    }
    if (permissions && !permissions.CanEdit && id !== undefined) navigate("/error401");
    if (permissions && !permissions.CanCreate && id === undefined) navigate("/error401");

    // form validators
    const textValidator = (value) => !value ? "Please enter a value" : "";
    const optionValidator = (value) => value ? "" : "Please select an option";
    return (
        <div style={{ padding: 20 }}>
            <Form onSubmit={handleSubmit} initialValues={values} key={formkey} render={(formRenderProps) => (
                <FormElement style={{ width: "95%" }} >
                    <fieldset className={"k-form-fieldset"}>
                        <div className="row">
                            {GetField("name")["isVisible"] && <div className="col-md-3">
                                <Field id={"name"} name={"name"} label={GetField("name")["label"]} component={FormInput} validator={textValidator} />
                            </div>}
                            {GetField("isActive")["isVisible"] && <div className='col-md-1'>
                                <Field id={"isActive"} name={"isActive"} label={GetField("isActive")["label"]} component={FormSwitch} />
                            </div>}
                            {GetField("siteId")["isVisible"] && <div className="col-md-3">
                                <Field id={"siteId"} name={"siteId"} label={GetField("siteId")["label"]} component={FormDropDownList} data={sites} textField="name" dataItemKey="id" />
                            </div>}
                        </div>
                        {id !== undefined && <div>
                            <div className="row" style={{ marginTop: "53px" }}>
                                <h4>Triggers</h4>
                            </div>
                            <div className="row">
                                {GetField("addEvent")["isVisible"] && <div className='col-md-3'>
                                    <Label>{GetField("addEvent")["label"]}</Label>
                                    <DropDownList id={"addEvent"} name={"addEvent"} data={triggerEvents} value={newEvent} onChange={AddNewEvent} />
                                    OR<br/>
                                    <Label>{GetField("newBusinessData")["label"]}</Label>
                                    <DropDownList id={"newBusinessData"} name={"newBusinessData"} data={businessData} value={newBusinessData} textField="name" onChange={AddNewBusinessData} />
                                </div>}
                                {GetField("newFilter")["isVisible"] && <div className="col-md-2">
                                    <Label>{GetField("newFilter")["label"]}</Label>
                                    <TextBox id={"newFilter"} name={"newFilter"} onChange={AddNewFilter} />
                                </div>}
                                {GetField("newNextState")["isVisible"] && <div className="col-md-2">
                                    <Label>{GetField("newNextState")["label"]}</Label>
                                    <DropDownList id={"newNextState"} name={"newNextState"} data={outputStates} textField="name" onChange={AddNewNextState} />
                                </div>}
                                <div className='col-md-1' style={{marginTop: "26px"} }>
                                    <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={AddTriggerEvent}>Add Trigger</Button>
                                </div>
                                <div className='row'>
                                    <strong>Filter Values</strong>
                                    Numeric value, Empty (any non-null value will trigger), Belowminimumweight, Aboveminimumweight, Stable, Found, First, Notfirst, Notfound
                                </div>
                            </div>
                            <div className="row" style={{marginTop: "30px"} }>
                                    <FieldArray name="triggers" dataItemKey={"id"} component={TriggerFormGrid} triggerList={triggers} triggerId={id} refresh={ReloadTriggers } />
                            </div>
                            <div className="row" style={{ marginTop: "53px" }}>
                                <h4>State Outputs</h4>
                            </div>
                            <div className="row">
                                {GetField("addEvent")["isVisible"] && <div className='col-md-3'>
                                    <Label>{GetField("outputBusinessData")["label"]}</Label>
                                    <DropDownList id={"outputBusinessData"} name={"outputBusinessData"} data={businessData} textField="name" onChange={AddOutputBusinessData} />
                                </div>}
                                {GetField("outputValue")["isVisible"] && <div className="col-md-2">
                                    <Label>{GetField("outputValue")["label"]}</Label>
                                    <TextBox id={"outputValue"} name={"outputValue"} onChange={AddOutputValue} />
                                </div>}
                                <div className='col-md-1' style={{ marginTop: "26px" }}>
                                    <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={AddOutput}>Add Output</Button>
                                </div>
                            </div>
                            <div className='row'>
                                <strong>Output Value</strong> Gates: Open or Close, Traffic Lights: Red or Green, Remote Displays: Text (4-6 chars max)
                            </div>
                            <div className="row" style={{ marginTop: "30px" }}>
                                <FieldArray name="outputs" dataItemKey={"id"} component={OutputFormGrid} outputList={outputs} triggerId={id} refresh={ReloadTriggers} />
                            </div>
                        </div>}
                    </fieldset>
                    <div className="k-form-buttons" style={{ paddingTop: 10 }}>
                        <div className='col-md-1'>
                            <Button type={"submit"} themeColor={"primary"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" disabled={!formRenderProps.allowSubmit}>{saveName}</Button>
                        </div>
                        <div className='col-md-1'>
                            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={GoBack}>Back</Button>
                        </div>
                    </div>
                </FormElement>
            )} />
        </div>
    );
}

export default EditOutputStates