import * as React from "react";
import { Avatar, Menu, MenuItem } from "@progress/kendo-react-layout";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import { Popup } from "@progress/kendo-react-popup";
import Bot from "../pages/Bot";
import { Alert } from "./Alert";
import operator from '../assets/assistance.png'
import user from '../assets/user.png'
import { GetToken, GetUserName } from "../components/Session";
import configData from "../config.json";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { setMessageSession } from "../components/Session";
let kendokaAvatar =
  "https://www.telerik.com/kendo-react-ui-develop/components/images/kendoka-react.png";

export const Header = (props) => {
  const { onButtonClick } = props;
  const anchor = React.useRef(null);

  const [show, setShow] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(true);
  const [message, setMessage] = React.useState(null);
  const [supportId, setSupportId] = React.useState(0);
  const onClick = () => {
    setShow(!show);
  };
  React.useEffect(() => {
    var baseUrl;
    const init = {
      method: "GET",
      accept: "application/json",
      headers: {"Authorization": "Bearer " + GetToken()},
    };
      if(localStorage.getItem("MessageId") == undefined){
        baseUrl = configData.SERVER_URL + "apiV7/support/ViewMessage/"+supportId;
      }
      else{
        baseUrl = configData.SERVER_URL + "apiV7/support/ViewMessage/"+localStorage.getItem("MessageId");
      }
      
      fetch(baseUrl, init)
      .then((response) => response.json())
      .then((d) => {       
          setMessage(d.description);
          setSupportId(d.supportId);        
        
      });
  },[]);
  
  const closeMessage = () => {
    setShowMessage(false);
    localStorage.setItem("MessageId", supportId);
  }
  return (
    <AppBar>
      <AppBarSection>
        <div className="menu-button">
          <span className={"k-icon k-i-menu"} onClick={onButtonClick} />
        </div>
      </AppBarSection>
      <AppBarSpacer
        style={{
          width: 400,
        }}
      />{message != null &&<Fade>{showMessage && <AppBarSection>
          < NotificationGroup >
          <Notification style={{width:900, textAlign:"center", }} closable={true} onClose={closeMessage}
            key={{}}
            type={{
              style: "info",
            }}
          >
            <b><h7 style={{fontsize: 40}}>{message}</h7></b>
          </Notification>
          </NotificationGroup>
        </AppBarSection>}</Fade>}
      <AppBarSpacer
        style={{
          float: "right",
        }}
      />
      {/*<AppBarSection>*/}
      {/*  <ul>*/}
      {/*    <li>*/}
      {/*      <div>*/}
      {/*        <button*/}
      {/*          className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"*/}
      {/*          onClick={onClick}*/}
      {/*          ref={anchor}*/}
      {/*        >*/}
      {/*          <Avatar type="image">*/}
      {/*            <img*/}
      {/*                src={operator}*/}
      {/*                alt="KendoReact Layout Kendoka Avatar"*/}
      {/*              />*/}
      {/*          </Avatar>*/}
      {/*        </button>*/}
      {/*        <Popup anchor={anchor.current} show={show}>*/}
      {/*          <Bot></Bot>*/}
      {/*        </Popup>*/}
      {/*      </div>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</AppBarSection>*/}
          <AppBarSection>
        <Menu>
          <MenuItem
            render={() => {
              return (
                <Avatar type="image">
                  <img
                    src={user}
                    alt="KendoReact Layout Kendoka Avatar"
                  />
                </Avatar>
              );
            }}
          >
            <MenuItem text="Account" url="/account" />
            <MenuItem text="Settings" url="/settings" />
            <MenuItem text="Task Board" url="/Todos" />
            <MenuItem text="Change Password" url="/password" />
            <MenuItem text={"Logout ("+GetUserName()+")"}   url="/Logout"/>
          </MenuItem>
        </Menu>
      </AppBarSection>
    </AppBar>
  );
};
