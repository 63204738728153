import * as React from 'react';
import DataGrid from '../components/DataGrid';
import GetColumnDefs from '../components/ColumnDefs';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import Notifications from '../components/Notifications';
import { GetPermissions } from '../components/Options';
import { useNavigate } from "react-router-dom";
import { GetSession, GetToken } from '../components/Session';
import configData from "../config.json";
import { TextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";

const DocketLookup = () => {
    const [columns, setColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [permissions, setPermissions] = React.useState(null);
    const [rego, setRego] = React.useState("");
    const [docket, setDocket] = React.useState("");
    const navigate = useNavigate();
    if (permissions && !permissions.CanView) navigate("/");
    const [layout, setLayout] = React.useState([
        { "field": "searchDocket", "label": "Docket No", "isVisible": true },
        { "field": "searchRego", "label": "Registration", "isVisible": true }
    ]);

    const GetField = (f) => {
        return layout.find(obj => { return obj.field === f });
    }

    React.useEffect(() => {
        GetPermissions(["ViewTransaction", "EditTransaction"], setPermissions);
        GetColumnDefs("DocketLookup", setColumns);
        let baseUrl = configData.SERVER_URL + "apiv7/userinterfaces?$filter=viewName eq 'DocketLookup'";
        const init = {
            method: 'GET',
            accept: 'application/json',
            headers: { "Authorization": "Bearer " + GetToken() }
        };
        fetch(baseUrl, init).then(response => response.json()).then(d => {
            if (GetSession().IsMobile) {
                let data = [];
                for (let i = 0; i < d.length; i++) {
                    data.push({ field: d[i].field, label: d[i].label, type: d[i].type, format: d[i].format, isVisible: d[i].isVisible && d[i].isVisibleOnMobile });
                }
                setLayout(data);
            } else {
                setLayout(d);
            }
        });
    }, [])

    let dr = JSON.parse(localStorage.getItem('DocketDates'));
    if (dr !== null) {
        if (dr.start !== null) dr.start = new Date(dr.start);
        if (dr.end !== null) dr.end = new Date(dr.end);
    } else {
        let dt = new Date();
        dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: null };
        localStorage.setItem("DocketDates", JSON.stringify(dr));
    }
    const [dateRange, setDateRange] = React.useState(dr);

    const CreateParams = (dr, docket, rego) => {
        var params = {
            FromDate: dr.start === null ? "2000-01-01T00:00:00Z" : dr.start.toISOString(),
            ToDate: dr.end === null ? "2050-01-01T00:00:00Z" : dr.end.toISOString(),
            Docket: docket,
            Rego: rego
        };
        return encodeURI(JSON.stringify(params));
    }

    const par = CreateParams(dr);
    const [lookupParams, setLookupParams] = React.useState(par);

    const rangeChanged = (event) => {
        let dr = event.target.value;
        setDateRange(dr);
        localStorage.setItem("DocketDates", JSON.stringify(dr));
    }

    const regoChanged = (event) => {
        setRego(event.target.value);
    }

    const docketChanged = (event) => {
        setDocket(event.target.value);
    }

    const DoSearch = () => {
        setLookupParams(CreateParams(dateRange, docket, rego));
    }

    const ClearSearch = () => {
        setRego("");
        setDocket("");
        let dt = new Date();
        let dr = { start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()), end: null };
        setDateRange(dr);
        setLookupParams(CreateParams(dr, "", ""));
    }

    return (
        <div>
            <div>
                <div>
                    <Notifications errorMessage={errorMessage} />
                </div>
                <div className="row" style={{ padding: "25px" }}>
                    {GetField("searchDocket")["isVisible"] && <div className="col-md-3">
                        <label className="control-label">{GetField("searchDocket")["label"]}</label>
                        <TextBox id="Rego" type="text" value={docket} onChange={docketChanged} />
                    </div>}
                    {GetField("searchRego")["isVisible"] && <div className="col-md-3">
                        <label className="control-label">{GetField("searchRego")["label"]}</label>
                        <TextBox id="Rego" type="text" value={rego} onChange={regoChanged} />
                    </div>}
                    <div className="col-md-3">
                        <DateRangePicker id="DateRange" value={dateRange} format="dd/MM/yyyy" onChange={rangeChanged} />
                    </div>
                    <div className="col-md-3" style={{ padding: "20px" }}>
                        <Button onClick={DoSearch} themeColor={"primary"}>Search</Button>{" "}
                        <Button onClick={ClearSearch} themeColor={"primary"}>Clear</Button>
                    </div>
                </div>

            </div>
            {columns && permissions && <DataGrid dataSource='apiv7/transactions' viewName='DocketLookup' columns={columns} canEdit editUrl='EditDocketLookup' keyField="transactionId" params={lookupParams} permissions={permissions} />}
        </div>
    );

}

export default DocketLookup;