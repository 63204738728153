import * as React from 'react';
import createTransaction from '../assets/Create-Transaction.mp4'
import createCustomer from '../assets/Create-customer.mp4'
import createJob from '../assets/Create-job.mp4'
import createSource from '../assets/Create-source.mp4'
import createDestination from '../assets/Create-destination.mp4'
import createProduct from '../assets/Create-product.mp4'
import createTruck from '../assets/Create-Truck.mp4'
import DocketLookUp from '../assets/Create-customer.mp4'
import { useParams } from "react-router-dom";
const WatchVideo = () => {
   const { link } = useParams(); 
   var src;
   var topic
   switch(link){
      case "createTransaction": src = createTransaction ; topic = "Transaction"; break;
      case "createJob": src = createJob ; topic = "Job"; break;
      case "createSource": src = createSource ; topic = "Source"; break;
      case "createDestination": src = createDestination ; topic = "Destination"; break;
      case "createProduct": src = createProduct ; topic = "Product"; break;
      case "createTruck": src = createTruck ; topic = "Truck"; break;
      case "DocketLookUp": src = DocketLookUp ; topic = "Docket Lookup"; break;
      default: src = createCustomer; break;
  }
    return ( 
       
      <div className='hero-container'>
         <div style={{width:"100%",padding:20, textAlign:"center"}}>
           <h2>How to Create a {topic}</h2 ><button><a style={{color:"white"}} href="/">Back to Dashboard</a></button>
         </div>
         <div style={{width:"100%",padding:20}}>
            <video fluid={false} width="100%" height="100%" src={src} autoPlay loop muted></video>
         </div>
      </div>
              
         
    );
}

export default WatchVideo;